import { ReactElement } from 'react'

type ConditonalWrapperProps = {
  children: ReactElement
  condition: boolean
  wrapper: (children: ReactElement) => JSX.Element
}

export const ConditonalWrapper = ({ condition, wrapper, children }: ConditonalWrapperProps) =>
  condition ? wrapper(children) : children
