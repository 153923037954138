import { Line } from 'react-chartjs-2'

import { ChartDataProcessed } from '../../types'
import { lineOptions } from './constants'
import { ChartContainer, Heading } from './styled'

import { ChartPlaceholder } from './ChartPlaceholder'

type Props = {
  heading: string
  data?: ChartDataProcessed
}

export const LineChart = ({ data, heading }: Props) => {
  if (!data || !data.datasets[0]) return <ChartPlaceholder />

  return (
    <ChartContainer>
      <Heading>{heading}</Heading>
      <Line data={data} options={lineOptions} height={267} />
    </ChartContainer>
  )
}
