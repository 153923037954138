import { MouseEvent } from 'react'
import styled from 'styled-components'

import { applySpacingProps, SpacingProps } from 'helpers'

export type SVGProps = {
  $spacing?: SpacingProps
  color?: string
  height?: string
  width?: string
  onClick?: (e: MouseEvent<SVGSVGElement>) => void
}

export const SVG = styled.svg<SVGProps>`
  display: block;
  transition: ${props => props.theme.transitions.fastEase};

  ${props => props.onClick && 'cursor: pointer;'}
  ${props => applySpacingProps(props.$spacing)}
`
