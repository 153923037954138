import { useEffect, useState, useRef } from 'react'
import WordArray from 'crypto-js/lib-typedarrays'

import { useTree } from '../providers/use-tree'
import { TreeState, TreeStateStored, WatchState } from '../providers/use-tree-state'
import { useChartId } from '../providers/use-chart-uuid'

const generateId = (length = 32) => WordArray.random(length).toString()

/**
 * @see useWatchConnectionLine (path: /tree/hooks/use-watch-connection-lines.tsx) hook for details
 */
export function useWatch<K extends keyof Pick<WatchState, 'onConnectionLinesChange'>>({
  name,
}: {
  name: K
}): WatchState[K]
export function useWatch<K extends keyof WatchState>({ name }: { name: K }): WatchState[K]
export function useWatch<K extends keyof TreeStateStored>({ name }: { name: K }): TreeStateStored[K]
export function useWatch<K extends keyof TreeState>({ name }: { name: K }): TreeState[K] {
  const chartUuid = useChartId()
  const methods = useTree()

  const { useWatchFieldsRef, useWatchRenderFunctionsRef, watch, valueRef } = methods.control
  const [, updateValue] = useState<unknown>()
  const idRef = useRef<string>()

  useEffect(() => {
    idRef.current = generateId()
    const id = idRef.current
    const watchFieldsHookRender = useWatchRenderFunctionsRef.current
    const watchFieldsHook = useWatchFieldsRef.current
    watchFieldsHook[id] = new Set()
    watchFieldsHookRender[id] = () => updateValue({})
    watch(name, id)

    return () => {
      delete watchFieldsHook[id]
      delete watchFieldsHookRender[id]
    }
  }, [chartUuid, name, useWatchRenderFunctionsRef, useWatchFieldsRef, watch])

  return idRef.current ? watch(name, idRef.current) : valueRef.current[name]
}
