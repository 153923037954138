import { SVG, SVGProps } from './SVG'

export const WomanSittingOnMoonShapeOfVoidIcon = (props: SVGProps) => (
  <SVG width='159' height='123' viewBox='0 0 159 123' fill='none' {...props}>
    <path
      d='M154.879 71.9296C172.125 91.0013 122.133 118.486 81.7384 118.486C41.3439 118.486 13.3971 97.1903 8.5976 71.9296C-3.03965 10.6811 107.75 -28.2481 81.7384 25.3729C43.8239 103.532 141.298 56.9103 154.879 71.9296Z'
      fill='#F1F5F7'
    />
    <path
      d='M147.141 76.4433C164.387 95.515 114.395 123 74.0006 123C33.6061 123 5.65935 101.704 0.859803 76.4433C-10.7774 15.1948 100.012 -23.7344 74.0006 29.8866C36.0861 108.046 133.56 61.424 147.141 76.4433Z'
      fill='#535461'
    />
    <path
      d='M91.9969 98.9827L85.2535 92.2393C85.7704 91.4353 86.1207 90.5357 86.2836 89.5939C86.4464 88.6521 86.4186 87.6871 86.2018 86.7562C85.9849 85.8253 85.5834 84.9474 85.0211 84.1745C84.4587 83.4016 83.7469 82.7495 82.9279 82.2567C81.3858 81.3354 79.5538 81.0269 77.795 81.3923C76.0361 81.7576 74.4786 82.7702 73.4309 84.2295C72.4669 85.5812 72.0039 87.2265 72.1215 88.8826C72.2391 90.5387 72.93 92.1021 74.0754 93.304C75.2208 94.506 76.7491 95.2713 78.3976 95.4685C80.0462 95.6656 81.7119 95.2823 83.1084 94.3845L89.8519 101.128C90.1364 101.412 90.5221 101.572 90.9242 101.572C91.3264 101.571 91.712 101.412 91.9964 101.127C92.2807 100.843 92.4405 100.457 92.4406 100.055C92.4407 99.653 92.2811 99.2673 91.9969 98.9827ZM82.8566 91.9876C82.0211 92.8232 80.9216 93.3432 79.7456 93.459C78.5697 93.5748 77.3899 93.2793 76.4074 92.6229C75.4249 91.9664 74.7004 90.9895 74.3573 89.8587C74.0143 88.7279 74.074 87.5132 74.5262 86.4215C74.9784 85.3298 75.7951 84.4286 76.8372 83.8716C77.8794 83.3145 79.0824 83.1361 80.2414 83.3666C81.4003 83.5971 82.4435 84.2224 83.1932 85.1358C83.9428 86.0492 84.3525 87.1944 84.3525 88.376C84.3534 89.0469 84.2217 89.7113 83.965 90.3311C83.7083 90.9509 83.3316 91.5138 82.8566 91.9876Z'
      fill='white'
    />
    <path
      d='M24.1151 31.7288C12.014 31.196 2.63135 28.5857 2.75462 25.7861C2.82852 24.1077 6.25324 22.7581 12.1506 22.0834C12.1717 22.0808 12.1931 22.0825 12.2136 22.0882C12.2341 22.094 12.2532 22.1037 12.2699 22.1169C12.2866 22.1301 12.3005 22.1464 12.3108 22.165C12.3212 22.1835 12.3277 22.204 12.3302 22.2251C12.3326 22.2462 12.3308 22.2676 12.3249 22.2881C12.319 22.3085 12.3091 22.3275 12.2959 22.3441C12.2826 22.3607 12.2661 22.3746 12.2475 22.3848C12.2289 22.395 12.2084 22.4014 12.1872 22.4037C6.63192 23.0393 3.14097 24.3408 3.07671 25.8003C2.96524 28.3319 12.6061 30.8993 24.1292 31.4067C35.6524 31.9141 45.4816 30.204 45.5931 27.6723C45.6576 26.2067 42.2728 24.5989 36.7595 23.4765C36.7387 23.4723 36.7189 23.4641 36.7013 23.4523C36.6837 23.4404 36.6686 23.4252 36.6569 23.4076C36.6452 23.3899 36.637 23.3701 36.633 23.3493C36.6289 23.3285 36.629 23.307 36.6332 23.2863C36.6375 23.2655 36.6458 23.2457 36.6576 23.2282C36.6695 23.2106 36.6847 23.1955 36.7024 23.1838C36.7201 23.1722 36.74 23.1641 36.7608 23.1601C36.7816 23.1561 36.8031 23.1563 36.8238 23.1605C42.6757 24.3519 45.9893 26.0016 45.9151 27.6865C45.7919 30.486 36.2161 32.2616 24.1151 31.7288Z'
      fill='#FED20A'
    />
    <path
      d='M13.3674 32.1976C14.3798 34.1207 15.8794 35.7442 17.7162 36.9058C19.553 38.0673 21.6627 38.726 23.8341 38.8162C26.0055 38.9063 28.1624 38.4246 30.0892 37.4193C32.016 36.414 33.645 34.9203 34.8132 33.0878C27.6537 33.1843 20.4943 32.8871 13.3674 32.1976Z'
      fill='#FED20A'
    />
    <path
      d='M36.1517 29.6969C36.761 27.8694 36.9365 25.9253 36.6645 24.0183C36.3924 22.1113 35.6803 20.2938 34.5843 18.7096C33.4884 17.1254 32.0388 15.8181 30.3503 14.891C28.6617 13.964 26.7806 13.4426 24.8557 13.3683C22.9308 13.294 21.0151 13.6687 19.26 14.4627C17.505 15.2568 15.9589 16.4485 14.7441 17.9434C13.5293 19.4384 12.6791 21.1956 12.2608 23.0759C11.8425 24.9562 11.8676 26.9081 12.3341 28.7771C20.2001 30.1124 28.2061 30.4216 36.1517 29.6969Z'
      fill='#FED20A'
    />
    <path
      d='M27.2164 21.3756C28.0177 21.3756 28.6673 20.726 28.6673 19.9248C28.6673 19.1235 28.0177 18.474 27.2164 18.474C26.4152 18.474 25.7656 19.1235 25.7656 19.9248C25.7656 20.726 26.4152 21.3756 27.2164 21.3756Z'
      fill='white'
    />
    <path
      d='M19.7496 27.0162C21.085 27.0162 22.1676 25.9336 22.1676 24.5981C22.1676 23.2627 21.085 22.1801 19.7496 22.1801C18.4141 22.1801 17.3315 23.2627 17.3315 24.5981C17.3315 25.9336 18.4141 27.0162 19.7496 27.0162Z'
      fill='white'
    />
    <path
      d='M56.2443 24.6476C57.1346 24.6476 57.8563 23.9259 57.8563 23.0356C57.8563 22.1453 57.1346 21.4236 56.2443 21.4236C55.354 21.4236 54.6323 22.1453 54.6323 23.0356C54.6323 23.9259 55.354 24.6476 56.2443 24.6476Z'
      fill='#E6E6E6'
    />
    <path
      d='M19.8127 73.9757C20.3469 73.9757 20.7799 73.5426 20.7799 73.0084C20.7799 72.4742 20.3469 72.0412 19.8127 72.0412C19.2785 72.0412 18.8455 72.4742 18.8455 73.0084C18.8455 73.5426 19.2785 73.9757 19.8127 73.9757Z'
      fill='#E6E6E6'
    />
    <path
      d='M87.5178 107.183C88.0519 107.183 88.485 106.75 88.485 106.216C88.485 105.682 88.0519 105.249 87.5178 105.249C86.9836 105.249 86.5505 105.682 86.5505 106.216C86.5505 106.75 86.9836 107.183 87.5178 107.183Z'
      fill='#E6E6E6'
    />
    <path
      d='M24.4875 55.2761C24.7546 55.2761 24.9711 55.0596 24.9711 54.7925C24.9711 54.5254 24.7546 54.3089 24.4875 54.3089C24.2204 54.3089 24.0039 54.5254 24.0039 54.7925C24.0039 55.0596 24.2204 55.2761 24.4875 55.2761Z'
      fill='#E6E6E6'
    />
    <path
      d='M64.4658 91.3856C64.7329 91.3856 64.9494 91.169 64.9494 90.9019C64.9494 90.6348 64.7329 90.4183 64.4658 90.4183C64.1987 90.4183 63.9822 90.6348 63.9822 90.9019C63.9822 91.169 64.1987 91.3856 64.4658 91.3856Z'
      fill='#E6E6E6'
    />
    <path
      d='M137.168 86.7107C137.435 86.7107 137.652 86.4942 137.652 86.2271C137.652 85.96 137.435 85.7434 137.168 85.7434C136.901 85.7434 136.685 85.96 136.685 86.2271C136.685 86.4942 136.901 86.7107 137.168 86.7107Z'
      fill='#E6E6E6'
    />
    <path
      d='M114.439 101.058C114.706 101.058 114.922 100.841 114.922 100.574C114.922 100.307 114.706 100.09 114.439 100.09C114.172 100.09 113.955 100.307 113.955 100.574C113.955 100.841 114.172 101.058 114.439 101.058Z'
      fill='#E6E6E6'
    />
    <path
      d='M90.7419 83.6478C91.009 83.6478 91.2255 83.4313 91.2255 83.1642C91.2255 82.8971 91.009 82.6806 90.7419 82.6806C90.4748 82.6806 90.2583 82.8971 90.2583 83.1642C90.2583 83.4313 90.4748 83.6478 90.7419 83.6478Z'
      fill='#E6E6E6'
    />
    <path
      d='M60.1133 35.9318C60.3804 35.9318 60.5969 35.7153 60.5969 35.4482C60.5969 35.1811 60.3804 34.9646 60.1133 34.9646C59.8462 34.9646 59.6296 35.1811 59.6296 35.4482C59.6296 35.7153 59.8462 35.9318 60.1133 35.9318Z'
      fill='#E6E6E6'
    />
    <path
      d='M63.9822 12.0738C64.2492 12.0738 64.4658 11.8573 64.4658 11.5902C64.4658 11.3231 64.2492 11.1066 63.9822 11.1066C63.7151 11.1066 63.4985 11.3231 63.4985 11.5902C63.4985 11.8573 63.7151 12.0738 63.9822 12.0738Z'
      fill='#E6E6E6'
    />
    <path
      d='M61.7253 112.503C61.9924 112.503 62.2089 112.287 62.2089 112.02C62.2089 111.752 61.9924 111.536 61.7253 111.536C61.4582 111.536 61.2417 111.752 61.2417 112.02C61.2417 112.287 61.4582 112.503 61.7253 112.503Z'
      fill='#E6E6E6'
    />
    <path
      d='M37.8674 102.347C38.1345 102.347 38.351 102.131 38.351 101.864C38.351 101.597 38.1345 101.38 37.8674 101.38C37.6003 101.38 37.3838 101.597 37.3838 101.864C37.3838 102.131 37.6003 102.347 37.8674 102.347Z'
      fill='#E6E6E6'
    />
    <path
      d='M113.471 84.9375C113.739 84.9375 113.955 84.7209 113.955 84.4538C113.955 84.1867 113.739 83.9702 113.471 83.9702C113.204 83.9702 112.988 84.1867 112.988 84.4538C112.988 84.7209 113.204 84.9375 113.471 84.9375Z'
      fill='#E6E6E6'
    />
    <path
      d='M23.9184 37.689C24.7196 37.689 25.3692 37.0394 25.3692 36.2382C25.3692 35.4369 24.7196 34.7873 23.9184 34.7873C23.1171 34.7873 22.4675 35.4369 22.4675 36.2382C22.4675 37.0394 23.1171 37.689 23.9184 37.689Z'
      fill='white'
    />
    <path
      d='M56.7279 58.5002C56.7279 62.8334 50.0285 72.9304 48.0706 75.7887C48.0115 75.8751 47.9323 75.9457 47.8397 75.9946C47.7471 76.0434 47.6441 76.069 47.5394 76.069C47.4347 76.069 47.3316 76.0434 47.2391 75.9946C47.1465 75.9457 47.0672 75.8751 47.0082 75.7887C45.0503 72.9304 38.3508 62.8334 38.3508 58.5002C38.3508 57.2935 38.5885 56.0987 39.0503 54.9839C39.512 53.8691 40.1889 52.8561 41.0421 52.0029C41.8953 51.1497 42.9083 50.4728 44.0231 50.0111C45.1379 49.5493 46.3327 49.3116 47.5394 49.3116C48.746 49.3116 49.9409 49.5493 51.0557 50.0111C52.1705 50.4728 53.1834 51.1497 54.0367 52.0029C54.8899 52.8561 55.5667 53.8691 56.0285 54.9839C56.4903 56.0987 56.7279 57.2935 56.7279 58.5002Z'
      fill='white'
    />
    <path
      d='M47.5394 62.5303C50.1212 62.5303 52.2143 60.4373 52.2143 57.8554C52.2143 55.2735 50.1212 53.1805 47.5394 53.1805C44.9575 53.1805 42.8645 55.2735 42.8645 57.8554C42.8645 60.4373 44.9575 62.5303 47.5394 62.5303Z'
      fill='#535461'
    />
    <path
      d='M47.4589 81.0686C50.4414 81.0686 52.8592 80.5995 52.8592 80.0208C52.8592 79.4421 50.4414 78.9729 47.4589 78.9729C44.4764 78.9729 42.0586 79.4421 42.0586 80.0208C42.0586 80.5995 44.4764 81.0686 47.4589 81.0686Z'
      fill='white'
    />
    <path
      d='M131.679 51.2495C131.638 51.3288 131.604 51.4112 131.576 51.4959L123.873 53.6362L122.485 52.2778L120.161 54.1047L122.385 56.7483C122.564 56.9619 122.808 57.1114 123.08 57.1744C123.352 57.2375 123.637 57.2106 123.892 57.0979L132.213 53.425C132.495 53.6209 132.83 53.7262 133.174 53.727C133.518 53.7278 133.853 53.6239 134.137 53.4292C134.42 53.2345 134.637 52.9582 134.759 52.6371C134.881 52.3159 134.903 51.9652 134.821 51.6315C134.739 51.2977 134.558 50.9969 134.301 50.7689C134.044 50.5408 133.723 50.3965 133.382 50.3551C133.041 50.3136 132.695 50.377 132.391 50.5367C132.087 50.6965 131.838 50.9451 131.679 51.2495H131.679Z'
      fill='#FFB8B8'
    />
    <path
      d='M123.601 53.7616L120.71 56.0119C120.628 56.076 120.533 56.1215 120.431 56.1455C120.33 56.1694 120.224 56.1712 120.122 56.1508C120.02 56.1303 119.923 56.0881 119.839 56.0269C119.755 55.9658 119.684 55.8871 119.633 55.7964L117.836 52.6163C117.511 52.1935 117.367 51.6591 117.435 51.13C117.503 50.601 117.778 50.1206 118.2 49.7942C118.622 49.4678 119.156 49.3221 119.685 49.3889C120.214 49.4558 120.696 49.7298 121.023 50.1508L123.657 52.6646C123.732 52.7366 123.791 52.824 123.83 52.9208C123.868 53.0176 123.886 53.1217 123.88 53.2258C123.875 53.3299 123.847 53.4316 123.799 53.5241C123.751 53.6166 123.684 53.6976 123.601 53.7616V53.7616Z'
      fill='#6C63FF'
    />
    <path
      d='M132.418 50.1851L135.35 51.5042C135.472 51.5595 135.568 51.6612 135.616 51.7871C135.664 51.913 135.66 52.0527 135.604 52.1754L135.257 52.947C135.298 52.9655 135.33 52.9995 135.346 53.0414C135.362 53.0833 135.36 53.1298 135.342 53.1708L135.203 53.4794C135.185 53.5202 135.151 53.5521 135.109 53.568C135.067 53.5839 135.02 53.5825 134.98 53.5643L134.841 53.8729C134.881 53.8914 134.913 53.9254 134.929 53.9673C134.945 54.0092 134.944 54.0557 134.926 54.0967L134.787 54.4053C134.768 54.4461 134.734 54.478 134.692 54.4939C134.65 54.5098 134.604 54.5084 134.563 54.4902L132.688 58.6566C132.633 58.7794 132.531 58.8752 132.406 58.923C132.28 58.9707 132.14 58.9665 132.017 58.9113L129.085 57.5922C128.962 57.537 128.867 57.4352 128.819 57.3093C128.771 57.1835 128.775 57.0438 128.831 56.921L131.746 50.4398C131.802 50.317 131.903 50.2212 132.029 50.1734C132.155 50.1257 132.295 50.1299 132.418 50.1851Z'
      fill='#3F3D56'
    />
    <path
      d='M129.618 57.1817L131.97 58.2409C132.087 58.2936 132.221 58.2976 132.342 58.2519C132.463 58.2063 132.561 58.1148 132.614 57.9973L133.701 55.5809L134.078 54.7433L135.061 52.5571C135.114 52.4394 135.117 52.3058 135.072 52.1853C135.026 52.0649 134.935 51.9673 134.817 51.9141L134.078 51.5807L132.464 50.8548C132.346 50.8022 132.212 50.7985 132.091 50.8445C131.971 50.8905 131.874 50.9824 131.821 51.1002L130.87 53.2136L129.787 55.6198L129.374 56.5387C129.321 56.6563 129.317 56.79 129.363 56.9105C129.409 57.031 129.5 57.1285 129.618 57.1817Z'
      fill='#F2F2F2'
    />
    <path d='M124.703 87.1209L126.679 87.1207L127.619 79.4978L124.702 79.4979L124.703 87.1209Z' fill='#FFB8B8' />
    <path
      d='M124.199 86.4757L128.091 86.4755H128.091C128.417 86.4755 128.739 86.5396 129.04 86.6643C129.341 86.7889 129.614 86.9716 129.845 87.202C130.075 87.4323 130.258 87.7057 130.382 88.0066C130.507 88.3076 130.571 88.6301 130.571 88.9558V89.0364L124.199 89.0367L124.199 86.4757Z'
      fill='#2F2E41'
    />
    <path d='M129.861 86.6373L131.838 86.6371L132.778 79.0142L129.861 79.0143L129.861 86.6373Z' fill='#FFB8B8' />
    <path
      d='M129.357 85.9921L133.249 85.9919H133.249C133.575 85.9919 133.898 86.056 134.199 86.1807C134.5 86.3053 134.773 86.488 135.003 86.7183C135.234 86.9487 135.416 87.2221 135.541 87.523C135.666 87.8239 135.73 88.1465 135.73 88.4722V88.5528L129.357 88.553L129.357 85.9921Z'
      fill='#2F2E41'
    />
    <path
      d='M115.838 69.2331C116.259 70.4812 117.058 71.5677 118.123 72.342C119.189 73.1162 120.469 73.5401 121.786 73.5549L121.886 73.5565C122.907 73.5855 124.104 73.2567 125.28 72.7844C127.601 71.851 129.83 70.3567 130.422 69.9456L129.419 79.5839L129.013 83.4915C129.003 83.5925 129.014 83.6947 129.045 83.7912C129.077 83.8878 129.128 83.9767 129.196 84.0522C129.265 84.1276 129.348 84.188 129.44 84.2294C129.533 84.2709 129.634 84.2924 129.735 84.2926H132.239C132.399 84.2927 132.555 84.2396 132.682 84.1416C132.81 84.0436 132.901 83.9063 132.941 83.751L137.075 68.0095C137.199 67.5384 137.209 67.0445 137.104 66.5687C136.999 66.0929 136.782 65.6489 136.472 65.2735C136.161 64.8981 135.766 64.602 135.318 64.4098C134.87 64.2175 134.383 64.1347 133.897 64.1681L125.312 64.7565L125.911 62.3578L118.248 61.8484L118.225 61.8645C118.042 61.9983 117.863 62.1385 117.692 62.2852C117.264 62.6466 116.886 63.0616 116.565 63.52C115.991 64.3426 115.625 65.2921 115.498 66.2871C115.372 67.2821 115.488 68.293 115.838 69.2331Z'
      fill='#2F2E41'
    />
    <path
      d='M115.838 69.2331C116.259 70.4812 117.058 71.5677 118.123 72.342C119.189 73.1163 120.469 73.5401 121.786 73.5549C123.046 72.9174 124.261 72.1963 125.425 71.3964L125.28 72.7844L124.016 84.9423C124.005 85.0434 124.016 85.1455 124.048 85.2421C124.08 85.3386 124.131 85.4275 124.199 85.503C124.267 85.5785 124.35 85.6389 124.443 85.6803C124.536 85.7217 124.636 85.7432 124.738 85.7435H127.241C127.402 85.7436 127.558 85.6904 127.685 85.5925C127.812 85.4945 127.903 85.3571 127.944 85.2018L129.419 79.5839L132.077 69.4604C132.201 68.9892 132.211 68.4953 132.107 68.0195C132.002 67.5437 131.785 67.0997 131.475 66.7243C131.164 66.3489 130.769 66.0528 130.321 65.8606C129.873 65.6684 129.386 65.5855 128.9 65.6189L120.315 66.2073L120.914 63.8086L116.565 63.5201C115.991 64.3426 115.625 65.2921 115.498 66.2871C115.372 67.2821 115.488 68.293 115.838 69.2331Z'
      fill='#2F2E41'
    />
    <path
      d='M117.681 62.8413L117.623 62.8344L116.286 56.2597C116.275 56.2027 115.181 50.5317 118.533 47.567L118.591 47.1612C118.606 47.0574 118.643 46.958 118.7 46.87C118.757 46.7819 118.833 46.7073 118.921 46.6513C119.01 46.5952 119.11 46.5591 119.214 46.5453C119.318 46.5316 119.424 46.5405 119.524 46.5716L122.681 47.5508C122.86 47.6059 123.01 47.728 123.101 47.8915C123.192 48.055 123.216 48.2473 123.168 48.4281L122.861 49.5955C123.286 50.0952 128.296 56.1262 126.551 60.7725L125.769 63.8276L117.681 62.8413Z'
      fill='#FED20A'
    />
    <path
      d='M130.79 57.7101C130.714 57.7576 130.643 57.811 130.576 57.8697L122.854 55.797L122.35 53.9216L119.42 54.3134L119.991 57.7204C120.037 57.9956 120.171 58.2484 120.373 58.4409C120.575 58.6334 120.834 58.7552 121.111 58.7879L130.144 59.8549C130.287 60.1672 130.522 60.4283 130.818 60.6037C131.113 60.779 131.455 60.8602 131.798 60.8365C132.141 60.8128 132.468 60.6853 132.737 60.4709C133.006 60.2565 133.202 59.9655 133.302 59.6364C133.401 59.3074 133.397 58.956 133.292 58.629C133.186 58.3019 132.984 58.0147 132.711 57.8056C132.438 57.5965 132.109 57.4754 131.765 57.4583C131.422 57.4412 131.082 57.5291 130.79 57.7101H130.79Z'
      fill='#FFB8B8'
    />
    <path
      d='M122.557 55.7671L118.923 56.2351C118.82 56.2484 118.715 56.2393 118.615 56.2084C118.516 56.1774 118.424 56.1254 118.346 56.0558C118.269 55.9862 118.207 55.9007 118.166 55.8052C118.124 55.7096 118.103 55.6062 118.105 55.5019L118.175 51.8499C118.109 51.3206 118.257 50.787 118.585 50.3661C118.912 49.9453 119.393 49.6716 119.923 49.605C120.452 49.5384 120.986 49.6844 121.408 50.0109C121.829 50.3374 122.104 50.8179 122.172 51.3469L123.162 54.8505C123.191 54.9509 123.197 55.0561 123.181 55.1591C123.165 55.2621 123.127 55.3604 123.07 55.4474C123.012 55.5344 122.937 55.608 122.848 55.6631C122.76 55.7183 122.66 55.7537 122.557 55.7671Z'
      fill='#E5BD09'
    />
    <path
      d='M121.534 45.6792C123.721 45.6792 125.493 43.9065 125.493 41.7199C125.493 39.5332 123.721 37.7606 121.534 37.7606C119.347 37.7606 117.575 39.5332 117.575 41.7199C117.575 43.9065 119.347 45.6792 121.534 45.6792Z'
      fill='#FFB8B8'
    />
    <path
      d='M121.326 42.0505L120.83 41.1479C119.891 44.9736 121.55 48.1397 121.55 48.1397L115.112 45.1412L115.174 44.0773L114.537 44.8001L113.622 44.2958L113.501 43.6057L112.81 43.7768L115.332 39.2007C117.779 34.8461 121.337 36.5087 121.337 36.5087C126.998 36.2396 126.307 41.8244 126.307 41.8244L121.326 42.0505Z'
      fill='#2F2E41'
    />
  </SVG>
)
