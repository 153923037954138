import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

export const GlobalStyleReset = createGlobalStyle`
  ${reset}

  html, body {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
  }

  input, button {
    font-family: Roboto;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`
