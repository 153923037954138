import styled from 'styled-components'

export const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-right: ${props => props.theme.spaces.l};
`
