import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 373px;
  padding: 25px;
  padding-top: 80px;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(38, 15, 63, 0.08);
  border-radius: 6px;
  position: relative;
`

export const ChartContainer = styled(Container)`
  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    width: calc(100vw - (${props => props.theme.spaces.l} * 3));
    padding: ${props => props.theme.spaces.m};
    padding-top: 80px;
  }
`
