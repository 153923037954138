import styled from 'styled-components'
import { GrDrag } from 'react-icons/gr'

import { useResponsiveInfo } from 'providers'

type DirectoryEmptyContainerProps = {
  nodeType: 'employee' | 'department'
}

export const DirectoryEmptyContainer = ({ nodeType }: DirectoryEmptyContainerProps) => {
  const { isSmallDevice } = useResponsiveInfo()
  return (
    <TextContainer>
      <InfoContainer>
        <HeadingText>Your Directory is empty</HeadingText>
        <Text>Use your Directory to temporarily remove {nodeType}s from your Org Chart.</Text>
      </InfoContainer>
      <br />
      <Text>
        {isSmallDevice ? (
          `Move ${nodeType}s into your Org Chart using the 'Supervisor and department' field when editing Person`
        ) : (
          <>
            You can drag and drop {nodeType}s into your Directory by hovering your mouse over them until you see this
            symbol <StyledInlineGrDrag title='Drag &amp; drop' />.
          </>
        )}
      </Text>
    </TextContainer>
  )
}

const TextContainer = styled.div`
  max-width: 340px;
  margin-top: 30px;
`

const InfoContainer = styled.div`
  margin-top: 30px;

  &:first-of-type {
    margin-top: 10px;
  }
`

const HeadingText = styled.div`
  margin-bottom: 10px;
  color: ${props => props.theme.colors.grey};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

const Text = styled.div`
  color: ${props => props.theme.colors.grey};
  font-size: 13px;
  line-height: 20px;
`

const StyledInlineGrDrag = styled(GrDrag)`
  display: inline-block;

  path {
    stroke: ${props => props.theme.colors.border};
  }
`
