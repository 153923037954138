import globalConfig from 'config'

export const UNARIC_URL = 'https://www.unaric.com'

export const ORG_CHART_UNARIC = 'https://orgchart.unaric.com'
export const ORG_CHART_UNARIC_CONTACT_URL = 'https://orgchart.unaric.com/contact-us'
export const ORG_CHART_UNARIC_DPA_URL = 'https://orgchart.unaric.com/data-processing-agreement'
export const ORG_CHART_UNARIC_TERMS_CONDITIONS_URL = 'https://orgchart.unaric.com/terms-conditions'
export const ORG_CHART_UNARIC_PRIVACY_POLICY = 'https://orgchart.unaric.com/client-privacy-policy'

export const PRICING_PAGE_URI = globalConfig.PRICING_PAGE_URI
export const ORG_CHART_HELP_CENTER_URL = globalConfig.HELP_CENTER_URL

export const WORKSPACE_GOOGLE_MARKETPLACE_ORG_CHART_URL =
  'https://workspace.google.com/marketplace/app/org_chart_best_chart_builder/219218309058'

export const WHATSAPP_CLICK_TO_CHAT_LINK = 'https://wa.me'
