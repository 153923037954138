import { SuperiorField, HandleSetFieldValue } from './superior-field'
import { SuperiorFieldContainer } from './superior-field-container'

type Props = {
  chartUuid: string
  parentUuid?: string | null
  handleSetFieldValue: HandleSetFieldValue
}

export const SuperiorFieldCreate = ({ chartUuid, parentUuid = 'null', handleSetFieldValue }: Props) => {
  return !parentUuid || parentUuid === 'null' ? (
    // Creating Node from root (company)
    <SuperiorField
      chartUuid={chartUuid}
      moveToChart={false}
      node={null}
      parentUuid={parentUuid}
      createMode
      handleSetFieldValue={handleSetFieldValue}
    />
  ) : (
    // Creating Node from another Node
    <SuperiorFieldContainer chartUuid={chartUuid} parentUuid={parentUuid} handleSetFieldValue={handleSetFieldValue} />
  )
}
