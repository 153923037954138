import { useEffect } from 'react'

import { Loading } from 'components/generic'
import { AuthLayout } from 'components/layouts'

import { usePremiumScreenHandler } from 'features/premium'

export const PremiumPayScreen = () => {
  const { handlePremiumScreen } = usePremiumScreenHandler()

  useEffect(() => {
    handlePremiumScreen()
  }, [])

  return (
    <AuthLayout>
      <Loading size='big' />
    </AuthLayout>
  )
}
