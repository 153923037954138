import styled from 'styled-components'

import { AlertBar, Button } from 'components/generic'

import { useResyncChartAction } from 'tree/actions'
import { useChartData } from 'tree/providers'
import { spaces } from 'theme'

export const GWTokenRevokedBar = () => {
  const chartData = useChartData()
  const canUpdateChart = chartData.capabilities?.canUpdate
  // Use `uuid` only, don't use `id` (`id` on public link is `id` of link, not the chart)
  const chartUuid = chartData.uuid

  const { resyncChartWithConfirm } = useResyncChartAction()

  const type = 'error'
  const size = 'small'

  if (canUpdateChart) {
    return (
      <AlertBar
        type={type}
        size={size}
        title='Token revoked'
        ButtonComponent={
          <Button buttonSize='small' spacing={{ px: spaces.l }} onClick={() => resyncChartWithConfirm({ chartUuid })}>
            Connect
          </Button>
        }
        includeIcon
      >
        <Text>
          Your synchronization token has expired. Please reconnect to continue syncing your Org Chart with Google
          Workspace.
        </Text>
      </AlertBar>
    )
  }

  return (
    <AlertBar type={type} size={size} title='Attention!' includeIcon>
      <Text>
        The connection to Google Workspace is not synced right now. Please contact the owner or administrator of this
        organizational chart to re-connect the chart with Google Workspace. Thank you for using our Org Chart builder,
        and we apologize for any inconvenience.
      </Text>
    </AlertBar>
  )
}

const Text = styled.div`
  margin: 0 16px;
  margin-top: 8px;
  font-size: 14px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    margin-top: 0;
  }
`
