import { ReactNode } from 'react'
import styled from 'styled-components'

import { Modal, Button } from 'components/generic'

import { ModalTypeFactory, ModalComponentProps } from 'modal'
import { spaces } from 'theme'

type GenericOnErrorModalProps = ModalComponentProps & {
  title: string
  button?: ReactNode
  children?: ReactNode
}

export type GenericOnErrorModalType = ModalTypeFactory<'genericOnErrorModal', GenericOnErrorModalProps>

export const GenericOnErrorModal = ({ title, button, children, onClose }: GenericOnErrorModalProps) => {
  return (
    <Modal size='small' title={{ text: title }} onClose={onClose}>
      <Text>
        {children ? children : <>Refresh the page and try again. If the problem persists, please contact us.</>}
      </Text>
      {button ? (
        button
      ) : (
        <Button spacing={{ mt: spaces.l, ml: 'auto' }} onClick={onClose}>
          Ok, I understand
        </Button>
      )}
    </Modal>
  )
}

const Text = styled.div`
  color: ${props => props.theme.colors.grey};
  font-size: 13px;
  line-height: 20px;
`
