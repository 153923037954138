import styled from 'styled-components'

export const Container = styled.div<{ $compact: boolean }>`
  min-width: 0; /* fixes content being any width (based on text length) as it is set to auto due to flex parent (https://makandracards.com/makandra/66994-css-flex-and-min-width) */
  width: 100%;
  display: flex;
  ${props => !props.$compact && 'flex-direction: column;'}
  ${props => props.$compact && 'flex-wrap: wrap;'}
  align-items: ${props => (props.$compact ? 'flex-start' : 'center')};
`
