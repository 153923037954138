import { gql } from '@apollo/client'

export const personSearchDataFragment = gql`
  fragment PersonSearchDataFragment on Person {
    chartUuid
    id
    uuid
    type
    email
    image
    name
    position
    unassigned
    capabilities {
      canMove
    }
    parentNodes {
      ... on Department {
        id
        uuid
        expanded @client
      }
      ... on Person {
        id
        uuid
        expanded @client
      }
    }
    expanded @client
  }
`
