import { Prompt, PromptProps } from 'react-router-dom'

type Props = Pick<PromptProps, 'when'> & {
  message?: string
}

export const UnsavedPrompt = ({
  when,
  message = `If you leave before saving, your changes will be lost.\nAre you sure you want to leave?`,
}: Props) => <Prompt when={when} message={message} />
