export const barOptions = {
  legend: {
    display: false,
  },
  tooltips: {
    callbacks: {
      title: function () {
        return undefined
      },
      label: function (tooltipItem: any) {
        return tooltipItem.xLabel
      },
      footer: function (tooltipItem: any) {
        return `Count: ${tooltipItem[0].yLabel}`
      },
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          callback: function (value: string) {
            return value.length > 15 ? value.substr(0, 15).concat('...') : value
          },
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 6,
        },
      },
    ],
  },
  animation: {
    duration: 0,
  },
  maintainAspectRatio: false,
}

export const barDatasetAttrs = {
  maxBarThickness: 120,
}
