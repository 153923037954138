import { gql } from '@apollo/client'

export const departmentSearchDataFragment = gql`
  fragment DepartmentSearchDataFragment on Department {
    chartUuid
    id
    uuid
    type
    unassigned
    parentNodes {
      ... on Department {
        id
        uuid
        name
        color
        expanded @client
      }
      ... on Person {
        id
        uuid
        name
        expanded @client
      }
    }
    name
    color
    capabilities {
      canMove
    }
    expanded @client
  }
`
