import styled, { css } from 'styled-components'

export const Text = styled.div<{ $maxWidth?: string }>`
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.dark};

  ${props =>
    props.$maxWidth &&
    css`
      max-width: ${props.$maxWidth};
    `}
`
