import { CustomFieldPreview } from '../../components'
import { Card, SelectBoxButton } from '../../../components'
import { FlexBox } from 'components/generic'

import { CUSTOM_FIELD_OPTIONS } from 'tree/constants'
import { CustomFieldOption } from 'tree/types'

type Props = {
  selectedFieldOption?: CustomFieldOption
  onChange: (selectedFieldOption: CustomFieldOption) => void
}

export const SelectType = ({ selectedFieldOption, onChange }: Props) => (
  <Card title='Choose field type'>
    <FlexBox $gap='l' $isDirectionColumn>
      {CUSTOM_FIELD_OPTIONS.map(o => (
        <SelectBoxButton
          key={o.type}
          name={o.type}
          checked={selectedFieldOption?.type === o.type}
          onChange={() => onChange(o)}
        >
          <CustomFieldPreview
            icon={o.icon}
            squareColor={o.background}
            title={o.typeLabel}
            description={o.description}
          />
        </SelectBoxButton>
      ))}
    </FlexBox>
  </Card>
)
