import { lazy, Suspense, useEffect } from 'react'
import { ToastContainer } from 'react-toastify'

import { IntercomLauncher } from './IntercomLauncher'
import { GlobalProviders } from './GlobalProviders'
import { GlobalStyleReset } from './GlobalStyleReset'
import { Routes } from './Routes'

import analytics from 'analytics'
import { useAuth } from 'auth'
import { useInitAppVersionCheck } from 'hooks'
import { getIsSubscribed } from 'features/premium'

import 'react-toastify/dist/ReactToastify.css'

const ModalRenderer = lazy(() => import('modal/components/modal-renderer'))
const GlobalModalRegisters = lazy(() => import('./GlobalModalRegisters'))

export const ConfigureApp = () => {
  const { user } = useAuth()
  const { name, email, createdAt, subscription, plan } = user
  const isSubscribed = getIsSubscribed(subscription)

  useEffect(() => {
    const initAnalytics = () => {
      analytics.init({ name, email, createdAt, isSubscribed })
      analytics.setUserProperties({ subscriptionPlan: plan })
    }

    // Init Analytics and Re-Init on any dependency param change
    initAnalytics()

    // Re-Init Analytics on Cookie consent change
    document.addEventListener('cookieyes_consent_update', initAnalytics)
    return () => document.removeEventListener('cookieyes_consent_update', initAnalytics)
  }, [name, email, createdAt, isSubscribed, plan])

  useInitAppVersionCheck()

  // No console logs, warnings or errors on production
  if (process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line no-console
    console.log = console.warn = console.error = () => {}
  }

  return null
}

export const App = () => (
  <GlobalProviders>
    <ToastContainer position='bottom-right' />
    <ConfigureApp />
    <GlobalStyleReset />
    <Suspense fallback={null}>
      <ModalRenderer />
      <GlobalModalRegisters />
    </Suspense>
    <Routes />
    <IntercomLauncher />
  </GlobalProviders>
)
