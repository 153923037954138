import { ReactNode } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import { ModalWindow, ModalWindowSize, ModalTitle, ModalTitleProps } from './components'
import { IconButton, IconButtonProps } from '../icon-button'

import { CloseIcon } from 'components/icons'

import { addTransparencyToColor, modal } from 'helpers'
import { ModalComponentProps } from 'modal'

export type ModalProps = ModalComponentProps & {
  children: ReactNode | ReactNode[]
  className?: string
  whiteOverlay?: boolean
  size?: ModalWindowSize
  closeButton?: boolean
  closeButtonProps?: Omit<IconButtonProps, 'onClick'>
  title?: ModalTitleProps
}

export const Modal = ({
  className,
  whiteOverlay,
  size,
  closeButton = true,
  closeButtonProps,
  title,
  children,
  onClose,
}: ModalProps) => {
  return ReactDOM.createPortal(
    <ModalContainer>
      <Overlay whiteOverlay={whiteOverlay} />
      <ModalWindow className={className} size={size} onClose={onClose}>
        {closeButton && (
          <StyledIconButton size='small' {...closeButtonProps} onClick={onClose} data-testid='button-close-modal'>
            <CloseIcon />
          </StyledIconButton>
        )}
        {title && <ModalTitle size={title.size}>{title.text}</ModalTitle>}
        {children}
      </ModalWindow>
    </ModalContainer>,
    modal
  )
}

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  width: 100%;
  max-width: 600px;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.l};
  padding-top: calc(${props => props.theme.spaces.l} + ${props => props.theme.sizes.topNavigation});
  z-index: 199;
`

const Overlay = styled.div<{ whiteOverlay?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  background: ${props =>
    props.whiteOverlay
      ? addTransparencyToColor(props.theme.colors.white, 0.8)
      : addTransparencyToColor(props.theme.colors.dark, 0.3)};
  animation: ${props => props.theme.keyframes.opacity} ${props => props.theme.transitions.fastEase};
  z-index: 10;
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 16px;
  right: 16px;
`
