import { SuperiorField, HandleSetFieldValue } from './superior-field'
import { useNodeQuery } from 'apollo/generated/graphql'

type Props = {
  chartUuid: string
  parentUuid: string
  handleSetFieldValue: HandleSetFieldValue
}

export const SuperiorFieldContainer = ({ chartUuid, parentUuid, handleSetFieldValue }: Props) => {
  const { data } = useNodeQuery({ variables: { uuid: parentUuid, chartKey: chartUuid } })
  const node = data?.node

  if (node?.__typename !== 'Person' && node?.__typename !== 'Department') {
    return null
  }

  return (
    <SuperiorField
      chartUuid={chartUuid}
      moveToChart={false}
      node={node}
      parentUuid={parentUuid}
      createMode
      handleSetFieldValue={handleSetFieldValue}
    />
  )
}
