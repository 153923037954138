import styled, { css } from 'styled-components'
import { generatePath, Link } from 'react-router-dom'
import { useAuth } from 'auth'

import { ChartButton } from '../chart-button'
import { InsightsButton } from '../insights-button'
import {
  SettingsIcon,
  ShareIcon,
  QuestionMarkInCircleIcon,
  LogoutIcon,
  AccountPlusIcon,
  DownloadIcon,
  ProfileIcon,
  PaletteIcon,
  CustomFieldsIcon,
  DottedLineReportingFlatIcon,
  DuplicateChartIcon,
  GemIcon,
} from 'components/icons'

import { useShowComponents } from '../use-show-components'
import { useIsOnPublicRoute } from 'tree/hooks'
import { useChartId, useChartData } from 'tree/providers'

import { useManageSubscription } from 'hooks'
import { applySpacingProps, SpacingProps } from 'helpers'
import {
  getCanShowCustomBrandingView,
  getCanShowCustomFieldsView,
  getCanShowDottedLineView,
  getCanShowExportView,
  getCanShowGeneralView,
  getCanShowMembersView,
  getCanShowSharingView,
  useGetCanAccessSettings,
} from 'helpers/viewCapabilities'
import {
  SETTINGS_GENERAL_PATH,
  SETTINGS_MEMBERS_PATH,
  SETTINGS_SHARING_LINK_PATH,
  SETTINGS_DOWNLOAD_PATH,
  SETTINGS_CUSTOM_FIELDS_PATH,
  SETTINGS_BRANDING_PATH,
  SETTINGS_DOTTED_LINES_PATH,
} from 'routes'
import { ORG_CHART_HELP_CENTER_URL } from 'consts'

type Props = {
  editPageVisited: boolean
  onMenuClose: () => void
}

export const MobileMenu = ({ editPageVisited, onMenuClose }: Props) => {
  const chartUuid = useChartId(true) || ''
  const { user, logout } = useAuth()
  const { showChartButton, showInsightsButton, showCommonControls } = useShowComponents()
  const isOnPublicRoute = useIsOnPublicRoute()

  const { capabilities: c } = useChartData(true) || {}
  const { getCanAccessSettings } = useGetCanAccessSettings()
  const canShowSomeOfSettings = Boolean(chartUuid && c && getCanAccessSettings(c))
  const canShowCustomBrandingView = getCanShowCustomBrandingView()
  const { manageSubscription } = useManageSubscription()

  return (
    <Container>
      <Centering>
        {showChartButton && <MobileChartButton chartUuid={chartUuid} />}
        {showInsightsButton && <MobileInsightsButton chartUuid={chartUuid} editPageVisited={editPageVisited} />}
        {canShowSomeOfSettings && c && (
          <>
            <MenuSection>SETTINGS</MenuSection>

            {canShowCustomBrandingView && (
              <MenuItem>
                <StyledLink
                  to={{
                    pathname: generatePath(SETTINGS_BRANDING_PATH, { chartUuid }),
                    state: { from: window.location.pathname },
                  }}
                  onClick={onMenuClose}
                >
                  <IconWrapper>
                    <PaletteIcon />
                  </IconWrapper>
                  Customize
                </StyledLink>
              </MenuItem>
            )}
            {getCanShowCustomFieldsView(c, isOnPublicRoute) && (
              <MenuItem>
                <StyledLink
                  to={{
                    pathname: generatePath(SETTINGS_CUSTOM_FIELDS_PATH, { chartUuid }),
                    state: { from: window.location.pathname },
                  }}
                  onClick={onMenuClose}
                >
                  <IconWrapper>
                    <CustomFieldsIcon />
                  </IconWrapper>
                  Custom fields
                </StyledLink>
              </MenuItem>
            )}
            {getCanShowDottedLineView(c) && (
              <MenuItem>
                <StyledLink
                  to={{
                    pathname: generatePath(SETTINGS_DOTTED_LINES_PATH, { chartUuid }),
                    state: { from: window.location.pathname },
                  }}
                  onClick={onMenuClose}
                >
                  <IconWrapper>
                    <DottedLineReportingFlatIcon width='23px' height='100%' />
                  </IconWrapper>
                  Connection fields
                </StyledLink>
              </MenuItem>
            )}
            {getCanShowMembersView(c) && (
              <MenuItem>
                <StyledLink
                  to={{
                    pathname: generatePath(SETTINGS_MEMBERS_PATH, { chartUuid }),
                    state: { from: window.location.pathname },
                  }}
                  onClick={onMenuClose}
                >
                  <IconWrapper>
                    <AccountPlusIcon width='22px' />
                  </IconWrapper>
                  Members
                </StyledLink>
              </MenuItem>
            )}
            {getCanShowSharingView(c) && (
              <MenuItem>
                <StyledLink
                  to={{
                    pathname: generatePath(SETTINGS_SHARING_LINK_PATH, { chartUuid }),
                    state: { from: window.location.pathname },
                  }}
                  onClick={onMenuClose}
                >
                  <IconWrapper>
                    <ShareIcon />
                  </IconWrapper>
                  Sharable link
                </StyledLink>
              </MenuItem>
            )}
            {getCanShowExportView(c) && (
              <MenuItem>
                <StyledLink
                  to={{
                    pathname: generatePath(SETTINGS_DOWNLOAD_PATH, { chartUuid }),
                    state: { from: window.location.pathname },
                  }}
                  onClick={onMenuClose}
                >
                  <IconWrapper>
                    <DownloadIcon width='21px' height='auto' />
                  </IconWrapper>
                  Export Org Chart
                </StyledLink>
              </MenuItem>
            )}
            {c.canClone && (
              <MenuItem>
                <StyledLink
                  to={{
                    pathname: `${generatePath(SETTINGS_GENERAL_PATH, { chartUuid })}`,
                    state: { from: window.location.pathname, highlightEffect: true },
                  }}
                  onClick={onMenuClose}
                >
                  <IconWrapper>
                    <DuplicateChartIcon width='20px' />
                  </IconWrapper>
                  Duplicate Org Chart
                </StyledLink>
              </MenuItem>
            )}
            {getCanShowGeneralView(c) && (
              <MenuItem>
                <StyledLink
                  to={{
                    pathname: generatePath(SETTINGS_GENERAL_PATH, { chartUuid }),
                    state: { from: window.location.pathname },
                  }}
                  onClick={onMenuClose}
                >
                  <IconWrapper>
                    <SettingsIcon width='18px' height='auto' />
                  </IconWrapper>
                  General
                </StyledLink>
              </MenuItem>
            )}
            <HorizontalRuler />
          </>
        )}

        <MenuSection>{showCommonControls ? 'ACCOUNT' : 'GENERAL'}</MenuSection>
        {showCommonControls && (
          <MenuItem>
            <Email>
              <Circle>
                <ProfileIcon width='12px' />
              </Circle>
              {user.email}
            </Email>
          </MenuItem>
        )}
        {showCommonControls && (
          <MenuItem onClick={() => manageSubscription()}>
            <IconWrapper>
              <GemIcon width='20px' height='auto' />
            </IconWrapper>
            Manage Subscription
          </MenuItem>
        )}
        {ORG_CHART_HELP_CENTER_URL && (
          <MenuItem>
            <StyledLink to={{ pathname: ORG_CHART_HELP_CENTER_URL }} target='_blank'>
              <IconWrapper>
                <QuestionMarkInCircleIcon width='20px' height='auto' />
              </IconWrapper>
              Help center
            </StyledLink>
          </MenuItem>
        )}
        {showCommonControls && (
          <MenuItem onClick={() => logout()}>
            <IconWrapper>
              <LogoutIcon width='23px' height='auto' />
            </IconWrapper>
            Log out
          </MenuItem>
        )}
      </Centering>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: ${props => props.theme.sizes.topNavigationMobile};
  width: 100%;
  height: calc(100vh - ${props => props.theme.sizes.topNavigationMobile});
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.l};
  background: ${props => props.theme.colors.white};
  overflow: scroll;
`

const Centering = styled.div`
  max-width: 340px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
`

const StyledLink = styled(Link)`
  width: 100%;
  display: flex;
  align-items: center;
`

const MobileButtonsCSS = css`
  width: 100%;
  max-width: 340px;
  height: 44px;
  margin: 0 auto;
  margin-bottom: ${props => props.theme.spaces.m};
`

const MobileChartButton = styled(ChartButton)`
  ${MobileButtonsCSS}
`

const MobileInsightsButton = styled(InsightsButton)`
  ${MobileButtonsCSS}
`

const MenuSection = styled.div<{ $spacing?: SpacingProps }>`
  margin-top: ${props => props.theme.spaces.xl};
  margin-bottom: ${props => props.theme.spaces.l};
  color: #83869c;
  font-weight: 500;
  font-size: 13px;

  ${props => applySpacingProps(props.$spacing)}
`

const MenuItem = styled.div`
  display: flex;
  width: 100%;
  height: 44px;
  align-items: center;
  margin: ${props => props.theme.spaces.s} 0;
`

const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${props => props.theme.spaces.m};
`

const Circle = styled(IconWrapper)`
  width: 32px;
  height: 32px;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 50%;
`

const HorizontalRuler = styled.div`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.colors.greyExtraLight};
  margin-left: -${props => props.theme.spaces.l};
`

const Email = styled.div`
  display: flex;
  align-items: center;
`
