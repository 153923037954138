import { useState, DragEvent } from 'react'
import { DragElementWrapper, DragSourceOptions } from 'react-dnd'
import styled from 'styled-components'

import { HoverBackground, HoverEffect } from './hover-effect'
import { NodeMenu, NodeMenuProps } from '../node-menu'
import { DragIcon } from 'components/icons'

import { NodeTypename } from 'tree/types'
import { isColorDark } from 'helpers'
import { colors } from 'theme'

const ICON_DARK_COLOR = colors.greyMedium
const ICON_WHITE_COLOR = colors.white

const DRAG_COLOR = ICON_DARK_COLOR
const MENU_COLORS = {
  circle: colors.greyExtraLight,
  dot: ICON_DARK_COLOR,
}

type Props = Omit<NodeMenuProps, 'onClick'> & {
  nodeType: NodeTypename
  canDrag: boolean
  hoverColor?: string
  dragRef: DragElementWrapper<DragSourceOptions> | null
  onMenuClick?: () => void
  onDragStart?: (e: DragEvent<HTMLDivElement>) => void
  onDragEnd?: (e: DragEvent<HTMLDivElement>) => void
}

// FIXME: hover register blocks clicking on node (detail should open)
export const NodeActionsOverlay = (props: Props) => {
  const {
    canDrag,
    nodeType,
    hoverColor = colors.greyExtraLight,
    node,
    dragRef,
    onMenuClick,
    onDragStart,
    onDragEnd,
  } = props
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  // Active colors applies when: menu is opened / actions are hovered
  const areIconsWhite = isColorDark(hoverColor)
  const iconActiveColor = areIconsWhite ? ICON_WHITE_COLOR : ICON_DARK_COLOR
  const dragActiveColor = iconActiveColor
  const menuActiveColors = {
    circle: 'none',
    dot: iconActiveColor,
  }
  const menuCircleColor = isMenuOpen ? menuActiveColors.circle : MENU_COLORS.circle
  const menuDotColor = isMenuOpen ? menuActiveColors.dot : MENU_COLORS.dot

  return (
    <Container>
      {isMenuOpen && (
        <NodeMenuContainer onMouseLeave={() => setIsMenuOpen(false)} onClick={() => setIsMenuOpen(false)}>
          <NodeMenuRegister />
          <NodeMenu node={node} onClick={() => onMenuClick?.()} />
        </NodeMenuContainer>
      )}

      {canDrag && (
        <Drag
          $color={DRAG_COLOR}
          $activeColor={dragActiveColor}
          title='Drag &amp; Drop'
          ref={dragRef}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          data-testid={`drag-on-${nodeType.toLocaleLowerCase()}`}
        >
          <HoverEffect background={hoverColor} side='left' />
          <DragIcon height='14px' />
        </Drag>
      )}

      <Menu
        $activeCircleBackground={menuActiveColors.circle}
        $activeDotBackground={menuActiveColors.dot}
        title='Open node menu'
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <HoverEffect isActive={isMenuOpen} background={hoverColor} side='right' />
        <MenuCircle $background={menuCircleColor}>
          <DotContainer>
            <Dot $background={menuDotColor} />
            <Dot $background={menuDotColor} />
            <Dot $background={menuDotColor} />
          </DotContainer>
        </MenuCircle>
      </Menu>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  z-index: 6;
  pointer-events: none;
`

const NodeMenuContainer = styled.div`
  position: absolute;
  top: 0;
  left: calc(100% + 4px);
  pointer-events: auto;
`

const NodeMenuRegister = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: calc(35px + 100%);
  height: 100%;
  cursor: default;
`

const ACTION_GAP = '6px'

const NodeActionOverlay = styled.div`
  margin-top: ${ACTION_GAP};

  &:hover ${HoverBackground} {
    transform: translate(0);
  }
`

const Drag = styled(NodeActionOverlay)<{ $color: string; $activeColor: string }>`
  margin-left: ${ACTION_GAP};
  color: ${props => props.$color};
  cursor: move;

  &:hover {
    color: ${props => props.$activeColor};
  }
`

const MenuCircle = styled.div<{ $background: string }>`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -3px;
  margin-right: -3px;
  background: ${props => props.$background};
  border-radius: 50%;
  transition: ${props => props.theme.transitions.extraFastEase};
`

const DotContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
`

const Dot = styled.div<{ $background: string }>`
  width: 3px;
  height: 3px;
  background: ${props => props.$background};
  border-radius: 50%;
  transition: ${props => props.theme.transitions.extraFastEase};
`

const Menu = styled(NodeActionOverlay)<{ $activeCircleBackground: string; $activeDotBackground: string }>`
  margin-right: ${ACTION_GAP};
  margin-left: auto;
  cursor: pointer;

  &:hover ${MenuCircle} {
    background: ${props => props.$activeCircleBackground};
  }
  &:hover ${Dot} {
    background: ${props => props.$activeDotBackground};
  }
`
