import { DropdownIndicatorProps, ControlProps, PlaceholderProps, components, ClearIndicatorProps } from 'react-select'
import styled from 'styled-components'

import { InputClear } from '../input'
import { inputFieldCSS, inputPlaceholderCSS } from '../input/input-CSS'
import { RotatingChevron } from '../rotating-chevron'

import { LockIcon } from 'components/icons'
import { spaces } from 'theme'

const ClearIndicator = ({ innerProps, selectProps }: ClearIndicatorProps<any>) => {
  const { ref, onClick, ...restInnerProps } = innerProps
  const customOnClear = selectProps.customOnClear as undefined | (() => void)
  const customOnClick = (customOnClear ?? onClick) || (() => undefined)
  return <StyledInputClear {...restInnerProps} onClick={customOnClick} />
}
const StyledInputClear = styled(InputClear)`
  margin-right: 0;
`

const Control = ({ innerProps, innerRef, selectProps, children }: ControlProps<any>) => {
  const { isSearchable, isDisabled } = selectProps
  return (
    <ControlInner $isSearchable={isSearchable} $isDisabled={isDisabled} {...innerProps} ref={innerRef}>
      {children}
    </ControlInner>
  )
}
const ControlInner = styled.div<{ $isSearchable: boolean; $isDisabled: boolean }>`
  ${inputFieldCSS};
  display: flex;
  cursor: ${props => (props.$isSearchable ? 'text' : 'pointer')};
`

const DropdownIndicator = ({ selectProps: { menuIsOpen } }: DropdownIndicatorProps<any>) => (
  <StyledRotatingChevron direction={menuIsOpen ? 'top' : 'bottom'} />
)
const StyledRotatingChevron = styled(RotatingChevron)`
  cursor: pointer;
`

const Placeholder = ({ children, isDisabled, ...rest }: PlaceholderProps<any>) => (
  <StyledPlaceholder isDisabled={isDisabled} {...rest}>
    {isDisabled && <LockIcon />}
    {children}
  </StyledPlaceholder>
)
const StyledPlaceholder = styled(components.Placeholder)`
  display: flex;
  gap: ${spaces.m};

  && {
    ${inputPlaceholderCSS}
  }
`

export const selectComponents = {
  ClearIndicator,
  Control,
  DropdownIndicator,
  Placeholder,
}
