import { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { IconButton, Tooltip } from 'components/generic'
import { CloseIcon } from 'components/icons'

import { CHARTS_PATH } from 'routes'

export const CloseButton = () => {
  const [isTooltipShown, setIsTooltipShown] = useState(false)
  const history = useHistory()

  return (
    <StyledIconButton
      onMouseEnter={() => setIsTooltipShown(true)}
      onMouseLeave={() => setIsTooltipShown(false)}
      onClick={() => history.push(CHARTS_PATH)}
    >
      <CloseIcon />
      <StyledTooltip isTooltipShown={isTooltipShown}>Back to charts overview</StyledTooltip>
    </StyledIconButton>
  )
}

const StyledIconButton = styled(IconButton)`
  color: ${props => props.theme.colors.greyMediumLight};
`

const StyledTooltip = styled(Tooltip)`
  display: none;

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    display: block;
    min-width: 150px;
  }
`
