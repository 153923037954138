import { SVG, SVGProps } from '../SVG'

export const MicrosoftIcon = (props: SVGProps) => (
  <SVG width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M14.8377 21.0294C14.8742 20.7989 14.8837 4.12668 14.8488 3.7628C12.0159 4.43754 9.18726 5.1117 6.3469 5.78831V17.541C4.88841 18.1111 3.4298 18.6808 1.97107 19.2502C1.96171 19.245 1.95375 19.2421 1.94614 19.2367C1.9397 19.232 1.93151 19.225 1.93092 19.2186C1.92624 19.1746 1.92051 19.1306 1.92051 19.0859C1.91992 14.3371 1.91992 9.58771 1.91992 4.8507C1.98511 4.77029 2.06938 4.76022 2.14265 4.7333C6.3057 3.17431 10.47 1.61684 14.6335 0.0578516C14.7777 0.00377849 14.9086 -0.0226729 15.0685 0.0243778C17.353 0.687417 19.6398 1.34285 21.9259 2.00062C21.959 2.01069 21.9923 2.02239 22.0393 2.03761V22.0533C21.3829 22.2358 20.7322 22.4183 20.081 22.5978C18.4233 23.055 16.7646 23.5086 15.108 23.9692C14.9458 24.0144 14.804 24.0108 14.6445 23.9522C10.5496 22.4389 6.45282 20.9302 2.35719 19.4211C2.27643 19.3911 2.19719 19.3576 2.12919 19.2855C6.3655 19.867 10.6019 20.4479 14.8377 21.0294Z'
      fill='#DC3E15'
    />
  </SVG>
)
