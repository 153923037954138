import { useMemo } from 'react'
import styled from 'styled-components'
import { Switch, NavLink, useLocation, useHistory, Redirect, useParams, generatePath } from 'react-router-dom'

import { NavlinkTitle, navlinkCSS } from './components'
import { TopNavigation } from 'components/specific'
import { Badge, SidePanel } from 'components/generic'
import { WebLayout } from 'components/layouts'
import { ChartForbiddenScreen } from '../chart/screens'
import { getIsSubscribed, ProBadge } from 'features/premium'

import { isUuid, scrollToTop } from 'helpers'
import { useResponsiveInfo } from 'providers'
import {
  ChartConfigSafeProvider,
  ChartDataContextSafeProvider,
  ChartIdContextSafeProvider,
  useChartData,
  useChartId,
} from 'tree/providers'
import { IsOnPublicRouteContext, useChartMapper, useChartPermission, useIsOnPublicRoute } from 'tree/hooks'
import {
  getCanAccessSharing,
  getCanShowCustomFieldsView,
  getCanShowDottedLineView,
  getCanShowExportView,
  getCanShowGeneralView,
  getCanShowMembersView,
  getCanShowSharingView,
  getCanShowCustomBrandingView,
  useGetCanAccessSettings,
} from 'helpers/viewCapabilities'
import {
  CHARTS_PATH,
  RenderRoutes,
  SETTINGS_BRANDING_PATH,
  SETTINGS_CUSTOM_FIELDS_PATH,
  SETTINGS_DOTTED_LINES_PATH,
  SETTINGS_DOWNLOAD_PATH,
  SETTINGS_GENERAL_PATH,
  SETTINGS_MEMBERS_PATH,
  SETTINGS_ROUTES,
  SETTINGS_SHARING_LINK_PATH,
} from 'routes'
import analytics from 'analytics'
import { CUSTOM_FIELDS_TRACK } from 'analytics/constants'

import { spaces } from 'theme'

export const SettingsScreen = () => (
  <ChartConfigSafeProvider>
    <SettingsScreenWithProviders />
  </ChartConfigSafeProvider>
)
const SettingsScreenWithProviders = () => {
  const { chartUuid } = useParams<{ chartUuid: string }>()
  const { loading, data, error } = useChartMapper(chartUuid)
  const isOnPublicRoute = !isUuid(chartUuid)

  // TODO: Routes - Rewrite /chart/:chartUuid route to return single component handler for edit/setings/insights
  // https://app.clickup.com/t/u3x69b
  if (error) return <ChartForbiddenScreen />
  if (loading) return null
  if (!data) return <div>Error, no data!</div>

  return (
    <IsOnPublicRouteContext.Provider value={isOnPublicRoute}>
      <ChartIdContextSafeProvider value={chartUuid}>
        <ChartDataContextSafeProvider value={data}>
          <SettingsScreenWithData />
        </ChartDataContextSafeProvider>
      </ChartIdContextSafeProvider>
    </IsOnPublicRouteContext.Provider>
  )
}

const SettingsScreenWithData = () => {
  const chartUuid = useChartId()
  const { isSmallDevice } = useResponsiveInfo()
  const isOnPublicRoute = useIsOnPublicRoute()

  const { state: locationState } = useLocation<{ from?: string }>()
  const initialPrevPath = useMemo(() => locationState?.from || CHARTS_PATH, [])
  const history = useHistory()
  const handleGoBack = () => history.push(initialPrevPath)

  const { public: hasPublicLink, capabilities: c, subscription } = useChartData()
  const { isLoading: isLoadingPermissions } = useChartPermission()
  const { getCanAccessSettings } = useGetCanAccessSettings()

  const isSubscribed = getIsSubscribed(subscription)

  const handleClickCustomFields = () => {
    analytics.track(CUSTOM_FIELDS_TRACK.click, { chartUuid, from: 'Settings' })
    scrollToTop()
  }

  if (isLoadingPermissions || !c) return null

  return (
    <WebLayout>
      <TopNavigation />
      <Container>
        {!isSmallDevice && (
          <SidePanel title='Settings' onGoBack={handleGoBack}>
            {getCanShowGeneralView(c) && (
              <GeneralNavlink onClick={scrollToTop} to={generatePath(SETTINGS_GENERAL_PATH, { chartUuid })}>
                General
              </GeneralNavlink>
            )}
            {getCanShowCustomFieldsView(c, isOnPublicRoute) && (
              <StyledNavlink
                onClick={handleClickCustomFields}
                to={generatePath(SETTINGS_CUSTOM_FIELDS_PATH, { chartUuid })}
              >
                Custom fields {!isSubscribed && <StyledProBadge />}
              </StyledNavlink>
            )}
            {getCanShowDottedLineView(c) && (
              <StyledNavlink onClick={scrollToTop} to={generatePath(SETTINGS_DOTTED_LINES_PATH, { chartUuid })}>
                Connection fields {!isSubscribed && <StyledProBadge />}
              </StyledNavlink>
            )}
            {getCanShowCustomBrandingView() && (
              <BrandingNavlink onClick={scrollToTop} to={generatePath(SETTINGS_BRANDING_PATH, { chartUuid })}>
                Customize
              </BrandingNavlink>
            )}

            {getCanAccessSharing(c) && <NavlinkTitle>Sharing</NavlinkTitle>}
            {getCanShowMembersView(c) && (
              <StyledNavlink onClick={scrollToTop} to={generatePath(SETTINGS_MEMBERS_PATH, { chartUuid })}>
                Members
              </StyledNavlink>
            )}
            {getCanShowSharingView(c) && (
              <StyledNavlink onClick={scrollToTop} to={generatePath(SETTINGS_SHARING_LINK_PATH, { chartUuid })}>
                Sharable link
                {hasPublicLink && (
                  <Badge $size='small' $color='purple' $spacing={{ ml: spaces.s }}>
                    ON
                  </Badge>
                )}
                {!isSubscribed && <StyledProBadge />}
              </StyledNavlink>
            )}

            {getCanShowExportView(c) && (
              <>
                <NavlinkTitle>Export</NavlinkTitle>
                <StyledNavlink onClick={scrollToTop} to={generatePath(SETTINGS_DOWNLOAD_PATH, { chartUuid })}>
                  Export Org Chart {!isSubscribed && <StyledProBadge />}
                </StyledNavlink>
              </>
            )}
          </SidePanel>
        )}

        <Content>
          <Switch>
            {/* If can't access any of settings routes, redirect to charts */}
            {!(getCanAccessSettings(c) || getCanAccessSharing(c)) && <Redirect to={CHARTS_PATH} />}
            {/* If can't access specific route, redirect to closest one */}
            {!getCanShowGeneralView(c) && <Redirect from={SETTINGS_GENERAL_PATH} to={SETTINGS_CUSTOM_FIELDS_PATH} />}
            {!getCanShowCustomFieldsView(c, isOnPublicRoute) && (
              <Redirect from={SETTINGS_CUSTOM_FIELDS_PATH} to={SETTINGS_DOTTED_LINES_PATH} />
            )}
            {!getCanShowDottedLineView(c) && <Redirect from={SETTINGS_DOTTED_LINES_PATH} to={SETTINGS_BRANDING_PATH} />}
            {!getCanShowCustomBrandingView() && <Redirect from={SETTINGS_BRANDING_PATH} to={SETTINGS_DOWNLOAD_PATH} />}
            {!getCanShowExportView(c) && <Redirect from={SETTINGS_DOWNLOAD_PATH} to={SETTINGS_MEMBERS_PATH} />}
            {!getCanShowMembersView(c) && <Redirect from={SETTINGS_MEMBERS_PATH} to={SETTINGS_SHARING_LINK_PATH} />}
            {!getCanShowSharingView(c) && <Redirect from={SETTINGS_SHARING_LINK_PATH} to={SETTINGS_GENERAL_PATH} />}
            {RenderRoutes(SETTINGS_ROUTES)}
          </Switch>
        </Content>
      </Container>
    </WebLayout>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1048px;
  min-height: calc(100vh - ${props => props.theme.sizes.topNavigation});
  height: 100%;
  display: flex;
  margin: 0 auto;
  color: ${props => props.theme.colors.dark};
  user-select: none;
`

const Content = styled.div`
  position: relative;
  min-width: 320px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const StyledNavlink = styled(NavLink)`
  ${navlinkCSS}
`

const GeneralNavlink = styled(NavLink)`
  ${navlinkCSS}
  margin-top: ${props => props.theme.spaces.l};
  margin-bottom: 0;
`

const StyledProBadge = styled(ProBadge)`
  margin-left: ${props => props.theme.spaces.s};
`

const BrandingNavlink = styled(NavLink)`
  ${navlinkCSS}
  margin-bottom: 0;
`
