import { CSSProperties } from 'styled-components'
import { generatePath, Link } from 'react-router-dom'

import { Button } from 'components/generic'
import { OrgchartStandardStructureIcon } from 'components/icons'

import { useIsOnPublicRoute } from 'tree/hooks'
import { CHART_EDIT_PATH, SHARED_LINK_PATH } from 'routes'
import { spaces } from 'theme'

type Props = {
  chartUuid: string
  className?: string
  style?: CSSProperties
}

export const ChartButton = ({ chartUuid, className, style }: Props) => {
  const isOnPublicRoute = useIsOnPublicRoute()
  const pathname = isOnPublicRoute
    ? generatePath(SHARED_LINK_PATH, { code: chartUuid })
    : generatePath(CHART_EDIT_PATH, { chartUuid })

  return (
    <Link to={{ pathname, state: { from: window.location.pathname } }}>
      <Button
        className={className}
        style={style}
        buttonSize='small'
        leftEl={<OrgchartStandardStructureIcon $spacing={{ mr: spaces.m }} />}
      >
        Org Chart
      </Button>
    </Link>
  )
}
