import { SVG, SVGProps } from './SVG'

export const DoubleArrowsUpIcon = (props: SVGProps) => (
  <SVG width='10' height='10' viewBox='0 0 10 10' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5 6.1225L1.5575 9.5575L0.5 8.5L5 4L9.5 8.5L8.4425 9.5575L5 6.1225ZM5 2.1225L1.5575 5.5575L0.5 4.5L5 -4.76837e-06L9.5 4.5L8.4425 5.5575L5 2.1225Z'
      fill='currentColor'
    />
  </SVG>
)
