import { gql } from '@apollo/client'

export const personDataFragment = gql`
  fragment PersonDataFragment on Person {
    employeeCount
    departmentCount
    chartUuid
    unassigned
    deleted
    capabilities {
      canUpdate
      canMove
      canDelete
      canUnassign
    }
    parentNodes {
      ... on Department {
        id
        name
        uuid
        color
        expanded @client
      }
      ... on Person {
        id
        name
        uuid
        expanded @client
      }
    }
    customFields {
      ... on CustomDropdownFieldValue {
        id
        type
        value
      }
      ... on CustomConnectionFieldValue {
        id
        type
        values
        nodes {
          ... on Person {
            id
            familyName
            givenName
          }
        }
      }
    }
    image
    name
    givenName
    familyName
    position
    email
    emailAliases
    uuid
    id
    parentUuid
    type
    teamSizeTest
    xLink
    linkedInLink
    facebookLink
    youTubeLink
    instagramLink
    whatsAppLink
    expanded @client
  }
`
