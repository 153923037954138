import { SVG, SVGProps } from '../SVG'

export const WebexIcon = (props: SVGProps) => (
  <SVG width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M20.208 12.1263C20.208 16.7308 16.476 20.4632 11.8721 20.4632C7.26811 20.4632 3.53613 16.7308 3.53613 12.1263C3.53613 7.52185 7.26811 3.78947 11.8721 3.78947C16.476 3.78947 20.208 7.52185 20.208 12.1263Z'
      fill='white'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.9435 5.0107C18.1293 4.18839 17.2053 3.54089 16.2189 3.06519C17.9954 6.73137 17.3622 11.2684 14.314 14.3106C11.2661 17.3523 6.72097 17.9843 3.04785 16.2126C3.50784 17.1618 4.12672 18.0538 4.9103 18.8456L4.99543 18.931C8.84688 22.7747 15.0918 22.7747 18.9435 18.931C22.7949 15.0869 22.7949 8.85474 18.9435 5.0107Z'
      fill='#6EBE4A'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.04514 7.24678C4.48436 7.24678 3.92383 7.03331 3.49617 6.60636C2.6406 5.75272 2.6406 4.36855 3.49617 3.51491C5.76733 1.2483 8.78671 4.57764e-05 11.9986 4.57764e-05C13.2085 4.57764e-05 14.1889 0.978741 14.1889 2.18632C14.1889 3.39365 13.2085 4.37259 11.9986 4.37259C9.95703 4.37259 8.03774 5.16586 6.59411 6.60636C6.16645 7.03331 5.60592 7.24678 5.04514 7.24678Z'
      fill='#D541D8'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.3789 0.0350494C13.4072 0.214923 14.1893 1.10848 14.1893 2.18621C14.1893 3.30158 13.3519 4.22014 12.2705 4.35379C16.3733 4.49753 19.6673 7.87092 19.6673 11.9999C19.6673 10.8065 20.6368 9.83918 21.8323 9.83918C23.0282 9.83918 23.9977 10.8065 23.9977 11.9999C23.9977 5.52448 18.8195 0.236649 12.3789 0.0350494Z'
      fill='#FBAB18'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.8316 9.83922C20.636 9.83922 19.6665 10.8065 19.6665 12C19.6665 16.129 16.3726 19.5024 12.2695 19.6461C13.3509 19.78 14.1883 20.6986 14.1883 21.814C14.1883 22.8917 13.4063 23.7852 12.3782 23.9651C18.8185 23.7635 23.9967 18.4757 23.9967 12C23.9967 10.8065 23.0272 9.83922 21.8316 9.83922Z'
      fill='#005073'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.40402 6.77408C6.00693 7.08835 5.52647 7.24675 5.04526 7.24675C4.48448 7.24675 3.92395 7.03328 3.49629 6.60633C2.74227 5.85399 2.65386 4.68987 3.22904 3.83951C1.22791 5.9798 0 8.84869 0 12C0 15.1513 1.22816 18.0205 3.22954 20.161C2.65386 19.3107 2.74202 18.146 3.49629 17.3934C4.29149 16.6002 5.54441 16.5454 6.40377 17.2257C5.11928 15.8567 4.33039 14.019 4.33039 12C4.33039 9.98073 5.11928 8.14309 6.40402 6.77408Z'
      fill='#FF3D67'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.9994 24C8.7875 24 5.76787 22.7518 3.49696 20.4851C2.64164 19.6312 2.64164 18.2471 3.49696 17.3934C4.35278 16.5395 5.74008 16.54 6.59489 17.3934C8.03853 18.8342 9.95781 19.6275 11.9994 19.6275C13.2093 19.6275 14.1897 20.6064 14.1897 21.814C14.1897 23.0211 13.2093 24 11.9994 24Z'
      fill='#00BCEB'
    />
  </SVG>
)
