import { useMemo } from 'react'

import { personMapper } from './use-person'
import { departmentMapper } from './use-department'
import { useChartId } from '../providers/use-chart-uuid'
import { useChartData } from '../providers/use-chart-data'
import { Department, Person } from '../types'
import { useAuth } from 'auth'
import { useChartConfig } from 'tree/providers'

import { useNodesQuery } from 'apollo/generated/graphql'

export const useNodes = (parentUuid: string | null) => {
  const chartUuid = useChartId()
  const { data, loading, error } = useNodesQuery({
    variables: { chartKey: chartUuid, filter: { unassigned: false, parentUuid: parentUuid || null } },
  })
  const chart = useChartData()
  const { config: chartConfig } = useChartConfig()
  const { user } = useAuth()
  const authEmail = user.email

  const memoizedConvertedNodes: (Department | Person)[] = useMemo(() => {
    return (data?.nodes?.items || []).map(node => {
      if (node.__typename === 'Person') return personMapper({ authEmail, chart, chartConfig, person: node })
      else if (node.__typename === 'Department') return departmentMapper({ chartConfig, department: node })
      return node
    })
  }, [data?.nodes?.items, authEmail, chart, chartConfig])

  return {
    nodes: memoizedConvertedNodes,
    loading,
    error,
  }
}
