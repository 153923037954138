import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { generatePath, useRouteMatch } from 'react-router-dom'

// relative imports - insights related
import { SpanOfControlTable } from '../components/widgets/SpanOfControlTable'
import {
  TrendKpi,
  BarChart,
  Heading,
  Widgets,
  Row,
  LineChart,
  CompanyOverviewTable,
  TilAd,
  TilAdCalendar,
  customStyles,
  InfoBox,
} from '../components'
import {
  datasetToBarChart,
  datasetToKpi,
  datasetToLineChart,
  datasetToOverviewTable,
  datasetToSpanOfControlTable,
} from '../models'
import { WITHOUT_DEPARTMENT_UUID, kpiLabels, WITHOUT_DEPARTMENT_LABEL } from '../constants'
import { DepFilter } from '../types'
import { CompanyOverviewProvider, useInsights, useInsightsState } from '../hooks'
import { parseLabel } from '../utils'

// global imports - app related
import { InfoBoxButtonGoogleCalendar } from 'components/specific'
import { AccountPlusIcon, AccountMinusIcon, AccountGroupIcon, SyncIcon } from 'components/icons'

import { useResponsiveInfo } from 'providers'
import { useGoogleWorkspace } from 'tree/hooks'
import { useChartId } from 'tree/providers'
import analytics from 'analytics'
import { INSIGHTS_OVERVIEW_CONNECT_GW_PATH } from 'routes'
import { mainTheme } from 'theme'
import { INSIGHTS_TRACK } from 'analytics/constants'
import { dropdownMenu } from 'helpers'

export const CompanyOverview = () => {
  const chartUuid = useChartId()
  const { data } = useInsights(chartUuid)
  const { actualDataIndex, actualMonthIndex, askForCalendarPermission, isComputingInsights } = useInsightsState()
  const { authorize } = useGoogleWorkspace()
  const { params } = useRouteMatch<Record<string, string>>()
  const { isSmallDevice, isBigDevice } = useResponsiveInfo()

  const allDepartments = { value: chartUuid, label: 'All departments' }
  const [actualDepartments, setActualDepartments] = useState<DepFilter[]>([allDepartments])
  const [activeDep, setActiveDep] = useState<DepFilter>(allDepartments)

  useEffect(() => {
    const { departments } = data[actualDataIndex]
    const depOptions = departments ? departments.map(dep => ({ value: dep.uuid, label: dep.name })) : []
    depOptions.sort((a, b) => a.label.localeCompare(b.label))
    depOptions.unshift({ value: WITHOUT_DEPARTMENT_UUID, label: WITHOUT_DEPARTMENT_LABEL })
    depOptions.unshift(allDepartments)
    setActualDepartments(depOptions)
  }, [actualDataIndex])

  const dataActual = data[actualDataIndex].datasets
  const dataPrevious = actualDataIndex > 0 ? data[actualDataIndex - 1]?.datasets : undefined
  const {
    headcountByDepartment,
    joinersByDepartment,
    leaversByDepartment,
    turnoverByDepartment,
    headcountMonthly,
    joinersMonthly,
    leaversMonthly,
    spanOfControl,
  } = dataActual

  const lineChartData = datasetToLineChart([headcountMonthly, joinersMonthly, leaversMonthly], chartUuid)
  const displayLineChart = Number(lineChartData?.labels.length) >= 3
  const reportStartDay = new Date(data[actualDataIndex].reportInfo.dateFrom).getDate()

  return (
    <CompanyOverviewProvider value={{ activeDep }}>
      <StyledHeading>Company Insights</StyledHeading>

      {reportStartDay !== 1 && (
        <InfoBox isAdaptive={isBigDevice}>
          Data in this report are not from full month. Data starts from the day {reportStartDay}
        </InfoBox>
      )}

      {isComputingInsights && (
        <InfoBox type='til'>
          Your company data for this month is being processed, this may take several hours. Please try again later.
        </InfoBox>
      )}

      {askForCalendarPermission && !isComputingInsights && (
        <InfoBox type='til'>
          Share your Google Calendar and unlock more features.
          <InfoBoxButtonGoogleCalendar
            size='small'
            redirectPath={generatePath(INSIGHTS_OVERVIEW_CONNECT_GW_PATH, params)}
            authorize={authorize}
            spacing={{ m: `0 ${mainTheme.spaces.l}` }}
            outlineIsOnDark
          />
        </InfoBox>
      )}

      <Widgets>
        <KpiSelectContainer>
          <KpiSelectText>Select department</KpiSelectText>
          {isSmallDevice && <KpiSubSelectText>(influencing only 4 metrics bellow)</KpiSubSelectText>}
          <Select
            menuPosition='fixed'
            menuPortalTarget={dropdownMenu} // Portaled due to Safari bug which hides fixed positioned element if their parent has overflow hidden -> https://bugs.webkit.org/show_bug.cgi?id=160953
            value={activeDep}
            onChange={option => {
              setActiveDep(option as DepFilter)
              analytics.track(INSIGHTS_TRACK.departmentSelect, { chartUuid })
            }}
            options={actualDepartments}
            getOptionLabel={option => parseLabel(option.label as string).toString()}
            getOptionValue={option => option.value as string}
            styles={customStyles}
          />
        </KpiSelectContainer>
        <KpiRow>
          <TrendKpi
            value={datasetToKpi(activeDep, headcountByDepartment)}
            previousValue={datasetToKpi(activeDep, dataPrevious?.headcountByDepartment)}
            icon={<AccountGroupIcon />}
            title={kpiLabels.headcountKpi}
          />
          <TrendKpi
            value={datasetToKpi(activeDep, joinersByDepartment)}
            previousValue={datasetToKpi(activeDep, dataPrevious?.joinersByDepartment)}
            icon={<AccountPlusIcon />}
            title={kpiLabels.joiners}
          />
          <TrendKpi
            value={datasetToKpi(activeDep, leaversByDepartment)}
            previousValue={datasetToKpi(activeDep, dataPrevious?.leaversByDepartment)}
            icon={<AccountMinusIcon />}
            title={kpiLabels.leavers}
          />
          <TrendKpi
            value={datasetToKpi(activeDep, turnoverByDepartment)}
            previousValue={datasetToKpi(activeDep, dataPrevious?.turnoverByDepartment)}
            icon={<SyncIcon />}
            valType='percent'
            title={kpiLabels.turnover}
          />
        </KpiRow>
        <HrLine />
      </Widgets>
      <Widgets>
        <BarChart
          heading='Headcount per department'
          chartUuid={chartUuid}
          data={datasetToBarChart(headcountByDepartment, chartUuid)}
        />
      </Widgets>
      {displayLineChart && (
        <Widgets>
          <LineChart heading='Company growth' data={lineChartData} />
        </Widgets>
      )}
      {askForCalendarPermission && (
        <Widgets>
          <TilAdCalendar />
        </Widgets>
      )}
      <Widgets>
        <TableRow>
          <CompanyOverviewTable
            title='Joiners per department'
            rows={datasetToOverviewTable(joinersByDepartment, dataPrevious?.joinersByDepartment, chartUuid)}
            valueColLabel='Joiners'
            activeMonth={actualMonthIndex}
            chartUuid={chartUuid}
          />
          <CompanyOverviewTable
            title='Leavers per department'
            rows={datasetToOverviewTable(leaversByDepartment, dataPrevious?.leaversByDepartment, chartUuid)}
            valueColLabel='Leavers'
            activeMonth={actualMonthIndex}
            chartUuid={chartUuid}
          />
        </TableRow>
      </Widgets>

      <Widgets>
        <SpanAdRow>
          <SpanOfControlTable
            title='Direct reports'
            rows={datasetToSpanOfControlTable(spanOfControl)}
            chartUuid={chartUuid}
          />
          <TilAd chartUuid={chartUuid} />
        </SpanAdRow>
      </Widgets>
    </CompanyOverviewProvider>
  )
}

const TableRow = styled(Row)`
  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    flex-direction: column;
    & > div {
      margin-bottom: 30px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`

const SpanAdRow = styled(Row)`
  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    flex-direction: column;
    left: 50%;
    & > div {
      margin-bottom: 30px;
    }
  }
`

const KpiRow = styled(Row)`
  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    flex-wrap: wrap;
    justify-content: space-evenly;
    & > div {
      margin: ${props => props.theme.spaces.s} 0;
    }
  }
`

const KpiSelectContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const KpiSelectText = styled.p`
  margin-right: 12px;
  font-size: 16px;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    margin-top: 0;
  }
`

const KpiSubSelectText = styled.p`
  color: ${props => props.theme.colors.greyLight};
  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    font-size: 13px;
    margin-bottom: ${props => props.theme.spaces.m};
  }
`

const HrLine = styled.div`
  height: 1px;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 2px;
  background-color: #e6e7ed;
`

const StyledHeading = styled(Heading)`
  display: flex;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    margin-bottom: ${props => props.theme.spaces.l};
  }
`
