import { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { TableBody, TableDescIcon, TableAscIcon, TableRow, TableCol } from './styled/table'
import { WidgetHeading, WidgetContainer } from '../General'
import { ChartPlaceholder } from '../charts'
import { Label } from '../styled'
import { AvatarCircleMiniature } from 'components/specific/avatar-circle-miniature'
import { SearchIcon, CloseIcon } from 'components/icons'
import avatarPlaceholderSrc from 'assets/avatar-icon.jpg'

import { useInsightsState } from '../../hooks'
import { useResponsiveInfo } from 'providers'
import { SpanOfControlTableRow } from '../../types'
import { sortNumbers, sortStrings, normalizeString, removeAccents } from 'helpers'
import analytics from 'analytics'
import { mainTheme } from 'theme'
import { INSIGHTS_TRACK } from 'analytics/constants'

type Props = {
  title: string
  rows: SpanOfControlTableRow[]
  chartUuid?: string
}

type RowsType = Omit<SpanOfControlTableRow, 'personIcon'>
type ColType = keyof RowsType

export const SpanOfControlTable = ({ title, rows, chartUuid }: Props) => {
  const { setIsTyping } = useInsightsState()
  const [tableRows, setRows] = useState(rows)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [sortedBy, setSortedBy] = useState<ColType>('subordinatesCount')
  const [isDesc, setIsDesc] = useState<boolean>(true)
  const { isSmallDevice } = useResponsiveInfo()

  useEffect(() => {
    sortAndSetRows(sortedBy, rows)
  }, [rows])

  useEffect(() => {
    if (searchQuery) {
      setRows(
        [...rows].filter(row =>
          removeAccents(normalizeString(row.personName)).includes(removeAccents(normalizeString(searchQuery)))
        )
      )
      setIsTyping(true)
    } else {
      setRows(rows)
      setIsTyping(false)
    }
  }, [searchQuery])

  const sortAndSetRows = (type: ColType, rows?: SpanOfControlTableRow[]) => {
    const isDescActual = rows ? isDesc : sortedBy === type ? !isDesc : true
    setRows(sortRows(rows ?? tableRows, type, isDescActual))
    setIsDesc(isDescActual)
    setSortedBy(type)
  }

  const sortArrow = isDesc ? <TableDescIcon /> : <TableAscIcon />
  const colTitle = 'Click to sort according to this column'

  if (!rows || !rows.length || !tableRows) return <ChartPlaceholder />

  return (
    <StyledWidgetContainer>
      <WidgetHeading>
        {title}
        <SearchPeopleInputWrapper>
          <StyledSearchIcon />
          <SearchPeopleInput
            type='text'
            onChange={e => {
              setSearchQuery(e.target.value)
              if (e.target.value.length > 3) {
                analytics.track(INSIGHTS_TRACK.spanOfControlSearch, { chartUuid, searchedText: e.target.value })
              }
            }}
            value={searchQuery}
            placeholder='Search for person here'
          />
          {searchQuery && (
            <div onClick={() => setSearchQuery('')}>
              <StyledCloseIcon />
            </div>
          )}
        </SearchPeopleInputWrapper>
      </WidgetHeading>
      <StyledTableRow isHeader>
        <StyledTableCol
          onClick={() => {
            sortAndSetRows('personName')
            analytics.track(INSIGHTS_TRACK.spanOfControlSortByPersonName, { chartUuid })
          }}
          isActive={sortedBy === 'personName'}
          title={colTitle}
          isHeader
        >
          Supervisor{sortedBy === 'personName' && sortArrow}
        </StyledTableCol>
        <StyledTableCol
          onClick={() => {
            sortAndSetRows('dep')
            analytics.track(INSIGHTS_TRACK.spanOfControlSortDepartment, { chartUuid })
          }}
          isActive={sortedBy === 'dep'}
          title={colTitle}
          isHeader
        >
          Department{sortedBy === 'dep' && sortArrow}
        </StyledTableCol>
        <StyledTableCol
          onClick={() => {
            sortAndSetRows('subordinatesCount')
            analytics.track(INSIGHTS_TRACK.spanOfControlSortSubCount, { chartUuid })
          }}
          isActive={sortedBy === 'subordinatesCount'}
          title={colTitle}
          isHeader
        >
          {sortedBy === 'subordinatesCount' && sortArrow}Number of team members
        </StyledTableCol>
      </StyledTableRow>
      <StyledTableBody>
        {tableRows.map(({ personName, dep, personIcon, subordinatesCount }, i) => {
          return (
            <StyledTableRow key={personName + i} isHighlighted={dep === 'Company avarage'}>
              <StyledTableCol>
                <AvatarCircleMiniature
                  src={personIcon ? personIcon : avatarPlaceholderSrc}
                  size={isSmallDevice ? '28px' : '36px'}
                  spacing={{ mr: isSmallDevice ? mainTheme.spaces.s : mainTheme.spaces.l }}
                />
                {personName}
              </StyledTableCol>
              <StyledTableCol isScrollbarShifted={tableRows.length > 6}>
                <Label value={dep} />
              </StyledTableCol>
              <StyledTableCol>{subordinatesCount}</StyledTableCol>
            </StyledTableRow>
          )
        })}
      </StyledTableBody>
    </StyledWidgetContainer>
  )
}

const sortRows = (array: SpanOfControlTableRow[], type: ColType, isDescActual: boolean) =>
  [...array].sort((a, b) => {
    if (type === 'personName' || type === 'dep') return sortStrings(a[type], b[type], isDescActual)
    return sortNumbers(a[type], b[type], isDescActual)
  })

const SearchPeopleInputWrapper = styled.div`
  position: relative;
  width: calc(162px + 64px);
  height: 34px;
  background: #f8f8fb;
  border-radius: 3px;
  margin-left: 38px;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    margin-left: 0;
    margin-top: ${props => props.theme.spaces.s};
  }
`

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  height: auto;
  width: 14px;
  color: #83869c;
  padding: 10px;
`

const StyledCloseIcon = styled(CloseIcon)`
  height: auto;
  position: absolute;
  color: #83869c;
  right: 0;
  top: 0;
  padding: 10px;
  cursor: pointer;
`

const SearchPeopleInput = styled.input`
  width: 162px;
  height: 34px;
  background: #f8f8fb;
  border: none;
  outline-color: #e0dede;
  padding: 0 32px;
  color: ${props => props.theme.colors.dark};
`

const StyledTableBody = styled(TableBody)`
  padding: 0 8px;
`

const StyledWidgetContainer = styled(WidgetContainer)`
  height: 452px;
  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    padding: 80px 0 0 0;
  }
`

const StyledTableRow = styled(TableRow)<{ isHeader?: boolean; isHighlighted?: boolean }>`
  height: 65px;
  border: none;

  &:nth-child(2n) {
    background: ${props => (props.isHeader ? 'white' : '#f8f8fb')};
    border-radius: 3px;
  }

  ${props =>
    props.isHeader &&
    css`
      height: 16px;
      padding: 0 8px;
      margin: 0;
      width: calc(100% - 16px);
      background: ${props => props.theme.colors.white};
      & > div {
        padding: 0;
      }
      & + div > div:first-child {
        border-top: 1px solid #e6e7ed;
      }
    `};

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    height: 68px;
  }
`

const StyledTableCol = styled(TableCol)<{ isHeader?: boolean; isActive?: boolean; isScrollbarShifted?: boolean }>`
  align-items: center;

  &:first-child {
    flex: 30%;
    padding-left: 17px;
  }
  &:last-child {
    padding-right: 17px;
  }

  ${props =>
    !props.isHeader &&
    css`
      &:first-child,
      &:nth-child(2) {
        color: #4d009d;
      }
    `};

  ${props =>
    props.isScrollbarShifted &&
    css`
      padding-left: 2px;
    `};

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    &:first-child {
      padding-left: 0;
      line-height: 18px;
    }
    &:last-child {
      flex: 20%;
      padding-right: ${props => props.theme.spaces.l};
      line-height: 12px;
      align-items: flex-end;
    }
    ${props =>
      !props.isHeader &&
      css`
        &:nth-child(2) {
          line-height: 14px;
        }
      `};
  }
`
