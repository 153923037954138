import { useRef, MouseEvent } from 'react'
import styled from 'styled-components'
import rafSchd from 'raf-schd'

import { useResizeObserver } from 'hooks'
import { useWatch } from 'tree/hooks'
import { useTree } from 'tree/providers'
import { sizes, spaces } from 'theme'

const calcTreePosition = (minimapOffsetX: number, minimapOffsetY: number, sizeRatio: number, treeWidth: number) => {
  const x = ((minimapOffsetX - MINIMAP_PADDING_SIZE) * sizeRatio - treeWidth / 2) * -1
  const y = (minimapOffsetY - MINIMAP_PADDING_SIZE) * sizeRatio * -1
  return { x, y }
}
const calcMinimapOffset = ({ target, clientX, clientY }: MouseEvent<HTMLDivElement>) => {
  const { left, top } = (target as HTMLDivElement).getBoundingClientRect()
  return { offsetX: clientX - left, offsetY: clientY - top }
}
const getPxSizeAsNumber = (size: string) => Number(size.split('px')[0])

const MINIMAP_PADDING_SIZE = getPxSizeAsNumber(spaces.l)
const TREE_PADDING_SIZE = getPxSizeAsNumber(sizes.treeBoundariesPadding)

type Props = {
  minimapSize: number
}

export const ChartMinimap = ({ minimapSize }: Props) => {
  const { treeBoundariesRef, padRef, setPosition: setTreePosition } = useTree()
  const isMouseDown = useRef(false)
  const positionX = useWatch({ name: 'positionX' })
  const positionY = useWatch({ name: 'positionY' })
  const zoom = useWatch({ name: 'zoom' })

  const { width: treeWidthWithoutPadding = 0, height: treeHeightWithoutPadding = 0 } = useResizeObserver({
    targetRef: treeBoundariesRef,
  })
  const { width: padWidth = 0, height: padHeight = 0 } = useResizeObserver({ targetRef: padRef })

  const treeWidth = treeWidthWithoutPadding + TREE_PADDING_SIZE * 2
  const treeHeight = treeHeightWithoutPadding + TREE_PADDING_SIZE * 2
  const sizeRatio = Math.max(treeWidth, treeHeight) / minimapSize

  const minimapTree = {
    width: treeWidth / sizeRatio,
    height: treeHeight / sizeRatio,
  }
  const pointer = {
    top: (positionY * -1) / sizeRatio,
    left: (positionX * -1) / sizeRatio,
    width: (padWidth * (1 / zoom)) / sizeRatio,
    height: (padHeight * (1 / zoom)) / sizeRatio,
  }

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    isMouseDown.current = true
    const { offsetX, offsetY } = calcMinimapOffset(e)
    const { x, y } = calcTreePosition(offsetX, offsetY, sizeRatio, treeWidth)
    setTreePosition(x, y)
  }

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (isMouseDown.current) {
      const { offsetX, offsetY } = calcMinimapOffset(e)
      const { x, y } = calcTreePosition(offsetX, offsetY, sizeRatio, treeWidth)
      setTreePosition(x, y)
    }
  }
  const throttledHandleMouseMove = rafSchd(handleMouseMove)

  const handleMouseLeave = () => (isMouseDown.current = false)
  const handleMouseUp = () => (isMouseDown.current = false)

  return (
    <Minimap
      $size={minimapSize}
      $padding={MINIMAP_PADDING_SIZE}
      onMouseDown={handleMouseDown}
      onMouseMove={throttledHandleMouseMove}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
    >
      <MinimapTree $width={minimapTree.width} $height={minimapTree.height} />
      <PointerCentering $top={MINIMAP_PADDING_SIZE}>
        <Pointer $top={pointer.top} $left={pointer.left} $width={pointer.width} $height={pointer.height} />
      </PointerCentering>
    </Minimap>
  )
}

const Minimap = styled.div<{ $size: number; $padding: number }>`
  position: relative;
  width: auto;
  max-width: ${props => props.$size}px;
  height: auto;
  max-height: ${props => props.$size}px;
  box-sizing: content-box;
  padding: ${props => props.$padding}px;
  border-radius: 2px;
  border: 1px solid ${props => props.theme.colors.border};
  background: ${props => props.theme.colors.greyExtraLight};
  user-select: none;
  overflow: hidden;
  cursor: all-scroll;
  z-index: 2;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    transform: translateX(-${props => props.theme.spaces.m});
  }
`

type MinimapTreeProps = { $width: number; $height: number }
const MinimapTree = styled.div.attrs<MinimapTreeProps>(({ $width, $height }) => ({
  style: { width: $width, height: $height },
}))<MinimapTreeProps>`
  border-radius: 2px;
  background: ${props => props.theme.colors.greyUltraLight};
  user-select: none;
  pointer-events: none;
`

const PointerCentering = styled.div<{ $top: number }>`
  position: absolute;
  top: ${props => props.$top}px;
  left: 50%;
  width: 0;
  height: 0;
  transform: translate(-50%, 0);
  user-select: none;
  pointer-events: none;
`

type PointerProps = { $top: number; $left: number; $width: number; $height: number }
const Pointer = styled.div.attrs<PointerProps>(({ $top, $left, $width, $height }) => ({
  style: { top: $top, left: $left, width: $width, height: $height },
}))<PointerProps>`
  position: absolute;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.purple};
  border-radius: 2px;
  background: rgba(109, 46, 174, 0.05);
  transform: translate(-50%, -50%);
  user-select: none;
  pointer-events: none;
`
