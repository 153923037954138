import { gql } from '@apollo/client'
import { chartCapabilitiesFragment } from './chart-capabilities-fragment'
import { subscriptionInfoFragment } from './subscription-info-fragment'

export const chartFragment = gql`
  fragment ChartFragment on Chart {
    id
    uuid
    name
    emoji
    logo
    lock {
      active
      from
    }
    providerInfo {
      name
      syncedFields {
        name
        readonly
      }
      scopes
      capabilities {
        canReadUser
        canUpdateUser
        canReadCalendar
      }
    }
    public
    adminEmail
    topNodes {
      items {
        ... on Person {
          id
          uuid
          name
          image
          position
          type
          expanded @client
        }
        ... on Department {
          id
          uuid
          name
          color
          type
          employeeCount
          expanded @client
        }
      }
    }
    subscription {
      ...SubscriptionInfo
    }
    employeeCount
    departmentCount
    unassignedEmployeeCount
    unassignedDepartmentCount
    userPermission {
      role
    }
    capabilities {
      ...ChartCapabilities
    }
  }
  ${chartCapabilitiesFragment}
  ${subscriptionInfoFragment}
`
