import { SVG, SVGProps } from './SVG'

export const OrgchartStandardStructureIcon = (props: SVGProps) => (
  <SVG width='18' height='14' viewBox='0 0 18 14' fill='none' {...props}>
    <rect x='6.75' y='1' width='4.5' height='3' stroke='currentColor' strokeWidth='1.5' />
    <rect x='1.5' y='10' width='3' height='3' stroke='currentColor' strokeWidth='1.5' />
    <rect x='7.5' y='10' width='3' height='3' stroke='currentColor' strokeWidth='1.5' />
    <rect x='8.25' y='4.75' width='1.5' height='3' fill='currentColor' />
    <rect x='14.25' y='6.25' width='1.5' height='3' fill='currentColor' />
    <rect x='2.25' y='6.25' width='1.5' height='3' fill='currentColor' />
    <rect x='8.25' y='6.25' width='1.5' height='3' fill='currentColor' />
    <rect x='2.25' y='6.25' width='13.5' height='1.5' fill='currentColor' />
    <rect x='13.5' y='10' width='3' height='3' stroke='currentColor' strokeWidth='1.5' />
  </SVG>
)
