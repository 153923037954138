import { toast, ToastOptions } from 'react-toastify'
import { onError } from '@apollo/client/link/error'

import { history } from 'app/history'
import { requestedUrlStorage } from 'auth'
import { ErrorCode, ERROR_CODE_MAP } from 'helpers'
import { LOGIN_PATH, MAINTENANCE_PATH } from 'routes'

type GraphQLErrorsMessage = {
  statusCode: number
  error: string
}

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  const { location } = history
  const { pathname } = location

  if (graphQLErrors) {
    graphQLErrors.map(({ extensions, message, path }) => {
      const typedMessage = message as unknown as GraphQLErrorsMessage
      const error = typeof message === 'string' ? message : typedMessage.error
      const statusCode: number = typedMessage.statusCode

      if (!extensions) return getQLError('Error [no-ex]', error, path)

      const code: ErrorCode = extensions.code
      const quiet = ERROR_CODE_MAP[code]?.silenceToast ?? false

      switch (extensions.code) {
        case 'MAINTENANCE_IN_PROGRESS':
          history.push(MAINTENANCE_PATH)
          break
        case 'NOT_FOUND':
          getQLError(extensions.code, error, path, quiet)
          break
        case 'INTERNAL_SERVER_ERROR':
          if (statusCode === 401 /* UNAUTHENTICATED */ || statusCode === 403 /* FORBIDDEN */) {
            requestedUrlStorage.setItem(pathname)
            history.push(LOGIN_PATH)
          } else getQLError(extensions.code, error, path, true)
          break
        case 'UNAUTHENTICATED':
        case 'FORBIDDEN':
          break
        default:
          getQLError(`Unknown error, code: ${extensions?.code}`, error, path, quiet)
      }
    })
  }

  if (networkError) {
    if ('statusCode' in networkError) {
      switch (networkError.statusCode) {
        case 200:
          break
        case 401:
          history.push(LOGIN_PATH)
          break
        case 403:
          requestedUrlStorage.setItem(pathname)
          history.push(LOGIN_PATH)
          break
        default:
          getNetworkError(networkError.statusCode, networkError.message)
      }
    } else getNetworkError(0, 'NetworkError without status code')
  }
})

const getQLError = (desc: string, error: string, path: readonly (string | number)[] | undefined, quiet?: boolean) => {
  console.error(`[GraphQL Error - ${desc}]: Message: ${error}, Path: ${path}`)
  const toastId = `${error}-${path}`
  if (!quiet) invokeToast(toastId, `${error}. Please reload and retry.`, { type: 'error' })
}

const getNetworkError = (statusCode: number, error: string) => {
  console.error(`[Network Error - Status code: ${statusCode}]: Message: ${error}`)
  const userMessage = statusCode === 404 ? 'Error 404 - Page not found' : 'Network error! Please reload and retry.'
  const toastId = `network-error-${statusCode}`
  invokeToast(toastId, userMessage, { type: 'error' })
}

const invokeToast = (toastId: string, message: string, options: Exclude<ToastOptions, 'toastId'> = {}) => {
  const isToastActive = toast.isActive(toastId)
  if (!isToastActive) {
    toast(message, { ...options, toastId })
  }
}
