import { SVG, SVGProps } from './SVG'

export const HeadsetWithMicrophoneIcon = (props: SVGProps) => (
  <SVG width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M2.25 12C2.25 6.61406 6.61406 2.25 12 2.25C17.3859 2.25 21.75 6.61406 21.75 12V18.7547C21.75 19.7906 20.9109 20.6297 19.875 20.6297L14.7 20.625C14.3109 19.95 13.5844 19.5 12.75 19.5H11.25C10.0078 19.5 9 20.5078 9 21.75C9 22.9922 10.0078 24 11.25 24H12.75C13.5844 24 14.3109 23.5453 14.7 22.875L19.875 22.8797C22.1531 22.8797 24.0047 21.0328 24.0047 18.7547V12C24 5.37188 18.6281 0 12 0C5.37188 0 0 5.37188 0 12V13.875C0 14.4984 0.501562 15 1.125 15C1.74844 15 2.25 14.4984 2.25 13.875V12ZM7.5 10.5V15.75C6.67031 15.75 6 15.0797 6 14.25V12C6 11.1703 6.67031 10.5 7.5 10.5ZM3.75 12V14.25C3.75 16.3219 5.42812 18 7.5 18H8.25C9.07969 18 9.75 17.3297 9.75 16.5V9.75C9.75 8.92031 9.07969 8.25 8.25 8.25H7.5C5.42812 8.25 3.75 9.92813 3.75 12ZM16.5 10.5C17.3297 10.5 18 11.1703 18 12V14.25C18 15.0797 17.3297 15.75 16.5 15.75V10.5ZM20.25 12C20.25 9.92813 18.5719 8.25 16.5 8.25H15.75C14.9203 8.25 14.25 8.92031 14.25 9.75V16.5C14.25 17.3297 14.9203 18 15.75 18H16.5C18.5719 18 20.25 16.3219 20.25 14.25V12Z'
      fill='currentColor'
    />
  </SVG>
)
