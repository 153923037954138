import { Chart, ChartsDocument, Node, useCloneChartMutation } from 'apollo/generated/graphql'

type ForkChartRequestParams = {
  chartId: Chart['id']
  rootId: Node['id']
}

export const useForkChartRequest = () => {
  const [cloneChartMutation] = useCloneChartMutation()

  const forkChartRequest = async ({ chartId, rootId }: ForkChartRequestParams) => {
    return cloneChartMutation({
      variables: { uuid: chartId, rootUuid: rootId },
      refetchQueries: [ChartsDocument],
    })
  }

  return { forkChartRequest }
}
