import { CreatePersonInput } from 'apollo/generated/graphql'

const formKeyPriority: {
  [key in keyof CreatePersonInput]: number
} = {
  // About fields ---------------
  image: 10000,
  givenName: 9990,
  familyName: 9980,
  position: 9800,
  employeeType: 9790,
  employeeId: 9780,
  // + Custom fields

  // Connections ----------------
  // + Supervisor and department
  // + Dotted line fields

  // Contacts -------------------
  email: 7990,
  emailAliases: 7980,
  mobile: 7800,
  linkedInLink: 7790,
  whatsAppLink: 7780,
  xLink: 7770,
  facebookLink: 7760,
  instagramLink: 7750,
  youTubeLink: 7740,

  // Location -------------------
  costCenter: 6700,
  location: 6600,
  buildingId: 6500,
  floorName: 6400,
  floorSection: 6300,
  deskCode: 6200,
}

export const getPriority = (name: keyof CreatePersonInput): number => formKeyPriority[name] || 0
