import analytics from 'analytics'
import { handleErrorValidation } from 'helpers'
import { SHARING_TRACK } from 'analytics/constants'

import { getCollaborators } from 'apollo/query'
import { CollaboratorRole, useUpdateCollaboratorMutation } from 'apollo/generated/graphql'

export const useOnRoleChange = () => {
  const [updateCollaboratorMutation] = useUpdateCollaboratorMutation()

  const onRoleChange = (chartUuid: string, collaboratorUuid: string, role: CollaboratorRole) => {
    updateCollaboratorMutation({
      variables: { chartUuid, uuid: collaboratorUuid, data: { role } },
      refetchQueries: [{ query: getCollaborators, variables: { chartUuid } }],
    })
      .then(() => analytics.track(SHARING_TRACK.updateCollaborator, { chartUuid, role }))
      .catch(error => {
        handleErrorValidation({
          track: { message: SHARING_TRACK.updateColleagueFailure, values: { chartUuid, error } },
          toast: { message: 'Colleague update failed' },
        })
      })
  }

  return onRoleChange
}
