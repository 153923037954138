import { useCallback } from 'react'

import { useExpandNodesOnCreate } from './use-expand-nodes-on-create'
import { useUpdateNodeQuery } from './use-update-node-query'
import { useUpdateCount } from 'tree/hooks'
import { useChartId } from 'tree/providers'

import { CreateDepartmentInput, useCreateDepartmentMutation } from 'apollo/generated/graphql'

export const useCreateDepartmentRequest = () => {
  const chartUuid = useChartId()
  const [createDepartmentMutation] = useCreateDepartmentMutation()
  const { expandNodesOnCreate } = useExpandNodesOnCreate()
  const { updateNodeQuery } = useUpdateNodeQuery()
  const { updateCount } = useUpdateCount()

  const createDepartmentRequest = useCallback(
    (values: CreateDepartmentInput) => {
      return createDepartmentMutation({
        update: (cache, { data }) => {
          const createdDepartment = data?.createDepartment
          if (createdDepartment) {
            updateNodeQuery({ cache, createdNode: createdDepartment, chartUuid })
            updateCount({ cache, mutationData: createdDepartment, action: 'increase' })
            const parentUuidList = [null, ...createdDepartment.parentNodes.map(({ uuid }) => uuid)]
            expandNodesOnCreate(parentUuidList)
          } else {
            throw new Error('No mutationResult data on createDepartment')
          }
        },
        variables: { data: { ...values, parentUuid: values.parentUuid }, chartUuid },
      })
    },
    [chartUuid, createDepartmentMutation, updateNodeQuery, updateCount, expandNodesOnCreate]
  )

  return { createDepartmentRequest }
}
