import { useState } from 'react'

import { useDeletePublicLinkRequest } from './use-delete-public-link-request'
import { WithConfirmModal } from '../../types'
import { ConfirmationModalType } from 'components/specific'
import { useModal } from 'modal'
import analytics from 'analytics'
import { SHARING_TRACK } from 'analytics/constants'
import { handleErrorValidation } from 'helpers'

import { Chart, PublicLink } from 'apollo/generated/graphql'

type DeletePublicLinkActionParams = {
  chartId: Chart['id']
  publicLinkId: PublicLink['id']
}

export const useDeletePublicLinkAction = () => {
  const [isDeleting, setIsDeleting] = useState(false)
  const { open } = useModal()
  const { deletePublicLinkRequest } = useDeletePublicLinkRequest()

  const deletePublicLink = async (params: DeletePublicLinkActionParams) => {
    const { chartId } = params
    setIsDeleting(true)
    try {
      const res = await deletePublicLinkRequest(params)
      analytics.track(SHARING_TRACK.deletePublicLink, { chartUuid: chartId })
      return Promise.resolve(res)
    } catch (error) {
      handleErrorValidation({
        track: {
          message: SHARING_TRACK.deletePublicLinkFailure,
          values: { chartUuid: chartId, error },
        },
        toast: { message: 'Failed to delete link' },
      })
    } finally {
      setIsDeleting(false)
    }
  }

  const deletePublicLinkWithConfirm = async ({
    onAbort,
    ...params
  }: DeletePublicLinkActionParams & WithConfirmModal) => {
    setIsDeleting(true)
    const res = await new Promise((resolve, reject) => {
      let hasConfirmed = false
      open<ConfirmationModalType>('confirmationModal', {
        title: { text: 'Delete link' },
        message: 'Permanently delete your current sharable link. This cannot be undone.',
        confirmButton: { buttonType: 'danger', children: 'Delete' },
        onConfirm: () => {
          hasConfirmed = true
          deletePublicLinkRequest(params).catch(reject).then(resolve)
        },
        onClose: () => {
          if (!hasConfirmed) onAbort?.()
        },
      })
    })
    setIsDeleting(false)
    return res
  }

  return { deletePublicLink, deletePublicLinkWithConfirm, isDeleting }
}
