import { ReactNode } from 'react'
import styled from 'styled-components'

import { Button } from 'components/generic'
import { PlusIcon } from 'components/icons'

import { spaces } from 'theme'

type Props = {
  onClick: () => void
  className?: string
  children: ReactNode
}

export const NewFieldButton = ({ onClick, className, children }: Props) => (
  <Button
    className={className}
    buttonSize='small'
    spacing={{ ml: 'auto', py: spaces.m, px: spaces.l }}
    onClick={onClick}
  >
    <StyledPlusIcon />
    {children}
  </Button>
)

const StyledPlusIcon = styled(PlusIcon)`
  width: 12px;
  height: auto;
  margin-right: 8px;
  color: ${props => props.theme.colors.white};
`
