import { ReactNode } from 'react'
import styled from 'styled-components'

import { ListItemContainer } from './list-item-container'
import { LocationPinIcon } from 'components/icons'

import { SpacingProps } from 'helpers'
import { spaces } from 'theme'

type Props = {
  children: ReactNode
  className?: string
  gap?: string
  iconColor?: string
  IconComponent?: ReactNode
  spacing?: SpacingProps
  onClick?: () => void
}

export const ListItem = ({
  children,
  className,
  gap = spaces.m,
  iconColor,
  IconComponent,
  spacing,
  onClick,
}: Props) => (
  <StyledListItemContainer className={className} $gap={gap} $spacing={spacing} onClick={onClick}>
    {IconComponent ?? (
      <div>
        <StyledLocationPinIcon color={iconColor} />
      </div>
    )}
    {children}
  </StyledListItemContainer>
)

const StyledListItemContainer = styled(ListItemContainer)<{ $gap?: string }>`
  ${props => props.$gap && `gap: ${props.$gap};`}
`

const StyledLocationPinIcon = styled(LocationPinIcon)`
  display: inline-block;
  vertical-align: middle;
`
