import { Button } from 'components/generic'

import { spaces } from 'theme'

type Props = {
  onClick: () => void
}

export const OverviewButton = ({ onClick }: Props) => (
  <Button buttonType='secondary' spacing={{ ml: spaces.m }} onClick={onClick}>
    Go to Org Chart
  </Button>
)
