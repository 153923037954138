import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { useAuth } from 'auth'

import { TopNavigation } from 'components/specific'
import { LinkStyles, FlexBox, BaseText } from 'components/generic'
import { WebLayout } from 'components/layouts'
import { ForbiddenImage } from './images/forbidden-image'

import { CHARTS_PATH, LOGIN_PATH } from 'routes'

export const ChartForbiddenScreen = () => {
  const { isAuthenticated } = useAuth()

  return (
    <WebLayout>
      <TopNavigation />
      <Container>
        <FlexBox $isCentered $isDirectionColumn>
          <ForbiddenImage />
          <FlexBox $spacing={{ mt: '30px' }} $isCentered $isDirectionColumn>
            <FlexBox>
              <Text $isBold>You cannot access to this Org Chart.</Text>
            </FlexBox>
            <FlexBox $spacing={{ mt: '16px' }}>
              <Text>Please ask the admin of the Org Chart to check the sharing settings.</Text>
            </FlexBox>
            <FlexBox $spacing={{ mt: '16px' }}>
              <Text>
                <StyledLink to={isAuthenticated ? CHARTS_PATH : LOGIN_PATH}>
                  Go to {isAuthenticated ? 'overview' : 'login'}
                </StyledLink>
              </Text>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </Container>
    </WebLayout>
  )
}

const StyledLink = styled(Link)`
  ${LinkStyles}
`

const Text = styled(BaseText)<{ $isBold?: boolean }>`
  font-size: 13px;
  line-height: 20px;

  ${props =>
    props.$isBold &&
    css`
      font-weight: bold;
    `}
`

const Container = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
