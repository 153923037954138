import { MouseEvent } from 'react'

import { Badge } from 'components/generic'
import { GemIcon } from 'components/icons'

import { useGoToPremiumScreen } from './hooks'
import { SpacingProps } from 'helpers'
import { spaces } from 'theme'

type Props = {
  className?: string
  redirectToPro?: boolean
  spacing?: SpacingProps
}

export const ProBadge = ({ className, redirectToPro, spacing }: Props) => {
  const { goToPremiumScreen } = useGoToPremiumScreen()

  const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    goToPremiumScreen()
  }

  return (
    <Badge
      className={className}
      $color='yellow'
      $size='small'
      onClick={redirectToPro ? handleOnClick : undefined}
      $spacing={spacing}
    >
      <GemIcon $spacing={{ mr: spaces.s }} />
      PRO
    </Badge>
  )
}
