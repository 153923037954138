import { toast } from 'react-toastify'

import { formErrorHandler } from 'helpers'
import analytics from 'analytics'
import { CollaboratorFormData } from '../types'
import { SHARING_TRACK } from 'analytics/constants'

import { getCollaborators } from 'apollo/query'
import { useAddCollaboratorMutation } from 'apollo/generated/graphql'

type AddCollaboratorParams = {
  chartUuid: string
  collaborator: CollaboratorFormData
}

export const useAddCollaborator = () => {
  const [addCollaboratorMutation] = useAddCollaboratorMutation()

  const addCollaborator = ({ chartUuid, collaborator }: AddCollaboratorParams) => {
    analytics.track(SHARING_TRACK.addCollaborator, {
      chartUuid,
      role: collaborator.role,
    })
    addCollaboratorMutation({
      variables: { chartUuid: chartUuid, data: collaborator },
      refetchQueries: [{ query: getCollaborators, variables: { chartUuid } }],
    }).catch(error => {
      const code = formErrorHandler(error)
      switch (code) {
        case 'COLLABORATOR_EXISTS':
          toast(`Collaborator ${collaborator.email} already exists`, { type: 'error' })
          break

        default:
          toast(
            `Unknown error occurred when sharing with ${collaborator.email}. Please contact us if the problem persists.`,
            { type: 'error' }
          )
      }
    })
  }

  return { addCollaborator }
}
