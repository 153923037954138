import { ReactNode } from 'react'
import styled from 'styled-components'

import { Button, ButtonProps, Modal } from 'components/generic'
import { ModalTitleProps } from 'components/generic/modal/components'

import { ModalComponentProps, ModalTypeFactory } from 'modal'

type Props = ModalComponentProps & {
  message: ReactNode
  title: ModalTitleProps
  onConfirm: () => void
  cancelButton?: ButtonProps
  className?: string
  confirmButton?: ButtonProps
}

export type ConfirmationModalType = ModalTypeFactory<'confirmationModal', Props>

export const ConfirmationModal = (props: Props) => {
  const { message, title, onConfirm, cancelButton, className, confirmButton, onClose } = props
  const handleConfirm = () => {
    onConfirm()
    onClose()
  }

  return (
    <Modal className={className} size='small' title={title} onClose={onClose}>
      <Content>{message}</Content>
      <SpaceBetween>
        <Button buttonType='tertiary' onClick={onClose} data-testid='button-modal-cancel' {...cancelButton}>
          {cancelButton?.children ? cancelButton.children : 'Cancel'}
        </Button>
        <Button onClick={handleConfirm} data-testid='button-modal-confirm' {...confirmButton}>
          {confirmButton?.children ? confirmButton.children : 'Confirm'}
        </Button>
      </SpaceBetween>
    </Modal>
  )
}

const Content = styled.div`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
`

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${props => props.theme.spaces.l};
`
