export const CONNECT_GSUITE_PATH_NAME = 'connect-gsuite' as const
export const GSUITE_PATH = `/${CONNECT_GSUITE_PATH_NAME}` as const
const getConnectGoogleWorkspacePath = (path: string) => `${path}/${CONNECT_GSUITE_PATH_NAME}`

// Root Paths ---------------------------------------------------
export const BASE_URL = window.location.origin
export const CALLBACK_PATH = `/callback` as const
export const INVITE_PATH = `/invite/:code` as const
export const LOGIN_PATH = `/login` as const
export const LOGOUT_CALLBACK_PATH = `/logout-callback` as const
export const MAINTENANCE_PATH = `/maintenance` as const
export const PAY_PREMIUM_PATH = '/get-pro' as const
export const SHARED_LINK_PATH = `/s/:code` as const
export const CHARTS_PATH = '/charts' as const
export const CREATE_CHART_PATH = '/create-chart' as const
export const CHART_EXPORT_PATH = '/export/:chartUuid/:format' as const
export const CHART_EDIT_PATH = '/chart/:chartUuid/edit' as const
export const CHART_PRICING_PATH = '/chart/:chartUuid/pricing'
export const CHART_EDIT_GW_PATH = getConnectGoogleWorkspacePath('/chart/:chartUuid/edit')

// Path Names ---------------------------------------------------
export const CUSTOM_FIELDS_PATH_NAME = 'custom-fields' as const
export const DOTTED_LINE_PATH_NAME = 'dotted-line' as const
export const NODE_EDIT_PATH_NAME = 'edit' as const

// Insights ---------------------------------------------------
export const INSIGHTS_PATH = '/chart/:chartUuid/insights' as const
export const INSIGHTS_OVERVIEW_PATH = `${INSIGHTS_PATH}/overview` as const
export const INSIGHTS_OVERVIEW_CONNECT_GW_PATH = getConnectGoogleWorkspacePath(INSIGHTS_OVERVIEW_PATH)
export const INSIGHTS_SOCIOMAP_PATH = `${INSIGHTS_PATH}/sociomap` as const
export const INSIGHTS_SOCIOMAP_CONNECT_GW_PATH = getConnectGoogleWorkspacePath(INSIGHTS_SOCIOMAP_PATH)
export const INSIGHTS_TIL_ANALYTICS_PATH = `${INSIGHTS_PATH}/til-analytics` as const

// Settings ---------------------------------------------------
export const SETTINGS_PATH = `/chart/:chartUuid/settings` as const
export const SETTINGS_GENERAL_PATH = `${SETTINGS_PATH}/general` as const
export const SETTINGS_CUSTOM_FIELDS_PATH = `${SETTINGS_PATH}/${CUSTOM_FIELDS_PATH_NAME}` as const
export const SETTINGS_CREATE_CUSTOM_FIELD_PATH = `${SETTINGS_CUSTOM_FIELDS_PATH}/create` as const
export const SETTINGS_EDIT_CUSTOM_FIELD_PATH = `${SETTINGS_CUSTOM_FIELDS_PATH}/:customFieldId/edit` as const
export const SETTINGS_DOTTED_LINES_PATH = `${SETTINGS_PATH}/${DOTTED_LINE_PATH_NAME}` as const
export const SETTINGS_CREATE_DOTTED_LINE_PATH = `${SETTINGS_DOTTED_LINES_PATH}/create` as const
export const SETTINGS_EDIT_DOTTED_LINE_PATH = `${SETTINGS_DOTTED_LINES_PATH}/:connectionFieldId/edit` as const
export const SETTINGS_BRANDING_PATH = `${SETTINGS_PATH}/branding` as const
export const SETTINGS_MEMBERS_PATH = `${SETTINGS_PATH}/sharing/members` as const
export const SETTINGS_SHARING_LINK_PATH = `${SETTINGS_PATH}/sharing/sharable-link` as const
export const SETTINGS_DOWNLOAD_PATH = `${SETTINGS_PATH}/downloads` as const

// Payment ---------------------------------------------------
export const PAYMENT_SUCCESS_PATH = '/payment/success' as const
export const PAYMENT_FAILURE_PATH = '/payment/failure' as const

// Employee ---------------------------------------------------
export const CREATE_EMPLOYEE_PATH_NAME = 'create-employee/:parentUuid' as const
export const EMPLOYEE_PATH_NAME = 'employee/:uuid' as const
export const EMPLOYEE_EDIT_PATH_NAME = `${EMPLOYEE_PATH_NAME}/${NODE_EDIT_PATH_NAME}` as const
// Create
export const EMPLOYEE_CREATE_PATH = `${CHART_EDIT_PATH}/${CREATE_EMPLOYEE_PATH_NAME}` as const
// Detail
export const EMPLOYEE_DETAIL_PATH = `${CHART_EDIT_PATH}/${EMPLOYEE_PATH_NAME}` as const
// Edit
export const EMPLOYEE_EDIT_PATH = `${CHART_EDIT_PATH}/${EMPLOYEE_EDIT_PATH_NAME}` as const
// Shared Link
export const SHARED_LINK_EMPLOYEE_DETAIL_PATH = `${SHARED_LINK_PATH}/${EMPLOYEE_PATH_NAME}` as const
// Google Workspace
export const EMPLOYEE_DETAIL_CONNECT_GW_PATH = getConnectGoogleWorkspacePath(EMPLOYEE_DETAIL_PATH)
export const EMPLOYEE_EDIT_CONNECT_GW_PATH = getConnectGoogleWorkspacePath(EMPLOYEE_EDIT_PATH)

// Department ---------------------------------------------------
export const CREATE_DEPARTMENT_PATH_NAME = 'create-department/:parentUuid' as const
export const DEPARTMENT_PATH_NAME = 'department/:uuid' as const
export const DEPARTMENT_EDIT_PATH_NAME = `${DEPARTMENT_PATH_NAME}/${NODE_EDIT_PATH_NAME}` as const
// Create
export const DEPARTMENT_CREATE_PATH = `${CHART_EDIT_PATH}/${CREATE_DEPARTMENT_PATH_NAME}` as const
// Detail
export const DEPARTMENT_DETAIL_PATH = `${CHART_EDIT_PATH}/${DEPARTMENT_PATH_NAME}` as const
// Edit
export const DEPARTMENT_EDIT_PATH = `${CHART_EDIT_PATH}/${DEPARTMENT_EDIT_PATH_NAME}` as const
// Shared Link
export const SHARED_LINK_DEPARTMENT_EDIT_PATH = `${SHARED_LINK_PATH}/${DEPARTMENT_PATH_NAME}` as const
