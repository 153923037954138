import { CreateChartOptionType } from '../types'
import { ChartFormValues } from 'tree/types'
import { getCodeVerifier } from 'helpers'

import {
  CreateChartFromFileInput,
  CreateChartFromGoogleWorkspaceInput,
  CreateChartInput,
} from 'apollo/generated/graphql'

type V<T extends CreateChartOptionType> = T extends 'file'
  ? CreateChartFromFileInput
  : T extends 'gsuite'
  ? CreateChartFromGoogleWorkspaceInput
  : CreateChartInput

export function getChartDataByOptionType<T extends CreateChartOptionType>(optionType: T, data: ChartFormValues): V<T>
export function getChartDataByOptionType(
  optionType: CreateChartOptionType,
  data: ChartFormValues
): CreateChartFromFileInput | CreateChartFromGoogleWorkspaceInput | CreateChartInput {
  const generalData = {
    emoji: data.emoji,
    name: data.name,
  } as CreateChartInput

  switch (optionType) {
    case 'file':
      return {
        ...generalData,
        file: data.file,
      } as CreateChartInput
    case 'gsuite':
      return {
        ...generalData,
        code: data.code,
        state: data.codeState,
        codeVerifier: getCodeVerifier(),
      } as CreateChartFromGoogleWorkspaceInput
    default:
      return generalData
  }
}

export type ChartTrackedData =
  | CreateChartFromFileInput
  | Omit<CreateChartFromGoogleWorkspaceInput, 'code' | 'codeVerifier' | 'state'>
  | CreateChartInput

export const getChartTrackedData = (optionType: CreateChartOptionType, data: ChartFormValues): ChartTrackedData => {
  const generalData = { emoji: data.emoji, name: data.name }
  if (optionType === 'file') {
    return { ...generalData, file: data.file, build: Boolean(data.build) }
  }
  return generalData
}
