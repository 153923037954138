import { ReactNode, useState } from 'react'

import { createSafeContext } from 'helpers'

export type ChartConfig = {
  isReadOnly?: boolean
}

type ContextType = {
  config: ChartConfig
  /** Config remains the same but your values are added on top of it. */
  handleEditConfig: (newConfig: ChartConfig) => void
  /** Whole config is replaced by your new config. */
  handleReplaceConfig: (newConfig: ChartConfig) => void
}

export const [useChartConfig, EmptyChartConfigProvider] = createSafeContext<ContextType>()

type Props = {
  children: ReactNode
}

export const ChartConfigSafeProvider = ({ children }: Props) => {
  const [config, setConfig] = useState<ChartConfig>({})

  const handleEditConfig: ContextType['handleEditConfig'] = newConfig => {
    setConfig({ ...config, ...newConfig })
  }

  const handleReplaceConfig: ContextType['handleReplaceConfig'] = newConfig => {
    setConfig(newConfig)
  }

  return (
    <EmptyChartConfigProvider value={{ config, handleEditConfig, handleReplaceConfig }}>
      {children}
    </EmptyChartConfigProvider>
  )
}
