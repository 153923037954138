import { FieldArray } from 'formik'
import styled from 'styled-components'

import { DeleteButton, OptionRow, SettingsCardTitle } from '../../components'
import { ColorPicker } from 'components/specific'
import { Button, FieldEmojiPicker, FieldInput } from 'components/generic'

import { CUSTOM_FIELD_DEFAULT_OPTION } from 'tree/constants'
import { DropdownOption, DropdownOptionCreation } from 'tree/types'

type Props = {
  title: string
  name: string
  dropdownOptions: (DropdownOption | DropdownOptionCreation)[]
}

export const SelectOptions = ({ title, name, dropdownOptions }: Props) => (
  <FieldArray name={name}>
    {({ push, remove, replace }) => (
      <>
        <StyledSettingsCardTitle>{title}</StyledSettingsCardTitle>

        {dropdownOptions.map((option, i) => (
          <OptionRow key={i} $isLast={i === dropdownOptions.length - 1}>
            <ColorPicker
              color={dropdownOptions[i].background}
              colorSelected={value => replace(i, { ...option, background: value })}
              title='Change color'
              colorPrieviewChildren={dropdownOptions[i].icon}
              isSquared
              isBig
            />

            <StyledFieldEmojiPicker
              name={`${name}.${i}.icon`}
              value={dropdownOptions[i].icon}
              onSelect={value => replace(i, { ...option, icon: value })}
              absolute
            />

            <StyledFieldInput
              value={option.value}
              name={`${name}.${i}.value`}
              type='text'
              placeholder='Fill name of the option'
              maxLength={255}
              onChange={e => replace(i, { ...option, value: e.target.value })}
              onClear={() => replace(i, { ...option, value: '' })}
            />

            {dropdownOptions.length > 1 && <DeleteButton title='Delete option' onClick={() => remove(i)} />}
          </OptionRow>
        ))}

        <Button spacing={{ mr: 'auto' }} onClick={() => push(CUSTOM_FIELD_DEFAULT_OPTION)} buttonType='secondary'>
          Add option
        </Button>
      </>
    )}
  </FieldArray>
)

const StyledSettingsCardTitle = styled(SettingsCardTitle)`
  margin-bottom: ${props => props.theme.spaces.m};
`

const StyledFieldEmojiPicker = styled(FieldEmojiPicker)`
  && {
    display: flex;
    width: 96px;
    min-width: 96px;
    margin: 0;
    margin-right: ${props => props.theme.spaces.l};
  }
`

const StyledFieldInput = styled(FieldInput)`
  && {
    margin-right: ${props => props.theme.spaces.l};
    margin-bottom: 0;
  }
`
