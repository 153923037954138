import styled from 'styled-components'

import { AlertBoxContainer } from './alert-box-container'
import { AlertBoxIcon } from './alert-box-icon'
import { AlertBoxTitle } from './alert-box-title'
import { AlertBoxProps } from './alert-box.types'

import { Button } from '../button'

export const AlertBox = ({
  children,
  type,
  size = 'medium',
  className,
  includeIcon,
  isIconCentered,
  title,
  ButtonComponent,
  buttonProps,
}: AlertBoxProps) => (
  <StyledAlertBoxContainer className={className} type={type} size={size}>
    {includeIcon && <StyledAlertBoxIcon type={type} size={size} $isIconCentered={isIconCentered} />}
    <Content>
      {title && <StyledAlertBoxTitle>{title}</StyledAlertBoxTitle>}
      {children}
    </Content>
    {ButtonComponent ?? (buttonProps && <StyledButton {...buttonProps} />)}
  </StyledAlertBoxContainer>
)

const StyledAlertBoxContainer = styled(AlertBoxContainer)`
  max-width: 1020px;
  margin-right: auto;
  margin-left: auto;
  align-items: flex-start;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    margin-right: ${props => props.theme.spaces.l};
  }
`

const StyledAlertBoxIcon = styled(AlertBoxIcon)<{ $isIconCentered?: boolean }>`
  ${props => props.$isIconCentered && 'align-self: center;'}

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    margin-right: ${props => props.theme.spaces.l};
  }
`

const StyledAlertBoxTitle = styled(AlertBoxTitle)`
  margin-bottom: ${props => props.theme.spaces.s};
`

const StyledButton = styled(Button)`
  align-self: center;
  margin-left: auto;
`
