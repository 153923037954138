import styled from 'styled-components'

export const DataEmpty = styled.div`
  position: absolute;
  color: ${props => props.theme.colors.white};
  font-size: 18px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 400px;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    max-width: calc(100% - 10px);
  }
`
