import { useParams } from 'react-router-dom'

import { EmployeeEdit } from './edit'

import { NodeComponent } from '../node-components'
import { useCreateNodeAction } from 'tree/actions'
import { usePersonOnCreation } from 'tree/hooks'
import { OnPersonFormSubmit } from 'tree/types'

export const CreateEmployee = ({ onClose }: NodeComponent) => {
  const { parentUuid: parentUuidFromUrl } = useParams<{ parentUuid: string }>()
  const parentUuid = parentUuidFromUrl === 'null' ? null : parentUuidFromUrl
  const personOnCreation = usePersonOnCreation()

  const { createNode } = useCreateNodeAction()
  const handleSubmit: OnPersonFormSubmit = ({ values, actions }) => {
    createNode({ values, actions, nodeTypename: 'Person' }).then(() => {
      actions.setSubmitting(false)
      onClose()
    })
  }

  return (
    <EmployeeEdit
      parentUuid={parentUuid}
      personOnCreation={personOnCreation}
      onClose={onClose}
      onSubmit={handleSubmit}
      createMode
    />
  )
}
