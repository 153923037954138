import styled, { css } from 'styled-components'

import { ImageWithFallback, ImageWithFallbackProps } from 'components/generic/image-with-fallback'

type Props = ImageWithFallbackProps & {
  compact: boolean
}

export const PersonImage = ({ compact, ...imageWithFallbackProps }: Props) => (
  <Container $compact={compact}>
    <ImageWithFallback {...imageWithFallbackProps} />
  </Container>
)

const compactViewCSS = css`
  order: 2;
  align-self: flex-start;
  margin-left: ${props => props.theme.spaces.xs};
`
const standardViewCSS = css`
  position: absolute;
  top: 0;
  transform: translateY(-50%);
`
const Container = styled.div<{ $compact: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => (props.$compact ? compactViewCSS : standardViewCSS)}
`
