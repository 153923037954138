import { ReactNode } from 'react'
import styled from 'styled-components'

import { Button } from 'components/generic'
import { ChevronLeftIcon } from 'components/icons'

type Props = {
  children?: ReactNode
  className?: string
  backTitle?: string
  onBackClick?: () => void
}

export const ViewHeader = ({ className, children, backTitle, onBackClick }: Props) => (
  <Container className={className}>
    {onBackClick && (
      <StyledButton onClick={onBackClick} buttonType='tertiary' title={backTitle}>
        <StyledChevronLeftIcon />
      </StyledButton>
    )}
    {children}
  </Container>
)

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.spaces.l};
`

const StyledButton = styled(Button)`
  height: 24px;
  padding: ${props => props.theme.spaces.m};
  margin-right: ${props => props.theme.spaces.s};
`

const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  width: 24px;
  height: 24px;
`
