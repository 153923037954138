import { LineChartValueKey } from '../types'

export const chartColorPalette = [
  '#FFA600',
  '#FF7D22',
  '#F65338',
  '#E3234B',
  '#C6005B',
  '#A00067',
  '#71006F',
  '#36006F',
]

export const getLineChartColor = (key: LineChartValueKey) => {
  if (key === 'headcountMonthly') return '#4D009D'
  if (key === 'joinersMonthly') return '#FFA600'
  if (key === 'leaversMonthly') return '#F65338'
  return '#4D009D'
}
