import { useState } from 'react'
import styled from 'styled-components'

import { ExportChart, ExportChartOrNode, SelectedNode } from '../../export-chart'
import { ExportPremiumBenefits, PremiumBenefits } from 'screens/settings/components'
import { FlexBox, Modal, ModalProps } from 'components/generic'

import { getIsSubscribed } from 'features/premium'
import { ChartDataContextSafeProvider, ChartIdContextSafeProvider } from 'tree/providers'
import { ChartDetail, Node, NodeTypename } from 'tree/types'
import { ModalTypeFactory, ModalComponentProps } from 'modal'
import { COMPANY_UUID } from 'consts'
import { spaces } from 'theme'

type Props = ModalComponentProps & {
  chartData: ChartDetail
  nodeName: string
  nodeTypename: NodeTypename
  rootId: Node['id']
  goToPremiumScreen: () => void
}
export type ExportChartModalType = ModalTypeFactory<'exportChartModal', Props>

const getModalTitle = ({ id, name, __typename }: SelectedNode) => {
  const isCompany = id === COMPANY_UUID
  const personAddition = `'s team`
  const departmentAddition = ' department'
  const addition = __typename === 'Person' ? personAddition : __typename === 'Department' ? departmentAddition : ''
  const title = `Export ${isCompany ? 'Org Chart' : `${name}${addition}`}`
  return title
}

export const ExportChartModal = (props: Props) => (
  <ChartDataContextSafeProvider value={props.chartData}>
    <ChartIdContextSafeProvider value={props.chartData.id}>
      <ExportChartModalInner {...props} />
    </ChartIdContextSafeProvider>
  </ChartDataContextSafeProvider>
)

const ExportChartModalInner = ({ chartData, nodeName, nodeTypename, rootId, onClose, goToPremiumScreen }: Props) => {
  const [modalTitle, setModalTitle] = useState(getModalTitle({ id: rootId, name: nodeName, __typename: nodeTypename }))
  const isSubscribed = getIsSubscribed(chartData.subscription)

  return (
    <StyledModal title={{ text: modalTitle }} size='small' onClose={onClose}>
      {isSubscribed ? (
        <ExportChartOrNode
          exportNodeId={rootId}
          searchValue={nodeName}
          onNodeSelect={selectedNode => setModalTitle(getModalTitle(selectedNode))}
        />
      ) : (
        <>
          <ExportChart rootId={rootId} goToPremiumScreen={goToPremiumScreen} />
          <BenefitsContainer $isDirectionColumn $spacing={{ pt: spaces.xxl }}>
            <ExportPremiumBenefits />
            <PremiumBenefits spacing={{ mt: spaces.xxl }} />
          </BenefitsContainer>
        </>
      )}
    </StyledModal>
  )
}

const StyledModal = styled((props: ModalProps) => <Modal {...props} />)`
  && {
    width: 100%;
    max-width: 800px;
  }
`

const BenefitsContainer = styled(FlexBox)`
  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    padding-right: 52px;
  }
`
