import { ReactNode, LabelHTMLAttributes } from 'react'
import styled from 'styled-components'
import { MdCheckCircleOutline } from 'react-icons/md'

import { FileDropLabel, FlexBox, IconButton } from 'components/generic'
import { Button } from 'components/generic/button'
import { CloseIcon, FileIcon } from 'components/icons'

import { spaces } from 'theme'

type Props = {
  file: File | null | undefined
  labelProps: LabelHTMLAttributes<HTMLLabelElement>
  uploadDescription: string
  uploadedDescription: string
  uploadedTitle: string
  uploadTitle: string
  className?: string
  fileIcon?: ReactNode
  onDelete: () => void
}

export const FileUploader = ({
  className,
  file,
  fileIcon,
  labelProps,
  uploadDescription,
  uploadedDescription,
  uploadedTitle,
  uploadTitle,
  onDelete,
}: Props) => (
  <FlexBox>
    {file && (
      <StyledIconButton size='small' onClick={() => onDelete()}>
        <CloseIcon width='12px' />
      </StyledIconButton>
    )}

    <FileDropLabel className={className} {...labelProps}>
      {file ? (
        <>
          <FlexBox $spacing={{ mb: spaces.m }}>
            <MdCheckCircleOutline fontSize={32} color='green' />
          </FlexBox>
          <Title>{uploadedTitle}</Title>
          <FileName>{file?.name}</FileName>
          <Description>{uploadedDescription}</Description>
          <StyledButton
            buttonType='secondary'
            buttonSize='small'
            spacing={{ mt: spaces.m }}
            // prevents with pointer-events: none; double upload window on button click
            onClick={e => e.stopPropagation()}
          >
            Upload new file
          </StyledButton>
        </>
      ) : (
        <>
          {fileIcon ?? <FileIcon $spacing={{ mb: spaces.l }} />}
          <Title>{uploadTitle}</Title>
          <Description>{uploadDescription}</Description>
        </>
      )}
    </FileDropLabel>
  </FlexBox>
)

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: ${props => props.theme.spaces.s};
  right: ${props => props.theme.spaces.s};
  color: ${props => props.theme.colors.dark};

  &:hover {
    color: ${props => props.theme.colors.purple};
  }
`

const Title = styled.div`
  margin-bottom: ${props => props.theme.spaces.s};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`

const Description = styled.div`
  color: ${props => props.theme.colors.border};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`

const FileName = styled.div`
  margin-bottom: ${props => props.theme.spaces.s};
  font-size: 13px;
  font-weight: 500;
`

const StyledButton = styled(Button)`
  pointer-events: none;
`
