import { Button } from 'components/generic'
import { PlusIcon } from 'components/icons'

import { colors, spaces } from 'theme'

type Props = {
  className?: string
  onClick: () => void
}

export const ButtonNewChart = ({ className, onClick }: Props) => (
  <Button className={className} onClick={onClick} data-testid='button-create-a-new-org-chart'>
    <PlusIcon width='14px' color={colors.white} $spacing={{ mr: spaces.m, mb: spaces.xs }} />
    Create new Org Chart
  </Button>
)
