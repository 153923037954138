import { useEffect, useMemo } from 'react'

import { getNodeHeight, getNodeWidth } from '../helpers'
import { useTree } from 'tree/providers'
import { CustomConnectionField, CustomConnectionLineStyle, NodeTypename } from 'tree/types'
import { useWatch } from 'tree/hooks'

type Props = {
  areNodesReducedHeight: boolean
  id: string
  isCompact: boolean
  nodeType: NodeTypename
  parentId: string
  lineColor: CustomConnectionField['lineColor']
  lineStyle: CustomConnectionLineStyle
}

const STROKE_SIZE = 2

const STROKE_DASHARRAYS: Record<CustomConnectionLineStyle, unknown> = {
  dotted: STROKE_SIZE,
  dashed: STROKE_SIZE * 2,
  solid: 0,
}

export const NodeConnectionLine = (props: Props) => {
  // Also triggers re-render which recalculates connection line on DOM changes
  const nodesInDom = useWatch({ name: 'nodesInDom' })
  const parentNodeEl = nodesInDom.includes(props.parentId)

  if (!parentNodeEl) {
    return null
  }

  return <NodeConnectionLineContent {...props} />
}

const NodeConnectionLineContent = (props: Props) => {
  const { areNodesReducedHeight, id, isCompact, lineColor, lineStyle, nodeType, parentId } = props
  const { nodeRefs, treeBoundariesRef, unregisterConnection, registerConnection } = useTree()
  const nodesInDom = useWatch({ name: 'nodesInDom' })
  const compact = useWatch({ name: 'compact' })
  const zoom = useWatch({ name: 'zoom' })

  const w = useMemo(() => parseFloat(getNodeWidth(isCompact, nodeType, false)), [isCompact, nodeType])
  const h = useMemo(
    () => parseFloat(getNodeHeight(isCompact, areNodesReducedHeight)),
    [isCompact, areNodesReducedHeight]
  )

  // Node
  const nodeEl = nodeRefs.current[id]?.ref.current
  const parentNodeEl = nodeRefs.current[parentId]?.ref.current

  useEffect(() => {
    const tree = treeBoundariesRef.current
    if (!tree) {
      return
    }

    const connectionId = `${id}-${parentId}`
    const nodeRect = nodeEl?.getBoundingClientRect()
    const parentNodeRect = parentNodeEl?.getBoundingClientRect()
    if (nodeRect && parentNodeRect) {
      const getZoomValue = (val: number) => val / zoom
      const { y, x } = tree.getBoundingClientRect()

      // Connection line
      const strokeDasharray = STROKE_DASHARRAYS[lineStyle]
      const x1 = getZoomValue(nodeRect?.left ?? 0)
      const y1 = getZoomValue(nodeRect?.top ?? 0)
      const x2 = getZoomValue(parentNodeRect?.left ?? 0)
      const y2 = getZoomValue(parentNodeRect?.top ?? 0)

      const width = Math.max(2, Math.abs(x2 - x1))
      const height = Math.max(2, Math.abs(y2 - y1))

      const top = Math.min(y1, y2) + h / 2
      const left = Math.min(x1, x2) + w / 2

      registerConnection(connectionId, {
        top: top - getZoomValue(y),
        left: left - getZoomValue(x),
        width,
        height,
        x1,
        y1,
        x2,
        y2,
        strokeColor: lineColor,
        strokeDasharray: String(strokeDasharray),
      })
    }

    return () => {
      unregisterConnection(connectionId)
    }
  }, [id, parentId, nodeEl, parentNodeEl, compact, nodesInDom])

  return null
}
