export const useNumberOfInsightsVisits = () => {
  const getNumberOfInsightsVisits = (chartUuid: string) => {
    const numOfVisitsJSON = window.localStorage.getItem(`number-of-insights-visits-${chartUuid}`)
    const numOfVisits = numOfVisitsJSON && JSON.parse(numOfVisitsJSON)
    return typeof numOfVisits === 'number' ? numOfVisits : 0
  }

  const setNumberOfInsightsVisits = (chartUuid: string, numberOfInsightsVisits: number) => {
    window.localStorage.setItem(`number-of-insights-visits-${chartUuid}`, JSON.stringify(numberOfInsightsVisits))
  }

  return { getNumberOfInsightsVisits, setNumberOfInsightsVisits }
}
