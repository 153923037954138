import {
  ReactNode,
  useRef,
  TouchEvent,
  MouseEvent,
  useState,
  useCallback,
  WheelEvent,
  DragEvent,
  useEffect,
} from 'react'
import styled from 'styled-components'
import rafSchd from 'raf-schd'

import { useWatch } from 'tree/hooks'
import { useTree } from 'tree/providers'
import {
  getPadRect,
  getContainerHeight,
  getMidpoint,
  getDistanceBetweenPoints,
  getPointFromTouch,
  getExponentialStep,
} from 'helpers'

let prevTouchClientX = 0
let prevTouchClientY = 0
let lastDistance = 0
let startHeight = 0
let startY = 0

let isPinching = false
let debounceSetOffsetTimeout: number | undefined
let debounceIsTouchingTimeout: number | undefined

const THRESHOLD = 100

type Props = {
  children: ReactNode
}

export const PositionPad = ({ children }: Props) => {
  const { padRef, setZoom, setPosition } = useTree()
  const throttledSetPosition = rafSchd(setPosition)
  const movingPadRef = useRef<HTMLDivElement>(null)
  const isTouching = useRef(false)
  const [isMouseDown, setIsMouseDown] = useState(false)

  // only changes based on request animation frame, udpates all visuals
  const positionX = useWatch({ name: 'positionX' })
  const positionY = useWatch({ name: 'positionY' })
  const zoomValue = useWatch({ name: 'zoom' })

  // current position
  const currPositionX = useRef(positionX)
  const currPositionY = useRef(positionY)

  // updates current position when position changes from other source (e.g. zoom)
  useEffect(() => {
    if (positionX !== currPositionX.current) currPositionX.current = positionX
    if (positionY !== currPositionY.current) currPositionY.current = positionY
  }, [positionX, positionY])

  const setPositionInternal = useCallback(
    (x: number, y: number) => {
      currPositionX.current = x
      currPositionY.current = y
      throttledSetPosition(x, y)
    },
    [throttledSetPosition]
  )

  const handleTouchStart = useCallback(({ touches }: TouchEvent<HTMLDivElement>) => {
    const isTouch = touches.length === 1
    const isPinch = touches.length === 2

    if (isTouch && !isPinching) {
      const touch = touches[0]
      prevTouchClientX = touch.clientX
      prevTouchClientY = touch.clientY
    } else if (isPinch) {
      isPinching = true
      const pointA = getPointFromTouch(touches[0])
      const pointB = getPointFromTouch(touches[1])
      const midpoint = getMidpoint(pointA, pointB)
      startHeight = getContainerHeight(movingPadRef)
      startY = currPositionY.current - midpoint.y
      lastDistance = getDistanceBetweenPoints(pointA, pointB)
    }
  }, [])

  const handleTouchMove = useCallback(
    ({ touches }: TouchEvent<HTMLDivElement>) => {
      const isTouch = touches.length === 1
      const isPinch = touches.length === 2

      if (isTouch && !isPinching) {
        const touch = touches[0]
        if (touch) {
          const { clientX, clientY } = touch
          setPositionInternal(
            (clientX - prevTouchClientX) * (1 / zoomValue) + currPositionX.current,
            (clientY - prevTouchClientY) * (1 / zoomValue) + currPositionY.current
          )
          prevTouchClientX = clientX
          prevTouchClientY = clientY
        }
      } else if (isPinch) {
        isPinching = true
        const pointA = getPointFromTouch(touches[0])
        const pointB = getPointFromTouch(touches[1])
        const distance = getDistanceBetweenPoints(pointA, pointB)
        const zoom = zoomValue * (distance / lastDistance)
        const midpoint = getMidpoint(pointA, pointB)

        const zoomSteps = 10
        const step = startHeight / zoomSteps / 3
        const nextY = startY + midpoint.y + (1 - zoom) * zoomSteps * step

        setZoom(zoom)
        setPositionInternal(currPositionX.current, nextY)
        lastDistance = distance
      }
    },
    [zoomValue, setPositionInternal, setZoom]
  )

  const handleTouchEnd = useCallback(() => {
    setTimeout(() => {
      isPinching = false
    }, 100)
  }, [])

  const handleScroll = useCallback(
    ({ deltaX, deltaY }: WheelEvent<HTMLDivElement>) => {
      setPositionInternal(currPositionX.current + deltaX * -1, currPositionY.current + deltaY * -1)
    },
    [setPositionInternal]
  )

  const handleMouseMove = useCallback(
    ({ movementY, movementX }: MouseEvent) => {
      if (isMouseDown) {
        setPositionInternal(
          currPositionX.current + movementX * (1 / zoomValue),
          currPositionY.current + movementY * (1 / zoomValue)
        )
      }
    },
    [isMouseDown, zoomValue, setPositionInternal]
  )

  const handleDragOver = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      const { clientX, clientY } = event
      event.preventDefault()

      clearTimeout(debounceIsTouchingTimeout)
      if (!isTouching.current) isTouching.current = true
      debounceIsTouchingTimeout = Number(setTimeout(() => (isTouching.current = false), 200))

      let dx = 0
      let dy = 0

      const { left, width, top, height } = getPadRect(padRef)
      const step = 5

      // Move X
      const leftDiff = clientX - left
      const rightDiff = left + width - clientX
      if (leftDiff < THRESHOLD) {
        dx = getExponentialStep(step, leftDiff, THRESHOLD)
      } else if (rightDiff < THRESHOLD) {
        dx = -getExponentialStep(step, rightDiff, THRESHOLD)
      }

      // Move Y
      const topDiff = clientY - top
      const bottomDiff = top + height - clientY
      if (topDiff < THRESHOLD) {
        dy = getExponentialStep(step, topDiff, THRESHOLD)
      } else if (bottomDiff < THRESHOLD) {
        dy = -getExponentialStep(step, bottomDiff, THRESHOLD)
      }

      // Debounce setOffset state change => It will smooth out scrolling
      if (debounceSetOffsetTimeout) {
        return
      }
      debounceSetOffsetTimeout = Number(
        setTimeout(function () {
          setPositionInternal(currPositionX.current + dx, currPositionY.current + dy)
          clearTimeout(debounceSetOffsetTimeout)
          debounceSetOffsetTimeout = undefined
        }, 5)
      )

      return false
    },
    [padRef, setPositionInternal]
  )

  return (
    <Pad
      $isMouseDown={isMouseDown}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onTouchCancel={handleTouchEnd}
      onWheel={handleScroll}
      onMouseMove={handleMouseMove}
      onMouseUp={() => isMouseDown && setIsMouseDown(false)}
      onMouseDown={() => !isMouseDown && setIsMouseDown(true)}
      onMouseLeave={() => isMouseDown && setIsMouseDown(false)}
      onDragOver={handleDragOver}
      ref={padRef}
    >
      <MovingPad $positionX={positionX} $positionY={positionY} $zoom={zoomValue} ref={movingPadRef}>
        {children}
      </MovingPad>
    </Pad>
  )
}

type PadProps = { $isMouseDown: boolean }
const Pad = styled.div.attrs<PadProps>(({ $isMouseDown }) => ({
  style: { cursor: $isMouseDown ? 'move' : 'default' },
}))<PadProps>`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: ${props => props.theme.colors.greyExtraLight};
  user-select: none;
  overflow: hidden;
  touch-action: none;
`

type MovingPadProps = { $positionX: number; $positionY: number; $zoom: number }
const MovingPad = styled.div.attrs<MovingPadProps>(({ $positionX, $positionY, $zoom }) => ({
  // react-dnd -> causes chart node drag preview on Safari behave incorrectly
  style: { transform: `scale(${$zoom}) translate3d(${$positionX}px, ${$positionY}px, 0px)` },
}))<MovingPadProps>`
  position: relative;
  box-sizing: border-box;
`
