import styled, { css } from 'styled-components'

import { ButtonNewChart } from './button-new-chart'
import { ChartCard } from 'components/specific'

import { QuotaProInfo, QuotaExceeded, useQuotaFor, getIsSubscribed } from 'features/premium'
import { useAuth } from 'auth'
import { pluralize } from 'helpers'
import { spaces } from 'theme'

import { ChartFragmentFragment } from 'apollo/generated/graphql'

type ChartListProps = {
  charts: readonly ChartFragmentFragment[]
  onCreateChartClick: () => void
}

export const ChartList = ({ charts, onCreateChartClick }: ChartListProps) => {
  const { user } = useAuth()
  const upgradableCharts = charts.filter(c => c?.subscription?.upgradable)
  const { checkQuotas } = useQuotaFor([user, ...upgradableCharts])
  const quotaWarningResults = checkQuotas().filter(q => q.status === 'warning')
  const isSubscribed = getIsSubscribed(user.subscription)
  const showQuotaProInfo = !isSubscribed && quotaWarningResults.length === 0
  const showQuotaExceeded = user.subscription?.upgradable && quotaWarningResults.length > 0

  return (
    <ChartsContainer chartsCount={charts.length}>
      {showQuotaProInfo && <StyledQuotaProInfo />}
      {showQuotaExceeded && <StyledQuotaExceeded quotaResults={quotaWarningResults} />}

      <CreateContainer>
        <Heading>
          {charts.length} Org {pluralize('Chart', charts.length)}
        </Heading>
        <ButtonNewChart onClick={onCreateChartClick} />
      </CreateContainer>

      {charts.map(chart => (
        <ChartCard key={chart.id} {...chart} />
      ))}

      {/* TODO: Awaiting Unaric response if they want to add their custom banner there */}
      {/* {charts.map((chart, index) => {
        const showAdBanner = index === 0
        return (
          <Fragment key={chart.uuid}>
            <ChartCard {...chart} />
            {showAdBanner && (
              <ChartCardBanner isSubscribed={isSubscribed} isOverusing={quotaWarningResults.length > 0} />
            )}
          </Fragment>
        )
      })} */}
    </ChartsContainer>
  )
}

const GAP_SIZE = spaces.l

const StyledQuotaProInfo = styled(QuotaProInfo)`
  grid-column: 1 / -1;
  margin-bottom: ${props => props.theme.spaces.l};
`
const StyledQuotaExceeded = styled(QuotaExceeded)`
  grid-column: 1 / -1;
  margin-bottom: ${props => props.theme.spaces.l};
`

const ChartsContainer = styled.div<{ chartsCount: number }>`
  align-self: flex-start;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 500px));
  justify-content: center;
  gap: ${GAP_SIZE};
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.xl} ${props => props.theme.spaces.l};

  ${props =>
    props.chartsCount === 1 &&
    css`
      max-width: 1200px;
      margin-right: auto;
      margin-left: auto;
    `}

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    padding-right: ${props => props.theme.spaces.xxl};
    padding-left: ${props => props.theme.spaces.xxl};
  }
`

const CreateContainer = styled.div`
  grid-column: -1 / 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: calc(${props => props.theme.spaces.xl} - ${GAP_SIZE});

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Heading = styled.div`
  margin-bottom: ${props => props.theme.spaces.m};
  color: ${props => props.theme.colors.dark};
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    margin-bottom: 0;
  }
`
