import { gql } from '@apollo/client'
import { insightFragment } from '../fragments'

export const getInsights = gql`
  query insights($chartUuid: String!) {
    insights(chartUuid: $chartUuid) {
      items {
        ...InsightFragment
      }
    }
  }
  ${insightFragment}
`
