import styled from 'styled-components'
import { useAuth } from 'auth'

import {
  ORG_CHART_UNARIC_DPA_URL,
  ORG_CHART_UNARIC_PRIVACY_POLICY,
  ORG_CHART_UNARIC_TERMS_CONDITIONS_URL,
} from 'consts'
import { ButtonGoogle } from 'components/specific'
import { LinkStyles } from 'components/generic'
import { Link } from 'components/generic/link'
import { ContactLink } from 'screens/invite/components'

type Props = {
  redirectPath?: string
  onWhiteBackground?: boolean
}

export const LoginSignupButtonsGroup = ({ redirectPath, onWhiteBackground }: Props) => {
  const { loginWithRedirect } = useAuth()
  const loginAttempts = parseInt(sessionStorage.getItem('loginAttempts') ?? '0')

  return (
    <>
      <ButtonGoogle
        data-testid='button-log-in-with-google'
        onClick={() => loginWithRedirect({ connection: 'google-oauth2', redirectPath })}
        spacing={{ mb: 8 }}
      >
        Continue with Google
      </ButtonGoogle>

      <Divider>
        <DividerCircle $onWhiteBackground={onWhiteBackground}>or</DividerCircle>
      </Divider>

      <LoginText>
        <LoginLink
          onClick={() => loginWithRedirect({ mode: 'login', redirectPath })}
          data-testid='button-log-in-with-email'
        >
          Log in with email and password
        </LoginLink>
      </LoginText>

      <PolicyText>
        By logging in, you agree with{' '}
        <PolicyLink href={ORG_CHART_UNARIC_TERMS_CONDITIONS_URL} target='_blank'>
          Terms and Conditions
        </PolicyLink>{' '}
        and{' '}
        <PolicyLink href={ORG_CHART_UNARIC_DPA_URL} target='_blank'>
          DPA
        </PolicyLink>
        . Our privacy policy can be viewed <PolicyLink href={ORG_CHART_UNARIC_PRIVACY_POLICY}>here</PolicyLink>.
      </PolicyText>

      {loginAttempts > 0 && (
        <LoginProblemText>
          <div>Having trouble logging in?</div>
          <ContactLink
            intercomMsg='Hello, I have a problem to Log in. Can you help me in this matter please?'
            capFirstLetter
          />
        </LoginProblemText>
      )}
    </>
  )
}

const LoginText = styled.div`
  display: flex;
  align-self: center;
  font-size: 13px;
  color: ${props => props.theme.colors.greyDark};
  white-space: pre;
  margin-bottom: ${props => props.theme.spaces.xl};
`

const LoginLink = styled.div`
  ${LinkStyles}
  color: ${props => props.theme.colors.linkColor};
  font-weight: 500;
`

const PolicyText = styled.div`
  max-width: 310px;
  margin: 0 auto 12px;
  margin-bottom: ${props => props.theme.spaces.xxl};
  color: ${props => props.theme.colors.greyLight};
  font-size: 11px;
  line-height: 18px;
  text-align: center;
`

const PolicyLink = styled(Link)`
  color: ${props => props.theme.colors.greyLight};
  box-shadow: inset 0px -1px 0px ${props => props.theme.colors.greyExtraLight};

  &:hover {
    box-shadow: inset 0px -1px 0px ${props => props.theme.colors.greyLight};
  }
`

const Divider = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${props => props.theme.spaces.m};

  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 1px;
    background: ${props => props.theme.colors.greyExtraLight};
    transform: translate(-50%, -50%);
  }
`

const DividerCircle = styled.div<{ $onWhiteBackground?: boolean }>`
  position: relative;
  width: 37px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${props => (props.$onWhiteBackground ? props.theme.colors.white : props.theme.colors.neutralSecondary)};
  color: ${props => props.theme.colors.greyLight};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  z-index: 2;
`

const LoginProblemText = styled(LoginText)`
  width: auto;
  align-self: center;
  flex-direction: column;
  align-items: center;
  border: 2px solid ${props => props.theme.colors.purple};
  padding: 10px;
  border-radius: 10px;
  line-height: 18px;
  font-size: 14px;
`
