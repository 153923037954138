import { SyntheticEvent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { applySpacingProps, SpacingProps } from 'helpers'

type Animation = 'fill' | 'opacity'
type Size = 'small' | 'medium' | 'big' | 'extraBig'
type Type = 'primary' | 'secondary' | 'dark'
export type IconButtonProps = {
  animation?: Animation
  children?: ReactNode
  className?: string
  disabled?: boolean
  /** Default: true */
  isClickable?: boolean
  /** Default: medium */
  size?: Size
  spacing?: SpacingProps
  type?: Type
  onClick?: (e: SyntheticEvent) => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  // data-testid
}

export const IconButton = ({
  animation = 'fill',
  isClickable = true,
  size = 'medium',
  children,
  ...rest
}: IconButtonProps) => (
  <IconButtonInner animation={animation} isClickable={isClickable} size={size} {...rest}>
    {children}
  </IconButtonInner>
)

const SIZES: Record<Size, string> = {
  small: '24px',
  medium: '30px',
  big: '34px',
  extraBig: '40px',
}

type IconButtonInnerProps = IconButtonProps & {
  isClickable: boolean
  size: Size
}

const IconButtonInner = styled.div.withConfig<IconButtonInnerProps>({
  shouldForwardProp: prop => !['animation', 'size', 'spacing', 'type', 'isClickable'].includes(prop),
})`
  position: relative;
  min-width: ${props => SIZES[props.size]};
  width: ${props => SIZES[props.size]};
  min-height: ${props => SIZES[props.size]};
  height: ${props => SIZES[props.size]};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 3px;
  color: inherit;
  user-select: none;
  cursor: pointer;
  z-index: 2;

  ${props => props.type === 'secondary' && typeSecondaryCSS}
  ${props => props.type === 'dark' && typeDarkCSS}
  

  ${props => props.animation === 'fill' && !props.disabled && props.theme.animations.backgroundShapeFill};
  ${props => props.animation === 'opacity' && !props.disabled && opacityAnimationCSS}

  cursor: ${props => (props.isClickable ? 'pointer' : 'default')};
  ${props => props.disabled && disabledCSS}

  ${props => applySpacingProps(props.spacing)}
`

const typeSecondaryCSS = css`
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.border};
  color: ${props => props.theme.colors.dark};
`

const typeDarkCSS = css`
  color: ${props => props.theme.colors.white};

  &:hover {
    color: ${props => props.theme.colors.dark};
  }
`

const opacityAnimationCSS = css`
  opacity: 0.85;
  animation: opacity ${props => props.theme.transitions.fastEase};

  &:hover {
    opacity: 1;
  }
`

const disabledCSS = css`
  cursor: not-allowed;
  color: ${props => props.theme.colors.greyLightSecond};
  border-color: ${props => props.theme.colors.greyExtraLight};
`
