import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import CountUp from 'react-countup'

import { Button, Link } from 'components/generic'
import { DollarIcon } from 'components/icons'

import { useCompanyOverview } from '../../hooks'
import { KpiValue } from '../../types'

type ValueType = 'time' | 'percent' | 'money' // time in minutes, money as dollars
type Props = {
  value?: KpiValue | undefined
  valType?: ValueType
  desc?: string
  icon?: ReactNode
  moreInfoNeed?: boolean
}

export const Kpi = ({ value, valType, desc, icon, moreInfoNeed }: Props) => {
  const { activeDep } = useCompanyOverview()

  if (moreInfoNeed && valType === 'money') {
    return (
      <Container isGolden>
        <StyledDollarIcon />
        <Title>Estimated cost</Title>
        <Info>Can&apos;t show you estimated cost before you fill in few details about your company.</Info>
        <StyledButton onClick={() => undefined}>Fill details to see Estimated cost</StyledButton>
      </Container>
    )
  }

  if (moreInfoNeed) {
    return (
      <Container moreInfoNeed>
        <Icon isBlocked>{icon}</Icon>
        <Info>
          You have to fill in few details before we can show you more. <Link href='#'>Click here</Link>
        </Info>
      </Container>
    )
  }

  if (!value)
    return (
      <Container moreInfoNeed>
        <Info>{`No data avaible for ${activeDep}`}</Info>
      </Container>
    )

  return (
    <Container>
      <Icon>{icon}</Icon>
      <Value>{getFormatedValue(value, valType)}</Value>
      <Description>{desc}</Description>
    </Container>
  )
}

const Icon = styled.div<{ isBlocked?: boolean }>`
  color: #4d009d;
  display: flex;
  margin-top: 25px;
  margin-bottom: 15px;

  ${props =>
    props.isBlocked &&
    css`
      color: #b2b3c1;
      transform: scale(1.5);
      margin-top: 40px;
    `};
`

const Container = styled.div<{ moreInfoNeed?: boolean; isGolden?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 0 0 33.333333%;
  flex-shrink: 1;
  position: relative;
  height: 176px;
  box-shadow: 0px 0px 6px rgba(38, 15, 63, 0.08);
  border-radius: 6px;
  overflow: hidden;
  background: ${props => props.theme.colors.white};

  ${props =>
    props.isGolden &&
    css`
      justify-content: center;
      background: #fed20a;
      color: ${props => props.theme.colors.dark};
    `};
`

const Value = styled.div`
  font-weight: 500;
  font-size: 24px;
`

const Title = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 500;
  z-index: 2;
`

const Description = styled.div`
  color: #83869c;
  font-size: 16px;
  max-width: 350px;
  margin-top: 10px;
  line-height: 24px;
`

const Info = styled.div`
  margin-bottom: 15px;
  z-index: 2;
  font-size: 16px;
  max-width: 350px;
  margin-top: 10px;
  line-height: 24px;
`

const StyledButton = styled(Button)`
  border-radius: 3px;
  background-color: #ffffff;
  color: ${props => props.theme.colors.dark};
  transition: background-color 0.25s ease-in-out;
  z-index: 2;
  &:hover {
    background-color: #fdefb2;
  }
`

const StyledDollarIcon = styled(DollarIcon)`
  position: absolute;
  right: -5px;
  color: #ffffff;
`

export const getFormatedValue = (value: number, type?: ValueType) => {
  if (!type) return <CountUp end={value} duration={1} preserveValue />
  if (type === 'percent') return <CountUp end={value} duration={1} suffix='%' preserveValue />
  if (type === 'money') return <CountUp end={value} duration={1} prefix='$ ' preserveValue />
  if (type === 'time') {
    const hours = Math.floor(value / 60)
    const minutes = value % 60
    return (
      <>
        {hours > 0 && <CountUp end={hours} duration={1} suffix='h ' preserveValue />}
        {minutes > 0 && <CountUp end={minutes} duration={1} suffix=' min' preserveValue />}
      </>
    )
  }
}
