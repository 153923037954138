import analytics from 'analytics'
import { DeepPartial, RequiredPick } from 'types'

import { Chart } from 'apollo/generated/graphql'

type ChartPartial = DeepPartial<Chart> & RequiredPick<Chart, 'employeeCount' | 'unassignedEmployeeCount'>
type Charts = readonly ChartPartial[] | ChartPartial[]

export const getMaxChartPeopleCount = (charts: Charts): number | undefined => {
  if (charts.length === 0) {
    return undefined
  }
  const peopleList = charts.map(chart => chart.employeeCount + chart.unassignedEmployeeCount)
  return Math.max(...peopleList)
}

export const updateChartCountsInAnalytics = (charts: Charts) => {
  const maxChartPeopleCount = getMaxChartPeopleCount(charts)
  analytics.setUserProperties({
    'Max chart people count': maxChartPeopleCount,
    'Chart count': charts.length,
  })
}
