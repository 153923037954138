import { Dispatch, MouseEvent, SetStateAction } from 'react'
import { useFormikContext } from 'formik'
import styled from 'styled-components'

import { ConnectWithGoogleWorkspace } from 'components/specific'
import { Badge, CollapsePanel, FileUploader, TilButton } from 'components/generic'

import { CreateChartOptionType, Scope } from '../types'
import { useGoogleWorkspace } from 'tree/hooks'
import { ChartFormValues } from 'tree/types'
import { useCsvUpload } from 'hooks'
import analytics from 'analytics'
import { ORG_TRACK, PERMISSIONS_TRACK } from 'analytics/constants'
import * as csv from 'csv'
import { CREATE_CHART_PATH } from 'routes'
import { spaces } from 'theme'

import GoogleWorkspaceIconSrc from 'assets/google-workspace-logo.png'

type Props = {
  loading: boolean
  optionType?: CreateChartOptionType
  setOptionType: Dispatch<SetStateAction<CreateChartOptionType | undefined>>
}

export const StepFirst = ({ loading, optionType, setOptionType }: Props) => {
  const { values, isSubmitting, isValid, handleChange, setFieldValue } = useFormikContext<ChartFormValues>()

  const { authorize } = useGoogleWorkspace()
  const connectGSuite = async (values: ChartFormValues) => {
    const { syncEmployeeInformation, syncCalendar } = values

    const scopes: Scope[] = []
    scopes.push(syncEmployeeInformation ? 'user' : 'userReadonly')
    if (syncCalendar) scopes.push('calendarReadonly')

    analytics.track(PERMISSIONS_TRACK.connectGsuite)
    authorize({ scopes, redirectPath: CREATE_CHART_PATH })
  }

  const handleFileChange = (file?: File) => {
    analytics.track(ORG_TRACK.createNewChartFileSelected)
    setFieldValue('file', file)
  }

  const { getRootProps } = useCsvUpload({
    onFileChange: handleFileChange,
  })

  const handleDownloadCSVTemplate = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault()
    analytics.track(ORG_TRACK.createChartDownloadSampleTemp)
    csv.download([csv.SAMPLE_DATA], 'OrgChart_Template.csv', 'template')
  }

  return (
    <>
      <PanelsContainer $hasGap={!optionType}>
        <CollapsePanel
          title={
            <GsuiteTitle>
              <GoogleWorkspaceIcon src={GoogleWorkspaceIconSrc} />
              <StyledBadge $color='purple' $capitalized $animate>
                Most time saving
              </StyledBadge>
            </GsuiteTitle>
          }
          subtitle='Connect with Google Workspace'
          isActive={optionType === 'gsuite'}
          isHidden={optionType && optionType !== 'gsuite'}
          onClick={() => setOptionType('gsuite')}
        >
          <ConnectWithGoogleWorkspace
            syncEmployeeInformation={Boolean(values.syncEmployeeInformation)}
            syncCalendar={Boolean(values.syncCalendar)}
            onChange={handleChange}
          />
        </CollapsePanel>

        <CollapsePanel
          title='CSV File'
          subtitle='Import team members'
          isActive={optionType === 'file'}
          isHidden={optionType && optionType !== 'file'}
          onClick={() => setOptionType('file')}
        >
          <FileUploader
            uploadTitle='Select CSV to upload'
            uploadedTitle='Your file'
            uploadDescription='You can drag and drop.'
            uploadedDescription='You can delete and upload new CSV file.'
            file={values.file}
            labelProps={getRootProps()}
            onDelete={() => setFieldValue('file', undefined)}
          />
          {!values.file && (
            <DownloadTemplateButton type='outlined' onClick={handleDownloadCSVTemplate}>
              Download template
            </DownloadTemplateButton>
          )}
        </CollapsePanel>

        <CollapsePanel
          title='Create new chart'
          subtitle='Create empty Org Chart'
          isActive={optionType === 'empty'}
          isHidden={optionType && optionType !== 'empty'}
          onClick={() => setOptionType('empty')}
        />
      </PanelsContainer>

      {optionType === 'gsuite' && (
        <TilButton
          disabled={loading || isSubmitting || !isValid}
          isSubmitButton
          spacing={{ mt: spaces.xl }}
          onClick={e => {
            e.preventDefault()
            connectGSuite(values)
          }}
          data-testid='button-create-chart-gsuite'
        >
          Create Org Chart
        </TilButton>
      )}
    </>
  )
}

const PanelsContainer = styled.div<{ $hasGap?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${props => props.$hasGap && `gap: ${props.theme.spaces.l}`};
  animation: ${props => props.theme.keyframes.opacity} ${props => props.theme.transitions.mediumEase};
`

const GsuiteTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
`

const StyledBadge = styled(Badge)`
  font-size: 11px;
  line-height: initial;
  margin-left: 8px;
  padding: 4px 6px;
  cursor: pointer;
`

const GoogleWorkspaceIcon = styled.img`
  height: 20px;
`

const DownloadTemplateButton = styled(TilButton)`
  margin-top: ${props => props.theme.spaces.l};
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.purpleLighter};
  }
`
