import Select, { ActionMeta, OptionProps, StylesConfig, OnChangeValue } from 'react-select'
import styled from 'styled-components'

import {
  FieldContainer,
  FieldTitle,
  FlexBox,
  selectComponents,
  selectMenuBottomMargin,
  selectStyles,
} from 'components/generic'
import { AvatarCircleMiniature, RowNode } from 'components/specific'
import { ProfileIcon } from 'components/icons'

import { useFullTextSearch } from 'hooks'
import { useChartId } from 'tree/providers'
import { dropdownMenu } from 'helpers'
import { CustomConnectionLineValue } from 'tree/types'
import { spaces } from 'theme'

import { NodeSearchDataFragmentFragment } from 'apollo/generated/graphql'

type OptionType = Partial<NodeSearchDataFragmentFragment> & CustomConnectionLineValue

type Props = {
  title: string
  onChange: (value: OnChangeValue<OptionType, true>, actionMeta: ActionMeta<OptionType>) => void
  className?: string
  defaultValue?: readonly OptionType[] | OptionType[]
  excludeId?: string
}

export const CustomConnectionField = ({ className, title, defaultValue, excludeId, onChange }: Props) => {
  const chartId = useChartId()
  const {
    data: options,
    isSearching,
    search,
  } = useFullTextSearch({ chartUuid: chartId, filter: { unassigned: false, excludeId } })

  return (
    <FieldContainer className={className}>
      <FieldTitle title={title} />
      <Select<OptionType, true>
        menuPosition='fixed'
        menuPortalTarget={dropdownMenu} // Portaled due to Safari bug which hides fixed positioned element if their parent has overflow hidden -> https://bugs.webkit.org/show_bug.cgi?id=160953
        defaultValue={defaultValue}
        options={options}
        menuPlacement='auto'
        placeholder='Search name, email address or job title'
        filterOption={() => true}
        getOptionValue={({ id }) => id}
        getOptionLabel={({ name }) => name}
        isLoading={isSearching}
        loadingMessage={() => 'Searching ...'}
        noOptionsMessage={({ inputValue }) => {
          return inputValue.length < 2 ? 'Type at least 2 characters for search' : 'No results'
        }}
        styles={customStyles}
        isMulti
        isClearable
        onInputChange={search}
        onChange={onChange}
        components={{
          Control: selectComponents.Control,
          ClearIndicator: selectComponents.ClearIndicator,
          Placeholder: selectComponents.Placeholder,
          Option,
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        }}
      />
    </FieldContainer>
  )
}

const customStyles: StylesConfig<any, boolean> = {
  ...selectStyles,
  menu: (provided, props) => ({
    ...selectStyles.menu?.(provided, props),
    ...selectMenuBottomMargin,
  }),
}

const Option = ({ data: node, innerProps, innerRef }: OptionProps<OptionType, true>) => {
  if (node.__typename !== 'Person') {
    return null
  }

  const { name, position, email } = node
  const icon = <AvatarCircleMiniature src={node.image} fallbackIcon={<StyledProfileIcon />} />

  return (
    <StyledFlexBox {...innerProps} ref={innerRef}>
      <RowNode
        title={name}
        description={position}
        email={email || ''}
        icon={icon}
        spacing={{ p: spaces.xs }}
        isClickable
      />
    </StyledFlexBox>
  )
}

const StyledProfileIcon = styled(ProfileIcon)`
  width: 16px;
  height: auto;
  color: ${props => props.theme.colors.border};
`

const StyledFlexBox = styled(FlexBox)`
  margin-bottom: ${props => props.theme.spaces.s};
  cursor: pointer;
`
