import { EmployeeForm } from './form'
import { NodeEditContainer, NodeEditTitle, useHideIntercomOnEditEnter } from '../../node-components'

import { useChartId } from 'tree/providers'
import {
  PersonOnCreationDetail,
  OnPersonFormSubmit,
  PersonDetail,
  PersonFormValues,
  PersonFormCapabilities,
  CustomFieldValueObject,
} from 'tree/types'
import { Mutable } from 'types'

import { PersonCapabilities } from 'apollo/generated/graphql'

type Props = {
  parentUuid: string | null
  onClose: () => void
  onSubmit: OnPersonFormSubmit
  createMode?: boolean
  person?: PersonDetail
  personOnCreation?: PersonOnCreationDetail
  onCancel?: () => void
}

const getFullName = ({ givenName, familyName }: { givenName?: string | null; familyName?: string | null }) => {
  if (!givenName) return familyName || 'employee'
  if (!familyName) return givenName || 'employee'
  return givenName + ' ' + familyName
}

export const EmployeeEdit = (props: Props) => {
  const { createMode, parentUuid, onClose, onSubmit, person, personOnCreation, onCancel } = props
  useHideIntercomOnEditEnter()
  const chartUuid = useChartId()
  const customFields = person?.customFields || personOnCreation?.customFields || []
  const customFieldsObject: CustomFieldValueObject = customFields.reduce<CustomFieldValueObject>((obj, customField) => {
    obj[customField.id] = customField
    return obj
  }, {})
  const personFullName = getFullName({ givenName: person?.givenName, familyName: person?.familyName })

  const {
    buildingId,
    costCenter,
    deskCode,
    email,
    emailAliases,
    employeeId,
    employeeType,
    familyName,
    floorName,
    floorSection,
    givenName,
    image,
    location,
    mobile,
    position,
    xLink,
    linkedInLink,
    youTubeLink,
    facebookLink,
    whatsAppLink,
    instagramLink,
  } = person || {}

  const initialValues: PersonFormValues = {
    buildingId: buildingId || '',
    costCenter: costCenter || '',
    deskCode: deskCode || '',
    email: email || '',
    emailAliases: (emailAliases as Mutable<PersonFormValues['emailAliases']>) || [],
    employeeId: employeeId || '',
    employeeType: employeeType || '',
    familyName: familyName || '',
    floorName: floorName || '',
    floorSection: floorSection || '',
    givenName: givenName || '',
    image: image || '',
    location: location || '',
    mobile: mobile || '',
    position: position || '',
    // values outside of person
    moveToChart: false,
    customFields: customFieldsObject,
    parentUuid,
    xLink: xLink || '',
    linkedInLink: linkedInLink || '',
    youTubeLink: youTubeLink || '',
    facebookLink: facebookLink || '',
    instagramLink: instagramLink || '',
    whatsAppLink: whatsAppLink || '',
  }

  const getCapability = (capability: Exclude<keyof PersonCapabilities, '__typename'>) => {
    if (createMode || (person?.capabilities as PersonCapabilities)[capability]) return true
    return false
  }

  const capabilities: PersonFormCapabilities = {
    canModifyBuildingId: getCapability('canUpdateBuildingId'),
    canModifyCostCenter: getCapability('canUpdateCostCenter'),
    canModifyDeskCode: getCapability('canUpdateDeskCode'),
    canModifyEmail: getCapability('canUpdateEmail'),
    canModifyEmailAliases: getCapability('canUpdateEmailAliases'),
    canModifyEmployeeId: getCapability('canUpdateEmployeeId'),
    canModifyEmployeeType: getCapability('canUpdateEmployeeType'),
    canModifyFloorName: getCapability('canUpdateFloorName'),
    canModifyFloorSection: getCapability('canUpdateFloorSection'),
    canModifyImage: getCapability('canUpdateImage'),
    canModifyLocation: getCapability('canUpdateLocation'),
    canModifyMobile: getCapability('canUpdateMobile'),
    canModifyName: getCapability('canUpdateName'),
    canModifyPosition: getCapability('canUpdatePosition'),
    canModifySocialContacts: getCapability('canUpdateSocialContacts'),
    canMove: getCapability('canMove'),
    canUpdateSocialContacts: getCapability('canUpdateSocialContacts'),
  }

  return (
    <NodeEditContainer>
      <NodeEditTitle
        title={createMode ? 'Add new employee' : `Editing: ${personFullName}`}
        onBack={createMode ? undefined : onCancel}
        onClose={onClose}
      />
      <EmployeeForm
        initialValues={initialValues}
        person={person}
        chartUuid={chartUuid}
        capabilities={capabilities}
        parentUuid={parentUuid}
        onSubmit={onSubmit}
        onClose={onClose}
        onCancel={onCancel}
      />
    </NodeEditContainer>
  )
}
