import { SVG, SVGProps } from './SVG'

export const LockIcon = (props: SVGProps) => (
  <SVG width='12' height='15' viewBox='0 0 12 15' fill='none' {...props}>
    <path
      d='M9.99972 5.33329H9.33306V3.99996C9.33306 2.15996 7.83972 0.666626 5.99972 0.666626C4.15972 0.666626 2.66639 2.15996 2.66639 3.99996V5.33329H1.99972C1.26639 5.33329 0.666389 5.93329 0.666389 6.66663V13.3333C0.666389 14.0666 1.26639 14.6666 1.99972 14.6666H9.99972C10.7331 14.6666 11.3331 14.0666 11.3331 13.3333V6.66663C11.3331 5.93329 10.7331 5.33329 9.99972 5.33329ZM3.99972 3.99996C3.99972 2.89329 4.89306 1.99996 5.99972 1.99996C7.10639 1.99996 7.99972 2.89329 7.99972 3.99996V5.33329H3.99972V3.99996ZM9.99972 13.3333H1.99972V6.66663H9.99972V13.3333ZM5.99972 11.3333C6.73306 11.3333 7.33306 10.7333 7.33306 9.99996C7.33306 9.26663 6.73306 8.66663 5.99972 8.66663C5.26639 8.66663 4.66639 9.26663 4.66639 9.99996C4.66639 10.7333 5.26639 11.3333 5.99972 11.3333Z'
      fill='currentColor'
    />
  </SVG>
)
