export const getItemFromLocalStorage = (itemName: string) => {
  const item = localStorage.getItem(itemName)
  return item ? JSON.parse(item) : undefined
}

export const setItemInLocalStorage = (itemName: string, itemValue: unknown) => {
  localStorage.setItem(itemName, JSON.stringify(itemValue))
}

export const deleteItemFromLocalStorage = (itemName: string) => {
  localStorage.removeItem(itemName)
}
