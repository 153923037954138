import { gql } from '@apollo/client'
import { personChildDepartmentsDataFragment } from '../fragments'

export const nodeChildDepartments = gql`
  query nodeChildDepartments($chartUuid: String!, $uuid: String!) {
    node(chartUuid: $chartUuid, uuid: $uuid) {
      ...PersonChildDepartmentsDataFragment
    }
  }
  ${personChildDepartmentsDataFragment}
`
