import { useCallback, useState } from 'react'

import { getChartLockColors } from '../helpers/get-chart-lock-colors'
import { useTimeCountdown } from 'hooks'
import { TimeRemaining } from 'helpers'

type Params = {
  chartLockDate: Date | string
}

type Result = {
  timeRemaining: TimeRemaining
  background: string
  clockBackground: string
}

export const useChartLockCountdown = ({ chartLockDate }: Params): Result => {
  const onTimeUpdate = useCallback((timeRemaining: TimeRemaining) => {
    const { background, clock } = getChartLockColors(timeRemaining)
    setBackground(background)
    setClockBackground(clock)
  }, [])

  const { timeRemaining } = useTimeCountdown({
    toDate: chartLockDate,
    updateInterval: 'minute',
    onUpdate: onTimeUpdate,
  })

  const [background, setBackground] = useState(getChartLockColors(timeRemaining).background)
  const [clockBackground, setClockBackground] = useState(getChartLockColors(timeRemaining).clock)

  return { timeRemaining, background, clockBackground }
}
