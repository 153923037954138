import { SVG, SVGProps } from './SVG'

export const GraphBarIcon = (props: SVGProps) => (
  <SVG width='14' height='14' viewBox='0 0 14 14' fill='none' {...props}>
    <path
      d='M0.777832 4.5111H3.4445L3.4445 13.2222H0.777832L0.777832 4.5111ZM5.75561 0.777771L8.2445 0.777771V13.2222H5.75561V0.777771ZM10.7334 7.88888H13.2223V13.2222H10.7334V7.88888Z'
      fill='currentColor'
    />
  </SVG>
)
