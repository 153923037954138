import { useCallback } from 'react'

import { useDeleteOrUnassignNode, useRefetchPersonTeamSize } from 'tree/hooks'
import { useChartId } from 'tree/providers'
import { findSuperiorPerson } from 'helpers'

import { NodeDataFragmentFragment, useDeleteNodeMutation } from 'apollo/generated/graphql'

type DeleteNodeRequestParams = {
  node: Partial<NodeDataFragmentFragment> & Pick<NodeDataFragmentFragment, 'uuid' | 'parentUuid' | 'parentNodes'>
}

export const useDeleteNodeRequest = () => {
  const chartUuid = useChartId()
  const [deleteNodeMutation] = useDeleteNodeMutation()
  const { deleteOrUnassignNode } = useDeleteOrUnassignNode()
  const { isGsuiteProvider, refetchPersonTeamSize } = useRefetchPersonTeamSize()

  const deleteNodeRequest = useCallback(
    ({ node }: DeleteNodeRequestParams) => {
      return deleteNodeMutation({
        variables: { chartUuid, uuid: node.uuid },
        update: (cache, { data }) => {
          const mutationNodes = data?.deleteNode || []
          const prevData = node
          const prevParentUuid = node.parentUuid || null
          deleteOrUnassignNode({ cache, mutationNodes, prevData, prevParentUuid })

          // Refetch teamSize
          if (isGsuiteProvider) {
            const previousSuperiorPerson = findSuperiorPerson(node)
            refetchPersonTeamSize(previousSuperiorPerson?.id)
          }
        },
      })
    },
    [chartUuid, deleteNodeMutation, deleteOrUnassignNode, refetchPersonTeamSize]
  )

  return { deleteNodeRequest }
}
