import { KeyboardEvent } from 'react'
import Select, { InputActionMeta, MultiValue } from 'react-select'
import { ErrorMessage } from 'formik'
import styled from 'styled-components'

import { FieldContainer, FieldErrorMessage, FieldTitle } from './components'
import { selectComponents, selectStyles } from '../select'
import { Link } from '../link'

import { useChartPermission } from 'tree/hooks'
import { useChartData } from 'tree/providers'
import { getIsSubscribed } from 'features/premium'
import { validateIpAddress } from 'helpers'
import { colors } from 'theme'

export const FIELD_PUBLIC_LINK_IP_RESTRICT_LOWEST_SUBNET = 24

type Props = {
  name: string
  ips: string[] | readonly string[]
  inputValue: string
  onIpsChange: (ips: MultiValue<string>) => void
  onInputChange: (value: string) => void
  onError: (error: string) => void
}

const TitleWithExample = () => (
  <>
    Allow access only to the following IP addresses
    <TitleExplanation>
      (Use{' '}
      <Link
        href='https://en.wikipedia.org/wiki/Classless_Inter-Domain_Routing#CIDR_notation'
        target='_blank'
        color={colors.greyLight}
      >
        CIDR notation
      </Link>
      . Example: 192.100.10.0/32)
    </TitleExplanation>
  </>
)

export const FieldPublicLinkIpRestrict = ({ name, ips, inputValue, onIpsChange, onInputChange, onError }: Props) => {
  const { canUpdatePublicLink } = useChartPermission()
  const title = canUpdatePublicLink ? <TitleWithExample /> : 'Allowed access only to the following IP addresses'
  const placeholder = canUpdatePublicLink
    ? 'Use space or comma (,) to add multiple IP addresses'
    : 'All IP addresses allowed'

  const addIpAddress = (ipAddress: string) => {
    const error = validateIpAddress({
      ipAddress,
      hasSubnet: true,
      subnetLowest: FIELD_PUBLIC_LINK_IP_RESTRICT_LOWEST_SUBNET,
    })
    if (error) return onError(error)

    onInputChange('')
    onIpsChange([...ips, ipAddress])
  }

  const { subscription } = useChartData()
  const isSubscribed = getIsSubscribed(subscription)
  const handleInputChange = (v: string, { action }: InputActionMeta) => {
    // Prevent input value change from other actions (like blur)
    if (action !== 'input-change') {
      return
    }
    // Prevent adding IP addresses for unsubscribed users
    if (!isSubscribed) {
      onError('Only PRO users are allowed to add IP addresses. Please upgrade to PRO.')
      return
    }

    const lastChar = v.slice(-1)
    const hasSpace = lastChar === ' '
    const hasComma = lastChar === ','

    if (hasSpace || hasComma) {
      const ipAddress = v.split(hasSpace ? ' ' : ',')[0]
      addIpAddress(ipAddress)
    } else {
      // Prevents space in the beggining when copy pasting
      const trimmedV = v.trim()
      onInputChange(trimmedV)
    }
  }

  const handleBlur = () => {
    if (inputValue) addIpAddress(inputValue)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    const hitEnter = e.code === 'Enter'
    if (hitEnter && inputValue) addIpAddress(inputValue)
  }

  return (
    <FieldContainer>
      <FieldTitle title={title} size='big' />
      <Select<string, true>
        value={ips}
        inputValue={inputValue}
        placeholder={placeholder}
        styles={selectStyles}
        menuIsOpen={false}
        getOptionLabel={ipAddress => ipAddress}
        getOptionValue={ipAddress => ipAddress}
        isDisabled={!canUpdatePublicLink}
        isMulti
        isClearable
        onInputChange={handleInputChange}
        onChange={v => onIpsChange(v)} // Called only on IP address deletion (adding is controlled outside select)
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        components={{
          Control: selectComponents.Control,
          ClearIndicator: selectComponents.ClearIndicator,
          Placeholder: selectComponents.Placeholder,
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        }}
      />
      <ErrorMessage name={name}>{message => (message ? <FieldErrorMessage message={message} /> : null)}</ErrorMessage>
    </FieldContainer>
  )
}

const TitleExplanation = styled.div`
  margin-left: ${props => props.theme.spaces.s};
  color: ${props => props.theme.colors.greyLight};
`
