import styled, { css } from 'styled-components'

import { getNodeHeight, getNodeWidth } from '../../helpers'
import { NodeTypename } from 'tree/types'

type Props = {
  $canDoActions: boolean
  $compact: boolean
  $type: NodeTypename
  $isOver?: boolean
  $isReducedHeight?: boolean
}

export const Node = styled.div.attrs<Props>(({ $compact, $type, $isReducedHeight }) => ({
  style: {
    height: getNodeHeight($compact, $isReducedHeight),
    width: getNodeWidth($compact, $type, true),
  },
}))<Props>`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 ${props => props.theme.spaces.m};
  /* "auto" prevents creating stacking context which breaks displaying of connection line */
  z-index: ${props => (props.$isOver ? '3' : 'auto')};

  &:hover {
    z-index: 5;
  }

  ${props =>
    props.$canDoActions &&
    css`
      @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
        &:hover .node-inner {
          width: ${getNodeWidth(props.$compact, props.$type, true)};
        }
      }
    `}
`
