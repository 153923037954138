import mixpanel from './mixpanel'
import intercom from './intercom'
import gtm from './gtm'
import { getCookiesEnabled } from './cookie-consent'

import config from '../config'

import { clientAppVersion } from 'helpers'
import { TrackKeyName } from 'types'

const { MIXPANEL_ENABLED, MIXPANEL_TOKEN, MIXPANEL_PROXY_URI, INTERCOM_ENABLED, INTERCOM_APP_ID, GTM_ENABLED, GTM_ID } =
  config

type InitParams = {
  createdAt: Date | undefined
  email: string | undefined
  isSubscribed: boolean
  name: string | undefined
}
export const init = ({ createdAt, email, isSubscribed, name }: InitParams) => {
  const isAuthenticated = Boolean(email?.length)
  if (GTM_ENABLED) gtm.init(GTM_ID)
  if (MIXPANEL_ENABLED && (isAuthenticated || getCookiesEnabled('analytics'))) {
    mixpanel.init(MIXPANEL_TOKEN, MIXPANEL_PROXY_URI)
    mixpanel.identify(name, email)
  }
  if (INTERCOM_ENABLED && (isSubscribed || getCookiesEnabled('functional'))) {
    intercom.init(INTERCOM_APP_ID, name, email, createdAt)
  }
}

export const setUserProperties = (properties: Record<string, any>) => {
  if (MIXPANEL_ENABLED) mixpanel.setUserProperties(properties)
  if (INTERCOM_ENABLED) intercom.setUserProperties(properties)
}

export const track = (name: TrackKeyName, properties?: Record<string, any>) => {
  const newProperties = { ...properties, clientAppVersion }
  if (MIXPANEL_ENABLED) mixpanel.track(name, newProperties)
  if (INTERCOM_ENABLED && getCookiesEnabled('analytics')) intercom.track(name, newProperties)
  if (GTM_ENABLED && getCookiesEnabled('analytics')) gtm.track(name, newProperties)
}

export default {
  init,
  setUserProperties,
  track,
}
