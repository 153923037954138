import { SVG, SVGProps } from './SVG'

export const TargetIcon = (props: SVGProps) => (
  <SVG width='18' height='18' viewBox='0 0 18 18' fill='none' {...props}>
    <path
      d='M9 6C9.79565 6 10.5587 6.31607 11.1213 6.87868C11.6839 7.44129 12 8.20435 12 9C12 9.79565 11.6839 10.5587 11.1213 11.1213C10.5587 11.6839 9.79565 12 9 12C8.20435 12 7.44129 11.6839 6.87868 11.1213C6.31607 10.5587 6 9.79565 6 9C6 8.20435 6.31607 7.44129 6.87868 6.87868C7.44129 6.31607 8.20435 6 9 6ZM2.2875 9.75H0.75V8.25H2.2875C2.625 5.1225 5.1225 2.625 8.25 2.2875V0.75H9.75V2.2875C12.8775 2.625 15.375 5.1225 15.7125 8.25H17.25V9.75H15.7125C15.375 12.8775 12.8775 15.375 9.75 15.7125V17.25H8.25V15.7125C5.1225 15.375 2.625 12.8775 2.2875 9.75ZM9 3.75C7.60761 3.75 6.27226 4.30312 5.28769 5.28769C4.30312 6.27226 3.75 7.60761 3.75 9C3.75 10.3924 4.30312 11.7277 5.28769 12.7123C6.27226 13.6969 7.60761 14.25 9 14.25C10.3924 14.25 11.7277 13.6969 12.7123 12.7123C13.6969 11.7277 14.25 10.3924 14.25 9C14.25 7.60761 13.6969 6.27226 12.7123 5.28769C11.7277 4.30312 10.3924 3.75 9 3.75Z'
      fill='currentColor'
    />
  </SVG>
)
