import { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  icon: ReactNode
  title: string
  children: ReactNode
}

export const OptionCard = ({ icon, title, children }: Props) => (
  <Card>
    <Icon>{icon}</Icon>
    <Title>{title}</Title>
    {children}
  </Card>
)

const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.l};
  text-align: center;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 6px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    flex-direction: row;
  }
`

const Icon = styled.div`
  width: 64px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${props => props.theme.spaces.l};

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    flex-direction: row;
    margin-bottom: 0;
  }
`

const Title = styled.div`
  margin-bottom: ${props => props.theme.spaces.m};
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-left: ${props => props.theme.spaces.xl};
    margin-bottom: 0;
  }
`
