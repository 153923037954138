import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { Modal, ModalProps } from 'components/generic/modal/modal'
import { ModalComponentProps, ModalTypeFactory } from 'modal'
import { ModalWindowSize } from 'components/generic/modal/components'
import { Button } from 'components/generic'
import { mainTheme } from 'theme'
import { SpacingProps, applySpacingProps } from 'helpers'
import { Title } from '../insights-modals/styled'

type Props = ModalComponentProps & {
  content: ReactNode
  preTitleContent?: ReactNode
  title?: string | ReactNode
  titleMaxWidth?: string
  actionsArea?: ReactNode
  modalStep?: string
  size?: ModalWindowSize
  isUnclosable?: boolean
  isOverflowVisible?: boolean
  hasLineSeparator?: boolean
  isCentered?: boolean
  tilBackground?: boolean
  contentSpacingProps?: SpacingProps
  actionsAreaSpacingProps?: SpacingProps
  onClick?: () => void
}

export type TilModalType = ModalTypeFactory<'tilModal', Props>
export type ModalContentType = TilModalType['properties']

export const TilModal = ({
  content,
  preTitleContent,
  title,
  titleMaxWidth,
  actionsArea,
  modalStep,
  size = 'small',
  isUnclosable,
  isOverflowVisible,
  hasLineSeparator,
  isCentered,
  tilBackground,
  contentSpacingProps,
  actionsAreaSpacingProps,
  onClose,
}: Props) => {
  return (
    <StyledModal
      size={size}
      onClose={isUnclosable ? () => undefined : onClose}
      closeButton={!isUnclosable}
      $tilBackground={tilBackground}
      $isOverflowVisible={isOverflowVisible}
    >
      {modalStep && <StepCounter>{modalStep}</StepCounter>}
      <Content spacing={contentSpacingProps}>
        <Wrapper $isCentered={isCentered}>{preTitleContent}</Wrapper>
        {title && (
          <Title $maxWidth={titleMaxWidth} $isCentered={isCentered}>
            {title}
          </Title>
        )}
        <Wrapper $isCentered={isCentered}>{content}</Wrapper>
      </Content>
      {actionsArea ? (
        <>
          {hasLineSeparator && <LineSeparator />}
          <ActionsArea spacing={actionsAreaSpacingProps} $isCentered={isCentered}>
            {actionsArea}
          </ActionsArea>
        </>
      ) : (
        <Button onClick={onClose}>Ok, I understand</Button>
      )}
    </StyledModal>
  )
}

const StyledModal = styled((props: ModalProps & { $isOverflowVisible?: boolean; $tilBackground?: boolean }) => (
  <Modal {...props} />
))`
  && {
    height: auto;
    width: 498px;
    border-radius: 6px;
    padding: 30px 25px 25px 30px;
    box-shadow: 0px 0px 6px rgba(38, 15, 63, 0.08);

    ${props =>
      props.size === 'medium' &&
      css`
        width: 536px;
        height: 315px;
      `};

    ${props =>
      props.$isOverflowVisible &&
      css`
        overflow: visible;
      `};

    ${props =>
      props.$tilBackground &&
      css`
        background: radial-gradient(61.94% 61.94% at 22.56% 10.39%, #3c0675 0%, #4d009d 100%);
      `};
  }
`

const StepCounter = styled.div`
  display: inline-block;
  color: #ffffff;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 500;
  height: 23px;
  padding: ${props => props.theme.spaces.s} ${props => props.theme.spaces.m};
  margin-bottom: 20px;
  line-height: 23px;
  background: ${mainTheme.colors.purpleLighter};
`

const Content = styled.div<{ spacing?: SpacingProps }>`
  ${props => applySpacingProps(props.spacing)}
`

const LineSeparator = styled.div`
  position: absolute;
  height: 1px;
  width: 100%;
  background: #f1f5f7;
  margin-left: -30px;
`

const centerAttrsCSS = css`
  justify-content: center;
  margin: auto;
  text-align: center;
`

const ActionsArea = styled.div<{ spacing?: SpacingProps; $isCentered?: boolean }>`
  display: flex;
  align-items: center;
  height: 40px;
  padding-top: calc(20px + 1px); /* +1px border height which is absolute */

  ${props => props.$isCentered && centerAttrsCSS}
  ${props => applySpacingProps(props.spacing)}
`

const Wrapper = styled.div<{ $isCentered?: boolean }>`
  display: flex;

  ${props => props.$isCentered && centerAttrsCSS}
`
