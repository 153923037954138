import styled from 'styled-components'

import { BaseText, Button } from 'components/generic'

import { useNode } from 'tree/hooks'
import { useCompanyNodeState } from 'tree/providers'
import { useGoToAddDepartment, useGoToAddEmployee } from 'routes'
import { spaces } from 'theme'

export const CreateFirstNode = () => {
  const node = useCompanyNodeState()
  const { canDrop, isOver, setNodeInnerRefs } = useNode(node)

  const { goToAddEmployee } = useGoToAddEmployee()
  const { goToAddDepartment } = useGoToAddDepartment()

  return (
    <CreateFirstNodeInner isOver={isOver} canDrop={canDrop} ref={setNodeInnerRefs}>
      <Text>There are no employees or departments in your Org Chart.</Text>
      <StyledButton
        spacing={{ mb: spaces.m }}
        onClick={() => goToAddEmployee('null')}
        data-testid='button-lets-create-employee'
      >
        Add employee
      </StyledButton>
      <StyledButton onClick={() => goToAddDepartment('null')} data-testid='button-lets-create-department'>
        Add department
      </StyledButton>
    </CreateFirstNodeInner>
  )
}

const CreateFirstNodeInner = styled.div<{ isOver: boolean; canDrop: boolean }>`
  min-width: 213px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.colors.white};
  padding: ${props => props.theme.spaces.l} ${props => props.theme.spaces.m};
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgb(69 90 100 / 16%);
  transform: ${({ isOver, canDrop }) => (isOver && canDrop ? `scale(1.05)` : 'none')};
  transform-origin: center;
  transition: transform 0.2s;
`

const Text = styled(BaseText)`
  max-width: 200px;
  margin-bottom: ${props => props.theme.spaces.l};
  text-align: center;
  font-size: 14px;
  line-height: 20px;
`

const StyledButton = styled(Button)`
  min-width: 170px;
`
