import { ChartDataProcessed, LineChartValueKey } from '../../types'
import { chartColorPalette, getLineChartColor, lineChartLabels } from '../../constants'
import { lineDatasetAttrs, barDatasetAttrs } from '../../components/charts/constants'
import { parseLabel } from '../../utils'

import { mod } from 'helpers'

import {
  InsightDataset,
  InsightDatasetMulti,
  InsightDatasetSingle,
  InsightDatasetValueSingle,
} from 'apollo/generated/graphql'

export const datasetValueSingleToChart = (values: InsightDatasetSingle['values'] = []) =>
  values.reduce(
    (obj, dsValue: any) => {
      const { labels, data, colors } = obj
      labels.push(parseLabel(dsValue.l).toString())
      data.push(dsValue.v)
      colors.push(dsValue.c)

      return obj
    },
    {
      labels: [] as string[],
      data: [] as number[],
      colors: [] as string[],
    }
  )

type InsightDatasetValueSingleEnhanced = InsightDatasetValueSingle & { c: string }
export const datasetToBarChart = (ds?: InsightDataset, chartUuid?: string): ChartDataProcessed | undefined => {
  if (ds?.__typename !== 'InsightDatasetSingle') return undefined

  const enhancedData = ds.values
    .filter(dsValue => !(dsValue.f.dep === chartUuid || dsValue.l === 'insights.all_departments'))
    .map((dsValue, i) => {
      const cIndex = mod(i, chartColorPalette.length)
      return {
        ...dsValue,
        c: chartColorPalette[cIndex],
      }
    })
    .sort(
      (a: InsightDatasetValueSingleEnhanced, b: InsightDatasetValueSingleEnhanced) =>
        b.v - a.v || a.l.localeCompare(b.l)
    )
    .slice(0, 40)

  const { data, labels, colors } = datasetValueSingleToChart(enhancedData)

  return {
    labels,
    datasets: [
      {
        ...barDatasetAttrs,
        label: '',
        backgroundColor: colors,
        data,
      },
    ],
  }
}

export const datasetValueMultiToChart = (values: InsightDatasetMulti['values'] = [], chartUuid: string) => {
  const allCompany = values.filter(value => value.f.dep === chartUuid)
  return allCompany.map(value =>
    datasetValueSingleToChart(
      value.d.map(({ l, v }) => ({
        __typename: 'InsightDatasetValueSingle',
        f: value.f,
        l: parseLabel(l).toString(),
        v,
      }))
    )
  )[0]
}

export const datasetToLineChart = (dsList: InsightDataset[] = [], chartUuid: string): ChartDataProcessed | undefined =>
  dsList
    .flatMap(ds => (ds?.__typename === 'InsightDatasetMulti' ? [ds] : []))
    .reduce(
      (obj, ds) => {
        const res = datasetValueMultiToChart(ds.values, chartUuid)
        if (!res) return { labels: [] as string[], datasets: [] as any[] }
        const { labels, data } = res

        if (obj.labels.length === 0) {
          obj.labels = labels
        }

        const color = getLineChartColor(ds.id as any)
        obj.datasets.push({
          ...lineDatasetAttrs,
          label: lineChartLabels[ds.id as LineChartValueKey],
          borderColor: color,
          pointBackgroundColor: color,
          data,
        })
        return obj
      },
      {
        labels: [] as string[],
        datasets: [] as any[],
      }
    )
