export const getCustomFieldsValueName = (fieldId: string) => {
  return `customFields.${fieldId}.value`
}

export const getCustomFieldsValuesName = (fieldId: string) => {
  return `customFields.${fieldId}.values`
}

export const getCustomFieldsOptionsName = (fieldId: string) => {
  return `customFields.${fieldId}.options`
}
