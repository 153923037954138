import styled from 'styled-components'

import { Button } from 'components/generic'

type Props = {
  isSubmitDisabled: boolean
  onCancel: () => void
  onSubmit: () => void
}

export const NodeFormButtons = ({ isSubmitDisabled, onCancel, onSubmit }: Props) => (
  <Container>
    <Button buttonType='secondary' buttonSize='small' onClick={onCancel} data-testid='button-node-form-cancel'>
      Cancel
    </Button>
    <Button buttonSize='small' disabled={isSubmitDisabled} onClick={onSubmit} data-testid='button-node-form-save'>
      Save
    </Button>
    <HiddenSubmitInput type='submit' />
  </Container>
)

const Container = styled.div`
  bottom: 0;
  position: fixed;
  width: 100%;
  max-width: 458px;
  height: ${props => props.theme.sizes.chartSidePanelFormButtons};
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.m} ${props => props.theme.spaces.l};
  margin-left: -${props => props.theme.spaces.l};
  background: ${props => props.theme.colors.white};
  z-index: 10;

  &::before {
    content: '';
    position: absolute;
    bottom: calc(100% - 2px);
    left: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(0deg, ${props => props.theme.colors.white} 0%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
  }
`

const HiddenSubmitInput = styled.input`
  display: none;
`
