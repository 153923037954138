import { convertToInternationalPhoneNumber } from './convert-to-international-phone-number'
import { WHATSAPP_CLICK_TO_CHAT_LINK } from 'consts'

// WhatsApp Documentation: https://faq.whatsapp.com/5913398998672934
export const getWhatsappLink = (phoneNumber: string) => {
  const internationalPhoneNumber = convertToInternationalPhoneNumber(phoneNumber)
  const whatsappLink = `${WHATSAPP_CLICK_TO_CHAT_LINK}/${internationalPhoneNumber}`

  return whatsappLink
}
