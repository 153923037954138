import { SVG, SVGProps } from './SVG'

export const CalendarIcon = (props: SVGProps) => (
  <SVG width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M9.125 1.125C9.125 0.501562 8.62344 0 8 0C7.37656 0 6.875 0.501562 6.875 1.125V3H5C3.34531 3 2 4.34531 2 6V6.75V9V21C2 22.6547 3.34531 24 5 24H20C21.6547 24 23 22.6547 23 21V9V6.75V6C23 4.34531 21.6547 3 20 3H18.125V1.125C18.125 0.501562 17.6234 0 17 0C16.3766 0 15.875 0.501562 15.875 1.125V3H9.125V1.125ZM4.25 9H8V11.625H4.25V9ZM4.25 13.875H8V16.875H4.25V13.875ZM10.25 13.875H14.75V16.875H10.25V13.875ZM17 13.875H20.75V16.875H17V13.875ZM20.75 11.625H17V9H20.75V11.625ZM20.75 19.125V21C20.75 21.4125 20.4125 21.75 20 21.75H17V19.125H20.75ZM14.75 19.125V21.75H10.25V19.125H14.75ZM8 19.125V21.75H5C4.5875 21.75 4.25 21.4125 4.25 21V19.125H8ZM14.75 11.625H10.25V9H14.75V11.625Z'
      fill='currentColor'
    />
  </SVG>
)
