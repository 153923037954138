import { SVG, SVGProps } from './SVG'

export const OrgchartSymbolLogo = (props: SVGProps) => (
  <SVG width='24' height='19' viewBox='0 0 24 19' fill='none' {...props}>
    <path
      d='M11.7142 12.6945L5.85712 6.84033L-8.35944e-06 12.6945L5.85712 18.5488L11.7142 12.6945Z'
      fill='currentColor'
    />
    <path d='M23.3107 12.6945L17.4536 6.84033L11.5965 12.6945L17.4536 18.5488L23.3107 12.6945Z' fill='currentColor' />
    <path
      d='M5.85704 5.24334C7.22945 5.24334 8.34201 4.13134 8.34201 2.75961C8.34201 1.38788 7.22945 0.275879 5.85704 0.275879C4.48463 0.275879 3.37207 1.38788 3.37207 2.75961C3.37207 4.13134 4.48463 5.24334 5.85704 5.24334Z'
      fill='currentColor'
    />
    <path
      d='M17.4537 5.24334C18.8261 5.24334 19.9386 4.13134 19.9386 2.75961C19.9386 1.38788 18.8261 0.275879 17.4537 0.275879C16.0812 0.275879 14.9687 1.38788 14.9687 2.75961C14.9687 4.13134 16.0812 5.24334 17.4537 5.24334Z'
      fill='currentColor'
    />
  </SVG>
)
