import { ApolloCache } from '@apollo/client'

import { useTree } from 'tree/providers'

import { getNodeNormalizedId } from 'apollo'
import { nodes as nodesQuery } from 'apollo/query'
import { nodeDataFragment } from 'apollo/fragments'
import { NodeDataFragmentFragment, NodesQuery, NodesQueryVariables } from 'apollo/generated/graphql'

type Params = {
  cache: ApolloCache<any>
  createdNode: NodeDataFragmentFragment
  chartUuid: string
}

export const useUpdateNodeQuery = () => {
  const { writeNodesToCache } = useTree()

  const updateNodeQuery = ({ cache, createdNode, chartUuid }: Params) => {
    const parentUuid = createdNode.parentUuid || null
    const cachedNodes = cache.readQuery<NodesQuery, NodesQueryVariables>({
      query: nodesQuery,
      variables: { chartKey: chartUuid, filter: { unassigned: false, parentUuid } },
    })
    if (cachedNodes) {
      const newItems = [...cachedNodes.nodes.items, createdNode]
      writeNodesToCache(chartUuid, parentUuid, newItems)
    } else {
      // Cache query for parent node if it has 0 subordinates to prevent unnnecessary fetch
      const parentId = getNodeNormalizedId(parentUuid, cache)
      if (!parentId) return

      const parentNode = cache.readFragment<NodeDataFragmentFragment>({
        id: parentId,
        fragment: nodeDataFragment,
        fragmentName: 'NodeDataFragment',
      })
      if (!parentNode) return

      const { employeeCount, departmentCount } = parentNode
      if (employeeCount + departmentCount === 0) writeNodesToCache(chartUuid, parentUuid, [createdNode])
    }
  }

  return { updateNodeQuery }
}
