import { Dispatch, ReactNode, SetStateAction, useState } from 'react'

import { createSafeContext } from 'helpers'

type Context = {
  showLauncher: boolean
  setShowLauncher: Dispatch<SetStateAction<boolean>>
}
export const [useIntercom, EmptyIntercomProvider] = createSafeContext<Context>()

type Props = {
  children: ReactNode
}

export const IntercomSafeProvider = ({ children }: Props) => {
  const [showLauncher, setShowLauncher] = useState(true)
  return <EmptyIntercomProvider value={{ showLauncher, setShowLauncher }}>{children}</EmptyIntercomProvider>
}
