import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { useSlider } from 'hooks'

import slide1 from 'assets/create-orgchart-slide-1.png'
import slide2 from 'assets/create-orgchart-slide-2.png'
import slide3 from 'assets/create-orgchart-slide-3.png'

const SliderImage = styled.img<{ $hasShadow?: boolean }>`
  width: 100%;
  max-width: 600px;
  border-radius: 10.5px;

  ${props =>
    props.$hasShadow &&
    css`
      box-shadow: 0px 4.27px 6.406px -2.135px rgba(16, 24, 40, 0.05),
        0px 12.811px 17.082px -4.27px rgba(16, 24, 40, 0.1);
    `}
`

type Slide = {
  heading: string
  description: string
  image: ReactNode
}

const slides: Slide[] = [
  {
    heading: 'Intuitive org charting with dotted line reporting',
    description:
      "Creating a realistic Org Chart reflecting how your organization works is very important. By default, we let you show direct relationships in your organization. Use our PRO Dotted Line functionality to showcase the dotted line reporting between people who aren't in direct relationships.",
    image: <SliderImage src={slide1} $hasShadow />,
  },
  {
    heading: 'Effortless Org Charts with Google Workspace Sync',
    description:
      'Connect your org chart builder directly to Google Workspace. Enjoy real-time, automatic updates from your G-Directory, ensuring your organizational charts are always accurate and up-to-date. Streamline your workflow with seamless integration.',
    image: <SliderImage src={slide2} />,
  },
  {
    heading: 'Collaborate and Share Org Charts with Ease',
    description:
      'Empower your team with tailored access roles - Admins, Editors, and Viewers. Work together seamlessly on organizational charts, or simply share them with a click. Collaboration in org chart management has never been easier.',
    image: <SliderImage src={slide3} $hasShadow />,
  },
]

export const RightSide = () => {
  const { currentSlide, currentSlideIndex, setIndex } = useSlider({ slides })
  const { heading, description, image } = currentSlide || {}

  return (
    <RightSideInner>
      <RightContentMargin />
      <RightContent>
        <Heading>{heading}</Heading>
        <Description>{description}</Description>
        <ImageContainer>{image}</ImageContainer>

        <SliderBulletContainer>
          {slides.map((_, index) => (
            <SliderBullet key={index} $isActive={currentSlideIndex === index} onClick={() => setIndex(index)} />
          ))}
        </SliderBulletContainer>
      </RightContent>
    </RightSideInner>
  )
}

const RightSideInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.colors.yellowExtraLight};

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    flex: 5;
    flex-direction: row;
  }
`

const RightContentMargin = styled.span`
  display: none;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    flex: 0 1 auto;
    width: 100%;
    max-width: 84px;
    height: 100%;
    display: flex;
  }
`

const RightContent = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 40px ${props => props.theme.spaces.l};
  text-align: center;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    width: 100%;
    max-width: 600px;
    align-items: flex-start;
    text-align: left;
  }
`

const Heading = styled.h2`
  margin-bottom: ${props => props.theme.spaces.m};
  color: ${props => props.theme.colors.dark};
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
`

const Description = styled.p`
  max-width: 600px;
  margin-bottom: ${props => props.theme.spaces.xl};
  color: ${props => props.theme.colors.dark};
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    /* Prevents slider jumping on next/previous slide */
    min-height: 88px;
  }
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${props => props.theme.spaces.xl};

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    /* Prevents slider jumping on next/previous slide */
    min-height: 350px;
  }
`

const SliderBulletContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: auto;
  margin-right: auto;
  user-select: none;
`

const SliderBullet = styled.span<{ $isActive?: boolean }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${props => props.theme.colors.greyLight};
  cursor: pointer;

  ${props =>
    props.$isActive &&
    css`
      width: 12px;
      height: 12px;
      background: ${props => props.theme.colors.purpleLighter};
    `}
`
