import styled from 'styled-components'

import { Modal, Button } from 'components/generic'

import { pluralize } from 'helpers'
import { ModalTypeFactory, ModalComponentProps } from 'modal'

import { NodeDataFragmentFragment } from 'apollo/generated/graphql'

type UnassingNodesModalProps = ModalComponentProps & {
  node: NodeDataFragmentFragment
  onConfirm: () => void
}

export type UnassingNodesModalType = ModalTypeFactory<'unassignNodesModal', UnassingNodesModalProps>

export const UnassingNodesModal = ({ node, onConfirm, onClose }: UnassingNodesModalProps) => {
  const employeeCount = node.employeeCount
  const departmentCount = node.departmentCount

  return (
    <Modal title={{ text: `Relocate ${node.name}` }} onClose={onClose}>
      <Text>
        Are you sure you want to unassign{' '}
        {employeeCount > 0 && `${employeeCount} ${pluralize('employee', employeeCount)}`}{' '}
        {employeeCount > 0 && departmentCount > 0 && 'and '}
        {departmentCount > 0 && `${departmentCount} ${pluralize('team', departmentCount)}`} from your Org Chart?
      </Text>

      <ButtonsWrap>
        <Button buttonType='tertiary' onClick={onClose} data-testid='button-unassign-node-decline'>
          No
        </Button>
        <Button
          onClick={() => {
            onConfirm()
            onClose()
          }}
          data-testid='button-unassign-node-confirm'
        >
          Yes
        </Button>
      </ButtonsWrap>
    </Modal>
  )
}

const Text = styled.div`
  margin-bottom: 10px;
  color: ${props => props.theme.colors.grey};
  font-size: 13px;
  line-height: 20px;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`
