import { generatePath, Link } from 'react-router-dom'

import { Button } from 'components/generic'
import { ShareIcon } from 'components/icons'

import { SETTINGS_MEMBERS_PATH } from 'routes'
import { spaces } from 'theme'

type Props = {
  chartUuid: string
  className?: string
}

export const ChartShareButton = ({ chartUuid, className }: Props) => (
  <Link
    to={{
      pathname: generatePath(SETTINGS_MEMBERS_PATH, { chartUuid }),
      state: { from: window.location.pathname },
    }}
  >
    <Button
      className={className}
      buttonSize='small'
      buttonType='warning'
      data-testid='button-chart-share-in-top-navigation'
    >
      <ShareIcon width='18px' $spacing={{ mr: spaces.m }} />
      Share
    </Button>
  </Link>
)
