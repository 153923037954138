import { useState } from 'react'
import { generatePath, Link } from 'react-router-dom'

import { IconButton, Tooltip } from 'components/generic'
import { SettingsIcon } from 'components/icons'

import { SETTINGS_GENERAL_PATH } from 'routes'

type Props = {
  chartUuid: string
}

export const ChartSettings = ({ chartUuid }: Props) => {
  const [isTooltipShown, setIsTooltipShown] = useState(false)

  return (
    <Link
      to={{
        pathname: generatePath(SETTINGS_GENERAL_PATH, { chartUuid }),
        state: { from: window.location.pathname },
      }}
    >
      <IconButton
        size='big'
        onMouseEnter={() => setIsTooltipShown(true)}
        onMouseLeave={() => setIsTooltipShown(false)}
        data-testid='button-top-navigation-chart-settings'
      >
        <SettingsIcon />
        <Tooltip isTooltipShown={isTooltipShown}>Settings</Tooltip>
      </IconButton>
    </Link>
  )
}
