import { useState } from 'react'
import { toast } from 'react-toastify'

import { handleErrorValidation } from 'helpers'
import { SHARING_TRACK } from 'analytics/constants'

import { getCollaborators } from 'apollo/query'
import { useResendInvitationMutation } from 'apollo/generated/graphql'

export const useResendCollaboratorInvitation = () => {
  const [isResending, setIsResending] = useState(false)
  const [resendInvitation] = useResendInvitationMutation()

  const resendCollaboratorInvitation = (chartUuid: string, collaboratorUuid: string) => {
    setIsResending(true)

    resendInvitation({
      variables: { code: collaboratorUuid },
      refetchQueries: [{ query: getCollaborators, variables: { chartUuid } }],
    })
      .then(() => toast('Invite resent', { type: 'success' }))
      .catch(error => {
        handleErrorValidation({
          track: { message: SHARING_TRACK.inviteResentFailure, values: { chartUuid, error } },
          toast: { message: 'Invite resent failed' },
        })
      })
      .finally(() => setIsResending(false))
  }

  return { resendCollaboratorInvitation, isResending }
}
