import { useEffect } from 'react'

type Params = {
  /** Can be changed run-time but whole interval with initial delay is going to be re-run again. */
  updateIntervalMs: number
  /** If set to 0, will execute the callback immediately. */
  initialDelayMs?: number
  /** Callback must remain with the same reference. You can wrap your callback in `useCallback` for that. */
  callback: () => void
}

export const useInterval = ({ callback, updateIntervalMs, initialDelayMs }: Params) => {
  useEffect(() => {
    let timer: NodeJS.Timeout
    let intervalTimer: NodeJS.Timer

    const startInterval = () => {
      intervalTimer = setInterval(callback, updateIntervalMs)
    }

    if (typeof initialDelayMs === 'number') {
      timer = setTimeout(() => {
        callback()
        startInterval()
      }, initialDelayMs)
    } else {
      startInterval()
    }

    return () => {
      clearTimeout(timer)
      clearInterval(intervalTimer)
    }
  }, [updateIntervalMs, callback])
}
