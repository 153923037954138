// Cast all first letters to toLowerCase so all the lowerCase letters be NOT be after all upperCase letters - can be attribute someday
export const sortStrings = (a: string, b: string, isDescOrder: boolean) => {
  const aLow = a.toLowerCase()
  const bLow = b.toLowerCase()
  return isDescOrder ? aLow.localeCompare(bLow) : bLow.localeCompare(aLow)
}

export const sortNumbers = (a: number, b: number, isDescOrder: boolean) => (isDescOrder ? b - a : a - b)

export const sortArrayOfObjectsByString = <T extends Record<string, any>>(objs: T[], attrName: string): T[] => {
  return [...objs].sort((a, b) => sortStrings(a[attrName], b[attrName], true))
}
