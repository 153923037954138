import { ChangeEvent } from 'react'
import styled from 'styled-components'

import { DirectoryNode } from './directory-node'
import { Loading } from 'components/generic'
import { inputPlaceholderCSS } from 'components/generic/input/input-CSS'
import { SearchIcon } from 'components/icons'

import { useResponsiveInfo } from 'providers'
import { sortArrayOfObjectsByString } from 'helpers'

import { NodeSearchDataFragmentFragment } from 'apollo/generated/graphql'

type Props = {
  isSearching: boolean
  nodeType: 'employee' | 'department'
  nodes: NodeSearchDataFragmentFragment[]
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const DirectorySearchContainer = ({ isSearching, nodeType, nodes, value, onChange }: Props) => {
  const { isSmallDevice } = useResponsiveInfo()
  const MAX_VISIBLE_NODES_ON_SMALL_DEVICE = 7
  const shouldDisplaySearchField = (value === '' && nodes.length > MAX_VISIBLE_NODES_ON_SMALL_DEVICE) || Boolean(value)
  const sortedNodes = sortArrayOfObjectsByString([...nodes], 'name')

  return (
    <>
      <Text>
        {isSmallDevice
          ? `Move ${nodeType}s into your Org Chart using the 'Supervisor and department' field when editing Person`
          : `You can drag and drop ${nodeType}s into your Org Chart.`}
      </Text>
      {shouldDisplaySearchField && (
        <SearchFieldWrap>
          <SearchIconWrap>
            <StyledSearchIcon />
          </SearchIconWrap>
          <SearchField placeholder={`Search for ${nodeType}s`} onChange={onChange} value={value} />
        </SearchFieldWrap>
      )}

      <ResultsContainer>
        {isSearching ? (
          <Loading />
        ) : sortedNodes.length === 0 ? (
          <NoNodeText>No {nodeType}s found</NoNodeText>
        ) : (
          sortedNodes.map(node => <DirectoryNode key={node.uuid} {...node} />)
        )}
      </ResultsContainer>
    </>
  )
}

const Text = styled.div`
  margin-top: 20px;
  color: ${props => props.theme.colors.grey};
  font-size: 13px;
  line-height: 20px;
`

const NoNodeText = styled(Text)`
  margin-top: 10px;
`

const SearchFieldWrap = styled.div`
  position: relative;
  margin-top: 10px;
  margin-right: 8px;
  margin-bottom: 10px;
`

const SearchIconWrap = styled.div`
  position: absolute;
  top: 0;
  left: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;
`

const StyledSearchIcon = styled(SearchIcon)`
  width: 14px;
  height: auto;
  color: ${props => props.theme.colors.dark};
`

const SearchField = styled.input`
  min-width: inherit;
  width: 100%;
  height: 34px;
  box-sizing: border-box;
  padding-top: 0;
  padding-right: 25px;
  padding-bottom: 0;
  padding-left: 38px;
  font-size: 13px;
  line-height: 16px;
  background: ${props => props.theme.colors.neutralSecondary};
  outline: none;
  border: none;
  transition: ${props => props.theme.transitions.extraFastEase};

  &:focus {
    background: ${props => props.theme.colors.greyActive};
  }

  &::placeholder,
  &::-webkit-input-placeholder {
    ${inputPlaceholderCSS}
  }
`

const ResultsContainer = styled.div`
  padding-bottom: 5px;
  margin-top: 10px;
  text-align: center;
  overflow-y: auto;
`
