import { FormikHelpers } from 'formik'

import { FieldContainer, FieldTitle } from './components'
import { FlexBox } from '../flex'
import { AssetUpload } from 'components/specific'

import { useChartPermission } from 'tree/hooks'
import { useChartData } from 'tree/providers'
import { ChartFormValues } from 'tree/types'
import { getIsSubscribed, ProBadge } from 'features/premium'

type Props = {
  setFieldValue: FormikHelpers<ChartFormValues>['setFieldValue']
}

export const FieldLogoUpload = ({ setFieldValue }: Props) => {
  const { uuid, logo, subscription } = useChartData()
  const { canUpdate } = useChartPermission()
  const isSubscribed = getIsSubscribed(subscription)
  const canEditLogo = isSubscribed && canUpdate

  return (
    <FieldContainer>
      <FieldTitle title={<FlexBox $gap='s'>Logo {!isSubscribed && <ProBadge redirectToPro />}</FlexBox>} size='big' />
      <AssetUpload
        assetType='logo'
        chartUuid={uuid}
        disabled={!canEditLogo}
        initialImgSrc={logo || ''}
        setFieldValue={val => setFieldValue('logo', val)}
      />
    </FieldContainer>
  )
}
