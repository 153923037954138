import { gql } from '@apollo/client'

export const customTextFieldFragment = gql`
  fragment CustomTextFieldFragment on CustomTextField {
    id
    type
    label
  }
`
export const customDateFieldFragment = gql`
  fragment CustomDateFieldFragment on CustomDateField {
    id
    type
    label
  }
`

export const customDropdownFieldFragment = gql`
  fragment CustomDropdownFieldFragment on CustomDropdownField {
    id
    type
    label
    options {
      id
      label
      icon
      background
    }
    displayIconsOnChartNode
  }
`

export const customConnectionFieldFragment = gql`
  fragment CustomConnectionFieldFragment on CustomConnectionField {
    id
    displayLineOnChart
    label
    lineColor
    lineStyle
    type
  }
`

export const customFieldFragment = gql`
  fragment CustomFieldFragment on CustomFieldItem {
    ...CustomTextFieldFragment
    ...CustomDateFieldFragment
    ...CustomDropdownFieldFragment
    ...CustomConnectionFieldFragment
  }
  ${customTextFieldFragment}
  ${customDateFieldFragment}
  ${customDropdownFieldFragment}
  ${customConnectionFieldFragment}
`
