import styled from 'styled-components'

export const GroupContainer = styled.div`
  min-height: 20px;
  display: inline-flex;
  align-items: center;
  margin: -${props => props.theme.spaces.s};
  margin-bottom: ${props => props.theme.spaces.s};
  padding: ${props => props.theme.spaces.s};
  color: ${props => props.theme.colors.greyLight};
  border-radius: 4px;
  user-select: none;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};

  ${props => props.onClick && props.theme.animations.backgroundShapeFill}
`
