import styled from 'styled-components'

import { NodeDetailItem } from '../../../node-components'
import { Link } from 'components/generic'
import { LinkIcon } from 'components/icons'

type Props = {
  url: string
}

export const CalendarLink = ({ url }: Props) => (
  <NodeDetailItem
    title='Calendar'
    value={
      <StyledLink href={url}>
        Open employee calendar
        <StyledLinkIcon />
      </StyledLink>
    }
  />
)

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`

const StyledLinkIcon = styled(LinkIcon)`
  margin-bottom: 2px;
  margin-left: ${props => props.theme.spaces.m};
`
