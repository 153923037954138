import styled from 'styled-components'
import { useLocation } from 'react-router-dom'

import { Card } from '../../../components'
import { borderHighlightCSS, Button } from 'components/generic'

import OrgcharSmallUrl from 'assets/orgchart-small.png'
import CopyChartArrowUrl from 'assets/copy-chart-arrow.png'

type Props = {
  deleteButtonText: string
  description: string
  title: string
  onDuplicate: () => void
  disabled?: boolean
}

export const DuplicationCard = ({ deleteButtonText, description, title, onDuplicate, disabled, ...rest }: Props) => {
  const { state: locationState } = useLocation<{ highlightEffect?: boolean }>()

  return (
    <StyledCard title={title} $animate={Boolean(locationState?.highlightEffect)}>
      <Content>{description}</Content>
      <ChartImgWrapper>
        <ChartImg src={OrgcharSmallUrl} />
        <ArrowImg src={CopyChartArrowUrl} />
        <ChartImg src={OrgcharSmallUrl} opacity={0.7} />
      </ChartImgWrapper>

      <Button disabled={disabled} onClick={onDuplicate} buttonType='primary' spacing={{ ml: 'auto' }} {...rest}>
        {deleteButtonText}
      </Button>
    </StyledCard>
  )
}

const Content = styled.div`
  margin-bottom: ${props => props.theme.spaces.l};
  font-size: 13px;
  line-height: 20px;
`

const ChartImgWrapper = styled.div`
  min-height: 172px; /* Prevents changing height when images are still loading */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

const ChartImg = styled.img<{ opacity?: number }>`
  margin: 26px;
  margin-top: 6px;
  opacity: ${({ opacity }) => opacity ?? 1};
`

const ArrowImg = styled.img`
  height: 100%;
`

const StyledCard = styled(Card)<{ $animate: boolean }>`
  position: relative;
  ${props => props.$animate && borderHighlightCSS}
`
