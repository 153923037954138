import { SVG, SVGProps } from './SVG'

export const OrgchartCompactStructureIcon = (props: SVGProps) => (
  <SVG width='14' height='15' viewBox='0 0 14 15' fill='none' {...props}>
    <rect x='1' y='1' width='4' height='1.25' stroke='currentColor' strokeWidth='2' />
    <rect x='1' y='6.25' width='4' height='1.25' stroke='currentColor' strokeWidth='2' />
    <rect x='1' y='11.5' width='4' height='1.25' stroke='currentColor' strokeWidth='2' />
    <rect x='8.5' y='1' width='4' height='1.25' stroke='currentColor' strokeWidth='2' />
    <rect x='8.5' y='6.25' width='4' height='1.25' stroke='currentColor' strokeWidth='2' />
    <rect x='8.5' y='11.5' width='4' height='1.25' stroke='currentColor' strokeWidth='2' />
  </SVG>
)
