import { gql } from '@apollo/client'
import { chartFragment } from '../fragments'

export const getCharts = gql`
  query charts {
    charts {
      items {
        ...ChartFragment
      }
    }
  }
  ${chartFragment}
`
