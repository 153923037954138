import styled from 'styled-components'

import PaletteSrc from 'assets/palette.png'
import { applySpacingProps, SpacingProps } from 'helpers'

type Props = {
  sociomapSize?: number
  labels: string[]
  spacing?: SpacingProps
}

export const SociomapScale = (props: Props) => {
  const { sociomapSize, spacing, labels } = props

  return (
    <Scale sociomapSize={sociomapSize} spacing={spacing}>
      <PaletteWrapper>
        <PaletteImage src={PaletteSrc} />
      </PaletteWrapper>
      <Labels>
        {labels.map(label => (
          <Label key={label}>{label}</Label>
        ))}
      </Labels>
    </Scale>
  )
}

const Scale = styled.div.withConfig<{ sociomapSize?: number; spacing?: SpacingProps }>({
  shouldForwardProp: prop => !['sociomapSize', 'spacing'].includes(prop),
})`
  width: ${props => (props.sociomapSize !== undefined ? `${props.sociomapSize * 0.7}px` : '100%')};
  height: 32px;

  ${props => applySpacingProps(props.spacing)}
`

const PaletteWrapper = styled.div`
  position: relative;
  height: 8px;
  border-radius: 4px;
  overflow: hidden;
`

const PaletteImage = styled.img`
  width: 100%;
  height: 13px;
`

const Labels = styled.div`
  display: flex;
`

const Label = styled.div`
  flex: 1;
  position: relative;
  text-align: center;
  font-size: 12px;
  color: ${props => props.theme.colors.dark};
  padding-top: 5px;

  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: right;
  }
  &:first-child:last-child {
    text-align: center;
  }
`
