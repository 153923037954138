import { useState } from 'react'
import { generatePath, Link } from 'react-router-dom'
import styled from 'styled-components'

import { IconButton, Tooltip } from 'components/generic'
import { DuplicateChartIcon } from 'components/icons'

import { SETTINGS_GENERAL_PATH } from 'routes'

type Props = {
  chartUuid: string
}

export const ChartDuplicate = ({ chartUuid }: Props) => {
  const [isTooltipShown, setIsTooltipShown] = useState(false)

  return (
    <Link
      to={{
        pathname: `${generatePath(SETTINGS_GENERAL_PATH, { chartUuid })}`,
        state: { from: window.location.pathname, highlightEffect: true },
      }}
    >
      <IconButton
        size='big'
        onMouseEnter={() => setIsTooltipShown(true)}
        onMouseLeave={() => setIsTooltipShown(false)}
        data-testid='button-top-navigation-chart-duplicate'
      >
        <StyledDuplicateChartIcon />
        <Tooltip isTooltipShown={isTooltipShown}>Duplicate Org Chart</Tooltip>
      </IconButton>
    </Link>
  )
}

const StyledDuplicateChartIcon = styled(DuplicateChartIcon)`
  transform: scale(0.85);
`
