import { ReactNode, useState } from 'react'
import Bowser from 'bowser'

import { createSafeContext } from 'helpers'

type IsSafariBrowserContext = {
  isSafariBrowser: boolean
}
export const [useIsSafariBrowser, EmptyIsSafariBrowserSafeProvider] = createSafeContext<IsSafariBrowserContext>()

type Props = {
  children: ReactNode
}

export const IsSafariBrowserSafeProvider = ({ children }: Props) => {
  const [isSafariBrowser] = useState(() => {
    try {
      const browser = Bowser.getParser(window.navigator.userAgent)
      const browserName = browser.getBrowserName()
      return browserName === 'Safari'
    } catch (error) {
      console.error("Can't recognize browser name. Error: ", error)
      return false
    }
  })

  return <EmptyIsSafariBrowserSafeProvider value={{ isSafariBrowser }}>{children}</EmptyIsSafariBrowserSafeProvider>
}
