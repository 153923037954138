import { useCallback, useMemo } from 'react'

import { CheckQuotaResult, QuotaId, UseQuotaForResult } from '../types'

import { Quota, SubscriptionInfoFragment } from 'apollo/generated/graphql'

const quotaLimitOverrideMap: Partial<Record<QuotaId, number>> = {
  'chart.publicLink.domain': 1,
}

type SubscriptionLike = { subscription?: SubscriptionInfoFragment | null } | undefined

export const useQuotaFor = (objs: SubscriptionLike[]): UseQuotaForResult => {
  const quotas = useMemo<Quota[]>(() => {
    return objs.flatMap(obj => {
      return obj?.subscription?.quotas?.items ?? []
    })
  }, [objs])

  const quotaMap = useMemo(() => {
    return quotas.reduce<Record<string, Quota[]>>((map, quota) => {
      if (!map[quota.id]) {
        map[quota.id] = []
      }
      map[quota.id].push(quota)
      return map
    }, {})
  }, [quotas])

  const checkQuota = useCallback(
    (id: QuotaId): CheckQuotaResult => {
      const quotas = quotaMap[id]
      const defaultLimit: CheckQuotaResult = {
        id,
        displayName: 'unknown',
        status: 'ok',
        displayNameCount: -1,
        limit: -1,
      }

      if (!quotas) {
        return defaultLimit
      }

      const exceededLimit = quotas.reduce<CheckQuotaResult>((limit, quota) => {
        const { currentValue, softLimit, hardLimit, displayName } = quota
        if (hardLimit > -1 && currentValue > hardLimit) {
          return {
            id,
            displayName,
            status: 'exceeded',
            displayNameCount: quotaLimitOverrideMap[id] ?? hardLimit,
            limit: hardLimit,
          }
        } else if (softLimit > -1 && currentValue > softLimit) {
          return {
            id,
            displayName,
            status: 'warning',
            displayNameCount: quotaLimitOverrideMap[id] ?? softLimit,
            limit: softLimit,
          }
        }

        return limit
      }, defaultLimit)

      return exceededLimit
    },
    [quotaMap]
  )

  const checkQuotas = useCallback(
    (ids?: QuotaId[]) => {
      const quotaList = Array.isArray(ids) ? ids : (Object.keys(quotaMap) as QuotaId[])

      return quotaList.flatMap(id => {
        const quota = checkQuota(id)
        if (quota.status !== 'ok') {
          return [quota]
        }

        return []
      })
    },
    [checkQuota, quotaMap]
  )

  return {
    checkQuota,
    checkQuotas,
  }
}
