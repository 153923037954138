import { ChartFormSubmitValues } from 'tree/types'

import { Chart, useUpdateChartMutation } from 'apollo/generated/graphql'

type UpdateChartRequestParams = {
  chartId: Chart['id']
  values: ChartFormSubmitValues
}

export const useUpdateChartRequest = () => {
  const [updateChartMutation] = useUpdateChartMutation()

  const updateChartRequest = ({ chartId, values }: UpdateChartRequestParams) => {
    return updateChartMutation({ variables: { uuid: chartId, data: values } })
  }

  return { updateChartRequest }
}
