import { useState, useLayoutEffect } from 'react'
import { Bar } from 'react-chartjs-2'

import { ChartPlaceholder } from './ChartPlaceholder'
import { ChartContainer, ChartHeading, ChartButtonContainer, ChartButtonLabel } from './styled'
import { ToggleButton } from 'components/generic'

import { barOptions } from './constants'
import { ChartDataProcessed, BarDatasetAttributes } from '../../types'
import analytics from 'analytics'
import { INSIGHTS_TRACK } from 'analytics/constants'

type Props = {
  heading: string
  data?: ChartDataProcessed
  chartUuid?: string
}

export const BarChart = ({ data, heading, chartUuid }: Props) => {
  const [extremesHidden, setExtremesHidden] = useState<boolean>(false)
  const [sliceIndex, setSliceIndex] = useState(0)

  useLayoutEffect(() => {
    if (data) {
      let index = 0
      const values = data.datasets[0].data
      const MAX_EXTREMES = Math.min(values.length / 2, 10)
      const mean = values.reduce((acc, curr) => acc + curr, 0) / values.length
      const standardDeviation = Math.sqrt(
        values.reduce((acc, curr) => acc + Math.pow(curr - mean, 2), 0) / values.length
      )
      for (let i = 0; i < MAX_EXTREMES; i++) {
        if ((values[i] - mean) / standardDeviation > 3) index = i + 1
      }
      setSliceIndex(index)
    }
  }, [data])

  if (!data || !data.datasets[0]) return <ChartPlaceholder />

  return (
    <ChartContainer>
      <ChartHeading>{heading}</ChartHeading>
      {sliceIndex > 0 && (
        <ChartButtonContainer>
          <ChartButtonLabel>
            Hide extremes
            <ToggleButton
              onClick={() => {
                setExtremesHidden(!extremesHidden)
                analytics.track(INSIGHTS_TRACK.extremeValuesButton, { chartUuid })
              }}
            />
          </ChartButtonLabel>
        </ChartButtonContainer>
      )}
      <Bar
        data={
          extremesHidden
            ? {
                datasets: [
                  {
                    backgroundColor: (data.datasets[0] as BarDatasetAttributes).backgroundColor.slice(sliceIndex),
                    data: data.datasets[0].data.slice(sliceIndex),
                  },
                ],
                labels: data.labels.slice(sliceIndex),
              }
            : data
        }
        options={barOptions}
        height={267}
      />
    </ChartContainer>
  )
}
