import { SVG, SVGProps } from './SVG'

export const LocationIcon = (props: SVGProps) => (
  <SVG width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <path
      d='M7.99967 1.33325C5.41967 1.33325 3.33301 3.41992 3.33301 5.99992C3.33301 9.49992 7.99967 14.6666 7.99967 14.6666C7.99967 14.6666 12.6663 9.49992 12.6663 5.99992C12.6663 3.41992 10.5797 1.33325 7.99967 1.33325ZM7.99967 7.66659C7.07967 7.66659 6.33301 6.91992 6.33301 5.99992C6.33301 5.07992 7.07967 4.33325 7.99967 4.33325C8.91967 4.33325 9.66634 5.07992 9.66634 5.99992C9.66634 6.91992 8.91967 7.66659 7.99967 7.66659Z'
      fill='currentColor'
    />
  </SVG>
)
