import { useEffect, useMemo } from 'react'
import { Route, RouteProps, useRouteMatch, RouteComponentProps, useLocation } from 'react-router-dom'

import analytics from 'analytics'
import { ORG_TRACK } from 'analytics/constants'

const TrackPageview = () => {
  const { pathname } = useLocation()
  const { path, isExact } = useRouteMatch()

  useEffect(() => {
    if (isExact) analytics.track(ORG_TRACK.pageView, { path, pathname })
  }, [isExact, path, pathname])

  return null
}

export const TrackedRoute = ({ component: Component, render, ...props }: RouteProps) => {
  const renderMemo = useMemo(() => {
    return render
      ? (renderProps: RouteComponentProps) => (
          <>
            <TrackPageview />
            {render?.call?.(null, renderProps)}
          </>
        )
      : undefined
  }, [render])

  const componentMemo = useMemo(() => {
    return Component
      ? (componentProps: RouteComponentProps) => (
          <>
            <TrackPageview />
            <Component {...componentProps} />
          </>
        )
      : undefined
  }, [Component])

  return <Route {...props} component={componentMemo} render={renderMemo} />
}
