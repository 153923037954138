import { gql } from '@apollo/client'
import { publicLinkFragment } from '../fragments'

export const getPublicLinks = gql`
  query getPublicLinks($chartUuid: String!) {
    getPublicLinks(chartUuid: $chartUuid) {
      ...PublicLink
    }
  }
  ${publicLinkFragment}
`
