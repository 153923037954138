import { useState } from 'react'
import styled from 'styled-components'

import { IconButton, Tooltip } from 'components/generic'
import { QuestionMarkInCircleIcon } from 'components/icons'

import { useAuth } from 'auth'
import analytics from 'analytics'
import { ORG_TRACK } from 'analytics/constants'
import { ORG_CHART_HELP_CENTER_URL } from 'consts'

type Props = {
  className?: string
  shiftXPx?: number
}

export const HelpCenter = ({ className, shiftXPx }: Props) => {
  const [isTooltipShown, setIsTooltipShown] = useState(false)
  const { user } = useAuth()

  const handleClick = () => {
    analytics.track(ORG_TRACK.helpCenterOpen, { user: user?.email, name: user?.name })
    window.open(ORG_CHART_HELP_CENTER_URL)
  }

  return (
    <StyledIconButton
      className={className}
      size='big'
      onMouseEnter={() => setIsTooltipShown(true)}
      onMouseLeave={() => setIsTooltipShown(false)}
      onClick={handleClick}
    >
      <QuestionMarkInCircleIcon />
      <Tooltip shiftXPx={shiftXPx} isTooltipShown={isTooltipShown}>
        Help center
      </Tooltip>
    </StyledIconButton>
  )
}

const StyledIconButton = styled(IconButton)`
  &:only-child {
    margin-right: ${props => props.theme.spaces.l};
  }
`
