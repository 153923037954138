import { ReactNode } from 'react'
import styled from 'styled-components'

import { FlexBox } from 'components/generic'

import { useResponsiveInfo, useTopNavigationToggle } from 'providers'
import { sizes } from 'theme'

type Props = {
  children: ReactNode
  className?: string
}

export const WebLayout = ({ children, className }: Props) => {
  const { isMobileDevice } = useResponsiveInfo()
  const { isVisible, withAnimation } = useTopNavigationToggle()

  return (
    <WebLayoutContainer
      className={className}
      $isMobileDevice={isMobileDevice}
      $isTopNavVisible={isVisible}
      $hasPaddingAnimation={withAnimation}
    >
      {children}
    </WebLayoutContainer>
  )
}

const WebLayoutContainer = styled(FlexBox)<{
  $isMobileDevice?: boolean
  $isTopNavVisible: boolean
  $hasPaddingAnimation: boolean
}>`
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: auto;
  padding-top: ${props =>
    props.$isTopNavVisible ? (props.$isMobileDevice ? sizes.topNavigationMobile : sizes.topNavigation) : '0'};
  background: ${props => props.theme.colors.neutralSecondary};
  transition: ${props => (props.$hasPaddingAnimation ? `padding ${props.theme.transitions.slowEase}` : 'none')};
  overflow: auto;
`
