import { FieldInput, InputProps } from 'components/generic'

type Props = {
  disabled: boolean
  name: string
  title: string
  value: string
  onClear: () => void
} & Pick<InputProps, 'onChange' | 'onBlur'>

export const CustomTextField = ({ title, ...props }: Props) => <FieldInput {...props} title={{ title }} type='text' />
