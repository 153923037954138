import { useEffect, RefObject } from 'react'

export const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  handler: (event: TouchEvent | MouseEvent) => void,
  ignoredElements: HTMLElement[] = []
) => {
  useEffect(() => {
    const listener = (event: TouchEvent | MouseEvent) => {
      const target = event.target as HTMLElement
      const targetIsWithinIgnoredElements = ignoredElements.some(ignoredElement => ignoredElement?.contains(target))
      const clickedInside = !ref.current || ref.current?.contains(target) || targetIsWithinIgnoredElements
      if (!clickedInside) handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}
