import { Route, Switch, useParams } from 'react-router-dom'

import { DepartmentDetails } from './details'
import { DepartmentEdit } from './edit'
import { NodeActions, NodeComponent, NodeHeader } from '../node-components'

import { useDeleteNodeAction, useUpdateNodeAction } from 'tree/actions'
import { useDepartment } from 'tree/hooks'
import { useChartId } from 'tree/providers'
import { OnDepartmentFormSubmit } from 'tree/types'
import { DEPARTMENT_EDIT_PATH, TrackedRoute, useGoToDepartmentDetail, useGoToEditDepartment } from 'routes'

import { DepartmentDetailDataFragmentFragment, useNodeQuery } from 'apollo/generated/graphql'

export const Department = ({ onClose }: NodeComponent) => {
  const { uuid } = useParams<{ uuid: string }>()
  const chartUuid = useChartId()

  const { data } = useNodeQuery({ variables: { uuid, chartKey: chartUuid } })
  const department = data?.node
  if (department?.__typename !== 'Department') return null

  return <DepartmentWithData department={department} onClose={onClose} />
}

type Props = {
  department: DepartmentDetailDataFragmentFragment
  onClose: () => void
}

const DepartmentWithData = ({ department: departmentBE, onClose }: Props) => {
  const department = useDepartment(departmentBE)
  const { parentUuid = null } = department

  const { goToDepartmentDetail } = useGoToDepartmentDetail()
  const { goToEditDepartment } = useGoToEditDepartment()

  const { deleteNodeWithConfirm } = useDeleteNodeAction()
  const handleDelete = () => deleteNodeWithConfirm({ node: departmentBE }).then(onClose)

  const { updateNodeAction } = useUpdateNodeAction()
  const submitUpdateHandler: OnDepartmentFormSubmit = ({ values, actions }) => {
    updateNodeAction({ node: departmentBE, values, actions, nodeTypename: 'Department' })
  }

  return (
    <Switch>
      <TrackedRoute
        path={DEPARTMENT_EDIT_PATH}
        exact
        render={() => (
          <DepartmentEdit
            department={department}
            parentUuid={parentUuid}
            onClose={onClose}
            onCancel={() => goToDepartmentDetail(department.id)}
            onSubmit={submitUpdateHandler}
          />
        )}
      />
      <Route
        render={() => (
          <>
            <NodeHeader
              color={department.color}
              title={department.name}
              description={department.description ?? ''}
              departmentCount={department.departmentCount}
              employeeCount={department.employeeCount}
              onClose={onClose}
            />
            <NodeActions node={department} onDelete={handleDelete} onEdit={() => goToEditDepartment(department.id)} />
            <DepartmentDetails department={department} />
          </>
        )}
      />
    </Switch>
  )
}
