import { CheckQuotaResult } from '../types'
import { pluralize } from 'helpers'

export const getChartLimit = (q: CheckQuotaResult) => {
  return q.limit > 0
    ? `you can only have ${q.displayNameCount} ${pluralize(q.displayName, q.displayNameCount)}`
    : `you cannot use ${pluralize(q.displayName, q.displayNameCount)}`
}

export const getChartLimits = (warnings: CheckQuotaResult[]) => {
  return warnings
    .sort((a, b) => b.limit - a.limit)
    .map(getChartLimit)
    .join(', ')
}
