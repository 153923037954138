import { ReactNode, useRef, useState } from 'react'
import { createSafeContext } from 'helpers'

type NodesInViewport = {
  areNodesInViewport: boolean
  addNodeToViewport: (nodeUuid: string) => void
  removeNodeFromViewport: (nodeUuid: string) => void
}

export const [useNodesInViewport, EmptyNodesInViewportSafeProvider] = createSafeContext<NodesInViewport>()

type Props = {
  children: ReactNode
}

export const NodesInViewportSafeProvider = ({ children }: Props) => {
  const [areNodesInViewport, setAreNodesInViewport] = useState(false)
  const nodesInViewport = useRef<string[]>([])

  const addNodeToViewport = (nodeUuid: string) => {
    const isInViewport = nodesInViewport.current.includes(nodeUuid)

    if (!isInViewport) {
      nodesInViewport.current = [...nodesInViewport.current, nodeUuid]
      setAreNodesInViewport(true)
    }
  }

  const removeNodeFromViewport = (nodeUuid: string) => {
    nodesInViewport.current = nodesInViewport.current.filter(currNodeUuid => currNodeUuid !== nodeUuid)

    if (nodesInViewport.current.length === 0) {
      setAreNodesInViewport(false)
    }
  }

  return (
    <EmptyNodesInViewportSafeProvider
      value={{
        areNodesInViewport,
        addNodeToViewport,
        removeNodeFromViewport,
      }}
    >
      {children}
    </EmptyNodesInViewportSafeProvider>
  )
}
