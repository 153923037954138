import { Button, ButtonProps } from 'components/generic'
import { TrashCanIcon } from 'components/icons'

type Props = Omit<ButtonProps, 'buttonType' | 'spacing'>

export const DeleteButton = (props: Props) => (
  <Button buttonType='tertiary' spacing={{ ml: 'auto' }} {...props}>
    <TrashCanIcon />
  </Button>
)
