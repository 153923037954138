import styled from 'styled-components'

import { Badge, Button } from 'components/generic'
import { CopyIcon, SendEmailIcon } from 'components/icons'

import { useChartPermission } from 'tree/hooks'
import { spaces } from 'theme'

import { CollaboratorStatus } from 'apollo/generated/graphql'

type Props = {
  isResending: boolean
  status: CollaboratorStatus
  onCopy: () => void
  onSendNewInv: () => void
}

export const StatusContainer = ({ isResending, status, onCopy, onSendNewInv }: Props) => {
  const { canAddCollaborator } = useChartPermission()

  if (status === 'accepted') return null
  const displayCopyInvLink = status === 'invited' && canAddCollaborator // TODO: Missing capability canReadInvitationLink

  return (
    <StatusContainerInner>
      {status === 'invitation_expired' && (
        <Badge $color='red' $spacing={{ m: spaces.s, ml: 0 }}>
          Invitation expired
        </Badge>
      )}
      {status === 'invited' && (
        <Badge $color='grey' $spacing={{ m: spaces.s, ml: 0 }}>
          Invitation pending
        </Badge>
      )}
      {canAddCollaborator && (
        <Button
          buttonSize='extraSmall'
          buttonType='secondary'
          leftEl={<StyledSendEmailIcon />}
          spacing={{ m: spaces.s, ml: 0 }}
          disabled={isResending}
          onClick={onSendNewInv}
        >
          Send new invitation
        </Button>
      )}
      {displayCopyInvLink && (
        <Button
          buttonSize='extraSmall'
          buttonType='secondary'
          leftEl={<StyledCopyIcon />}
          spacing={{ m: spaces.s, ml: 0 }}
          disabled={isResending}
          onClick={onCopy}
        >
          Copy invitation link
        </Button>
      )}
    </StatusContainerInner>
  )
}

const StatusContainerInner = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const StyledSendEmailIcon = styled(SendEmailIcon)`
  margin-right: ${props => props.theme.spaces.s};
`

const StyledCopyIcon = styled(CopyIcon)`
  margin-right: ${props => props.theme.spaces.s};
`
