import sha256 from 'crypto-js/sha256'
import CryptoJS from 'crypto-js'
import WordArray from 'crypto-js/lib-typedarrays'

const PKCE_CODE_VERIFIED_KEY = 'pkce_code_verifier'

const createCodeVerifier = (length = 64) => {
  const code = WordArray.random(length).toString()
  window.localStorage.setItem(PKCE_CODE_VERIFIED_KEY, code)

  return code
}

export const getCodeVerifier = (length = 64) => {
  const code = window.localStorage.getItem(PKCE_CODE_VERIFIED_KEY)

  if (code) {
    return code
  }

  return createCodeVerifier(length)
}

export const getCodeChallenge = () => {
  const code = createCodeVerifier()

  return sha256(code).toString(CryptoJS.enc.Base64).split('=')[0].replace(/\+/g, '-').replace(/\//g, '_')
}

export const clearCodeVerifier = () => {
  window.localStorage.removeItem(PKCE_CODE_VERIFIED_KEY)
}
