import {
  FC,
  ReactNode,
  useState,
  useContext,
  createContext,
  useRef,
  useMemo,
  useCallback,
  MutableRefObject,
} from 'react'

type ModalProps = {
  onClose?: () => void
}

export type ModalComponentProps = Required<ModalProps>

export type ModalType<P extends ModalProps = ModalProps> = {
  name: unknown
  properties: P
}

export type ModalTypeFactory<N, P extends ModalProps> = {
  name: N
  properties: Omit<P, keyof ModalProps> & ModalProps
}

export type ModalElement<T extends ModalType> = FC<T['properties'] & ModalComponentProps>

type RegisterModalConfig<T extends ModalType> = {
  name: T['name']
  element: ModalElement<T>
}

type ModalRecord = Record<string, ModalElement<ModalType>>

type ModalContext = {
  openedModalsRef: MutableRefObject<ModalType[]>
  modalMapRef: MutableRefObject<ModalRecord>
  register: <T extends ModalType>(config: RegisterModalConfig<T>) => void
  unregister: <T extends ModalType>(name: T['name']) => void
  open: <T extends ModalType>(name: T['name'], properties?: T['properties']) => void
  close: <T extends ModalType>(name: T['name']) => void
  replace: <S extends ModalType, T extends ModalType>(
    replacedName: S['name'],
    name: T['name'],
    properties?: T['properties']
  ) => void
}

const noop = () => undefined
export const ModalContext = createContext<ModalContext>({
  openedModalsRef: { current: [] },
  modalMapRef: { current: {} },
  register: noop,
  unregister: noop,
  open: noop,
  close: noop,
  replace: noop,
})

type Props = {
  children: ReactNode
}

export const ModalProvider = ({ children }: Props) => {
  const [rerenderValue, rerender] = useState<unknown>()
  const openedModalsRef = useRef<ModalType[]>([])
  const modalMapRef = useRef<ModalRecord>({})

  const findIndex = useCallback(
    (name: ModalType['name']) => openedModalsRef.current.findIndex(modal => modal.name === name),
    []
  )

  const exists = useCallback((name: ModalType['name']) => findIndex(name) > -1, [findIndex])

  const isLast = useCallback((name: ModalType['name']) => {
    const index = findIndex(name)
    return openedModalsRef.current.length - 1 === index
  }, [])

  const register: ModalContext['register'] = useCallback(({ name, element }) => {
    if (!modalMapRef.current[name as string]) {
      modalMapRef.current[name as string] = element
    }
  }, [])

  const unregister: ModalContext['unregister'] = useCallback(
    name => {
      if (modalMapRef.current[name as string]) {
        const removed = remove(name, false)
        delete modalMapRef.current[name as string]
        removed && rerender({})
      }
    },
    [rerender]
  )

  const add = useCallback(
    (modal: ModalType, index = openedModalsRef.current.length) => {
      if (exists(modal.name)) throw new Error(`ModalType ${modal.name} already exists`)

      openedModalsRef.current.splice(index, 0, modal)
    },
    [findIndex]
  )

  const remove = useCallback(
    (name: ModalType['name'], callOnClose = true) => {
      const index = findIndex(name)
      if (index > -1) {
        const modalToRemove = openedModalsRef.current.splice(index, 1)[0]
        callOnClose && modalToRemove.properties?.onClose && modalToRemove.properties.onClose()

        return true
      }

      return false
    },
    [findIndex]
  )

  const open: ModalContext['open'] = useCallback(
    (name, properties = {}) => {
      try {
        add({ name, properties })
        rerender({})
      } catch (err) {
        /* do not rerender on add error */
      }
    },
    [add, rerender]
  )

  const close: ModalContext['close'] = useCallback(
    name => {
      if (isLast(name)) {
        remove(name)
        rerender({})
      }
    },
    [isLast, rerender]
  )

  const replace: ModalContext['replace'] = useCallback(
    (replacedName, name, properties = {}) => {
      const index = findIndex(replacedName)
      if (index > -1) {
        try {
          remove(replacedName)
          add({ name, properties }, index)
          rerender({})
        } catch (err) {
          /* do not rerender on add error */
        }
      }
    },
    [findIndex, remove]
  )

  const value = useMemo<ModalContext>(
    () => ({
      openedModalsRef,
      modalMapRef,
      register,
      unregister,
      open,
      close,
      replace,
    }),
    [rerenderValue, openedModalsRef, register, open, close, replace]
  )

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>
}

export const useModal = () => useContext(ModalContext)
