import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { CustomFieldsIconsPanelProps } from './custom-fields-panel'
import { SelectedDropdownOption, CustomFieldValue, PersonDetail } from 'tree/types'

const getSelectedOptions = (customFieldsValues: CustomFieldValue[]) => {
  return customFieldsValues.reduce<SelectedDropdownOption[]>((selectedOptions, f) => {
    if (f.type === 'single-select' && f.displayIconsOnChartNode && f.selectedOption && f.selectedOption.icon) {
      selectedOptions.push(f.selectedOption)
    }
    return selectedOptions
  }, [])
}

type Props = Pick<PersonDetail, 'customFields'> & {
  childrenCount: number
  image: string
  compact: boolean
}

export const useCustomFieldsPanel = ({
  customFields,
  childrenCount,
  image,
  compact,
}: Props): CustomFieldsIconsPanelProps => {
  const [displayCount, setDisplayCount] = useState(0)
  const selectedOptions = useMemo(() => getSelectedOptions(customFields), [JSON.stringify(customFields)])

  const visibleFields = selectedOptions.slice(0, displayCount)
  const hiddenFields = selectedOptions.slice(displayCount)
  const hiddenFieldsTitle = hiddenFields.map(({ value }) => value).join(', ')
  const showPanel = displayCount > 0

  const calculateCount = (node: HTMLDivElement) => {
    const containerWidth = Math.floor(node.getBoundingClientRect().width)
    const panelWidth = 28 // 24px + (2 x 2px margins)
    const maxPanelCount = Math.floor(containerWidth / panelWidth)
    selectedOptions.length > maxPanelCount ? setDisplayCount(maxPanelCount - 1) : setDisplayCount(maxPanelCount)
  }

  // Calculate count on init
  const hasInitialized = useRef(false)
  const panelRef = useRef<null | HTMLDivElement>(null)
  const setPanelRef = useCallback((node: HTMLDivElement | null) => {
    panelRef.current = node
    if (node && !hasInitialized.current) {
      hasInitialized.current = true
      calculateCount(node)
    }
  }, [])

  // Re-calculate count on change
  useEffect(() => {
    if (panelRef.current) calculateCount(panelRef.current)
  }, [
    compact,
    childrenCount,
    image,
    selectedOptions /* ref check for selectedOptions as it's equality is checked in useMemo */,
  ])

  return {
    compact,
    hiddenFields,
    hiddenFieldsTitle,
    showPanel,
    visibleFields,
    setPanelRef,
  }
}
