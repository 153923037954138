import { SVG, SVGProps } from './SVG'

export const PdfFileIcon = (props: SVGProps) => (
  <SVG width='21' height='24' viewBox='0 0 21 24' fill='none' {...props}>
    <path
      d='M3 21.75H4.5V24H3C1.34531 24 0 22.6547 0 21V3C0 1.34531 1.34531 0 3 0H10.7578C11.5547 0 12.3187 0.314063 12.8812 0.876563L17.1234 5.11875C17.6859 5.68125 18 6.44531 18 7.24219V13.5H15.75V7.5H12C11.1703 7.5 10.5 6.82969 10.5 6V2.25H3C2.5875 2.25 2.25 2.5875 2.25 3V21C2.25 21.4125 2.5875 21.75 3 21.75ZM7.5 16.5H8.625C10.0734 16.5 11.25 17.6766 11.25 19.125C11.25 20.5734 10.0734 21.75 8.625 21.75H8.25V23.25C8.25 23.6625 7.9125 24 7.5 24C7.0875 24 6.75 23.6625 6.75 23.25V21V17.25C6.75 16.8375 7.0875 16.5 7.5 16.5ZM8.625 20.25C9.24844 20.25 9.75 19.7484 9.75 19.125C9.75 18.5016 9.24844 18 8.625 18H8.25V20.25H8.625ZM12 17.25C12 16.8375 12.3375 16.5 12.75 16.5H13.875C15.1172 16.5 16.125 17.5078 16.125 18.75V21.75C16.125 22.9922 15.1172 24 13.875 24H12.75C12.3375 24 12 23.6625 12 23.25V17.25ZM13.5 22.5H13.875C14.2875 22.5 14.625 22.1625 14.625 21.75V18.75C14.625 18.3375 14.2875 18 13.875 18H13.5V22.5ZM18 16.5H20.25C20.6625 16.5 21 16.8375 21 17.25C21 17.6625 20.6625 18 20.25 18H18.75V19.5H20.25C20.6625 19.5 21 19.8375 21 20.25C21 20.6625 20.6625 21 20.25 21H18.75V23.25C18.75 23.6625 18.4125 24 18 24C17.5875 24 17.25 23.6625 17.25 23.25V20.25V17.25C17.25 16.8375 17.5875 16.5 18 16.5Z'
      fill='currentColor'
    />
  </SVG>
)
