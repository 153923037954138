import { useState, useRef, ReactNode } from 'react'
import { ChromePicker, ColorResult } from 'react-color'
import styled, { css } from 'styled-components'

import { useEscapeKeypressListener, useOnClickOutside } from 'hooks'
import { Button } from 'components/generic'
import { PaletteOutlineIcon } from 'components/icons'
import { HexString } from 'helpers'

type Props = {
  title: string
  color: HexString
  colorSelected: (color: HexString) => void
  colorPrieviewChildren?: string | ReactNode
  isSquared?: boolean
  isBig?: boolean
}

export const ColorPicker = ({ title, color, colorSelected, colorPrieviewChildren, isSquared, isBig }: Props) => {
  const [displayPicker, setDisplayPicker] = useState(false)
  const colorPickerRef = useRef<HTMLDivElement>(null)

  useOnClickOutside(colorPickerRef, () => setDisplayPicker(false))
  useEscapeKeypressListener(() => setDisplayPicker(false))

  return (
    <Container ref={colorPickerRef}>
      <ColorPreview
        onClick={() => setDisplayPicker(!displayPicker)}
        background={color}
        $isSquared={isSquared}
        $isBig={isBig}
      >
        {colorPrieviewChildren}
      </ColorPreview>
      <PaletteButton
        id='palette-button'
        onClick={() => setDisplayPicker(!displayPicker)}
        buttonType='tertiary'
        rightEl={<StyledPaletteOutlineIcon />}
      >
        {title}
      </PaletteButton>
      <PopOver>
        {displayPicker && (
          <ChromePicker
            color={color}
            onChange={(colorResult: ColorResult) => colorSelected(colorResult.hex)}
            disableAlpha
          />
        )}
      </PopOver>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-right: ${props => props.theme.spaces.l};
`

type ColorPreviewProps = {
  background?: HexString
  $isSquared?: boolean
  $isBig?: boolean
}
const ColorPreview = styled.div.attrs<ColorPreviewProps>(({ background }) => ({
  style: { background },
}))<ColorPreviewProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  margin-right: ${props => props.theme.spaces.m};
  cursor: pointer;

  ${props =>
    props.$isSquared &&
    css`
      border-radius: 4px;
    `}

  ${props =>
    props.$isBig &&
    css`
      width: 40px;
      height: 40px;
      font-size: 24px;
    `}
`

const PopOver = styled.div`
  position: absolute;
  top: 45px;
  z-index: 2;
`

const PaletteButton = styled(Button)`
  background: ${props => props.theme.colors.greyUltraLight};
`

const StyledPaletteOutlineIcon = styled(PaletteOutlineIcon)`
  padding-left: ${props => props.theme.spaces.m};
`
