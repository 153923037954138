import { SVG, SVGProps } from './SVG'

export const LocationPinIcon = (props: SVGProps) => (
  <SVG width='16' height='12' viewBox='0 0 16 12' fill='none' {...props}>
    <path
      d='M6.01557 0C8.7539 0 13.629 3.65625 15.6405 5.25938C16.1198 5.64375 16.1198 6.35625 15.6405 6.74062C13.629 8.34375 8.7539 12 6.01557 12C2.69447 12 0 9.3125 0 6C0 2.6875 2.69447 0 6.01557 0Z'
      fill='currentColor'
    />
  </SVG>
)
