import styled from 'styled-components'

import { useEscapeKeypressListener, useManageSubscription } from 'hooks'
import { useAuth } from 'auth'
import { getIsSubscribed } from 'features/premium'

type Props = {
  onClose: () => void
}

export const CommonControlsMenu = ({ onClose }: Props) => {
  const { user, logout } = useAuth()
  const isSubscribed = getIsSubscribed(user.subscription)
  const showManageSubscriptionItem = isSubscribed && user?.subscription?.capabilities?.canManage === true
  useEscapeKeypressListener(onClose)

  const { manageSubscription } = useManageSubscription()

  return (
    <Menu>
      <Item>
        {user?.name}
        <Email>{user?.email}</Email>
      </Item>
      {showManageSubscriptionItem && (
        <LinkItem onClick={() => manageSubscription()} data-testid='button-manage-subscription-in-common-controls'>
          Manage Subscription
        </LinkItem>
      )}

      <LinkItem onClick={() => logout()} data-testid='button-logout-in-common-controls'>
        Log out
      </LinkItem>
    </Menu>
  )
}

const Menu = styled.ul`
  position: fixed;
  right: ${props => props.theme.spaces.m};
  min-width: 200px;
  flex-direction: column;
  box-shadow: 0px 3px 12px rgba(69, 90, 100, 0.3);
  background: ${props => props.theme.colors.white};
  transform: translateY(4px);
  z-index: 3;
`

const Item = styled.li`
  position: relative;
  padding: ${props => props.theme.spaces.m};
  color: ${props => props.theme.colors.greyMedium};
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${props => props.theme.colors.greyExtraLight};
  }
`

const Email = styled.div`
  margin-top: ${props => props.theme.spaces.xs};
  color: ${props => props.theme.colors.greyMediumLight};
  font-size: 11px;
  line-height: 13px;
`

const LinkItem = styled(Item)`
  min-height: 34px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;

  ${props => props.theme.animations.backgroundShapeFill}

  &:after {
    display: none;
  }
`
