import { useState } from 'react'
import styled from 'styled-components'

import { Invited } from './invited'
import { RowNode, RowNodeProps } from 'components/specific/row-node'
import { CollaboratorRoleSelect } from 'components/specific/collaborator-role-select'
import { Button, Link } from 'components/generic'
import { CollaboratorFormData } from 'components/forms/collaboratorForm/types'

import { PendingCollaborator } from 'providers'
import { spaces } from 'theme'

import { CollaboratorRole } from 'apollo/generated/graphql'
import { getAllowedRoles } from 'components/specific/collaborator-role-select/helpers'

type Props = Pick<RowNodeProps, 'title' | 'description' | 'icon'> & {
  email: PendingCollaborator['email']
  onCancel: (email: PendingCollaborator['email']) => void
  invitationUuid?: string
  isChartMember?: boolean
  isInvited?: boolean
  loggedUserChartRole?: CollaboratorRole
  onInvite?: (collaborator: CollaboratorFormData) => void
}

export const InviteRowNode = ({
  description,
  email,
  icon,
  title,
  onCancel,
  invitationUuid,
  isChartMember,
  isInvited,
  loggedUserChartRole = 'viewer',
  onInvite,
}: Props) => {
  const [role, setRole] = useState<CollaboratorRole>('editor')
  const collaborator = { role, email }

  const renderContent = () => {
    if (!isInvited)
      return (
        <>
          <CollaboratorRoleSelect
            value={role}
            allowedRoles={getAllowedRoles({ userRole: role, loggedUserChartRole })}
            onRoleChange={role => setRole(role)}
          />
          <Button onClick={() => onInvite && onInvite(collaborator)}>Invite</Button>
        </>
      )
    if (invitationUuid) return <Invited invitationUuid={invitationUuid} />
    return (
      <CancelContainer>
        Sending invite...
        <Link as='div' spacing={{ ml: spaces.m }} onClick={() => onCancel(email)}>
          Cancel
        </Link>
      </CancelContainer>
    )
  }

  return (
    <Result>
      <StyledRowNode title={title} email={isChartMember ? email : undefined} description={description} icon={icon} />

      {renderContent()}
    </Result>
  )
}

const Result = styled.div`
  min-height: 49px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: ${props => props.theme.spaces.s} ${props => props.theme.spaces.m};
  box-sizing: border-box;
  border-bottom: 1px solid ${props => props.theme.colors.greyExtraLight};
  padding: ${props => props.theme.spaces.s} 0;
  font-weight: 500;
  overflow: hidden;

  &:last-of-type {
    border-bottom: none;
  }
`

const CancelContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.dark};
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
`

const StyledRowNode = styled(RowNode)`
  width: auto;
  margin-right: auto;
`
