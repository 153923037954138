import { Auth0ProviderOptions } from '@auth0/auth0-react'

import { onRedirectCallback } from './on-redirect-callback'
import globalConfig from '../config'

const { AUTH0_CUSTOM_DOMAIN, AUTH0_CLIENT_ID, AUTH0_AUDIENCE, AUTH0_CALLBACK } = globalConfig

export const auth0ProviderOptions: Auth0ProviderOptions = {
  domain: AUTH0_CUSTOM_DOMAIN,
  clientId: AUTH0_CLIENT_ID,
  audience: AUTH0_AUDIENCE,
  redirectUri: AUTH0_CALLBACK,
  responseType: 'token id_token',
  scope: 'openid',
  onRedirectCallback,
}
