import { SVG, SVGProps } from './SVG'

export const EyeIcon = (props: SVGProps) => (
  <SVG width='14' height='14' viewBox='0 0 14 14' fill='none' {...props}>
    <path
      d='M6.99992 5.25C6.53579 5.25 6.09067 5.43437 5.76248 5.76256C5.43429 6.09075 5.24992 6.53587 5.24992 7C5.24992 7.46413 5.43429 7.90925 5.76248 8.23744C6.09067 8.56563 6.53579 8.75 6.99992 8.75C7.46405 8.75 7.90917 8.56563 8.23736 8.23744C8.56554 7.90925 8.74992 7.46413 8.74992 7C8.74992 6.53587 8.56554 6.09075 8.23736 5.76256C7.90917 5.43437 7.46405 5.25 6.99992 5.25ZM6.99992 9.91667C6.22637 9.91667 5.4845 9.60938 4.93752 9.06239C4.39054 8.51541 4.08325 7.77355 4.08325 7C4.08325 6.22645 4.39054 5.48459 4.93752 4.93761C5.4845 4.39062 6.22637 4.08333 6.99992 4.08333C7.77347 4.08333 8.51533 4.39062 9.06231 4.93761C9.60929 5.48459 9.91658 6.22645 9.91658 7C9.91658 7.77355 9.60929 8.51541 9.06231 9.06239C8.51533 9.60938 7.77347 9.91667 6.99992 9.91667V9.91667ZM6.99992 2.625C4.08325 2.625 1.59242 4.43917 0.583252 7C1.59242 9.56083 4.08325 11.375 6.99992 11.375C9.91658 11.375 12.4074 9.56083 13.4166 7C12.4074 4.43917 9.91658 2.625 6.99992 2.625Z'
      fill='currentColor'
    />
  </SVG>
)
