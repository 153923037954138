import { ChangeEvent } from 'react'
import styled from 'styled-components'

import { SelectButton, SelectButtonProps } from 'components/generic'

type Props = {
  checked: boolean
  description: string
  heading: string
  name: string
  className?: string
  note?: string
  onChange: (e?: ChangeEvent<HTMLInputElement>) => void
}

export const PermissionOption = ({ className, heading, description, note, checked, name, onChange }: Props) => (
  <CheckboxContainer className={className}>
    <StyledSelectButton type='checkbox' checked={checked} name={name} onChange={onChange} />
    <DescriptionContainer>
      <Heading>{heading}</Heading>
      <CheckboxDescription>
        {description} <Note>{note}</Note>
      </CheckboxDescription>
    </DescriptionContainer>
  </CheckboxContainer>
)

const CheckboxContainer = styled.label`
  display: flex;
  align-items: flex-start;
  user-select: none;
  cursor: pointer;
`

const StyledSelectButton = styled((props: SelectButtonProps) => <SelectButton {...props} />)`
  margin-top: 1px;
`

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${props => props.theme.spaces.m};
`

const Heading = styled.div`
  margin-bottom: ${props => props.theme.spaces.s};
  color: ${props => props.theme.colors.dark};
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`

const CheckboxDescription = styled.div`
  color: ${props => props.theme.colors.dark};
  font-size: 14px;
  line-height: 18px;
`

const Note = styled(CheckboxDescription)`
  display: inline-block;
  color: ${props => props.theme.colors.greyLight};
`
