import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client'
import introspectionQueryResultData from '../../generated/graphql.schema.json'

// translates a GraphQL introspection query into a possibleTypes configuration object
// see https://www.apollographql.com/docs/react/data/fragments/#generating-possibletypes-automatically
const possibleTypes: Record<string, any> = {}
introspectionQueryResultData.__schema.types.forEach(supertype => {
  if (supertype.possibleTypes) {
    possibleTypes[supertype.name] = supertype.possibleTypes.map(subtype => subtype.name)
  }
})

export const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: {
    Department: {
      fields: {
        capabilities: { merge: (curr, inc) => ({ ...curr, ...inc }) },
        parentNodes: { merge: false }, // always prefer incoming over existing data
      },
    },
    Person: {
      fields: {
        capabilities: { merge: (curr, inc) => ({ ...curr, ...inc }) },
        parentNodes: { merge: false },
      },
    },
    // don't normalize entities
    SubscriptionInfo: {
      keyFields: false,
    },
    CustomFieldValue: {
      keyFields: false,
    },
    Quota: {
      keyFields: false,
    },
    QuotaCollection: {
      keyFields: false,
    },
    InsightDataset: { keyFields: false },
    InsightDepartment: { keyFields: false },
  },
  dataIdFromObject: (object: any) => {
    const isDropdown = object?.__typename === 'CustomDropdownFieldValue'
    const isText = object?.__typename === 'CustomTextFieldValue'
    const isDate = object?.__typename === 'CustomDateFieldValue'
    const shouldNormalizeInCache = !(isDropdown || isText || isDate)
    return shouldNormalizeInCache ? defaultDataIdFromObject(object) : undefined
  },
})
