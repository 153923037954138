import { gql } from '@apollo/client'
import { collaboratorFragment } from '../fragments'

export const getCollaborators = gql`
  query getCollaborators($chartUuid: String!) {
    getCollaborators(chartUuid: $chartUuid) {
      ...CollaboratorFragment
    }
  }
  ${collaboratorFragment}
`
