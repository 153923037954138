import styled, { css } from 'styled-components'

import { ChevronDownIcon } from 'components/icons'

export const StyledChevronDownIcon = styled(ChevronDownIcon)<{ isPointingUp: boolean }>`
  color: ${props => props.theme.colors.border};
  transition: all ${props => props.theme.transitions.fastEase};

  ${props =>
    props.isPointingUp &&
    css`
      transform: rotate(180deg);
    `}
`
