import { useApolloClient } from '@apollo/client'

import { useChartData } from '../providers/use-chart-data'

import { personTeamSize as personTeamSizeQuery } from 'apollo/query'
import { NodeQuery, NodeQueryVariables, Person } from 'apollo/generated/graphql'

export const useRefetchPersonTeamSize = () => {
  const { providerInfo, id: chartUuid } = useChartData()
  const isGsuiteProvider = providerInfo?.name === 'gsuite'
  const apolloClient = useApolloClient()

  const refetchPersonTeamSize = (personId?: Person['id']) => {
    if (personId && isGsuiteProvider) {
      return apolloClient.query<NodeQuery, NodeQueryVariables>({
        query: personTeamSizeQuery,
        fetchPolicy: 'network-only',
        variables: { chartKey: chartUuid, uuid: personId },
      })
    }
  }

  return { isGsuiteProvider, refetchPersonTeamSize }
}
