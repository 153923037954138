import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { CheckIcon, InfoIcon, LightingIcon, WarningIcon } from 'components/icons'
import wavesSrc from 'assets/waves.svg'

type InfoBoxType = 'info' | 'success' | 'warning' | 'error' | 'til'
type Props = {
  children: ReactNode
  type?: InfoBoxType
  icon?: ReactNode
  className?: string
  isAdaptive?: boolean
  noIcon?: boolean
}

export const InfoBox = ({ children, type = 'info', icon, isAdaptive, noIcon, className }: Props) => (
  <Container type={type} isAdaptive={isAdaptive} className={className}>
    {!noIcon && <IconWrapper>{icon ? icon : getIcon(type)}</IconWrapper>}
    {children}
    {type === 'til' && <WavesImg src={wavesSrc} alt='waves' />}
  </Container>
)

const getIcon = (type?: InfoBoxType) => {
  switch (type) {
    case 'info':
      return <LightingIcon />
    case 'success':
      return <CheckIcon />
    case 'warning':
      return <InfoIcon />
    case 'error':
      return <WarningIcon />
    default:
      return null
  }
}

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
`

const WavesImg = styled.img`
  display: flex;
  position: absolute;
  pointer-events: none;
  right: 2em;
`

const Container = styled.div<{ type: InfoBoxType; isAdaptive?: boolean }>`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 52px;
  font-weight: 500;
  font-size: 14px;
  border-radius: 6px;
  margin-bottom: 25px;
  padding-left: 15px;

  color: #0052bf;
  background: #e0ecfc;

  ${props =>
    props.type === 'success' &&
    css`
      color: #00897b;
      background: #d9edeb;
    `}

  ${props =>
    props.type === 'warning' &&
    css`
      color: #8c771a;
      background: #fff9e1;
    `}

    ${props =>
    props.type === 'error' &&
    css`
      color: #c6322e;
      background: #fbe1e1;
    `}

    ${props =>
    props.type === 'til' &&
    css`
      color: ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.purpleDark};
    `}

  ${props =>
    props.isAdaptive &&
    css`
      position: absolute;
      width: auto;
      padding-right: 32px;
      left: calc(50% - 250px);
    `}

  @media (max-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    padding-left: ${props => props.theme.spaces.s};
    height: 62px;
  }
`
