import { getDate } from './get-date'

export const getNextDayMidnight = (date: Date | string | number) => {
  const newDate = getDate(date)

  if (!newDate) {
    return null
  }

  // Add day
  const nextDate = new Date(newDate)
  nextDate.setDate(nextDate.getDate() + 1)

  // Set time to midnight
  nextDate.setMilliseconds(0)
  nextDate.setSeconds(0)
  nextDate.setMinutes(0)
  nextDate.setHours(0)

  return nextDate
}
