import { useCreateStripePortalSessionMutation } from 'apollo/generated/graphql'

export const useManageSubscription = () => {
  const [createStripePortalSessionMutation] = useCreateStripePortalSessionMutation({
    update: (_, result = {}) => {
      const url = result.data?.createStripePortalSession?.url
      if (url) {
        window.location.assign(url)
      }
    },
  })

  return { manageSubscription: createStripePortalSessionMutation }
}
