import styled from 'styled-components'
import { useModal } from 'modal'

import { Card } from '../../../components'

import { Button } from 'components/generic'
import { ConfirmationModalType } from 'components/specific'

type Props = {
  onSync: () => void
  onRevokeToken: () => void
  canSync: boolean
  canRevokeToken: boolean
}

export const DangerZoneCard = ({ onSync, onRevokeToken, canSync, canRevokeToken }: Props) => {
  const { open } = useModal()

  const canDoAction = canSync || canRevokeToken

  if (!canDoAction) {
    return null
  }

  const handleSync = () =>
    open<ConfirmationModalType>('confirmationModal', {
      title: { text: 'Sync Org Chart' },
      message: 'Do you want to sync this Org Chart with an external provider? This cannot be undone.',
      confirmButton: { buttonType: 'primary', children: 'Sync' },
      onConfirm: onSync,
    })

  const handleRevokeToken = () =>
    open<ConfirmationModalType>('confirmationModal', {
      title: { text: 'Revoke Token' },
      message: 'Permanently revoke token on this Org Chart? This cannot be undone.',
      confirmButton: { buttonType: 'primary', children: 'Revoke' },
      onConfirm: onRevokeToken,
    })

  return (
    <DangerCard title='Danger Zone'>
      <Content>This action will sync this Org Chart with an external provider. This cannot be undone.</Content>
      <Button onClick={handleSync} buttonType='danger' spacing={{ mr: 'auto' }}>
        Sync
      </Button>
      <Spacer />
      <Content>This action will revoke token on this Org Chart. This cannot be undone.</Content>
      <Button onClick={handleRevokeToken} buttonType='danger' spacing={{ mr: 'auto' }}>
        Revoke token
      </Button>
    </DangerCard>
  )
}

const Content = styled.div`
  margin-bottom: ${props => props.theme.spaces.l};
  font-size: 13px;
  line-height: 20px;
`

const Spacer = styled.div`
  margin: ${props => props.theme.spaces.l} 0;
  border-top: solid 1px ${props => props.theme.colors.red};
`

const DangerCard = styled(Card)`
  border: solid 1px ${props => props.theme.colors.red};
`
