import { useMemo } from 'react'
import tc from 'tinycolor2'
import { ApolloError } from '@apollo/client'

import {
  CustomField,
  CustomDropdownField,
  CustomConnectionLineStyle,
  ChartDetail,
  CustomConnectionLineLabel,
} from '../types'
import { makeCapabilitiesReadOnly } from '../utils'
import { useChartConfig } from '../providers'
import { CONNECTION_LINE_STYLE_TO_LABEL } from 'tree/constants'
import { colors } from 'theme'

import {
  CustomDropdownFieldOption,
  CustomFieldItem,
  useChartQuery,
  CustomConnectionField as CustomConnectionFieldBE,
} from 'apollo/generated/graphql'

const convertOptions = (options: readonly CustomDropdownFieldOption[]): CustomDropdownField['options'] => {
  return options.map(({ id, label, icon, background }) => ({
    id,
    value: label,
    icon: icon || '',
    background: background || '',
  }))
}

const DEFAULT_LINE_COLOR = colors.solidRed
const getLineColor = (color: CustomConnectionFieldBE['lineColor']) => {
  if (!color) return DEFAULT_LINE_COLOR
  const tcColor = tc(color)
  return tcColor.isValid() ? tcColor.toHexString() : DEFAULT_LINE_COLOR
}

const DEFAULT_LINE_STYLE: CustomConnectionLineStyle = 'dotted'
const ALLOWED_LINE_STYLES: Record<CustomConnectionLineStyle, true> = { dashed: true, dotted: true, solid: true }
const getLineStyle = (style: CustomConnectionFieldBE['lineStyle']): CustomConnectionLineStyle => {
  if (!style) return DEFAULT_LINE_STYLE
  return Object.keys(ALLOWED_LINE_STYLES).some(allowedStyle => allowedStyle === style)
    ? (style as CustomConnectionLineStyle)
    : DEFAULT_LINE_STYLE
}

const getLineLabel = (lineStyle: CustomConnectionLineStyle): CustomConnectionLineLabel => {
  return CONNECTION_LINE_STYLE_TO_LABEL[lineStyle]
}

const convertCustomFieldToFE = (customField: CustomFieldItem): CustomField | undefined => {
  const { id, label } = customField
  switch (customField.__typename) {
    case 'CustomTextField':
      return { id, label, type: 'text', typeLabel: 'Text' }
    case 'CustomDateField':
      return { id, label, type: 'single-date', typeLabel: 'Date picker' }
    case 'CustomDropdownField':
      return {
        id,
        label,
        options: convertOptions(customField.options),
        displayIconsOnChartNode: customField.displayIconsOnChartNode,
        type: 'single-select',
        typeLabel: 'Dropdown',
      }
    case 'CustomConnectionField': {
      const lineStyle = getLineStyle(customField.lineStyle)
      return {
        id,
        label,
        lineColor: getLineColor(customField.lineColor),
        lineStyle: lineStyle,
        lineLabel: getLineLabel(lineStyle),
        type: 'connection',
        typeLabel: 'Connection',
        displayLineOnChart: customField.displayLineOnChart,
      }
    }
    default:
      console.error('Custom field:', customField, ' is not handled!')
  }
}

const convertCustomFieldsToFE = (customFields: readonly CustomFieldItem[]): CustomField[] => {
  return customFields.reduce<CustomField[]>((mapped, field) => {
    const convertedField = convertCustomFieldToFE(field)
    if (convertedField) mapped.push(convertedField)
    return mapped
  }, [])
}

type Params = {
  loading: boolean
  data?: ChartDetail
  error?: ApolloError
}

export const useChartMapper = (key: string): Params => {
  const { loading, data, error } = useChartQuery({ variables: { key } })
  const chartData = data?.chart
  const { config: chartConfig } = useChartConfig()

  const memoizedConvertedChartData = useMemo(() => {
    if (!chartData) return undefined

    // Custom fields
    const customFields = convertCustomFieldsToFE(chartData.customFields)
    const getHasCustomFieldsToDisplay = () =>
      customFields.some(f => f.type === 'single-select' && f.displayIconsOnChartNode)

    const areNodesReducedHeight = customFields.length === 0 || !getHasCustomFieldsToDisplay()

    // Capabilities
    const capabilities = { ...chartData.capabilities }
    // Return new capabilities based on chart mode
    const newCapabilities = Object.freeze(
      chartConfig.isReadOnly ? makeCapabilitiesReadOnly(capabilities) : capabilities
    ) as ChartDetail['capabilities']

    // Google Workspace
    const { providerInfo } = chartData
    const providedByGW = providerInfo?.name === 'gsuite'
    const canProviderReadUser = providerInfo?.capabilities?.canReadUser === true
    const GWTokenAccepted = providedByGW && canProviderReadUser
    const GWTokenRevoked = providedByGW && !canProviderReadUser

    const newChartData: ChartDetail = {
      ...chartData,
      customFields,
      areNodesReducedHeight,
      capabilities: newCapabilities,
      GWTokenAccepted,
      GWTokenRevoked,
    }

    return newChartData
  }, [loading, data, error, chartConfig])

  return { loading, data: memoizedConvertedChartData, error }
}
