type AnyQueryParams = Record<string, any>
type QueryParams = Record<string, string>

export const createQueryString = (queryParams: AnyQueryParams) => {
  // Filter non-string and empty string from query params
  const safeQueryParams = Object.entries(queryParams).reduce<QueryParams>((obj, [key, value]) => {
    if (typeof value === 'string' && value !== '') {
      obj[key] = value
    }

    return obj
  }, {})

  const search = new URLSearchParams(safeQueryParams)
  const searchString = search.toString()
  const queryString = searchString.length > 0 ? `?${searchString}` : ''

  return { search, queryString }
}
