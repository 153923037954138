import styled from 'styled-components'
import { BoxContainerCSS } from './styled/box-container-css'

type Props = {
  value: string
  backgroundColor: string
  icon: string
}

export const CustomFieldIconBox = ({ icon, backgroundColor, value }: Props) => (
  <Container $backgroundColor={backgroundColor} title={value}>
    {icon && <Icon>{icon}</Icon>}
  </Container>
)

type ContainerProps = { $backgroundColor: string }
const Container = styled.div.attrs<ContainerProps>(props => ({
  style: {
    background: props.$backgroundColor,
  },
}))<ContainerProps>`
  ${BoxContainerCSS}
`

const Icon = styled.div`
  font-size: 14px;
`
