import { SVG, SVGProps } from './SVG'

export const ProfileIcon = (props: SVGProps) => (
  <SVG version='1.1' width='16' height='16' x='0px' y='0px' viewBox='0 0 16 16' {...props}>
    <path
      d='M8,2c1.1,0,2,0.9,2,2S9.1,6,8,6S6,5.1,6,4S6.9,2,8,2z M8,11c2.7,0,5.8,1.3,6,2v1H2v-1C2.2,12.3,5.3,11,8,11L8,11z M8,0
	C5.8,0,4,1.8,4,4s1.8,4,4,4s4-1.8,4-4S10.2,0,8,0L8,0z M8,9c-2.7,0-8,1.3-8,4v3h16v-3C16,10.3,10.7,9,8,9L8,9z'
      fill='currentColor'
    />
  </SVG>
)
