import { SVG, SVGProps } from './SVG'

export const RotateIcon = (props: SVGProps) => (
  <SVG width='12' height='15' viewBox='0 0 12 15' fill='none' {...props}>
    <path
      d='M8.6625 4.1625L5.25 0.75V3.0525C2.295 3.42 0 5.94 0 9C0 12.06 2.2875 14.58 5.25 14.9475V13.4325C3.12 13.0725 1.5 11.2275 1.5 9C1.5 6.7725 3.12 4.9275 5.25 4.5675V7.5L8.6625 4.1625ZM11.9475 8.25C11.82 7.2075 11.4075 6.2025 10.7325 5.3325L9.6675 6.3975C10.0725 6.96 10.3275 7.5975 10.4325 8.25H11.9475ZM6.75 13.425V14.94C7.7925 14.8125 8.805 14.4075 9.675 13.7325L8.595 12.6525C8.0325 13.0575 7.4025 13.32 6.75 13.425ZM9.6675 11.61L10.7325 12.6675C11.4075 11.7975 11.82 10.7925 11.9475 9.75H10.4325C10.3275 10.4025 10.0725 11.04 9.6675 11.61Z'
      fill='currentColor'
    />
  </SVG>
)
