import { CSSObject } from 'styled-components'
import { StylesConfig } from 'react-select'

import { colors, spaces } from 'theme'

// creates "bottom margin" for menu so it's not behind form buttons (cancel+submit)
export const selectMenuBottomMargin: CSSObject = {
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-32px',
    left: '0',
    width: '1px',
    height: '1px',
  },
}

const selectText: CSSObject = {
  color: colors.greyLight,
  fontSize: '14px',
  lineHeight: '18px',
  cursor: 'default',
}

export const selectStyles: StylesConfig<any> = {
  menu: provided => ({
    ...provided,
    margin: 0,
    boxShadow: '0px 0px 6px rgba(38, 15, 63, 0.08)',
    borderRadius: '3px',
    padding: '8px 0',
    zIndex: '9',
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    minHeight: '33px',
    display: 'flex',
    alignItems: 'center',
    padding: `${spaces.s} ${spaces.m}`,
    color: state.isSelected ? colors.purple : colors.dark,
    background: state.isFocused && !state.isSelected ? colors.greyExtraLight : colors.white,
    fontSize: '14px',
    lineHeight: '18px',
    cursor: state.isSelected ? 'default' : 'pointer',
    ':active': {
      background: colors.greyExtraLight,
    },
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
    fontSize: '13px',
    lineHeight: '16px',
    color: colors.dark,
    // Fixes grid creating 2 columns when you have single select value
    'input[readonly]': { position: 'absolute' },
    'input[aria-readonly]': { position: 'absolute' },
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 299,
  }),
  singleValue: () => ({
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
  multiValue: provided => ({
    ...provided,
    maxWidth: '180px',
    cursor: 'default',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    paddingTop: spaces.xs,
    paddingBottom: spaces.xs,
    paddingRight: state.isDisabled ? provided.paddingLeft : provided.paddingRight, // even side padding when multiValueRemove is hidden
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    display: state.isDisabled ? 'none' : provided.display,
  }),
  indicatorsContainer: provided => ({
    ...provided,
    gap: spaces.m,
    marginLeft: spaces.m,
  }),
  noOptionsMessage: provided => ({
    ...provided,
    ...selectText,
  }),
  loadingMessage: provided => ({
    ...provided,
    ...selectText,
  }),
}
