import { EmployeeWithEmail } from '../invite-members'
import { InviteRowNode } from '../../components'
import { AvatarCircleMiniature } from 'components/specific/avatar-circle-miniature'
import { SearchHint } from 'components/generic/styled'

import { pluralize } from 'helpers'
import { usePendingCollaborators } from 'providers'

import { CollaboratorRole } from 'apollo/generated/graphql'
import { useGetCollaboratorUuid } from '../../hooks/use-get-collaborator-uuid'
import { SHARING_TRACK } from 'analytics/constants'
import analytics from 'analytics'

type Props = {
  employeesWithEmail: EmployeeWithEmail[]
  chartUuid: string
  loggedUserChartRole: CollaboratorRole
}

export const Results = ({ employeesWithEmail, chartUuid, loggedUserChartRole }: Props) => {
  const { getChartPendingCollabs, onAddPendingCollab, onCancelPendingCollab } = usePendingCollaborators()
  const pendingCollaborators = getChartPendingCollabs(chartUuid)
  const getCollaboratorUuid = useGetCollaboratorUuid(chartUuid)
  const getIsInvited = (email: EmployeeWithEmail['email']) => pendingCollaborators.some(c => c.email === email)

  return (
    <>
      <SearchHint>
        {employeesWithEmail.length} {pluralize('result', employeesWithEmail.length)}
      </SearchHint>

      <div>
        {employeesWithEmail.map(employee => (
          <InviteRowNode
            key={employee.uuid}
            description={employee.position}
            email={employee.email}
            icon={employee.image ? <AvatarCircleMiniature src={employee.image} /> : undefined}
            title={employee.name}
            isInvited={getIsInvited(employee.email)}
            loggedUserChartRole={loggedUserChartRole}
            onCancel={email => onCancelPendingCollab(email)}
            onInvite={collaborator => {
              analytics.track(SHARING_TRACK.inviteMember, {
                chartUuid,
                emailCollaborator: collaborator.email,
                roleCollaborator: collaborator.role,
              })
              onAddPendingCollab({ collaborator, chartUuid })
            }}
            invitationUuid={getCollaboratorUuid(employee.email)}
            isChartMember
          />
        ))}
      </div>
    </>
  )
}
