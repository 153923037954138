export const extractChartData = (method: string, result: any = {}) => {
  const {
    data: {
      [method]: {
        name = '',
        uuid = '',
        employeeCount = 0,
        unassignedEmployeeCount = 0,
        departmentCount = 0,
        unassignedDepartmentCount = 0,
        adminEmail = '',
      } = {},
    } = {},
  } = result

  const totalEmployeeCount = employeeCount + unassignedDepartmentCount
  const totalDepartmentCount = departmentCount + unassignedDepartmentCount
  return {
    uuid,
    name,
    employeeCount,
    unassignedEmployeeCount,
    departmentCount,
    unassignedDepartmentCount,
    totalEmployeeCount,
    totalDepartmentCount,
    adminEmail,
  }
}
