import { getCharts } from 'apollo/query'
import { Chart, useCloneChartMutation } from 'apollo/generated/graphql'

type CloneChartRequestParams = {
  chartId: Chart['id']
}

export const useCloneChartReqeust = () => {
  const [cloneChartMutation] = useCloneChartMutation()

  const cloneChartRequest = async ({ chartId }: CloneChartRequestParams) => {
    return cloneChartMutation({
      variables: { uuid: chartId },
      refetchQueries: [{ query: getCharts }],
    })
  }

  return { cloneChartRequest }
}
