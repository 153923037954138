import { SVG, SVGProps } from './SVG'

export const TilAnalyticsIcon = (props: SVGProps) => (
  <SVG width='315' height='35' viewBox='0 0 315 35' fill='none' {...props}>
    <path d='M41.9753 4.97362H36.0185V25.4545H32.2868V4.97362H26.3125V1.5397H41.9753V4.97362Z' fill='currentColor' />
    <path
      d='M47.5732 9.54648V25.4546H43.9993V9.54648H47.5732ZM45.7862 1.90778C46.4286 1.90778 46.9717 2.12969 47.4156 2.57354C47.8711 3.01737 48.0989 3.55465 48.0989 4.18537C48.0989 4.82776 47.8711 5.37088 47.4156 5.81473C46.9717 6.25856 46.4286 6.48048 45.7862 6.48048C45.1438 6.48048 44.5949 6.25856 44.1394 5.81473C43.6956 5.35921 43.4736 4.81025 43.4736 4.16785C43.4736 3.54881 43.7013 3.01737 44.1569 2.57354C44.6124 2.12969 45.1555 1.90778 45.7862 1.90778Z'
      fill='currentColor'
    />
    <path
      d='M63.1753 11.2107C64.4835 9.7741 66.1304 9.05579 68.116 9.05579C69.9964 9.05579 71.5557 9.61642 72.7937 10.7377C73.5647 11.4502 74.1136 12.2795 74.4406 13.2255C74.7794 14.1599 74.9486 15.3805 74.9486 16.8873V25.4545H71.3746V17.1676C71.3746 15.5557 71.1818 14.3877 70.7965 13.6635C70.3293 12.7875 69.4999 12.3495 68.3086 12.3495C67.713 12.3495 67.1581 12.4839 66.6442 12.7525C66.1304 13.0211 65.7508 13.3774 65.5055 13.8212C65.1084 14.5454 64.9098 15.7718 64.9098 17.5004V25.4545H61.3357V17.1676C61.3357 15.5557 61.143 14.3877 60.7575 13.6635C60.2903 12.7875 59.4552 12.3495 58.2522 12.3495C57.6448 12.3495 57.0841 12.4839 56.5703 12.7525C56.0562 13.0211 55.6767 13.3774 55.4315 13.8212C55.0342 14.5571 54.8357 15.7835 54.8357 17.5004V25.4545H51.2617V9.54634H54.8357V10.7027C55.8986 9.60475 57.1659 9.05579 58.6377 9.05579C60.3896 9.05579 61.9022 9.7741 63.1753 11.2107Z'
      fill='currentColor'
    />
    <path
      d='M93.3365 18.867H81.3178C81.4931 19.7196 81.8026 20.4438 82.2464 21.0394C83.064 22.149 84.2495 22.7038 85.8029 22.7038C86.6322 22.7038 87.4032 22.5227 88.1156 22.1607C88.828 21.7986 89.3945 21.2965 89.815 20.6541L90.1303 20.1459L93.2138 21.4249C91.4269 24.4384 88.9507 25.945 85.7854 25.945C83.3327 25.945 81.3296 25.0631 79.7761 23.2996C78.3511 21.6761 77.6387 19.7371 77.6387 17.4829C77.6387 14.8783 78.5496 12.7408 80.3719 11.0706C81.8434 9.72739 83.5721 9.05579 85.5577 9.05579C87.8237 9.05579 89.7099 9.89674 91.2166 11.5787C91.9876 12.4313 92.5598 13.5059 92.9335 14.8023C93.2138 15.7951 93.3542 16.8112 93.3542 17.8507C93.3542 18.026 93.3483 18.3647 93.3365 18.867ZM89.7975 16.0988C89.5406 15.0943 89.161 14.2826 88.6587 13.6635C87.8996 12.7058 86.8776 12.2269 85.5927 12.2269C84.5065 12.2269 83.5837 12.5715 82.8246 13.2606C82.077 13.938 81.5747 14.8841 81.3178 16.0988H89.7975Z'
      fill='currentColor'
    />
    <path
      d='M107.773 9.54648V25.4546H104.199V9.54648H107.773ZM105.986 1.90778C106.629 1.90778 107.172 2.12969 107.616 2.57354C108.071 3.01737 108.299 3.55465 108.299 4.18537C108.299 4.82776 108.071 5.37088 107.616 5.81473C107.172 6.25856 106.629 6.48048 105.986 6.48048C105.344 6.48048 104.795 6.25856 104.34 5.81473C103.896 5.35921 103.674 4.81025 103.674 4.16785C103.674 3.54881 103.901 3.01737 104.357 2.57354C104.813 2.12969 105.356 1.90778 105.986 1.90778Z'
      fill='currentColor'
    />
    <path
      d='M113.231 20.0934C113.722 21.0862 114.189 21.7753 114.633 22.1607C115.077 22.5345 115.632 22.7213 116.297 22.7213C117.057 22.7213 117.623 22.4936 117.997 22.038C118.324 21.6527 118.487 21.2263 118.487 20.7591C118.487 20.4438 118.4 20.1634 118.225 19.9181C118.026 19.6496 117.349 19.2932 116.192 18.8495L114.563 18.2187C112.262 17.331 111.111 15.9061 111.111 13.9439C111.111 12.5189 111.637 11.3509 112.688 10.4399C113.751 9.51715 115.03 9.05579 116.525 9.05579C118.826 9.05579 120.59 10.2121 121.816 12.5247L119.013 13.9439C118.581 13.3015 118.178 12.8576 117.804 12.6123C117.442 12.3554 116.998 12.2269 116.473 12.2269C115.9 12.2269 115.433 12.3787 115.071 12.6824C114.709 12.9744 114.528 13.3599 114.528 13.8387C114.528 14.1541 114.61 14.4286 114.773 14.6622C114.948 14.8958 115.62 15.2403 116.788 15.6958L118.26 16.2565C119.591 16.7703 120.555 17.3719 121.15 18.061C121.758 18.7502 122.062 19.6028 122.062 20.6189C122.062 22.1607 121.478 23.4514 120.309 24.4909C119.223 25.4604 117.874 25.945 116.262 25.945C114.814 25.945 113.599 25.5654 112.618 24.8062C111.649 24.047 110.866 22.879 110.271 21.3022L113.231 20.0934Z'
      fill='currentColor'
    />
    <path d='M137.235 1.5397V22.003H146.17V25.4545H133.503V1.5397H137.235Z' fill='currentColor' />
    <path
      d='M154.343 9.54637V12.7701H151.82V20.4438C151.82 21.1212 151.902 21.6118 152.066 21.9155C152.241 22.2075 152.515 22.3535 152.889 22.3535C153.263 22.3535 153.823 22.2484 154.571 22.038V25.3844C153.788 25.6181 153.064 25.7348 152.399 25.7348C151.149 25.7348 150.15 25.3144 149.403 24.4734C148.632 23.6207 148.246 22.2776 148.246 20.4438V12.7701H146.547V9.54637H148.246V6.02487L151.82 4.60574V9.54637H154.343Z'
      fill='currentColor'
    />
    <path
      d='M172.257 25.4545H168.684V24.0704C167.071 25.3202 165.437 25.9449 163.778 25.9449C162.457 25.9449 161.18 25.5887 159.941 24.8763C158.621 24.1171 157.593 23.0893 156.858 21.7928C156.098 20.4846 155.719 19.048 155.719 17.4829C155.719 14.925 156.647 12.8167 158.504 11.1582C160.081 9.75657 161.838 9.05577 163.778 9.05577C165.424 9.05577 167.06 9.68065 168.684 10.9304V1.5397H172.257V25.4545ZM164.145 12.297C162.698 12.297 161.517 12.7934 160.607 13.7862C159.754 14.7089 159.328 15.9294 159.328 17.4479C159.328 19.2348 159.866 20.6014 160.94 21.5475C161.827 22.3183 162.866 22.7038 164.058 22.7038C165.424 22.7038 166.546 22.219 167.423 21.2497C168.275 20.3152 168.702 19.0771 168.702 17.5354C168.702 15.7367 168.17 14.3643 167.106 13.4182C166.266 12.6707 165.278 12.297 164.145 12.297Z'
      fill='currentColor'
    />
    <path
      d='M178.293 21.3549C178.936 21.3549 179.478 21.5769 179.922 22.0206C180.366 22.4644 180.589 22.9959 180.589 23.615C180.589 24.2456 180.366 24.7828 179.922 25.2267C179.478 25.6706 178.936 25.8925 178.293 25.8925C177.65 25.8925 177.108 25.6706 176.664 25.2267C176.22 24.7828 175.999 24.234 175.999 23.58C175.999 22.9726 176.22 22.4528 176.664 22.0206C177.119 21.5769 177.663 21.3549 178.293 21.3549Z'
      fill='currentColor'
    />
    <path
      d='M195.367 18.6767H206.419L209.003 25.4583H211.801L202.58 1.52573H199.242L190.021 25.4583H192.785L195.367 18.6767ZM196.301 16.201L200.857 4.10915L205.45 16.201H196.301Z'
      fill='currentColor'
    />
    <path
      d='M216.471 9.56313H213.922V25.4584H216.471V17.4929C216.471 15.1606 216.757 13.9765 217.474 13.0795C218.263 12.1107 219.555 11.5725 221.027 11.5725C222.282 11.5725 223.288 12.003 224.04 12.7924C224.831 13.6536 225.117 14.8376 225.117 17.1699V25.4584H227.664V16.847C227.664 14.0841 227.163 12.3977 226.014 11.1419C224.831 9.85018 223.144 9.13257 221.278 9.13257C219.269 9.13257 217.654 9.88606 216.471 11.3572V9.56313Z'
      fill='currentColor'
    />
    <path
      d='M246.996 25.4584V9.56313H244.449V11.6442C242.906 9.95782 241.076 9.13257 238.851 9.13257C234.403 9.13257 230.813 12.8642 230.813 17.4929C230.813 22.1573 234.403 25.8889 238.851 25.8889C241.04 25.8889 242.978 25.0277 244.449 23.3773V25.4584H246.996ZM239.102 11.4648C242.223 11.4648 244.521 14.0124 244.521 17.4929C244.521 21.0451 242.223 23.5566 238.996 23.5566C235.694 23.5566 233.397 21.0451 233.397 17.4569C233.397 13.9047 235.729 11.4648 239.102 11.4648Z'
      fill='currentColor'
    />
    <path d='M253.736 25.4583V1.52573H251.188V25.4583H253.736Z' fill='currentColor' />
    <path
      d='M262.175 24.7406L258.228 33.8185H260.883L271.504 9.56302H268.778L263.539 21.6189L258.48 9.56302H255.718L262.175 24.7406Z'
      fill='currentColor'
    />
    <path
      d='M276.292 11.8595H278.947V9.56311H276.292V4.79095L273.745 5.79561V9.56311H271.95V11.8595H273.745V20.6861C273.745 24.0231 275.037 25.7096 277.584 25.7096C278.121 25.7096 278.552 25.6377 279.162 25.4584V23.1261C278.661 23.3056 278.409 23.3414 278.086 23.3414C276.829 23.3414 276.292 22.5161 276.292 20.6861V11.8595Z'
      fill='currentColor'
    />
    <path
      d='M284.335 9.56312H281.788V25.4584H284.335V9.56312ZM283.08 2.42282C282.076 2.42282 281.25 3.24809 281.25 4.21687C281.25 5.25742 282.076 6.08268 283.08 6.08268C284.085 6.08268 284.909 5.25742 284.909 4.25275C284.909 3.24809 284.085 2.42282 283.08 2.42282Z'
      fill='currentColor'
    />
    <path
      d='M301.781 11.8236C299.952 9.95782 298.159 9.13257 295.827 9.13257C291.126 9.13257 287.538 12.7565 287.538 17.4929C287.538 22.265 291.126 25.8889 295.827 25.8889C298.266 25.8889 299.989 25.0995 301.926 23.0544L300.203 21.3321C298.803 22.9826 297.656 23.5566 295.79 23.5566C292.452 23.5566 290.12 21.0809 290.12 17.4929C290.12 13.9406 292.452 11.4648 295.754 11.4648C297.513 11.4648 298.803 12.0748 300.095 13.5459L301.781 11.8236Z'
      fill='currentColor'
    />
    <path
      d='M314.017 12.003C312.905 10.0655 311.362 9.13257 309.244 9.13257C306.374 9.13257 304.185 11.106 304.185 13.7253C304.185 15.5552 305.262 16.8828 307.415 17.7439L309.138 18.4258C311.433 19.3227 311.936 19.7532 311.936 20.9733C311.936 22.4802 310.716 23.5926 309.065 23.5926C307.486 23.5926 306.626 22.9109 305.657 20.9016L303.468 21.8345C304.508 24.5255 306.374 25.8889 308.993 25.8889C312.079 25.8889 314.483 23.7001 314.483 20.8298C314.483 18.928 313.406 17.7081 310.859 16.7035L309.316 16.1293C307.881 15.5552 307.523 15.3758 307.235 15.1606C306.805 14.7659 306.554 14.2635 306.554 13.6177C306.554 12.326 307.666 11.3931 309.173 11.3931C310.393 11.3931 311.147 11.8595 311.936 13.1153L314.017 12.003Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.4803 25.755L7.76927 13.6727L0 25.755H15.4803Z'
      fill='currentColor'
    />
    <path fillRule='evenodd' clipRule='evenodd' d='M0 1.59091L7.76927 13.6727L15.4803 1.59091H0Z' fill='currentColor' />
  </SVG>
)
