import { SVG, SVGProps } from './SVG'

export const SendEmailIcon = (props: SVGProps) => (
  <SVG width='15' height='14' viewBox='0 0 15 14' fill='none' {...props}>
    <path
      d='M12.3333 0.666626L1.66659 0.666626C0.933252 0.666626 0.333252 1.26663 0.333252 1.99996L0.333252 9.99996C0.333252 10.7333 0.933252 11.3333 1.66659 11.3333H7.66659V9.99996H1.66659L1.66659 3.33329L6.99992 6.66663L12.3333 3.33329V6.66663H13.6666V1.99996C13.6666 1.26663 13.0666 0.666626 12.3333 0.666626ZM6.99992 5.33329L1.66659 1.99996L12.3333 1.99996L6.99992 5.33329ZM11.6666 7.99996L14.3333 10.6666L11.6666 13.3333V11.3333H8.99992V9.99996H11.6666V7.99996Z'
      fill='currentColor'
    />
  </SVG>
)
