import styled from 'styled-components'

import { Heading, Container } from './charts/styled'

export const WidgetHeading = styled(Heading)`
  padding: 0 25px;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    top: ${props => props.theme.spaces.l};
    font-size: 16px;
    padding: 0 ${props => props.theme.spaces.m};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const WidgetContainer = styled(Container)`
  flex-direction: column;
  height: 292px;
  padding: 68px 0 0 0;
`
