import { SVG, SVGProps } from './SVG'

export const QuestionMarkInCircleIcon = (props: SVGProps) => (
  <SVG width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <path
      d='M7.2 12.8H8.8V11.2H7.2V12.8ZM8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0ZM8 14.4C4.472 14.4 1.6 11.528 1.6 8C1.6 4.472 4.472 1.6 8 1.6C11.528 1.6 14.4 4.472 14.4 8C14.4 11.528 11.528 14.4 8 14.4ZM8 3.2C6.232 3.2 4.8 4.632 4.8 6.4H6.4C6.4 5.52 7.12 4.8 8 4.8C8.88 4.8 9.6 5.52 9.6 6.4C9.6 8 7.2 7.8 7.2 10.4H8.8C8.8 8.6 11.2 8.4 11.2 6.4C11.2 4.632 9.768 3.2 8 3.2Z'
      fill='currentColor'
    />
  </SVG>
)
