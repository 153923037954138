export const LoginImage = () => (
  <svg width='131' height='174' viewBox='0 0 131 174'>
    <g fill='none' fillRule='evenodd'>
      <rect width='110' height='163' y='4' fill='#216398' fillRule='nonzero' opacity='.1' />
      <polygon fill='#CCC' fillRule='nonzero' points='80 172 0 167.768 0 4.232 80 0' />
      <circle cx='73' cy='94' r='1' fill='#535461' fillRule='nonzero' />
      <polygon fill='#535461' fillRule='nonzero' points='73.5 94 74.25 95 75 96 73.5 96 72 96 72.75 95' />
      <polygon fill='#B7B7B7' fillRule='nonzero' points='84 172 79 171.984 79.198 0 84 0' />
      <polygon
        fill='#CCC'
        fillRule='nonzero'
        points='4.814 172 .052 172 .29 0 4.814 0'
        opacity='.1'
        transform='translate(79)'
      />
      <circle cx='73' cy='87' r='3' fill='#000' fillRule='nonzero' opacity='.1' />
      <circle cx='73' cy='86' r='3' fill='#535461' fillRule='nonzero' />
      <line x1='13' x2='13' y1='4' y2='168' stroke='#000' strokeWidth='2' opacity='.1' />
      <line x1='27' x2='27' y1='3' y2='169' stroke='#000' strokeWidth='2' opacity='.1' />
      <line x1='41' x2='41' y1='2' y2='170' stroke='#000' strokeWidth='2' opacity='.1' />
      <line x1='55' x2='55' y1='2' y2='171' stroke='#000' strokeWidth='2' opacity='.1' />
      <line x1='70' x2='70' y1='1' y2='171' stroke='#000' strokeWidth='2' opacity='.1' />
      <ellipse cx='115' cy='24' fill='#2F2E41' fillRule='nonzero' rx='10' ry='9' />
      <path
        fill='#FFB8B8'
        fillRule='nonzero'
        d='M86.6478836,81 L84.2300411,84.556038 C84.2300411,84.556038 72.6243972,87.0960651 76.9765137,90.9061058 C81.3286301,94.7161465 87.6150205,87.3500678 87.6150205,87.3500678 L91,82.778019 L86.6478836,81 Z'
      />
      <path
        fill='#6C63FF'
        fillRule='nonzero'
        d='M99.2814624,41 C99.2814624,41 96.0686452,41 95.3272258,45.4947212 C94.5858065,49.9894423 93.8443871,64.9718462 93.8443871,64.9718462 C93.8443871,64.9718462 83.4645161,78.7057165 85.1944946,80.4536636 C86.9244731,82.2016107 88.9015914,85.4477982 90.1372903,84.9483848 C91.3729892,84.4489713 102,69.7162741 102,69.7162741 L99.2814624,41 Z'
      />
      <path
        fill='#7EC53C'
        fillRule='nonzero'
        d='M99.2814624,41 C99.2814624,41 96.0686452,41 95.3272258,45.4947212 C94.5858065,49.9894423 93.8443871,64.9718462 93.8443871,64.9718462 C93.8443871,64.9718462 83.4645161,78.7057165 85.1944946,80.4536636 C86.9244731,82.2016107 88.9015914,85.4477982 90.1372903,84.9483848 C91.3729892,84.4489713 102,69.7162741 102,69.7162741 L99.2814624,41 Z'
      />
      <path
        fill='#FFB8B8'
        fillRule='nonzero'
        d='M109.207814,29 C109.207814,29 108.967157,38.4228188 106.079275,38.9060403 C103.191393,39.3892617 104.15402,42.5302013 104.15402,42.5302013 C104.15402,42.5302013 113.539638,48.0872483 119.796716,42.5302013 L121,39.6308725 C121,39.6308725 116.668177,36.4899329 118.593431,32.1409396 L109.207814,29 Z'
      />
      <path
        fill='#535461'
        fillRule='nonzero'
        d='M92.4141999,88 C92.4141999,88 77.7547401,115.467266 83.2209794,123.957148 C88.6872186,132.44703 96.8865775,149.9262 98.1289046,149.9262 C99.3712317,149.9262 106.328264,146.430366 105.831333,144.183044 C105.334402,141.935722 97.8804392,125.20566 95.8927158,123.707446 C93.9049925,122.209231 94.8988542,118.213993 95.1473196,117.96429 C95.395785,117.714588 103.595144,104.48036 103.595144,104.48036 C103.595144,104.48036 103.346678,125.455363 104.34054,128.701494 C105.334402,131.947625 104.092075,155.16995 105.334402,156.16876 C106.576729,157.16757 112.291434,158.915487 113.285295,157.417272 C114.279157,155.919058 117.260742,133.196138 115.273019,127.952387 L119,91.9952387 L92.4141999,88 Z'
      />
      <path
        fill='#535461'
        fillRule='nonzero'
        d='M105.393062,155 L99.0402366,166.358845 C99.0402366,166.358845 89.8921683,172.779061 96.2449935,173.766787 C102.597819,174.754513 106.409514,172.285199 106.409514,172.285199 L112,168.334296 L112,156.975451 L105.393062,155 Z'
      />
      <path
        fill='#535461'
        fillRule='nonzero'
        d='M97,148.367655 L100.192982,156.114931 C100.192982,156.114931 101.421053,159.363789 100.192982,160.113525 C98.9649123,160.863261 95.5263158,165.36168 97.7368421,165.861504 C98.1815284,165.95015 98.6334494,165.995862 99.0865846,165.998031 C102.806064,166.061726 106.389919,164.577797 109.008822,161.889636 L111,159.863613 C111,159.863613 104.179281,143.882165 104.27386,144.000656 C104.368438,144.119146 97,148.367655 97,148.367655 Z'
      />
      <circle cx='115.5' cy='26.5' r='8.5' fill='#FFB8B8' fillRule='nonzero' />
      <path
        fill='#7EC53C'
        fillRule='nonzero'
        d='M119.54659,38.0152968 L116.963983,40.6213565 C116.963983,40.6213565 109.093181,43.5997104 105.403742,40.6213565 C105.403742,40.6213565 106.756536,37.7671007 106.756536,38.0152968 C106.756536,38.263493 96.0571638,39.876768 95.0733135,44.3442989 C94.0894632,48.8118298 95.8112012,56.5059109 95.8112012,56.5059109 C95.8112012,56.5059109 88.924249,89.2678042 91.6298374,90.0123927 C94.3354257,90.7569812 119.669572,96.9618852 119.423609,92.2461581 C119.177646,87.5304311 121.39131,68.1711304 121.39131,68.1711304 L130,51.5419876 C130,51.5419876 128.770187,46.0816721 125.818636,44.3442989 C122.867085,42.6069258 119.54659,38.0152968 119.54659,38.0152968 Z'
      />
      <path
        fill='#FFB8B8'
        fillRule='nonzero'
        d='M108.649234,87 L105.537807,88.9674797 C105.537807,88.9674797 93.5707843,97.3292683 99.7936364,98.804878 C106.016488,100.280488 109.606595,92.902439 109.606595,92.902439 L112,89.9512195 L108.649234,87 Z'
      />
      <path
        fill='#000'
        fillRule='nonzero'
        d='M127.376386,50 L129.625198,52.2216987 C129.625198,52.2216987 131.124406,72.9575537 128.12599,77.1540958 C125.127575,81.3506379 111.384836,92.7059871 110.635232,91.9654208 C109.885628,91.2248546 105.138137,87.5220233 106.137609,87.0283125 C107.13708,86.5346017 120.425776,72.8169893 120.425776,72.8169893 L119.380611,53.7028312 L127.376386,50 Z'
        opacity='.1'
      />
      <path
        fill='#7EC53C'
        fillRule='nonzero'
        d='M128.376386,50 L130.625198,52.2216987 C130.625198,52.2216987 132.124406,72.9575537 129.12599,77.1540958 C126.127575,81.3506379 112.384836,92.7059871 111.635232,91.9654208 C110.885628,91.2248546 106.138137,87.5220233 107.137609,87.0283125 C108.13708,86.5346017 121.425776,72.8169893 121.425776,72.8169893 L120.380611,53.7028312 L128.376386,50 Z'
      />
      <path
        fill='#2F2E41'
        fillRule='nonzero'
        d='M112.008043,13.7326065 C112.008043,13.7326065 109.211584,12.6764953 105.398229,14.9207314 L107.432018,15.4487857 C107.432018,15.4487857 104.381335,15.7128129 104,19.9372578 L105.398229,19.9372578 C105.398229,19.9372578 104.508446,23.6336459 105.398229,24.6897571 L105.970224,23.5676391 L107.622676,27 L108.004006,25.2838183 L108.766677,25.547848 L109.275126,22.7755551 C109.275126,22.7755551 110.546243,24.7557639 111.563138,24.8877775 L111.563138,23.1715983 C111.563138,23.1715983 114.359596,26.4719431 115.249379,26.3399295 L113.978261,24.4917368 L115.757825,24.8877725 L114.995166,23.5676341 L119.57119,24.8877725 L118.554296,23.5676341 L121.732088,24.6237453 L123.257431,25.2838133 C123.257431,25.2838133 125.418332,20.1352732 122.367648,16.5708987 C119.316965,13.0065242 114.868045,12.2144452 112.008043,13.7326065 Z'
      />
    </g>
  </svg>
)
