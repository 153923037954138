import styled from 'styled-components'

export const SearchHint = styled.div`
  margin-bottom: ${props => props.theme.spaces.l};
  color: ${props => props.theme.colors.greyLight};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  cursor: default;
`
