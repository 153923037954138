import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { LeftSide } from './left-side'
import { RightSide } from './right-side'
import { WebLayout } from 'components/layouts'

import { ChartIdContextSafeProvider } from 'tree/providers'

export const PricingScreen = () => {
  const { chartUuid } = useParams<{ chartUuid: string }>()

  return (
    <ChartIdContextSafeProvider value={chartUuid}>
      <WebLayout>
        <Container>
          <LeftSide />
          <RightSide />
        </Container>
      </WebLayout>
    </ChartIdContextSafeProvider>
  )
}

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    flex-direction: row;
  }
`
