import { useState, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import {
  CommonControls,
  HelpCenter,
  Home,
  ChartShareButton,
  ChartSelect,
  Logo,
  ChartSettings,
  InsightsButton,
  ChartButton,
  RegisterToApp,
  ChartDuplicate,
  ChartCustomFieldsButton,
  ChartExportButton,
} from './components'
import { MobileMenu } from './components/mobile-menu'
import { IconButton, SearchField } from 'components/generic'
import { SearchIcon, BurgerIcon, ChevronLeftIcon, CloseIcon } from 'components/icons'

import { useShowComponents } from './components/use-show-components'
import { useResponsiveInfo, useTopNavigationToggle } from 'providers'
import { useChartId } from 'tree/providers'
import { CHART_EDIT_PATH } from 'routes'
import { ORG_CHART_HELP_CENTER_URL } from 'consts'
import { mainTheme } from 'theme'

export const TopNavigation = () => {
  const { path } = useRouteMatch()
  const chartUuid = useChartId(true)
  const { isVisible, withAnimation } = useTopNavigationToggle()

  const {
    showRegisterToApp,
    showChartSelect,
    showChartButton,
    showSearchField,
    showInsightsButton,
    showChartSettings,
    showChartShareButton,
    showChartExportButton,
    showCustomFieldsButton,
    showDuplicatesButton,
    showCommonControls,
  } = useShowComponents()

  const [isMobileSearchActive, setIsMobileSearchActive] = useState(false)
  const [isBurgerMenuActive, setIsBurgerMenuActive] = useState(false)
  useEffect(() => {
    if (isMobileSearchActive) setIsBurgerMenuActive(false)
  }, [isMobileSearchActive])

  const [editPageVisited, setEditPageVisited] = useState(false)
  useEffect(() => {
    if (path.includes(CHART_EDIT_PATH) && !editPageVisited) setEditPageVisited(true)
  }, [path])

  const { isSmallerDevice } = useResponsiveInfo()

  if (isSmallerDevice) {
    return (
      <TopNavigationInner $isVisible={isVisible} $hasTransformAnimation={withAnimation}>
        {!isMobileSearchActive && <Logo spacing={{ mx: mainTheme.spaces.l }} />}

        {!isMobileSearchActive && showChartSelect && (
          <LeftContent>
            <ChartSelect chartUuid={chartUuid} />
          </LeftContent>
        )}

        <RightContent $isMobileSearchActive={isMobileSearchActive}>
          {showSearchField &&
            (isMobileSearchActive ? (
              <>
                <IconButton onClick={() => setIsMobileSearchActive(false)}>
                  <StyledChevronLeftIcon />
                </IconButton>
                <StyledSearchField mode='independent' chartUuid={chartUuid} />
              </>
            ) : (
              <>
                <IconButton onClick={() => setIsMobileSearchActive(true)}>
                  <StyledSearchIcon />
                </IconButton>
              </>
            ))}
          {!isMobileSearchActive && (
            <IconButton onClick={() => setIsBurgerMenuActive(!isBurgerMenuActive)} spacing={{ m: mainTheme.spaces.l }}>
              {isBurgerMenuActive ? <StyledCloseIcon /> : <BurgerIcon />}
            </IconButton>
          )}
        </RightContent>

        {isBurgerMenuActive && (
          <MobileMenu editPageVisited={editPageVisited} onMenuClose={() => setIsBurgerMenuActive(false)} />
        )}
      </TopNavigationInner>
    )
  }

  return (
    <TopNavigationInner $isVisible={isVisible} $hasTransformAnimation={withAnimation}>
      <Logo spacing={{ mr: mainTheme.spaces.l }} />

      <LeftContent>
        {showRegisterToApp ? <RegisterToApp /> : <Home />}
        {showChartSelect && <ChartSelect chartUuid={chartUuid} />}
        {showChartButton && <ChartButton chartUuid={chartUuid as string} />}
        {showInsightsButton && <InsightsButton chartUuid={chartUuid as string} editPageVisited={editPageVisited} />}
        {showSearchField && <StyledSearchField mode='topNavigation' chartUuid={chartUuid} />}
      </LeftContent>

      <RightContent>
        {showCustomFieldsButton && <ChartCustomFieldsButton chartUuid={chartUuid as string} />}
        {showChartShareButton && <ChartShareButton chartUuid={chartUuid as string} />}
        {showChartExportButton && <ChartExportButton chartUuid={chartUuid as string} />}
        {showDuplicatesButton && <ChartDuplicate chartUuid={chartUuid as string} />}
        {showChartSettings && <ChartSettings chartUuid={chartUuid as string} />}
        {ORG_CHART_HELP_CENTER_URL && <HelpCenter shiftXPx={showCommonControls ? undefined : -28} />}
        {showCommonControls && <CommonControls />}
      </RightContent>
    </TopNavigationInner>
  )
}

const TopNavigationInner = styled.header<{ $isVisible: boolean; $hasTransformAnimation: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  width: 100%;
  max-width: 100vw;
  height: ${props => props.theme.sizes.topNavigation};
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  padding: 0 ${props => props.theme.spaces.m};
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 1px 4px rgba(69, 90, 100, 0.12);
  color: ${props => props.theme.colors.dark};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  transition: ${props => (props.$hasTransformAnimation ? `transform ${props.theme.transitions.slowEase}` : 'none')};
  transform: translateY(${props => (props.$isVisible ? '0%' : '-100%')});
  z-index: 200;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    overflow: initial;
    height: ${props => props.theme.sizes.topNavigationMobile};
    padding: 0;
  }
`

const LeftContent = styled.div`
  flex: 1;
  align-self: center;
  display: flex;
  align-items: center;

  & > * {
    margin-right: ${props => props.theme.spaces.m};
  }

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    overflow: hidden;

    & > * {
      margin-right: 0;
    }
  }
`

const StyledSearchField = styled(props => <SearchField {...props} />)`
  flex: 1 400px;
  min-width: 240px;
  width: auto;
  max-width: 400px;
  min-height: 34px;
  border-radius: 3px;
  background: transparent;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: ${props => props.theme.colors.greyUltraLight};
    pointer-events: none;
    z-index: -5;
  }

  ${props => props.theme.animations.backgroundShapeFill};

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    min-width: calc(100vw - 64px);
  }
`

const RightContent = styled.div<{ $isMobileSearchActive?: boolean }>`
  align-self: center;
  display: flex;
  align-items: center;
  margin-left: auto;

  & > * {
    margin-left: ${props => props.theme.spaces.m};
  }

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    margin-left: ${props => (props.$isMobileSearchActive ? 'unset' : 'auto')};
    & > * {
      margin-left: ${props => props.theme.spaces.m};
    }
  }
`

const StyledSearchIcon = styled(SearchIcon)`
  width: 20px;
  height: 20px;
`

const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  width: 24px;
  height: 24px;
`

const StyledCloseIcon = styled(CloseIcon)`
  width: 18px;
  height: 18px;
`
