import { gql } from '@apollo/client'

export const chartCapabilitiesFragment = gql`
  fragment ChartCapabilities on ChartCapabilities {
    canAddCollaborator
    canClone
    canCreateCustomField
    canCreateDepartment
    canCreatePerson
    canCreatePublicLink
    canDelete
    canDeleteCollaborator
    canDeleteCustomField
    canDeletePublicLink
    canExport
    canReadCollaborators
    canReadCustomField
    canReadInsight
    canReadPublicLink
    canUpdate
    canUpdateCollaborator
    canUpdateCustomField
    canUpdatePublicLink
    canReadDirectory
    canSync
    canRevokeToken
  }
`
