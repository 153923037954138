import { LabelHTMLAttributes } from 'react'
import styled from 'styled-components'

import { Card } from '../../../components'
import { ConfirmationModalType } from 'components/specific'
import { Button, FileUploader, FlexBox } from 'components/generic'
import { SpinnerIcon } from 'components/icons'

import { useModal } from 'modal'
import { spaces } from 'theme'

type Props = {
  isLoading: boolean
  labelProps: LabelHTMLAttributes<HTMLLabelElement>
  file?: File
  onDelete: () => void
  onImport: (file?: File) => void
}

export const ImportEmployeesCard = ({ isLoading, labelProps, file, onDelete, onImport }: Props) => {
  const { open } = useModal()

  const handleImport = () =>
    open<ConfirmationModalType>('confirmationModal', {
      title: { text: 'Import employee data' },
      message:
        'Add, update, or delete employee data. The data will be overwritten based on the attached CSV file. This cannot be undone.',
      confirmButton: { buttonType: 'primary', children: 'Confirm & Import employee data' },
      onConfirm: () => onImport(file),
    })

  return (
    <Card title='Import employee data'>
      <Content>
        This feature allows you to add, update, or delete employee data using a CSV file. You can use this feature to
        bulk upload employee data to your Org Chart.
      </Content>

      <Table>
        <MainRow>
          <Cell>CSV File</Cell> Impact in your Org Chart
        </MainRow>
        <Row>
          <Cell>New employees</Cell>The employee will be added.
        </Row>
        <Row>
          <Cell>Existing employees</Cell>Employee data will be updated only if some value has changed.
        </Row>
        <Row>
          <Cell>Missing employees</Cell>The employee will be deleted from the chart.
        </Row>
      </Table>

      <Content>
        {isLoading && <LoaderBackground />}
        <FileUploader
          uploadTitle='Select CSV to upload'
          uploadDescription='You can drag and drop.'
          uploadedTitle='Your file'
          uploadedDescription='You can delete and upload new CSV file.'
          file={file}
          labelProps={labelProps}
          onDelete={onDelete}
        />
      </Content>

      <Button onClick={handleImport} disabled={!file || isLoading} spacing={{ ml: 'auto' }}>
        {isLoading && <SpinnerIcon width='20px' height='100%' $spacing={{ mr: spaces.m }} />} Import employee data
      </Button>
    </Card>
  )
}

const Content = styled.div`
  position: relative;
  margin-bottom: ${props => props.theme.spaces.l};
  font-size: 13px;
  line-height: 20px;
`

const Table = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: ${props => props.theme.spaces.l};
`

const Row = styled.div`
  min-height: 40px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.s} ${props => props.theme.spaces.m};
  border-bottom: 1px solid ${props => props.theme.colors.border};

  &:last-of-type {
    border-bottom: 0;
  }
`

const MainRow = styled(Row)`
  background: ${props => props.theme.colors.greyMedium};
  color: ${props => props.theme.colors.white};
  border-bottom: 0;
  font-weight: 500;
`

const Cell = styled.div`
  padding-right: ${props => props.theme.spaces.l};
  box-sizing: border-box;

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    min-width: 170px;
  }
`

const LoaderBackground = styled(FlexBox)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.grey};
  opacity: 0.4;
  z-index: 3;
`
