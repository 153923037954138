import { Button, ButtonProps } from 'components/generic'
import { GemIcon } from 'components/icons'

import { useGoToPremiumScreen } from './hooks'
import { getIsSubscribed } from './helpers'
import { useChartData } from 'tree/providers'
import { useAuth } from 'auth'
import { spaces } from 'theme'

export const UpgradeSubscriptionButton = (buttonProps: ButtonProps) => {
  const { goToPremiumScreen } = useGoToPremiumScreen()
  const chartData = useChartData(true)
  const { user } = useAuth()
  const isSubscribed = getIsSubscribed(chartData?.subscription || user.subscription)

  return (
    <Button
      buttonType='warning'
      leftEl={<GemIcon $spacing={{ mr: spaces.m }} />}
      onClick={() => goToPremiumScreen()}
      {...buttonProps}
    >
      Upgrade to {isSubscribed ? 'Business' : 'PRO'}
    </Button>
  )
}
