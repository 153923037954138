import styled, { css } from 'styled-components'

import { FlexBox, BaseText, Link } from 'components/generic'
import { VerifyEmailImage } from './images/verify-email-image'

type Props = {
  onResendEmail: () => void
  onRefresh: () => void
}

export const ChartVerifyEmailScreen = ({ onResendEmail, onRefresh }: Props) => (
  <FlexBox $isDirectionColumn $isCentered>
    <VerifyEmailImage />
    <FlexBox $spacing={{ mt: '30px' }} $isDirectionColumn $isCentered>
      <FlexBox>
        <Text $isBold>Access denied: Email unverified</Text>
      </FlexBox>
      <FlexBox $spacing={{ mt: '20px' }}>
        <Text>
          Please follow the verification instructions sent to you via email, then{' '}
          <Link as='div' onClick={onRefresh}>
            refresh this page
          </Link>
          .
        </Text>
      </FlexBox>
      <FlexBox $spacing={{ mt: '10px' }}>
        <Text>
          <Link as='div' onClick={onResendEmail}>
            Click here
          </Link>{' '}
          to resend the verification email.
        </Text>
      </FlexBox>
    </FlexBox>
  </FlexBox>
)

const Text = styled(BaseText)<{ $isBold?: boolean }>`
  font-size: 13px;
  line-height: 20px;

  ${props =>
    props.$isBold &&
    css`
      font-weight: bold;
    `}
`
