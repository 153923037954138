import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { CloseButton } from './close-button'
import { OrgchartFullLogo } from 'components/icons'

import { CHARTS_PATH } from 'routes'

type Props = {
  loading: boolean
  onClose: () => void
}

// TODO: move to 'screens/create-chart/components' and use it also inside create-chart-screen
export const LeftHeader = ({ loading, onClose }: Props) => {
  const history = useHistory()
  const onLogoClick = () => history.push(CHARTS_PATH)

  return (
    <LeftHeaderInner>
      <OrgchartFullLogo onClick={onLogoClick} />
      {!loading && <CloseButton tooltip='Return to Org Chart' onClose={onClose} />}
    </LeftHeaderInner>
  )
}

const LeftHeaderInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.spaces.xl};
`
