import { ReactNode } from 'react'

import { FlexBox, SocialLink } from 'components/generic'

import { SOCIAL_LINKS_ICON } from 'tree/constants'
import { SocialLinkFieldKey } from 'tree/types'

type Props = {
  children: ReactNode
  name: SocialLinkFieldKey
}

export const SocialLinkTitle = ({ children, name }: Props) => {
  const { background, icon } = SOCIAL_LINKS_ICON[name]

  return (
    <FlexBox $gap='s'>
      <SocialLink background={background} icon={icon} fontSize='11px' size='16px' />
      <span>{children}</span>
    </FlexBox>
  )
}
