import { SVG, SVGProps } from './SVG'

export const MapWithMagnifyingGlassIcon = (props: SVGProps) => (
  <SVG width='18' height='18' viewBox='0 0 18 18' fill='none' {...props}>
    <path
      d='M11.625 9C13.5 9 15 10.5 15 12.375C15 13.035 14.8125 13.65 14.4825 14.175L16.7925 16.5L15.75 17.5425L13.41 15.24C12.8925 15.5625 12.2775 15.75 11.625 15.75C9.75 15.75 8.25 14.25 8.25 12.375C8.25 10.5 9.75 9 11.625 9ZM11.625 10.5C11.1277 10.5 10.6508 10.6975 10.2992 11.0492C9.94754 11.4008 9.75 11.8777 9.75 12.375C9.75 12.8723 9.94754 13.3492 10.2992 13.7008C10.6508 14.0525 11.1277 14.25 11.625 14.25C12.1223 14.25 12.5992 14.0525 12.9508 13.7008C13.3025 13.3492 13.5 12.8723 13.5 12.375C13.5 11.8777 13.3025 11.4008 12.9508 11.0492C12.5992 10.6975 12.1223 10.5 11.625 10.5ZM14.625 1.5C14.7245 1.5 14.8198 1.53951 14.8902 1.60984C14.9605 1.68016 15 1.77554 15 1.875V8.8575C14.565 8.445 14.0625 8.1075 13.5 7.875V3.525L11.25 4.395V7.5C10.725 7.5525 10.215 7.68 9.75 7.875V4.4025L6.75 3.3525V12.0975V12.375C6.75 12.855 6.8175 13.32 6.945 13.755L6 13.425L1.995 14.9775L1.875 15C1.77554 15 1.68016 14.9605 1.60984 14.8902C1.53951 14.8198 1.5 14.7245 1.5 14.625V3.285C1.5 3.1125 1.6125 2.9775 1.77 2.925L6 1.5L10.5 3.075L14.505 1.5225L14.625 1.5ZM3 4.095V12.9825L5.25 12.1125V3.3375L3 4.095Z'
      fill='currentColor'
    />
  </SVG>
)
