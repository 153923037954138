import { ReactNode } from 'react'
import styled from 'styled-components'

import { sizes } from 'theme'

type Props = {
  children: ReactNode
}

export const NodeEditContainer = ({ children }: Props) => (
  <NodeEditContainerInner>
    <OverflowWrap>{children}</OverflowWrap>
  </NodeEditContainerInner>
)

const NodeEditContainerInner = styled.div`
  position: fixed;
  width: inherit;
  max-width: inherit;
  height: calc(100vh - ${sizes.topNavigation} - ${sizes.chartSidePanelFormButtons});
  box-sizing: border-box;
  animation: ${props => props.theme.keyframes.opacity} ${props => props.theme.transitions.mediumLinear};
`

// OverflowWrap due to Safari bug
// https://stackoverflow.com/questions/26704903/only-in-safari-positionfixed-child-cut-off-when-parent-is-positionfixed-and/59656203#59656203
// Working example: https://jsfiddle.net/jxmallett/gsyb326v/1/
const OverflowWrap = styled.div`
  width: inherit;
  max-width: inherit;
  height: inherit;
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.l};
  padding-bottom: calc(56px - ${props => props.theme.spaces.l});
  overflow-y: auto;
  scroll-behavior: smooth;
`
