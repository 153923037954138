import { ReactNode } from 'react'
import styled from 'styled-components'

import { SelectButton } from 'components/generic'

type Props = {
  children: ReactNode
  checked: boolean
  className?: string
  onClick: () => void
}

export const Option = ({ className, checked, children, onClick }: Props) => (
  <Container className={className} onClick={onClick}>
    <StyledSelectButton type='radio' checked={checked} onChange={() => null} />
    <Description>{children}</Description>
  </Container>
)

const Container = styled.label`
  display: flex;
  margin-bottom: ${props => props.theme.spaces.l};
  user-select: none;
  cursor: pointer;
`

const StyledSelectButton = styled(props => <SelectButton {...props} />)`
  margin-top: 2px;
  margin-right: ${props => props.theme.spaces.m};
`

const Description = styled.div`
  color: ${props => props.theme.colors.dark};
  font-size: 13px;
  line-height: 20px;
`
