import styled from 'styled-components'

import { CardTitle } from '../card'
import { FlexBox, IconWithTextItem } from 'components/generic'
import { getIsSubscribed, UpgradeSubscriptionButton } from 'features/premium'
import {
  CustomFieldsTagsIcon,
  DottedLineReportingIcon,
  HeadsetWithMicrophoneIcon,
  PaletteOutlineIcon,
  PdfFileIcon,
  ShareOutlineIcon,
} from 'components/icons'

import { useChartData } from 'tree/providers'
import { SpacingProps } from 'helpers'
import { spaces } from 'theme'

type Props = {
  className?: string
  noUpgradeButton?: boolean
  spacing?: SpacingProps
}

export const PremiumBenefits = ({ className, noUpgradeButton, spacing }: Props) => {
  const { subscription } = useChartData(noUpgradeButton) || {}
  const isSubscribed = getIsSubscribed(subscription)
  const showUpgradeButton = !noUpgradeButton && !isSubscribed

  return (
    <FlexBox className={className} $spacing={spacing} $isDirectionColumn>
      <CardTitle>Take your HR operations to the next level with Org Chart PRO</CardTitle>
      <ItemList>
        <IconWithTextItem icon={<DottedLineReportingIcon />} text='Dotted Line Reporting' />
        <IconWithTextItem icon={<CustomFieldsTagsIcon />} text='Custom Fields' />
        <IconWithTextItem icon={<PaletteOutlineIcon width='28px' height='100%' />} text='Brand Customization' />
        <IconWithTextItem icon={<ShareOutlineIcon />} text='Advanced Sharing' />
        <IconWithTextItem icon={<PdfFileIcon />} text='PDF & PNG exports' />
        <IconWithTextItem icon={<HeadsetWithMicrophoneIcon />} text='Email & Chat Support' />
      </ItemList>
      {showUpgradeButton && (
        <FlexBox $spacing={{ mt: spaces.l }}>
          <UpgradeSubscriptionButton />
        </FlexBox>
      )}
    </FlexBox>
  )
}

const ItemList = styled.div`
  display: grid;
  grid-gap: ${props => props.theme.spaces.l};
  color: ${props => props.theme.colors.purpleLighter};

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`
