import { ConnectionFieldOption, CustomConnectionLineLabel, CustomConnectionLineStyle } from '../types'

export const CONNECTION_LINE_OPTIONS: readonly ConnectionFieldOption[] = [
  { lineStyle: 'dotted', lineLabel: 'Dotted line' },
  { lineStyle: 'dashed', lineLabel: 'Dashed line' },
  { lineStyle: 'solid', lineLabel: 'Solid line' },
]

export const CONNECTION_LINE_STYLE_TO_LABEL: Record<CustomConnectionLineStyle, CustomConnectionLineLabel> = {
  dotted: 'Dotted line',
  dashed: 'Dashed line',
  solid: 'Solid line',
}
