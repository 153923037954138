import { SVG, SVGProps } from './SVG'

export const ArrowDownIcon = (props: SVGProps) => (
  <SVG width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
    <path
      d='M9.16627 3.33337L10.8329 3.33337L10.8329 13.3334L15.4163 8.75004L16.5996 9.93337L9.99961 16.5334L3.39961 9.93337L4.58294 8.75004L9.16628 13.3334L9.16627 3.33337Z'
      fill='currentColor'
    />
  </SVG>
)
