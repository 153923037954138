import { SVG, SVGProps } from './SVG'

export const CustomFieldsTagsIcon = (props: SVGProps) => (
  <SVG width='24' height='22' viewBox='0 0 24 22' fill='none' {...props}>
    <path
      d='M16.1719 0.832809C15.7359 0.392184 15.0234 0.387497 14.5828 0.823434C14.1422 1.25937 14.1375 1.97187 14.5734 2.4125L20.5594 8.47343C22.1484 10.0812 22.1484 12.6641 20.5594 14.2719L15.3141 19.5828C14.8781 20.0234 14.8828 20.7359 15.3234 21.1719C15.7641 21.6078 16.4766 21.6031 16.9125 21.1625L22.1625 15.8562C24.6187 13.3719 24.6187 9.37812 22.1625 6.89375L16.1719 0.832809ZM11.3766 1.37656C10.8141 0.814059 10.05 0.499997 9.25312 0.499997H2.25C1.00781 0.499997 0 1.50781 0 2.75V9.75781C0 10.5547 0.314063 11.3187 0.876563 11.8812L8.75156 19.7562C9.92344 20.9281 11.8219 20.9281 12.9938 19.7562L19.2516 13.4984C20.4234 12.3266 20.4234 10.4281 19.2516 9.25625L11.3766 1.38125V1.37656ZM2.25 2.75H9.25781C9.45469 2.75 9.64688 2.82968 9.7875 2.97031L17.6625 10.8453C17.9531 11.1359 17.9531 11.6141 17.6625 11.9047L11.4047 18.1625C11.1141 18.4531 10.6359 18.4531 10.3453 18.1625L2.47031 10.2875C2.32969 10.1469 2.25 9.95468 2.25 9.75781V2.75ZM6.75 5.75C6.75 5.35217 6.59196 4.97064 6.31066 4.68934C6.02936 4.40803 5.64782 4.25 5.25 4.25C4.85218 4.25 4.47064 4.40803 4.18934 4.68934C3.90804 4.97064 3.75 5.35217 3.75 5.75C3.75 6.14782 3.90804 6.52935 4.18934 6.81066C4.47064 7.09196 4.85218 7.25 5.25 7.25C5.64782 7.25 6.02936 7.09196 6.31066 6.81066C6.59196 6.52935 6.75 6.14782 6.75 5.75Z'
      fill='currentColor'
    />
  </SVG>
)
