import { ReactNode, useMemo } from 'react'
import styled from 'styled-components'

import { QuotaExceeded, QuotaId, useQuotaFor } from 'features/premium'
import { useChartData } from 'tree/providers'

type Props = {
  children: ReactNode
  className?: string
  quota?: QuotaId
}

export const View = ({ className, children, quota }: Props) => {
  const chartData = useChartData()
  const { checkQuota } = useQuotaFor([chartData])
  const quotaResult = useMemo(() => (quota ? checkQuota(quota) : null), [quota, checkQuota])
  const showQuotaExceeded = chartData.subscription?.upgradable && quotaResult?.status === 'warning'

  return (
    <>
      {showQuotaExceeded && <StyledQuotaExceeded quotaResults={[quotaResult]} />}
      <ViewInner className={className}>{children}</ViewInner>
    </>
  )
}

const StyledQuotaExceeded = styled(QuotaExceeded)`
  width: auto;
  margin: ${props => props.theme.spaces.xl} ${props => props.theme.spaces.l};
  margin-bottom: 0;
`

const ViewInner = styled.div`
  padding: ${props => props.theme.spaces.xl} ${props => props.theme.spaces.l};
  background: ${props => props.theme.colors.neutralSecondary};
  opacity: 0.3;
  transform: translate3d(0, 4px, 0);
  animation: ${props => props.theme.keyframes.fadeUp} ${props => props.theme.transitions.fastEase} forwards;
`
