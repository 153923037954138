import styled, { css } from 'styled-components'

export const ExpandButtonContainer = styled.div<{ $compact: boolean }>`
  display: flex;
  align-items: center;
  z-index: 2;

  ${props => (props.$compact ? expandButtonCompactView : expandButtonStandardView)}
`
const expandButtonStandardView = css`
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
`
const expandButtonCompactView = css`
  margin-top: auto;
`
