import { ReactNode, MouseEvent, ButtonHTMLAttributes, MutableRefObject } from 'react'
import styled, { css } from 'styled-components'

import { applySpacingProps, SpacingProps } from 'helpers'
import { keyframes } from 'theme'

export type ButtonProps = {
  buttonType?: 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'danger' | 'warning'
  buttonSize?: 'medium' | 'small' | 'extraSmall'
  className?: string
  disabled?: boolean
  shakeAnimate?: boolean
  leftEl?: ReactNode
  rightEl?: ReactNode
  spacing?: SpacingProps
  ref?: MutableRefObject<HTMLButtonElement>
  onClick?: (e: MouseEvent<HTMLElement>) => void
  // data-testid
} & ButtonHTMLAttributes<HTMLButtonElement>

export const Button = ({
  children,
  leftEl,
  rightEl,
  buttonType = 'primary',
  buttonSize: size = 'medium',
  shakeAnimate,
  spacing,
  ...rest
}: ButtonProps) => (
  <ButtonInner type='button' $buttonType={buttonType} $size={size} $spacing={spacing} $animate={shakeAnimate} {...rest}>
    {leftEl}
    {children}
    {rightEl}
  </ButtonInner>
)

type ButtonPropsCSS = {
  $buttonType: ButtonProps['buttonType']
  $size: ButtonProps['buttonSize']
  $spacing?: ButtonProps['spacing']
  $animate?: ButtonProps['shakeAnimate']
} & ButtonHTMLAttributes<HTMLButtonElement>

// Types
const buttonPrimaryCSS = css<ButtonPropsCSS>`
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.purple};

  &:hover {
    background: ${props => props.theme.colors.purpleDark};
  }

  ${props =>
    props.disabled &&
    css`
      background: ${props => props.theme.colors.purpleVeryLight};
    `}
`
const buttonSecondaryCSS = css<ButtonPropsCSS>`
  border: 1px solid ${props => props.theme.colors.border};
  background: ${props => props.theme.colors.transparent};
  color: ${props => props.theme.colors.dark};

  &:hover {
    background: ${props => props.theme.colors.greyExtraLight};
  }

  ${props =>
    props.disabled &&
    css`
      border: 1px solid ${props => props.theme.colors.border};
      color: ${props => props.theme.colors.border};
    `}
`
const buttonTertiaryCSS = css<ButtonPropsCSS>`
  color: ${props => props.theme.colors.dark};

  &:hover {
    background: ${props => props.theme.colors.greyExtraLight};
  }

  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.colors.border};
    `}
`

const buttonQuaternaryCSS = css<ButtonPropsCSS>`
  color: ${props => props.theme.colors.purple};
  text-decoration: underline;
  text-decoration-color: ${props => props.theme.colors.greyExtraLight};
  text-underline-offset: 5px;

  &:hover {
    text-decoration-color: ${props => props.theme.colors.purple};
  }

  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.colors.border};
      text-decoration: none;
    `}
`

const buttonDangerCSS = css<ButtonPropsCSS>`
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.red};

  &:hover {
    opacity: 0.8;
  }

  ${props =>
    props.disabled &&
    css`
      background: ${props => props.theme.colors.border};
    `}
`

const buttonWarningCSS = css<ButtonPropsCSS>`
  color: ${props => props.theme.colors.dark};
  background: ${props => props.theme.colors.yellow};

  &:hover {
    background: ${props => props.theme.colors.darkYellow};
  }

  ${props =>
    props.disabled &&
    css`
      background: ${props => props.theme.colors.greyExtraLight};
      color: ${props => props.theme.colors.border};
    `}
`
const buttonTypesCSS = css<ButtonPropsCSS>`
  ${props => props.$buttonType === 'primary' && buttonPrimaryCSS}
  ${props => props.$buttonType === 'secondary' && buttonSecondaryCSS}
  ${props => props.$buttonType === 'tertiary' && buttonTertiaryCSS}
  ${props => props.$buttonType === 'quaternary' && buttonQuaternaryCSS}
  ${props => props.$buttonType === 'danger' && buttonDangerCSS}
  ${props => props.$buttonType === 'warning' && buttonWarningCSS}
`

// Sizes
const sizeMediumCSS = css`
  min-height: 40px;
  padding: ${props => props.theme.spaces.m} ${props => props.theme.spaces.l};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`
const sizeSmallCSS = css`
  min-height: 34px;
  padding: ${props => props.theme.spaces.m};
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
`
const sizeExtraSmallCSS = css`
  min-height: 24px;
  padding: ${props => props.theme.spaces.s};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`
const sizesCSS = css<ButtonPropsCSS>`
  ${props => props.$size === 'medium' && sizeMediumCSS}
  ${props => props.$size === 'small' && sizeSmallCSS}
  ${props => props.$size === 'extraSmall' && sizeExtraSmallCSS}
`

const buttonDisabledCSS = css<ButtonPropsCSS>`
  cursor: default;
  pointer-events: none;
`

const ButtonInner = styled.button<ButtonPropsCSS>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  outline: none;
  border: none;
  background: none;
  border-radius: 3px;
  text-transform: none;
  overflow: visible;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  transition: all ${props => props.theme.transitions.extraFastEase};
  -webkit-appearance: none;

  ${props => props.disabled && buttonDisabledCSS}

  ${({ $animate }) =>
    $animate &&
    css`
      animation: ${keyframes.shakeWithBounce} 2.75s linear 3;
    `}

  ${buttonTypesCSS}
  ${sizesCSS}
  ${props => applySpacingProps(props.$spacing)}
`
