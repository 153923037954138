import { useState, useMemo, useCallback } from 'react'

import { useInterval } from './use-interval'
import {
  DAY_IN_MS,
  getDateTimeValue,
  getNextTimeUnitStart,
  getTimeRemaining,
  HOUR_IN_MS,
  MINUTE_IN_MS,
  NO_TIME_REMAINING,
  SECOND_IN_MS,
  TimeRemaining,
} from 'helpers'
import { TimeUnit } from 'types'

type CountdownTimeUnit = Extract<TimeUnit, 'day' | 'hour' | 'minute' | 'second'>

type ErrorCode = 'INVALID-TARGET-DATE'

type Params = {
  updateInterval: CountdownTimeUnit
  toDate: Date | string | number
  onUpdate?: (timeRemaining: TimeRemaining) => void
  onError?: (error: ErrorCode) => void
}

type Result = {
  timeRemaining: TimeRemaining
  error: ErrorCode | undefined
  updateIntervalMs: number
  initialUpdateIntervalMs: number
}

const UPDATE_INTERVAL: Record<CountdownTimeUnit, number> = {
  second: SECOND_IN_MS,
  minute: MINUTE_IN_MS,
  hour: HOUR_IN_MS,
  day: DAY_IN_MS,
}

export const useTimeCountdown = ({ updateInterval, toDate, onUpdate, onError }: Params): Result => {
  const updateIntervalMs = useMemo(() => UPDATE_INTERVAL[updateInterval], [updateInterval])
  const initialUpdateIntervalMs = useMemo(() => getNextTimeUnitStart(updateInterval) || SECOND_IN_MS, [updateInterval])

  const [error, setError] = useState<ErrorCode>()
  const [timeRemaining, setTimeRemaining] = useState<TimeRemaining>(
    getTimeRemaining({ to: toDate }) || NO_TIME_REMAINING
  )

  const updateRemainingTime = useCallback(() => {
    const updatedTimeRemaining = getTimeRemaining({ to: toDate })
    if (updatedTimeRemaining) {
      onUpdate?.(updatedTimeRemaining)
      setTimeRemaining(updatedTimeRemaining)
    } else {
      const code = 'INVALID-TARGET-DATE'
      onError?.(code)
      setError(code)
    }
  }, [getDateTimeValue(toDate)])

  // Updates clock on next time unit and then every interval
  useInterval({ callback: updateRemainingTime, updateIntervalMs, initialDelayMs: initialUpdateIntervalMs })

  return {
    timeRemaining,
    error,
    updateIntervalMs,
    initialUpdateIntervalMs,
  }
}
