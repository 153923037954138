import { ReactNode } from 'react'
import styled from 'styled-components'

import { FlexBox } from './flex'

export type FlipPadSize = {
  flipPadHeight?: string
  flipPadWidth?: string
}

export type FlipClockProps = FlipPadSize & {
  background: string
  color: string
  number: ReactNode
  description?: ReactNode
}

const DEFAULT_FLIP_PAD_WIDTH = '45px'
const DEFAULT_FLIP_PAD_HEIGHT = '50px'

const getButtonWidthPx = (flipPadHeight: number): `${number}px` => `${flipPadHeight / 25}px`
const getButtonHeightPx = (flipPadHeight: number): `${number}px` => `${flipPadHeight / 5}px`

const getFlipNumberFontSize = (flipPadWidth: number): `${number}px` => `${flipPadWidth / 1.55}px`
const getDescriptionFontSize = (flipPadWidth: number): `${number}px` => `${flipPadWidth / 4.09}px`

export const FlipClock = ({
  background,
  color,
  number,
  description,
  flipPadHeight = DEFAULT_FLIP_PAD_HEIGHT,
  flipPadWidth = DEFAULT_FLIP_PAD_WIDTH,
}: FlipClockProps) => {
  const flipPadHeightNumber = parseFloat(flipPadHeight)
  const buttonWidth = getButtonWidthPx(flipPadHeightNumber)
  const buttonHeight = getButtonHeightPx(flipPadHeightNumber)

  const flipPadWidthNumber = parseFloat(flipPadWidth)
  const flipNumberFontSize = getFlipNumberFontSize(flipPadWidthNumber)
  const descriptionFontSize = getDescriptionFontSize(flipPadWidthNumber)

  return (
    <FlexBox $gap='s' $isDirectionColumn $isCentered>
      <FlexBox $isYCentered>
        <LeftButton $width={buttonWidth} $height={buttonHeight} />
        <FlipPad $background={background} $width={flipPadWidth} $height={flipPadHeight}>
          <FlipNumber $color={color} $fontSize={flipNumberFontSize}>
            {number}
          </FlipNumber>
          <Separator />
        </FlipPad>
        <RightButton $width={buttonWidth} $height={buttonHeight} />
      </FlexBox>

      {description && <Description $fontSize={descriptionFontSize}>{description}</Description>}
    </FlexBox>
  )
}

type ButtonProps = {
  $width: string
  $height: string
}

const Button = styled.span<ButtonProps>`
  width: ${props => props.$width};
  height: ${props => props.$height};
  background: ${props => props.theme.colors.greyLight};
`

const LeftButton = styled(Button)`
  border-radius: 2px 0px 0px 2px;
`

type FlipPadProps = {
  $width: string
  $height: string
  $background: string
}

const FlipPad = styled.div<FlipPadProps>`
  position: relative;
  min-width: ${props => props.$width};
  min-height: ${props => props.$height};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.$background};
  box-sizing: border-box;
  border-radius: 5px;
  padding: ${props => props.theme.spaces.s};
`

const FlipNumber = styled.span<{ $color: string; $fontSize: string }>`
  text-align: center;
  color: ${props => props.$color};
  font-size: ${props => props.$fontSize};
  font-weight: 500;
  line-height: 24px;
`

const Separator = styled.span`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
`

const RightButton = styled(Button)`
  border-radius: 0px 2px 2px 0px;
`

const Description = styled.span<{ $fontSize: string }>`
  color: ${props => props.theme.colors.greyMedium};
  font-size: ${props => props.$fontSize};
  font-weight: 500;
  line-height: 13px;
`
