import { useState } from 'react'
import styled from 'styled-components'
import { Link, generatePath } from 'react-router-dom'

import { ChartCardColumn } from './components'
import { chartCardCSS, chartCardHeadingCSS, chartCardContentCSS } from './chart-card-css'
import { Tooltip, IconButton, FlexBox } from 'components/generic'
import {
  SettingsIcon,
  ShareIcon,
  GsuiteIcon,
  CustomFieldsIcon,
  DuplicateChartIcon,
  DownloadIcon,
  SyncErrorBadgeIcon,
} from 'components/icons'

import { ChartCardCountdownOverlay, getChartLockDate } from 'features/premium'
import { useIsOnPublicRoute } from 'tree/hooks'
import { useResyncChartAction } from 'tree/actions'
import {
  CHART_EDIT_PATH,
  SETTINGS_CUSTOM_FIELDS_PATH,
  SETTINGS_DOWNLOAD_PATH,
  SETTINGS_GENERAL_PATH,
  SETTINGS_MEMBERS_PATH,
} from 'routes'
import {
  getCanAccessSharing,
  getCanShowCustomFieldsView,
  getCanShowExportView,
  useGetCanAccessSettings,
} from 'helpers/viewCapabilities'
import { spaces } from 'theme'

import {
  ChartFragmentFragment,
  PersonDataFragmentFragment,
  DepartmentDataFragmentFragment,
} from 'apollo/generated/graphql'

export const ChartCard = (props: ChartFragmentFragment) => {
  const {
    capabilities,
    departmentCount,
    emoji,
    employeeCount,
    lock,
    logo,
    name,
    providerInfo,
    topNodes,
    uuid: chartUuid,
  } = props

  const [isGsuiteTooltipShown, setIsGsuiteTooltipShown] = useState(false)
  const [isCustomFieldsTooltipShown, setIsCustomFieldsTooltipShown] = useState(false)
  const [isShareTooltipShown, setIsShareTooltipShown] = useState(false)
  const [isExportTooltipShown, setIsExportTooltipShown] = useState(false)
  const [isDuplicateChartTooltipShown, setIsDuplicateChartTooltipShown] = useState(false)
  const [isSettingsTooltipShown, setIsSettingsTooltipShown] = useState(false)

  const nodes = topNodes?.items || []
  const employees = nodes.filter(node => node.__typename === 'Person') as PersonDataFragmentFragment[]
  const departments = nodes.filter(node => node.__typename === 'Department') as DepartmentDataFragmentFragment[]

  const { getCanAccessSettings } = useGetCanAccessSettings()
  const { resyncChartWithConfirm } = useResyncChartAction()
  const providedByGW = providerInfo?.name === 'gsuite'
  const canProviderReadUser = providerInfo?.capabilities?.canReadUser === true
  const canUpdateChart = capabilities?.canUpdate === true
  const canResyncChart = !canProviderReadUser && canUpdateChart

  const isOnPublicRoute = useIsOnPublicRoute()
  const { chartLockDate } = getChartLockDate(lock)

  return (
    <ChartCardWrap>
      <StyledLink to={generatePath(CHART_EDIT_PATH, { chartUuid })} />
      <HeadingContainer>
        {logo && <CompanyLogo src={logo} alt='Company logo' />}
        {providedByGW && (
          <IconButton
            spacing={{ mr: spaces.s }}
            isClickable={canResyncChart}
            onMouseEnter={() => setIsGsuiteTooltipShown(true)}
            onMouseLeave={() => setIsGsuiteTooltipShown(false)}
            onClick={canResyncChart ? () => resyncChartWithConfirm({ chartUuid }) : undefined}
            data-testid='button-google-workspace'
          >
            <StyledTooltip isTooltipShown={isGsuiteTooltipShown}>
              {canProviderReadUser ? (
                <>
                  <NoWrap>Synced with</NoWrap> <NoWrap>Google Workspace</NoWrap>
                </>
              ) : (
                <NoWrap>Out of sync</NoWrap>
              )}
            </StyledTooltip>
            <FlexBox>
              {!canProviderReadUser && <StyledSyncErrorBadgeIcon />}
              <GsuiteIcon />
            </FlexBox>
          </IconButton>
        )}
        <Emoji>{emoji}</Emoji>
        <ChartName>{name}</ChartName>
        <IconsContainer>
          {capabilities && getCanShowCustomFieldsView(capabilities, isOnPublicRoute) && (
            <Link
              to={{
                pathname: generatePath(SETTINGS_CUSTOM_FIELDS_PATH, { chartUuid }),
                state: { from: window.location.pathname },
              }}
            >
              <IconButton
                onMouseEnter={() => setIsCustomFieldsTooltipShown(true)}
                onMouseLeave={() => setIsCustomFieldsTooltipShown(false)}
                data-testid='button-top-navigation-chart-custom-fields'
              >
                <StyledTooltip isTooltipShown={isCustomFieldsTooltipShown}>Custom fields</StyledTooltip>
                <StyledCustomFieldsIcon />
              </IconButton>
            </Link>
          )}

          {capabilities && getCanAccessSharing(capabilities) && (
            <Link
              to={{
                pathname: generatePath(SETTINGS_MEMBERS_PATH, { chartUuid }),
                state: { from: window.location.pathname },
              }}
              onClick={e => e.stopPropagation()}
            >
              <IconButton
                onMouseEnter={() => setIsShareTooltipShown(true)}
                onMouseLeave={() => setIsShareTooltipShown(false)}
                data-testid='button-top-navigation-chart-sharing'
              >
                <StyledTooltip isTooltipShown={isShareTooltipShown}>Share</StyledTooltip>
                <StyledShareIcon />
              </IconButton>
            </Link>
          )}

          {capabilities && getCanShowExportView(capabilities) && (
            <Link
              to={{
                pathname: generatePath(SETTINGS_DOWNLOAD_PATH, { chartUuid }),
                state: { from: window.location.pathname },
              }}
            >
              <IconButton
                size='big'
                onMouseEnter={() => setIsExportTooltipShown(true)}
                onMouseLeave={() => setIsExportTooltipShown(false)}
                data-testid='button-top-navigation-chart-export'
              >
                <DownloadIcon />
                <Tooltip isTooltipShown={isExportTooltipShown}>Export Org Chart</Tooltip>
              </IconButton>
            </Link>
          )}

          {capabilities && capabilities.canClone && (
            <Link
              to={{
                pathname: `${generatePath(SETTINGS_GENERAL_PATH, { chartUuid })}`,
                state: { from: window.location.pathname, highlightEffect: true },
              }}
            >
              <IconButton
                size='big'
                onMouseEnter={() => setIsDuplicateChartTooltipShown(true)}
                onMouseLeave={() => setIsDuplicateChartTooltipShown(false)}
                data-testid='button-top-navigation-chart-duplicate'
              >
                <StyledDuplicateChartIcon />
                <Tooltip isTooltipShown={isDuplicateChartTooltipShown}>Duplicate chart</Tooltip>
              </IconButton>
            </Link>
          )}

          {capabilities && getCanAccessSettings(capabilities) && (
            <Link
              to={{
                pathname: generatePath(SETTINGS_GENERAL_PATH, { chartUuid }),
                state: { from: window.location.pathname },
              }}
              onClick={e => e.stopPropagation()}
            >
              <IconButton
                onMouseEnter={() => setIsSettingsTooltipShown(true)}
                onMouseLeave={() => setIsSettingsTooltipShown(false)}
                data-testid='button-top-navigation-chart-settings'
              >
                <StyledTooltip isTooltipShown={isSettingsTooltipShown}>Settings</StyledTooltip>
                <SettingsIcon />
              </IconButton>
            </Link>
          )}
        </IconsContainer>
      </HeadingContainer>
      <ContentContainer>
        <ChartCardColumn items={employees} itemType='employee' listedItemsCount={employeeCount} />
        <ChartCardColumn items={departments} itemType='department' listedItemsCount={departmentCount} />
      </ContentContainer>
      {chartLockDate && <ChartCardCountdownOverlay chartLockDate={chartLockDate} />}
    </ChartCardWrap>
  )
}

const ChartCardWrap = styled.div`
  ${chartCardCSS}
`

const StyledLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  z-index: 2;
`

const HeadingContainer = styled.div`
  ${chartCardHeadingCSS}
`

const StyledSyncErrorBadgeIcon = styled(SyncErrorBadgeIcon)`
  transform: translate(50%, 50%);
  position: absolute;
  bottom: 0;
  right: 0;
`

const CompanyLogo = styled.img`
  max-width: 72px;
  max-height: 42px;
  margin-right: 8px;
`

const Emoji = styled.div`
  margin-right: 5px;
`

const ChartName = styled.div`
  min-width: 0;
  overflow-wrap: break-word;
  line-height: 20px;
`

const IconsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

const StyledShareIcon = styled(ShareIcon)`
  width: 18px;
  height: auto;
`

const ContentContainer = styled.div`
  ${chartCardContentCSS}
`

const StyledTooltip = styled(Tooltip)`
  bottom: -4px;
  min-width: 58px;
  width: auto;
  max-width: unset;
  height: auto;
  padding: 4px 6px;
  margin-left: 0;
  font-size: 13px;
  line-height: 20px;
`

const NoWrap = styled.div`
  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    white-space: nowrap;
  }
`

const StyledCustomFieldsIcon = styled(CustomFieldsIcon)`
  height: 22px;
`

const StyledDuplicateChartIcon = styled(DuplicateChartIcon)`
  transform: scale(0.85);
`
