import { InsightDataset } from 'apollo/generated/graphql'
import { DepFilter } from '../../types'

export const getKpiTrend = (value: number, previousValue?: number) => {
  if (!previousValue || isNaN(value) || isNaN(previousValue)) return 0
  return Math.round((value / previousValue - 1) * 100)
}

export const datasetToKpi = (activeDep: DepFilter, ds?: InsightDataset) => {
  if (ds?.__typename !== 'InsightDatasetSingle') return undefined

  const value = ds.values.find((rec: any) => rec.f.dep === activeDep.value)?.v
  return typeof value === 'number' ? value : undefined
}
