import { ErrorMessage } from 'formik'

import { FieldContainer, FieldErrorMessage, FieldTitle } from './components'
import { FlexBox } from '../flex'
import { EmojiPicker, EmojiPickerProps } from '../../specific/emoji-picker'

type Props = EmojiPickerProps & {
  className?: string
  title?: string
  name?: string
}

export const FieldEmojiPicker = ({ className, title, name, ...restProps }: Props) => (
  <FieldContainer className={className}>
    <FlexBox $isDirectionColumn $isFullWidth>
      {title && <FieldTitle title={title} size='big' />}
      <EmojiPicker {...restProps} />
      {name && (
        <ErrorMessage name={name}>{message => (message ? <FieldErrorMessage message={message} /> : null)}</ErrorMessage>
      )}
    </FlexBox>
  </FieldContainer>
)
