import { useCallback } from 'react'

import { useExpandNodesOnCreate } from './use-expand-nodes-on-create'
import { useUpdateNodeQuery } from './use-update-node-query'
import { useRefetchPersonTeamSize, useUpdateCount } from 'tree/hooks'
import { useChartId } from 'tree/providers'
import { PersonFormSubmitValues } from 'tree/types'
import { findSuperiorPerson } from 'helpers'

import { useCreatePersonMutation } from 'apollo/generated/graphql'

export const useCreatePersonRequest = () => {
  const chartUuid = useChartId()
  const [createPersonMutation] = useCreatePersonMutation()
  const { updateNodeQuery } = useUpdateNodeQuery()
  const { expandNodesOnCreate } = useExpandNodesOnCreate()
  const { updateCount } = useUpdateCount()
  const { isGsuiteProvider, refetchPersonTeamSize } = useRefetchPersonTeamSize()

  const createPersonRequest = useCallback(
    (values: PersonFormSubmitValues) => {
      return createPersonMutation({
        update: async (cache, { data }) => {
          const createdPerson = data?.createPerson
          if (!createdPerson) return

          updateNodeQuery({ cache, createdNode: createdPerson, chartUuid })
          updateCount({ cache, mutationData: createdPerson, action: 'increase' })
          const parentUuidList = [null, ...createdPerson.parentNodes.map(({ uuid }) => uuid)]
          await expandNodesOnCreate(parentUuidList)

          // Refetch teamSize
          if (isGsuiteProvider) {
            const superiorPerson = findSuperiorPerson(createdPerson)
            refetchPersonTeamSize(superiorPerson?.id)
          }
        },
        variables: { data: { ...values, parentUuid: values.parentUuid }, chartUuid },
      })
    },
    [chartUuid, createPersonMutation, updateNodeQuery, updateCount, expandNodesOnCreate, refetchPersonTeamSize]
  )

  return { createPersonRequest }
}
