import styled, { css } from 'styled-components'

import { LineDivider } from 'components/generic/styled'
import { ProfileInCircleIcon, DepartmentIcon } from 'components/icons'
import { colors, spaces } from 'theme'

import { ChartFragmentFragment } from 'apollo/generated/graphql'

type ChartItemProps = {
  chart: ChartFragmentFragment
  isActive: boolean
  onClick: () => void
}

export const ChartItem = ({ chart, isActive, onClick }: ChartItemProps) => {
  const { emoji, name, employeeCount, departmentCount, logo } = chart

  return (
    <ChartItemInner onClick={onClick} isActive={isActive}>
      {logo && (
        <CompanyLogoContainer>
          <CompanyLogo src={logo} alt='Company logo' />
        </CompanyLogoContainer>
      )}
      {emoji && <Emoji>{emoji}</Emoji>}
      {name}
      <ChartInfo>
        <StyledProfileInCircleIcon />
        {employeeCount}
        {departmentCount > 0 && (
          <>
            <StyledLineDivider $height='10px' $horizontalSpacing={spaces.s} color={colors.dark} />
            <DepartmentIcon width='12px' $spacing={{ mr: spaces.xs }} />
            {departmentCount}
          </>
        )}
      </ChartInfo>
    </ChartItemInner>
  )
}

const ChartItemInner = styled.div<{ isActive: boolean }>`
  flex-shrink: 0;
  min-height: 34px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.s} ${props => props.theme.spaces.m};
  border-radius: 3px;
  color: ${props => props.theme.colors.grey};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  cursor: pointer;
  transition: all ${props => props.theme.transitions.extraFastEase};

  &:hover {
    background: ${props => props.theme.colors.greyExtraLight};
  }

  ${props =>
    props.isActive &&
    css`
      color: ${props => props.theme.colors.greyExtraLight};
      background: ${props => props.theme.colors.purple};
      cursor: default;
      pointer-events: none;

      ${ChartInfo} {
        color: ${props => props.theme.colors.white};
      }

      ${StyledLineDivider} {
        background: ${props => props.theme.colors.white};
      }
    `}

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    height: 48px;
  }
`

const CompanyLogoContainer = styled.div`
  max-width: 46px;
  max-height: 24px;
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spaces.xs};
  border-radius: 3px;
  margin-right: ${props => props.theme.spaces.m};
  background: ${props => props.theme.colors.white};
`
const CompanyLogo = styled.img`
  max-width: inherit;
  max-height: inherit;
`

const Emoji = styled.div`
  min-width: 22px;
  display: flex;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    align-self: center;
  }
`

const ChartInfo = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${props => props.theme.spaces.m};
  margin-left: auto;
  color: ${props => props.theme.colors.dark};
  font-weight: 500;
  font-size: 12px;
`

const StyledProfileInCircleIcon = styled(ProfileInCircleIcon)`
  width: 12px;
  margin-right: ${props => props.theme.spaces.xs};
`

const StyledLineDivider = styled(LineDivider)`
  opacity: 0.4;
`
