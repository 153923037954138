import { ReactNode } from 'react'
import styled from 'styled-components'

type ButtonWithIconProps = {
  children: ReactNode
  icon: JSX.Element
  className?: string
  onClick: () => void
  // data-testid
}

export const ButtonWithIcon = ({ icon, children, ...rest }: ButtonWithIconProps) => (
  <Button {...rest}>
    <LogoWrap>{icon}</LogoWrap>
    <Content>{children}</Content>
  </Button>
)

const Button = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
`

const LogoWrap = styled.div`
  min-width: 38px;
  min-height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1px;
  background: ${props => props.theme.colors.white};
  border-radius: 4px;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.white};
`
