import styled from 'styled-components'

import { TREE_COMPACT_SIZES, TREE_STANDARD_SIZES } from '../../helpers'

type Props = {
  compact: boolean
}

export const SubTreeError = ({ compact }: Props) => <SubTreeErrorInner $compact={compact}>!</SubTreeErrorInner>

const SubTreeErrorInner = styled.div<{ $compact: boolean }>`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${props => (props.$compact ? TREE_COMPACT_SIZES : TREE_STANDARD_SIZES).vertical};
  background: ${props => props.theme.colors.red};
  border-radius: 50%;
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  z-index: 10;
`
