import styled, { css } from 'styled-components'
import { colors } from 'theme'

type TagSize = 'normal' | 'large'
type Props = {
  text: string
  size?: TagSize
  className?: string
  animate?: boolean
  onClick?: () => void
}

export const Tag = ({ text, size, className, animate, onClick }: Props) => (
  <Container size={size} className={className} animate={animate} onClick={onClick}>
    {text}
  </Container>
)

const Container = styled.div<{ size?: TagSize; animate?: boolean }>`
  height: 14px;
  line-height: 14px;
  border-radius: 20px;
  padding: 0 6px;
  font-size: 10px;
  font-weight: 500;
  background: ${colors.yellow};
  max-width: calc(102px - 12px);

  ${({ size }) =>
    size === 'large' &&
    css`
      height: 22px;
      line-height: 22px;
      font-size: 12px;
      padding: 0 8px;
      max-width: calc(124px - 16px);
    `}

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    `}

    ${props =>
    props.animate &&
    css`
      animation: ${props => props.theme.keyframes.shakeWithBounce} 2.75s linear 3;
    `}
`
