import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

import { Logo } from 'components/specific/top-navigation/components' // because of cyclic import
import { Button, ButtonProps } from 'components/generic'

import { CHARTS_PATH } from 'routes'
import { TopNavigation } from 'components/specific'

type Props = {
  title: ReactNode
  description: ReactNode
  subDescription?: ReactNode
  descMaxWidth?: string
  button?: ButtonProps
  icon?: ReactNode
  minimalisticTopNavigation?: boolean
  withoutButton?: boolean
}

export const Screen = ({
  title,
  description,
  subDescription,
  descMaxWidth,
  icon,
  button = { children: 'Go back to Org Chart' },
  minimalisticTopNavigation,
  withoutButton,
}: Props) => (
  <Container>
    {minimalisticTopNavigation ? (
      <TopContainer>
        <Logo />
      </TopContainer>
    ) : (
      <TopNavigation />
    )}
    <Content>
      {icon && <IconWrap>{icon}</IconWrap>}
      <Title>{title}</Title>
      <Description $descMaxWidth={descMaxWidth}>{description}</Description>
      <SubDescription>{subDescription}</SubDescription>
      {button && !withoutButton && (
        <NavLink to={CHARTS_PATH}>
          <Button {...button} />
        </NavLink>
      )}
    </Content>
  </Container>
)

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.m};
  background: ${props => props.theme.colors.neutralSecondary};
  color: ${props => props.theme.colors.dark};
  cursor: default;
  overflow: auto;
`

const TopContainer = styled.div`
  margin-bottom: ${props => props.theme.spaces.l};
`

const Content = styled.div`
  flex: 1;
  align-self: center;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${props => props.theme.spaces.xl};
  text-align: center;
`

const IconWrap = styled.div`
  max-width: 80%;
  display: flex;
`

const Title = styled.div`
  font-weight: bold;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: 0.25px;
  margin-top: ${props => props.theme.spaces.xl};
`

const Description = styled.div<{ $descMaxWidth?: string }>`
  margin-top: ${props => props.theme.spaces.xl};
  margin-bottom: ${props => props.theme.spaces.xl};
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  ${props =>
    props.$descMaxWidth &&
    css`
      max-width: ${props.$descMaxWidth};
    `}

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    font-size: 34px;
    line-height: 42px;
  }
`

const SubDescription = styled(Description)`
  font-weight: normal;
  font-size: 16px;
  margin-top: -${props => props.theme.spaces.l};
`
