import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { CheckmarkIcon } from 'components/icons'

type Props = {
  title: string | ReactNode
  subtitle: string | ReactNode
  isActive?: boolean
  isHidden?: boolean
  children?: ReactNode
  onClick: () => void
}

export const CollapsePanel = ({ title, subtitle, children, isActive, isHidden, onClick }: Props) => (
  <>
    <Container $isActive={isActive} $isHidden={isHidden} onClick={onClick}>
      <Title>
        {title}
        {isActive && (
          <CircleContainer>
            <Circle>
              <CheckmarkIcon />
            </Circle>
          </CircleContainer>
        )}
      </Title>
      <Subtitle>{subtitle}</Subtitle>
    </Container>

    {isActive && children && <StyledCard>{children}</StyledCard>}
  </>
)

const Card = styled.div`
  padding: 20px;
  border: 1px solid ${props => props.theme.colors.greyExtraLight};
  border-radius: 6px;
`

const Container = styled(Card)<{ $isActive?: boolean; $isHidden?: boolean }>`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-height: 94px;
  background: ${props => props.theme.colors.white};
  line-height: 22px;
  cursor: pointer;
  user-select: none;
  transition: ${props => props.theme.transitions.extraFastEase};

  ${props =>
    props.$isActive &&
    css`
      border: 1px solid ${props => props.theme.colors.purpleLighter};
      background: rgba(184, 153, 216, 0.05);
      cursor: initial;
    `}

  &:hover {
    border: 1px solid ${props => props.theme.colors.purpleLighter};
  }

  ${props => props.$isHidden && 'display: none;'};
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  color: ${props => props.theme.colors.greyMedium};
`

const Subtitle = styled(Title)`
  margin-bottom: 0;
  font-size: 12px;
  color: ${props => props.theme.colors.greyMedium};
`

const CircleContainer = styled.div`
  @media (max-width: 350px) {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const Circle = styled.div`
  min-width: 18px;
  min-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${props => props.theme.spaces.xs};
  border-radius: 50%;
  background: ${props => props.theme.colors.purple};
  color: ${props => props.theme.colors.white};
  animation: ${props => props.theme.keyframes.breatheIn} ${props => props.theme.transitions.mediumEase};
`

const StyledCard = styled(Card)`
  animation: ${props => props.theme.keyframes.fadeUp} ${props => props.theme.transitions.mediumEase};
`
