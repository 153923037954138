import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { KpiValue } from '../../types'
import { getKpiTrend } from '../../models'
import { getFormatedValue } from './Kpi'

import { useCompanyOverview, useInsightsState } from '../../hooks'
import { monthNames } from '../../constants'
import { TrendValue } from '../TrendValue'
import { parseLabel } from '../../utils'

type ValueType = 'time' | 'percent' | 'money' // time in minutes, money as dollars
type Props = {
  value?: KpiValue
  previousValue?: KpiValue
  valType?: ValueType
  title?: string
  icon?: ReactNode
}

export const TrendKpi = ({ value = 0, previousValue = 0, valType, title, icon }: Props) => {
  const { actualMonthIndex } = useInsightsState()
  const { activeDep } = useCompanyOverview()

  if (typeof value !== 'number') {
    return (
      <Container isEmpty>
        <Info>{`No data available for "${parseLabel(activeDep.label).toString()}" in ${
          monthNames[actualMonthIndex]
        }`}</Info>
      </Container>
    )
  }

  return (
    <Container>
      <Row>
        <Icon>{icon}</Icon>
        <Title>{title}</Title>
      </Row>
      <Row>
        <Value>{getFormatedValue(value, valType)}</Value>
        <Col>
          {previousValue !== undefined ? (
            <>
              <TrendValue value={getKpiTrend(value, previousValue)} />
              <Description>in last calendar month</Description>
            </>
          ) : (
            <>
              <TrendValuePlaceHolder />
              <Description noData>No data from previous month</Description>
            </>
          )}
        </Col>
      </Row>
    </Container>
  )
}

const TrendValuePlaceHolder = styled.div`
  height: 20px;
`

const Container = styled.div<{ isEmpty?: boolean }>`
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  flex: 0 0 33.333333%;
  flex-shrink: 1;
  position: relative;
  height: 81px;
  box-shadow: 0px 0px 6px rgba(38, 15, 63, 0.08);
  border-radius: 6px;
  overflow: hidden;
  background: ${props => props.theme.colors.white};
  text-align: center;

  ${props =>
    props.isEmpty &&
    css`
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      color: #83869c;
    `}

  @media (max-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    flex: 1 1 100%;
  }
`

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Icon = styled.div<{ isBlocked?: boolean }>`
  color: #4d009d;
  display: flex;
  margin-right: 12px;

  ${props =>
    props.isBlocked &&
    css`
      color: #b2b3c1;
      transform: scale(1.5);
      margin-top: 40px;
    `};
`

const Value = styled.div`
  font-weight: 500;
  letter-spacing: 0.25px;
  font-size: 34px;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  z-index: 2;
`

const Description = styled.div<{ noData?: boolean }>`
  font-size: 16px;
  max-width: 350px;
  line-height: 24px;
  font-size: 12px;
  align-self: flex-end;

  ${props =>
    props.noData &&
    css`
      color: #83869c;
    `};
`

const Info = styled.div`
  font-size: 15px;
`
