import { RefObject, createContext, useContext, ReactNode } from 'react'

type Props = {
  panelRef: RefObject<HTMLDivElement>
  children: ReactNode
}
const ChartSidePanelRefContext = createContext<RefObject<HTMLDivElement> | null>(null)

export const ChartSidePanelRefProvider = ({ panelRef, children }: Props) => (
  <ChartSidePanelRefContext.Provider value={panelRef}>{children}</ChartSidePanelRefContext.Provider>
)

export const useChartSidePanelRef = () => useContext(ChartSidePanelRefContext)
