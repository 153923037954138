import styled from 'styled-components'
import { applySpacingProps, SpacingProps } from 'helpers'

export const ListItemContainer = styled.li<{ $spacing?: SpacingProps }>`
  display: flex;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.dark};

  ${props => applySpacingProps(props.$spacing)}
`
