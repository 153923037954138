import { generatePath, useHistory } from 'react-router-dom'

import { useCloneChartReqeust } from './use-clone-chart-request'
import { ChartDetail, WithConfirmModal } from '../../types'
import { ConfirmationModalType } from 'components/specific'
import { useModal } from 'modal'
import analytics from 'analytics'
import { ORG_TRACK } from 'analytics/constants'
import { CHARTS_PATH, CHART_EDIT_PATH } from 'routes'
import { handleErrorValidation } from 'helpers'

type CloneChartActionParams = {
  chartId: ChartDetail['id']
  chartName: ChartDetail['name']
  providerInfo: ChartDetail['providerInfo']
}

export const useCloneChartAction = () => {
  const history = useHistory()
  const { open } = useModal()
  const { cloneChartRequest } = useCloneChartReqeust()

  const cloneChart = async (params: CloneChartActionParams) => {
    const { chartId, chartName } = params
    try {
      const res = await cloneChartRequest({ chartId })
      analytics.track(ORG_TRACK.cloneChart, { uuid: chartId, name: chartName })
      const clonedChartId = res.data?.cloneChart?.id
      const path = clonedChartId ? generatePath(CHART_EDIT_PATH, { chartUuid: clonedChartId }) : CHARTS_PATH
      history.push(path)
      return Promise.resolve(res)
    } catch (error) {
      handleErrorValidation({
        track: { message: ORG_TRACK.cloneChartFailure, values: { chartUuid: chartId, error } },
        toast: { message: 'Failed to clone Org Chart' },
      })
    }
  }

  const cloneChartWithConfirm = async ({ onAbort, ...params }: CloneChartActionParams & WithConfirmModal) => {
    const providedByGW = params.providerInfo?.name === 'gsuite'
    const GWNote = providedByGW ? ' Please note that your new Org Chart will not be synced with Google Workspace.' : ''

    return new Promise((resolve, reject) => {
      let hasConfirmed = false
      open<ConfirmationModalType>('confirmationModal', {
        title: { text: 'Duplicate Org Chart' },
        message: `You are about to make a copy of this Org Chart.${GWNote}`,
        confirmButton: { buttonType: 'primary', children: 'Duplicate' },
        onConfirm: () => {
          hasConfirmed = true
          cloneChart(params).catch(reject).then(resolve)
        },
        onClose: () => {
          if (!hasConfirmed) onAbort?.()
        },
      })
    })
  }

  return { cloneChart, cloneChartWithConfirm }
}
