import { gql } from '@apollo/client'
import { appFragment } from '../fragments'

export const getApp = gql`
  query app {
    app {
      ...AppFragment
    }
  }
  ${appFragment}
`
