import { DepartmentForm } from './form'
import { NodeEditContainer, NodeEditTitle, useHideIntercomOnEditEnter } from '../../node-components'

import { useChartId } from 'tree/providers'
import { DepartmentFormCapabilities, DepartmentFormValues, OnDepartmentFormSubmit } from 'tree/types'
import { getNodeColorPallete } from 'helpers'
import { Mutable } from 'types'

import { DepartmentDetailDataFragmentFragment } from 'apollo/generated/graphql'

type Props = {
  parentUuid: string | null
  onClose: () => void
  onSubmit: OnDepartmentFormSubmit
  createMode?: boolean
  department?: DepartmentDetailDataFragmentFragment
  onCancel?: () => void
}

export const DepartmentEdit = (props: Props) => {
  const { createMode, onClose, department, parentUuid, onCancel, onSubmit } = props
  const chartUuid = useChartId()
  const nodeColorPallete = getNodeColorPallete(department?.color)
  useHideIntercomOnEditEnter()

  const initialValues: Mutable<DepartmentFormValues> = {
    color: nodeColorPallete.value,
    name: department?.name || '',
    description: department?.description || '',
    moveToChart: false,
    parentUuid,
  }

  const capabilities: DepartmentFormCapabilities = {
    canUpdate: Boolean(department?.capabilities?.canUpdate || createMode),
    canMove: Boolean(department?.capabilities?.canMove || createMode),
  }

  return (
    <NodeEditContainer>
      <NodeEditTitle
        title={createMode ? 'Add new department' : `Editing: ${department?.name}`}
        onBack={createMode ? undefined : onCancel}
        onClose={onClose}
      />
      <DepartmentForm
        initialValues={initialValues}
        department={department}
        chartUuid={chartUuid}
        capabilities={capabilities}
        onClose={onClose}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </NodeEditContainer>
  )
}
