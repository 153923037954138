import { SVG, SVGProps } from './SVG'

export const GsuiteIcon = (props: SVGProps) => (
  <SVG width='18' height='18' viewBox='0 0 18 18' fill='none' {...props}>
    <path
      d='M17.9999 9.20694C17.9999 8.59517 17.9493 7.98009 17.8414 7.37823H9.18127V10.8439H14.1405C13.9347 11.9616 13.2735 12.9503 12.3053 13.5787V15.8273H15.2639C17.0013 14.2599 17.9999 11.945 17.9999 9.20694Z'
      fill='#4285F4'
    />
    <path
      d='M9.18135 18C11.6576 18 13.7459 17.203 15.2674 15.8274L12.3087 13.5787C11.4855 14.1276 10.4228 14.4385 9.18472 14.4385C6.78945 14.4385 4.75852 12.8545 4.02982 10.7249H0.976685V13.043C2.5353 16.082 5.70989 18 9.18135 18Z'
      fill='#34A853'
    />
    <path
      d='M4.02643 10.7248C3.64183 9.60711 3.64183 8.39679 4.02643 7.27907V4.96094H0.976665C-0.325555 7.50393 -0.325555 10.5 0.976665 13.043L4.02643 10.7248Z'
      fill='#FBBC04'
    />
    <path
      d='M9.18135 3.56212C10.4903 3.54228 11.7554 4.02509 12.7034 4.91134L15.3247 2.34188C13.6649 0.814101 11.4619 -0.0258486 9.18135 0.000606479C5.70989 0.000606479 2.5353 1.9186 0.976685 4.96094L4.02644 7.27907C4.75177 5.14612 6.78607 3.56212 9.18135 3.56212Z'
      fill='#EA4335'
    />
  </SVG>
)
