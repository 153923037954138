import { NodeDetailItem } from '../../../node-components'
import { GroupHeading } from '../../components'
import { LocationIcon } from 'components/icons'

import { useChartSidePanelDetail } from 'tree/providers'
import { LocationFields, PersonDetail } from 'tree/types'
import { getPriority } from 'consts'

type Props = {
  person: PersonDetail
}

export const LocationFieldsDetail = ({ person }: Props) => {
  const { costCenter, location, buildingId, floorName, floorSection, deskCode } = person
  const locationFields: LocationFields = { costCenter, location, buildingId, floorName, floorSection, deskCode }
  const showLocationFields = Object.values(locationFields).some(Boolean)

  if (!showLocationFields) {
    return null
  }

  return <LocationFieldsDetailInner locationFields={locationFields} />
}

type Field = {
  title: string
  value?: string | null
}

type PropsInner = {
  locationFields: LocationFields
}

const LocationFieldsDetailInner = ({ locationFields }: PropsInner) => {
  const { costCenter, location, buildingId, floorName, floorSection, deskCode } = locationFields
  const { isLocationsExpanded, setIsLocationsExpanded } = useChartSidePanelDetail()

  const locationFieldsObject: Record<keyof LocationFields, Field> = {
    costCenter: {
      title: 'Cost center',
      value: costCenter,
    },
    location: {
      title: 'Area',
      value: location,
    },
    buildingId: {
      title: 'Building Id',
      value: buildingId,
    },
    floorName: {
      title: 'Floor name',
      value: floorName,
    },
    floorSection: {
      title: 'Floor section',
      value: floorSection,
    },
    deskCode: {
      title: 'Desk code',
      value: deskCode,
    },
  }
  const locationFieldsArray = Object.entries(locationFieldsObject).sort(
    ([a], [b]) => getPriority(b as keyof LocationFields) - getPriority(a as keyof LocationFields)
  )

  return (
    <section>
      <GroupHeading
        icon={<LocationIcon width='18px' height='100%' />}
        title='Location'
        isExpanded={isLocationsExpanded}
        onClick={() => setIsLocationsExpanded(!isLocationsExpanded)}
      />

      {isLocationsExpanded &&
        locationFieldsArray.map(([key, { title, value }]) => {
          if (!value) return null
          return <NodeDetailItem key={key} title={title} value={value} />
        })}
    </section>
  )
}
