import { useApolloClient } from '@apollo/client'

import { useGetChartNodeViaEmail } from './use-get-chart-node-via-email'
import { useUpdateMeOnChart } from './use-update-me-on-chart'
import { useTree } from '../providers/use-tree'
import { useChartData } from '../providers/use-chart-data'

export const useZoomOnMe = () => {
  const { me } = useChartData()
  const { getChartNodeViaEmail } = useGetChartNodeViaEmail()
  const { zoomTo } = useTree()
  const { updateMeOnChart } = useUpdateMeOnChart()
  const { cache } = useApolloClient()

  const email = me?.email

  const zoomOnMe = email
    ? async () => {
        const node = await getChartNodeViaEmail(email)
        if (node?.email) {
          zoomTo(node)
          return Promise.resolve()
        }
        updateMeOnChart(null, cache)
        return Promise.reject(`Email "${email}" not found`)
      }
    : undefined

  return { zoomOnMe }
}
