import { useState, forwardRef, useRef } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import MomentLocaleUtils from 'react-day-picker/moment'

import { FieldInput, FieldInputProps, InputProps } from 'components/generic'
import './custom-date-picker-style.css'

import { CUSTOM_DATE_PICKER_FORMAT, isValidDate } from './custom-date-picker-helper'

export type CustomDatePickerInputProps = Pick<InputProps, 'value' | 'onChange' | 'onBlur'> & {
  inputName: string
  onClear: () => void
  onDayPick: (value: string) => void
}

export const CustomDatePicker = (props: CustomDatePickerInputProps) => {
  const { value, inputName, onChange, onBlur, onClear, onDayPick } = props
  const [selectedDays, setSelectedDays] = useState<Date | undefined>(isValidDate(value) ? new Date(value) : undefined)

  const { formatDate, parseDate } = MomentLocaleUtils

  const handleDayChange = (date: Date) => {
    setSelectedDays(date)
    date && onDayPick(formatDate(date, CUSTOM_DATE_PICKER_FORMAT))
  }

  const pickerRef = useRef<DayPickerInput | null>(null)
  const handleClear = () => {
    setSelectedDays(undefined)
    onClear()
    // Fixes bug of unsynced value between state and input value (otherwise the value is not cleared from the input even when the value from prop is empty string)
    // issue link: https://github.com/gpbl/react-day-picker/issues/804
    pickerRef.current?.setState({ value: '', typedValue: '' })
  }

  return (
    <DayPickerInput
      ref={pickerRef}
      value={value}
      format={CUSTOM_DATE_PICKER_FORMAT}
      placeholder='mm/dd/yyyy'
      dayPickerProps={{ localeUtils: MomentLocaleUtils, selectedDays }}
      component={FieldInputForwardRef}
      inputProps={{
        name: inputName,
        type: 'text',
        optimized: false,
        onClear: handleClear,
        onChange,
        onBlur,
      }}
      onDayChange={handleDayChange}
      formatDate={formatDate}
      parseDate={parseDate}
    />
  )
}

const FieldInputForwardRef = forwardRef<HTMLInputElement, FieldInputProps>((props, ref) => (
  <FieldInput {...props} innerRef={ref} />
))
FieldInputForwardRef.displayName = 'FieldInputForwardRef'
