import styled, { css, FlattenInterpolation, FlattenSimpleInterpolation, ThemeProps } from 'styled-components'

import { AlertBoxContainerProps, AlertBoxSize, AlertBoxType } from './alert-box.types'

export const AlertBoxContainer = ({ children, type, size, className }: AlertBoxContainerProps) => (
  <Container className={className} $type={type} $size={size}>
    {children}
  </Container>
)

type ContainerProps = {
  $type: AlertBoxType
  $size: AlertBoxSize
}

// Type
const typeErrorCSS = css`
  background: #fae2e4;
  border-color: ${props => props.theme.colors.red};
`
const typeWarningCSS = css`
  background: #fcf7e1;
  border-color: ${props => props.theme.colors.yellow};
`

// Size
const sizeSmallCSS = css`
  border-width: 1px;
  padding: 8px;
`
const sizeMediumCSS = css`
  border-width: 2px;
  border-radius: 8px;
  padding: 24px;
`

// Mappers
const TYPE_CSS: Record<AlertBoxContainerProps['type'], FlattenInterpolation<ThemeProps<unknown>>> = {
  error: typeErrorCSS,
  warning: typeWarningCSS,
}
const SIZE_CSS: Record<AlertBoxContainerProps['size'], FlattenSimpleInterpolation> = {
  small: sizeSmallCSS,
  medium: sizeMediumCSS,
}

const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${props => props.theme.spaces.m};
  box-sizing: border-box;
  text-align: center;
  border-style: solid;

  ${props => TYPE_CSS[props.$type]}
  ${props => SIZE_CSS[props.$size]}

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    flex-direction: row;
    gap: 0;
    text-align: initial;
  }
`
