import { Dispatch, SetStateAction } from 'react'
import { FormikHelpers } from 'formik'

import { CreateChartOptionType, Error, GsuiteObj, Scope } from '../types'
import { ChartTrackedData, extractChartData, getChartDataByOptionType, getChartTrackedData } from '../helpers'
import { ChartFormValues } from 'tree/types'
import {
  deleteItemFromLocalStorage,
  extractErrorCode,
  extractErrorMessages,
  extractExtensions,
  formErrorHandler,
  isValidNonEmptyString,
  normalizeString,
} from 'helpers'
import analytics from 'analytics'
import { ORG_TRACK } from 'analytics/constants'
import { useAuth } from 'auth'

import { getCharts, user as getUser } from 'apollo/query'
import {
  useCreateChartFromFileMutation,
  useCreateChartFromGoogleWorkspaceMutation,
  useCreateChartMutation,
} from 'apollo/generated/graphql'

const MUTATION_NAME_BY_OPTION = {
  empty: 'createChart',
  file: 'createChartFromFile',
  gsuite: 'createChartFromGoogleWorkspace',
} as const

type Params = {
  optionType?: CreateChartOptionType
  goToChart: (uuid: string) => void
  setLoading: Dispatch<SetStateAction<boolean>>
  setError: Dispatch<SetStateAction<Error>>
}

// @TODO: refactor
export const parseErrors = (error: any): SetStateAction<Error> => {
  const errorCode = extractErrorCode(error)
  if (errorCode === 'GSUITE_INSUFFICIENT_PERMISSIONS') {
    const scopes = extractExtensions<{ details: { scopes: Scope[] } }>(error)?.details?.scopes
    return { type: 'insufficient-permission', data: { scopes } }
  } else if (errorCode === 'IMPORT_CYCLE_DETECTED') {
    const path = extractExtensions(error)?.response ?? []
    return { type: 'import-cycle-detected', data: { path } }
  } else if (errorCode === 'BAD_USER_INPUT') {
    const errors = extractErrorMessages(error)
    const messages = errors.flatMap(
      ({ constraints, sourceLocation }) => `${sourceLocation?.path.join(' ')}: ${Object.values(constraints)}`
    )
    return { type: 'csv-validation-error', data: { messages } }
  }
  return { type: 'unknown-error' }
}

export const useCreateChartHandler = ({ optionType, goToChart, setLoading, setError }: Params) => {
  const { user } = useAuth()

  // Mutations
  const [createChartMutation] = useCreateChartMutation()
  const [createChartFromFileMutation] = useCreateChartFromFileMutation()
  const [createChartFromGoogleWorkspaceMutation] = useCreateChartFromGoogleWorkspaceMutation()

  const catchMutationOnError = (
    error: any,
    chartTrackedData: ChartTrackedData,
    gsuiteObj: GsuiteObj,
    actions?: FormikHelpers<ChartFormValues>
  ) => {
    setLoading(false)

    const errorCode = formErrorHandler(error, actions)
    analytics.track(ORG_TRACK.createChartFailure, {
      option: optionType,
      ...gsuiteObj,
      ...chartTrackedData,
      error,
      errorCode,
      errorMessage: error.message,
    })

    setError(parseErrors(error))
  }

  const callMutationByOptionType =
    (optionType: CreateChartOptionType, chartTrackedData: ChartTrackedData, actions?: FormikHelpers<ChartFormValues>) =>
    (data: ChartFormValues) => {
      const syncEmployeeInformation = Boolean(data.syncEmployeeInformation)

      // for analytics tracking
      const gsuiteObj: GsuiteObj =
        optionType === 'gsuite'
          ? {
              syncOption: syncEmployeeInformation ? 'twoWaySync' : 'editOnly',
              syncCalendar: Boolean(data.syncCalendar),
            }
          : {}

      const updateHandler = (result = {}) => {
        const {
          uuid,
          name,
          employeeCount,
          unassignedEmployeeCount,
          departmentCount,
          unassignedDepartmentCount,
          totalDepartmentCount,
          totalEmployeeCount,
          adminEmail,
        } = extractChartData(MUTATION_NAME_BY_OPTION[optionType], result)

        const extendedGsuiteObj =
          optionType === 'gsuite'
            ? {
                ...gsuiteObj,
                adminEmail,
              }
            : {}

        analytics.track(ORG_TRACK.createChartSuccess, {
          uuid,
          name,
          peopleCount: employeeCount, // deprecated but remains for easier comparison
          employeeCount,
          unassignedEmployeeCount,
          departmentCount,
          unassignedDepartmentCount,
          totalDepartmentCount,
          totalEmployeeCount,
          option: optionType,
          ...extendedGsuiteObj,
        })

        deleteItemFromLocalStorage('chartName')
        deleteItemFromLocalStorage('chartEmoji')

        const areEmailsValid = isValidNonEmptyString(user?.email) && isValidNonEmptyString(adminEmail)
        const areEmailsEqual =
          areEmailsValid && normalizeString(user?.email as string) === normalizeString(adminEmail as string)
        if (optionType === 'gsuite' && areEmailsValid && areEmailsEqual) {
          analytics.setUserProperties({ isGWorkspaceAdmin: true })
        }

        setLoading(false)
        goToChart(uuid)
      }

      switch (optionType) {
        case 'file':
          return createChartFromFileMutation({
            variables: { data: getChartDataByOptionType<'file'>('file', data) },
            refetchQueries: [{ query: getCharts }, { query: getUser }],
            update: (_, result = {}) => {
              updateHandler(result)
            },
          }).catch(error => catchMutationOnError(error, chartTrackedData, gsuiteObj, actions))
        case 'gsuite':
          return createChartFromGoogleWorkspaceMutation({
            variables: { data: getChartDataByOptionType('gsuite', data) },
            refetchQueries: [{ query: getCharts }, { query: getUser }],
            update: (_, result = {}) => {
              updateHandler(result)
            },
          }).catch(error => catchMutationOnError(error, chartTrackedData, gsuiteObj, actions))
        default:
          return createChartMutation({
            variables: { data: getChartDataByOptionType('empty', data) },
            refetchQueries: [{ query: getCharts }, { query: getUser }],
            update: (_, result = {}) => {
              updateHandler(result)
            },
          }).catch(error => catchMutationOnError(error, chartTrackedData, gsuiteObj, actions))
      }
    }

  const createChartHandler = async (
    optionType: CreateChartOptionType,
    data: ChartFormValues,
    actions?: FormikHelpers<ChartFormValues>
  ) => {
    setLoading(true)
    const chartTrackedData = getChartTrackedData(optionType, data)

    // for analytics tracking
    const gsuiteObj: GsuiteObj =
      optionType === 'gsuite'
        ? {
            syncOption: data.syncEmployeeInformation ? 'twoWaySync' : 'editOnly',
            syncCalendar: Boolean(data.syncCalendar),
          }
        : {}
    analytics.track(ORG_TRACK.createChart, {
      option: optionType,
      ...gsuiteObj,
      ...chartTrackedData,
    })

    callMutationByOptionType(optionType, chartTrackedData, actions)(data)
  }

  return createChartHandler
}
