import { useChartSidePanelRef } from './chart-side-panel-context'

export const useScrollToTopChartSidePanel = () => {
  const chartSidePanelRef = useChartSidePanelRef()

  const scrollToTop = () => {
    if (chartSidePanelRef?.current) {
      chartSidePanelRef.current.scrollTop = 0
    }
  }

  return { scrollToTop }
}
