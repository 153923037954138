import { ReactNode, useState } from 'react'

import { useChartData } from './use-chart-data'
import { Company } from '../types'
import { COMPANY_UUID } from 'consts'
import { createSafeContext } from 'helpers'

export const [useCompanyNodeState, CompanyNodeStateContextSafeProvider] = createSafeContext<Company>()

type Props = {
  children: ReactNode
}

export const CompanyNodeStateProvider = ({ children }: Props) => {
  const { uuid: chartUuid, employeeCount, departmentCount } = useChartData()
  const [expanded, setExpanded] = useState(true)

  return (
    <CompanyNodeStateContextSafeProvider
      value={{
        chartUuid,
        departmentCount,
        employeeCount,
        expanded,
        id: COMPANY_UUID,
        parentNodes: [],
        parentUuid: null,
        type: 'company',
        uuid: COMPANY_UUID,
        setExpanded,
        __typename: 'Company',
      }}
    >
      {children}
    </CompanyNodeStateContextSafeProvider>
  )
}
