import mixpanel from 'mixpanel-browser'
import { payloadMapperFactory } from 'helpers'

const MINUTE_IN_MILLISECONDS = 60000
const MAX_RETRIES = 10

const payloadMapper = payloadMapperFactory({ maxStrLength: 255, maxStrGroupCount: 5 })

export const init = (token: string, proxyUri: string) => {
  mixpanel.init(token, { api_host: proxyUri, batch_requests: false })
}

export const setUserProperties = (properties: Record<string, unknown>, maxRetries = MAX_RETRIES) => {
  try {
    mixpanel.people.set(properties)
  } catch (_) {
    if (maxRetries > 0) {
      setTimeout(() => setUserProperties(properties, maxRetries - 1), MINUTE_IN_MILLISECONDS)
    }
  }
}

export const identify = (name?: string, email?: string) => {
  if (email) {
    mixpanel.identify(email)
    mixpanel.people.set({
      $email: email,
    })
  }

  if (name) {
    mixpanel.people.set({
      $name: name,
    })
  }

  mixpanel.people.set_once('First Login Date', new Date())
}

export const track = (name: string, properties?: Record<string, unknown>, maxRetries = MAX_RETRIES) => {
  const payload = payloadMapper(properties)
  try {
    mixpanel.track(name, payload)
  } catch (_) {
    if (maxRetries > 0) {
      setTimeout(() => track(name, payload, maxRetries - 1), MINUTE_IN_MILLISECONDS)
    }
  }
}

export default {
  init,
  setUserProperties,
  identify,
  track,
}
