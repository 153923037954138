import { useState, ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { ErrorMessage } from 'formik'

import { FieldContainer, FieldTitle, FieldErrorMessage, FieldTitleProps } from './components'
import { Tooltip } from '../tooltip'
import { inputPlaceholderCSS } from '../input/input-CSS'
import { Input, InputProps, InputClear } from '../input'
import { GoogleIcon, LockIcon } from 'components/icons'

import { ChartSyncProvider } from 'tree/types'
import { mainTheme } from 'theme'

export type FieldInputProps = InputProps & {
  onClear: () => void
  className?: string
  icon?: ReactNode
  iconTooltipText?: string
  name?: string
  showClearOnEmptyValue?: boolean
  title?: FieldTitleProps
  required?: boolean
  syncProvider?: ChartSyncProvider
  // data-testid
}

export const FieldInput = ({
  disabled,
  value,
  onClear,
  className,
  icon,
  iconTooltipText,
  name,
  showClearOnEmptyValue,
  title,
  required,
  syncProvider,
  ...inputProps
}: FieldInputProps) => {
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [isIconTooltipShown, setIsIconTooltipShown] = useState(false)
  const [isGoogleTooltipShown, setIsGoogleTooltipShown] = useState(false)

  const showClear = isInputFocused && !disabled && (showClearOnEmptyValue || value.length > 0)
  const hasIconTooltip = (disabled || icon) && iconTooltipText

  return (
    <FieldContainer className={className}>
      {title && <FieldTitle {...title} />}

      <InputContainer>
        <InputWrap onMouseEnter={() => setIsInputFocused(true)} onMouseLeave={() => setIsInputFocused(false)}>
          <StyledInput
            name={name}
            value={value}
            disabled={disabled}
            required={required}
            $showClear={showClear}
            $hasIcon={Boolean(icon || disabled)}
            $hasProvider={Boolean(syncProvider)}
            {...inputProps}
          />

          <InputOverlay>
            {(disabled || icon || hasIconTooltip) && (
              <LeftIcons
                onMouseEnter={() => hasIconTooltip && setIsIconTooltipShown(true)}
                onMouseLeave={() => hasIconTooltip && setIsIconTooltipShown(false)}
              >
                {disabled ? <LockIcon /> : icon}
                {hasIconTooltip && <StyledTooltip isTooltipShown={isIconTooltipShown}>{iconTooltipText}</StyledTooltip>}
              </LeftIcons>
            )}
            <RightIcons>
              {showClear && <InputClear onClick={onClear} />}
              {syncProvider === 'google' && (
                <SyncProvider
                  onMouseEnter={() => setIsGoogleTooltipShown(true)}
                  onMouseLeave={() => setIsGoogleTooltipShown(false)}
                >
                  <GoogleIcon width='16px' $spacing={{ ml: 'auto', mr: mainTheme.spaces.m }} />
                  <StyledProviderTooltip isTooltipShown={isGoogleTooltipShown} $orientation='left'>
                    Synced with Google Workspace
                  </StyledProviderTooltip>
                </SyncProvider>
              )}
            </RightIcons>
          </InputOverlay>
        </InputWrap>

        {name && (
          <ErrorMessage name={name}>
            {message => (message ? <FieldErrorMessage message={message} /> : null)}
          </ErrorMessage>
        )}
      </InputContainer>
    </FieldContainer>
  )
}

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const InputWrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`

type StyledInputProps = InputProps & { $hasIcon: boolean; $showClear: boolean; $hasProvider: boolean }
const StyledInput = styled(Input)<StyledInputProps>`
  color: ${props => props.theme.colors.dark};

  ${props =>
    props.$hasIcon &&
    css`
      padding-left: 30px;
    `}

  ${props => {
    const { $showClear, $hasProvider } = props
    let padding = 0
    if ($hasProvider) padding += 16 + 8 // Google icon width + margin right
    if ($showClear) padding += 18 + 8 // Clear width + margin right
    if ($hasProvider || $showClear) padding += 4 // Space from input text
    if (padding) return `padding-right: ${padding}px;`
  }}

${props =>
    props.$showClear &&
    css`
      padding-right: 34px;
    `}

  &::placeholder,
  &::-webkit-input-placeholder {
    ${inputPlaceholderCSS}
  }
`

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  top: 100%;
  right: unset;
  bottom: unset;
  left: 0;
  min-width: unset;
  width: auto;
  max-width: 320px;
  transform: translate(0px, 0px);

  &:after {
    left: 14px;
    margin-left: 0;
  }
`

const InputOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  pointer-events: none;
  z-index: 3;
`

const LeftIcons = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: ${props => props.theme.spaces.m};
  padding-left: ${props => props.theme.spaces.m};
  pointer-events: auto;
`

const RightIcons = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
`

const SyncProvider = styled.div`
  position: relative;
  pointer-events: auto;
`

const StyledProviderTooltip = styled(Tooltip)`
  bottom: 50%;
  left: -4px;
  transform: translate(-100%, 50%);
  min-width: 120px;
`
