import MomentLocaleUtils from 'react-day-picker/moment'

import {
  PersonOnCreationDetail,
  CustomDropdownField,
  CustomField,
  CustomFieldValue,
  PersonDetail,
  DropdownOptionValue,
  CustomSingleDropdownFieldValue,
  Person,
  PersonBase,
  ChartDetail,
  CustomFieldValueFilled,
} from '../types'
import { makeCapabilitiesReadOnly } from '../utils'
import { ChartConfig, useChartConfig, useChartData } from '../providers'
import { CUSTOM_DATE_PICKER_FORMAT } from 'components/specific/chart-side-panel/employee/edit/fields/custom'
import { normalizeString } from 'helpers'
import { useAuth } from 'auth'

import {
  CustomFieldValue as CustomFieldValueBE,
  CustomDropdownFieldValue as CustomDropdownFieldValueBE,
  PersonDetailDataFragmentFragment,
  PersonDataFragmentFragment,
  CustomConnectionFieldValue,
} from 'apollo/generated/graphql'

type OptionsParams = {
  options: CustomDropdownField['options']
  optionId: CustomDropdownFieldValueBE['value']
}
const convertOptions = ({ options, optionId }: OptionsParams): DropdownOptionValue[] => {
  return options.map(option => ({ ...option, isSelected: option.id === optionId }))
}

type ConvertFieldValParams = {
  personCF: CustomFieldValueBE | undefined
  chartCF: CustomField
}
const convertCustomFieldValueToFE = ({ personCF, chartCF }: ConvertFieldValParams): CustomFieldValue | undefined => {
  const { id, label, type } = chartCF
  const value = personCF ? ('value' in personCF ? personCF.value : '') : ''

  switch (type) {
    case 'text':
      return { id, label, type: 'text', typeLabel: 'Text', value }
    case 'single-date':
      return {
        id,
        label,
        type,
        typeLabel: 'Date picker',
        value: value ? MomentLocaleUtils.formatDate(new Date(value), CUSTOM_DATE_PICKER_FORMAT) : value,
      }
    case 'single-select': {
      const options = convertOptions({ options: chartCF?.options || [], optionId: value })
      const selectedOption = options.find(o => o.isSelected) as CustomSingleDropdownFieldValue['selectedOption']
      return {
        id,
        label,
        options,
        selectedOption,
        displayIconsOnChartNode: chartCF.displayIconsOnChartNode,
        type,
        typeLabel: 'Dropdown',
      }
    }
    case 'connection': {
      const { lineColor, lineStyle, lineLabel, displayLineOnChart } = chartCF
      const nodes = (personCF as CustomConnectionFieldValue | undefined)?.nodes || []
      const customValues = nodes.map(n => ({
        id: n.id,
        name: n.__typename === 'Person' ? `${n.givenName} ${n.familyName}`.trim() : n.name,
      }))
      return {
        id,
        label,
        lineColor,
        lineStyle,
        lineLabel,
        values: customValues,
        type,
        typeLabel: 'Connection',
        displayLineOnChart,
      }
    }
    default:
      console.error('Custom field value:', personCF, ' is not handled!')
  }
}

type ConvertFieldsValParams = {
  chartCFs: CustomField[]
  personCFs?: readonly CustomFieldValueBE[]
}
export const convertCustomFieldsValueToFE = ({ chartCFs, personCFs }: ConvertFieldsValParams): CustomFieldValue[] => {
  return chartCFs.reduce<CustomFieldValue[]>((mapped, chartCF) => {
    const personCF = personCFs?.find(f => f.id === chartCF.id)
    const convertedCF = convertCustomFieldValueToFE({ personCF, chartCF })
    if (convertedCF) mapped.push(convertedCF)

    return mapped
  }, [])
}

const getCustomFieldsFilled = (customFields: PersonBase['customFields']): CustomFieldValueFilled[] => {
  const customFieldsWithValue = customFields.filter(field => {
    switch (field.type) {
      case 'text':
      case 'single-date':
        return Boolean(field.value)
      case 'connection':
        return field.values.length > 0
      case 'single-select':
        return Boolean(field.selectedOption)
      default:
        console.error('Custom field value:', field, ' is not handled!')
    }
  })

  return customFieldsWithValue as CustomFieldValueFilled[]
}

type BasePersonMapperParams = {
  authEmail: string
  chart: ChartDetail
  chartConfig: ChartConfig
  person: PersonDataFragmentFragment | PersonDetailDataFragmentFragment
}
export const basePersonMapper = ({ authEmail, chart, chartConfig, person }: BasePersonMapperParams): PersonBase => {
  const { customFields: chartCFs } = chart || {}
  const { teamSizeTest: teamSize, emailAliases, email, capabilities } = person
  const personEmail = email || ''

  // Return new capabilities based on chart mode
  const newCapabilities = chartConfig.isReadOnly ? makeCapabilitiesReadOnly(capabilities) : capabilities

  // Check isMe via email and email aliases
  const isMe = [personEmail, ...emailAliases].some(e => authEmail && normalizeString(e) === normalizeString(authEmail))

  // Custom fields
  const customFields =
    (chartCFs && convertCustomFieldsValueToFE({ personCFs: person.customFields as CustomFieldValueBE[], chartCFs })) ??
    []
  const customFieldsFilled = getCustomFieldsFilled(customFields)

  return { capabilities: newCapabilities, customFields, customFieldsFilled, isMe, teamSize, type: 'employee' }
}

type PersonMapperParams = Omit<BasePersonMapperParams, 'person'> & { person: PersonDataFragmentFragment }
export const personMapper = (params: PersonMapperParams): Person => {
  const personBase = basePersonMapper(params)
  const { person } = params

  return { ...person, ...personBase }
}

type PersonDetailMapperParams = Omit<BasePersonMapperParams, 'person'> & { person: PersonDetailDataFragmentFragment }
export const personDetailMapper = (params: PersonDetailMapperParams): PersonDetail => {
  const personBase = basePersonMapper(params)
  const { person } = params

  return { ...person, ...personBase }
}

export const usePerson = (person: PersonDetailDataFragmentFragment): PersonDetail => {
  const chart = useChartData()
  const { config: chartConfig } = useChartConfig()
  const { user } = useAuth()
  const authEmail = user.email

  return personDetailMapper({ authEmail, chart, chartConfig, person })
}

export const usePersonOnCreation = (): PersonOnCreationDetail => {
  const { customFields: chartCFs } = useChartData()
  return { customFields: convertCustomFieldsValueToFE({ chartCFs }) }
}
