import styled from 'styled-components'

const Row = styled.div<{ $isDisabled?: boolean }>`
  min-height: 56px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${props => props.theme.spaces.m};
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.m} ${props => props.theme.spaces.l};
  cursor: ${props => (props.$isDisabled ? 'default' : 'pointer')};

  &:not(:last-child) {
    border-bottom: 1px solid ${props => props.theme.colors.greyExtraLight};
  }

  &:hover {
    background: ${props => (props.$isDisabled ? 'initial' : props.theme.colors.greyExtraLight)};
  }

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    gap: initial;
    flex-wrap: nowrap;
  }
`

const Cell = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  line-height: 26px;
  color: ${props => props.theme.colors.dark};
  margin-right: 40px;
`

const LabelCell = styled(Cell)`
  width: 200px;
  font-weight: 500;
`

const TypeCell = styled(Cell)`
  width: 80px;
`

const LineCell = styled(Cell)`
  width: 70px;
  justify-content: center;
`

const OptionsCell = styled(Cell)`
  width: 70px;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    display: none;
  }
`

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${props => props.theme.spaces.s};
  background: ${props => props.theme.colors.greyExtraLight};
  border-radius: 50%;
`

export const tableComponents = {
  Row,
  Cell,
  LabelCell,
  TypeCell,
  LineCell,
  OptionsCell,
  IconContainer,
}
