import { generatePath, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { LeftHeader } from './left-header'
import { SubscriptionCards } from './subscription-cards'
import { LinkStyles } from 'components/generic'
import { LinkIcon } from 'components/icons'

import { useChartId } from 'tree/providers'
import { useGoToPremiumScreen } from 'features/premium'
import { CHART_EDIT_PATH } from 'routes'
import { spaces } from 'theme'

export const LeftSide = () => {
  const chartId = useChartId()
  const history = useHistory()
  const { goToPremiumScreen } = useGoToPremiumScreen()
  const onClose = () => history.push(generatePath(CHART_EDIT_PATH, { chartUuid: chartId }))

  return (
    <LeftSideInner>
      <LeftContent>
        <LeftHeader loading={false} onClose={onClose} />
        <Heading>Which subscription plan are you interested in?</Heading>
        <LinkStyled onClick={() => goToPremiumScreen()}>
          Full plans specification <LinkIcon $spacing={{ my: 'auto', ml: spaces.s }} />
        </LinkStyled>
        <SubscriptionCards />
      </LeftContent>
    </LeftSideInner>
  )
}

const LeftSideInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.colors.white};

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    flex: 4;
    align-items: flex-end;
  }

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) and (min-height: 900px) {
    justify-content: center;
  }
`

const LeftContent = styled.div`
  width: 100%;
  max-width: 392px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 40px ${props => props.theme.spaces.l};
  text-align: center;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    align-items: flex-start;
    margin-right: 75px;
    text-align: left;
  }

  @media (min-width: 1280px) {
    max-width: calc(400px + (2 * ${props => props.theme.spaces.l}));
  }
`

const Heading = styled.h1`
  margin-bottom: ${props => props.theme.spaces.m};
  color: ${props => props.theme.colors.dark};
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
`

const LinkStyled = styled.div`
  ${LinkStyles}
  margin-bottom: ${props => props.theme.spaces.xl};
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
`
