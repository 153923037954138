import { css } from 'styled-components'

export const navlinkCSS = css`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 3px 0px 0px 3px;
  padding: ${props => props.theme.spaces.m};
  margin-top: ${props => props.theme.spaces.s};
  color: ${props => props.theme.colors.dark};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-decoration: none;
  cursor: pointer;

  &.${'active'} {
    background: ${props => props.theme.colors.greyExtraLight};
    pointer-events: none;
    cursor: default;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 3px;
      height: 100%;
      border-radius: 3px 0px 0px 3px;
      background: ${props => props.theme.colors.dark};
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background: ${props => props.theme.colors.greyExtraLight};
    transform: translateX(-100%);
    transition: transform ${props => props.theme.transitions.extraFastEase};
    z-index: -1;
  }

  &:hover:before {
    transform: translateX(0);
  }
`
