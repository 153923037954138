import { SVG, SVGProps } from './SVG'

export const ProfileInSquareIcon = (props: SVGProps) => (
  <SVG width='14' height='14' viewBox='0 0 14 14' fill='none' {...props}>
    <path
      d='M12.25 1.75V12.25H1.75V1.75H12.25ZM12.25 0.25H1.75C0.9175 0.25 0.25 0.925 0.25 1.75V12.25C0.25 13.075 0.9175 13.75 1.75 13.75H12.25C13.075 13.75 13.75 13.075 13.75 12.25V1.75C13.75 0.925 13.075 0.25 12.25 0.25ZM7 7C5.7625 7 4.75 5.9875 4.75 4.75C4.75 3.5125 5.7625 2.5 7 2.5C8.2375 2.5 9.25 3.5125 9.25 4.75C9.25 5.9875 8.2375 7 7 7ZM7 4C6.5875 4 6.25 4.3375 6.25 4.75C6.25 5.1625 6.5875 5.5 7 5.5C7.4125 5.5 7.75 5.1625 7.75 4.75C7.75 4.3375 7.4125 4 7 4ZM11.5 11.5H2.5V10.3525C2.5 8.4775 5.4775 7.6675 7 7.6675C8.5225 7.6675 11.5 8.4775 11.5 10.3525V11.5ZM4.2325 10H9.7675C9.25 9.58 7.9825 9.16 7 9.16C6.0175 9.16 4.7425 9.58 4.2325 10Z'
      fill='currentColor'
    />
  </SVG>
)
