import { SVG, SVGProps } from './SVG'

export const CalendarTodayIcon = (props: SVGProps) => (
  <SVG width='28' height='28' viewBox='0 0 28 28' fill='none' {...props}>
    <path
      d='M8.16667 11.6667H14V17.5H8.16667V11.6667ZM22.1667 22.1667H5.83333V9.33333H22.1667V22.1667ZM22.1667 3.5H21V1.16666H18.6667V3.5H9.33333V1.16666H7V3.5H5.83333C4.53833 3.5 3.5 4.55 3.5 5.83333V22.1667C3.5 22.7855 3.74583 23.379 4.18342 23.8166C4.621 24.2542 5.21449 24.5 5.83333 24.5H22.1667C22.7855 24.5 23.379 24.2542 23.8166 23.8166C24.2542 23.379 24.5 22.7855 24.5 22.1667V5.83333C24.5 5.21449 24.2542 4.621 23.8166 4.18341C23.379 3.74583 22.7855 3.5 22.1667 3.5Z'
      fill='currentColor'
    />
  </SVG>
)
