import { ReactNode, useRef } from 'react'
import styled from 'styled-components'

import { ChartLeftSidePanelRefProvider } from './chart-left-side-panel-context'

import { useTopNavigationToggle } from 'providers'

type Props = {
  children: ReactNode
}

export const ChartLeftSidePanel = ({ children }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const { isVisible } = useTopNavigationToggle()

  return (
    <ChartLeftSidePanelInner ref={ref} $isTopNavVisible={isVisible}>
      <ChartLeftSidePanelRefProvider panelRef={ref}>{children}</ChartLeftSidePanelRefProvider>
    </ChartLeftSidePanelInner>
  )
}

const ChartLeftSidePanelInner = styled.div<{ $isTopNavVisible: boolean }>`
  position: relative;
  width: 100%;
  max-width: 640px;
  height: auto;
  background: ${props => props.theme.colors.white};
  overflow-y: auto;
  z-index: 20;
  box-shadow: rgb(69 90 100 / 30%) 0px 3px 12px;
`
