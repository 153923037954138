import MomentLocaleUtils from 'react-day-picker/moment'

import { ExportingModalType } from 'components/specific'

import { useExportChartRequest } from './use-export-chart-request'
import { ChartDetail, ChartExportFileFormat, Node } from '../../types'
import { useModal } from 'modal'
import analytics from 'analytics'
import { EXPORT_TRACK } from 'analytics/constants'
import { downloadFileFromBlob, getBlobFromUrl, handleErrorValidation, normalizeFileName, wait } from 'helpers'
import { getIsSubscribed } from 'features/premium'

type ExportChartActionParams = {
  format: ChartExportFileFormat
  compact: boolean
  rootId?: Node['id']
}

type HandleErrorParams = ExportChartActionParams & { error: unknown }

const UNSUBSCRIBED_WAIT_TIME_IN_MS = 7 * 1000

export const useExportChartAction = ({ chartData }: { chartData: ChartDetail }) => {
  const { uuid: chartId, name: chartName, employeeCount, departmentCount } = chartData
  const isSubscribed = getIsSubscribed(chartData.subscription)
  const { open, close, replace } = useModal()
  const { exportChartRequest } = useExportChartRequest()

  const handleDownload = async ({
    downloadUrl,
    fileType,
  }: {
    downloadUrl: string
    fileType: ChartExportFileFormat
  }) => {
    const fileBlob = await getBlobFromUrl(downloadUrl)
    const { formatDate } = MomentLocaleUtils
    const dateName = formatDate(new Date(), 'YYYY-MM-DD')
    const normalizedChartName = normalizeFileName(chartName)
    const fileName = `${dateName} - ${normalizedChartName}.${fileType}`
    downloadFileFromBlob(fileBlob, fileName)
  }

  const handleTrackAnalytics = ({ format }: { format: ChartExportFileFormat }) => {
    analytics.track(EXPORT_TRACK[`${format}Success` as const], {
      chartUuid: chartId,
      chartName,
      employeeCount,
      departmentCount,
    })
  }

  // Export ----------------------------------------------
  const handleError = ({ error, ...params }: HandleErrorParams) => {
    handleErrorValidation({
      track: {
        message: EXPORT_TRACK[`${params.format}Failed` as const],
        values: { chartUuid: chartId, chartName, employeeCount, departmentCount, error },
      },
    })
  }

  const exportChart = async (params: ExportChartActionParams) => {
    const { compact, format, rootId } = params
    try {
      const res = await exportChartRequest({ chartId, compact, format, rootId })
      await handleDownload({ downloadUrl: res.data.exportNodes.downloadUrl, fileType: format })
      handleTrackAnalytics({ format })
      return Promise.resolve(res)
    } catch (error) {
      handleError({ ...params, error })
    }
  }

  // Export with status modal ----------------------------------------------
  const handleErrorWithModal = ({ error, ...params }: HandleErrorParams) => {
    handleError({ error, ...params })
    replace<ExportingModalType, ExportingModalType>('exportingModal', 'exportingModal', {
      chartData,
      status: 'failed',
      exportAgain: () => {
        close('exportingModal')
        exportChartWithStatus(params)
      },
    })
  }

  const exportChartWithStatus = async (params: ExportChartActionParams) => {
    const { compact, format, rootId } = params
    close('exportChartModal')
    open<ExportingModalType>('exportingModal', { chartData })
    const startTimer = performance.now()
    try {
      const res = await exportChartRequest({ chartId, compact, format, rootId })
      if (!isSubscribed) {
        const waitedTime = performance.now() - startTimer
        const remainingWaitTime = UNSUBSCRIBED_WAIT_TIME_IN_MS - waitedTime
        await wait(remainingWaitTime)
      }
      replace<ExportingModalType, ExportingModalType>('exportingModal', 'exportingModal', {
        chartData,
        status: 'completed',
      })
      await handleDownload({ downloadUrl: res.data.exportNodes.downloadUrl, fileType: format })
      handleTrackAnalytics({ format })
    } catch (error) {
      handleErrorWithModal({ ...params, error })
    }
  }

  return { exportChart, exportChartWithStatus }
}
