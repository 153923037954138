import { ReactNode } from 'react'
import styled from 'styled-components'

import { FlexBox } from 'components/generic'

import { spaces } from 'theme'

type Props = {
  icon: ReactNode
  squareColor: string
  title?: string
  description?: ReactNode
}

export const CustomFieldPreview = ({ icon, squareColor, title, description }: Props) => (
  <FlexBox $isYCentered $spacing={{ p: spaces.m, pl: 0 }}>
    <Square $background={squareColor}>{icon}</Square>

    <FlexBox $isDirectionColumn $spacing={{ ml: spaces.l }}>
      {title && <Title>{title}</Title>}
      {description && <Description>{description}</Description>}
    </FlexBox>
  </FlexBox>
)

const Square = styled.div<{ $background: string }>`
  min-width: 48px;
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: ${props => props.$background};
`

const Title = styled.p`
  color: ${props => props.theme.colors.dark};
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
`

const Description = styled.p`
  max-width: 612px;
  color: ${props => props.theme.colors.dark};
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
`
