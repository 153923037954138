import { gql } from '@apollo/client'
import { nodeDetailDataFragment } from '../fragments'

export const node = gql`
  query node($chartKey: String!, $uuid: String!) {
    node(chartKey: $chartKey, uuid: $uuid) {
      ...NodeDetailDataFragment
    }
  }
  ${nodeDetailDataFragment}
`
