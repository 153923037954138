import { gql } from '@apollo/client'

export const uploadUrlPolicy = gql`
  fragment UploadUrlPolicyFragment on UploadUrlPolicy {
    url
    getUrl
    fields {
      key
      value
    }
  }
`
