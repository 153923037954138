import { useRef, ReactNode } from 'react'
import styled from 'styled-components'
import { Formik, FormikProps, FormikHelpers } from 'formik'

import { isValidEmail } from 'helpers'
import { CollaboratorFormData } from './types'

type FormErrors = {
  email?: string
  role?: string
}
export type CollaboratorFormProps = {
  children: (s: FormikProps<CollaboratorFormData>) => ReactNode
  onSubmit: (data: CollaboratorFormData, actions: FormikHelpers<CollaboratorFormData>) => void
  className?: string
  initialValues?: CollaboratorFormData
}
const defaultData: CollaboratorFormData = { email: '', role: 'viewer' }

export const CollaboratorForm = (props: CollaboratorFormProps) => {
  const { children, onSubmit, className, initialValues = defaultData } = props
  const formRef = useRef<HTMLFormElement>(null)

  return (
    <Formik<CollaboratorFormData>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => {
        formRef?.current?.focus()
        onSubmit(values, formikHelpers)
      }}
      validate={values => {
        const errors: FormErrors = {}
        if (values.email && !isValidEmail(values.email)) errors.email = `Email address ${values.email} is not valid`
        return errors
      }}
    >
      {formState => (
        <Form className={className} ref={formRef} tabIndex={0} onSubmit={formState.handleSubmit}>
          {children(formState)}
        </Form>
      )}
    </Formik>
  )
}

const Form = styled.form`
  outline: none;
`
