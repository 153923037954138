import { SVG, SVGProps } from './SVG'

export const PdfIcon = ({ width = '20px', ...props }: SVGProps) => (
  <SVG width={width} viewBox='0 0 64 63' fill='none' {...props}>
    <path
      d='M62.6246 39.7217C57.8438 34.7575 44.7898 36.7801 41.6644 37.1477C37.0681 32.7348 33.9427 27.403 32.8394 25.5645C34.4941 20.6003 35.5969 15.636 35.7811 10.3045C35.7811 5.70788 33.9426 0.744019 28.7942 0.744019C26.9558 0.744019 25.3007 1.84683 24.3813 3.31765C22.1753 7.17911 23.0943 14.9012 26.5877 22.8071C24.5655 28.5066 22.7267 34.0223 17.5786 43.767C12.2468 45.9726 1.03121 51.1211 0.111794 56.6372C-0.25581 58.2914 0.295595 59.9468 1.58261 61.2334C2.86963 62.3366 4.52425 62.888 6.17928 62.888C12.982 62.888 19.6009 53.5117 24.1975 45.605C28.0586 44.3184 34.126 42.4796 40.1935 41.3764C47.3638 47.6276 53.6151 48.5466 56.9248 48.5466C61.3372 48.5466 62.9922 46.7082 63.5436 45.0532C64.4627 43.2152 63.9112 41.1926 62.6246 39.7217ZM58.0276 42.8476C57.8438 44.1346 56.1895 45.4212 53.2475 44.686C49.7541 43.767 46.6286 42.112 43.8708 39.9055C46.261 39.5375 51.5925 38.9861 55.4539 39.7217C56.9248 40.0893 58.3956 41.0088 58.0276 42.8476ZM27.3234 4.97227C27.691 4.42087 28.2428 4.05326 28.7942 4.05326C30.4488 4.05326 30.8164 6.07549 30.8164 7.73051C30.6326 11.592 29.897 15.4526 28.6104 19.1299C25.8521 11.7754 26.4039 6.62689 27.3234 4.97227ZM26.9558 40.6411C28.4266 37.6995 30.4492 32.551 31.1844 30.3446C32.839 33.102 35.5969 36.4121 37.0681 37.8829C37.0681 38.0671 31.3682 39.1699 26.9558 40.6411ZM16.1078 47.9956C11.8792 54.9821 7.46629 59.3946 5.07606 59.3946C4.70846 59.3946 4.34045 59.2108 3.97285 59.027C3.42104 58.659 3.23724 58.1076 3.42104 57.3724C3.97285 54.7983 8.75331 51.3049 16.1078 47.9956Z'
      fill='currentColor'
    />
  </SVG>
)
