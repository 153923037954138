import { css } from 'styled-components'

const BORDER_SIZE = 2

export const borderHighlightCSS = css`
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - ${BORDER_SIZE * 2}px);
    height: calc(100% - ${BORDER_SIZE * 2}px);
    border: ${BORDER_SIZE}px solid ${props => props.theme.colors.purple};
    border-radius: inherit;
    opacity: 0;
    pointer-events: none;
    touch-action: none;
    animation: ${props => props.theme.keyframes.borderHighlight} 1s ease-in;
  }
`
