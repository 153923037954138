import styled, { css } from 'styled-components'

export const CalendarIcon = styled.img<{ isBig?: boolean }>`
  position: relative;
  top: -1px;
  width: 30px;
  margin-left: 8px;

  ${props =>
    props.isBig &&
    css`
      display: flex;
      width: 48px;
      margin: ${props => props.theme.spaces.l} 0;
    `}
`
