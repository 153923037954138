import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { MdKeyboardArrowRight } from 'react-icons/md'

import { Badge, FlexBox } from 'components/generic'

import { addTransparencyToColor } from 'helpers'
import { spaces } from 'theme'

type Props = {
  title: string
  subscriptionTitleBadge?: string
  employeeLimit: string
  price: ReactNode
  couponBadgeLabel?: string
  GWSync: string
  support: string
  usersOrEditors: string
  isActive?: boolean
  onClick: () => void
}

export const SubscriptionOptionCard = ({
  title,
  subscriptionTitleBadge,
  employeeLimit,
  price,
  couponBadgeLabel,
  GWSync,
  support,
  usersOrEditors,
  isActive,
  onClick,
}: Props) => (
  <Card $isActive={isActive} onClick={onClick}>
    <Content>
      <Row $isBig>
        <FlexBox $isYCentered>
          <MainText>{title}</MainText>
          {subscriptionTitleBadge && (
            <Badge $color='purple' $size='medium' $spacing={{ ml: spaces.m, py: spaces.xs }}>
              {subscriptionTitleBadge}
            </Badge>
          )}
        </FlexBox>
        <RightSide>
          <DescriptionGrey>{employeeLimit}</DescriptionGrey>
        </RightSide>
      </Row>

      <Row $isBig>
        <DescriptionGrey>Per month</DescriptionGrey>
        <RightSide>
          <MainText>{price}</MainText>
          {couponBadgeLabel && (
            <Badge $color='yellow' $size='medium' $spacing={{ ml: spaces.m, py: spaces.xs }}>
              {couponBadgeLabel}
            </Badge>
          )}
        </RightSide>
      </Row>

      <Row>
        <Description>Google Workspace Sync</Description>
        <RightSide>
          <MainText>{GWSync}</MainText>
        </RightSide>
      </Row>
      <Row>
        <Description>Support</Description>
        <RightSide>
          <MainText>{support}</MainText>
        </RightSide>
      </Row>
      <Row>
        <Description>Users / editors</Description>
        <RightSide>
          <MainText>{usersOrEditors}</MainText>
        </RightSide>
      </Row>
    </Content>

    <ArrowContainer $isActive={isActive}>
      <MdKeyboardArrowRight size={28} />
    </ArrowContainer>
  </Card>
)

const ArrowContainer = styled.div<{ $isActive?: boolean }>`
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  background: ${props => props.theme.colors.greyExtraLight};
  color: ${props => props.theme.colors.greyMedium};
  transition: ${props => props.theme.transitions.extraFastEase};

  ${props =>
    props.$isActive &&
    css`
      background: ${props => props.theme.colors.purple};
      color: ${props => props.theme.colors.white};
    `}
`

const Card = styled.div<{ $isActive?: boolean }>`
  min-width: 280px;
  width: 100%;
  max-width: 400px;
  min-height: 184px;
  display: flex;
  border-radius: 6px;
  border: 2px solid ${props => props.theme.colors.greyExtraLight};
  background: ${props => props.theme.colors.white};
  text-align: left;
  cursor: pointer;
  transition: ${props => props.theme.transitions.fastEase};

  &:hover {
    border-color: ${props => (props.$isActive ? props.theme.colors.purpleDark : props.theme.colors.border)};

    ${ArrowContainer} {
      background: ${props => (props.$isActive ? props.theme.colors.purpleDark : props.theme.colors.border)};
    }
  }

  ${props =>
    props.$isActive &&
    css`
      border-color: ${props => props.theme.colors.purple};
      background: ${props => addTransparencyToColor(props.theme.colors.purpleVeryLight, 0.2)};
    `}
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${props => props.theme.spaces.l};
`

const Row = styled.div<{ $isBig?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${props => props.theme.spaces.m};
  margin-bottom: ${props => (props.$isBig ? props.theme.spaces.l : props.theme.spaces.m)};

  &:last-child {
    margin-bottom: 0;
  }
`

const RightSide = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
`

const MainText = styled.h2`
  color: ${props => props.theme.colors.dark};
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
`

const Description = styled.p`
  color: ${props => props.theme.colors.dark};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`
const DescriptionGrey = styled(Description)`
  color: ${props => props.theme.colors.greyMedium};
`
