import { useState } from 'react'
import styled from 'styled-components'

import { MoveToChartButton } from './move-to-chart-button'
import { DepartmentSelect } from '../department-select'
import { SearchField } from 'components/generic'
import { LinkStyles } from 'components/generic/link/link-styles'

import { PersonDetail } from 'tree/types'

import { PersonDetailDataFragmentFragment, DepartmentDetailDataFragmentFragment } from 'apollo/generated/graphql'

export type InitSuperiorValues = {
  directParent: {
    uuid: string | null
    name: string | null
    type: string | null
    departmentColor: string | null
  }
  departmentSuperiorPerson: {
    uuid: string | null
    name: string | null
  }
}

export type InitSearchFieldValues = {
  uuid: string | null
  name: string | null
  type: string | null
  departmentColor: string | null
}

export type InitDepartmentSelectValues = {
  uuid: string | null
  name: string | null
  departmentColor: string | null
  isDropdownListActive: boolean
}

export type SetDepartmentSelectValues = (departmentSelectValues: InitDepartmentSelectValues) => void

export type HandleParentChange = (value: string | null) => void

export type HandleSetFieldValue = (name: string, value: string | boolean | null) => void

export type SuperiorFieldProps = {
  chartUuid: string
  moveToChart: boolean
  node: (PersonDetail | PersonDetailDataFragmentFragment) | DepartmentDetailDataFragmentFragment | null
  parentUuid: string | null
  handleSetFieldValue: HandleSetFieldValue
  className?: string
  createMode?: boolean
}

export const SuperiorField = (props: SuperiorFieldProps) => {
  const { chartUuid, moveToChart, node, parentUuid, handleSetFieldValue, className, createMode } = props
  const parentNodesLength = node?.parentNodes.length || 0
  const directParentNode = node?.parentNodes[parentNodesLength - 1]

  const findDepartmentSuperiorPerson = () => {
    // If Node is Department - check if has superior person
    if (node?.__typename === 'Department' || (node?.__typename === 'Person' && node?.uuid !== parentUuid)) {
      const departmentSuperiorPerson = node.parentNodes
        .slice()
        .reverse()
        .find((node: any) => node.__typename === 'Person')
      return {
        uuid: departmentSuperiorPerson?.uuid || null,
        name: departmentSuperiorPerson?.name || null,
      }
    }
    // Node isn't Department
    return {
      uuid: null,
      name: null,
    }
  }

  // ---------------------------------
  // Superior Init Values
  let initSuperiorValues: InitSuperiorValues
  if (node?.uuid === parentUuid) {
    // Creating new Node (Employee / Department)
    initSuperiorValues = {
      directParent: {
        uuid: node.uuid,
        name: node.name,
        type: node.type,
        departmentColor: node?.__typename === 'Department' ? node.color : null,
      },
      departmentSuperiorPerson: findDepartmentSuperiorPerson(),
    }
  } else if (directParentNode?.__typename === 'Department') {
    // Direct Parent Node is Department
    initSuperiorValues = {
      directParent: {
        uuid: directParentNode?.uuid,
        name: directParentNode?.name,
        type: 'department',
        departmentColor: directParentNode?.color,
      },
      departmentSuperiorPerson: findDepartmentSuperiorPerson(),
    }
  } else if (directParentNode?.__typename === 'Person') {
    // Direct Parent Node is Person
    initSuperiorValues = {
      directParent: {
        uuid: directParentNode?.uuid,
        name: directParentNode?.name,
        type: 'employee',
        departmentColor: null,
      },
      departmentSuperiorPerson: {
        uuid: null,
        name: null,
      },
    }
  } else {
    // No parent exists - root
    initSuperiorValues = {
      directParent: {
        uuid: null,
        name: null,
        type: null,
        departmentColor: null,
      },
      departmentSuperiorPerson: {
        uuid: null,
        name: null,
      },
    }
  }

  // ---------------------------------
  // Init values for Search Field
  let initSearchFieldValues: InitSearchFieldValues
  const emptySearchFieldValues = {
    uuid: null,
    name: null,
    type: null,
    departmentColor: null,
  }
  if (node && !initSuperiorValues.departmentSuperiorPerson.name) {
    initSearchFieldValues = {
      uuid: initSuperiorValues.directParent.uuid,
      name: initSuperiorValues.directParent.name,
      type: initSuperiorValues.directParent.type,
      departmentColor: initSuperiorValues.directParent.departmentColor,
    }
  } else if (node && initSuperiorValues.departmentSuperiorPerson.name) {
    initSearchFieldValues = {
      uuid: initSuperiorValues.departmentSuperiorPerson.uuid,
      name: initSuperiorValues.departmentSuperiorPerson.name,
      type: 'employee',
      departmentColor: null,
    }
  } else {
    // Creating Node from root
    initSearchFieldValues = { ...emptySearchFieldValues }
  }

  // ---------------------------------
  // Init values for Department Select
  let initDepartmentSelectValues: InitDepartmentSelectValues
  const emptyDepartmentSelectValues = {
    uuid: null,
    name: null,
    departmentColor: null,
    isDropdownListActive: false,
  }
  if (initSuperiorValues.directParent.type === 'department') {
    initDepartmentSelectValues = {
      uuid: initSuperiorValues.directParent.uuid,
      name: initSuperiorValues.directParent.name,
      departmentColor: initSuperiorValues.directParent.departmentColor,
      isDropdownListActive: false,
    }
  } else if (initSuperiorValues.directParent.type === 'employee') {
    initDepartmentSelectValues = {
      uuid: null,
      name: 'Direct report',
      departmentColor: null,
      isDropdownListActive: false,
    }
  } else {
    initDepartmentSelectValues = { ...emptyDepartmentSelectValues }
  }

  // Logic
  const [personUuid, setPersonUuid] = useState(
    initSearchFieldValues.type === 'employee' ? initSearchFieldValues.uuid : ''
  )
  const [departmentSelectValues, setDepartmentSelectValues] = useState(initDepartmentSelectValues)

  const handleParentChange: HandleParentChange = value => {
    handleSetFieldValue('parentUuid', value)
  }

  return (
    <SuperiorFieldWrap className={className} inDirectory={parentUuid === undefined}>
      <>
        {node?.unassigned && !moveToChart ? (
          <MoveToChartButton
            onMoveToChart={() => {
              handleSetFieldValue('parentUuid', null)
              handleSetFieldValue('moveToChart', true)
            }}
          />
        ) : (
          <>
            <StyledSearchField
              mode='superiorField'
              initValues={initSearchFieldValues}
              personUuid={personUuid}
              handleSetPersonUuid={setPersonUuid}
              chartUuid={chartUuid}
              parentUuid={parentUuid}
              nodeUuid={node?.uuid}
              nodeType={node?.__typename}
              wasUnassigned={node?.unassigned}
              handleSetDepartmentSelectValues={setDepartmentSelectValues}
              onParentChange={handleParentChange}
              data-testid='input-supervisor-and-department-search'
            />
            {personUuid && (
              <>
                <VerticalLineWrap>
                  <VerticalLine />
                </VerticalLineWrap>
                <DepartmentSelect
                  initValues={departmentSelectValues}
                  handleSetDepartmentSelectValues={setDepartmentSelectValues}
                  personUuid={personUuid}
                  chartUuid={chartUuid}
                  handleSetFieldValue={handleSetFieldValue}
                />
              </>
            )}
            {!createMode && node?.unassigned && moveToChart && (
              <KeepInDirectory
                onClick={() => {
                  handleSetFieldValue('parentUuid', null)
                  handleSetFieldValue('moveToChart', false)
                  setPersonUuid('')
                  setDepartmentSelectValues(emptyDepartmentSelectValues)
                }}
              >
                Keep in directory
              </KeepInDirectory>
            )}
          </>
        )}
      </>
    </SuperiorFieldWrap>
  )
}

const SuperiorFieldWrap = styled.div<{ inDirectory: boolean }>`
  position: relative;
  flex: 1;
  width: calc(100% - 34px);
  min-height: ${props => props.theme.sizes.superiorFieldHeight};
  display: flex;
  ${props => props.inDirectory && 'padding-bottom: 10px;'}
`

const StyledSearchField = styled(props => <SearchField {...props} />)`
  min-height: inherit;
`

const VerticalLineWrap = styled.div`
  height: ${props => props.theme.sizes.superiorFieldHeight};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-right: 3px;
  padding-left: 3px;
  background: ${props => props.theme.colors.neutralSecondary};
`

const VerticalLine = styled.div`
  min-width: 1px;
  max-width: 1px;
  height: 16px;
  background: ${props => props.theme.colors.dark};
  opacity: 0.2;
`

const KeepInDirectory = styled.div`
  ${LinkStyles}
  position: absolute;
  top: ${props => props.theme.sizes.superiorFieldHeight};
  right: 0;
  margin-left: auto;
  font-size: 11px;
  line-height: 20px;
  user-select: none;
  transform: translateY(3px);
`
