import styled from 'styled-components'

import { Link } from 'components/generic'
import { GraphBarIcon } from 'components/icons'
import barchartPlaceholder from 'assets/barChart-placeholder.jpg'

type Props = {
  extraInfoNeeded?: boolean
}

export const ChartPlaceholder = ({ extraInfoNeeded }: Props) => (
  <Container>
    <InfoContainer>
      <Info>
        <StyledGraphBarIcon />
        <InfoHeading>Can’t show you this Widget</InfoHeading>
        {extraInfoNeeded ? (
          <span>
            You have to fill in few details before we can show you more&nbsp;
            <Link href='#'>Click here</Link>
          </span>
        ) : (
          <span>There are no data to display for this Widget</span>
        )}
      </Info>
    </InfoContainer>
    <StyledBarImg src={barchartPlaceholder} width='100%' alt='bar Org Chart placeholder' />
  </Container>
)

const Heading = styled.h1`
  position: absolute;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  top: 25px;
  left: 30px;
`

const InfoHeading = styled(Heading)`
  position: initial;
`

const StyledBarImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
`

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: 431px;
`

const StyledGraphBarIcon = styled(GraphBarIcon)`
  color: #b2b3c1;
  display: flex;
  margin-top: 25px;
  margin-bottom: 16px;
  transform: scale(2.5);
`

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
`

const Info = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  z-index: 99;
  font-size: 16px;
  max-width: 350px;
  line-height: 24px;
`
