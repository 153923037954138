import { ReactNode, createContext, useContext, useRef, useState } from 'react'

import { useAddCollaborator } from 'components/forms/collaboratorForm/hooks'
import { CollaboratorFormData } from 'components/forms/collaboratorForm/types'

export type PendingCollaborator = CollaboratorFormData & {
  chartUuid: string
  intervalId?: number
  isInvited?: boolean
  isNonMember?: boolean
}
type AddCollaboratorParams = {
  collaborator: CollaboratorFormData
  chartUuid: PendingCollaborator['chartUuid']
  isNonMember?: boolean
}
type ContextType = {
  pendingCollaborators: PendingCollaborator[]
  getChartPendingCollabs: (chartUuid: string) => PendingCollaborator[]
  onAddPendingCollab: (params: AddCollaboratorParams) => void
  onCancelPendingCollab: (canceledCollaboratorEmail: PendingCollaborator['email']) => void
  onCollabRemove: (chartUuid: string, email: string) => void
}

const noop = () => undefined
const PendingCollaboratorsContext = createContext<ContextType>({
  pendingCollaborators: [],
  getChartPendingCollabs: () => [],
  onAddPendingCollab: noop,
  onCancelPendingCollab: noop,
  onCollabRemove: noop,
})

type Props = {
  children: ReactNode
}

export const PendingCollaboratorsProvider = ({ children }: Props) => {
  const [pendingCollaborators, setPendingCollaborators] = useState<PendingCollaborator[]>([])
  const pendingCollaboratorsRef = useRef<PendingCollaborator[]>()
  pendingCollaboratorsRef.current = pendingCollaborators

  const { addCollaborator } = useAddCollaborator()

  const getChartPendingCollabs = (chartUuid: string) => {
    return pendingCollaborators.filter(c => c.chartUuid === chartUuid)
  }

  const handleAddCollab = ({ collaborator, chartUuid }: AddCollaboratorParams) => {
    const newPendingCollaborators = pendingCollaboratorsRef.current?.map(collab => {
      if (collab.email === collaborator.email) {
        clearInterval(collab?.intervalId)
        return { ...collab, intervalId: undefined, isInvited: true }
      }
      return collab
    })
    addCollaborator({ chartUuid, collaborator })
    setPendingCollaborators(newPendingCollaborators || [])
  }

  const handleAddPendingCollab: ContextType['onAddPendingCollab'] = ({ collaborator, chartUuid, isNonMember }) => {
    const intervalId = Number(setInterval(() => handleAddCollab({ collaborator, chartUuid }), 3000))
    setPendingCollaborators([...pendingCollaborators, { ...collaborator, chartUuid, intervalId, isNonMember }])
  }

  const handleCancelPendingCollab: ContextType['onCancelPendingCollab'] = canceledCollaboratorEmail => {
    const newPendingCollaborators = pendingCollaborators.filter(collaborator =>
      collaborator.email === canceledCollaboratorEmail ? clearInterval(collaborator.intervalId) : true
    )
    setPendingCollaborators(newPendingCollaborators)
  }

  const handleCollabRemove = (chartUuid: string, email: string) => {
    const pendingCollabIndex = pendingCollaborators.findIndex(c => c.chartUuid === chartUuid && c.email === email)
    if (pendingCollabIndex > -1) {
      const newPendingCollaborators = [...pendingCollaborators]
      newPendingCollaborators.splice(pendingCollabIndex, 1)
      setPendingCollaborators(newPendingCollaborators)
    }
  }

  return (
    <PendingCollaboratorsContext.Provider
      value={{
        pendingCollaborators,
        getChartPendingCollabs,
        onAddPendingCollab: handleAddPendingCollab,
        onCancelPendingCollab: handleCancelPendingCollab,
        onCollabRemove: handleCollabRemove,
      }}
    >
      {children}
    </PendingCollaboratorsContext.Provider>
  )
}

export const usePendingCollaborators = () => useContext(PendingCollaboratorsContext)
