import styled from 'styled-components'

import { Card } from '../card'
import { Button } from 'components/generic'

type Props = {
  deleteButtonText: string
  description: string
  title: string
  onDelete: () => void
  disabled?: boolean
}

export const DeletionCard = ({ deleteButtonText, description, title, onDelete, disabled, ...rest }: Props) => (
  <Card title={title}>
    <Content>{description}</Content>
    <Button disabled={disabled} onClick={onDelete} buttonType='danger' spacing={{ mr: 'auto' }} {...rest}>
      {deleteButtonText}
    </Button>
  </Card>
)

const Content = styled.div`
  margin-bottom: ${props => props.theme.spaces.l};
  font-size: 13px;
  line-height: 20px;
`
