import { SVG, SVGProps } from './SVG'

export const FolderIcon = (props: SVGProps) => (
  <SVG width='16' height='12' viewBox='0 0 16 12' fill='none' {...props}>
    <path
      d='M14 1.5H8L6.5 0H2C1.175 0 0.5075 0.675 0.5075 1.5L0.5 10.5C0.5 11.325 1.175 12 2 12H14C14.825 12 15.5 11.325 15.5 10.5V3C15.5 2.175 14.825 1.5 14 1.5ZM14 10.5H2V3H14V10.5Z'
      fill='currentColor'
    />
  </SVG>
)
