import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { ChartProviders, Directory, GWTokenRevokedBar, OnChartError } from './components'
import { InviteCollaborators, ChartTree, TopNavigation } from 'components/specific'
import { ChartLeftSidePanel } from 'components/generic'
import { WebLayout } from 'components/layouts'

import { useChartMapper, IsOnPublicRouteContext, useIsOnPublicRoute } from 'tree/hooks'
import { ChartConfigSafeProvider, useChartData } from 'tree/providers'
import { useInviteCollaboratorsPanel } from 'providers'
import {
  QuotaBarProInfo,
  QuotaBarExceeded,
  useQuotaFor,
  getIsSubscribed,
  ChartLockCountdownBar,
  getChartLockDate,
} from 'features/premium'
import { CHART_SIDE_PANEL_ROUTES, RenderRoutes } from 'routes'
import { useAuth } from 'auth'

export const PublicChartScreen = () => {
  const { code } = useParams<{ code: string }>()
  return (
    <IsOnPublicRouteContext.Provider value>
      <ChartConfigSafeProvider>
        <ChartScreenWrapper chartKey={code} />
      </ChartConfigSafeProvider>
    </IsOnPublicRouteContext.Provider>
  )
}

export const PrivateChartScreen = () => {
  const { chartUuid } = useParams<{ chartUuid: string }>()
  return (
    <ChartConfigSafeProvider>
      <ChartScreenWrapper chartKey={chartUuid} />
    </ChartConfigSafeProvider>
  )
}

type Props = {
  chartKey: string
}

const ChartScreenWrapper = ({ chartKey }: Props) => {
  const { loading, data, error } = useChartMapper(chartKey)
  if (error) return <OnChartError error={error} />
  if (loading) return null
  if (!data) return <div>Error, no data!</div>
  return (
    <ChartProviders chartData={data}>
      <ChartScreen />
    </ChartProviders>
  )
}

const ChartScreen = () => {
  const data = useChartData()
  const { GWTokenRevoked, subscription, capabilities, lock } = data
  const { chartLockDate } = getChartLockDate(lock)
  const { user } = useAuth()
  const { checkQuotas } = useQuotaFor([user, data])
  const quotaWarningResults = checkQuotas().filter(q => q.status === 'warning')
  const isSubscribed = getIsSubscribed(subscription)
  const isOnPublicRoute = useIsOnPublicRoute()
  const showQuotaProInfo = !isSubscribed && quotaWarningResults.length === 0 && !isOnPublicRoute
  const showQuotaExceeded = subscription?.upgradable && quotaWarningResults.length > 0 && !isOnPublicRoute

  const { isShown } = useInviteCollaboratorsPanel()

  return (
    <StyledWebLayout>
      <TopNavigation />

      {chartLockDate ? (
        <ChartLockCountdownBar chartLockDate={chartLockDate} />
      ) : (
        <>
          {showQuotaProInfo && <QuotaBarProInfo />}
          {showQuotaExceeded && <QuotaBarExceeded quotaResults={quotaWarningResults} />}
        </>
      )}
      {GWTokenRevoked && <GWTokenRevokedBar />}

      <Container>
        {isShown && (
          <ChartLeftSidePanel>
            <InviteCollaborators />
          </ChartLeftSidePanel>
        )}

        <Container>
          {capabilities?.canReadDirectory && <Directory />}
          <ChartTree />
        </Container>

        {RenderRoutes(CHART_SIDE_PANEL_ROUTES)}
      </Container>
    </StyledWebLayout>
  )
}

const StyledWebLayout = styled(WebLayout)`
  max-height: 100vh;
  flex-direction: column;
`

const Container = styled.div`
  flex: 1;
  position: relative;
  display: flex;
  overflow: hidden;
`
