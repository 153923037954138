import Select, { ActionMeta, StylesConfig, OnChangeValue } from 'react-select'
import styled from 'styled-components'

import { FieldContainer, FieldTitle, selectComponents, selectMenuBottomMargin, selectStyles } from 'components/generic'

import { dropdownMenu } from 'helpers'
import { CustomField, CustomFieldWithoutConnection } from 'tree/types'

import { PublicLink } from 'apollo/generated/graphql'

type OptionType = {
  fieldLabel: string
  fieldId: string
}

type Props = {
  customFields: CustomField[]
  allowedCustomFields: PublicLink['allowedCustomFields']
  onChange: (value: OnChangeValue<OptionType, true>, actionMeta: ActionMeta<OptionType>) => void
}

export const CustomFieldsOnPublicLinkSelect = ({ customFields, allowedCustomFields, onChange }: Props) => {
  const customFieldsWithoutConnection = customFields.filter(
    value => value.type !== 'connection'
  ) as CustomFieldWithoutConnection[]
  const options = customFieldsWithoutConnection.map<OptionType>(({ id, label }) => ({ fieldLabel: label, fieldId: id }))
  const defaultValue = options.filter(({ fieldId }) => allowedCustomFields?.includes(fieldId))

  return (
    <FieldContainer>
      <FieldTitle
        title={
          <>
            Show only these custom fields <TitleExplanation>(Custom fields are hidden by default.)</TitleExplanation>
          </>
        }
        size='big'
      />
      <Select<OptionType, true>
        menuPosition='fixed'
        menuPortalTarget={dropdownMenu} // Portaled due to Safari bug which hides fixed positioned element if their parent has overflow hidden -> https://bugs.webkit.org/show_bug.cgi?id=160953
        defaultValue={defaultValue}
        options={options}
        getOptionLabel={({ fieldLabel }) => fieldLabel}
        getOptionValue={({ fieldId }) => fieldId}
        menuPlacement='auto'
        placeholder='Select custom fields'
        styles={customStyles}
        isMulti
        isClearable
        onChange={onChange}
        components={{
          ClearIndicator: selectComponents.ClearIndicator,
          Control: selectComponents.Control,
          DropdownIndicator: selectComponents.DropdownIndicator,
          IndicatorSeparator: () => null,
          Placeholder: selectComponents.Placeholder,
        }}
      />
    </FieldContainer>
  )
}

const customStyles: StylesConfig<OptionType, true> = {
  ...selectStyles,
  menu: (provided, props) => ({
    ...selectStyles.menu?.(provided, props),
    ...selectMenuBottomMargin,
  }),
}

const TitleExplanation = styled.div`
  margin-left: ${props => props.theme.spaces.s};
  color: ${props => props.theme.colors.greyLight};
`
