import { FieldContainer, FieldTitle } from './components'
import { AssetUpload } from '../../specific/asset-upload'

import { useChartId } from 'tree/providers'

type Props = {
  initialImgSrc: string
  disabled: boolean
  onChange: (value: string) => void
}

export const FieldProfilePicture = ({ disabled, initialImgSrc, onChange }: Props) => {
  const chartId = useChartId()

  return (
    <FieldContainer>
      <FieldTitle title='Profile picture' />
      <AssetUpload
        assetType='avatar'
        chartUuid={chartId}
        initialImgSrc={initialImgSrc}
        disabled={disabled}
        setFieldValue={onChange}
        withEditor
      />
    </FieldContainer>
  )
}
