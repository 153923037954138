import { SVG, SVGProps } from './SVG'

export const ImageFileIcon = (props: SVGProps) => (
  <SVG viewBox='0 0 48 64' fill='none' {...props}>
    <path
      d='M8 0C3.5875 0 0 3.5875 0 8V56C0 60.4125 3.5875 64 8 64H40C44.4125 64 48 60.4125 48 56V20H32C29.7875 20 28 18.2125 28 16V0H8ZM32 0V16H48L32 0ZM16 32C16 34.2125 14.2125 36 12 36C9.7875 36 8 34.2125 8 32C8 29.7875 9.7875 28 12 28C14.2125 28 16 29.7875 16 32ZM27 36C27.6625 36 28.275 36.325 28.65 36.8625L39.65 52.8625C40.075 53.475 40.1125 54.275 39.775 54.925C39.4375 55.575 38.75 56 38 56H27H22H16H10C9.275 56 8.6125 55.6125 8.2625 54.9875C7.9125 54.3625 7.9125 53.5875 8.2875 52.975L14.2875 42.975C14.65 42.375 15.3 42 16 42C16.7 42 17.35 42.3625 17.7125 42.975L19.3125 45.65L25.35 36.875C25.725 36.3375 26.3375 36.0125 27 36.0125V36Z'
      fill='currentColor'
    />
  </SVG>
)
