import { gql } from '@apollo/client'
import { personDataFragment } from './person-fragment'
import { departmentDataFragment } from './department-fragment'

export const nodeDataFragment = gql`
  fragment NodeDataFragment on Node {
    ...PersonDataFragment
    ...DepartmentDataFragment
  }
  ${departmentDataFragment}
  ${personDataFragment}
`
