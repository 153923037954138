import { useState } from 'react'
import styled from 'styled-components'

import { ExportButton } from './export-button'
import { Button, FlexBox, OptionCard, SelectButton } from '../../generic'
import {
  DownloadIcon,
  ImageFileIcon,
  OrgchartCompactStructureIcon,
  OrgchartStandardStructureIcon,
  PdfIcon,
  TableIcon,
} from 'components/icons'

import { useChartData } from 'tree/providers'
import { ChartExportFileFormat, Node } from 'tree/types'
import { getIsSubscribed } from 'features/premium'
import { downloadFileFromBlob, getBlobFromUrl } from 'helpers'
import { colors, spaces } from 'theme'

type Props = {
  className?: string
  rootId?: Node['id']
  goToPremiumScreen: () => void
}

export const ExportChart = ({ className, rootId, goToPremiumScreen }: Props) => (
  <StyledFlexBox className={className} $isDirectionColumn>
    <OptionCard icon={<ImageFileIcon width='48px' color={colors.border} />} title='Download as PNG'>
      <ToggleContent format='png' rootId={rootId} goToPremiumScreen={goToPremiumScreen} />
    </OptionCard>

    <OptionCard icon={<PdfIcon width='64px' color='#FA0F00' />} title='Download as PDF'>
      <ToggleContent format='pdf' rootId={rootId} goToPremiumScreen={goToPremiumScreen} />
    </OptionCard>

    <OptionCard icon={<TableIcon color={colors.border} />} title='Download as CSV'>
      <ExportButton format='csv' rootId={rootId} goToPremiumScreen={goToPremiumScreen} />
    </OptionCard>
  </StyledFlexBox>
)

const StyledFlexBox = styled(FlexBox)`
  flex-wrap: wrap;
  justify-content: center;
  gap: ${props => props.theme.spaces.l};
`

type ToggleContentProps = {
  format: ChartExportFileFormat
  goToPremiumScreen: () => void
  rootId?: Node['id']
}
const ToggleContent = ({ format, rootId, goToPremiumScreen }: ToggleContentProps) => {
  const [isCompactView, setIsCompactView] = useState(true)
  const { subscription } = useChartData()
  const isSubscribed = getIsSubscribed(subscription)

  const downloadExportSample = async () => {
    const fileName = `export-sample-happy-company.${format}`
    const downloadUrl = `/${fileName}`
    const fileBlob = await getBlobFromUrl(downloadUrl)
    downloadFileFromBlob(fileBlob, fileName)
  }

  return (
    <Container>
      <FlexBox $gap='l' $isYCentered $spacing={{ px: spaces.l, ml: 'auto' }}>
        {isSubscribed ? (
          <>
            <StyledSelectButton checked={isCompactView} type='radio' onChange={() => setIsCompactView(true)}>
              <OrgchartCompactStructureIcon $spacing={{ ml: spaces.s }} />
              Compact
            </StyledSelectButton>

            <StyledSelectButton checked={!isCompactView} type='radio' onChange={() => setIsCompactView(false)}>
              <OrgchartStandardStructureIcon $spacing={{ ml: spaces.s }} />
              Standard
            </StyledSelectButton>
          </>
        ) : (
          <Button buttonType='tertiary' onClick={downloadExportSample}>
            <DownloadIcon $spacing={{ mr: spaces.m }} />
            Download sample
          </Button>
        )}
      </FlexBox>

      <ExportButton format={format} compact={isCompactView} rootId={rootId} goToPremiumScreen={goToPremiumScreen} />
    </Container>
  )
}

const Container = styled.div`
  flex: 1;
  display: flex;
  color: ${props => props.theme.colors.dark};

  @media (max-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    align-items: center;
    flex-direction: column;
    gap: ${props => props.theme.spaces.l};
    margin-top: ${props => props.theme.spaces.m};
  }
`

const StyledSelectButton = styled(SelectButton)`
  font-size: 13px;
  gap: ${props => props.theme.spaces.s};
`
