import { AboutFieldsDetail } from './about-fields-detail'
import { ConnectionFieldsDetail } from './connection-fields-detail'
import { ContactFieldsDetail } from './contact-fields-detail'
import { LocationFieldsDetail } from './location-fields-detail'
import { NodeActions, NodeContent, NodeHeader } from '../../node-components'

import { CustomConnectionFieldValue, PersonDetail } from 'tree/types'
import { useGoToEditEmployee } from 'routes'
import { findSuperiorPerson, findSuperiorDepartment } from 'helpers'

type Props = {
  person: PersonDetail
  onClose: () => void
  onDelete: () => void
}

export const EmployeeDetails = ({ person, onClose, onDelete }: Props) => {
  const superiorPerson = findSuperiorPerson(person)
  const superiorDepartment = findSuperiorDepartment(person)
  const { goToEditEmployee } = useGoToEditEmployee()

  return (
    <>
      <NodeHeader
        color={superiorDepartment?.color}
        title={person.name}
        departmentCount={person.departmentCount}
        employeeCount={person.employeeCount}
        description={person.position}
        image={person.image}
        onClose={onClose}
      />

      <NodeActions node={person} onDelete={onDelete} onEdit={() => goToEditEmployee(person.id)} />

      <NodeContent>
        <AboutFieldsDetail person={person} />
        <ConnectionFieldsDetail
          unassigned={person.unassigned}
          superiorDepartment={superiorDepartment}
          superiorPerson={superiorPerson}
          customConnectionFieldValue={
            person.customFields.filter(cf => cf.type === 'connection') as CustomConnectionFieldValue[]
          }
        />
        <ContactFieldsDetail person={person} />
        <LocationFieldsDetail person={person} />
      </NodeContent>
    </>
  )
}
