import { useEffect } from 'react'
import { useModal, ModalType, ModalElement } from './use-modal'

export const useRegister = <T extends ModalType>(name: T['name'], element: ModalElement<T>) => {
  const { register, unregister } = useModal()
  useEffect(() => {
    register<T>({ name, element })

    return () => {
      unregister<T>(name)
    }
  }, [register, unregister])
}
