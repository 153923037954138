import { gql } from '@apollo/client'

export const departmentDetailDataFragment = gql`
  fragment DepartmentDetailDataFragment on Department {
    id
    uuid
    capabilities {
      canUpdate
      canMove
      canDelete
      canUnassign
    }
    parentNodes {
      ... on Department {
        id
        uuid
        name
        color
        expanded @client
      }
      ... on Person {
        id
        uuid
        name
        expanded @client
      }
    }
    unassigned
    deleted
    parentUuid
    chartUuid
    name
    color
    description
    type
    childrenCount
    employeeCount
    departmentCount
    expanded @client
  }
`
