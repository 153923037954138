import styled from 'styled-components'

import { UpgradeSubscriptionButton } from './upgrade-subscription-button'
import { AlertBox, FlexBox, ListItem, UnsortedList } from 'components/generic'
import { GemIcon } from 'components/icons'

import { useResponsiveInfo } from 'providers'
import { colors, spaces } from 'theme'

const QUOTA_ITEMS = [
  'Generate an unlimited number of Org Charts',
  'Create Org Charts with up to 500 employees',
  'Export beautiful PDFs & PNGs',
  'Customize your Org Charts with unique labels',
]

export const QuotaProInfo = () => {
  const { isSmallDevice } = useResponsiveInfo()

  return (
    <StyledAlertBox
      type='warning'
      title={
        <FlexBox $isYCentered>
          <div>
            <GemIcon $spacing={{ mr: spaces.m }} />
          </div>
          Org Chart PRO: Unlock powerful workforce management planning
        </FlexBox>
      }
      ButtonComponent={<StyledUpgradeSubscriptionButton />}
    >
      <StyledUnsortedList $columns={isSmallDevice ? 1 : 2} $gap='m'>
        {QUOTA_ITEMS.map(item => (
          <ListItem key={item} iconColor={colors.yellow}>
            {item}
          </ListItem>
        ))}
      </StyledUnsortedList>
    </StyledAlertBox>
  )
}

const StyledAlertBox = styled(AlertBox)`
  grid-column: 1 / -1;
  margin-bottom: ${props => props.theme.spaces.l};
`

const StyledUpgradeSubscriptionButton = styled(UpgradeSubscriptionButton)`
  align-self: center;
  margin-top: ${props => props.theme.spaces.l};

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    margin-top: 0;
  }
`

const StyledUnsortedList = styled(UnsortedList)`
  margin-top: ${props => props.theme.spaces.l};
`
