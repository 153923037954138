import styled from 'styled-components'

import { adModalComponents } from './ad-modal-components'
import { ResponsiveCircle } from 'components/generic'

import { ModalComponentProps, ModalTypeFactory } from 'modal'
import { mainTheme } from 'theme'

import customFieldsPreviewOnSidePanel from 'assets/custom-fields-preview-on-side-panel.png'

const {
  StyledModal,
  Content,
  ChartPreview,
  CircleContainer,
  PreviewImage,
  TextContainer,
  Title,
  Description,
  SmallTitle,
  SmallDescription,
  StyledUpgradeSubscriptionButton,
} = adModalComponents

type Props = ModalComponentProps

export type CustomFieldsAdModalType = ModalTypeFactory<'customFieldsAdModal', Props>

export const CustomFieldsAdModal = ({ onClose }: ModalComponentProps) => (
  <StyledModal onClose={onClose}>
    <Content>
      <ChartPreview>
        <CircleContainer>
          <ResponsiveCircle $color={mainTheme.colors.purple} />
        </CircleContainer>
        <StyledPreviewImage src={customFieldsPreviewOnSidePanel} />
      </ChartPreview>

      <TextContainer $isDirectionColumn>
        <Title>Tailor Your Org Charts Like Never Before</Title>
        <Description>
          Dive into a world of customization with the premium feature: the ability to create your own fields.
        </Description>

        <SmallTitle>🧩 Fully Customizable Fields</SmallTitle>
        <SmallDescription>
          Create fields that fit your unique organizational needs. Whether it&apos;s employee skills, department
          budgets, or project statuses, tailor your charts to include the information that matters most to you.
        </SmallDescription>

        <SmallTitle>📊 Data-Rich Insights</SmallTitle>
        <SmallDescription>
          Enhance your organizational charts with rich, meaningful data. Go beyond names and titles - add depth to each
          node with custom data points.
        </SmallDescription>

        <StyledUpgradeSubscriptionButton />
      </TextContainer>
    </Content>
  </StyledModal>
)

const StyledPreviewImage = styled(PreviewImage)`
  max-width: 255px;
`
