import { CSSProperties, useEffect } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import moment from 'moment'
import { useApolloClient } from '@apollo/client'

import { Button, ButtonProps } from 'components/generic'

import {
  clientAppVersion as clientVersion,
  getItemFromLocalStorage,
  hardPageReload,
  setItemInLocalStorage,
} from 'helpers'
import analytics from 'analytics'
import { colors, mainTheme, spaces } from 'theme'
import globalConfig from 'config'

import { getApp as getAppQuery } from 'apollo/query'
import { AppQuery, AppQueryVariables } from 'apollo/generated/graphql'

const LOCAL_STORAGE_KEY_NAME = 'lastAppVersionNotificationDate'

export const useInitAppVersionCheck = () => {
  const apolloClient = useApolloClient()
  const getWebVersion = async () => {
    return apolloClient.query<AppQuery, AppQueryVariables>({
      query: getAppQuery,
      fetchPolicy: 'network-only',
    })
  }

  const getIsNewestVersion = async () => {
    const { data } = await getWebVersion()
    const webVersion = data.app?.version.web
    if (!webVersion || !clientVersion) return true
    return webVersion === clientVersion
  }

  const showRefreshToast = () => {
    setItemInLocalStorage(LOCAL_STORAGE_KEY_NAME, new Date().toISOString())
    toast(<AlertToast />, {
      autoClose: false,
      closeOnClick: false,
      style: alertToastStyle,
    })
  }

  const getHasNotifiedToday = () => {
    const lastNotificationDate = getItemFromLocalStorage(LOCAL_STORAGE_KEY_NAME)
    if (!lastNotificationDate) {
      return false
    }
    return moment(new Date()).isSame(lastNotificationDate, 'day')
  }

  const checkVersion = async () => {
    const hasNotifiedToday = getHasNotifiedToday()
    if (hasNotifiedToday) {
      return
    }
    const isNewest = await getIsNewestVersion()
    if (!isNewest) showRefreshToast()
  }

  useEffect(() => {
    if (globalConfig.VERSION_CHECK_ENABLED) {
      const timeoutId = setTimeout(checkVersion, 120000)
      const intervalId = setInterval(checkVersion, 1200000)

      return () => {
        clearTimeout(timeoutId)
        clearInterval(intervalId)
      }
    }
  }, [])

  useEffect(() => {
    analytics.setUserProperties({ clientAppVersion: clientVersion })
  }, [])
}

const AlertToast = () => (
  <Container>
    A new version of the Org Chart is available.
    <StyledButton
      // theme is not injected due to react-toastify on first render and app crashes trying to access undefined values
      theme={mainTheme}
      buttonType='primary'
      spacing={{ mx: spaces.m }}
      onClick={hardPageReload}
    >
      Refresh
    </StyledButton>
  </Container>
)

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: default;
`

// if styled(Button) is used: TypeError: Cannot read properties of undefined (reading 'Button')
const StyledButton = styled((props: ButtonProps) => <Button {...props} />)`
  && {
    background: ${colors.yellow};
    color: ${colors.dark};

    &:hover {
      background: ${colors.yellow};
      opacity: 0.9;
    }
  }
`

const alertToastStyle: CSSProperties = {
  border: `1px solid ${colors.yellow}`,
  background: '#fcf7e0',
  color: '#856404',
}
