import { ChangeEvent } from 'react'
import styled, { css } from 'styled-components'

export type RangeInputProps = {
  name: string
  min: number
  max: number
  step: number
  defaultValue: number
  onChange?: (e: ChangeEvent) => void
}

type RangeInputType = {
  className?: string
} & RangeInputProps

export const RangeInput = ({ defaultValue, ...rest }: RangeInputType) => {
  return <RangeInputWrap type='range' defaultValue={defaultValue.toString()} {...rest} />
}

const RangeInputReset = css`
  width: 100%;
  -webkit-appearance: none; /* Hides the original slider */
  background: transparent; /* Otherwise white in Chrome */

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  &::-ms-track {
    width: 100%;
    background: transparent;
    border-color: transparent;
    color: transparent;
    cursor: pointer;
  }

  &:focus {
    outline: none; /* Removes the blue border. */
  }
`

const thumbCSS = css`
  width: 18px;
  height: 18px;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 1px 4px rgba(39, 40, 51, 0.2);
  outline: none;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 50%;
  cursor: pointer;
`

const RangeInputWrap = styled.input`
  ${RangeInputReset}
  position: relative;
  height: 6px;
  margin: 0;
  background: transparent;
  z-index: 10;

  /* THUMB */
  /* WebKit/Blink */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    margin-top: -6px; /* Specify margin in Chrome (in Firefox and IE it's automatic) */
    ${thumbCSS}
  }
  /* Firefox */
  &::-moz-range-thumb {
    ${thumbCSS}
  }
  /* IE */
  &::-ms-thumb {
    ${thumbCSS}
  }

  /* TRACK */
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: transparent;
    border-radius: 2px;
  }
  /* Firefox */
  &::-moz-range-progress {
    background: transparent;
    border-radius: 2px;
  }
  &::-moz-range-track {
    background: transparent;
    border-radius: 2px;
  }
  /* IE */
  &::-ms-track {
    width: 100%;
    height: 8.4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }
  /* Reset fills for IE */
  &::-ms-fill-lower {
    background: transparent;
    border-radius: 2px;
  }
  &::-ms-fill-upper {
    background: transparent;
    border-radius: 2px;
  }
`
