import styled from 'styled-components'
import { parseLabel } from '../../utils'

type Props = {
  value: string
}

export const Path = styled.div`
  font-size: 80%;
`

export const Label = ({ value }: Props) => {
  const parsedLabel = parseLabel(value)
  const name = parsedLabel.getName()
  const path = parsedLabel.getPath()

  return (
    <div>
      {name}
      {path.length > 0 && <Path>({path})</Path>}
    </div>
  )
}
