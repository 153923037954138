import { getNextDayMidnight } from './get-next-day-midnight'
import { getTimeRemaining } from './get-time-remaining'
import { HOUR_IN_MS, MINUTE_IN_MS, SECOND_IN_MS } from './time-in-ms'
import { TimeUnit } from 'types'

type NextTimeUnit = Extract<TimeUnit, 'day' | 'hour' | 'minute' | 'second'>

export const getNextTimeUnitStart = (timeUnit: NextTimeUnit): number | null => {
  const nextDayMidnight = getNextDayMidnight(new Date())

  if (!nextDayMidnight) {
    return null
  }

  const timeRemainingTillNextDayMidnight = getTimeRemaining({ to: nextDayMidnight })

  if (!timeRemainingTillNextDayMidnight) {
    return null
  }

  const { hours, minutes, seconds, millisecond } = timeRemainingTillNextDayMidnight

  switch (timeUnit) {
    case 'second':
      return millisecond
    case 'minute':
      return millisecond + seconds * SECOND_IN_MS
    case 'hour':
      return millisecond + seconds * SECOND_IN_MS + minutes * MINUTE_IN_MS
    case 'day':
      return millisecond + seconds * SECOND_IN_MS + minutes * MINUTE_IN_MS + hours * HOUR_IN_MS
  }
}
