import { useState, useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import { useDebounce } from 'use-debounce'

import { handleErrorValidation } from 'helpers'
import { ERRORS_TRACK } from 'analytics/constants'

import { searchNodes as searchNodesQuery } from 'apollo/query'
import {
  SearchNodesFilter,
  NodeSearchDataFragmentFragment,
  SearchNodesQuery,
  SearchNodesQueryVariables,
} from 'apollo/generated/graphql'

type CustomFilter = {
  /** Filters node and all of it's children */
  deepExclude?: boolean
  /** Filters only single node (children are not filtered) */
  excludeId?: string
}

export type UseFullTextSearchParams = {
  chartUuid: string
  minLength?: number
  debounceTime?: number
  filter?: Omit<SearchNodesFilter, 'q' | 'excludeUuid'> & CustomFilter
}

export type NodeSearchResult = {
  data: NodeSearchDataFragmentFragment[]
  error: Error | null
  isSearching: boolean
}

const INIT_QUERY_DATA: NodeSearchResult = {
  data: [],
  error: null,
  isSearching: false,
}

export const useFullTextSearch = (parameters: UseFullTextSearchParams) => {
  const { chartUuid, minLength = 2, debounceTime = 300, filter = {} } = parameters

  const client = useApolloClient()
  const [value, setValue] = useState('')
  const [debouncedValue] = useDebounce(value, debounceTime)
  const [queryData, setQueryData] = useState<NodeSearchResult>({ ...INIT_QUERY_DATA })

  useEffect(() => {
    if (value === debouncedValue && debouncedValue.length >= minLength) querySearch(debouncedValue)
  }, [value, debouncedValue])

  const search = (value: string) => {
    setValue(value)
    if (value.length < minLength) {
      setQueryData({ ...INIT_QUERY_DATA })
    } else if (!queryData.isSearching) {
      setQueryData({ ...INIT_QUERY_DATA, isSearching: true })
    }
  }

  const querySearch = (value: string) => {
    if (chartUuid) {
      const { excludeId, deepExclude, ...restFilter } = filter
      const queryFilter: SearchNodesFilter = {
        q: value,
        // Filter node and it's children
        excludeUuid: deepExclude ? excludeId : undefined,
        ...restFilter,
      }

      client
        .query<SearchNodesQuery, SearchNodesQueryVariables>({
          query: searchNodesQuery,
          fetchPolicy: 'no-cache',
          variables: { chartKey: chartUuid, filter: queryFilter },
        })
        .then(({ data }) => {
          if (data?.searchNodes?.items) {
            let newData = [...data.searchNodes.items]

            // Filter single node
            if (excludeId && !deepExclude) {
              newData = newData.filter(node => node.id !== excludeId)
            }

            setQueryData({ data: newData, error: null, isSearching: false })
          }
        })
        .catch(({ error }) => {
          handleErrorValidation({
            track: { message: ERRORS_TRACK.fullTextSearchFailure, values: { chartUuid, error } },
            toast: { message: 'Full text search failed' },
          })
          setQueryData({ data: [], error, isSearching: false })
        })
    } else {
      handleErrorValidation({
        track: {
          message: ERRORS_TRACK.fullTextSearchFailure,
          values: { chartUuid, error: new Error('chartUuid is missing') },
        },
        toast: { message: 'Full text search failed' },
      })
      setQueryData({ ...queryData, isSearching: false, error: new Error('chartUuid is missing') })
    }
  }

  return {
    search,
    data: queryData.data,
    error: queryData.error,
    isSearching: queryData.isSearching,
  }
}
