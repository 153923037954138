import { gql } from '@apollo/client'

export const insightDatasetSingleFragment = gql`
  fragment InsightDatasetSingleFragment on InsightDatasetSingle {
    id
    values {
      f {
        dep
      }
      v
      l
    }
  }
`

export const insightDatasetMultiFragment = gql`
  fragment InsightDatasetMultiFragment on InsightDatasetMulti {
    id
    values {
      f {
        dep
      }
      d {
        v
        l
      }
    }
  }
`

export const insightDatasetSpanOfControlFragment = gql`
  fragment InsightDatasetSpanOfControlFragment on InsightDatasetSpanOfControl {
    id
    values {
      f {
        dep
      }
      uuid
      image
      givenName
      familyName
      department {
        uuid
        name
      }
      subordinatesCount
    }
  }
`

export const insightDatasetSociomapFragment = gql`
  fragment InsightDatasetSociomapFragment on InsightDatasetSociomap {
    id
    demo
    values {
      f {
        dep
      }
      x
      y
      z
      n
    }
  }
`

export const insightFragment = gql`
  fragment InsightFragment on Insight {
    id
    uuid
    chartUuid
    status
    dateFrom
    dateTo
    departments {
      uuid
      name
    }
    datasets {
      ...InsightDatasetSingleFragment
      ...InsightDatasetMultiFragment
      ...InsightDatasetSpanOfControlFragment
      ...InsightDatasetSociomapFragment
    }
  }
  ${insightDatasetSingleFragment}
  ${insightDatasetMultiFragment}
  ${insightDatasetSpanOfControlFragment}
  ${insightDatasetSociomapFragment}
`
