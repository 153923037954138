import styled from 'styled-components'

export const ChartButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 27px;
  top: 24px;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    top: ${props => props.theme.spaces.l};
    right: ${props => props.theme.spaces.l};
    width: 130px;
  }
`
