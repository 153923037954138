import { Dispatch, ReactNode, SetStateAction, useState } from 'react'
import { createSafeContext } from 'helpers'

type LoadingOverTreeResult = {
  showLoadingOverTree: boolean
  setShowLoadingOverTree: Dispatch<SetStateAction<boolean>>
}
export const [useLoadingOverTree, LoadingOverTreeContextSafeProvider] = createSafeContext<LoadingOverTreeResult>()

type Props = {
  children: ReactNode
}

export const LoadingOverTreeProvider = ({ children }: Props) => {
  const [showLoadingOverTree, setShowLoadingOverTree] = useState(false)

  return (
    <LoadingOverTreeContextSafeProvider value={{ showLoadingOverTree, setShowLoadingOverTree }}>
      {children}
    </LoadingOverTreeContextSafeProvider>
  )
}
