import { chart as chartQuery, getPublicLinks } from 'apollo/query'
import { Chart, useCreatePublicLinkMutation } from 'apollo/generated/graphql'

type CreatePublicLinkRequestParams = {
  chartId: Chart['id']
}

export const useCreatePublicLinkRequest = () => {
  const [createPublicLinkMutation] = useCreatePublicLinkMutation()

  const createPublicLinkRequest = async ({ chartId: chartUuid }: CreatePublicLinkRequestParams) => {
    return createPublicLinkMutation({
      variables: { chartUuid, createPublicLinkInput: { domains: [] } },
      refetchQueries: [
        { query: getPublicLinks, variables: { chartUuid } },
        { query: chartQuery, variables: { key: chartUuid } },
      ],
    })
  }

  return { createPublicLinkRequest }
}
