import styled from 'styled-components'

import { spaces } from 'theme'

export type UnsortedListProps = {
  $gap?: keyof typeof spaces
  $columns?: number
}

export const UnsortedList = styled.ul<UnsortedListProps>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(${props => props.$columns || 1}, minmax(0, 1fr));
  ${props => props.$gap && `gap: ${props.theme.spaces[props.$gap]};`}
  text-align: left;
`
