import { SVG, SVGProps } from './SVG'

export const SortDescendingIcon = (props: SVGProps) => (
  <SVG width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <path
      d='M3.33366 4.66667L1.33366 4.66667L4.00032 2L6.66699 4.66667L4.66699 4.66667L4.66699 14L3.33366 14L3.33366 4.66667ZM14.667 4.66667L8.00032 4.66667L8.00032 3.33333L14.667 3.33333L14.667 4.66667ZM12.0003 12.6667L12.0003 11.3333L14.667 11.3333L14.667 12.6667L12.0003 12.6667ZM14.667 8.66667L10.0003 8.66667L10.0003 7.33333L14.667 7.33333L14.667 8.66667Z'
      fill='currentColor'
    />
  </SVG>
)
