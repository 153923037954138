import { UpgradeSubscriptionButton } from './upgrade-subscription-button'
import { AlertBar, FlexBox, ListItem } from 'components/generic'

import { getChartLimit } from './helpers'
import { CheckQuotaResult } from './types'
import { capitalize } from 'helpers'
import { useResponsiveInfo } from 'providers'
import { colors, spaces } from 'theme'

type Props = {
  quotaResults: CheckQuotaResult[]
}

export const QuotaBarExceeded = ({ quotaResults }: Props) => {
  const { isBigDevice } = useResponsiveInfo()

  const quotaWarningResults = quotaResults.filter(q => q.status === 'warning')
  const chartLimits = quotaWarningResults.map(getChartLimit)

  return (
    <AlertBar
      type='error'
      size='small'
      title='You have exceeded the limits of the free version'
      ButtonComponent={<UpgradeSubscriptionButton buttonSize='small' />}
      includeIcon
    >
      {isBigDevice && (
        <FlexBox $gap='m' $spacing={{ ml: spaces.l }}>
          {chartLimits.map(limit => (
            <ListItem key={limit} iconColor={colors.red}>
              {capitalize(limit)}
            </ListItem>
          ))}
        </FlexBox>
      )}
    </AlertBar>
  )
}
