import { CustomFieldPreview } from '../../components'
import { Card } from '../../../components'

import { AlertBar, FlexBox } from 'components/generic'

import { CustomFieldOption } from 'tree/types'
import { spaces } from 'theme'

type Props = {
  selectedFieldOption: CustomFieldOption
}

export const EditType = ({ selectedFieldOption }: Props) => {
  const { icon, background, typeLabel, description } = selectedFieldOption

  return (
    <Card title='Field type'>
      <CustomFieldPreview icon={icon} squareColor={background} title={typeLabel} description={description} />
      <FlexBox $spacing={{ mt: spaces.l }}>
        <AlertBar type='warning'>
          The type of this custom field cannot be changed, as other types are not compatible with this type.
        </AlertBar>
      </FlexBox>
    </Card>
  )
}
