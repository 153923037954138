import { useState } from 'react'
import { generatePath } from 'react-router-dom'
import styled from 'styled-components'

import { DangerZoneCard, DuplicationCard, ImportEmployeesCard } from './components'
import { View, ViewHeader, ViewTitle } from '../components'
import { Card, DeletionCard } from '../../components'
import { parseErrors } from '../../../create-chart/actions'
import { ErorrHandler } from '../../../create-chart/error-handler'
import { Error } from '../../../create-chart/types'
import { ChartForm } from 'components/forms'
import { Button, FieldEmojiPicker, FieldInput, FieldLogoUpload } from 'components/generic'

import { useCloneChartAction, useDeleteChartAction, useUpdateChartAction } from 'tree/actions'
import { useChartData } from 'tree/providers'
import { useChartPermission } from 'tree/hooks'
import { ChartFormValues } from 'tree/types'
import { useCsvUpload } from 'hooks'
import { BASE_URL, CHART_EDIT_PATH } from 'routes'
import { spaces } from 'theme'

import {
  useRevokeChartTokenMutation,
  useSyncChartMutation,
  useSyncChartWithFileMutation,
} from 'apollo/generated/graphql'

// TODO: implement "GoogleWorkspaceSync" into "General" settings view
// if getCanShowGeneralView
export const General = () => {
  const { uuid, name, emoji, logo, providerInfo, capabilities } = useChartData()
  const { canDelete, canUpdate, canClone, canSync, canRevokeToken } = useChartPermission()
  const initialData: ChartFormValues = { name, emoji, logo }

  const canImportEmployeeData = Boolean(capabilities?.canCreatePerson && providerInfo?.name === 'database')
  const { file, setFile, getRootProps } = useCsvUpload()
  const [importEmployeeDataLoading, setImportEmployeeDataLoading] = useState(false)
  const [importEmployeeDataError, setImportEmployeeDataError] = useState<Error>({ type: undefined })

  const [syncChartWithFileMutation] = useSyncChartWithFileMutation()
  const [revokeChartTokenMutation] = useRevokeChartTokenMutation({ variables: { uuid } })
  const [syncChartMutation] = useSyncChartMutation({ variables: { uuid } })
  const { updateChartAction } = useUpdateChartAction()
  const { cloneChartWithConfirm } = useCloneChartAction()
  const { deleteChartWithConfirm } = useDeleteChartAction()

  return (
    <View>
      {importEmployeeDataError?.type && (
        <ErorrHandler
          error={importEmployeeDataError}
          handleResetComponent={() => setImportEmployeeDataError({ type: undefined })}
        />
      )}
      <ViewHeader>
        <ViewTitle>General</ViewTitle>
      </ViewHeader>
      <Card title='Name, emoji and logo'>
        <ChartForm
          onSubmit={({ actions, values }) =>
            updateChartAction({ actions, values, chartId: uuid }).then(() => actions.setSubmitting(false))
          }
          initialData={initialData}
        >
          {({
            values,
            dirty,
            isValid,
            isSubmitting,
            handleBlur,
            handleChange,
            setFieldValue,
            submitForm,
            resetForm,
          }) => {
            const isFormActionDisabled = isSubmitting || !dirty || !canUpdate
            return (
              <>
                <FieldLogoUpload setFieldValue={setFieldValue} />

                <FieldInput
                  title={{ title: 'Name', size: 'big', required: true }}
                  name='name'
                  type='text'
                  placeholder='e.g. Unaric, London Office'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  initialValue={initialData.name || ''}
                  value={values.name || ''}
                  disabled={!canUpdate}
                  onClear={() => setFieldValue('name', '')}
                />

                <FieldEmojiPicker
                  title='Emoji'
                  value={values.emoji || ''}
                  disabled={!canUpdate}
                  onSelect={value => setFieldValue('emoji', value)}
                />

                <ButtonsContainer>
                  <Button
                    buttonType='tertiary'
                    disabled={isFormActionDisabled}
                    spacing={{ mr: spaces.l }}
                    onClick={() => resetForm()}
                    data-testid='button-name-and-emoji-form-cancel'
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isFormActionDisabled || !isValid}
                    onClick={submitForm}
                    data-testid='button-name-and-emoji-form-save'
                  >
                    Save
                  </Button>
                </ButtonsContainer>
              </>
            )
          }}
        </ChartForm>
      </Card>
      <DuplicationCard
        title='Duplicate Org Chart'
        description='This action will copy your existing Org Chart. Once copied, you will find your new Org Chart on your dashboard.'
        deleteButtonText='Duplicate Org Chart'
        onDuplicate={() => cloneChartWithConfirm({ chartId: uuid, chartName: name, providerInfo })}
        data-testid='button-duplicate-chart'
        disabled={Boolean(!canClone)}
      />
      {canImportEmployeeData && (
        <ImportEmployeesCard
          file={file}
          isLoading={importEmployeeDataLoading}
          labelProps={getRootProps()}
          onDelete={() => setFile(undefined)}
          onImport={file => {
            setImportEmployeeDataLoading(true)
            syncChartWithFileMutation({ variables: { uuid, data: { file } } })
              .then(() => {
                // Redirect to the chart detail and refresh
                const path = generatePath(CHART_EDIT_PATH, { chartUuid: uuid })
                const url = `${BASE_URL}${path}`
                window.location.href = url
              })
              .catch(err => {
                const error = parseErrors(err)
                setImportEmployeeDataError(error)
              })
              .finally(() => {
                setFile(undefined)
                setImportEmployeeDataLoading(false)
              })
          }}
        />
      )}
      <DeletionCard
        title='Delete Org Chart'
        description='This action will permanently delete your Org Chart. This cannot be undone.'
        deleteButtonText='Delete Org Chart'
        onDelete={() => deleteChartWithConfirm({ chartId: uuid, chartName: name })}
        data-testid='button-delete-chart'
        disabled={Boolean(!canDelete)}
      />
      <DangerZoneCard
        onRevokeToken={revokeChartTokenMutation}
        onSync={syncChartMutation}
        canSync={canSync}
        canRevokeToken={canRevokeToken}
      />
    </View>
  )
}

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
