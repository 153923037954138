import { useGetCollaborators } from 'components/forms'
import { PendingCollaborator } from 'providers'

export const useGetCollaboratorUuid = (chartUuid: string) => {
  const collaborators = useGetCollaborators(chartUuid)

  const getCollaboratorUuid = (collaboratorEmail: PendingCollaborator['email']) =>
    collaborators.find(collab => collab.email === collaboratorEmail)?.uuid

  return getCollaboratorUuid
}
