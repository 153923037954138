import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { LinkStyles } from '../link'

import { ArrowIcon } from 'components/icons'

type Props = {
  title: string
  children: ReactNode
  className?: string
  side?: 'left' | 'right'
  fullWidthLinks?: boolean
  onGoBack?: () => void
}

export const SidePanel = ({ className, title, children, fullWidthLinks, onGoBack }: Props) => {
  return (
    <Navigation className={className}>
      <Container fullWidthLinks={fullWidthLinks}>
        <Heading fullWidthLinks={fullWidthLinks}>
          <Title>{title}</Title>
          {onGoBack && (
            <GoBack onClick={onGoBack}>
              <StyledArrowIcon />
              Back
            </GoBack>
          )}
        </Heading>

        <Content>{children}</Content>
      </Container>
    </Navigation>
  )
}

const Navigation = styled.nav`
  width: 100px;
  max-width: calc(100vw - 220px);
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  box-sizing: border-box;
  border-right: 1px solid ${props => props.theme.colors.greyExtraLight};

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    width: 186px;
  }
  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    width: 256px;
  }
`

const Container = styled.div<{ fullWidthLinks?: boolean }>`
  width: inherit;
  max-height: calc(100vh - ${props => props.theme.sizes.topNavigation});
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.xl} ${props => (props.fullWidthLinks ? 0 : props.theme.spaces.m)};
  padding-right: 0;
  overflow: auto;
`

const Heading = styled.div<{ fullWidthLinks?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  ${props =>
    props.fullWidthLinks &&
    css`
      padding-left: 8px;
    `}
`

const Title = styled.div`
  margin-left: ${props => props.theme.spaces.m};
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`

const GoBack = styled.div`
  ${LinkStyles}

  align-items: center;
  margin-left: ${props => props.theme.spaces.m};
  margin-right: ${props => props.theme.spaces.l};
  font-size: 13px;
  line-height: 16px;
  white-space: nowrap;
`

const StyledArrowIcon = styled(ArrowIcon)`
  margin-right: ${props => props.theme.spaces.s};
  margin-left: ${props => props.theme.spaces.xs};
`

const Content = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
