import { useEffect } from 'react'
import styled, { css } from 'styled-components'

import { GenericOnErrorModalType, GsuiteInsuffPermModal, GsuiteInsuffPermModalType } from 'components/specific'

import { CreateChartOptionType, Error } from './types'
import { useModal, useRegister } from 'modal'

type Props = {
  error: Error
  handleResetComponent: (optionType: CreateChartOptionType) => void
}

export const ErorrHandler = ({ error, handleResetComponent }: Props) => {
  const { open } = useModal()
  useRegister<GsuiteInsuffPermModalType>('gWorkspaceInsuffPermModal', GsuiteInsuffPermModal)

  useEffect(() => {
    if (error.type === 'insufficient-permission') {
      open<GsuiteInsuffPermModalType>('gWorkspaceInsuffPermModal', {
        onClose: () => handleResetComponent('gsuite'),
      })
    }

    if (error.type === 'import-cycle-detected') {
      const path = error?.data?.path as unknown as string[]

      open<GenericOnErrorModalType>('genericOnErrorModal', {
        title: 'Data conflict',
        children:
          path && Array.isArray(path) ? (
            <>
              <InfoHeading>Your chart contains a cycle hierarchy problem with the following members:</InfoHeading>
              {path.map((email, i) => (
                <>
                  <Email $highlight={i === 0 || i === path.length - 1} key={`cycle-email-${i}`}>
                    {` ${email}`}
                  </Email>
                  {i < path.length - 1 && ' ➜ '}
                </>
              ))}
              <br />
              <InfoFooter>Please remove the cycle and try it again.</InfoFooter>
            </>
          ) : (
            <>
              Your chart contains a cycle hierarchy problem <div>Please remove the cycle and try it again.</div>
            </>
          ),
        onClose: () => handleResetComponent('file'),
      })
    }

    if (error.type === 'unknown-error') {
      open<GenericOnErrorModalType>('genericOnErrorModal', {
        title: 'An error has occurred',
        children: 'Please try again and if the problem persists, contact support.',
        onClose: () => handleResetComponent('gsuite'),
      })
    }

    if (error.type === 'csv-error') {
      open<GenericOnErrorModalType>('genericOnErrorModal', {
        title: 'File incompatible',
        children: 'This file is incompatible. Please upload a file in .CSV format to import contacts.',
        onClose: () => handleResetComponent('file'),
      })
    }

    if (error.type === 'csv-validation-error') {
      const messages = (error.data?.messages as string[]) || []
      open<GenericOnErrorModalType>('genericOnErrorModal', {
        title: 'Validation Error',
        children: (
          <ul>
            {messages.map((m: string) => (
              <li key={m}>{m}</li>
            ))}
          </ul>
        ),
        onClose: () => handleResetComponent('file'),
      })
    }
  }, [])

  return null
}

const InfoHeading = styled.div`
  margin-bottom: 16px;
`

const Email = styled.span<{ $highlight?: boolean }>`
  ${({ $highlight }) =>
    $highlight &&
    css`
      color: purple;
    `}
`
const InfoFooter = styled.div`
  margin-top: 16px;
`
