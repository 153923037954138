import { SVG, SVGProps } from './SVG'

export const TrashCanIcon = (props: SVGProps) => (
  <SVG width='12' height='14' viewBox='0 0 12 14' fill='none' {...props}>
    <path
      d='M1.5 12.25C1.5 13.075 2.175 13.75 3 13.75H9C9.825 13.75 10.5 13.075 10.5 12.25V3.25H1.5V12.25ZM3 4.75H9V12.25H3V4.75ZM8.625 1L7.875 0.25H4.125L3.375 1H0.75V2.5H11.25V1H8.625Z'
      fill='currentColor'
    />
  </SVG>
)
