import TagManager from 'react-gtm-module'
import { payloadMapperFactory } from 'helpers'

const payloadMapper = payloadMapperFactory({ maxStrLength: 100, maxStrGroupCount: 5 })

export const init = (gtmId: string) => {
  const gtmConfig = {
    gtmId,
  }

  TagManager.initialize(gtmConfig)
}

export const track = (name: string, properties: Record<string, unknown> = {}) => {
  const payload = payloadMapper(properties)
  TagManager.dataLayer({
    dataLayer: {
      event: name,
      ...payload,
    },
  })
}

export default {
  init,
  track,
}
