import { WITHOUT_DEPARTMENT_LABEL, WITHOUT_DEPARTMENT_KEY } from '../constants'

const LABEL_DELIMITER = '\x1C'
const DEFAULT_PATH_SIZE = 2

export const parseLabel = (label = '') => {
  const parts = label
    .split(LABEL_DELIMITER)
    .map(part => part.trim().replace(WITHOUT_DEPARTMENT_KEY, WITHOUT_DEPARTMENT_LABEL))
    .filter(part => Boolean(part))

  const [name, ...path] = parts

  const getName = () => name
  const getParts = () => parts
  const getPath = (size = DEFAULT_PATH_SIZE) => path.splice(0, size).reverse().join(' > ')
  const toString = (size = DEFAULT_PATH_SIZE) => `${name} ${path.length > 0 ? ` (${getPath(size)})` : ''}`

  return {
    getName,
    getPath,
    getParts,
    toString,
  }
}
