import { SetStateAction, useEffect, useRef, useState } from 'react'

type Callback<T> = (value?: T) => void
type DispatchWithCallback<T> = (value: T, callback?: Callback<T>) => void

export function useStateCallback<T>(initialState: T | (() => T)): [T, DispatchWithCallback<SetStateAction<T>>] {
  const [state, setState] = useState(initialState)
  // value doesn't matter, it's only purpose is to trigger useEffect callback when you setState with same value
  const [registerCallToggle, setRegisterCallToggle] = useState(false)
  const callbackRef = useRef<Callback<T>>()
  const isFirstCallbackCall = useRef(true)

  const handleSetState = (setStateAction: SetStateAction<T>, callback?: Callback<T>) => {
    callbackRef.current = callback
    setState(setStateAction)
    setRegisterCallToggle(!registerCallToggle)
  }

  useEffect(() => {
    if (isFirstCallbackCall.current) {
      isFirstCallbackCall.current = false
      return
    }
    callbackRef.current?.(state)
  }, [state, registerCallToggle])

  return [state, handleSetState]
}
