import styled from 'styled-components'
import { useDrag } from 'react-dnd'

import { RowNode } from 'components/specific/row-node'
import { ImageWithFallback } from 'components/generic/image-with-fallback' // because of cyclic import

import { useGoToDepartmentDetail, useGoToEmployeeDetail } from 'routes'
import { getNodeColorPallete } from 'helpers'
import { spaces } from 'theme'

import { NodeSearchDataFragmentFragment } from 'apollo/generated/graphql'

export const DirectoryNode = (node: NodeSearchDataFragmentFragment) => {
  const { uuid, chartUuid, capabilities, __typename } = node

  const canDrag = Boolean(capabilities?.canMove)
  const [_, dragRef] = useDrag({
    item: { __typename, chartUuid, id: uuid, parentUuid: null },
    collect: monitor => ({ canDrag: monitor.canDrag() }),
    canDrag,
    type: __typename,
  })
  const draggableRef = canDrag ? dragRef : undefined

  const { goToEmployeeDetail } = useGoToEmployeeDetail()
  const { goToDepartmentDetail } = useGoToDepartmentDetail()

  if (node.__typename === 'Person') {
    const { image, name, position } = node
    return (
      <DirectoryNodeInner onClick={() => goToEmployeeDetail(uuid)} draggable={canDrag} ref={draggableRef}>
        <RowNode title={name} description={position} icon={<ImageWithFallback src={image} />} isDraggable={canDrag} />
      </DirectoryNodeInner>
    )
  }

  if (node.__typename === 'Department') {
    const { name, color } = node
    const colorPallete = getNodeColorPallete(color)
    return (
      <DirectoryNodeInner onClick={() => goToDepartmentDetail(uuid)} draggable={canDrag} ref={draggableRef}>
        <RowNode
          title={name}
          nodeColorPallete={colorPallete}
          draggableRef={draggableRef}
          spacing={{ pr: spaces.m }}
          isDraggable={canDrag}
        />
      </DirectoryNodeInner>
    )
  }

  return null
}

const DirectoryNodeInner = styled.div`
  position: relative;
  min-height: 50px;
  display: flex;
  box-sizing: border-box;
  margin-top: ${props => props.theme.spaces.m};
  margin-right: ${props => props.theme.spaces.m};
  box-shadow: 0px 2px 6px rgba(69, 90, 100, 0.24);
  font-weight: 500;
  cursor: pointer;
`
