import { getPersonNormalizedId, getDepartmentNormalizedId } from '../../helpers'
import { ApolloCache, ApolloClient, NormalizedCacheObject } from '@apollo/client'
import {
  departmentDataFragment,
  expandedDepartmentFragment,
  expandedPersonFragment,
  personDataFragment,
} from '../../fragments'
import { showUnassignedNodesSidebarQuery } from '../../query'
import {
  DepartmentDataFragmentFragment,
  ExpandedDepartmentFragmentFragment,
  ExpandedPersonFragmentFragment,
  PersonDataFragmentFragment,
} from '../../generated/graphql'

const getNodeAndFragment = (id: string, cache: ApolloCache<NormalizedCacheObject>) => {
  const person = cache.readFragment<PersonDataFragmentFragment>({
    id: getPersonNormalizedId(id),
    fragment: personDataFragment,
  })
  if (person?.id) return { node: person, fragment: expandedPersonFragment }

  const department = cache.readFragment<DepartmentDataFragmentFragment>({
    id: getDepartmentNormalizedId(id),
    fragment: departmentDataFragment,
  })
  if (department?.id) return { node: department, fragment: expandedDepartmentFragment }

  return null
}

export const resolvers = {
  Person: {
    expanded: (
      incPerson: Partial<PersonDataFragmentFragment>,
      _: unknown,
      { cache }: ApolloClient<NormalizedCacheObject>
    ) => {
      const person = cache.readFragment<ExpandedPersonFragmentFragment>({
        id: cache.identify(incPerson),
        fragment: expandedPersonFragment,
      })
      return person?.expanded ?? false
    },
  },

  Department: {
    expanded: (
      incDepartment: Partial<DepartmentDataFragmentFragment>,
      _: unknown,
      { cache }: ApolloClient<NormalizedCacheObject>
    ) => {
      const department = cache.readFragment<ExpandedDepartmentFragmentFragment>({
        id: cache.identify(incDepartment),
        fragment: expandedDepartmentFragment,
      })
      return department?.expanded ?? false
    },
  },

  Mutation: {
    setShowUnassignedNodesSidebar: (
      _: unknown,
      { showUnassignedNodesSidebar }: { showUnassignedNodesSidebar: boolean },
      { cache }: ApolloClient<NormalizedCacheObject>
    ) => {
      cache.writeQuery({ query: showUnassignedNodesSidebarQuery, data: { showUnassignedNodesSidebar } })
    },
    toggleSubtree: (
      _: unknown,
      { id, expanded }: { id: string; expanded: boolean },
      { cache }: ApolloClient<NormalizedCacheObject>
    ) => {
      const nodeAndFragment = getNodeAndFragment(id, cache)
      if (nodeAndFragment) {
        const { node, fragment } = nodeAndFragment
        const { employeeCount, departmentCount } = node
        const hasChildren = employeeCount + departmentCount > 0
        const newExpanded = hasChildren && expanded
        cache.writeFragment({ id: cache.identify(node), fragment, data: { ...node, expanded: newExpanded } })
      }
    },
  },
}
