import { getItemFromLocalStorage, setItemInLocalStorage, deleteItemFromLocalStorage } from 'helpers'

export const CHART_SIDE_PANEL_DETAIL_KEY = 'chartSidePanelDetail'

export type ChartSidePanelDetailStorage =
  | {
      isAboutExpanded?: boolean
      isConnectionsExpanded?: boolean
      isContactsExpanded?: boolean
      isLocationsExpanded?: boolean
    }
  | undefined
  | null

const INIT_STATE: ChartSidePanelDetailStorage = {
  isAboutExpanded: true,
  isConnectionsExpanded: true,
  isContactsExpanded: true,
  isLocationsExpanded: true,
}

const getState = (): ChartSidePanelDetailStorage => getItemFromLocalStorage(CHART_SIDE_PANEL_DETAIL_KEY)

const editState = (state: ChartSidePanelDetailStorage) => {
  const currentState = getState()
  setItemInLocalStorage(CHART_SIDE_PANEL_DETAIL_KEY, { ...currentState, ...state })
}

/** Set initial values if storage has never been initialized. */
const initializeStorage = () => {
  const state = getState()

  if (!state) {
    editState(INIT_STATE)
  }
}

const removeState = () => deleteItemFromLocalStorage(CHART_SIDE_PANEL_DETAIL_KEY)

export const chartSidePanelDetailSorage = {
  getState,
  editState,
  initializeStorage,
  removeState,
}
