import { RedirectProps } from 'react-router-dom'

import {
  CALLBACK_PATH,
  CREATE_CHART_PATH,
  CHARTS_PATH,
  CHART_EDIT_PATH,
  CHART_EDIT_GW_PATH,
  CHART_EXPORT_PATH,
  CONNECT_GSUITE_PATH_NAME,
  DEPARTMENT_CREATE_PATH,
  DEPARTMENT_DETAIL_PATH,
  DEPARTMENT_EDIT_PATH,
  EMPLOYEE_CREATE_PATH,
  EMPLOYEE_DETAIL_CONNECT_GW_PATH,
  EMPLOYEE_DETAIL_PATH,
  EMPLOYEE_EDIT_CONNECT_GW_PATH,
  EMPLOYEE_EDIT_PATH,
  GSUITE_PATH,
  INSIGHTS_OVERVIEW_CONNECT_GW_PATH,
  INSIGHTS_OVERVIEW_PATH,
  INSIGHTS_PATH,
  INSIGHTS_SOCIOMAP_CONNECT_GW_PATH,
  INSIGHTS_SOCIOMAP_PATH,
  INSIGHTS_TIL_ANALYTICS_PATH,
  INVITE_PATH,
  LOGIN_PATH,
  LOGOUT_CALLBACK_PATH,
  MAINTENANCE_PATH,
  PAY_PREMIUM_PATH,
  SETTINGS_BRANDING_PATH,
  SETTINGS_CUSTOM_FIELDS_PATH,
  SETTINGS_DOWNLOAD_PATH,
  SETTINGS_GENERAL_PATH,
  SETTINGS_MEMBERS_PATH,
  SETTINGS_PATH,
  SETTINGS_SHARING_LINK_PATH,
  SHARED_LINK_DEPARTMENT_EDIT_PATH,
  SHARED_LINK_EMPLOYEE_DETAIL_PATH,
  SHARED_LINK_PATH,
  SETTINGS_DOTTED_LINES_PATH,
  CHART_PRICING_PATH,
} from './constants'

import {
  ChartSidePanelRoutes,
  InsightsRoutes,
  NestedRoute,
  ProtectedRoutes,
  PublicRoutes,
  SettingsRoutes,
} from './types'

import {
  AuthCallbackScreen,
  AuthScreen,
  InviteScreen,
  Maintenance,
  PremiumPayScreen,
  PrivateChartScreen,
  PublicChartScreen,
  ChartsScreen,
  SettingsScreen,
  AnalyticsScreen,
  PricingScreen,
} from 'screens'
import { CreateChartScreen } from 'screens/create-chart'
import { ExportScreen } from 'screens/export'
import { GSuiteConnectionLoader } from 'screens/charts/components'
import { CompanyOverview, Sociomaps, TilAnalytics } from 'screens/analytics/views'
import {
  Branding,
  CustomFields,
  ConnectionFields,
  Downloads,
  General,
  Members,
  SharableLink,
} from 'screens/settings/views'
import { withConnectGoogleWorkspace } from 'screens/chart/components/with-connect-google-workspace'
import { ConnectGoogleWorkspaceCalendar } from 'screens/analytics/components/ConnectGoogleWorkspaceCalendar'

import { CreateDepartment, CreateEmployee, Department, Employee } from 'components/specific'
import { withSidePanel } from 'components/specific/chart-side-panel'
import { ConnectGoogleWorkspaceAddWritePermission } from 'components/specific/chart-side-panel/employee/edit'

const getConnectGWHandlerRoute = (
  path: string,
  component?: (props?: any) => JSX.Element,
  getParams = ''
): NestedRoute => {
  const redirectPath = path.replace(`/${CONNECT_GSUITE_PATH_NAME}`, '') + getParams
  return { path, render: withConnectGoogleWorkspace({ component, redirectPath }), exact: true }
}

export const SETTINGS_ROUTES: Record<SettingsRoutes, NestedRoute> = {
  general: { path: SETTINGS_GENERAL_PATH, component: General, exact: true, isProtected: true },
  customFields: { path: SETTINGS_CUSTOM_FIELDS_PATH, component: CustomFields },
  dottedLine: { path: SETTINGS_DOTTED_LINES_PATH, component: ConnectionFields },
  branding: { path: SETTINGS_BRANDING_PATH, component: Branding, exact: true, isProtected: true },
  members: { path: SETTINGS_MEMBERS_PATH, component: Members, exact: true, isProtected: true },
  sharableLink: { path: SETTINGS_SHARING_LINK_PATH, component: SharableLink, exact: true, isProtected: true },
  downloads: { path: SETTINGS_DOWNLOAD_PATH, component: Downloads, exact: true, isProtected: true },
}

export const PUBLIC_ROUTES: Record<PublicRoutes, NestedRoute> = {
  callback: { path: CALLBACK_PATH, component: AuthCallbackScreen, exact: true },
  chartSettings: { path: SETTINGS_PATH, component: SettingsScreen, routes: SETTINGS_ROUTES },
  invite: { path: INVITE_PATH, component: InviteScreen },
  login: { path: LOGIN_PATH, component: AuthScreen, exact: true },
  logoutCallback: { path: LOGOUT_CALLBACK_PATH, component: AuthCallbackScreen, exact: true },
  maintenance: { path: MAINTENANCE_PATH, component: Maintenance, exact: true },
  pay: { path: PAY_PREMIUM_PATH, component: PremiumPayScreen, exact: true },
  sharedLink: { path: SHARED_LINK_PATH, component: PublicChartScreen },
}

export const INSIGHTS_ROUTES: Record<InsightsRoutes, NestedRoute> = {
  overview: { path: INSIGHTS_OVERVIEW_PATH, component: CompanyOverview, exact: true },
  sociomap: { path: INSIGHTS_SOCIOMAP_PATH, component: Sociomaps, exact: true },
  overviewConnectGW: getConnectGWHandlerRoute(INSIGHTS_OVERVIEW_CONNECT_GW_PATH, ConnectGoogleWorkspaceCalendar),
  sociomapConnectGW: getConnectGWHandlerRoute(INSIGHTS_SOCIOMAP_CONNECT_GW_PATH, ConnectGoogleWorkspaceCalendar),
  tilAnalytics: { path: INSIGHTS_TIL_ANALYTICS_PATH, component: TilAnalytics },
}

export const PROTECTED_ROUTES: Record<ProtectedRoutes, NestedRoute> = {
  createChart: { path: CREATE_CHART_PATH, component: CreateChartScreen },
  gsuite: { path: GSUITE_PATH, component: GSuiteConnectionLoader, exact: true },
  pricing: { path: CHART_PRICING_PATH, component: PricingScreen },
  chartAnalytics: { path: INSIGHTS_PATH, component: AnalyticsScreen, routes: INSIGHTS_ROUTES },
  chartEditConnectGWorkspace: getConnectGWHandlerRoute(CHART_EDIT_GW_PATH),
  chartEdit: { path: CHART_EDIT_PATH, component: PrivateChartScreen },
  chartExport: { path: CHART_EXPORT_PATH, component: ExportScreen },
  charts: { path: CHARTS_PATH, component: ChartsScreen },
}

export const CHART_SIDE_PANEL_ROUTES: Record<ChartSidePanelRoutes, NestedRoute> = {
  createEmployee: { path: EMPLOYEE_CREATE_PATH, render: withSidePanel(CreateEmployee) },
  employee: {
    path: [EMPLOYEE_DETAIL_PATH, SHARED_LINK_EMPLOYEE_DETAIL_PATH, EMPLOYEE_EDIT_PATH],
    render: withSidePanel(Employee),
  },
  employeeConnectGWorkspace: getConnectGWHandlerRoute(EMPLOYEE_DETAIL_CONNECT_GW_PATH),
  employeeEditConnectGWorkspace: getConnectGWHandlerRoute(
    EMPLOYEE_EDIT_CONNECT_GW_PATH,
    ConnectGoogleWorkspaceAddWritePermission
  ),
  createDepartment: { path: DEPARTMENT_CREATE_PATH, render: withSidePanel(CreateDepartment) },
  department: {
    path: [SHARED_LINK_DEPARTMENT_EDIT_PATH, DEPARTMENT_DETAIL_PATH, DEPARTMENT_EDIT_PATH],
    render: withSidePanel(Department),
  },
}

export const OLD_ROUTES_REDIRECTS: RedirectProps[] = [
  { from: '/charts/edit/:chartUuid', to: CHART_EDIT_PATH },
  { from: '/charts/settings/:chartUuid', to: SETTINGS_PATH },
  { from: '/charts/analytics/:chartUuid', to: INSIGHTS_PATH },
]
