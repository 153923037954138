import styled from 'styled-components'

import { LinkStyles } from 'components/generic/link/link-styles'
import { FolderIcon } from 'components/icons'

type Props = {
  onMoveToChart: () => void
}

export const MoveToChartButton = ({ onMoveToChart }: Props) => (
  <MoveToChartButtonInner>
    <TextContainer>
      <StyledFolderIcon />
      In directory
    </TextContainer>

    <Link onClick={onMoveToChart}>Move to Org Chart</Link>
  </MoveToChartButtonInner>
)

const MoveToChartButtonInner = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: ${props => props.theme.colors.grey};
  font-size: 13px;
  line-height: 16px;
`

const StyledFolderIcon = styled(FolderIcon)`
  width: 15px;
  margin-right: 5px;
  color: ${props => props.theme.colors.greyLight};
`

const Link = styled.div`
  ${LinkStyles}
  font-size: 13px;
  line-height: 20px;
  user-select: none;
`
