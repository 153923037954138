import { ChangeEvent } from 'react'
import styled from 'styled-components'

import { ColorSelectRadio } from 'components/generic'
import { NodeColor, NODE_COLORS } from 'helpers'

type SelectColorPaletteProps = {
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  className?: string
  name?: string
}

export const SelectColorPalette = ({ value, onChange, className, name = 'color' }: SelectColorPaletteProps) => {
  const nodeColorsArray = Object.entries(NODE_COLORS).map(entry => entry[1]) as NodeColor[]

  return (
    <ColorsWrap className={className}>
      {nodeColorsArray.map(nodeColor => (
        <ColorSelectRadio
          key={nodeColor.value}
          name={name}
          value={nodeColor.value}
          textColor={nodeColor.textColor}
          checked={value === nodeColor.value}
          onChange={onChange}
        />
      ))}
    </ColorsWrap>
  )
}

const ColorsWrap = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: repeat(auto-fit, 36px);
  grid-gap: ${props => props.theme.spaces.s};
  justify-content: space-between;
`
