import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { DragElementWrapper, DragSourceOptions } from 'react-dnd'

import { GrDrag } from 'react-icons/gr'
import { EmailIcon } from 'components/icons'

import { addTransparencyToColor, NodeColor, applySpacingProps, SpacingProps, stripStr } from 'helpers'
import { useResponsiveInfo } from 'providers'

export type RowNodeProps = {
  className?: string
  description?: string
  email?: string
  icon?: ReactNode
  nodeColorPallete?: NodeColor
  spacing?: SpacingProps
  title?: string
  isClickable?: boolean
  isDraggable?: boolean
  draggableRef?: DragElementWrapper<DragSourceOptions>
}

export const RowNode = (props: RowNodeProps) => {
  const {
    title,
    className,
    description,
    email,
    icon,
    nodeColorPallete,
    spacing,
    isClickable,
    isDraggable,
    draggableRef,
  } = props
  const { isSmallDevice } = useResponsiveInfo()

  return (
    <RowNodeInner
      className={className}
      $nodeColorPallete={nodeColorPallete}
      $isClickable={isClickable}
      $spacing={spacing}
      ref={draggableRef}
    >
      {isDraggable && <DragWrap>{!isSmallDevice && <GrDrag title='Drag &amp; drop' />}</DragWrap>}

      {icon && <IconContainer>{icon}</IconContainer>}

      <TextContainer>
        <TopTextWrap>
          {title && <Title>{stripStr(title, 30)}</Title>}
          {email && (
            <Email>
              <StyledEmailIcon />
              {email}
            </Email>
          )}
        </TopTextWrap>
        <Description>{description}</Description>
      </TextContainer>
    </RowNodeInner>
  )
}

const RowNodeInner = styled.div<{ $nodeColorPallete?: NodeColor; $isClickable?: boolean; $spacing?: SpacingProps }>`
  position: relative;
  width: 100%;
  min-height: 32px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: ${props => addTransparencyToColor(props.$nodeColorPallete?.value || props.theme.colors.white, 0.1)};
  color: ${props => props.theme.colors.border};
  user-select: none;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: ${props => props.$nodeColorPallete?.value ?? 'none'};
  }

  ${props =>
    props.$isClickable &&
    css`
      cursor: pointer;
      ${props.theme.animations.backgroundShapeFill}
    `}

  ${props => applySpacingProps(props.$spacing)}
`

const DragWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${props => props.theme.spaces.l};
  margin-left: ${props => props.theme.spaces.m};
  cursor: move;

  path {
    stroke: inherit;
  }
`

const IconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: ${props => props.theme.spaces.m};
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow: hidden;
`

const TopTextWrap = styled.div`
  display: flex;
  align-items: center;
`

const textCSS = css`
  line-height: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
const Title = styled.div`
  ${textCSS}
  color: ${props => props.theme.colors.dark};
  font-size: 13px;
`

const Email = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${props => props.theme.spaces.m};
  color: ${props => props.theme.colors.greyLight};
  font-size: 12px;
  line-height: 14px;
`

const StyledEmailIcon = styled(EmailIcon)`
  width: 11px;
  height: auto;
  margin-right: ${props => props.theme.spaces.s};
`

const Description = styled.div`
  ${textCSS}
  font-weight: normal;
  width: 100%;
  color: ${props => props.theme.colors.greyLight};
  font-size: 12px;
`
