import { ReactNode } from 'react'
import { Auth0Provider } from '@auth0/auth0-react'

import { auth0ProviderOptions } from './config'

type Props = {
  children: ReactNode
}

export const Auth0ProviderWithHistory = ({ children }: Props) => (
  <Auth0Provider {...auth0ProviderOptions}>{children}</Auth0Provider>
)
