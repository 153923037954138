import { ReactNode } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ProBadge, useGoToPremiumScreen, getIsSubscribed } from 'features/premium'
import { ExportChartModalType } from 'components/specific'
import {
  AddDepartmentIcon,
  AddEmployeeIcon,
  DottedLineReportingFlatIcon,
  DownloadIcon,
  DuplicateChartIcon,
  EditIcon,
  TrashCanIcon,
} from 'components/icons'

import { useDeleteNodeAction, useForkChartAction, useDeleteChartAction, useCloneChartAction } from 'tree/actions'
import { useChartPermission } from 'tree/hooks'
import { useChartData, useChartId } from 'tree/providers'
import { Company, Department, Node, Person } from 'tree/types'
import { useModal } from 'modal'
import {
  SETTINGS_CREATE_DOTTED_LINE_PATH,
  SETTINGS_GENERAL_PATH,
  useGoToAddDepartment,
  useGoToAddEmployee,
  useGoToEditDepartment,
  useGoToEditEmployee,
} from 'routes'
import { animations, spaces } from 'theme'

export type NodeMenuProps = {
  node: Node
  className?: string
  onClick: () => void
}

export const NodeMenu = ({ className, node, onClick }: NodeMenuProps) => {
  const { id, type, __typename } = node
  const isPerson = __typename === 'Person'
  const isDepartment = __typename === 'Department'
  const isCompany = __typename === 'Company'
  const name = isCompany ? node.type : node.name

  const { name: chartName, providerInfo } = useChartData()

  const { capabilities } =
    isPerson || isDepartment ? (node as Person | Department) : ({} as Partial<Person | Department>)
  const { canUpdate, canDelete, canMove } = capabilities || {}
  const {
    canCreateCustomField,
    canCreateDepartment,
    canCreatePerson,
    canDelete: canDeleteChart,
    canExport,
    canUpdate: canUpdateChart,
    canClone,
  } = useChartPermission()

  const chartId = useChartId()
  const history = useHistory()

  const { goToAddEmployee } = useGoToAddEmployee()
  const { goToAddDepartment } = useGoToAddDepartment()
  const { goToPremiumScreen } = useGoToPremiumScreen()

  const handleAddDottedLine = () => {
    if (!isSubscribed) {
      return goToPremiumScreen()
    }
    history.push(generatePath(SETTINGS_CREATE_DOTTED_LINE_PATH, { chartUuid: chartId }), {
      from: window.location.pathname,
    })
  }

  const { goToEditEmployee } = useGoToEditEmployee()
  const { goToEditDepartment } = useGoToEditDepartment()
  const handleEdit = () => (isPerson ? goToEditEmployee(id) : goToEditDepartment(id))
  const handleEditChart = () => {
    history.push(generatePath(SETTINGS_GENERAL_PATH, { chartUuid: chartId }), { from: window.location.pathname })
  }

  const { deleteNodeWithConfirm } = useDeleteNodeAction()
  const { deleteChartWithConfirm } = useDeleteChartAction()
  const handleDelete = (node: Exclude<Node, Company>) => deleteNodeWithConfirm({ node })
  const handleDeleteChart = () => deleteChartWithConfirm({ chartId, chartName })

  const { forkChartWithConfirm } = useForkChartAction()
  const { cloneChartWithConfirm } = useCloneChartAction()
  const handleDuplicate = () => {
    isCompany
      ? cloneChartWithConfirm({ chartId, chartName, providerInfo })
      : forkChartWithConfirm({ chartId, rootName: name, rootId: id, providerInfo })
  }

  const chartData = useChartData()
  const isSubscribed = getIsSubscribed(chartData?.subscription)
  const { open, close } = useModal()
  const handleExport = () => {
    open<ExportChartModalType>('exportChartModal', {
      chartData,
      rootId: id,
      nodeName: isCompany ? '' : name,
      nodeTypename: __typename,
      goToPremiumScreen,
      onClose: () => close('exportChartModal'),
    })
  }

  return (
    <Menu className={className} onClick={onClick}>
      {canCreatePerson && (
        <MenuItem
          icon={<AddEmployeeIcon width='22px' height='12px' />}
          onClick={() => goToAddEmployee(id)}
          data-testid='button-add-employee'
        >
          Add employee
        </MenuItem>
      )}
      {canCreateDepartment && (
        <MenuItem
          icon={<AddDepartmentIcon width='19px' height='14px' />}
          onClick={() => goToAddDepartment(id)}
          data-testid='button-add-department'
        >
          Add department
        </MenuItem>
      )}
      {isPerson && canCreateCustomField && (
        <MenuItem
          icon={<DottedLineReportingFlatIcon width='24px' height='100%' />}
          isPro={!isSubscribed}
          onClick={handleAddDottedLine}
        >
          Add dotted line
        </MenuItem>
      )}
      <Divider />

      {isCompany
        ? canUpdateChart && (
            <MenuItem icon={<EditIcon width='18px' />} onClick={handleEditChart}>
              Edit chart
            </MenuItem>
          )
        : (canUpdate || canMove) && (
            <MenuItem icon={<EditIcon width='18px' />} onClick={handleEdit}>
              Edit {type}
            </MenuItem>
          )}
      {isCompany
        ? canDeleteChart && (
            <MenuItem icon={<TrashCanIcon width='16px' height='15px' />} onClick={handleDeleteChart}>
              Delete chart
            </MenuItem>
          )
        : canDelete && (
            <MenuItem icon={<TrashCanIcon width='16px' height='15px' />} onClick={() => handleDelete(node)}>
              Delete {type}
            </MenuItem>
          )}
      <Divider />

      {canExport && (
        <>
          <MenuItem icon={<DownloadIcon />} onClick={handleExport}>
            Export {isPerson ? 'team' : isDepartment ? 'department' : 'chart'}
          </MenuItem>
          <Divider />
        </>
      )}

      {canClone && (
        <MenuItem icon={<DuplicateChartIcon width='20px' height='19px' />} onClick={handleDuplicate}>
          Duplicate {isCompany ? 'this chart' : `from this ${type}`}
        </MenuItem>
      )}
    </Menu>
  )
}

const Menu = styled.div`
  position: relative;
  min-width: 250px;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background: ${props => props.theme.colors.white};
  z-index: 99;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.colors.greyExtraLight};
`

type NodeMenuItemProps = {
  icon: ReactNode
  children: ReactNode
  isPro?: boolean
  onClick: () => void
}

const MenuItem = ({ icon, children, isPro, onClick }: NodeMenuItemProps) => {
  return (
    <MenuItemInner onClick={onClick}>
      <IconContainer>{icon}</IconContainer>
      <Text>{children}</Text>
      {isPro && <ProBadge spacing={{ ml: spaces.m }} />}
    </MenuItemInner>
  )
}

const MenuItemInner = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;

  ${animations.backgroundShapeFill}
`

const IconContainer = styled.div`
  min-width: 24px;
  max-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
  color: ${props => props.theme.colors.greyLight};
`

const Text = styled.div`
  color: ${props => props.theme.colors.dark};
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
`
