export const ORG_TRACK = {
  login: 'Log in',
  logout: 'Log out',
  loginDifferentAcc: 'Wanted to log in for different account',
  pageView: 'Page View',

  createDepartment: 'Create department',
  createDepartmentFailure: 'Creating department failed',
  createEmployee: 'Create employee',
  createEmployeeFailure: 'Creating employee failed',
  deleteDepartment: 'Delete department',
  deleteDepartmentFailure: 'Deleting department failed',
  deleteEmployee: 'Delete employee',
  deleteEmployeeFailure: 'Deleting employee failed',
  unassignDepartment: 'Unassign department',
  unassignEmployee: 'Unassign employee',
  updateDepartment: 'Update department',
  updateDepartmentFailure: 'Updating department failed',
  updateEmployee: 'Update employee',
  updateEmployeeFailure: 'Updating employee failed',
  avatarUploadImageFailure: 'Failed to upload image',
  unassignNodeFailure: 'Unassign node failure',
  unassignNodesFailure: 'Unassign nodes failure',

  cloneChart: 'Clone chart',
  cloneChartFailure: 'Clonning Chart failed',
  createChart: 'Create chart',
  createChartDownloadSampleTemp: 'Create new chart download sample template',
  createChartFailure: 'Create chart failure',
  createChartSuccess: 'Create chart success',
  createNewChart: 'Create new chart',
  createNewChartFileRemoved: 'Create new chart file removed',
  createNewChartFileSelected: 'Create new chart file selected',
  createNewChartOption: 'Create new chart option selected',
  deleteChart: 'Delete chart',
  deleteChartFailure: 'Deleting Chart failed',
  openChart: 'Open chart',
  updateChart: 'Update chart',
  updateChartFailure: 'Updating Chart failed',
  updateChartSync: 'Update chart sync',
  updateChartSyncEditOnlyFailure: 'Make Google Workspace chart editOnly failed',
  updateChartSyncTwoWayFailure: 'Make Google Workspace chart twoWaySync failed',
  addWritePermissionForGWInsufficientPermissions:
    'Adding write permission for Google Workspace failed - Insufficient permissions',
  addWritePermissionForGWUnknownError: 'Adding write permission for Google Workspace failed - unknown error',

  zoomToNode: 'Zoom to node',
  zoomToNodeFailure: 'Zoom to node failed',
  helpCenterOpen: 'Help Center open',
} as const

export const PERMISSIONS_TRACK = {
  chartResync: 'Chart Resync',
  addGoogleCalPerEmplDet: 'Add Google Calendar permission (employee detail)',
  addGoogleCalPerInsights: 'Insights - Add Google Calendar permission',
  updateChartToken: 'Update Chart Token',
  updateChartTokenSuccess: 'Update Chart Token Success',
  updateChartTokenFailure: 'Update Chart Token Failure',
  connectGsuite: 'Connect G Suite', // Google Workspace, but don't here to keep key in mixpanel
  disconnectGsuite: 'Disconnect G Suite', // Google Workspace, but don't here to keep key in mixpanel
} as const

export const INSIGHTS_TRACK = {
  visited: 'Insights - insights visited',
  joinUs: 'Insights - Join Us',
  addCalPerAtt: 'Insights - Add Google Calendar permission (attempt)',
  contactUs: 'Insights - contact us cta button click',
  extremeValuesButton: 'Insights - bar chart hide/show extreme values',
  monthChange: 'Insights - month change',
  sortTableByDepartment: 'Insights - sort table rows by department',
  sortTableByValue: 'Insights - sort table rows by value',
  sortTableByTrend: 'Insights - sort table rows by trend',
  spanOfControlSearch: 'Insights - search in span of control',
  spanOfControlSortByPersonName: 'Insights - sort span of control rows by person name',
  spanOfControlSortDepartment: 'Insights - sort span of control rows by department',
  spanOfControlSortSubCount: 'Insights - sort span of control rows by subordinates count',
  departmentSelect: 'Insights - department select',
  sociomapVisited: 'Insights - sociomap visited',
  sociomapAnimationTouch: 'Insights - sociomap animation play/pause button',
} as const

export const ADS_TRACK = {
  adClicked: 'Ad clicked',
  adTilPopup: 'Click on Time is Ltd. Ad in popup',
} as const

export const EXPORT_TRACK = {
  pdfSuccess: 'Export chart as pdf success',
  pngSuccess: 'Export chart as png success',
  csvSuccess: 'Export chart as csv success',
  pdfFailed: 'Export chart as pdf failed',
  pngFailed: 'Export chart as png Failed',
  csvFailed: 'Export chart as csv Failed',
} as const

export const SHARING_TRACK = {
  inviteMember: 'Company member invited to chart',
  invitePerson: 'Person invited to chart by email',
  invitationResendFailure: 'Invitation resend failed',
  inviteResentFailure: 'Invite resent failed',

  addCollaborator: 'Add collaborator',
  deleteCollaborator: 'Delete collaborator',
  deleteColleagueFailure: 'Deleting colleague failed',
  updateCollaborator: 'Update collaborator',
  updateColleagueFailure: 'Updating colleague failed',

  createPublicLink: 'Create public link',
  createPublicLinkFailure: 'Creating link has failed',
  createPublicLinkOfTheChart: 'Create public link of the chart',
  deletePublicLink: 'Delete public link',
  deletePublicLinkFailure: 'Deleting link has failed',
  publicLinkCopied: 'Public link copied',
  updatePublicLink: 'Update public link',
  updatePublicLinkFailure: 'Updating link has failed',
} as const

export const ERRORS_TRACK = {
  authProviderIssue: 'AuthProvider issue',
  invitationAcceptError: 'Invitation accept error',
  fullTextSearchFailure: 'Full text search failed',
} as const

export const SUBSCRIPTION_TRACK = {
  getInfo: 'Subscription Get Info',
  purchaseFree: 'Subscription Purchase Free',
  purchasePRO: 'Subscription Purchase PRO',
  purchaseEnterprise: 'Subscription Purchase Enterprise',
  purchaseSuccess: 'Subscription Purchase Success',
  purchaseFailure: 'Subscription Purchase Failure',
} as const

export const CUSTOM_FIELDS_TRACK = {
  click: 'Custom Field Click', // from = 'Top navigation' | 'Settings'
  beginCreate: 'Custom Field Begin Create',
  create: 'Custom Field Create', // fieldLabel, fieldType, displayOnCards, optionsCount, emojiCount
  cancelCreate: 'Custom Field Create Cancel',
  edit: 'Custom Field Edit', // fieldLabel, fieldType, displayOnCards, optionsCount, emojiCount
  delete: 'Custom Field Delete', // fieldLabel, fieldType
} as const
