import { InsightDataset, InsightDatasetSingle } from 'apollo/generated/graphql'
import { OverviewTable, SpanOfControl, SpanOfControlTableRow, CompanyOverviewTableRow } from '../../types'

export const getOverviewTableData = (table?: OverviewTable) => {
  if (!table) return undefined

  return table.values.map(rec => ({ ...rec.f, ...rec.v })) // .filter(rec => rec.dep !== activeDep) - we probably don't want that
}

export const getSpanOfControlData = (table?: SpanOfControl) => {
  if (!table) return undefined

  return table.values.map(rec => ({ ...rec.f, ...rec.v })) // .filter(rec => rec.dep !== activeDep) - we probably don't want that
}

export const datasetToOverviewTable = (
  ds: InsightDataset,
  dsPrev?: InsightDataset,
  chartUuid?: string
): CompanyOverviewTableRow[] => {
  if (ds?.__typename !== 'InsightDatasetSingle') return []

  return ds.values
    .filter(dsValue => !(dsValue.f.dep === chartUuid || dsValue.l === 'insights.all_departments'))
    .map(({ l, v }, i) => ({
      dep: l,
      value: v,
      trend: getTableTrend(v, (dsPrev as InsightDatasetSingle)?.values[i]?.v),
    }))
}

const getTableTrend = (value: number, previousValue?: number) => {
  if (!previousValue || isNaN(value) || isNaN(previousValue)) return NaN
  return Math.round((value / previousValue - 1) * 100)
}

export const datasetToSpanOfControlTable = (ds: InsightDataset): SpanOfControlTableRow[] => {
  if (ds?.__typename !== 'InsightDatasetSpanOfControl') return []

  return ds.values.map(({ givenName, familyName, department, image, subordinatesCount }) => {
    return {
      dep: department.name,
      personName: `${givenName} ${familyName}`,
      subordinatesCount,
      personIcon: image,
    }
  })
}
