import { useState } from 'react'
import { generatePath } from 'react-router-dom'
import styled from 'styled-components'

import { Option } from './option'
import { Modal, Button } from 'components/generic'

import { handleErrorValidation } from 'helpers'
import { useGoogleWorkspace } from 'tree/hooks'
import { ChartDetail } from 'tree/types'
import { TreeState, UseTreeResult } from 'tree/providers'
import { ModalTypeFactory, ModalComponentProps } from 'modal'
import analytics from 'analytics'

import { EMPLOYEE_EDIT_CONNECT_GW_PATH } from 'routes'
import { ORG_TRACK } from 'analytics/constants'
import { SyncOption } from 'screens/create-chart/types'

type Props = ModalComponentProps & {
  expandedNodes: TreeState['expandedNodes']
  tree: UseTreeResult
  chartData?: ChartDetail
  personUuid?: string
}

export type ChartAddWritePermissionModalType = ModalTypeFactory<'chartAddWritePermissionModal', Props>

export const ChartAddWritePermissionModal = ({ expandedNodes, tree, chartData, personUuid, onClose }: Props) => {
  const [option, setOption] = useState<SyncOption>('twoWaySync')

  const { authorize } = useGoogleWorkspace()
  const { useMakeChartEditable } = tree
  const makeChartEditable = useMakeChartEditable(expandedNodes)

  const chartUuid = chartData?.uuid
  const scopes = chartData?.providerInfo?.scopes || []

  if (!chartUuid || !personUuid) return null

  const handleSubmit = () => {
    analytics.track(ORG_TRACK.updateChartSync, { chartUuid, chartName: chartData?.name, syncOption: option })
    new Promise((_, reject) => {
      if (option === 'twoWaySync') {
        authorize({
          redirectPath: generatePath(EMPLOYEE_EDIT_CONNECT_GW_PATH, { chartUuid, uuid: personUuid }),
          scopes: [...scopes, 'user'],
        }).catch(reject)
      } else {
        makeChartEditable({ chartUuid, personUuid }).then(onClose).catch(reject)
      }
    }).catch(error => {
      handleErrorValidation({
        track: {
          message: `Make Google Workspace chart ${option} failed` as const,
          values: { chartUuid, error },
        },
        toast: { message: 'Operation failed. Please try again.' },
      })
    })
  }

  return (
    <Modal size='small' title={{ text: 'Edit settings', size: 'small' }} onClose={onClose}>
      <StyledOption checked={option === 'twoWaySync'} onClick={() => setOption('twoWaySync')}>
        <BoldText>Sync with Google Workspace</BoldText>
        <Text>Data is shared between your Org Chart and Google Workspace so you only have to edit once.</Text>
      </StyledOption>

      <StyledOption checked={option === 'editOnly'} onClick={() => setOption('editOnly')}>
        <BoldText>Do not sync with Google Workspace</BoldText>
        <Text>Any changes made in your Org Chart won&apos;t be shared with Google Workspace.</Text>
      </StyledOption>

      <Note>These settings apply to your entire Org Chart and cannot be reversed once applied.</Note>

      <ButtonsWrap>
        <Button buttonType='secondary' onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Save changes</Button>
      </ButtonsWrap>
    </Modal>
  )
}

const Text = styled.div`
  color: ${props => props.theme.colors.dark};
  font-size: 13px;
  line-height: 20px;
`

const BoldText = styled(Text)`
  margin-bottom: ${props => props.theme.spaces.s};
  font-weight: 500;
`

const StyledOption = styled(Option)`
  align-items: flex-start;
  margin-bottom: ${props => props.theme.spaces.l};
`

const Note = styled.div`
  width: 100%;
  min-height: 32px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 8px;
  background: ${props => props.theme.colors.greyUltraLight};
  color: ${props => props.theme.colors.greyLight};
  font-size: 11px;
  line-height: 16px;
  cursor: default;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${props => props.theme.spaces.l};
`
