import styled from 'styled-components'
import { CustomConnectionField, CustomConnectionLineStyle } from 'tree/types'

export const DottedLinePreview = styled.div<{
  $lineColor: CustomConnectionField['lineColor']
  $lineStyle: CustomConnectionLineStyle
  $borderWidth?: string
}>`
  min-width: 30px;
  max-width: 30px;
  border-bottom-width: ${props => props.$borderWidth ?? '2px'};
  border-style: ${props => props.$lineStyle};
  border-color: ${props => props.$lineColor};
`
