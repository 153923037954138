import { useUnassignNodeRequest } from './use-unassign-node-request'
import { useChartData } from '../../providers'
import { NodeTypename } from '../../types'
import { handleErrorValidation, pluralize } from 'helpers'
import analytics from 'analytics'
import { ORG_TRACK } from 'analytics/constants'

import { NodeDataFragmentFragment } from 'apollo/generated/graphql'

type UnassignNodeActionParams = {
  __typename: Exclude<NodeTypename, 'Company'>
  chartUuid: string
  name: string
  nodeChildrenCount: number
  parentNodes: NodeDataFragmentFragment['parentNodes']
  uuid: string
  parentUuid?: string | null
}

export const useUnassignNodeAction = () => {
  const { unassignNodeRequest } = useUnassignNodeRequest()
  const { name: chartName } = useChartData()

  const unassignNodeAction = async (params: UnassignNodeActionParams) => {
    const { __typename, chartUuid, nodeChildrenCount, uuid, name, parentUuid, parentNodes } = params
    const trackKey = __typename === 'Person' ? 'unassignEmployee' : 'unassignDepartment'
    const trackBaseObj = {
      chartName,
      chartUuid,
      nodeChildrenCount,
      nodeName: name,
      onNodeUuid: uuid,
      nodeType: __typename,
    }

    try {
      const res = await unassignNodeRequest({ uuid, parentUuid, parentNodes })
      analytics.track(ORG_TRACK[trackKey], trackBaseObj)
      return Promise.resolve(res)
    } catch (error) {
      handleErrorValidation({
        track: {
          message: `Unassign ${pluralize('node', nodeChildrenCount) as 'node' | 'nodes'} failure` as const,
          values: { ...trackBaseObj, error },
        },
        toast: { message: 'Failed to unassign employees/departments. Please refresh the page and try again.' },
      })
    }
  }

  return { unassignNodeAction }
}
