import styled, { css } from 'styled-components'
import Select, {
  ContainerProps,
  ControlProps,
  ValueContainerProps,
  DropdownIndicatorProps,
  MenuProps,
  MenuListProps,
  OptionProps,
} from 'react-select'

import { CollaboratorRoleItem } from './components'
import { RotatingChevron } from 'components/generic'

import { dropdownMenu } from 'helpers'
import { mainTheme } from 'theme'

import { CollaboratorRole } from 'apollo/generated/graphql'

type OptionType = {
  label: string
  value: CollaboratorRole
}
const options: OptionType[] = [
  { label: 'Viewer', value: 'viewer' },
  { label: 'Editor', value: 'editor' },
  { label: 'Admin', value: 'admin' },
]

export type CollaboratorRoleSelectProps = {
  value: CollaboratorRole
  allowedRoles: readonly CollaboratorRole[]
  onRoleChange: (role: CollaboratorRole) => void
  className?: string
  disabled?: boolean
}

export const CollaboratorRoleSelect = (props: CollaboratorRoleSelectProps) => {
  const { value, allowedRoles, onRoleChange, className, disabled: shouldBeDisabled } = props

  const allowedOptions = allowedRoles.map(r => ({ label: r, value: r }))
  const selectedOptionIndex = options.findIndex(o => o.value === value)
  const selectedOption = options[selectedOptionIndex]
  const noOptionsAvailable = !allowedOptions[selectedOptionIndex]
  const disabled = shouldBeDisabled || noOptionsAvailable

  const SelectContainer = ({ children }: ContainerProps<OptionType, false>) => (
    <SelectContainerInner className={className} disabled={disabled}>
      {children}
    </SelectContainerInner>
  )

  const Control = ({
    innerProps,
    selectProps: { menuIsOpen },
    innerRef,
    children,
  }: ControlProps<OptionType, false>) => {
    return (
      <StyledControl {...innerProps} menuIsOpen={menuIsOpen} ref={innerRef} data-testid='button-collaborator-dropdown'>
        {children}
      </StyledControl>
    )
  }

  const ValueContainer = ({ children }: ValueContainerProps<OptionType, false>) => (
    <StyledValueContainer disabled={disabled}>{children}</StyledValueContainer>
  )

  const DropdownIndicator = ({ selectProps: { menuIsOpen } }: DropdownIndicatorProps<OptionType, false>) => {
    if (disabled) return null
    return <RotatingChevron direction={menuIsOpen ? 'top' : 'bottom'} />
  }

  const Menu = ({ children, innerProps, innerRef }: MenuProps<OptionType, false>) => (
    <StyledMenu {...innerProps} ref={innerRef}>
      {children}
    </StyledMenu>
  )

  const MenuList = ({ children }: MenuListProps<OptionType, false>) => (
    <StyledMenuList data-testid='menu-list-collaborator-dropdown'>{children}</StyledMenuList>
  )

  const Option = ({ innerProps, isSelected, data }: OptionProps<OptionType, false>) => {
    const role = data.value

    return (
      <CollaboratorRoleItem
        {...innerProps}
        $isSelected={isSelected}
        role={role}
        ref={null}
        data-testid={`select-${role}-in-dropdown`}
      >
        {role}
      </CollaboratorRoleItem>
    )
  }

  return (
    <Select<OptionType, false>
      menuPosition='fixed'
      menuPortalTarget={dropdownMenu} // Portaled due to Safari bug which hides fixed positioned element if their parent has overflow hidden -> https://bugs.webkit.org/show_bug.cgi?id=160953
      value={selectedOption}
      options={allowedOptions}
      isSearchable={false}
      disabled={disabled}
      onChange={option => option && onRoleChange(option.value)}
      styles={{
        menuPortal: provided => ({ ...provided, zIndex: 299 }),
        indicatorsContainer: () => ({}),
        valueContainer: () => ({}),
        singleValue: () => ({}),
      }}
      components={{
        SelectContainer,
        Control,
        ValueContainer,
        IndicatorSeparator: () => null,
        DropdownIndicator,
        Menu,
        MenuList,
        Option,
      }}
    />
  )
}

const SelectContainerInner = styled.div<{ disabled?: boolean }>`
  min-width: 96px;
  width: 100%;
  max-width: 196px;
  user-select: none;
  outline: none;

  ${props =>
    props.disabled &&
    css`
      cursor: default;
      pointer-events: none;
    `}
`

const StyledControl = styled.div<{ menuIsOpen?: boolean }>`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 12px;
  background: none;
  cursor: pointer;

  ${props =>
    props.menuIsOpen &&
    css`
      background: ${props.theme.colors.greyActive};
    `}
`

const StyledValueContainer = styled.div<{ disabled?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.dark};
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;

  ${props =>
    props.disabled &&
    css`
      color: ${props.theme.colors.greyLight};
    `}
`

const StyledMenu = styled.div`
  position: relative;
  width: 100%;
  z-index: 10;
`

const StyledMenuList = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 6px;
  background: ${mainTheme.colors.white};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
`
