import styled from 'styled-components'

type Props = {
  className?: string
}

export const RequiredAsterisk = ({ className }: Props) => <Asterisk className={className}>*</Asterisk>

const Asterisk = styled.div`
  display: inline;
  color: ${props => props.theme.colors.orange};
  margin-left: 3px;
`
