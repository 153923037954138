import { chart as chartQuery, getPublicLinks } from 'apollo/query'
import { Chart, PublicLink, useDeletePublicLinkMutation } from 'apollo/generated/graphql'

type DeletePublicLinkRequestParams = {
  chartId: Chart['id']
  publicLinkId: PublicLink['id']
}

export const useDeletePublicLinkRequest = () => {
  const [deletePublicLinkMutation] = useDeletePublicLinkMutation()

  const deletePublicLinkRequest = async ({ chartId, publicLinkId }: DeletePublicLinkRequestParams) => {
    return deletePublicLinkMutation({
      variables: { chartUuid: chartId, uuid: publicLinkId },
      refetchQueries: [
        { query: getPublicLinks, variables: { chartUuid: chartId } },
        { query: chartQuery, variables: { key: chartId } },
      ],
    })
  }

  return { deletePublicLinkRequest }
}
