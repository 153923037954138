import styled, { css } from 'styled-components'

import { applySpacingProps, SpacingProps } from 'helpers'
import { colors, keyframes, spaces } from 'theme'

type Props = {
  $color: 'grey' | 'red' | 'yellow' | 'purple'
  $size?: 'extraSmall' | 'small' | 'medium' // default: medium
  $spacing?: SpacingProps
  $animate?: boolean
  $capitalized?: boolean
}

// Sizes
const extraSmallSizeCSS = css`
  padding: ${spaces.xs};
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
`
const sizeSmallCSS = css`
  padding: ${spaces.xs} ${spaces.s};
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
`
const sizeMediumCSS = css`
  padding: ${spaces.s};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`
const badgeSizesCSS = css<Props>`
  ${props => props.$size === 'extraSmall' && extraSmallSizeCSS}
  ${props => props.$size === 'small' && sizeSmallCSS}
  ${props => props.$size === 'medium' && sizeMediumCSS}
`

// Colors
const badgeYellowCSS = css`
  background: ${colors.yellow};
  color: ${colors.dark};
`
const badgeGreyCSS = css`
  background: ${colors.greyExtraLight};
  color: ${colors.dark};
`
const badgeRedCSS = css`
  background: ${colors.redExtraLight};
  color: ${colors.red};
`
const badgePurpleCSS = css`
  background: ${colors.purple};
  color: ${colors.white};
`
const badgeColor = css<Props>`
  ${props => props.$color === 'yellow' && badgeYellowCSS}
  ${props => props.$color === 'grey' && badgeGreyCSS}
  ${props => props.$color === 'red' && badgeRedCSS}
  ${props => props.$color === 'purple' && badgePurpleCSS}
`

// Generic
const clickableCSS = css`
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`

export const Badge = styled.div<Props>`
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 3px;
  text-align: center;
  white-space: nowrap;
  user-select: none;
  cursor: inherit;

  transform: perspective(1px) translateZ(0);
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;

  ${({ $capitalized }) =>
    $capitalized &&
    css`
      text-transform: uppercase;
    `}

  ${({ $animate }) =>
    $animate &&
    css`
      animation: ${keyframes.shakeWithBounce} 2.75s linear 3;
    `}

  ${badgeSizesCSS};
  ${badgeColor};
  ${props => applySpacingProps(props.$spacing)}
  ${props => props.onClick && clickableCSS}
`
Badge.defaultProps = {
  $size: 'medium',
}
