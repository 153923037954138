import { useState } from 'react'
import styled from 'styled-components'

import { CommonControlsMenu } from './common-controls-menu'
import { ProfileIcon } from 'components/icons'
import { Tooltip } from 'components/generic'

export const CommonControls = () => {
  const [isOpened, setIsOpened] = useState(false)
  const [userMailToShow, setUserMailToShow] = useState<string>('')

  return (
    <>
      <CommonControlsInner tabIndex={0} onBlur={() => setIsOpened(false)}>
        <Circle
          onClick={() => {
            setUserMailToShow('')
            setIsOpened(!isOpened)
          }}
          isOpened={isOpened}
          data-testid='button-common-controls'
        >
          <StyledProfileIcon />
        </Circle>

        <StyledTooltip isTooltipShown={Boolean(userMailToShow)}>Logged in as {userMailToShow}</StyledTooltip>
        {isOpened && <CommonControlsMenu onClose={() => setIsOpened(false)} />}
      </CommonControlsInner>
    </>
  )
}

const CommonControlsInner = styled.div<{ tabIndex: number }>`
  position: relative;
  min-width: 34px;
  width: 34px;
  min-height: 34px;
  height: 34px;
  border-radius: 50%;
  outline: none;
`

const Circle = styled.div<{ isOpened: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: ${props => (props.isOpened ? props.theme.colors.greyActive : 'none')};
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: ${props => props.theme.transitions.extraFastEase};

  ${props => props.theme.animations.backgroundShapeFill};
`

const StyledProfileIcon = styled(ProfileIcon)`
  position: relative;
  width: 12px;
  color: inherit;
  z-index: 2;
`

const StyledTooltip = styled(Tooltip)`
  max-width: initial;
  width: auto;
  transform: translateY(100%) translateX(calc(-100% + 17.5px));

  &:after {
    left: calc(50% - 17.5px);
  }
`
