type Capabilities<T> = (Record<string, unknown> | null | undefined) & T

export const makeCapabilitiesReadOnly = <T>(capabilities: Capabilities<T>): T => {
  if (!capabilities) {
    return capabilities
  }
  const newCapabilities = Object.entries(capabilities).reduce<any>((capabilities, [keyName, value]) => {
    // Only falsify booleans whose key names don't start with canRead
    const shouldValueRemain = keyName.includes('canRead') || typeof value !== 'boolean'
    capabilities[keyName] = shouldValueRemain ? value : false
    return capabilities
  }, {})
  return newCapabilities
}
