import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { MdKeyboardArrowRight } from 'react-icons/md'

import { PremiumBenefits } from 'screens/settings/components'
import { Button, FlexBox, Link } from 'components/generic'
import { GemIcon } from 'components/icons'

import { useGoToPremiumScreen } from 'features/premium'
import { CHARTS_PATH } from 'routes'
import { spaces } from 'theme'

import Lock3D from 'assets/3d-lock.png'

export const ChartLockScreen = () => {
  const { goToPremiumScreen } = useGoToPremiumScreen()
  const history = useHistory()

  return (
    <Content $isDirectionColumn>
      <Header $isCentered $spacing={{ p: spaces.xl, mb: spaces.xl }}>
        <LockImage src={Lock3D} />
        <FlexBox $isDirectionColumn>
          <HeaderTitle>Your free version has expired</HeaderTitle>
          <HeaderDescription>
            You will no longer be able to access this organizational chart as it&apos;s exceeding some limits.
          </HeaderDescription>
        </FlexBox>
      </Header>

      <ContentTitle>Purchase Org Chart PRO today and regain access to your organizational charts.</ContentTitle>
      <FlexBox $isXCentered $spacing={{ mb: spaces.l }}>
        <StyledButton
          buttonType='warning'
          leftEl={<GemIcon $spacing={{ mr: spaces.m }} />}
          onClick={() => goToPremiumScreen()}
        >
          Upgrade to Org Chart PRO
          <StyledMdKeyboardArrowRight size={26} />
        </StyledButton>
      </FlexBox>
      <FlexBox $isCentered $spacing={{ mb: spaces.xl }}>
        <Link spacing={{ mb: spaces.l }} as='div' onClick={() => history.push(CHARTS_PATH)}>
          Go back to your overview
        </Link>
      </FlexBox>

      <PremiumBenefits noUpgradeButton />
    </Content>
  )
}

const Content = styled(FlexBox)`
  max-width: 700px;
  margin-right: auto;
  border-radius: 8px;
  margin-left: auto;
  text-align: center;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    max-width: 764px;
    padding: ${props => props.theme.spaces.xl};
    background: ${props => props.theme.colors.white};
  }
`

const Header = styled(FlexBox)`
  flex-direction: column;
  border-radius: 8px;
  background: linear-gradient(133deg, #9e2900 0%, #ce5f38 100%);
  color: ${props => props.theme.colors.white};
  font-weight: 500;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    flex-direction: row;
    text-align: left;
  }
`

const LockImage = styled.img`
  /* Negative margins remove image's whitespace */
  margin-left: -10px;
  margin-bottom: calc(${props => props.theme.spaces.l} - 11px);
  margin-right: -11px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-right: calc(${props => props.theme.spaces.xl} - 11px);
    margin-bottom: -11px;
  }
`

const HeaderTitle = styled.div`
  margin-bottom: ${props => props.theme.spaces.l};
  font-size: 20px;
  line-height: 26px;
`

const HeaderDescription = styled.div`
  max-width: 417px;
  font-size: 14px;
  line-height: 22px;
`

const ContentTitle = styled.div`
  margin-bottom: ${props => props.theme.spaces.l};
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
`

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 340px;
  min-height: 48px;
  font-size: 16px;
  line-height: 24px;
`

const StyledMdKeyboardArrowRight = styled(MdKeyboardArrowRight)`
  margin-bottom: 1px;
`
