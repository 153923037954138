import styled from 'styled-components'
import { DragElementWrapper } from 'react-dnd'

import { FolderIcon } from 'components/icons'

import { colors } from 'theme'
import { pluralize } from 'helpers'

type DirectoryButtonProps = {
  canDrop: boolean
  departmentCount: number
  employeeCount: number
  isOver: boolean
  dropRef: DragElementWrapper<any>
  onOpen: () => void
}

export const DirectoryButton = ({
  canDrop,
  departmentCount,
  employeeCount,
  isOver,
  dropRef,
  onOpen,
}: DirectoryButtonProps) => {
  const totalCount = employeeCount + departmentCount

  const getButtonBackground: () => string = () => {
    if (isOver) return colors.purpleExtraLight
    if (canDrop || totalCount <= 0) return colors.neutralSecondary
    return colors.purple
  }
  const getButtonBorderColor: () => string = () => {
    if (isOver) return colors.purple
    if (canDrop || totalCount <= 0) return colors.silver
    return colors.purple
  }

  return (
    <Button
      background={getButtonBackground()}
      borderColor={getButtonBorderColor()}
      canDrop={canDrop}
      totalCount={totalCount}
      onClick={onOpen}
      ref={dropRef}
      data-testid='button-directory'
    >
      <StyledFolderIcon canDrop={canDrop} totalCount={totalCount} />
      {canDrop && <span>Drop here to move employee/department to the directory</span>}
      {totalCount <= 0 && !canDrop && <span>Directory</span>}
      {totalCount > 0 && !canDrop && (
        <span>
          Directory ({employeeCount} {pluralize('employee', employeeCount)}, {departmentCount}{' '}
          {pluralize('department', departmentCount)})
        </span>
      )}
    </Button>
  )
}

const Button = styled.div<{ background: string; borderColor: string; canDrop: boolean; totalCount: number }>`
  position: absolute;
  top: ${props => props.theme.spaces.m};
  right: ${props => props.theme.spaces.m};
  height: ${props => (props.totalCount > 0 ? '24px' : '26px')};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 2px 6px 1px;
  background: ${props => props.background};
  border: 2px ${props => (props.canDrop ? 'dashed' : 'solid')};
  border-color: ${props => props.borderColor};
  border-radius: 3px;
  color: ${props => (props.totalCount > 0 && !props.canDrop ? colors.white : colors.dark)};
  font-weight: 500;
  font-size: ${props => (props.totalCount > 0 ? '13px' : '14px')};
  cursor: pointer;
  z-index: 10;

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    right: unset;
    left: ${props => props.theme.spaces.m};
  }

  @media (max-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    top: unset;
    right: unset;
    bottom: ${props => props.theme.spaces.l};
    left: ${props => props.theme.spaces.l};
    height: ${props => (props.totalCount > 0 ? '28px' : '32px')};
  }
`

const StyledFolderIcon = styled(FolderIcon)<{ canDrop: boolean; totalCount: number }>`
  width: 15px;
  height: auto;
  margin-right: 6.5px;
  margin-bottom: 1px;
  color: ${props =>
    props.totalCount > 0 && !props.canDrop ? 'rgba(255, 255, 255, 0.6)' : props.theme.colors.greyLight};
`
