import tc from 'tinycolor2'
import { colors, ColorType } from 'theme'

export const addTransparencyToColor = (color: string, transparency: number) => {
  return tc(color).setAlpha(transparency).toRgbString()
}

export const isColorDark = (background: string): boolean => {
  return tc(background).isDark()
}

export const addTransparencyWithBackground = (hexColor: HexString, backgroundHexColor: HexString, opacity: number) => {
  const { r: rColor, g: gColor, b: bColor } = hexToRGB(hexColor)
  const { r: rBackground, g: gBackground, b: bBackground } = hexToRGB(backgroundHexColor)

  const r = Math.floor(rColor * opacity + rBackground * (1 - opacity))
  const g = Math.floor(gColor * opacity + gBackground * (1 - opacity))
  const b = Math.floor(bColor * opacity + bBackground * (1 - opacity))

  return rgbToHex(r, g, b)
}

export type NodeColor = {
  value: string
  textColor: string
  gradient: string
}
const INIT_NODE_COLOR: NodeColor = {
  value: colors.greyLight,
  textColor: colors.white,
  gradient: 'linear-gradient(100.79deg, #83869C 0%, #BDC0D2 100%)',
}
type NodeColors = Partial<Record<ColorType, NodeColor>>
export const NODE_COLORS: NodeColors = {
  greyLight: INIT_NODE_COLOR,
  purple: {
    value: colors.purple,
    textColor: colors.white,
    gradient: 'linear-gradient(100.79deg, #36006F 0%, #4D009D 100%)',
  },
  purpleLight: {
    value: colors.purpleLight,
    textColor: colors.white,
    gradient: 'linear-gradient(100.79deg, #4A148C 0%, #8E24AA 100%)',
  },
  redLighter: {
    value: colors.redLighter,
    textColor: colors.white,
    gradient: 'linear-gradient(100.79deg, #B71C1C 0%, #E53935 100%)',
  },
  orangeLight: {
    value: colors.orangeLight,
    textColor: colors.white,
    gradient: 'linear-gradient(100.79deg, #E65100 0%, #FB8C00 100%)',
  },
  yellowishGreen: {
    value: colors.yellowishGreen,
    textColor: colors.white,
    gradient: 'linear-gradient(100.79deg, #827717 0%, #C0CA33 100%)',
  },
  darkCyan: {
    value: colors.darkCyan,
    textColor: colors.white,
    gradient: 'linear-gradient(100.79deg, #004D40 0%, #00897B 100%)',
  },
  cyan: {
    value: colors.cyan,
    textColor: colors.white,
    gradient: 'linear-gradient(100.79deg, #006064 0%, #00ACC1 100%)',
  },
  lightBlue: {
    value: colors.lightBlue,
    textColor: colors.white,
    gradient: 'linear-gradient(100.79deg, #01579B 0%, #039BE5 100%)',
  },
  darkBlue: {
    value: colors.darkBlue,
    textColor: colors.white,
    gradient: 'linear-gradient(100.79deg, #1A237E 0%, #3949AB 100%)',
  },
}

// TODO: transform old colors to new in BE database
const OLD_COLORS_TABLE: Record<string, string> = {
  '#F1F5F7': colors.greyBluishDark,
}
const COLOR_HEX_TO_NAME: Record<string, ColorType> = {
  '#83869C': 'greyLight',
  '#4D009D': 'purple',
  '#8E24AA': 'purpleLight',
  '#E53935': 'redLighter',
  '#FB8C00': 'orangeLight',
  '#C0CA33': 'yellowishGreen',
  '#00897B': 'darkCyan',
  '#00ACC1': 'cyan',
  '#039BE5': 'lightBlue',
  '#3949AB': 'darkBlue',
}
export const getNodeColorPallete = (color = ''): NodeColor => {
  const newColor = OLD_COLORS_TABLE[color] || color
  const colorPallete = NODE_COLORS[COLOR_HEX_TO_NAME[newColor]]
  return colorPallete
    ? {
        value: colorPallete.value,
        textColor: colorPallete.textColor,
        gradient: colorPallete.gradient,
      }
    : INIT_NODE_COLOR
}

export type HexString = string

export type RGBColor = {
  r: number
  g: number
  b: number
  a?: number
}

export const hexToRGB = (hex: HexString, alpha?: number): RGBColor => {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return { r, g, b, a: alpha }
}

// different way of hexToRgb (should be faster)
const hexToRgb = (hexString: string) => {
  const colorInt = parseInt(hexString.replace('#', ''), 16)
  return [(colorInt >> 16) & 255, (colorInt >> 8) & 255, colorInt & 255] // eslint-disable-line no-bitwise
}

function componentToHex(c: number) {
  const hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

export const rgbToHex = (r: number, g: number, b: number) => {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
}

export const isTextColorDark = (backgroundColor: HexString): boolean => {
  const { r, g, b } = hexToRGB(backgroundColor)
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 // simplified version
}

// different way of dimming color to rgba
export function dimColor(color: string, opacity: number) {
  const rgba = [...hexToRgb(color), opacity]
  return `rgba(${rgba.join(',')})`
}

export const applyAlphaToHex = (hex: HexString, opacity: number) => {
  const { r, g, b } = hexToRGB(hex)
  const d = (1 - opacity) * 255
  const rDim = Math.round(d + opacity * r)
  const gDim = Math.round(d + opacity * g)
  const bDim = Math.round(d + opacity * b)
  return rgbToHex(rDim, gDim, bDim) // still non-alpha format #rrggbb
}
