import styled, { css } from 'styled-components'

const RadioButtonChecked = css<{ disabled?: boolean }>`
  border: 2px solid ${props => (props.disabled ? props.theme.colors.purpleVeryLight : props.theme.colors.purple)};

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${props => (props.disabled ? props.theme.colors.purpleVeryLight : props.theme.colors.purple)};
    transform: translate(-50%, -50%);
  }
`

export const RadioButton = styled.div<{
  isChecked?: boolean
  disabled?: boolean
}>`
  position: relative;
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid ${props => (props.disabled ? props.theme.colors.greyExtraLight : props.theme.colors.border)};
  background: ${props => props.theme.colors.white};

  ${props => props.isChecked && RadioButtonChecked}
`
