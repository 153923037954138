import { createContext, useContext } from 'react'

// explanation and usage https://www.carlrippon.com/react-context-with-typescript-p4/
export const createSafeContext = <ContextType,>() => {
  const context = createContext<ContextType | undefined>(undefined)

  type SafeContextType<T> = T extends true ? ContextType | undefined : T extends false ? ContextType : never
  const useSafeContext = <T extends boolean = false>(canBeEmpty?: T): SafeContextType<T> => {
    const c = useContext(context)
    if (c) return c as SafeContextType<T>
    if (canBeEmpty) return undefined as SafeContextType<T>
    throw new Error(
      `You are using useSafeContext which doesn't have Provider with a value.\nPlease add Provider and a value for context in component below.`
    )
  }
  return [useSafeContext, context.Provider] as const
}
