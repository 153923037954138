import styled, { css } from 'styled-components'

import { SpinnerIcon } from 'components/icons'
import { useLoadingOverTree, useTree } from 'tree/providers'

export const LoadingOverTree = () => {
  const { initialized } = useTree()
  const { showLoadingOverTree } = useLoadingOverTree()
  if (!showLoadingOverTree && initialized) return null
  return (
    <LoadingOverTreeInner>
      <Background />
      <StyledSpinnerIcon />
    </LoadingOverTreeInner>
  )
}

const fullDimensionsCSS = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const LoadingOverTreeInner = styled.div`
  ${fullDimensionsCSS}
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  z-index: 99;
`

const Background = styled.div`
  ${fullDimensionsCSS}
  background: ${props => props.theme.colors.dark};
  opacity: 0.5;
  animation: ${props => props.theme.keyframes.halfOpacity} 0.4s linear;
`

const StyledSpinnerIcon = styled(SpinnerIcon)`
  position: relative;
  width: 100px;
  height: auto;
  color: ${props => props.theme.colors.white};
  z-index: 2;
`
