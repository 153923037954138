import { useHistory } from 'react-router-dom'

import { Button } from 'components/generic'

import { LOGIN_PATH } from 'routes'

export const RegisterToApp = () => {
  const history = useHistory()

  return (
    <Button
      buttonType='secondary'
      buttonSize='small'
      onClick={() => history.push(LOGIN_PATH)}
      data-testid='button-top-navigation-register-to-app'
    >
      Register or log in now
    </Button>
  )
}
