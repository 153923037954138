import styled, { css } from 'styled-components'

export const OptionRow = styled.div<{ $isLast?: boolean }>`
  display: flex;
  align-items: center;
  padding: ${props => props.theme.spaces.m} 0;
  border-bottom: 1px solid #e6e7ed;
  border-top: 1px solid ${props => props.theme.colors.transparent};

  ${props =>
    props.$isLast &&
    css`
      border-bottom: none;
      margin-bottom: ${props => props.theme.spaces.m};
    `};

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    flex-wrap: wrap;
    gap: ${props => props.theme.spaces.s};
  }
`
