import { SVG, SVGProps } from './SVG'

export const GemIcon = (props: SVGProps) => (
  <SVG width='16' height='14' viewBox='0 0 16 14' fill='none' {...props}>
    <path
      d='M15.9093 5.2125L12.4093 0.2125C12.3156 0.0796875 12.1625 0 12 0H3.97184C3.83747 0 3.68434 0.0796876 3.59059 0.213438L0.0905912 5.21344C-0.0451588 5.40728 -0.0265963 5.66937 0.134529 5.84188L7.63453 13.8419C7.73122 13.9438 7.86247 14 7.99997 14C8.13747 14 8.26981 13.9429 8.36466 13.8418L15.8647 5.84181C16.0281 5.66875 16.0437 5.40625 15.9093 5.2125ZM11.9812 1.34781L14.5406 5H9.19997L11.9812 1.34781ZM7.99997 4.925L5.00934 1H10.9906L7.99997 4.925ZM4.01872 1.34781L6.79997 5H1.46028L4.01872 1.34781ZM7.99997 12.7688L1.65403 6H14.3447L7.99997 12.7688Z'
      fill='currentColor'
    />
  </SVG>
)
