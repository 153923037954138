import styled from 'styled-components'

import { nodeBorderCSS } from './node-border-css'
import { getNodeWidth } from '../../helpers'
import { NodeTypename } from 'tree/types'
import { colors } from 'theme'

type Props = {
  $canDrop: boolean
  $compact: boolean
  $isOver: boolean
  $type: NodeTypename
  $background?: string
  $borderColor?: string
  $hasFullBorder?: boolean
}

export const NodeInner = styled.div.attrs<Props>(({ $canDrop, $isOver, $type, $background, $borderColor, theme }) => ({
  className: 'node-inner',
  style: {
    borderBottomWidth: $type === 'Company' ? '0px' : '2px',
    borderColor: $borderColor ?? theme.colors.transparent,
    background: $background ?? colors.white,
    cursor: $type === 'Company' ? 'default' : 'pointer',
    transform: $isOver && $canDrop ? `scale(1.1)` : 'none',
    opacity: $isOver && !$canDrop ? '0.5' : '1',
  },
}))<Props>`
  position: relative;
  width: ${props => getNodeWidth(props.$compact, props.$type)};
  height: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px rgba(69, 90, 100, 0.16);
  border-style: solid;
  border-radius: 4px;
  padding: 0 ${props => props.theme.spaces.s};
  transition: width 0.1s ease, transform 0.1s ease;

  /* Pre-apply border color */
  &::after {
    border-color: ${props => props.$borderColor ?? props.theme.colors.transparent};
  }

  /* Border if the node is logged user */
  ${props => props.$hasFullBorder && nodeBorderCSS}

  /* Border animation for "Find myself" function */
  &.animate-border {
    ${nodeBorderCSS}
    &::after {
      animation: ${props => props.theme.keyframes.borderHighlight} 1s ease-in;
    }
  }
`
