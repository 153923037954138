import styled from 'styled-components'

import { AvatarCircleMiniature } from '../../avatar-circle-miniature'
import { RowNode } from '../../row-node'
import { DepartmentIcon, ProfileIcon } from 'components/icons'

import { pluralize, capitalize } from 'helpers'
import { spaces } from 'theme'

import { DepartmentDataFragmentFragment, PersonDataFragmentFragment } from 'apollo/generated/graphql'

type Props = {
  items: PersonDataFragmentFragment[] | DepartmentDataFragmentFragment[]
  itemType: 'employee' | 'department'
  listedItemsCount: number
}

export const ChartCardColumn = ({ items, itemType, listedItemsCount }: Props) => {
  const unlistedItemsCount = listedItemsCount - items.length

  return (
    <Container>
      <TypeHeading>
        {listedItemsCount} {pluralize(capitalize(itemType), listedItemsCount)}
      </TypeHeading>
      {items.map(item => (
        <StyledRowNode
          key={item.uuid}
          title={item.name}
          description={
            item.__typename === 'Person'
              ? item.position
              : `${item.employeeCount} ${pluralize('Employee', item.employeeCount)}`
          }
          icon={
            <AvatarCircleMiniature
              src={item.__typename === 'Person' ? item.image : undefined}
              fallbackIcon={item.__typename === 'Person' ? <StyledProfileIcon /> : <StyledDepartmentIcon />}
            />
          }
          spacing={{ mt: spaces.l }}
        />
      ))}
      {unlistedItemsCount > 0 && (
        <UnlistedCount>{`+ ${unlistedItemsCount} ${pluralize(
          capitalize(itemType),
          unlistedItemsCount
        )}`}</UnlistedCount>
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-right: 10px;
`

const TypeHeading = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
`

const StyledRowNode = styled(RowNode)`
  background: transparent;
`

const UnlistedCount = styled.div`
  font-weight: normal;
  margin-top: 12px;
  margin-left: 40px;
  color: ${props => props.theme.colors.grey};
  font-size: 12px;
  line-height: 18px;
`

const StyledProfileIcon = styled(ProfileIcon)`
  width: 16px;
  height: auto;
  color: ${props => props.theme.colors.border};
`

const StyledDepartmentIcon = styled(DepartmentIcon)`
  width: 16px;
  height: auto;
  color: ${props => props.theme.colors.black};
`
