import { getDate } from './get-date'

/** Returns date as time value in ms. */
export const getDateTimeValue = (date: Date | string | number): number | null => {
  if (typeof date === 'number') {
    return date
  }

  if (typeof date === 'string') {
    const dateAsDate = getDate(date)
    return dateAsDate ? dateAsDate.getTime() : null
  }

  return date.getTime()
}
