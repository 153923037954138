import { SVG, SVGProps } from './SVG'

export const AddDepartmentIcon = (props: SVGProps) => (
  <SVG width='18' height='11' viewBox='0 0 18 11' fill='none' {...props}>
    <path
      d='M4.99996 3V1H0V10H10V3H4.99996ZM2 9H1V8H2V9ZM2 7H1V6H2V7ZM2 5H1V4H2V5ZM2 3H1V2H2V3ZM4 9H3V8H4V9ZM4 7H3V6H4V7ZM4 5H3V4H4V5ZM4 3H3V2H4V3ZM9 9L4.99996 9.08333L5 8H6V7H4.99996L5 6H6V5H5V4H9V9ZM8 5H7V6H8V5ZM8 7H7V8H8V7Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.5 11C15.5376 11 18 8.53757 18 5.5C18 2.46243 15.5376 0 12.5 0C9.46243 0 7 2.46243 7 5.5C7 8.53757 9.46243 11 12.5 11ZM13 6H16V5H13V2H12V5H9V6H12V9H13V6Z'
      fill='currentColor'
    />
    <path d='M16 6H13V9H12V6H9V5H12V2H13V5H16V6Z' fill='white' />
  </SVG>
)
