import { gql } from '@apollo/client'
import { chartDetailFragment } from '../fragments'

export const chart = gql`
  query chart($key: String!) {
    chart(key: $key) {
      ...ChartDetailFragment
    }
  }
  ${chartDetailFragment}
`
