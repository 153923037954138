import { Dispatch, ReactNode, SetStateAction, useState } from 'react'

import { createSafeContext } from 'helpers'

type Context = {
  isShown: boolean
  setIsShown: Dispatch<SetStateAction<boolean>>
}

export const [useInviteCollaboratorsPanel, EmptyInviteCollaboratorsPanelProvider] = createSafeContext<Context>()

type Props = {
  children: ReactNode
}

export const InviteCollaboratorsPanelProvider = ({ children }: Props) => {
  const [isShown, setIsShown] = useState(false)

  return (
    <EmptyInviteCollaboratorsPanelProvider value={{ isShown, setIsShown }}>
      {children}
    </EmptyInviteCollaboratorsPanelProvider>
  )
}
