import { FlipClock, FlipClockProps } from './flip-clock'
import { FlexBox } from './flex'

import { isNumber } from 'helpers'

type Countdown = {
  days?: number
  hours?: number
  minutes?: number
  seconds?: number
}

type PickFlipClockProps = Pick<FlipClockProps, 'background' | 'color' | 'flipPadHeight' | 'flipPadWidth'>

export type FlipClockCountdownProps = PickFlipClockProps & {
  countdown: Countdown
  className?: string
}

export const FlipClockCountdown = ({ countdown, className, ...flipClockProps }: FlipClockCountdownProps) => {
  const { days, hours, minutes, seconds } = countdown

  return (
    <FlexBox $gap='m' className={className}>
      {isNumber(days) && <FlipClock description='DAYS' number={days} {...flipClockProps} />}
      {isNumber(hours) && <FlipClock description='HRS' number={hours} {...flipClockProps} />}
      {isNumber(minutes) && <FlipClock description='MINS' number={minutes} {...flipClockProps} />}
      {isNumber(seconds) && <FlipClock description='SEC' number={seconds} {...flipClockProps} />}
    </FlexBox>
  )
}
