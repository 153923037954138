import { css } from 'styled-components'

export type SpacingProps = {
  // Margin
  m?: number | string // All sides
  mx?: number | string // Left, Right
  my?: number | string // Top, Down
  mt?: number | string // Top
  mr?: number | string // Right
  mb?: number | string // Bottom
  ml?: number | string // Left

  // Padding
  p?: number | string // All sides
  px?: number | string // Left, Right
  py?: number | string // Top, Down
  pt?: number | string // Top
  pr?: number | string // Right
  pb?: number | string // Bottom
  pl?: number | string // Left
}

const stringOrPx = (value: number | string) => (typeof value === 'number' ? `${value}px` : value)

export const applySpacingProps = (spacing: SpacingProps = {}) => {
  return css`
    /* Margin */
    ${spacing.m !== undefined &&
    css`
      margin: ${stringOrPx(spacing.m)};
    `}

    ${spacing.mx !== undefined &&
    css`
      margin-left: ${stringOrPx(spacing.mx)};
      margin-right: ${stringOrPx(spacing.mx)};
    `}

    ${spacing.my !== undefined &&
    css`
      margin-top: ${stringOrPx(spacing.my)};
      margin-bottom: ${stringOrPx(spacing.my)};
    `}

    ${spacing.mt !== undefined &&
    css`
      margin-top: ${stringOrPx(spacing.mt)};
    `}

    ${spacing.mr !== undefined &&
    css`
      margin-right: ${stringOrPx(spacing.mr)};
    `}

    ${spacing.mb !== undefined &&
    css`
      margin-bottom: ${stringOrPx(spacing.mb)};
    `}

    ${spacing.ml !== undefined &&
    css`
      margin-left: ${stringOrPx(spacing.ml)};
    `}

    /* Padding */
    ${spacing.p !== undefined &&
    css`
      padding: ${stringOrPx(spacing.p)};
    `}

    ${spacing.px !== undefined &&
    css`
      padding-left: ${stringOrPx(spacing.px)};
      padding-right: ${stringOrPx(spacing.px)};
    `}

    ${spacing.py !== undefined &&
    css`
      padding-top: ${stringOrPx(spacing.py)};
      padding-bottom: ${stringOrPx(spacing.py)};
    `}

    ${spacing.pt !== undefined &&
    css`
      padding-top: ${stringOrPx(spacing.pt)};
    `}

    ${spacing.pr !== undefined &&
    css`
      padding-right: ${stringOrPx(spacing.pr)};
    `}

    ${spacing.pb !== undefined &&
    css`
      padding-bottom: ${stringOrPx(spacing.pb)};
    `}

    ${spacing.pl !== undefined &&
    css`
      padding-left: ${stringOrPx(spacing.pl)};
    `}
  `
}
