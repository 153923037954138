import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'
import ResizeObserverPolyfill from 'resize-observer-polyfill'

const ResizeObserver = window.ResizeObserver || ResizeObserverPolyfill

export const useResizeObserver = ({ targetRef }: { targetRef: MutableRefObject<HTMLDivElement | null> }) => {
  const observer = useRef<ResizeObserver | null>(null)
  const [size, setSize] = useState({ width: 0, height: 0 })

  const onResize = useCallback((entries: ResizeObserverEntry[]) => {
    const { width, height } = entries[0].contentRect
    setSize({ width, height })
  }, [])

  useEffect(() => {
    if (targetRef.current) {
      if (observer.current) observer.current.unobserve(targetRef.current)
      observer.current = new ResizeObserver(onResize)
      observer.current.observe(targetRef.current)
    }

    return () => {
      if (observer.current && targetRef.current) {
        observer.current.unobserve(targetRef.current)
      }
    }
  }, [targetRef.current])

  return { width: size.width, height: size.height }
}
