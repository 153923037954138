import styled from 'styled-components'

export const NavlinkTitle = styled.div`
  margin-top: ${props => props.theme.spaces.l};
  margin-bottom: ${props => props.theme.spaces.s};
  margin-left: ${props => props.theme.spaces.m};
  color: ${props => props.theme.colors.greyLight};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
`
