import { ReactNode } from 'react'
import styled from 'styled-components'

import { LineDivider } from './styled'
import { spaces } from 'theme'

type Props = {
  items: ReactNode[]
  className?: string
  horizontalSpacing?: string
  marginBottom?: string
}

export const SeparatedInlineList = ({
  items,
  className,
  horizontalSpacing = spaces.m,
  marginBottom = spaces.s,
}: Props) => (
  <Container className={className} $marginBottom={`-${marginBottom}`}>
    {items.map((item, i) => (
      <Container key={i} $marginBottom={marginBottom}>
        {item}
        {i < items.length - 1 && <LineDivider $horizontalSpacing={horizontalSpacing} />}
      </Container>
    ))}
  </Container>
)

const Container = styled.div<{ $marginBottom?: string }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${props => props.$marginBottom};
`
