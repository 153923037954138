import { NoWrap } from 'components/generic'

import { ConfirmationModalType } from '../confirmation-modal'
import { useModal } from 'modal'

type ResyncModalProps = {
  onResync: () => void
  onClose?: () => void
}

export const useResyncModal = () => {
  const { open } = useModal()

  const openResyncModal = ({ onResync, onClose }: ResyncModalProps) => {
    open<ConfirmationModalType>('confirmationModal', {
      title: { text: 'Attention!' },
      message: (
        <div>
          The connection to Google Workspace is not synced right now. Please <NoWrap>re-connect</NoWrap> the chart with
          Google Workspace by clicking the &quot;Connect Google Workspace&quot; button below.
          <br />
          <br />
          Don&apos;t worry, it&apos;s a quick and easy process. Just follow the prompts and you&apos;ll be up and
          running in no time.
        </div>
      ),
      confirmButton: { children: 'Connect Google Workspace' },
      onConfirm: onResync,
      onClose,
    })
  }

  return { openResyncModal }
}
