import { SVG, SVGProps } from './SVG'

export const ProfileInfoCardIcon = (props: SVGProps) => (
  <SVG width='18' height='14' viewBox='0 0 18 14' fill='none' {...props}>
    <path
      d='M2 0C0.896875 0 0 0.896875 0 2V12C0 13.1031 0.896875 14 2 14H16C17.1031 14 18 13.1031 18 12V2C18 0.896875 17.1031 0 16 0H2ZM4.5 8H6.5C7.88125 8 9 9.11875 9 10.5C9 10.775 8.775 11 8.5 11H2.5C2.225 11 2 10.775 2 10.5C2 9.11875 3.11875 8 4.5 8ZM3.5 5C3.5 4.46957 3.71071 3.96086 4.08579 3.58579C4.46086 3.21071 4.96957 3 5.5 3C6.03043 3 6.53914 3.21071 6.91421 3.58579C7.28929 3.96086 7.5 4.46957 7.5 5C7.5 5.53043 7.28929 6.03914 6.91421 6.41421C6.53914 6.78929 6.03043 7 5.5 7C4.96957 7 4.46086 6.78929 4.08579 6.41421C3.71071 6.03914 3.5 5.53043 3.5 5ZM11.5 4H15.5C15.775 4 16 4.225 16 4.5C16 4.775 15.775 5 15.5 5H11.5C11.225 5 11 4.775 11 4.5C11 4.225 11.225 4 11.5 4ZM11.5 6H15.5C15.775 6 16 6.225 16 6.5C16 6.775 15.775 7 15.5 7H11.5C11.225 7 11 6.775 11 6.5C11 6.225 11.225 6 11.5 6ZM11.5 8H15.5C15.775 8 16 8.225 16 8.5C16 8.775 15.775 9 15.5 9H11.5C11.225 9 11 8.775 11 8.5C11 8.225 11.225 8 11.5 8Z'
      fill='currentColor'
    />
  </SVG>
)
