import { FieldContainer, FieldTitle } from 'components/generic'
import { CustomDatePicker, CustomDatePickerInputProps } from './custom-date-picker'

type Props = {
  title: string
} & CustomDatePickerInputProps

export const CustomDatePickerField = ({ title, ...datePickerProps }: Props) => (
  <FieldContainer>
    <FieldTitle title={title} />
    <CustomDatePicker {...datePickerProps} />
  </FieldContainer>
)
