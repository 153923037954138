import { DetailValue, NodeDetailItem } from '../../../node-components'
import { CustomFieldIconBox, Link, SeparatedInlineList } from 'components/generic'

import { CustomFieldValueFilledWithoutConnection, SelectedDropdownOption } from 'tree/types'
import { isValidUrl } from 'helpers'
import { spaces } from 'theme'

const getSelectedOptionsItems = (selectedOptions: SelectedDropdownOption[]) => {
  return selectedOptions.map(({ id, value, icon, background }) => (
    <>
      {(icon || background) && <CustomFieldIconBox key={id} backgroundColor={background} icon={icon} value={value} />}
      <DetailValue spacing={{ ml: spaces.s }}>{value}</DetailValue>
    </>
  ))
}

const getValue = (f: CustomFieldValueFilledWithoutConnection) => {
  switch (f.type) {
    case 'text': {
      if (isValidUrl(f.value)) {
        return <Link href={f.value}>{f.value}</Link>
      }
      return f.value
    }
    case 'single-date':
      return f.value
    case 'single-select':
      return (
        <SeparatedInlineList
          items={getSelectedOptionsItems(f.selectedOption ? [f.selectedOption] : [])}
          marginBottom={spaces.m}
        />
      )

    default:
      console.error('Custom field:', f, ' is not handled in employee detail!')
      return null
  }
}

type Props = {
  customFields: CustomFieldValueFilledWithoutConnection[]
}

export const CustomFieldsDetail = ({ customFields }: Props) => {
  const nonEmptyCustomFieldsValue = customFields.filter(f => {
    if (f.type === 'text' || f.type === 'single-date') return f.value
    return f.selectedOption
  })

  return (
    <>
      {nonEmptyCustomFieldsValue.map(cf => (
        <NodeDetailItem key={cf.id} title={cf.label} value={getValue(cf)} />
      ))}
    </>
  )
}
