import styled from 'styled-components'
import { generatePath, Link } from 'react-router-dom'

import { Badge, Button } from 'components/generic'
import { GraphBarIcon } from 'components/icons'

import { INSIGHTS_OVERVIEW_PATH } from 'routes'
import { spaces } from 'theme'

type Props = {
  chartUuid: string
  animate?: boolean
  className?: string
  editPageVisited?: boolean
}

export const InsightsButton = ({ chartUuid, editPageVisited, className, animate = true }: Props) => (
  <Link
    to={{
      pathname: generatePath(INSIGHTS_OVERVIEW_PATH, { chartUuid }),
      state: { from: window.location.pathname },
    }}
  >
    <Button
      className={className}
      buttonSize='small'
      leftEl={<StyledGraphBarIcon />}
      rightEl={
        <Badge $color='yellow' $size='small' $spacing={{ ml: spaces.s }} $animate={Boolean(editPageVisited && animate)}>
          BETA
        </Badge>
      }
    >
      Insights
    </Button>
  </Link>
)

const StyledGraphBarIcon = styled(GraphBarIcon)`
  margin-right: ${props => props.theme.spaces.s};
  width: 18px;
`
