import { useEffect, useCallback } from 'react'
import { RouteComponentProps, useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'

import { GenericOnErrorModalType } from 'components/specific'
import { Button, Loading } from 'components/generic'

import { chart as chartQuery } from 'apollo/query'

import { useGoogleWorkspace } from 'tree/hooks'
import { useChartId } from 'tree/providers'
import { useModal } from 'modal'
import { CONNECT_GSUITE_PATH_NAME } from 'routes'
import { handleErrorValidation, extractErrorCode, ErrorCode, getCodeVerifier } from 'helpers'
import { TrackKeyName } from 'types'
import analytics from 'analytics'
import { PERMISSIONS_TRACK } from 'analytics/constants'
import { spaces } from 'theme'

import { useUpdateChartTokenMutation, OAuth2Scope } from 'apollo/generated/graphql'

export type ConnectGoogleWorkspaceProps = RouteComponentProps & {
  redirectUrl?: string
  onSuccess?: () => void
  onFailure?: (error: Error, errorCode?: ErrorCode) => void
}

export const ConnectGoogleWorkspace = ({ redirectUrl, onFailure, onSuccess }: ConnectGoogleWorkspaceProps) => {
  const history = useHistory<{ code: string; state: string; requestedScopes: OAuth2Scope[]; error: unknown }>()
  const { params } = useRouteMatch<{ chartUuid?: string }>()
  const chartUuid = useChartId(true) || params?.chartUuid
  const { open } = useModal()

  const { pathname, state: { code, state: codeState, requestedScopes = [], error: locationStateError } = {} } =
    history.location
  const defaultRedirectUrl = pathname.replace(`/${CONNECT_GSUITE_PATH_NAME}`, '')
  const redirectPath = redirectUrl ?? defaultRedirectUrl

  const [updateChartTokenMutation] = useUpdateChartTokenMutation()
  const { authorize } = useGoogleWorkspace()

  const redirectToRoute = () => {
    history.replace(redirectPath)
  }

  const trackError = (message: TrackKeyName, error: Error, errorCode?: ErrorCode) => {
    handleErrorValidation({ track: { message, values: { chartUuid, error, errorCode } } })
  }

  const defaultOnSuccessHandler = useCallback(() => undefined, [])
  const defaultOnFailureHandler = useCallback(
    (error: Error, errorCode?: ErrorCode) => {
      if (errorCode === 'GSUITE_INSUFFICIENT_PERMISSIONS') {
        open<GenericOnErrorModalType>('genericOnErrorModal', {
          title: 'Account incompatible',
          button: (
            <Button
              spacing={{ mt: spaces.l, ml: 'auto' }}
              onClick={() =>
                authorize({
                  redirectPath: pathname,
                  scopes: requestedScopes,
                })
              }
            >
              Try again
            </Button>
          ),
          children:
            'You granted permission for the different account. Please grant the permission for the account you used during the creation of your Org Chart.',
        })
        trackError('Adding write permission for Google Workspace failed - Insufficient permissions', error, errorCode)
      } else {
        open<GenericOnErrorModalType>('genericOnErrorModal')
        trackError('Adding write permission for Google Workspace failed - unknown error', error, errorCode)
      }
    },
    [open, authorize, pathname]
  )

  useEffect(() => {
    if (code && codeState && chartUuid) {
      const data = {
        code,
        codeVerifier: getCodeVerifier(),
        state: codeState,
      }
      analytics.track(PERMISSIONS_TRACK.updateChartToken, { chartUuid, requestedScopes })
      updateChartTokenMutation({
        variables: { uuid: chartUuid, data },
        refetchQueries: [{ query: chartQuery, variables: { key: chartUuid } }],
      })
        .then(() => {
          redirectToRoute()
          analytics.track(PERMISSIONS_TRACK.updateChartTokenSuccess, { chartUuid, requestedScopes })
          const onSuccessHandler = onSuccess ?? defaultOnSuccessHandler
          onSuccessHandler()
        })
        .catch(error => {
          redirectToRoute()
          const errorCode = extractErrorCode(error)
          analytics.track(PERMISSIONS_TRACK.updateChartTokenFailure, { chartUuid, error, errorCode, requestedScopes })
          const onFailureHandler = onFailure ?? defaultOnFailureHandler
          onFailureHandler(error, errorCode)
        })
    } else if (locationStateError) {
      open<GenericOnErrorModalType>('genericOnErrorModal', {
        title: String(locationStateError),
        button: (
          <Button
            spacing={{ mt: spaces.l, ml: 'auto' }}
            onClick={() =>
              authorize({
                redirectPath: pathname,
                scopes: requestedScopes,
              })
            }
          >
            Try again
          </Button>
        ),
        children: String(locationStateError),
      })
      redirectToRoute()
    } else {
      redirectToRoute()
    }
  }, [code, codeState, chartUuid])

  return (
    <Container>
      <Loading size='big' />
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.white};
  z-index: 300;
`
