import { ApolloCache } from '@apollo/client'

import { useChartId } from '../providers/use-chart-uuid'

import { getChartNormalizedId } from 'apollo'
import { chartMeFragment } from 'apollo/fragments'
import { ChartMeFragmentFragment } from 'apollo/generated/graphql'

export const useUpdateMeOnChart = () => {
  const chartUuid = useChartId()

  const updateMeOnChart = (meData: ChartMeFragmentFragment['me'], cache: ApolloCache<any>) => {
    cache.writeFragment<ChartMeFragmentFragment>({
      id: getChartNormalizedId(chartUuid),
      fragment: chartMeFragment,
      data: { id: chartUuid, __typename: 'Chart', me: meData },
    })
  }

  return { updateMeOnChart }
}
