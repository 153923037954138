import { useEffect, useRef, useState, useCallback, useMemo, MutableRefObject, ReactNode } from 'react'
import { ApolloCache, NormalizedCacheObject, useApolloClient } from '@apollo/client'
import { ExecutionResult } from 'graphql'

import { useTreeState, TreeState } from './use-tree-state'
import { useCompanyNodeState } from './use-company-node-state'
import { useChartId } from './use-chart-uuid'
import { useLoadingOverTree } from './use-loading-over-tree'
import { COMPANY_UUID } from 'consts'
import { ExpandedNode, Node } from '../types'
import { createSafeContext, handleErrorValidation } from 'helpers'
import analytics from 'analytics'
import { ORG_TRACK } from 'analytics/constants'

import { getNodeNormalizedId } from 'apollo/helpers'
import { nodeDataFragment } from 'apollo/fragments'
import { node as nodeQuery, nodes as nodesQuery, chart as chartQuery } from 'apollo/query'
import {
  MakeChartEditableMutation,
  NodeDataFragmentFragment,
  NodeSearchDataFragmentFragment,
  NodesQuery,
  NodesQueryVariables,
  PersonDetailDataFragmentFragment,
  useMakeChartEditableMutation,
  useToggleSubtreeMutation,
} from 'apollo/generated/graphql'

type NodeRefs = Partial<Record<string, { ref: MutableRefObject<HTMLElement> }>>
export type TreeConnection = {
  top: number
  left: number
  width: number
  height: number
  x1: number
  y1: number
  x2: number
  y2: number
  strokeColor: string
  strokeDasharray: string
}

type TreeConnectionRefs = Record<string, TreeConnection>

type ZoomToNode = Pick<Node, 'uuid'> & {
  parentNodes: Node['parentNodes'] | NodeSearchDataFragmentFragment['parentNodes']
}

type UseTreeControl = {
  watch: <K extends keyof TreeState>(name: K, watchId: string) => TreeState[K]
  useWatchFieldsRef: MutableRefObject<Record<string, Set<string>>>
  useWatchRenderFunctionsRef: MutableRefObject<Record<string, () => void>>
  valueRef: MutableRefObject<TreeState>
}

export type UseTreeResult = {
  control: UseTreeControl
  initialized: boolean
  nodeRefs: MutableRefObject<NodeRefs>
  padRef: MutableRefObject<HTMLDivElement | null>
  treeBoundariesRef: MutableRefObject<HTMLDivElement | null>
  connectionLinesRefs: MutableRefObject<TreeConnectionRefs>
  collapseAll: () => void
  expandNodesOnMove: (parentUuid: string | null, parentUuidList: (string | null)[]) => Promise<void>
  getNodesByParentUuidList: (parentUuidList: (string | null)[]) => Promise<NodeDataFragmentFragment[]>
  getUniqueExpandedNodesFromList: (
    orderedUuidList: (string | null)[],
    currExpandedNodes: ExpandedNode[]
  ) => ExpandedNode[]
  register: (uuid: string, ref: MutableRefObject<HTMLElement>) => void
  registerConnection: (id: string, connection: TreeConnection) => void
  unregisterConnection: (id: string) => void
  resetPosition: () => void
  restoreCache: (
    chartUuid: string,
    nodesToExpand: (string | null)[],
    items?: NodeDataFragmentFragment[]
  ) => Promise<void | PromiseSettledResult<unknown>[]>
  setCompact: (compact: boolean) => void
  setExpandedNode: (node: Node, expanded: boolean) => void
  setPosition: (x: number, y: number) => void
  setZoom: (zoom: number) => void
  unregister: (uuid: string) => void
  useMakeChartEditable: (
    expandedNodes: TreeState['expandedNodes']
  ) => ({
    chartUuid,
    personUuid,
  }: {
    chartUuid: string
    personUuid: string
  }) => Promise<ExecutionResult<MakeChartEditableMutation>>
  writeNodesToCache: (chartUuid: string, parentUuid: string | null, items?: NodeDataFragmentFragment[]) => void
  zoomTo: (node: ZoomToNode) => void
}

export const [useTree, TreeContextSafeProvider] = createSafeContext<UseTreeResult>()

type Props = {
  children: ReactNode
}

export const TreeContextProvider = ({ children }: Props) => {
  const chartUuid = useChartId()
  const apolloClient = useApolloClient()

  const [makeEditableMutation] = useMakeChartEditableMutation()
  const [toggleSubtreeMutation] = useToggleSubtreeMutation()

  const { setShowLoadingOverTree } = useLoadingOverTree()
  const [initialized, setInitialized] = useState(false)

  const {
    treeStateRef: chartStateRef,
    getValidExpandedNodes,
    replaceExpandedNodes,
    resetPosition,
    setCompact,
    setExpandedNode,
    setNodeInDom,
    setPosition,
    setZoom,
  } = useTreeState()
  const { expanded: isCompanyNodeExpanded, setExpanded: setCompanyNodeExpanded } = useCompanyNodeState()

  const padRef = useRef<HTMLDivElement>(null)
  const treeBoundariesRef = useRef<HTMLDivElement>(null)
  const connectionLinesRefs = useRef<TreeConnectionRefs>({})
  const nodeRefs = useRef<NodeRefs>({})
  const useWatchFieldsRef = useRef<Record<string, Set<string>>>({})
  const useWatchRenderFunctionsRef = useRef<Record<string, () => void>>({})

  const renderWatchedFieldComponents = useCallback((name: keyof TreeState) => {
    for (const key in useWatchFieldsRef.current) {
      if (!name || !useWatchFieldsRef.current[key].size || useWatchFieldsRef.current[key].has(name)) {
        useWatchRenderFunctionsRef.current[key]()
      }
    }
  }, [])

  const renderAllWatchedFieldComponents = useCallback(() => {
    for (const key in useWatchRenderFunctionsRef.current) {
      if (Object.prototype.hasOwnProperty.call(useWatchRenderFunctionsRef.current, key)) {
        useWatchRenderFunctionsRef.current[key]()
      }
    }
  }, [])

  const watch = useCallback(
    <K extends keyof TreeState>(name: K, watchId: string): TreeState[K] => {
      const watchFields = useWatchFieldsRef.current[watchId]
      watchFields.add(name)
      return chartStateRef.current[name]
    },
    [chartStateRef]
  )

  const control = useMemo(
    () => ({
      watch,
      useWatchFieldsRef,
      useWatchRenderFunctionsRef,
      valueRef: chartStateRef,
    }),
    [watch, chartStateRef]
  )

  const register = useCallback((uuid: string, ref: MutableRefObject<HTMLElement>) => {
    nodeRefs.current[uuid] = { ref }
    setNodeInDom(uuid, true)
    renderWatchedFieldComponents('nodesInDom')
  }, [])

  const unregister = useCallback((uuid: string) => {
    if (nodeRefs.current[uuid]) {
      delete nodeRefs.current[uuid]
      setNodeInDom(uuid, false)
      renderWatchedFieldComponents('nodesInDom')
    }
  }, [])

  const registerConnection = useCallback((id: string, connection: TreeConnection) => {
    connectionLinesRefs.current[id] = connection
    renderWatchedFieldComponents('onConnectionLinesChange')
  }, [])

  const unregisterConnection = useCallback((id: string) => {
    if (connectionLinesRefs.current[id]) {
      delete connectionLinesRefs.current[id]
      renderWatchedFieldComponents('onConnectionLinesChange')
    }
  }, [])

  const setCompactInternal = useCallback(
    (compact: boolean) => {
      setCompact(compact)
      renderWatchedFieldComponents('compact')
    },
    [setCompact, renderWatchedFieldComponents]
  )

  const setPositionInternal = useCallback(
    (x: number, y: number) => {
      setPosition(x, y)
      renderWatchedFieldComponents('positionX')
      renderWatchedFieldComponents('positionY')
    },
    [setPosition, renderWatchedFieldComponents]
  )

  const setZoomInternal = useCallback(
    (zoom: number) => {
      setZoom(zoom)
      renderWatchedFieldComponents('zoom')
    },
    [setZoom, renderWatchedFieldComponents]
  )

  const resetPositionInternal = useCallback(() => {
    resetPosition()
    renderWatchedFieldComponents('positionX')
    renderWatchedFieldComponents('positionY')
  }, [resetPosition, renderWatchedFieldComponents])

  const setExpandedNodeInternal = useCallback(
    (node: Node, expanded: boolean) => {
      if (node.uuid === COMPANY_UUID) setCompanyNodeExpanded(expanded)
      else toggleSubtreeMutation({ variables: { id: node.uuid, expanded } })
      setExpandedNode(node, expanded)
    },
    [setCompanyNodeExpanded, toggleSubtreeMutation, setExpandedNode]
  )

  const writeNodesToCache = useCallback(
    (chartUuid: string, parentUuid: string | null, items: NodeDataFragmentFragment[] = []) => {
      apolloClient.writeQuery<NodesQuery, NodesQueryVariables>({
        query: nodesQuery,
        variables: { chartKey: chartUuid, filter: { unassigned: false, parentUuid } },
        data: { __typename: 'Query', nodes: { __typename: 'NodeCollection', items } },
      })
    },
    [apolloClient]
  )

  const restoreCache = useCallback(
    async (chartUuid: string, nodesToExpand: (string | null)[], items: NodeDataFragmentFragment[] = []) => {
      if (items.length === 0) {
        // Add empty array under company node
        return writeNodesToCache(chartUuid, null)
      }

      const parentMap = items.reduce<Record<string, NodeDataFragmentFragment[]>>((mapped, node) => {
        const parentUuid = node.parentUuid || COMPANY_UUID
        if (!mapped[parentUuid]) mapped[parentUuid] = []
        mapped[parentUuid].push(node)
        return mapped
      }, {})

      // Write queries to the apollo cache
      Object.keys(parentMap).forEach(parentUuid => {
        const items = parentMap[parentUuid]
        const parentUuidOrNull = parentUuid === COMPANY_UUID ? null : parentUuid
        writeNodesToCache(chartUuid, parentUuidOrNull, items)
      })

      // Expand nodes
      const expandPromiseList = nodesToExpand.map(parentUuidOrNull => {
        if (parentUuidOrNull === null) {
          if (!isCompanyNodeExpanded) {
            setCompanyNodeExpanded(true)
            // make sure that company node is expanded (this is replacement for setCompanyNodeExpanded callback)
            return new Promise(resolve => setTimeout(() => resolve(''), 5))
          }
          return Promise.resolve()
        }
        return toggleSubtreeMutation({ variables: { id: parentUuidOrNull, expanded: true } })
      })

      return Promise.allSettled(expandPromiseList)
    },
    [isCompanyNodeExpanded, writeNodesToCache, setCompanyNodeExpanded, toggleSubtreeMutation]
  )

  const getUniqueExpandedNodesFromList = useCallback(
    // orderedUuidList -> uuid's from top (company node) to bottom
    (orderedUuidList: (string | null)[], currExpandedNodes: ExpandedNode[]) => {
      const expandedNodes = orderedUuidList.reduce((mapped: ExpandedNode[], uuid, index) => {
        const isAlreadyExpanded = currExpandedNodes.some(expandedNode => expandedNode.uuid === uuid)
        if (!isAlreadyExpanded) {
          if (index === 0 && !isCompanyNodeExpanded) mapped.push({ uuid: null, parentUuid: null })
          mapped.push({ uuid, parentUuid: index === 0 ? null : orderedUuidList[index - 1] })
        }
        return mapped
      }, [])
      return expandedNodes
    },
    [isCompanyNodeExpanded]
  )

  const getNodesByParentUuidList = useCallback(
    async (parentUuidList: (string | null)[]) => {
      return new Promise((resolve: (items: NodeDataFragmentFragment[]) => void, reject) => {
        if (parentUuidList.length === 0) {
          resolve([])
        }

        // Get cached nodes
        let cachedNodes: NodeDataFragmentFragment[] = []
        const nonCachedParentUuidList = parentUuidList.filter(uuid => {
          const result = apolloClient.readQuery<NodesQuery, NodesQueryVariables>({
            query: nodesQuery,
            variables: { chartKey: chartUuid, filter: { unassigned: false, parentUuid: uuid } },
          })
          if (result) {
            cachedNodes = [...cachedNodes, ...result.nodes.items]
            return false
          }
          return true
        })

        if (nonCachedParentUuidList.length === 0) {
          // All nodes were cached
          resolve(cachedNodes)
        } else {
          // Get non cached nodes
          apolloClient
            .query<NodesQuery, NodesQueryVariables>({
              query: nodesQuery,
              variables: {
                chartKey: chartUuid,
                filter: { unassigned: false, parentUuidList: nonCachedParentUuidList },
              },
              fetchPolicy: 'no-cache',
            })
            .then(res => resolve([...cachedNodes, ...res.data.nodes.items]))
            .catch(reject)
        }
      })
    },
    [apolloClient, chartUuid]
  )

  const collapseAll = useCallback(() => {
    const expandedNodes = chartStateRef.current.expandedNodes
    chartStateRef.current.expandedNodes.forEach(node => {
      if (node.uuid) toggleSubtreeMutation({ variables: { id: node.uuid, expanded: false } })
    })
    if (expandedNodes.length > 0) replaceExpandedNodes([])
  }, [chartStateRef, toggleSubtreeMutation, replaceExpandedNodes])

  const zoomTo = useCallback(
    async ({ parentNodes, uuid }: ZoomToNode) => {
      analytics.track(ORG_TRACK.zoomToNode, { chartUuid, zoomedNodeUuid: uuid })
      setShowLoadingOverTree(true)

      const parentUuidList: (string | null)[] = (parentNodes || []).map(({ uuid }) => uuid)
      parentUuidList.push(null) // add company node (null) as it's not in parentNodes

      let zoomAttempts = 0
      const MAX_ATTEMPTS = 20
      const moveNodeToCenter = () => {
        const nodeInnerRef = nodeRefs.current[uuid]?.ref?.current
        if (nodeInnerRef) {
          const {
            width: padWidth = window.innerWidth, // width fallback
            height: padHeight = window.innerHeight, // height fallback
            top: padTop = 0,
            left: padLeft = 0,
          } = padRef.current?.getBoundingClientRect() || {}

          const { left: nodeLeft, top: nodeTop, width: nodeWidth } = nodeInnerRef.getBoundingClientRect()

          const dx = (padWidth / 2 - nodeWidth / 2 - (nodeLeft - padLeft)) * (1 / chartStateRef.current.zoom)
          const dy = (padHeight / 2 - (nodeTop + padTop / 2)) * (1 / chartStateRef.current.zoom)

          setPositionInternal(chartStateRef.current.positionX + dx, chartStateRef.current.positionY + dy)
          setShowLoadingOverTree(false)

          // Highlight node
          nodeInnerRef.classList.add('animate-border')

          return Promise.resolve()
        }
        // FIXME: Due to React 18 new feature - automatic batching, node is registered but not in DOM at this moment
        // Better method is to define custom toggleSubtreeMutation where you finish the promise AFTER the nodes are in DOM (and not finish promise after just mutation execution when you don't have nodes in DOM)
        if (zoomAttempts >= MAX_ATTEMPTS) {
          return Promise.reject('zoomedNodeWrapper is not defined')
        }
        zoomAttempts++
        setTimeout(moveNodeToCenter, 250)
      }

      getNodesByParentUuidList(parentUuidList)
        .then(nodes => restoreCache(chartUuid, parentUuidList, nodes))
        .then(() => {
          const currExpandedNodes = chartStateRef.current.expandedNodes
          const newExpandedNodes = getUniqueExpandedNodesFromList(parentUuidList, currExpandedNodes)
          replaceExpandedNodes([...currExpandedNodes, ...newExpandedNodes])
          return moveNodeToCenter()
        })
        .catch(error => {
          handleErrorValidation({
            track: { message: ORG_TRACK.zoomToNodeFailure, values: { chartUuid, zoomedNodeUuid: uuid, error } },
            toast: { message: 'Failed to see node in Org Chart. Please refresh the page and try again.' },
          })
          console.error(error)
          setShowLoadingOverTree(false)
        })
    },
    [
      chartStateRef,
      chartUuid,
      getNodesByParentUuidList,
      getUniqueExpandedNodesFromList,
      replaceExpandedNodes,
      restoreCache,
      setPositionInternal,
      setShowLoadingOverTree,
    ]
  )

  const useMakeChartEditable = useCallback(
    (expandedNodes: TreeState['expandedNodes']) => {
      const parentUuidList = expandedNodes.map(node => node.uuid)

      return async ({ chartUuid, personUuid }: { chartUuid: string; personUuid: string }) => {
        return makeEditableMutation({
          variables: { uuid: chartUuid },
          refetchQueries: [
            { query: chartQuery, variables: { key: chartUuid } },
            { query: nodeQuery, variables: { uuid: personUuid, chartKey: chartUuid } },
            {
              query: nodesQuery,
              variables: { chartKey: chartUuid, filter: { parentUuidList, unassigned: false } },
              fetchPolicy: 'no-cache',
            },
          ],
          update: cache => {
            // Remove each Person from cache to force new data request on Employee's detail
            Object.values((cache as any).data.data).forEach(node => {
              const typedNode = node as PersonDetailDataFragmentFragment
              if (typedNode.__typename === 'Person') delete (cache as any).data.data[typedNode.uuid]
            })
          },
        })
      }
    },
    [makeEditableMutation]
  )

  const expandNodesOnMove = async (parentUuid: string | null, parentUuidList: (string | null)[]) => {
    const items = await getNodesByParentUuidList(parentUuidList)
    await restoreCache(chartUuid, parentUuidList, items)

    const currExpandedNodes = chartStateRef.current.expandedNodes
    const newExpandedNodes = getUniqueExpandedNodesFromList(parentUuidList, currExpandedNodes)
    replaceExpandedNodes([...currExpandedNodes, ...newExpandedNodes])
    if (!parentUuid) return

    // Remove parent node from expanded if it has 0 subordinates
    const cache = apolloClient.cache as ApolloCache<NormalizedCacheObject>
    const prevParentId = getNodeNormalizedId(parentUuid, cache)
    if (!prevParentId) return

    const prevParentNode = apolloClient.readFragment<NodeDataFragmentFragment>({
      id: prevParentId,
      fragment: nodeDataFragment,
      fragmentName: 'NodeDataFragment',
    })
    if (!prevParentNode) return

    const { uuid, employeeCount, departmentCount } = prevParentNode
    if (employeeCount + departmentCount === 0) setExpandedNode({ uuid }, false)
  }

  // Restore chart state
  useEffect(() => {
    setInitialized(false)
    const expandedNodes = getValidExpandedNodes()
    const parentUuidList = expandedNodes.map(({ uuid }) => uuid)
    const shouldFetchNodes = parentUuidList.length > 0

    if (shouldFetchNodes) {
      getNodesByParentUuidList(parentUuidList)
        .then(items => restoreCache(chartUuid, parentUuidList, items))
        .finally(() => {
          // Replace restored nodes in the chart state
          replaceExpandedNodes(expandedNodes)
          renderAllWatchedFieldComponents() // Rerender components on chart uuid change
          setInitialized(true)
        })
    } else {
      renderAllWatchedFieldComponents() // Rerender components on chart uuid change
      setInitialized(true)
    }
  }, [chartUuid])

  return (
    <TreeContextSafeProvider
      value={{
        control,
        initialized,
        nodeRefs,
        padRef,
        treeBoundariesRef,
        connectionLinesRefs,
        expandNodesOnMove,
        collapseAll,
        getNodesByParentUuidList,
        getUniqueExpandedNodesFromList,
        register,
        registerConnection,
        unregisterConnection,
        resetPosition: resetPositionInternal,
        restoreCache,
        setCompact: setCompactInternal,
        setExpandedNode: setExpandedNodeInternal,
        setPosition: setPositionInternal,
        setZoom: setZoomInternal,
        unregister,
        useMakeChartEditable,
        writeNodesToCache,
        zoomTo,
      }}
    >
      {children}
    </TreeContextSafeProvider>
  )
}
