import { useRef, ReactNode } from 'react'
import styled from 'styled-components'
import { Formik, FormikProps } from 'formik'

import { ChartFormValues, OnChartFormSubmit } from 'tree/types'

type ChartFormContainerProps = {
  children: (s: FormikProps<ChartFormValues>) => ReactNode
  onSubmit: OnChartFormSubmit
  className?: string
  initialData?: ChartFormValues
}

export const ChartForm = ({ children, onSubmit, className, initialData = {} }: ChartFormContainerProps) => {
  const formRef = useRef<HTMLFormElement>(null)

  return (
    <Formik<ChartFormValues>
      enableReinitialize
      initialValues={initialData}
      onSubmit={(values, actions) => {
        formRef?.current?.focus()
        onSubmit({ values, actions })
      }}
      validate={values => (values.name ? undefined : { name: 'Required' })}
    >
      {formState => (
        <Form className={className} ref={formRef} tabIndex={0} onSubmit={formState.handleSubmit}>
          {children(formState)}
        </Form>
      )}
    </Formik>
  )
}

const Form = styled.form`
  outline: none;
`
