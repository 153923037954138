import { useParams } from 'react-router-dom'

import { DepartmentEdit } from './edit'
import { NodeComponent } from '../node-components'

import { useCreateNodeAction } from 'tree/actions'
import { OnDepartmentFormSubmit } from 'tree/types'

export const CreateDepartment = ({ onClose }: NodeComponent) => {
  const { parentUuid: parentUuidFromUrl } = useParams<{ parentUuid: string }>()
  const parentUuid = parentUuidFromUrl === 'null' ? null : parentUuidFromUrl

  const { createNode } = useCreateNodeAction()
  const handleSubmit: OnDepartmentFormSubmit = ({ values, actions }) => {
    createNode({ values, actions, nodeTypename: 'Department' }).then(() => {
      actions.setSubmitting(false)
      onClose()
    })
  }

  return <DepartmentEdit parentUuid={parentUuid} onClose={onClose} onSubmit={handleSubmit} createMode />
}
