import styled, { css } from 'styled-components'

import { TREE_COMPACT_SIZES, TREE_STANDARD_SIZES } from '../helpers'
import { NODE_COMPACT_SIZES } from '../chart-nodes/helpers'

type Props = {
  $compact: boolean
  $verticalSize: string
  $isCompanyNode?: boolean
  $isFlex?: boolean
  $nodesCount?: number
}

export const TreeItem = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: ${props => props.$verticalSize} 0;
  padding-bottom: 0;

  ${props =>
    props.$compact &&
    css<Props>`
      display: ${props => (props.$isFlex ? 'flex' : 'grid')};
      grid: repeat(${props.$nodesCount ? computeRowsCount(props.$nodesCount) : 5}, ${NODE_COMPACT_SIZES.height}) / auto-flow auto;
      padding: 0;
      padding-top: ${props => props.$verticalSize};
    `}

  ${props =>
    props.$compact &&
    props.$nodesCount &&
    shouldShrink(props.$nodesCount) &&
    css`
      /* Get all nodes together without space */
      & > div {
        margin: 0 -16px;
      }
      /* And have one padding on container */
      padding-right: 10px;
      padding-left: 10px;
    `}

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: ${props => (props.$compact ? TREE_COMPACT_SIZES : TREE_STANDARD_SIZES).vertical};
    box-sizing: border-box;
    border-top: 1px solid ${props => props.theme.colors.border};
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: calc(50% + 1px);
    height: ${props => (props.$compact ? TREE_COMPACT_SIZES : TREE_STANDARD_SIZES).vertical};
    box-sizing: border-box;
    border-top: 1px solid ${props => props.theme.colors.border};
    border-left: 1px solid ${props => props.theme.colors.border};
  }

  &:only-of-type::before {
    ${props =>
      !props.$isCompanyNode &&
      css`
        border-right: 1px solid ${props => props.theme.colors.border} !important;
      `}
  }

  &:last-of-type::before {
    border-right: 1px solid ${props => props.theme.colors.border};
    width: 50%;
  }

  &:first-of-type::before,
  :last-of-type::after {
    border: 0 none;
  }
`

const computeRowsCount = (elementsCount: number) => {
  if (elementsCount < 6) return elementsCount // 1 row
  if (elementsCount < 17) return Math.ceil(elementsCount / 2) // 2 rows
  if (elementsCount < 46) return Math.min(Math.ceil(elementsCount / 3), 9) // 3 to 5 rows
  return Math.ceil(elementsCount / 3) // 3 to infinity rows
}

const shouldShrink = (elementsCount: number) => {
  if (elementsCount < 6) return false // 1 row
  return true
}
