import styled from 'styled-components'

import { UpgradeSubscriptionButton } from '../upgrade-subscription-button'
import { FlexBox, ModalProps, Modal } from 'components/generic'

const StyledModal = styled((props: ModalProps) => <Modal {...props} />)`
  && {
    width: auto;
    max-width: unset;
  }
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.spaces.xl};
  padding: ${props => props.theme.spaces.xl} ${props => props.theme.spaces.xl};
  overflow: hidden;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    gap: ${props => props.theme.spaces.xxl};
    flex-wrap: nowrap;
    text-align: left;
  }
`

const ChartPreview = styled.div`
  align-self: center;
  position: relative;
  width: 100%;
  max-width: 372px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const CircleContainer = styled.div`
  width: 100%;
  max-width: 300px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    min-width: 300px;
  }
`

const PreviewImage = styled.img`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`

const TextContainer = styled(FlexBox)`
  color: ${props => props.theme.colors.dark};
  line-height: 20px;
  font-weight: 400;
  text-align: center;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    min-width: 440px;
    text-align: left;
  }
`

const Title = styled.h2`
  margin-bottom: ${props => props.theme.spaces.m};
  font-size: 18px;
  font-weight: 500;
`

const Description = styled.p`
  max-width: 440px;
  margin-bottom: ${props => props.theme.spaces.l};
  font-size: 16px;
  line-height: 23px;
`

const SmallTitle = styled.h3`
  margin-bottom: ${props => props.theme.spaces.m};
  font-size: 16px;
  font-weight: 500;
`

const SmallDescription = styled.p`
  max-width: 400px;
  margin-bottom: ${props => props.theme.spaces.l};
  font-size: 14px;
`

const StyledUpgradeSubscriptionButton = styled(UpgradeSubscriptionButton)`
  margin-right: auto;
  margin-left: auto;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-left: 0;
  }
`

export const adModalComponents = {
  StyledModal,
  Content,
  ChartPreview,
  CircleContainer,
  PreviewImage,
  TextContainer,
  Title,
  Description,
  SmallTitle,
  SmallDescription,
  StyledUpgradeSubscriptionButton,
}
