import styled from 'styled-components'

import { InfoContainer } from './styled'
import { Button } from 'components/generic'
import { spaces } from 'theme'

type Props = {
  email: string
  isDeleting: boolean
  onCancel: () => void
  onDelete: () => void
}

export const Deletion = ({ email, isDeleting, onCancel, onDelete }: Props) => (
  <>
    <DeletionInfoContainer>
      {`Do you want to revoke ${email}'s access?`}
      <ButtonsWrap>
        <Button
          buttonSize='small'
          buttonType='secondary'
          onClick={onCancel}
          spacing={{ mr: spaces.s }}
          data-testid='button-delete-collaborator-cancel'
        >
          Cancel
        </Button>
        <Button
          buttonSize='small'
          buttonType='danger'
          disabled={isDeleting}
          onClick={onDelete}
          data-testid='button-delete-collaborator-confirm'
        >
          Remove
        </Button>
      </ButtonsWrap>
    </DeletionInfoContainer>
  </>
)

const DeletionInfoContainer = styled(InfoContainer)`
  min-height: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 0;
  color: ${props => props.theme.colors.dark};
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
`

const ButtonsWrap = styled.div`
  display: flex;
`
