import { ReactNode, createContext, useContext } from 'react'
import { DepFilter } from '../types'

type ContextValue = {
  activeDep: DepFilter
}
const CompanyOverviewContext = createContext<ContextValue>({ activeDep: { value: '', label: '' } })
export const useCompanyOverview = () => useContext(CompanyOverviewContext)

type Props = {
  value: ContextValue
  children: ReactNode
}
export const CompanyOverviewProvider = ({ value, children }: Props) => (
  <CompanyOverviewContext.Provider value={value}>{children}</CompanyOverviewContext.Provider>
)
