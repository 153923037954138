import { SVG, SVGProps } from './SVG'

export const ProfileInCircleIcon = (props: SVGProps) => (
  <SVG width='12' height='12' viewBox='0 0 12 12' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.00008 0.166748C2.78008 0.166748 0.166748 2.78008 0.166748 6.00008C0.166748 9.22008 2.78008 11.8334 6.00008 11.8334C9.22008 11.8334 11.8334 9.22008 11.8334 6.00008C11.8334 2.78008 9.22008 0.166748 6.00008 0.166748ZM3.12423 9.66341C3.37507 9.13841 4.9034 8.62508 6.00007 8.62508C7.09674 8.62508 8.6309 9.13841 8.8759 9.66341C8.08257 10.2934 7.08507 10.6667 6.00007 10.6667C4.91507 10.6667 3.91757 10.2934 3.12423 9.66341ZM6.00008 7.45842C6.85175 7.45842 8.87591 7.80258 9.71008 8.81758C10.3051 8.03592 10.6667 7.06175 10.6667 6.00008C10.6667 3.42758 8.57258 1.33341 6.00008 1.33341C3.42758 1.33341 1.33341 3.42758 1.33341 6.00008C1.33341 7.06175 1.69508 8.03592 2.29008 8.81758C3.12425 7.80258 5.14841 7.45842 6.00008 7.45842ZM6.00008 2.50008C4.86841 2.50008 3.95841 3.41008 3.95841 4.54175C3.95841 5.67341 4.86841 6.58341 6.00008 6.58341C7.13175 6.58341 8.04175 5.67341 8.04175 4.54175C8.04175 3.41008 7.13175 2.50008 6.00008 2.50008ZM5.12508 4.54175C5.12508 5.02592 5.51591 5.41675 6.00008 5.41675C6.48425 5.41675 6.87508 5.02592 6.87508 4.54175C6.87508 4.05758 6.48425 3.66675 6.00008 3.66675C5.51591 3.66675 5.12508 4.05758 5.12508 4.54175Z'
      fill='currentColor'
    />
  </SVG>
)
