type Sizes = {
  width: string
  fullWidth: string
  companyWidth: string
  companyFullWidth: string
  height: string
}

type StandardSizes = Sizes & { reducedHeight: string }
export const NODE_STANDARD_SIZES: StandardSizes = {
  width: '160px',
  fullWidth: '160px',
  companyWidth: '180px',
  companyFullWidth: '180px',
  reducedHeight: '86px', // When no custom fields
  height: '107px',
}

export const NODE_COMPACT_SIZES: Sizes = {
  width: '206px',
  fullWidth: '206px',
  companyWidth: '220px',
  companyFullWidth: '220px',
  height: '70px',
}
