import { SVG, SVGProps } from './SVG'

export const SearchIcon = (props: SVGProps) => (
  <SVG width='14' height='14' viewBox='0 0 14 14' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.1224 9.14285L13.795 12.8037C13.8583 12.8672 13.907 12.9399 13.9412 13.0175C14.054 13.2741 14.0044 13.5845 13.7942 13.7949C13.6566 13.9319 13.4763 14 13.296 14C13.1166 14 12.9363 13.9319 12.7987 13.7949L9.12093 10.1284C8.16389 10.8632 6.9693 11.3055 5.67046 11.3055C2.54384 11.3055 0 8.76955 0 5.65268C0 2.53592 2.54384 0 5.67046 0C8.79707 0 11.3409 2.53592 11.3409 5.65268C11.3409 6.96999 10.8829 8.18059 10.1224 9.14285ZM5.67131 1.40222C3.32058 1.40222 1.40736 3.30913 1.40736 5.65279C1.40736 7.99634 3.32058 9.90324 5.67131 9.90324C8.02204 9.90324 9.93526 7.99634 9.93526 5.65279C9.93526 3.30913 8.02204 1.40222 5.67131 1.40222Z'
      fill='currentColor'
    />
  </SVG>
)
