import { useAuth } from 'auth'

import { AuthLayout } from 'components/layouts'
import { Loading } from 'components/generic'
import { AppRouter } from './AppRouter'

export const Routes = () => {
  const { isLoading, isAuthenticated } = useAuth()

  if (isLoading) return <LoadingComponent />

  return <AppRouter isAuthenticated={isAuthenticated} />
}

const LoadingComponent = () => (
  <AuthLayout>
    <Loading size='big' />
  </AuthLayout>
)
