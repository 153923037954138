import { useState } from 'react'
import styled, { css } from 'styled-components'
import { EmojiData, Picker } from 'emoji-mart'

import { InputClear } from 'components/generic/input'
import { ChevronDownIcon } from 'components/icons'
import 'emoji-mart/css/emoji-mart.css'

export type EmojiPickerProps = {
  value: string
  onSelect: (value: string) => void
  absolute?: boolean
  disabled?: boolean
}

export const EmojiPicker = ({ value, onSelect, absolute, disabled }: EmojiPickerProps) => {
  const [isFieldFocused, setIsFieldFocused] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)

  const showClear = value.length > 0 && isFieldFocused && !disabled

  return (
    <EmojiPickerWrap onMouseEnter={() => setIsFieldFocused(true)} onMouseLeave={() => setIsFieldFocused(false)}>
      <EmojiPickerFieldWrap>
        <EmojiPickerButton
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          isDropdownOpen={isDropdownOpen}
          disabled={disabled}
          data-testid='button-emoji-picker'
        >
          <PlaceholderText>{value}</PlaceholderText>
          <RightWrap>
            {showClear && (
              <InputClear
                onClick={e => {
                  e.stopPropagation()
                  setIsDropdownOpen(false)
                  onSelect('')
                }}
              />
            )}
            <StyledChevronDownIcon isDropdownOpen={isDropdownOpen} />
          </RightWrap>
        </EmojiPickerButton>

        {isDropdownOpen && (
          <Picker
            native
            style={{
              ...(absolute && { position: 'absolute' }),
              zIndex: 10,
              width: '100%',
              minWidth: '300px',
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
            }}
            onSelect={(val: EmojiData & { native: string }) => {
              setIsDropdownOpen(false)
              onSelect(val.native)
            }}
          />
        )}
      </EmojiPickerFieldWrap>
    </EmojiPickerWrap>
  )
}

const EmojiPickerWrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
`

const EmojiPickerFieldWrap = styled.div`
  flex: 1;

  & .emoji-mart-scroll + .emoji-mart-bar {
    display: none;
  }
`

const EmojiPickerButton = styled.div<{ isDropdownOpen: boolean; disabled?: boolean }>`
  flex: 1;
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-right: ${props => props.theme.spaces.m};
  padding-left: ${props => props.theme.spaces.m};
  background: ${props => (props.isDropdownOpen ? props.theme.colors.greyActive : props.theme.colors.neutralSecondary)};
  ${props =>
    props.disabled &&
    css`
      background: ${props.theme.colors.greyUltraLight};
    `}
  line-height: initial;
  cursor: pointer;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  transition: ${props => props.theme.transitions.extraFastEase};
`

const PlaceholderText = styled.div`
  transform: scale(1.5);
  margin-left: 6px;
  position: relative;
  top: -1px;
`

const RightWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

const StyledChevronDownIcon = styled(ChevronDownIcon)<{ isDropdownOpen: boolean }>`
  height: auto;
  color: ${props => props.theme.colors.dark};
  transition: all ${props => props.theme.transitions.fastEase};
  transform: ${props => (props.isDropdownOpen ? 'rotate(180deg)' : 'unset')};
`
