import styled from 'styled-components'
import { ChevronDownIcon } from 'components/icons'

type Direction = 'top' | 'right' | 'bottom' | 'left'
type Size = 'normal' | 'bigger'
type AnimationSpeed = 'slow' | 'normal' | 'fast' | 'extraFast'
type Props = {
  direction?: Direction
  size?: Size
  animationSpeed?: AnimationSpeed
}

export const RotatingChevron = styled(ChevronDownIcon)<Props>`
  color: ${props => props.theme.colors.dark};
  transition: ${props => {
    switch (props?.animationSpeed) {
      case 'slow':
        return props.theme.transitions.slowEase
      case 'normal':
        return props.theme.transitions.mediumEase
      case 'fast':
        return props.theme.transitions.fastEase
      default:
        return props.theme.transitions.extraFastEase
    }
  }};

  transform: ${props => {
    switch (props?.direction) {
      case 'top':
        return `scale(${props.size === 'bigger' ? 1.5 : 1}) rotate(180deg)`
      case 'right':
        return `scale(${props.size === 'bigger' ? 1.5 : 1}) rotate(270deg)`
      case 'left':
        return `scale(${props.size === 'bigger' ? 1.5 : 1}) rotate(90deg)`
      default:
        return `scale(${props.size === 'bigger' ? 1.5 : 1}) rotate(0deg)`
    }
  }};
`
