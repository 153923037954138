import { useRef, useState } from 'react'
import styled from 'styled-components'
import { Formik, FormikConfig, FormikErrors, FormikTouched } from 'formik'

import { UnsavedPrompt } from 'components/specific'
import {
  FieldPublicDomainRestrict,
  Button,
  validateDomain,
  FieldPublicLinkIpRestrict,
  FIELD_PUBLIC_LINK_IP_RESTRICT_LOWEST_SUBNET,
} from 'components/generic'
import { CustomFieldsOnPublicLinkSelect } from './custom-fields-on-public-link-select'

import { useUpdatePublicLink } from './hooks'
import { CustomField } from 'tree/types'
import { validateIpAddress } from 'helpers'
import { spaces } from 'theme'

import { PublicLink } from 'apollo/generated/graphql'

export type PublicLinkFormData = Pick<PublicLink, 'domains' | 'allowedCustomFields'> & {
  domainsInput: string
  ips: readonly string[] | string[]
  ipsInput: string
}

type PublicLinkFormErrors = Partial<Record<keyof PublicLinkFormData, string>>

type Props = {
  chartUuid: string
  customFields: CustomField[]
  publicLink: PublicLink
}

export const PublicLinkForm = ({ chartUuid, customFields, publicLink }: Props) => {
  const formRef = useRef<HTMLFormElement>(null)
  const initialData: PublicLinkFormData = {
    domains: publicLink.domains,
    domainsInput: '',
    ips: publicLink.ips,
    ipsInput: '',
    allowedCustomFields: publicLink.allowedCustomFields || [],
  }
  const { updateLink } = useUpdatePublicLink()
  const [revokeUnsavedPrompt, setRevokeUnsavedPrompt] = useState(false)

  const handleSubmit: FormikConfig<PublicLinkFormData>['onSubmit'] = (data, actions) => {
    formRef?.current?.focus()
    setRevokeUnsavedPrompt(true)
    updateLink(chartUuid, publicLink.uuid, data).finally(() => actions.resetForm({ values: data }))
  }

  return (
    <Formik<PublicLinkFormData>
      enableReinitialize
      initialValues={initialData}
      onSubmit={handleSubmit}
      validate={({ domains, domainsInput, ipsInput }) => {
        const errors: PublicLinkFormErrors = {}

        if (domainsInput.length > 0) {
          const domainError = validateDomain(domainsInput, domains)
          if (domainError) errors.domains = domainError
        }

        if (ipsInput.length > 0) {
          const ipsError = validateIpAddress({
            ipAddress: ipsInput,
            hasSubnet: true,
            subnetLowest: FIELD_PUBLIC_LINK_IP_RESTRICT_LOWEST_SUBNET,
          })
          if (ipsError) errors.ips = ipsError
        }

        return errors
      }}
    >
      {formState => {
        const {
          values,
          isSubmitting,
          dirty,
          setFieldValue,
          setFieldTouched,
          submitForm,
          handleSubmit,
          resetForm,
          setFormikState,
        } = formState
        const isButtonDisabled = isSubmitting || !dirty

        return (
          <Form ref={formRef} tabIndex={0} onSubmit={handleSubmit}>
            <UnsavedPrompt when={!revokeUnsavedPrompt && dirty} />

            <FieldPublicDomainRestrict
              name='domains'
              domains={values.domains}
              inputValue={values.domainsInput}
              onDomainsChange={domains => {
                setFieldValue('domains', domains)
                setFieldTouched('domains', false)
              }}
              onInputChange={value => setFieldValue('domainsInput', value)}
              onError={error => {
                const touched: FormikTouched<PublicLinkFormData> = {
                  ...formState.touched,
                  // Formik has incorrect typing
                  domains: true as unknown as FormikTouched<PublicLinkFormData>['domains'],
                }
                const errors: FormikErrors<PublicLinkFormData> = {
                  ...formState.errors,
                  // Formik has incorrect typing
                  domains: error as unknown as FormikErrors<PublicLinkFormData>['domains'],
                }
                setFormikState({ ...formState, touched, errors })
              }}
            />
            <FieldPublicLinkIpRestrict
              name='ips'
              ips={values.ips}
              inputValue={values.ipsInput}
              onIpsChange={ips => {
                setFieldValue('ips', ips)
                setFieldTouched('ips', false)
              }}
              onInputChange={value => setFieldValue('ipsInput', value)}
              onError={error => {
                const touched: FormikTouched<PublicLinkFormData> = {
                  ...formState.touched,
                  // Formik has incorrect typing
                  ips: true as unknown as FormikTouched<PublicLinkFormData>['ips'],
                }
                const errors: FormikErrors<PublicLinkFormData> = {
                  ...formState.errors,
                  // Formik has incorrect typing
                  ips: error as unknown as FormikErrors<PublicLinkFormData>['ips'],
                }
                setFormikState({ ...formState, touched, errors })
              }}
            />

            <CustomFieldsOnPublicLinkSelect
              customFields={customFields}
              allowedCustomFields={values.allowedCustomFields}
              onChange={options => {
                const allowedCustomFields = options.map(({ fieldId }) => fieldId)
                setFieldValue('allowedCustomFields', allowedCustomFields)
              }}
            />

            <ButtonsContainer>
              <Button
                buttonType='tertiary'
                disabled={isButtonDisabled}
                spacing={{ mr: spaces.m }}
                onClick={() => resetForm()}
              >
                Cancel
              </Button>
              <Button disabled={isButtonDisabled} onClick={submitForm}>
                Save
              </Button>
            </ButtonsContainer>
          </Form>
        )
      }}
    </Formik>
  )
}

const Form = styled.form`
  outline: none;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`
