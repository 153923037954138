import { generatePath, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'

import { Detail, Empty, CreateOrEdit } from './components'
import { NewFieldButton, View, ViewHeader, ViewTitle } from '../components'

import { useChartData, useChartId } from 'tree/providers'
import { useChartPermission } from 'tree/hooks'
import { CustomFieldWithoutConnection } from 'tree/types'
import { getIsSubscribed } from 'features/premium'
import { SETTINGS_CREATE_CUSTOM_FIELD_PATH, SETTINGS_CUSTOM_FIELDS_PATH, SETTINGS_EDIT_CUSTOM_FIELD_PATH } from 'routes'
import analytics from 'analytics'
import { CUSTOM_FIELDS_TRACK } from 'analytics/constants'

// if getCanShowCustomFieldsView
export const CustomFields = () => {
  const chartUuid = useChartId()
  const history = useHistory()
  const { customFields, subscription } = useChartData()
  const { canCreateCustomField } = useChartPermission()
  const isSubscribed = getIsSubscribed(subscription)
  const customFieldsWithoutConnection = customFields.filter(
    value => value.type !== 'connection'
  ) as CustomFieldWithoutConnection[]

  const detailPath = generatePath(SETTINGS_CUSTOM_FIELDS_PATH, { chartUuid })
  const createPath = generatePath(SETTINGS_CREATE_CUSTOM_FIELD_PATH, { chartUuid })

  const isOnDetail = Boolean(useRouteMatch(SETTINGS_CUSTOM_FIELDS_PATH)?.isExact)
  const isCreating = Boolean(useRouteMatch(SETTINGS_CREATE_CUSTOM_FIELD_PATH)?.isExact)
  const isEditing = Boolean(useRouteMatch(SETTINGS_EDIT_CUSTOM_FIELD_PATH)?.isExact)

  const title = isCreating ? 'Create new custom field' : isEditing ? 'Edit custom field' : 'Custom fields'

  const showCreateCF = canCreateCustomField && isSubscribed && isOnDetail

  const goToCreateCustomField = () => {
    history.push(createPath)
    analytics.track(CUSTOM_FIELDS_TRACK.beginCreate, { chartUuid })
  }

  const onBackClick = () => {
    if (isCreating) analytics.track(CUSTOM_FIELDS_TRACK.cancelCreate, { chartUuid })
    history.push(detailPath)
  }

  if (customFieldsWithoutConnection.length === 0 && !isCreating) {
    return <Empty viewTitle='Custom fields' onClick={goToCreateCustomField} />
  }

  return (
    <View quota='chart.customField'>
      <ViewHeader backTitle='Back on custom fields list' onBackClick={isOnDetail ? undefined : onBackClick}>
        <ViewTitle>{title}</ViewTitle>
        {showCreateCF && <NewFieldButton onClick={goToCreateCustomField}>New custom field</NewFieldButton>}
      </ViewHeader>

      <Switch>
        <Route
          path={[SETTINGS_CREATE_CUSTOM_FIELD_PATH, SETTINGS_EDIT_CUSTOM_FIELD_PATH]}
          render={() => <CreateOrEdit customFields={customFields} onBack={() => history.push(detailPath)} />}
          exact
        />
        <Route render={() => <Detail customFieldsWithoutConnection={customFieldsWithoutConnection} />} />
      </Switch>
    </View>
  )
}
