import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { generatePath, useHistory } from 'react-router-dom'

import { ChartItem } from './chart-item'
import { ChevronDownIcon } from 'components/icons'

import { pluralize, stripStr, updateChartCountsInAnalytics } from 'helpers'
import { useCharts, useEscapeKeypressListener } from 'hooks'
import { CHART_EDIT_PATH } from 'routes'

type ChartSelectProps = {
  chartUuid?: string
  className?: string
}

export const ChartSelect = ({ chartUuid, className }: ChartSelectProps) => {
  const { data: charts, loading } = useCharts()
  const history = useHistory()

  const [isOpened, setIsOpened] = useState(false)
  useEscapeKeypressListener(() => setIsOpened(false))

  useEffect(() => updateChartCountsInAnalytics(charts), [charts.length, charts])

  if (charts.length === 0 || !charts || loading) return null

  const activeChart = charts.find(chart => chart.uuid === chartUuid)

  return (
    <ChartSelectWrap className={className} tabIndex={0} onBlur={() => setIsOpened(false)}>
      <ChartSelectButton
        onClick={() => setIsOpened(!isOpened)}
        isActive={Boolean(chartUuid)}
        isOpened={isOpened}
        data-testid='button-top-navigation-org-chart-dropdown'
      >
        <PlaceholderText>
          {activeChart ? (
            <>
              <Emoji>{activeChart.emoji}</Emoji>
              {activeChart.emoji && '\u00a0'}
              {activeChart && stripStr(activeChart.name, 55)}
            </>
          ) : (
            'Select Org Chart'
          )}
        </PlaceholderText>
        <StyledChevronDownIcon isOpened={isOpened} />
      </ChartSelectButton>

      {isOpened && (
        <ChartSelectList>
          <ListHeading>
            {charts.length} Org {pluralize('Chart', charts.length)}
          </ListHeading>
          {charts.map(chart => (
            <ChartItem
              key={chart.uuid}
              chart={chart}
              isActive={chart.uuid === chartUuid}
              onClick={() => {
                history.push(generatePath(CHART_EDIT_PATH, { chartUuid: chart.uuid }))
                setIsOpened(false)
              }}
            />
          ))}
        </ChartSelectList>
      )}
    </ChartSelectWrap>
  )
}

const ChartSelectWrap = styled.div`
  align-self: center;
  position: relative;
  outline: none;
  user-select: none;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    width: 100%;
  }
`

const ChartSelectButton = styled.div<{
  isActive: boolean
  isOpened: boolean
}>`
  display: flex;
  min-width: 100px;
  max-width: 255px;
  height: 34px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-radius: 3px;
  padding: ${props => props.theme.spaces.m};
  background: ${props => (props.isOpened ? props.theme.colors.greyActive : 'none')};
  cursor: pointer;
  transition: ${props => props.theme.transitions.extraFastEase};

  ${props => props.theme.animations.backgroundShapeFill}

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    background: ${props => (props.isOpened ? props.theme.colors.greyActive : props.theme.colors.greyUltraLight)};
  }
`

const PlaceholderText = styled.div`
  height: 100%;
  line-height: 18px;
  display: inline-block;
  margin-right: ${props => props.theme.spaces.m};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const Emoji = styled.div`
  display: inline-block;
  margin-right: ${props => props.theme.spaces.xs};
`

const StyledChevronDownIcon = styled(ChevronDownIcon)<{ isOpened: boolean }>`
  min-width: 16px;
  transition: all ${props => props.theme.transitions.fastEase};
  transform: ${props => (props.isOpened ? 'rotate(180deg)' : 'unset')};
`

const ChartSelectList = styled.div`
  position: fixed;
  width: 280px;
  max-height: calc(100vh - ${props => props.theme.sizes.topNavigation} - ${props => props.theme.spaces.l});
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 3px;
  padding: ${props => props.theme.spaces.m};
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 3px 12px rgba(69, 90, 100, 0.3);
  overflow: auto;
  z-index: 110;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    width: 100%;
    left: 0;
    top: ${props => props.theme.sizes.topNavigationMobile};
  }
`

const ListHeading = styled.div`
  margin: ${props => props.theme.spaces.m};
  color: ${props => props.theme.colors.greyMediumLight};
  font-weight: bold;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  cursor: default;
`
