import { SVG, SVGProps } from './SVG'

export const EmailIcon = (props: SVGProps) => (
  <SVG width='16' height='12' viewBox='0 0 16 12' fill='none' {...props}>
    <path
      d='M15.5 1.5C15.5 0.675 14.825 0 14 0H2C1.175 0 0.5 0.675 0.5 1.5V10.5C0.5 11.325 1.175 12 2 12H14C14.825 12 15.5 11.325 15.5 10.5V1.5ZM14 1.5L8 5.25L2 1.5H14ZM14 10.5H2V3L8 6.75L14 3V10.5Z'
      fill='currentColor'
    />
  </SVG>
)
