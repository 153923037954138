import styled, { css } from 'styled-components'

export const Title = styled.div<{ $maxWidth?: string; $isCentered?: boolean }>`
  display: flex;
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 121%;
  letter-spacing: 0.01em;
  font-weight: bold;
  color: ${props => props.theme.colors.dark};

  ${props =>
    props.$maxWidth &&
    css`
      max-width: ${props.$maxWidth};
    `}

  ${props =>
    props.$isCentered &&
    css`
      text-align: center;
      margin: auto;
      margin-bottom: 20px;
      justify-content: center;
    `};
`
