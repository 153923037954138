import { SVG, SVGProps } from './SVG'

export const SyncIcon = (props: SVGProps) => (
  <SVG width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M12 18C10.4087 18 8.88258 17.3679 7.75736 16.2426C6.63214 15.1174 6 13.5913 6 12C6 11 6.25 10.03 6.7 9.2L5.24 7.74C4.46 8.97 4 10.43 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20V23L16 19L12 15V18ZM12 4V1L8 5L12 9V6C13.5913 6 15.1174 6.63214 16.2426 7.75736C17.3679 8.88258 18 10.4087 18 12C18 13 17.75 13.97 17.3 14.8L18.76 16.26C19.54 15.03 20 13.57 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4Z'
      fill='currentColor'
    />
  </SVG>
)
