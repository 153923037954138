import { BulletListIcon, CalendarIcon, LetterTIcon } from 'components/icons'

import { CustomFieldOption, CustomFieldWithoutConnection, DropdownOptionCreation } from '../types'
import { colors } from 'theme'

export const CUSTOM_FIELD_OPTIONS: readonly CustomFieldOption[] = [
  {
    type: 'text',
    icon: <LetterTIcon color={colors.white} />,
    typeLabel: 'Text',
    description:
      'Ideal for inputting free-form information, such as adding certifications, languages, and work preferences.',
    background: colors.purpleLight,
  },
  {
    type: 'single-select',
    icon: <BulletListIcon color={colors.white} />,
    typeLabel: 'Dropdown',
    description:
      'Ideal for creating dropdown lists, such as grouping employees by seniority (e.g., Associate, Mid-Level, Senior, Principal), employee status (e.g., Full-time, Part-time, Contract), or project assignments (e.g., Project A, Project B, Project C).',
    background: colors.redLighter,
  },
  {
    type: 'single-date',
    icon: <CalendarIcon color={colors.white} />,
    typeLabel: 'Date picker',
    description:
      "Ideal for tracking time-related data, such as employment start dates, recording employees' birthdays, or project deadlines.",
    background: colors.yellowishGreen,
  },
]

export const CUSTOM_FIELD_DEFAULT_OPTION: DropdownOptionCreation = { icon: '💻', value: '', background: '#C1BDFF' }

export const CUSTOM_FIELD_NAME_PLACEHOLDER: Record<CustomFieldWithoutConnection['type'], string> = {
  text: 'E.g. Certifications, Languages, or Work preferences',
  'single-date': "E.g. Start date, Employees' birthdays, or Project deadline",
  'single-select': 'E.g. Seniority, Employee status, or Project assignments',
}
