import { gql } from '@apollo/client'

export const personDetailDataFragment = gql`
  fragment PersonDetailDataFragment on Person {
    employeeCount
    departmentCount
    chartUuid
    unassigned
    deleted
    provider
    calendarUrl
    capabilities {
      canMove
      canDelete
      canUnassign
      canUpdate
      canUpdateName
      canUpdatePosition
      canUpdateEmail
      canUpdateMobile
      canUpdateImage
      canUpdateLocation
      canUpdateEmailAliases
      canUpdateBuildingId
      canUpdateFloorName
      canUpdateFloorSection
      canUpdateDeskCode
      canUpdateEmployeeId
      canUpdateEmployeeType
      canUpdateCostCenter
      canUpdateSocialContacts
    }
    parentNodes {
      ... on Department {
        id
        uuid
        name
        color
        expanded @client
      }
      ... on Person {
        id
        uuid
        name
        expanded @client
      }
    }
    customFields {
      ... on CustomTextFieldValue {
        id
        type
        value
      }
      ... on CustomDateFieldValue {
        id
        type
        value
      }
      ... on CustomDropdownFieldValue {
        id
        type
        value
      }
      ... on CustomConnectionFieldValue {
        id
        type
        values
        nodes {
          ... on Person {
            id
            familyName
            givenName
          }
        }
      }
    }
    email
    image
    mobile
    name
    givenName
    familyName
    location
    buildingId
    floorName
    floorSection
    deskCode
    position
    id
    uuid
    parentUuid
    emailAliases
    employeeType
    employeeId
    costCenter
    type
    teamSizeTest
    xLink
    linkedInLink
    facebookLink
    youTubeLink
    instagramLink
    whatsAppLink
    expanded @client
  }
`
