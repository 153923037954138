import { MutableRefObject, useCallback, useEffect, useRef, CSSProperties } from 'react'
import { useDragDropManager } from 'react-dnd'
import rafSchd from 'raf-schd'

import { applyCSSToElement, dropdownMenu } from 'helpers'

const NODE_STYLES: CSSProperties = {
  position: 'fixed',
  opacity: '0.8',
  userSelect: 'none',
  pointerEvents: 'none',
  zIndex: '999',
}

type Params = {
  isDragging: boolean
  isPreviewEnabled: boolean
  nodeRef: MutableRefObject<HTMLDivElement | null> | undefined
}
export const useChartNodeDragPreview = ({ isDragging, isPreviewEnabled, nodeRef }: Params) => {
  const previewNode = useRef<HTMLDivElement | null>(null)
  const dragDropManager = useDragDropManager()
  const monitor = dragDropManager.getMonitor()

  const showCustomChartNodePreview = useCallback(() => {
    if (nodeRef?.current) {
      previewNode.current = nodeRef.current.cloneNode(true) as HTMLDivElement
      const { x = 0, y = 0 } = monitor.getClientOffset() || {}
      const initNodePositionStyles: CSSProperties = { top: `${y}px`, left: `${x}px` }
      applyCSSToElement(previewNode.current, { ...NODE_STYLES, ...initNodePositionStyles })
      dropdownMenu.append(previewNode.current)
    }
  }, [monitor, nodeRef])

  const hideCustomChartNodePreview = useCallback(() => {
    if (previewNode.current) dropdownMenu.removeChild(previewNode.current)
  }, [])

  const setPreviewPosition = useCallback(() => {
    if (isDragging) {
      const { x = 0, y = 0 } = monitor.getClientOffset() || {}
      if (previewNode.current) {
        previewNode.current.style.top = `${y}px`
        previewNode.current.style.left = `${x}px`
      }
    }
  }, [monitor, isDragging])
  const throttledSetPreviewPosition = rafSchd(setPreviewPosition)

  useEffect(() => {
    const unsuscribe = isPreviewEnabled && monitor.subscribeToOffsetChange(throttledSetPreviewPosition)
    if (unsuscribe) return unsuscribe
  }, [monitor, throttledSetPreviewPosition, isPreviewEnabled])

  return {
    showCustomChartNodePreview,
    hideCustomChartNodePreview,
  }
}
