export const isValidUrl = (link: any): link is string => {
  if (!link) return false

  try {
    new URL(link)
    return true
  } catch (_) {
    return false
  }
}
