import { getCharts, user as getUser } from 'apollo/query'
import { Chart, useDeleteChartMutation } from 'apollo/generated/graphql'

type DeleteChartRequestParams = {
  chartId: Chart['id']
}

export const useDeleteChartRequest = () => {
  const [deleteChartMutation] = useDeleteChartMutation()

  const deleteChartRequest = async ({ chartId }: DeleteChartRequestParams) => {
    return deleteChartMutation({
      variables: { uuid: chartId },
      refetchQueries: [{ query: getCharts }, { query: getUser }],
    })
  }

  return { deleteChartRequest }
}
