import { generatePath, Link } from 'react-router-dom'

import { Button } from 'components/generic'
import { DownloadIcon } from 'components/icons'

import { SETTINGS_DOWNLOAD_PATH } from 'routes'
import { spaces } from 'theme'

type Props = {
  chartUuid: string
}

export const ChartExportButton = ({ chartUuid }: Props) => (
  <Link
    to={{
      pathname: generatePath(SETTINGS_DOWNLOAD_PATH, { chartUuid }),
      state: { from: window.location.pathname },
    }}
  >
    <Button buttonSize='small' buttonType='warning' data-testid='button-top-navigation-chart-export'>
      <DownloadIcon $spacing={{ mr: spaces.s }} />
      Export
    </Button>
  </Link>
)
