export const VerifyEmailImage = () => (
  <svg width='250' height='196' viewBox='0 0 250 196'>
    <g fill='none' fillRule='evenodd'>
      <path
        fill='#216398'
        fillRule='nonzero'
        d='M145.25536,22.9173014 C129.227324,22.4569106 113.962642,18.1567153 99.3960924,13.451569 C84.8295423,8.7464227 70.3692821,3.54231874 54.7253388,1.04994103 C44.6640318,-0.552990479 33.1581482,-0.779570272 25.0511263,3.70140668 C17.2484801,8.01365401 14.7289259,15.4594516 13.3737295,22.3701353 C12.3518969,27.567008 11.7528082,33.0386689 14.5477499,37.9029032 C16.4802939,41.2774958 19.9323005,44.1193849 22.314161,47.354173 C30.5999432,58.6132604 24.744335,72.4948883 15.762837,83.4864186 C11.5498911,88.6423141 6.6629706,93.5619881 3.41146539,99.050522 C0.15996018,104.539056 -1.33776139,110.832671 1.50066255,116.424853 C4.31976105,121.968827 11.037767,126.14127 18.3162107,129.074755 C33.0977562,135.028501 50.5172243,136.73267 67.50187,137.69925 C105.099513,139.834885 142.900073,138.904461 180.599174,137.98368 C194.549725,137.641399 208.563085,137.294298 222.288978,135.500944 C229.910449,134.505439 237.778318,132.926611 243.305394,129.113322 C250.330191,124.273192 252.069481,116.075342 247.363736,110.005896 C239.469294,99.8242679 217.650873,97.2957338 212.121381,86.3692849 C209.082456,80.3552787 212.203515,73.6543019 216.616962,68.0765823 C226.086427,56.111241 241.95986,45.6138474 242.795685,31.9371055 C243.368201,22.5364545 235.739484,13.1358036 223.941303,8.69098296 C211.575438,4.03163468 194.426526,4.61736755 185.290424,12.3307222 C175.91517,20.2706566 159.406414,23.3343046 145.25536,22.9173014 Z'
        opacity='.1'
      />
      <g fillRule='nonzero' transform='translate(174 43)'>
        <path
          fill='#FFB9B9'
          d='M1.62317587 68.5005888C1.62317587 68.5005888-.869527613 79.7638374 4.11587935 77.7614863 9.10128632 75.7591353 6.11004425 68 6.11004425 68L1.62317587 68.5005888zM49.2826889 73C49.2826889 73 55.6863377 80.3053118 51.6840578 81.816753 47.6817778 83.3281943 45.5472275 75.0152536 46.0808645 74.2595361 46.6145015 73.5038187 49.2826889 73 49.2826889 73z'
        />
        <circle cx='34.5' cy='12.5' r='7.5' fill='#FFB9B9' />
        <polygon fill='#FFB9B9' points='29 15 28 22.93 36.25 26 38 17.046' />
        <path
          fill='#7EC53C'
          d='M33.6455833,22.8824242 C33.6455833,22.8824242 29.2770069,20.7426071 28.3689347,19 L26.4300731,20.8369577 C26.4300731,20.8369577 12.5144376,19.5585425 10.4528632,26.4619891 C8.39128891,33.3654357 10.1951664,58.4223851 10.1951664,58.4223851 C10.1951664,58.4223851 7.36049955,69.6724457 8.13358993,69.9281222 C8.90668031,70.1837988 18.4414616,74.7861023 31.8417079,72.2292718 C31.8417079,72.2292718 37.7687363,71.2065375 38.7995256,71.9735866 C39.830315,72.7406358 38.2841321,59.700796 38.2841321,59.700796 C38.2841321,59.700796 48.5920037,36.6893088 46.7881306,33.8767931 C44.9842574,31.0642774 36.9956459,25.1835652 36.9956459,25.1835652 L37.5110395,22.3710516 C37.5110395,22.3710516 33.90328,23.9051564 33.6455833,22.8824242 Z'
        />
        <path
          fill='#7EC53C'
          d='M12.4519536 25C12.4519536 25 7.89863203 27.3011373 7.13974546 30.880683 6.38085889 34.4602288-.702080271 64.1193191.0567998675 65.9090909.815680006 67.6988627.562730677 70 .562730677 70L7.13974975 69.232955C7.13974975 69.232955 5.8749388 67.443181 6.63382537 66.6761381 7.39271194 65.9090952 8.15159851 63.3522741 7.64567413 62.3295474 7.13974975 61.3068206 6.63382537 63.0965924 9.4164116 59.7727305 12.1989978 56.4488686 18.5230504 26.0227289 12.4519536 25zM44.7289566 33C44.7289566 33 46.8488472 33.1296296 47.3476445 36.7592597 47.8464418 40.3888898 48.719337 62.8148162 48.719337 62.8148162 48.719337 62.8148162 51.9615214 70.0740764 50.7145282 71.1111129 49.467535 72.1481495 50.2157288 73.7037043 50.2157288 73.7037043L45.7265512 75 36 59.1851838 44.7289566 33z'
        />
        <path
          fill='#535461'
          d='M9.18900441,69 C9.18900441,69 7.63724665,69.2578124 7.63724665,74.9296867 C7.63724665,80.6015611 4.79236146,98.1328133 6.60274295,100.968749 C8.41312443,103.804686 8.67175036,129.328126 8.67175036,129.328126 C8.67175036,129.328126 13.5856474,131.390625 17.7236578,124.687501 L18.2409097,121.078125 L19.2754134,103.546875 L23.4134304,81.6328237 L29.1032008,105.867187 C29.1032008,105.867187 28.327323,135 31.1722104,135 C34.0170978,135 39.4482444,132.679689 39.4482444,132.679689 C39.4482444,132.679689 40.7413741,130.101565 40.2241222,128.812503 C39.7068704,127.523441 39.1896185,127.781254 39.7068704,126.750002 C40.2241222,125.71875 41,98.9062526 41,98.9062526 C41,98.9062526 39.9654963,71.3203135 38.6723667,70.5468764 C37.379237,69.7734393 9.18900441,69 9.18900441,69 Z'
        />
        <path
          fill='#535461'
          d='M8.75723027 128.193807L4.64214283 132.649726C4.64214283 132.649726-2.81645356 139.726773 3.61336934 139.988886 10.0431922 140.250999 13.6438962 135.795079 13.6438962 135.270854 13.6438962 134.746628 13.901089 132.911839 15.7014388 132.911839 17.5017886 132.911839 21.8740711 128.455919 20.8452954 126.621128 19.8165197 124.786336 16.730208 124 16.730208 124L8.75723027 128.193807zM30.8244343 133.904904C30.8244343 133.904904 27.7805423 140.135093 29.5561469 141.433048 31.3317514 142.731003 31.8390643 143.250185 31.8390643 143.250185 31.8390643 143.250185 33.3610092 142.990594 33.1073517 145.326915 32.8536942 147.663237 42.2390276 148.961192 42.7463426 147.144053 43.2536576 145.326913 43 141.692639 41.985368 140.654277 40.9707359 139.615915 37.9268482 132.347361 37.9268482 132.347361 37.9268482 132.347361 34.6292988 128.453491 30.8244343 133.904904z'
        />
        <path
          fill='#2F2E41'
          d='M42.5833826,6.07738715 L44,5.87393051 C44,5.87393051 42.0279071,2.20408988 38.2109622,1.53771601 L39.550812,0.623419497 C39.550812,0.623419497 37.2225773,-1.05882889 34.2136504,1.10349007 C32.631916,2.24016818 30.7954089,3.58190274 29.4444976,5.27071569 L28.0626045,4.92926345 L28.3289908,6.35573618 L26,7.14091441 L28.1276713,7.42224831 C27.8342501,8.09485414 27.6417888,8.80797976 27.5566507,9.5380462 C27.4741541,10.2689954 27.675768,11.0037584 28.1189419,11.5872643 L28.1189419,11.5872643 C28.1189419,11.5872643 30.5287613,8.63366204 30.6550188,8.11136733 L30.3394017,9.41709874 C30.3394017,9.41709874 31.9138276,8.56078176 32.1032021,7.7773515 L32.5860942,8.86527311 L33.2851967,7.51591747 L37.2783116,10.0246706 L36.8585451,8.67560701 L39.4618891,9.73398268 L38.7532948,8.03679705 C38.7532948,8.03679705 41.3929242,10.4878606 41.1630717,11.9531624 C40.9331788,13.4184342 41.4969313,15 41.4969313,15 C41.4969313,15 45.5118165,8.87654477 42.5833826,6.07738715 Z'
        />
      </g>
      <ellipse cx='166' cy='81' fill='#E6E8EC' fillRule='nonzero' rx='1' ry='1' />
      <path
        fill='#DCDFED'
        fillRule='nonzero'
        d='M120.903882,51.3784465 L58,92.5869234 L99.0507305,167.348029 C99.4385188,168.054806 100.101373,168.565606 100.878842,168.756783 L138.0737,177.91854 C139.387029,178.239306 140.743907,177.590675 141.330626,176.361624 L159.780122,137.657329 L180,110.363939 L132.016877,53.0422333 C129.25446,49.7437817 124.495279,49.0312589 120.903882,51.3784465 Z'
      />
      <path
        fill='#CCC'
        fillRule='nonzero'
        d='M180,110.363085 L179.822003,110.605956 L167.977997,126.594114 L167.76392,126.88313 L159.780521,137.65929 L144.001341,170.764954 L143.558754,171.69029 L141.331388,176.360688 C140.744264,177.591794 139.384564,178.24078 138.069716,177.917487 L100.875591,168.751558 C100.097878,168.560945 99.4348402,168.050032 99.0475158,167.34291 L95.4707413,160.841269 L94.8285095,159.670634 L69.0117508,112.636352 L68.7904574,112.235616 L58,92.5874006 L59.3037066,91.7324967 L69.1873423,85.2600006 L69.4158517,85.1094209 L90.2294558,71.4746766 L91.5427839,70.6149154 L120.900237,51.3820084 C124.491755,49.0303972 129.254803,49.7421105 132.017823,53.0432419 L153.906625,79.2003841 L154.86877,80.366162 L170.756191,99.3440551 L170.842784,99.4484894 L178.830994,108.988439 L179.836435,110.190647 L180,110.363085 Z'
      />
      <path
        fill='#000'
        fillRule='nonzero'
        d='M171,99.7147085 L169.051038,113.259382 L168.463447,117.346971 L168.127336,126.054262 L168.100737,126.733914 L166.352474,172.229506 C166.318394,173.083975 165.907162,173.879308 165.229716,174.40095 C164.552271,174.922591 163.678404,175.1168 162.843859,174.931185 L143.997441,170.722661 L143.794323,170.676706 L95.4595737,159.886922 L94.5721429,159.688589 L68,153.75312 L68.609353,112.848205 L68.6286975,111.481644 L68.8124707,99.1003608 L69.0155883,85.4347524 L89.9391257,71.8562169 L91.2593906,71 C110.6668,72.9905833 136.477251,76.8193724 153.962297,79.5500754 L154.929524,80.7110474 L170.888769,99.6107048 L171,99.7147085 Z'
        opacity='.1'
      />
      <path
        fill='#3F3D56'
        fillRule='nonzero'
        d='M173,84.1000414 L170.693274,100.164928 L168.578573,114.87647 L167.987915,118.980319 L167.650049,127.742984 L165.865922,174.211887 C165.831663,175.07179 165.418285,175.872181 164.737303,176.39714 C164.056321,176.922099 163.177892,177.117543 162.33899,176.930747 L143.190007,172.649211 L94.602926,161.790808 L67,155.617997 L67.6319796,113.07769 L67.8167121,100.617666 L68.0330436,86.0424323 L68.2542365,71.0509719 C72.819074,70.8343393 80.329909,71.3235882 89.2967277,72.2266295 C109.374235,74.2444767 136.743814,78.3264184 154.638561,81.1377736 C165.596115,82.8586638 173,84.1000414 173,84.1000414 Z'
      />
      <path
        fill='#000'
        fillRule='nonzero'
        d='M178.998144,109.203719 L168.818232,114.223185 L116.863832,139.841108 L68.9842868,100.055844 L59.3053022,92 L58,92.8523165 L68.8036638,112.441069 L68.177504,154.759069 L95.5262348,160.899622 L99.1001619,167.381586 C99.4879603,168.086569 100.151809,168.595935 100.930475,168.785972 L138.170121,177.919318 C139.484257,178.237821 140.841346,177.591401 141.42856,176.36723 L143.658652,171.710967 L162.631292,175.970128 C163.462469,176.155948 164.332808,175.961526 165.007519,175.43931 C165.68223,174.917094 166.091802,174.120885 166.125745,173.265476 L167.893442,127.039411 L179.966284,110.81634 L180,110.407132 L178.998144,109.203719 Z'
        opacity='.1'
      />
      <path
        fill='#CCC'
        fillRule='nonzero'
        d='M57.2838692,93 L53.0049177,166.610708 C52.9231354,168.017955 53.8694918,169.277036 55.2391879,169.583295 L168.877388,194.933461 C169.683503,195.110796 170.52658,194.92978 171.190104,194.436901 C171.853628,193.944022 172.272952,193.187301 172.340387,192.361076 L179,110.645065 L115.943662,141.713784 L57.2838692,93 Z'
      />
      <path
        fill='#000'
        fillRule='nonzero'
        d='M54.2384418,167.915201 L113.869711,131.626077 C115.692699,130.535605 118.046988,130.89909 119.444992,132.486859 L172.817401,193.886899 C173.011324,194.106877 173.105862,193.949067 172.817401,193.886899 L54.2384418,167.915201 C53.8990769,167.841078 53.9427095,168.089749 54.2384418,167.915201 Z'
        opacity='.1'
      />
      <path
        fill='#DCDCDC'
        fillRule='nonzero'
        d='M54.3061149,169.045976 L112.694925,133.661659 C114.600532,132.50702 117.057849,132.894867 118.517978,134.580732 L170.846305,194.953252 C171.023964,195.159311 171.050204,195.456316 170.911441,195.690509 C170.772679,195.924702 170.500069,196.043501 170.234741,195.985405 L54.4955799,170.203238 C54.2433789,170.148531 54.0501518,169.944711 54.008333,169.68928 C53.9665143,169.433848 54.0846054,169.178734 54.3061149,169.045976 L54.3061149,169.045976 Z'
      />
      <path
        stroke='#FFF'
        strokeWidth='2'
        d='M80.5 86C126.833333 93 152 97 156 98M80.5 96C114.892936 101 133.559603 104 136.5 105'
      />
    </g>
  </svg>
)
