import styled from 'styled-components'

import { DottedLineReportingFlatIcon, LocationIcon, OpenBookIcon, ProfileInfoCardIcon } from 'components/icons'

import { mainTheme } from 'theme'

type Props = {
  onAboutClick: () => void
  onConnectionsClick: () => void
  onContactsClick: () => void
  onLocationClick: () => void
}

export const EmployeeFieldTabs = ({ onAboutClick, onConnectionsClick, onContactsClick, onLocationClick }: Props) => (
  <TabsContainer>
    <Tab onClick={onAboutClick}>
      <ProfileInfoCardIcon width='17px' />
      <Heading>About</Heading>
    </Tab>

    <Tab onClick={onConnectionsClick}>
      <DottedLineReportingFlatIcon width='19px' height='100%' />
      <Heading>Connections</Heading>
    </Tab>

    <Tab onClick={onContactsClick}>
      <OpenBookIcon width='17px' />
      <Heading>Contacts</Heading>
    </Tab>

    <Tab onClick={onLocationClick}>
      <LocationIcon width='18px' height='100%' />
      <Heading>Location</Heading>
    </Tab>
  </TabsContainer>
)

const tabsTopPadding = mainTheme.spaces.m
const TabsContainer = styled.div`
  position: sticky;
  top: -${props => props.theme.spaces.l};
  width: 100%;
  max-width: 100%;
  display: flex;
  gap: ${props => props.theme.spaces.m};
  padding-top: ${tabsTopPadding};
  /* Removes margin when container is non-sticky */
  margin-top: -${tabsTopPadding};
  background: ${props => props.theme.colors.white};
  box-shadow: 0px -1px 0px 0px ${props => props.theme.colors.greyExtraLight} inset;
  overflow: auto;
  z-index: 99;

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    gap: ${props => props.theme.spaces.l};
  }
`

const Tab = styled.div`
  position: relative;
  min-height: ${props => props.theme.sizes.chartSidePanelFieldTabsHeight};
  max-height: ${props => props.theme.sizes.chartSidePanelFieldTabsHeight};
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  padding: 6px ${props => props.theme.spaces.s};
  gap: 3px;
  color: ${props => props.theme.colors.greyLight};
  cursor: pointer;
  user-select: none;
  transition: ${props => props.theme.transitions.extraFastEase};

  ${props => props.theme.animations.backgroundShapeFill};
`

const Heading = styled.span`
  color: ${props => props.theme.colors.dark};
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
`
