import papa from 'papaparse'

export const TEMPLATE_FIELDS = [
  'First name (required)',
  'Last name (required)',
  'Email (required)',
  'AliasEmails',
  'Position',
  'SupervisorEmail (required)',
  'Department',
  'SubDepartment',
  'Location',
  'StartDate',
  'EndDate',
  'Mobile',
  'ImageURL',
]

export const EXPORT_FIELDS = [...TEMPLATE_FIELDS, 'DepartmentPath', 'Id', 'SupervisorId']

export const SAMPLE_DATA = [
  'Thomas',
  'Ohr',
  'thomas.ohr@company.com',
  'thomas@company.com,tom@company.com',
  'CTO',
  'jan.suster@company.com',
  'Engineering',
  'Development',
  'San Francisco',
  '1.1.2011',
  '',
  '192362773',
  `${window.location.origin}/profile-default.jpg`,
]

type Mode = 'template' | 'export'

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean
  }
}

export const download = (data: string[][], filename = 'export.csv', mode: Mode = 'export') => {
  const fields = mode === 'template' ? TEMPLATE_FIELDS : EXPORT_FIELDS
  const csv = papa.unparse({
    fields,
    data,
  })

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}
