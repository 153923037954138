import { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useHistory, generatePath } from 'react-router-dom'
import { FormikHelpers } from 'formik'
import { useApolloClient } from '@apollo/client'

import { CloseButton, StepFirst, StepZero } from './components'
import { ErorrHandler } from './error-handler'
import { Loading, TilButton } from 'components/generic'
import { ChartForm } from 'components/forms'
import { WebLayout } from 'components/layouts'
import { FileIcon, OrgchartFullLogo } from 'components/icons'

import { useCreateChartHandler } from './actions'
import { CreateChartOptionType, Error, Step } from './types'
import { ChartFormValues } from 'tree/types'
import { getIsSubscribed } from 'features/premium'
import { useAuth } from 'auth'
import { setItemInLocalStorage, getItemFromLocalStorage, stripStr } from 'helpers'
import analytics from 'analytics'
import { ORG_TRACK } from 'analytics/constants'
import { CHARTS_PATH, CHART_EDIT_PATH, CHART_PRICING_PATH } from 'routes'
import { spaces } from 'theme'

import chartExampleOneRow from 'assets/chart-example-one-row.png'
import chartExampleTwoRows from 'assets/chart-example-two-rows.png'
import gSuiteLogo from 'assets/g-suite-logo.png'
import connectAndPlay from 'assets/connect-and-play.png'
import arrowTopDown from 'assets/arrow-top-down.png'

import { getCharts } from 'apollo/query'
import { OAuth2Scope, ChartsQuery, ChartsQueryVariables } from 'apollo/generated/graphql'

const TITLE_TEXT_BY_OPTION = {
  empty: [`You are a creator!`, `You chose an option where you can build your Org Chart with no limitation!`],
  file: [`Don't want to waste time creating your Org Chart from scratch?`, `Create one instantly using your CSV data.`],
  gsuite: [
    `Don't want to waste time creating your Org Chart from scratch?`,
    `Create one instantly using your Google data.`,
  ],
  default: [``, `This is how your Org Chart can look like`],
} as const

const getLeftTitle = (loading: boolean, step: Step) => {
  if (loading) return 'Your Org Chart is being created, please wait'
  if (step === 'zero') return 'Name your Org Chart first'
  return 'Choose how to start your Org Chart'
}

const getBackText = (step: Step, optionType?: CreateChartOptionType) => {
  if (step === 'zero') return '↩ Back to charts overview'
  if (!optionType) return '↩ Choose different name'
  return '↩ Choose different data source'
}

const getCompanyNodeTitle = (values: ChartFormValues) => (
  <>
    {values.emoji}
    {values.emoji && '\u00a0'}
    {stripStr(values.name || 'Name of company', 75)}
  </>
)

export const CreateChartScreen = () => {
  const history = useHistory<{ code: string; state: string; requestedScopes: OAuth2Scope[]; error: unknown }>()
  const { pathname, state: { code, state: codeState } = {} } = history.location
  const { user } = useAuth()
  const isSubscribed = getIsSubscribed(user.subscription)
  const client = useApolloClient()

  // State
  const [error, setError] = useState<Error>({ type: undefined })
  const [loading, setLoading] = useState(false)
  const [optionType, setOptionType] = useState<CreateChartOptionType>()
  const [step, setStep] = useState<Step>('zero')
  const [initialValues, setInitialValues] = useState<ChartFormValues>({
    emoji: '',
    syncEmployeeInformation: true,
    syncCalendar: true,
    build: true,
  })

  const resetCode = () => history.replace(pathname, undefined)

  const goToChart = (uuid: string) => {
    resetCode()
    history.push(generatePath(isSubscribed ? CHART_EDIT_PATH : CHART_PRICING_PATH, { chartUuid: uuid }))
    client.query<ChartsQuery, ChartsQueryVariables>({ query: getCharts, fetchPolicy: 'no-cache' })
  }

  const createChartHandler = useCreateChartHandler({ goToChart, setError, setLoading, optionType })

  useEffect(() => {
    const chartName = getItemFromLocalStorage('chartName')
    const chartEmoji = getItemFromLocalStorage('chartEmoji')
    if (chartName && !initialValues.name)
      setInitialValues({
        ...initialValues,
        name: chartName,
        emoji: initialValues.emoji ? initialValues.emoji : chartEmoji,
      })
    if (code && codeState) {
      const data = { code, codeState, name: chartName, emoji: chartEmoji }
      setStep('second')
      createChartHandler('gsuite', data)
    }
  }, [code, codeState, optionType])

  useEffect(() => {
    if (optionType) {
      analytics.track(ORG_TRACK.createNewChartOption, { option: optionType })
    }
  }, [optionType])

  const resetComponent = (optionType: CreateChartOptionType) => {
    resetCode()
    setError({ type: undefined })
    setStep('first')
    setOptionType(optionType)
  }

  const goBack = (setSubmitting: FormikHelpers<ChartFormValues>['setSubmitting']) => {
    setSubmitting(false)
    if (step === 'zero') return history.push(CHARTS_PATH)
    if (!optionType) return setStep('zero')
    setOptionType(undefined)
  }

  const continueStepZero = (
    { name, emoji }: ChartFormValues,
    setSubmitting: FormikHelpers<ChartFormValues>['setSubmitting']
  ) => {
    setSubmitting(false)
    setStep('first')
    setItemInLocalStorage('chartName', name)
    setItemInLocalStorage('chartEmoji', emoji)
  }

  const backText = getBackText(step, optionType)
  const leftTitle = getLeftTitle(loading, step)
  const topRightTitle = TITLE_TEXT_BY_OPTION[optionType ?? 'default'][0]
  const bottomRightTitle = TITLE_TEXT_BY_OPTION[optionType ?? 'default'][1]

  if (error.type) {
    return <ErorrHandler error={error} handleResetComponent={resetComponent} />
  }

  return (
    <WebLayout>
      <ChartForm
        onSubmit={({ actions, values }) => {
          if (step === 'zero') {
            return continueStepZero(values, actions.setSubmitting)
          }
          createChartHandler(optionType ?? 'gsuite', values, actions)
        }}
        initialData={{ ...initialValues, code, codeState }}
      >
        {({ values, isSubmitting, submitForm, setSubmitting }) => (
          <Container>
            <LeftSide>
              <LeftContent>
                <LeftHeader>
                  <OrgchartFullLogo $spacing={{ mb: 40 }} onClick={() => history.push(CHARTS_PATH)} />
                  {!loading && <CloseButton />}
                </LeftHeader>

                {!loading && (
                  <BackButton
                    onClick={e => {
                      e.preventDefault()
                      goBack(setSubmitting)
                    }}
                  >
                    {backText}
                  </BackButton>
                )}
                <LeftTitle key={leftTitle} $isBold>
                  {leftTitle}
                </LeftTitle>

                {step === 'zero' && (
                  <StepZero loading={loading} onContinue={() => continueStepZero(values, setSubmitting)} />
                )}

                {step === 'first' && (
                  <StepFirst loading={loading} optionType={optionType} setOptionType={setOptionType} />
                )}

                {step !== 'zero' && !loading && optionType !== 'gsuite' && (
                  <TilButton
                    disabled={loading || isSubmitting || !optionType || (optionType === 'file' && !values.file)}
                    spacing={{ mt: spaces.xl }}
                    isSubmitButton
                    onClick={submitForm}
                    data-testid='button-create-chart-other'
                  >
                    Create Org Chart
                  </TilButton>
                )}

                {loading && (
                  <LoadingContainer>
                    <Loading size='medium' />
                  </LoadingContainer>
                )}
              </LeftContent>
            </LeftSide>

            <RightSide>
              <RightContent>
                <TitleContainer>
                  <Title key={topRightTitle}>{topRightTitle}</Title>
                  <Title key={bottomRightTitle} $isBold>
                    {bottomRightTitle}
                  </Title>
                </TitleContainer>

                {optionType !== 'gsuite' && optionType !== 'file' && (
                  <ChartPreview key={optionType}>
                    <CompanyNodeTitle>{getCompanyNodeTitle(values)}</CompanyNodeTitle>
                    <ChartPreviewImage src={chartExampleOneRow} alt='Your Chart preview' />
                  </ChartPreview>
                )}

                {optionType === 'gsuite' && (
                  <ChartPreview key={optionType}>
                    <Circle>
                      <img src={gSuiteLogo} alt='G Suite logo' />
                      <ArrowTopDownImage src={arrowTopDown} alt='Arrow top down' />
                    </Circle>
                    <ConnectAndPlayImage src={connectAndPlay} alt='Connect and play!' />

                    <ChartPreviewContainer>
                      <CompanyNodeTitleBigger>{getCompanyNodeTitle(values)}</CompanyNodeTitleBigger>
                      <ChartPreviewImage src={chartExampleTwoRows} alt='Your Chart preview' />
                      <BottomChartFade />
                    </ChartPreviewContainer>
                  </ChartPreview>
                )}

                {optionType === 'file' && (
                  <ChartPreview key={optionType}>
                    <Circle>
                      <FileIcon color='#B899D8' $spacing={{ mb: '4px' }} />
                      <CircleDescription>CSV</CircleDescription>
                      <ArrowTopDownImage src={arrowTopDown} alt='Arrow top down' />
                    </Circle>
                    <ConnectAndPlayImage src={connectAndPlay} alt='Connect and play!' />

                    <ChartPreviewContainer>
                      <CompanyNodeTitleBigger>{getCompanyNodeTitle(values)}</CompanyNodeTitleBigger>
                      <ChartPreviewImage src={chartExampleTwoRows} alt='Your Chart preview' />
                      <BottomChartFade />
                    </ChartPreviewContainer>
                  </ChartPreview>
                )}
              </RightContent>
            </RightSide>
          </Container>
        )}
      </ChartForm>
    </WebLayout>
  )
}

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    flex-direction: row;
  }
`

const Title = styled.div<{ $isBold?: boolean }>`
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.theme.colors.dark};
  ${({ $isBold }) => $isBold && 'font-weight: 500;'}
  animation: ${props => props.theme.keyframes.opacityFromHalf} ${props => props.theme.transitions.mediumEase};
`

const MAX_CONTENT_HEIGHT = '800px'

// --------- Left side ---------
const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.colors.white};

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    flex: 4;
    align-items: flex-end;
  }

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) and (min-height: 900px) {
    justify-content: center;
  }
`

const LeftContent = styled.div`
  width: 100%;
  max-width: 392px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 40px ${props => props.theme.spaces.l};

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    align-items: flex-start;
    margin-right: 75px;
  }

  @media (min-width: 1280px) {
    max-width: calc(400px + (2 * ${props => props.theme.spaces.l}));
  }

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) and (min-height: 900px) {
    height: ${MAX_CONTENT_HEIGHT};
  }
`

const LeftHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const BackButton = styled(TilButton)`
  width: 100%;
  height: initial;
  min-height: 36px;
  justify-content: flex-start;
  margin-bottom: ${props => props.theme.spaces.l};
  background: ${props => props.theme.colors.greyExtraLight};
  color: ${props => props.theme.colors.greyLight};
  transition: ${props => props.theme.transitions.fastEase};
`

const LeftTitle = styled(Title)`
  margin-bottom: ${props => props.theme.spaces.xl};
`

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: ${props => props.theme.spaces.xl};
`

// --------- Right side ---------
const companyNodeTitleCSS = css`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: ${props => props.theme.colors.dark};
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`

const ChartPreview = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${props => props.theme.spaces.xl};
  animation: ${props => props.theme.keyframes.fadeUpBig} ${props => props.theme.transitions.mediumEase};

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    margin-top: 75px;
  }
`

const RightSide = styled.div`
  flex: 6;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.colors.greyUltraLight};

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    flex: 5;
    align-items: flex-start;
  }

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) and (min-height: 900px) {
    justify-content: center;
  }
`

const RightContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 40px ${props => props.theme.spaces.l};

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    width: 100%;
    max-width: 800px;
    padding-top: 114px; /* To be on same line with title from left side (logo height + margin) */
  }

  @media (min-width: ${props => props.theme.deviceBreakpoints.bigTablet}) and (min-height: 900px) {
    height: ${MAX_CONTENT_HEIGHT};
  }
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const CompanyNodeTitle = styled.div`
  ${companyNodeTitleCSS}
  top: 22%;
`

const CompanyNodeTitleBigger = styled.div`
  ${companyNodeTitleCSS}
  top: 10%;
  left: 58%;
`

const ChartPreviewImage = styled.img`
  width: auto;
  max-width: 100%;
`

const Circle = styled.div`
  position: relative;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid ${props => props.theme.colors.greyExtraLight};
  border-radius: 50%;
  box-shadow: 0px 2px 6px rgba(69, 90, 100, 0.16);
  margin-bottom: 26px;
  background: ${props => props.theme.colors.white};
`

const ArrowTopDownImage = styled.img`
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translate(-100%, 0%);
  z-index: 2;
`

const ConnectAndPlayImage = styled.img`
  margin-bottom: ${props => props.theme.spaces.xl};
`

const ChartPreviewContainer = styled.div`
  position: relative;
  width: auto;
  max-width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    margin-right: 72px;
  }
`

const BottomChartFade = styled.div`
  position: absolute;
  bottom: -2px;
  width: 100%;
  height: 200px;
  background: linear-gradient(
    180deg,
    rgba(248, 248, 251, 0) 0%,
    rgba(248, 248, 251, 0.3) 50%,
    rgba(248, 248, 251, 0.8) 100%
  );
`

const CircleDescription = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.colors.dark};
`
