import styled from 'styled-components'

export const CheckboxLabel = styled.label`
  display: inline-flex;
  color: ${props => props.theme.colors.dark};
  font-size: 13px;
  line-height: 18px;
  user-select: none;
  cursor: pointer;
`
