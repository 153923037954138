import { ReactNode } from 'react'
import { ApolloClient, ApolloProvider } from '@apollo/client'

import { useLink, cache, typeDefs, resolvers } from './client-components'

type Props = {
  children: ReactNode
}

export const AuthorizedApolloProvider = ({ children }: Props) => {
  const link = useLink()
  const apolloClient = new ApolloClient({ name: 'Web', link, cache, typeDefs, resolvers })

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}
