import styled from 'styled-components'

import { ImageWithFallback, ImageWithFallbackProps } from 'components/generic'
import { SpacingProps, applySpacingProps } from 'helpers'

type Props = ImageWithFallbackProps & {
  className?: string
  spacing?: SpacingProps
  size?: string
}

export const AvatarCircleMiniature = ({ className, spacing, size = '32px', ...rest }: Props) => (
  <Wrap className={className} size={size} spacing={spacing}>
    <ImageWithFallback size={size} {...rest} />
  </Wrap>
)

const Wrap = styled.div<{ size: string; spacing?: SpacingProps }>`
  min-width: ${props => props.size};
  max-width: ${props => props.size};
  min-height: ${props => props.size};
  max-height: ${props => props.size};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.greyExtraLight};
  border-radius: 50%;
  overflow: hidden;

  ${props => applySpacingProps(props.spacing)}
`
