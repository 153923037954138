import { useIsOnPublicRoute } from 'tree/hooks'
import { ChartCapabilitiesFragment } from 'apollo/generated/graphql'

// General
export const getCanShowGeneralView = (c: ChartCapabilitiesFragment): boolean => c.canUpdate || c.canDelete || c.canClone
export const getCanShowCustomFieldsView = (c: ChartCapabilitiesFragment, isOnPublicRoute: boolean): boolean => {
  return c.canReadCustomField && !isOnPublicRoute
}
export const getCanShowDottedLineView = (c: ChartCapabilitiesFragment): boolean => c.canReadCustomField

// Sharing
export const getCanShowMembersView = (c: ChartCapabilitiesFragment): boolean => c.canReadCollaborators
export const getCanShowSharingView = (c: ChartCapabilitiesFragment): boolean => c.canReadPublicLink
export const getCanAccessSharing = (c: ChartCapabilitiesFragment): boolean => {
  return getCanShowMembersView(c) || getCanShowSharingView(c)
}

// Export
export const getCanShowExportView = (c: ChartCapabilitiesFragment): boolean => c.canExport

// TODO: add capabilities after BE is ready
export const getCanShowCustomBrandingView = (): boolean => false

export const useGetCanAccessSettings = () => {
  const isOnPublicRoute = useIsOnPublicRoute()

  const getCanAccessSettings = (c: ChartCapabilitiesFragment): boolean => {
    return (
      getCanShowGeneralView(c) ||
      getCanShowCustomFieldsView(c, isOnPublicRoute) ||
      getCanShowDottedLineView(c) ||
      getCanAccessSharing(c) ||
      getCanShowExportView(c) ||
      getCanShowCustomBrandingView()
    )
  }

  return { getCanAccessSettings }
}
