import { useEffect } from 'react'
import styled from 'styled-components'

import { ChartControls } from './chart-controls'
import { CompanyNode } from './chart-nodes'
import { ConnectionLinesRenderer } from './connection-lines-renderer'
import { LoadingOverTree } from './loading-over-tree'
import { PositionPad } from './position-pad'
import { ResetPositionHelper } from './reset-position-helper'
import { SubTree } from './sub-tree'
import { TreeItem } from './components'

import {
  CustomFieldsAdModal,
  CustomFieldsAdModalType,
  DottedLineAdModal,
  DottedLineAdModalType,
} from 'features/premium'
import {
  ChartAddWritePermissionModal,
  ChartAddWritePermissionModalType,
  ExportChartModal,
  ExportChartModalType,
  ExportingModal,
  ExportingModalType,
} from '../modal'
import { useRegister } from 'modal'
import { useWatch, useOnChartVisit, useIsOnPublicRoute } from 'tree/hooks'
import { useTree, useChartData } from 'tree/providers'
import { sizes } from 'theme'

export const ChartTree = () => {
  const chartData = useChartData()
  const { initialized, treeBoundariesRef } = useTree()
  const compact = useWatch({ name: 'compact' })

  const isOnPublicRoute = useIsOnPublicRoute()
  const source = isOnPublicRoute ? 'publicLink' : 'direct'
  const onChartVisit = useOnChartVisit()
  useEffect(() => onChartVisit({ chartData, source }), [chartData.uuid])

  useRegister<ChartAddWritePermissionModalType>('chartAddWritePermissionModal', ChartAddWritePermissionModal)
  useRegister<ExportChartModalType>('exportChartModal', ExportChartModal)
  useRegister<ExportingModalType>('exportingModal', ExportingModal)
  useRegister<DottedLineAdModalType>('dottedLineAdModal', DottedLineAdModal)
  useRegister<CustomFieldsAdModalType>('customFieldsAdModal', CustomFieldsAdModal)

  return (
    <ChartTreeInner>
      <LoadingOverTree />
      {initialized && <ResetPositionHelper />}
      <ChartControls />

      {initialized && (
        <PositionPad>
          <TreeBoundaries ref={treeBoundariesRef}>
            {/* verticalSize=0 to remove top padding above company node */}
            <TreeItem $verticalSize='0' $compact={false} $isCompanyNode>
              <CompanyNode>
                <SubTree compact={compact} parentUuid={null} />
              </CompanyNode>
            </TreeItem>
            <ConnectionLinesRenderer />
          </TreeBoundaries>
        </PositionPad>
      )}
    </ChartTreeInner>
  )
}

const ChartTreeInner = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  overflow: hidden;
`

const TreeBoundaries = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  /* react-dnd -> causes chart node drag preview on Safari behave incorrectly */
  transform: translateX(-50%);
  min-width: 100vw;
  width: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: ${sizes.treeBoundariesPadding};
  border-radius: 10px;
  background: ${props => props.theme.colors.greyUltraLight};
`
