import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { Node, NodeInner, ExpandButton, NodeContent, Container, NodeActionsOverlay } from '../components'

import { useNode, useWatch, useChartPermission } from 'tree/hooks'
import { useCompanyNodeState, useChartData } from 'tree/providers'
import { Company } from 'tree/types'
import { stripStr } from 'helpers'

type Props = {
  children?: ReactNode
}

export const CompanyNode = ({ children }: Props) => {
  const node = useCompanyNodeState()
  return (
    <>
      <CompanyNodeInner node={node} />
      {node.expanded && children}
    </>
  )
}

type InnerProps = {
  node: Company
}

const CompanyNodeInner = ({ node }: InnerProps) => {
  const { expanded, __typename } = node
  const { canDrop, isOver, inView, showActions, setIsNodeHovered, toggleExpanded, setNodeRefs, setNodeInnerRefs } =
    useNode(node)

  const { employeeCount, departmentCount, emoji, name, providerInfo } = useChartData()
  const compact = useWatch({ name: 'compact' })
  const connectedWithGSuite = providerInfo?.name === 'gsuite' && providerInfo?.capabilities?.canReadUser

  const showExpandButton = !(employeeCount === 0 && departmentCount === 0)

  const { canCreateDepartment, canCreatePerson } = useChartPermission()
  const canCreateNode = canCreateDepartment || canCreatePerson
  const canDoActions = canCreateNode

  return (
    <Node
      ref={setNodeRefs}
      $canDoActions={canDoActions}
      $type={__typename}
      $compact={compact}
      onMouseEnter={() => setIsNodeHovered(true)}
      onMouseLeave={() => setIsNodeHovered(false)}
      data-testid='node-company'
    >
      <NodeInner ref={setNodeInnerRefs} $canDrop={canDrop} $compact={compact} $isOver={isOver} $type={__typename}>
        {inView && (
          <>
            {showActions && (
              <NodeActionsOverlay
                canDrag={false}
                nodeType={__typename}
                node={node}
                dragRef={null}
                onMenuClick={() => setIsNodeHovered(false)}
              />
            )}

            <NodeContent $compact={compact} $isCompany>
              <Container $compact={compact}>
                <TextContainer $compact={compact} title={`${emoji} ${name}`}>
                  {emoji}
                  {emoji && '\u00a0'}
                  {stripStr(name, compact ? 55 : 75)}
                </TextContainer>
                {showExpandButton && (
                  <ExpandButton
                    compact={compact}
                    departmentCount={departmentCount}
                    employeeCount={employeeCount}
                    expanded={expanded}
                    showGoogleLogo={connectedWithGSuite}
                    toggle={toggleExpanded}
                  />
                )}
              </Container>
            </NodeContent>
          </>
        )}
      </NodeInner>
    </Node>
  )
}

const TEXT_LINE_HEIGHT = 18
const NUMBER_OF_LINES = 4
const TextContainer = styled.div<{ $compact?: boolean }>`
  min-width: 0;
  height: ${props => (props.$compact ? 'auto' : '100%')};
  max-height: ${TEXT_LINE_HEIGHT * NUMBER_OF_LINES}px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.grey};
  font-size: 14px;
  line-height: ${TEXT_LINE_HEIGHT}px;
  text-align: ${props => (props.$compact ? 'left' : 'center')};
  overflow: hidden;

  ${props =>
    props.$compact &&
    css`
      width: 100%;
      max-height: 36px;
    `}
`
