import { ReactNode } from 'react'

import { FlexBox, FlexBoxProps } from 'components/generic'

import { spaces } from 'theme'

type Props = FlexBoxProps & {
  children: ReactNode
}

export const NodeContent = ({ children, $spacing, ...flexProps }: Props) => (
  <FlexBox
    $isDirectionColumn
    $isXCentered
    $gap='l'
    $spacing={{ p: spaces.l, pb: spaces.xl, ...$spacing }}
    {...flexProps}
  >
    {children}
  </FlexBox>
)
