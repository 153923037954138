import styled from 'styled-components'

export const Screen = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`

// TODO: use instead of Screen, WebLayout and AuthLayout
// export const NewScreen = styled.div`
//   position: relative;
//   box-sizing: border-box;
//   width: 100%;
//   height: auto;
//   min-height: 100vh;
//   display: flex;
//   flex-direction: column;
// `
