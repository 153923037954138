import {
  useState,
  useEffect,
  useMemo,
  KeyboardEvent,
  MouseEvent as ReactMouseEvent,
  ChangeEvent,
  FocusEvent,
  MutableRefObject,
  ForwardedRef,
} from 'react'
import styled from 'styled-components'

import { inputFieldCSS, inputFieldHiddenCSS } from './input-CSS'
import { applySpacingProps, SpacingProps } from 'helpers'

export type InputProps = {
  type: 'text' | 'email' | 'tel' | 'radio' | 'checkbox' | 'file'
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  accept?: string
  autoComplete?: 'on' | 'off'
  checked?: boolean
  className?: string
  disabled?: boolean
  required?: boolean
  hidden?: boolean
  initialValue?: string
  light?: boolean
  maxLength?: number
  name?: string
  optimized?: boolean
  placeholder?: string
  readOnly?: boolean
  innerRef?: ForwardedRef<HTMLInputElement> | MutableRefObject<HTMLInputElement>
  spacing?: SpacingProps
  tabIndex?: number
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement> & ChangeEvent<HTMLInputElement>) => void
  onMouseEnter?: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseLeave?: (e: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const Input = ({
  type,
  value,
  onChange,
  autoComplete = 'off',
  disabled,
  required,
  initialValue,
  optimized = true,
  placeholder,
  innerRef,
  onBlur,
  onKeyDown,
  ...rest
}: InputProps) => {
  const [innerValue, setInnerValue] = useState(value)
  const innerInitialValue = useMemo(() => initialValue ?? value, [initialValue])
  useEffect(() => {
    if (optimized && value !== innerValue) setInnerValue(value)
  }, [value])

  // Prevents whole formik form re-rendering on each keystroke by updating values to formik only on specific conditions
  const handleOptimizedChange = (e: ChangeEvent<HTMLInputElement>) => {
    const textInputTypes = ['text', 'email', 'tel']
    const canTypeIntoInput = textInputTypes.includes(type)

    if (optimized && canTypeIntoInput) {
      const upcomingValue = e.target.value
      setInnerValue(upcomingValue)

      const fromInitValue = innerValue === innerInitialValue
      const toInitValue = upcomingValue === innerInitialValue
      const shouldHandleChange = fromInitValue || toInitValue || !innerValue || !upcomingValue
      if (shouldHandleChange) onChange(e)
    } else {
      onChange(e)
    }
  }

  return (
    <InputInner
      type={type}
      value={optimized ? innerValue : value}
      autoComplete={autoComplete}
      placeholder={disabled ? '' : placeholder}
      disabled={disabled}
      required={required}
      ref={innerRef}
      onChange={handleOptimizedChange}
      onBlur={e => {
        onBlur && onBlur(e)
        optimized && onChange(e)
      }}
      onKeyDown={(e: KeyboardEvent<HTMLInputElement> & ChangeEvent<HTMLInputElement>) => {
        if (optimized && e.key === 'Enter') onChange(e)
        onKeyDown && onKeyDown(e)
      }}
      {...rest}
    />
  )
}

const InputInner = styled.input.withConfig<{ spacing?: InputProps['spacing']; disabled?: InputProps['disabled'] }>({
  shouldForwardProp: prop => !['spacing', 'light'].includes(prop),
})`
  ${props => (props.hidden ? inputFieldHiddenCSS : inputFieldCSS)}
  ${props => applySpacingProps(props.spacing)}
`
