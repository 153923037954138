//import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { App } from 'app'
//import reportWebVitals from './reportWebVitals'

//** StrictMode is doing some double check of component that might result in double-rendering of some component in case you wonder, thats perfectly fine behaviour */
//** Note: If your app doesn’t work after upgrading, check whether it’s wrapped in <StrictMode>.
/* Strict Mode has gotten stricter in React 18, and not all your components may be resilient to the new checks it adds in development mode.
/* If removing Strict Mode fixes your app, you can remove it during the upgrade, and then add it back (either at the top or for a part of the tree) after you fix the issues that it’s pointing out.
/* See: https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html */
const rootElement = document.getElementById('root') as HTMLElement
const root = createRoot(rootElement)
// TODO: In order to wrap whole App in Strict Mode, you must first update react router to v6 as v5 is incompatible with React 18 Strict Mode
root.render(<App />)

/** If you want to start measuring performance in your app, pass a function
 to log results (for example: reportWebVitals(console.log))
 or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals  */
// reportWebVitals(console.log)
