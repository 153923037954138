import styled from 'styled-components'
import { colors } from 'theme'

import { AlertBoxIconProps, AlertBoxSize } from './alert-box.types'
import { ErrorIcon, WarningIcon } from 'components/icons'

export const AlertBoxIcon = ({ type, size, className }: AlertBoxIconProps) => (
  <IconContainer className={className} $size={size}>
    {type === 'warning' ? (
      <WarningIcon width='100%' height='100%' color={colors.yellow} />
    ) : (
      <ErrorIcon width='100%' height='100%' color={colors.red} />
    )}
  </IconContainer>
)

const SIZE: Record<AlertBoxSize, string> = {
  medium: '36px',
  small: '24px',
}

const IconContainer = styled.div<{ $size: AlertBoxSize }>`
  min-width: ${props => SIZE[props.$size]};
  max-width: ${props => SIZE[props.$size]};
  min-height: ${props => SIZE[props.$size]};
  max-height: ${props => SIZE[props.$size]};
`
