import { useEffect, useRef } from 'react'
import styled from 'styled-components'

import { DepartmentSelectList } from './department-select-list'
import { ChevronDownIcon } from 'components/icons'
import { InitDepartmentSelectValues, SetDepartmentSelectValues, HandleSetFieldValue } from '../superior-field'

type DepartmentSelectProps = {
  initValues: InitDepartmentSelectValues
  handleSetDepartmentSelectValues: SetDepartmentSelectValues
  personUuid: string
  chartUuid: string
  handleSetFieldValue: HandleSetFieldValue
}

export const DepartmentSelect = ({
  initValues,
  handleSetDepartmentSelectValues,
  personUuid,
  chartUuid,
  handleSetFieldValue,
}: DepartmentSelectProps) => {
  const departmentSelectWrapRef = useRef<HTMLDivElement>(null)

  // Sync state with HTML focus/blur
  useEffect(() => {
    const depSelectWrap = departmentSelectWrapRef.current
    if (!depSelectWrap) return

    initValues.isDropdownListActive ? depSelectWrap.focus() : depSelectWrap.blur()
  }, [initValues])

  return (
    <DepartmentSelectWrap
      tabIndex={0}
      onBlur={() =>
        handleSetDepartmentSelectValues({
          ...initValues,
          isDropdownListActive: false,
        })
      }
      ref={departmentSelectWrapRef}
    >
      <DepartmentSelectButton
        isDropdownListActive={initValues.isDropdownListActive}
        onClick={() =>
          handleSetDepartmentSelectValues({
            ...initValues,
            isDropdownListActive: !initValues.isDropdownListActive,
          })
        }
        data-testid='button-department-select'
      >
        {initValues.name ? (
          <DepartmentName>
            {initValues.departmentColor && <DepartmentBullet color={initValues.departmentColor} />}
            {initValues.name}
          </DepartmentName>
        ) : (
          'Select department'
        )}
        <StyledChevronDownIcon isDropdownListActive={initValues.isDropdownListActive} />
      </DepartmentSelectButton>

      {initValues.isDropdownListActive && (
        <DepartmentSelectList
          initValues={initValues}
          handleSetDepartmentSelectValues={handleSetDepartmentSelectValues}
          personUuid={personUuid}
          chartUuid={chartUuid}
          handleSetFieldValue={handleSetFieldValue}
        />
      )}
    </DepartmentSelectWrap>
  )
}

const DepartmentSelectWrap = styled.div`
  width: 100%;
  align-self: flex-start;
  color: ${props => props.theme.colors.greyLight};
  font-size: 13px;
  line-height: 16px;
  outline: none;
  user-select: none;
`

const DepartmentSelectButton = styled.div<{ isDropdownListActive: boolean }>`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding-left: 9px;
  background: ${props =>
    props.isDropdownListActive ? props.theme.colors.greyActive : props.theme.colors.neutralSecondary};
  cursor: pointer;
  transition: ${props => props.theme.transitions.extraFastEase};
`

const StyledChevronDownIcon = styled(ChevronDownIcon)<{ isDropdownListActive: boolean }>`
  margin: 0 ${props => props.theme.spaces.m};
  color: ${props => props.theme.colors.dark};
  transition: all ${props => props.theme.transitions.fastEase};
  transform: ${props => (props.isDropdownListActive ? 'rotate(180deg)' : 'unset')};
`

const DepartmentName = styled.div`
  display: flex;
  color: ${props => props.theme.colors.dark};
  font-size: 13px;
  line-height: 16px;
`

const DepartmentBullet = styled.div<{ color: string }>`
  min-width: 6px;
  min-height: 6px;
  width: 6px;
  height: 6px;
  margin-top: 5px;
  margin-right: 4px;
  border-radius: 50%;
  background: ${props => props.color};
`
