import { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import { InitDepartmentSelectValues, SetDepartmentSelectValues, HandleSetFieldValue } from '../superior-field'

import { useApolloClient } from '@apollo/client'
import { nodeChildDepartments } from 'apollo/query'
import {
  PersonChildDepartmentsDataFragmentFragment,
  NodeChildDepartmentsQuery,
  NodeChildDepartmentsQueryVariables,
} from 'apollo/generated/graphql'

type Props = {
  initValues: InitDepartmentSelectValues
  handleSetDepartmentSelectValues: SetDepartmentSelectValues
  personUuid: string
  chartUuid: string
  handleSetFieldValue: HandleSetFieldValue
}

export const DepartmentSelectList = (props: Props) => {
  const { initValues, handleSetDepartmentSelectValues, personUuid, chartUuid, handleSetFieldValue } = props
  const client = useApolloClient()
  const [fetchedPerson, setFetchedPerson] = useState<PersonChildDepartmentsDataFragmentFragment | null>(null)

  useEffect(() => {
    client
      .query<NodeChildDepartmentsQuery, NodeChildDepartmentsQueryVariables>({
        query: nodeChildDepartments,
        fetchPolicy: 'no-cache',
        variables: { uuid: personUuid, chartUuid },
      })
      .then(({ data }) => {
        if (data.node?.__typename === 'Person') setFetchedPerson(data.node)
      })
  }, [personUuid])

  if (!fetchedPerson) {
    return null
  }

  const findDepartmentParent = (parentUuid: string) => {
    return fetchedPerson.childDepartments.find(department => department.uuid === parentUuid)
  }

  const departmentResults = fetchedPerson.childDepartments.map(department => {
    const departmentPath = [department]

    let departmentParent = department.parentUuid && findDepartmentParent(department.parentUuid)
    while (departmentParent) {
      departmentPath.unshift(departmentParent)
      departmentParent = findDepartmentParent(departmentParent.parentUuid as any)
    }

    return departmentPath
  })

  return (
    <DepartmentSelectListWrap>
      <DepartmentPath
        isActive={initValues.uuid === null}
        onClick={() => {
          handleSetDepartmentSelectValues({
            uuid: null,
            name: 'Direct report',
            departmentColor: null,
            isDropdownListActive: false,
          })
          handleSetFieldValue('parentUuid', personUuid)
        }}
      >
        Direct report
      </DepartmentPath>
      {departmentResults.map(departmentPath => {
        const selectedDepartment = departmentPath[departmentPath.length - 1]
        return (
          <DepartmentPath
            key={selectedDepartment.uuid}
            isActive={initValues.uuid === selectedDepartment.uuid}
            onClick={() => {
              handleSetDepartmentSelectValues({
                uuid: selectedDepartment.uuid,
                name: selectedDepartment.name,
                departmentColor: selectedDepartment.color,
                isDropdownListActive: false,
              })
              handleSetFieldValue('parentUuid', selectedDepartment.uuid)
            }}
          >
            {departmentPath.map(department => (
              <DepartmentItem key={department.uuid}>
                <DepartmentBullet color={department.color} />
                {department.name}
              </DepartmentItem>
            ))}
          </DepartmentPath>
        )
      })}
    </DepartmentSelectListWrap>
  )
}

const DepartmentSelectListWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
  background: #ffffff;
  box-shadow: 0px 3px 12px rgba(69, 90, 100, 0.3);
  color: ${props => props.theme.colors.dark};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  z-index: 2;
`

const DepartmentPath = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
  cursor: pointer;
  z-index: 1;
  transition: ${props => props.theme.transitions.extraFastEase};

  ${props => props.theme.animations.backgroundShapeFill};

  &:last-of-type {
    margin-bottom: 0;
  }

  ${props =>
    props.isActive &&
    css`
      color: ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.purple};
      cursor: default;
      pointer-events: none;
    `}
`

const DepartmentItem = styled.div`
  display: flex;

  &:not(:first-child):before {
    content: '>';
    margin-left: 4px;
    margin-right: 4px;
  }
`

const DepartmentBullet = styled.div<{ color: string }>`
  min-width: 6px;
  min-height: 6px;
  width: 6px;
  height: 6px;
  margin-top: 5px;
  margin-right: 4px;
  border-radius: 50%;
  background: ${props => props.color};
`
