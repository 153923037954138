import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSON: any;
  JSONObject: any;
  /** A GraphQL `Upload` scalar that can be used for a [`GraphQL multipart request`](https://github.com/jaydenseric/graphql-multipart-request-spec). */
  Upload: any;
};


export type AddCollaboratorInput = {
  /** The invitee email. */
  readonly email: Scalars['String'];
  /** The invitee role. */
  readonly role: CollaboratorRole;
};

export type App = {
  readonly __typename: 'App';
  readonly version: AppVersion;
};

export type AppVersion = {
  readonly __typename: 'AppVersion';
  readonly web: Scalars['String'];
};

export type AuthorizeInput = {
  /** The path where the current user should be redirected after successful token exchange. */
  readonly redirectPath?: Maybe<Scalars['String']>;
  /** The list of requested authorization scopes. */
  readonly scopes?: Maybe<ReadonlyArray<OAuth2Scope>>;
  /** The version of returned authorization url. Only supported value is `2`. */
  readonly version?: Maybe<Scalars['Int']>;
};

export type AuthorizeResponse = {
  readonly __typename: 'AuthorizeResponse';
  /** The authorization url. */
  readonly url: Scalars['String'];
};

/** The base interface of the Node */
export type BaseNode = {
  /** The id of the chart. */
  readonly chartUuid: Scalars['ID'];
  /** @deprecated Use `employeeCount` instead */
  readonly childrenCount: Scalars['Int'];
  /** Whether the node is deleted. */
  readonly deleted: Scalars['Boolean'];
  /** Returns total count of departments in the subtree. */
  readonly departmentCount: Scalars['Int'];
  /** Returns total count of employees in the subtree. */
  readonly employeeCount: Scalars['Int'];
  /** The id of the node. */
  readonly id: Scalars['ID'];
  /** The list of parent nodes. */
  readonly parentNodes: ReadonlyArray<Node>;
  /** The id of the parent node. */
  readonly parentUuid?: Maybe<Scalars['ID']>;
  /** Primary data provider for this node. */
  readonly provider: Scalars['String'];
  /** Identifies what kind of node this is. */
  readonly type: Scalars['String'];
  /** Whether the node is in the directory. */
  readonly unassigned: Scalars['Boolean'];
  /** @deprecated Use `id` instead */
  readonly uuid: Scalars['ID'];
};

export type Chart = PaginatedCollectionItem & {
  readonly __typename: 'Chart';
  /** Returns admin email in a `createChartFromGoogleWorkspace` mutation request. Null otherwise. */
  readonly adminEmail?: Maybe<Scalars['String']>;
  /** Capabilities the current user has on this chart. Each capability corresponds to a fine-grained action that a user may take. */
  readonly capabilities?: Maybe<ChartCapabilities>;
  /** The list of custom fields. */
  readonly customFields: ReadonlyArray<CustomFieldItem>;
  /** Returns total count of departments in a orgchart. */
  readonly departmentCount: Scalars['Int'];
  /** Unicode emoji. */
  readonly emoji?: Maybe<Scalars['String']>;
  /** Returns total count of employees in a orgchart. */
  readonly employeeCount: Scalars['Int'];
  /** The id of the chart. */
  readonly id: Scalars['ID'];
  /** Chart lock info. */
  readonly lock?: Maybe<ChartLock>;
  /** The logo url. */
  readonly logo?: Maybe<Scalars['String']>;
  /** Employee info about logged in user. */
  readonly me?: Maybe<Person>;
  /** The name of the chart. */
  readonly name: Scalars['String'];
  /** Return info about primary data provider. */
  readonly providerInfo?: Maybe<ChartProviderInfo>;
  /** Whether this chart has public link. */
  readonly public: Scalars['Boolean'];
  /** Subscription info. */
  readonly subscription?: Maybe<SubscriptionInfo>;
  /** Returns collection of 3 topmost nodes per node type. */
  readonly topNodes: NodeCollection;
  /** Returns total count of departments in a directory. */
  readonly unassignedDepartmentCount: Scalars['Int'];
  /** Returns total count of employees in a directory. */
  readonly unassignedEmployeeCount: Scalars['Int'];
  /** @deprecated Use `capabilities` instead */
  readonly userPermission?: Maybe<ChartUserPermission>;
  /** @deprecated Use `id` instead */
  readonly uuid: Scalars['ID'];
};

export type ChartCapabilities = {
  readonly __typename: 'ChartCapabilities';
  readonly canAddCollaborator: Scalars['Boolean'];
  /** Whether the current user can clone this chart. */
  readonly canClone: Scalars['Boolean'];
  /** Whether the current user can create custom fields for this chart. */
  readonly canCreateCustomField: Scalars['Boolean'];
  /** Whether the current user can create department for this chart. */
  readonly canCreateDepartment: Scalars['Boolean'];
  /** Whether the current user can create person for this chart. */
  readonly canCreatePerson: Scalars['Boolean'];
  /** Whether the current user can create public link for this chart. */
  readonly canCreatePublicLink: Scalars['Boolean'];
  /** Whether the current user can delete this chart. */
  readonly canDelete: Scalars['Boolean'];
  readonly canDeleteCollaborator: Scalars['Boolean'];
  /** Whether the current user can delete custom fields for this chart. */
  readonly canDeleteCustomField: Scalars['Boolean'];
  /** Whether the current user can delete public link for this chart. */
  readonly canDeletePublicLink: Scalars['Boolean'];
  /** Whether the current user can export this chart. */
  readonly canExport: Scalars['Boolean'];
  /** Whether the current user can invite members to this chart. */
  readonly canInviteMember: Scalars['Boolean'];
  readonly canReadCollaborators: Scalars['Boolean'];
  /** Whether the current user can read custom fields of this chart. */
  readonly canReadCustomField: Scalars['Boolean'];
  /** Whether the current user can read directory on this chart. */
  readonly canReadDirectory: Scalars['Boolean'];
  /** Whether the current user can read insights of this chart. */
  readonly canReadInsight: Scalars['Boolean'];
  /** Whether the current user can read members of this chart. */
  readonly canReadMembers: Scalars['Boolean'];
  /** Whether the current user can read public link of this chart. */
  readonly canReadPublicLink: Scalars['Boolean'];
  /** Whether the current user can revoke token on this chart. */
  readonly canRevokeToken: Scalars['Boolean'];
  /** Whether the current user can sync this chart with an external provider. */
  readonly canSync: Scalars['Boolean'];
  /** Whether the current user can update this chart. */
  readonly canUpdate: Scalars['Boolean'];
  readonly canUpdateCollaborator: Scalars['Boolean'];
  /** Whether the current user can update custom fields for this chart. */
  readonly canUpdateCustomField: Scalars['Boolean'];
  /** Whether the current user can update public link for this chart. */
  readonly canUpdatePublicLink: Scalars['Boolean'];
};

export type ChartCollection = PaginatedCollection & {
  readonly __typename: 'ChartCollection';
  /** The list of charts. */
  readonly items: ReadonlyArray<Chart>;
};

export type ChartLock = {
  readonly __typename: 'ChartLock';
  readonly active: Scalars['Boolean'];
  readonly from: Scalars['String'];
};

export type ChartProviderInfo = {
  readonly __typename: 'ChartProviderInfo';
  /** Capabilities the current provider has on this chart. Each capability corresponds to a fine-grained action that a provider may take. */
  readonly capabilities?: Maybe<ChartProviderInfoCapabilities>;
  /** The name of the provider. */
  readonly name: Scalars['String'];
  /** The list of scopes. */
  readonly scopes: ReadonlyArray<OAuth2Scope>;
  /** The list of fields that provider keeps in sync with this chart. */
  readonly syncedFields: ReadonlyArray<ChartProviderInfoSyncedField>;
};

export type ChartProviderInfoCapabilities = {
  readonly __typename: 'ChartProviderInfoCapabilities';
  /** Whether the current chart provider can read calendars. */
  readonly canReadCalendar: Scalars['Boolean'];
  /** Whether the current chart provider can read users. */
  readonly canReadUser: Scalars['Boolean'];
  /** Whether the current chart provider can update users. */
  readonly canUpdateUser: Scalars['Boolean'];
};

export type ChartProviderInfoSyncedField = {
  readonly __typename: 'ChartProviderInfoSyncedField';
  /** Name of the synced field. */
  readonly name: Scalars['String'];
  /** Whether the synced field is readonly. */
  readonly readonly: Scalars['Boolean'];
};

/** @deprecated: Use `ChartCapabilities` instead. */
export type ChartUserPermission = {
  readonly __typename: 'ChartUserPermission';
  readonly role?: Maybe<Scalars['String']>;
};

export type Collaborator = {
  readonly __typename: 'Collaborator';
  /** Whether the invitations is accepted. */
  readonly accepted: Scalars['Boolean'];
  /** The list of allowed roles. */
  readonly allowedRoles: ReadonlyArray<CollaboratorRole>;
  /** The id of the chart. */
  readonly chartUuid: Scalars['ID'];
  /** The collaborator's email */
  readonly email: Scalars['String'];
  /** The id of the node. */
  readonly id: Scalars['ID'];
  /** The collaborator's role. */
  readonly role: CollaboratorRole;
  /** Invitaation status. */
  readonly status: CollaboratorStatus;
  /** @deprecated Use `id` instead */
  readonly uuid: Scalars['ID'];
};

/**
 * The collaborator role.
 * For further info about permissions see [Role/Permission matrix](#introduction-item-3).
 */
export type CollaboratorRole =
  | 'admin'
  | 'editor'
  | 'viewer';

export type CollaboratorStatus =
  | 'accepted'
  | 'invitation_expired'
  | 'invited';

export type CreateChartFromFileInput = {
  /** Whether to create orgchart structure. */
  readonly build?: Maybe<Scalars['Boolean']>;
  /** Unicode emoji. */
  readonly emoji: Scalars['String'];
  /** The Upload scalar type represents a csv upload as per https://github.com/jaydenseric/graphql-upload specification. */
  readonly file: Scalars['Upload'];
  /** The logo url. */
  readonly logo?: Maybe<Scalars['String']>;
  /** The name of the chart. */
  readonly name: Scalars['String'];
};

export type CreateChartFromGoogleWorkspaceInput = {
  /** The Authorization Code received from the `authorize` mutation call. */
  readonly code: Scalars['String'];
  /**
   * Cryptographically random string that was used to generate the codeChallenge
   * passed to the `authorize` mutation.
   */
  readonly codeVerifier: Scalars['String'];
  /** Unicode emoji. */
  readonly emoji?: Maybe<Scalars['String']>;
  /** The logo url. */
  readonly logo?: Maybe<Scalars['String']>;
  /** The name of the chart. */
  readonly name?: Maybe<Scalars['String']>;
  /** The opaque state string received  the `authorize` mutation call. */
  readonly state: Scalars['String'];
};

export type CreateChartFromTemplateInput = {
  /** The ID of the template. */
  readonly templateId: Scalars['ID'];
};

export type CreateChartInput = {
  /** Unicode emoji. */
  readonly emoji: Scalars['String'];
  /** The logo url. */
  readonly logo?: Maybe<Scalars['String']>;
  /** The name of the chart. */
  readonly name: Scalars['String'];
};

export type CreateCustomConnectionFieldInput = {
  /** Whether to display icons on the chart node. */
  readonly displayLineOnChart: Scalars['Boolean'];
  /** The label of the connection field. */
  readonly label: Scalars['String'];
  /** Hexadecimal color of the connection line. */
  readonly lineColor?: Maybe<Scalars['String']>;
  /** Style of the connection line. */
  readonly lineStyle?: Maybe<Scalars['String']>;
};

export type CreateCustomDateFieldInput = {
  /** Label of the custom date field. */
  readonly label: Scalars['String'];
};

export type CreateCustomDropdownFieldInput = {
  /** Whether to display icons on the chart node. */
  readonly displayIconsOnChartNode: Scalars['Boolean'];
  /** The label of the dropdown field. */
  readonly label: Scalars['String'];
  /** The list of dropdown options. */
  readonly options: ReadonlyArray<CreateCustomDropdownFieldValueInput>;
};

export type CreateCustomDropdownFieldValueInput = {
  /** Hexadecimal color of the icon background. */
  readonly background?: Maybe<Scalars['String']>;
  /** Unicode emoji. */
  readonly icon?: Maybe<Scalars['String']>;
  /** The label of the dropdown field. */
  readonly label: Scalars['String'];
};

export type CreateCustomTextFieldInput = {
  /** Label of the custom text field. */
  readonly label: Scalars['String'];
};

export type CreateDepartmentInput = {
  /** Hexadecimal color. */
  readonly color?: Maybe<Scalars['String']>;
  /** Description. */
  readonly description?: Maybe<Scalars['String']>;
  /** The name of the department. */
  readonly name: Scalars['String'];
  /** The id of the parent node. */
  readonly parentUuid?: Maybe<Scalars['String']>;
};

export type CreatePersonInput = {
  /** Building identifier. */
  readonly buildingId?: Maybe<Scalars['String']>;
  /** The cost center of the employee's organization. */
  readonly costCenter?: Maybe<Scalars['String']>;
  /** The list of custom field values. */
  readonly customFields?: Maybe<CustomFieldValueInput>;
  /** Code of individual desk location. */
  readonly deskCode?: Maybe<Scalars['String']>;
  /** The employee's primary email address. */
  readonly email?: Maybe<Scalars['String']>;
  /** A list of the employee's alias email addresses. */
  readonly emailAliases?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  /** The id of the employee within the organization. */
  readonly employeeId?: Maybe<Scalars['String']>;
  /** The type of the employee within the organization. */
  readonly employeeType?: Maybe<Scalars['String']>;
  readonly endDate?: Maybe<Scalars['Date']>;
  /** The Facebook url. */
  readonly facebookLink?: Maybe<Scalars['String']>;
  /** The employees's last name. */
  readonly familyName?: Maybe<Scalars['String']>;
  /** Floor name within the building. */
  readonly floorName?: Maybe<Scalars['String']>;
  /** Floor section within the floor. */
  readonly floorSection?: Maybe<Scalars['String']>;
  /** The employees's first name. */
  readonly givenName?: Maybe<Scalars['String']>;
  /** Photo Url of the employee. */
  readonly image?: Maybe<Scalars['String']>;
  /** The Instagram url. */
  readonly instagramLink?: Maybe<Scalars['String']>;
  /** The LinkedIn url. */
  readonly linkedInLink?: Maybe<Scalars['String']>;
  /** The physical location of the employee. */
  readonly location?: Maybe<Scalars['String']>;
  /** The employee's mobile phone number. */
  readonly mobile?: Maybe<Scalars['String']>;
  /** The id of the parent node. */
  readonly parentUuid?: Maybe<Scalars['String']>;
  /** The employee's job title. */
  readonly position?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Date']>;
  /** The WhatsApp url. */
  readonly whatsAppLink?: Maybe<Scalars['String']>;
  /** The Twitter url. */
  readonly xLink?: Maybe<Scalars['String']>;
  /** The YouTube url. */
  readonly youTubeLink?: Maybe<Scalars['String']>;
};

export type CreatePublicLinkInput = {
  readonly allowedCustomFields?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly domains?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly ips?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};

export type CustomConnectionField = CustomField & {
  readonly __typename: 'CustomConnectionField';
  /** Whether to display connection line on the chart. */
  readonly displayLineOnChart: Scalars['Boolean'];
  /** The id of the custom connection field. */
  readonly id: Scalars['ID'];
  /** Label of the custom connection field. */
  readonly label: Scalars['String'];
  /** Hexadecimal color of the connection line. */
  readonly lineColor?: Maybe<Scalars['String']>;
  /** Style of the connection line. */
  readonly lineStyle?: Maybe<Scalars['String']>;
  /** Identifies what kind of resource this is. Value is fixed string `connection`. */
  readonly type: Scalars['String'];
};

export type CustomConnectionFieldValue = ListValue & {
  readonly __typename: 'CustomConnectionFieldValue';
  /** The id of the custom connection field. */
  readonly id: Scalars['ID'];
  /** The list of nodes. */
  readonly nodes: ReadonlyArray<Node>;
  /** Identifies what kind of resource this is. Value is fixed string `connection`. */
  readonly type: Scalars['String'];
  /** The list of node ids. */
  readonly values: ReadonlyArray<Scalars['String']>;
};

export type CustomConnectionFieldValueInput = {
  /** The id of the custom connection field. */
  readonly id: Scalars['ID'];
  /** List of node IDs. */
  readonly values: ReadonlyArray<Scalars['String']>;
};

export type CustomDateField = CustomField & {
  readonly __typename: 'CustomDateField';
  /** The id of the custom date field. */
  readonly id: Scalars['ID'];
  /** Label of the custom date field. */
  readonly label: Scalars['String'];
  /** Identifies what kind of resource this is. Value is fixed string `date`. */
  readonly type: Scalars['String'];
};

export type CustomDateFieldValue = StringValue & {
  readonly __typename: 'CustomDateFieldValue';
  /** The id of the custom date field. */
  readonly id: Scalars['ID'];
  /** Identifies what kind of resource this is. Value is fixed string `date`. */
  readonly type: Scalars['String'];
  /** The ISO 8601 formatted timestamp */
  readonly value: Scalars['String'];
};

export type CustomDateFieldValueInput = {
  /** The id of the custom date field. */
  readonly id: Scalars['ID'];
  /** The ISO 8601 formatted timestamp */
  readonly value: Scalars['String'];
};

export type CustomDropdownField = CustomField & {
  readonly __typename: 'CustomDropdownField';
  /** Whether to display icons on the chart node. */
  readonly displayIconsOnChartNode: Scalars['Boolean'];
  /** The id of the custom dropdown field. */
  readonly id: Scalars['ID'];
  /** Label of the custom dropdown field. */
  readonly label: Scalars['String'];
  /** The list of dropdown options. */
  readonly options: ReadonlyArray<CustomDropdownFieldOption>;
  /** Identifies what kind of resource this is. Value is fixed string `dropdown`. */
  readonly type: Scalars['String'];
};

export type CustomDropdownFieldOption = {
  readonly __typename: 'CustomDropdownFieldOption';
  /** Hexadecimal color of the icon background. */
  readonly background?: Maybe<Scalars['String']>;
  /** Unicode icon. */
  readonly icon?: Maybe<Scalars['String']>;
  /** The id of the custom dropdown field option. */
  readonly id: Scalars['ID'];
  /** Label of the custom dropdown field option. */
  readonly label: Scalars['String'];
};

export type CustomDropdownFieldValue = StringValue & {
  readonly __typename: 'CustomDropdownFieldValue';
  /** The id of the custom dropdown field */
  readonly id: Scalars['ID'];
  /** Identifies what kind of resource this is. Value is fixed string `dropdown`. */
  readonly type: Scalars['String'];
  /** Value is an id of selected option. */
  readonly value: Scalars['String'];
};

export type CustomDropdownFieldValueInput = {
  /** The id of the custom dropdown field. */
  readonly id: Scalars['ID'];
  /** Value is unique id of selected option */
  readonly value: Scalars['String'];
};

export type CustomField = {
  /** The id of the custom field. */
  readonly id: Scalars['ID'];
  /** Label of the custom field. */
  readonly label: Scalars['String'];
  /** Identifies what kind of resource this is. */
  readonly type: Scalars['String'];
};

export type CustomFieldCollection = {
  readonly __typename: 'CustomFieldCollection';
  /** The list of custom fields */
  readonly items: ReadonlyArray<CustomFieldItem>;
};

export type CustomFieldItem = CustomConnectionField | CustomDateField | CustomDropdownField | CustomTextField;

export type CustomFieldValue = CustomConnectionFieldValue | CustomDateFieldValue | CustomDropdownFieldValue | CustomTextFieldValue;

export type CustomFieldValueInput = {
  /** List of connection values. */
  readonly connection?: Maybe<ReadonlyArray<CustomConnectionFieldValueInput>>;
  /** List of date values. */
  readonly date?: Maybe<ReadonlyArray<CustomDateFieldValueInput>>;
  /** List of dropdown values. */
  readonly dropdown?: Maybe<ReadonlyArray<CustomDropdownFieldValueInput>>;
  /** List of text values. */
  readonly text?: Maybe<ReadonlyArray<CustomTextFieldValueInput>>;
};

export type CustomTextField = CustomField & {
  readonly __typename: 'CustomTextField';
  /** The id of the custom text field. */
  readonly id: Scalars['ID'];
  /** Label of the custom text field. */
  readonly label: Scalars['String'];
  /** Identifies what kind of resource this is. Value is fixed string `text`. */
  readonly type: Scalars['String'];
};

export type CustomTextFieldValue = StringValue & {
  readonly __typename: 'CustomTextFieldValue';
  /** The id of the custom text field. */
  readonly id: Scalars['ID'];
  /** Identifies what kind of resource this is. Value is fixed string `text`. */
  readonly type: Scalars['String'];
  /** Text value. */
  readonly value: Scalars['String'];
};

export type CustomTextFieldValueInput = {
  /** The id of the custom text field. */
  readonly id: Scalars['ID'];
  /** Text value. */
  readonly value: Scalars['String'];
};


export type Department = BaseNode & {
  readonly __typename: 'Department';
  /** Capabilities the current user has on this department. Each capability corresponds to a fine-grained action that a user may take. */
  readonly capabilities?: Maybe<DepartmentCapabilities>;
  /** The id of the chart. */
  readonly chartUuid: Scalars['ID'];
  /** @deprecated Use `employeeCount` instead */
  readonly childrenCount: Scalars['Int'];
  /** Hexadecimal color. */
  readonly color: Scalars['String'];
  /** Whether the department is deleted. */
  readonly deleted: Scalars['Boolean'];
  /** Returns total count of departments in the subtree. */
  readonly departmentCount: Scalars['Int'];
  /** Description. */
  readonly description?: Maybe<Scalars['String']>;
  /** Returns total count of employees in the subtree. */
  readonly employeeCount: Scalars['Int'];
  readonly expanded: Scalars['Boolean'];
  /** The id of the department. */
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  /** The list of parent nodes. */
  readonly parentNodes: ReadonlyArray<Node>;
  /** The id of the parent node. */
  readonly parentUuid?: Maybe<Scalars['ID']>;
  /** Primary data provider for this department. */
  readonly provider: Scalars['String'];
  /** Identifies what kind of node this is. Value: the fixed string "department". */
  readonly type: Scalars['String'];
  /** Whether the department is in the directory. */
  readonly unassigned: Scalars['Boolean'];
  /** @deprecated Use `id` instead */
  readonly uuid: Scalars['ID'];
};

export type DepartmentCapabilities = {
  readonly __typename: 'DepartmentCapabilities';
  /** Whether the current user can delete this department. */
  readonly canDelete: Scalars['Boolean'];
  /** Whether the current user can move this department. */
  readonly canMove: Scalars['Boolean'];
  /** Whether the current user can move this department to a directory. */
  readonly canUnassign: Scalars['Boolean'];
  /** Whether the current user can update this department. */
  readonly canUpdate: Scalars['Boolean'];
};

export type ExportNodesConfig = {
  /** Whether exported chart should be in the compact mode. */
  readonly compact?: Maybe<Scalars['Boolean']>;
  /** Requested file format. */
  readonly format: Scalars['String'];
  /** ID of the root node. */
  readonly rootUuid?: Maybe<Scalars['String']>;
};

export type GetNodesFilter = {
  /** Filter nodes by parent id or null for the topmost level. */
  readonly parentUuid?: Maybe<Scalars['String']>;
  /** Filter nodes by the list of parent ids. Parent id can be `null` also. */
  readonly parentUuidList?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  /** Filter nodes by `unassigned` field. Returns all if not provided. */
  readonly unassigned?: Maybe<Scalars['Boolean']>;
};

export type IDataset = {
  readonly id: Scalars['ID'];
  readonly type: Scalars['String'];
};

export type ISlice = {
  readonly id: Scalars['ID'];
  readonly type: Scalars['String'];
};

export type Insight = PaginatedCollectionItem & {
  readonly __typename: 'Insight';
  readonly chartUuid: Scalars['String'];
  readonly datasets: ReadonlyArray<InsightDataset>;
  readonly dateFrom: Scalars['String'];
  readonly dateTo: Scalars['String'];
  readonly departments: ReadonlyArray<InsightDepartment>;
  readonly id: Scalars['ID'];
  readonly status: Scalars['String'];
  readonly uuid: Scalars['ID'];
};

export type InsightChart = ISlice & {
  readonly __typename: 'InsightChart';
  readonly id: Scalars['ID'];
  readonly type: Scalars['String'];
  readonly values: ReadonlyArray<InsightChartValue>;
};

export type InsightChartValue = {
  readonly __typename: 'InsightChartValue';
  readonly f: InsightValueFilter;
  readonly l: Scalars['String'];
  readonly v: Scalars['Float'];
};

export type InsightCollection = PaginatedCollection & {
  readonly __typename: 'InsightCollection';
  readonly items: ReadonlyArray<Insight>;
};

export type InsightDataset = InsightDatasetMulti | InsightDatasetOneOnOne | InsightDatasetSingle | InsightDatasetSociomap | InsightDatasetSpanOfControl;

export type InsightDatasetMulti = IDataset & {
  readonly __typename: 'InsightDatasetMulti';
  readonly id: Scalars['ID'];
  readonly type: Scalars['String'];
  readonly values: ReadonlyArray<InsightDatasetValueMulti>;
};

export type InsightDatasetOneOnOne = IDataset & {
  readonly __typename: 'InsightDatasetOneOnOne';
  readonly id: Scalars['ID'];
  readonly type: Scalars['String'];
  readonly values: ReadonlyArray<InsightDatasetValueOneOnOne>;
};

export type InsightDatasetSingle = IDataset & {
  readonly __typename: 'InsightDatasetSingle';
  readonly id: Scalars['ID'];
  readonly type: Scalars['String'];
  readonly values: ReadonlyArray<InsightDatasetValueSingle>;
};

export type InsightDatasetSociomap = IDataset & {
  readonly __typename: 'InsightDatasetSociomap';
  readonly demo: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly type: Scalars['String'];
  readonly values: ReadonlyArray<InsightDatasetValueSociomap>;
};

export type InsightDatasetSpanOfControl = IDataset & {
  readonly __typename: 'InsightDatasetSpanOfControl';
  readonly id: Scalars['ID'];
  readonly type: Scalars['String'];
  readonly values: ReadonlyArray<InsightDatasetValueSpanOfControl>;
};

export type InsightDatasetValueMulti = {
  readonly __typename: 'InsightDatasetValueMulti';
  readonly d: ReadonlyArray<InsightDatasetValueMultiData>;
  readonly f: InsightValueFilter;
};

export type InsightDatasetValueMultiData = {
  readonly __typename: 'InsightDatasetValueMultiData';
  readonly l: Scalars['String'];
  readonly v: Scalars['Float'];
};

export type InsightDatasetValueOneOnOne = {
  readonly __typename: 'InsightDatasetValueOneOnOne';
  readonly department: InsightDepartment;
  readonly f: InsightValueFilter;
  readonly familyName: Scalars['String'];
  readonly givenName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly image: Scalars['String'];
  readonly pct: Scalars['Float'];
  readonly uuid: Scalars['String'];
};

export type InsightDatasetValueSingle = {
  readonly __typename: 'InsightDatasetValueSingle';
  readonly f: InsightValueFilter;
  readonly l: Scalars['String'];
  readonly v: Scalars['Float'];
};

export type InsightDatasetValueSociomap = {
  readonly __typename: 'InsightDatasetValueSociomap';
  readonly f: InsightValueFilter;
  readonly n: Scalars['String'];
  readonly x: Scalars['Float'];
  readonly y: Scalars['Float'];
  readonly z: Scalars['Float'];
};

export type InsightDatasetValueSpanOfControl = {
  readonly __typename: 'InsightDatasetValueSpanOfControl';
  readonly department: InsightDepartment;
  readonly f: InsightValueFilter;
  readonly familyName: Scalars['String'];
  readonly givenName: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly image: Scalars['String'];
  readonly subordinatesCount: Scalars['Int'];
  readonly uuid: Scalars['String'];
};

export type InsightDepartment = {
  readonly __typename: 'InsightDepartment';
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly uuid: Scalars['ID'];
};

export type InsightFilter = {
  readonly idList?: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly uuid: Scalars['ID'];
};

export type InsightKpi = ISlice & {
  readonly __typename: 'InsightKpi';
  readonly id: Scalars['ID'];
  readonly type: Scalars['String'];
  readonly values: ReadonlyArray<InsightKpiValue>;
};

export type InsightKpiValue = {
  readonly __typename: 'InsightKpiValue';
  readonly f: InsightValueFilter;
  readonly v: Scalars['Float'];
};

export type InsightSpanOfControl = ISlice & {
  readonly __typename: 'InsightSpanOfControl';
  readonly id: Scalars['ID'];
  readonly label: Scalars['String'];
  readonly type: Scalars['String'];
  readonly values: ReadonlyArray<InsightChartValue>;
};

export type InsightValueFilter = {
  readonly __typename: 'InsightValueFilter';
  readonly dep: Scalars['String'];
};

export type InsightsFilter = {
  readonly idList?: Maybe<ReadonlyArray<Scalars['String']>>;
};



export type ListValue = {
  /** The list of text values. */
  readonly values: ReadonlyArray<Scalars['String']>;
};

export type Mutation = {
  readonly __typename: 'Mutation';
  /** Accepts chart invitation via [Accept invitation](#accept-invitation) flow. */
  readonly acceptInvitation?: Maybe<Collaborator>;
  /** Sends invitation email via [Invite member](#invite-member) flow. */
  readonly addCollaborator?: Maybe<Collaborator>;
  /** Returns authorization url for specified provider. */
  readonly authorize?: Maybe<AuthorizeResponse>;
  /** Creates a copy of a chart. */
  readonly cloneChart?: Maybe<Chart>;
  /** Creates a chart. */
  readonly createChart?: Maybe<Chart>;
  /** Creates a chart from a csv file. */
  readonly createChartFromFile?: Maybe<Chart>;
  /**
   * Creates chart from Google Workspace.
   *
   * Once you have authorization code and opaque state string from Google OAuth 2.0 request,
   * use this mutation to create a chart connected with Google Workspace.
   */
  readonly createChartFromGoogleWorkspace?: Maybe<Chart>;
  /** Creates a chart from a template. */
  readonly createChartFromTemplate?: Maybe<Chart>;
  /** Creates a connection field. */
  readonly createConnectionField?: Maybe<CustomConnectionField>;
  /** Creates a date field. */
  readonly createDateField?: Maybe<CustomDateField>;
  /** Creates a department. */
  readonly createDepartment?: Maybe<Department>;
  /** Creates a dropdown field. */
  readonly createDropdownField?: Maybe<CustomDropdownField>;
  /** Creates an employee. */
  readonly createPerson?: Maybe<Person>;
  readonly createPublicLink?: Maybe<PublicLink>;
  /** Creates a checkout session. */
  readonly createStripeCheckoutSession?: Maybe<StripeSession>;
  /** Creates a portal session. */
  readonly createStripePortalSession?: Maybe<StripeSession>;
  /** Creates a text field. */
  readonly createTextField?: Maybe<CustomTextField>;
  /** Deletes a chart. */
  readonly deleteChart?: Maybe<Chart>;
  /** Deletes a collaborator. */
  readonly deleteCollaborator?: Maybe<Collaborator>;
  /** Deletes a connection field. */
  readonly deleteConnectionField?: Maybe<CustomConnectionField>;
  /** Deletes a date field. */
  readonly deleteDateField?: Maybe<CustomDateField>;
  /** Deletes a dropdown field. */
  readonly deleteDropdownField?: Maybe<CustomDropdownField>;
  /** Deletes a node. */
  readonly deleteNode: ReadonlyArray<Node>;
  readonly deletePublicLink?: Maybe<PublicLink>;
  /** Deletes a text field. */
  readonly deleteTextField?: Maybe<CustomTextField>;
  /**
   * Returns signed upload policy.
   *
   * How to upload file:
   * ```javascript
   *
   * const { url, fields, getUrl } = data.generateAvatarUploadUrlPolicy
   * const formData = new FormData()
   * for (const { key, value } of fields) {
   *   formData.append(key, value)
   * }
   * formData.append('Content-Type', blob.type)
   * formData.append('file', blob)
   *
   * const xhr = new XMLHttpRequest()
   * xhr.open('POST', url, true)
   * xhr.send(formData)
   * xhr.onload = () => {
   *   if (xhr.status === 204) {
   *     console.log('success')
   *   } else {
   *     console.log('failure')
   *   }
   * }
   * xhr.onerror = () => console.log('failure')
   * ```
   */
  readonly generateAvatarUploadUrlPolicy?: Maybe<UploadUrlPolicy>;
  readonly generateLogoUploadUrlPolicy?: Maybe<UploadUrlPolicy>;
  /** Changes Google Workspace sync mode. */
  readonly makeChartEditable?: Maybe<Chart>;
  /** Moves child nodes. */
  readonly moveChildNodes?: Maybe<Node>;
  /** Moves node. */
  readonly moveNode?: Maybe<Node>;
  /** Request T4T integration. */
  readonly requestT4T?: Maybe<RequestT4TResponse>;
  /** Resends invitation. */
  readonly resendInvitation?: Maybe<Collaborator>;
  /** Revokes chart token. */
  readonly revokeChartToken?: Maybe<Chart>;
  readonly sendVerificationEmail?: Maybe<Scalars['Boolean']>;
  readonly setShowUnassignedNodesSidebar: Scalars['Boolean'];
  /** Syncs chart with an external provider. */
  readonly syncChart?: Maybe<Chart>;
  /** Syncs the chart with a provided csv file. */
  readonly syncChartWithFile?: Maybe<Chart>;
  readonly toggleSubtree?: Maybe<Node>;
  /** Track Event */
  readonly trackEvent?: Maybe<TrackEventResult>;
  /** Moves node to the directory. */
  readonly unassignNode: ReadonlyArray<Node>;
  /** Updates a chart. */
  readonly updateChart?: Maybe<Chart>;
  /** Upserts Google Workspace token to a chart. */
  readonly updateChartToken?: Maybe<Chart>;
  /** Updates a collaborator. */
  readonly updateCollaborator?: Maybe<Collaborator>;
  /** Updates a connection field. */
  readonly updateConnectionField?: Maybe<CustomConnectionField>;
  /** Updates a date field. */
  readonly updateDateField?: Maybe<CustomDateField>;
  /** Updates a department. */
  readonly updateDepartment?: Maybe<Department>;
  /** Updates a dropdown field. */
  readonly updateDropdownField?: Maybe<CustomDropdownField>;
  /** Updates an employee. */
  readonly updatePerson?: Maybe<Person>;
  readonly updatePublicLink?: Maybe<PublicLink>;
  /** Updates a text field. */
  readonly updateTextField?: Maybe<CustomTextField>;
};


export type MutationAcceptInvitationArgs = {
  code?: Maybe<Scalars['String']>;
};


export type MutationAddCollaboratorArgs = {
  addCollaboratorInput: AddCollaboratorInput;
  chartUuid: Scalars['String'];
};


export type MutationAuthorizeArgs = {
  authorizeInput?: Maybe<AuthorizeInput>;
  codeChallenge: Scalars['String'];
  provider: OAuth2Provider;
};


export type MutationCloneChartArgs = {
  rootUuid?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};


export type MutationCreateChartArgs = {
  createChartInput: CreateChartInput;
};


export type MutationCreateChartFromFileArgs = {
  createChartFromFileInput: CreateChartFromFileInput;
};


export type MutationCreateChartFromGoogleWorkspaceArgs = {
  createChartFromGoogleWorkspaceInput: CreateChartFromGoogleWorkspaceInput;
};


export type MutationCreateChartFromTemplateArgs = {
  createChartFromTemplate?: Maybe<CreateChartFromTemplateInput>;
};


export type MutationCreateConnectionFieldArgs = {
  chartUuid: Scalars['String'];
  createConnectionFieldInput: CreateCustomConnectionFieldInput;
};


export type MutationCreateDateFieldArgs = {
  chartUuid: Scalars['String'];
  createDateFieldInput: CreateCustomDateFieldInput;
};


export type MutationCreateDepartmentArgs = {
  chartUuid: Scalars['String'];
  createDepartmentInput: CreateDepartmentInput;
};


export type MutationCreateDropdownFieldArgs = {
  chartUuid: Scalars['String'];
  createDropdownFieldInput: CreateCustomDropdownFieldInput;
};


export type MutationCreatePersonArgs = {
  chartUuid: Scalars['String'];
  createPersonInput: CreatePersonInput;
};


export type MutationCreatePublicLinkArgs = {
  chartUuid: Scalars['String'];
  createPublicLinkInput: CreatePublicLinkInput;
};


export type MutationCreateStripeCheckoutSessionArgs = {
  billingPeriod?: Maybe<Scalars['String']>;
  coupon?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
};


export type MutationCreateTextFieldArgs = {
  chartUuid: Scalars['String'];
  createTextFieldInput: CreateCustomTextFieldInput;
};


export type MutationDeleteChartArgs = {
  uuid: Scalars['String'];
};


export type MutationDeleteCollaboratorArgs = {
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
};


export type MutationDeleteConnectionFieldArgs = {
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
};


export type MutationDeleteDateFieldArgs = {
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
};


export type MutationDeleteDropdownFieldArgs = {
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
};


export type MutationDeleteNodeArgs = {
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
};


export type MutationDeletePublicLinkArgs = {
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
};


export type MutationDeleteTextFieldArgs = {
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
};


export type MutationGenerateAvatarUploadUrlPolicyArgs = {
  chartUuid: Scalars['String'];
  contentType: Scalars['String'];
};


export type MutationGenerateLogoUploadUrlPolicyArgs = {
  chartUuid: Scalars['String'];
  contentType: Scalars['String'];
};


export type MutationMakeChartEditableArgs = {
  uuid: Scalars['String'];
};


export type MutationMoveChildNodesArgs = {
  chartUuid: Scalars['String'];
  targetUuid?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};


export type MutationMoveNodeArgs = {
  chartUuid: Scalars['String'];
  targetUuid?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};


export type MutationRequestT4TArgs = {
  chartId: Scalars['String'];
  requestT4TInput?: Maybe<RequestT4TInput>;
};


export type MutationResendInvitationArgs = {
  code?: Maybe<Scalars['String']>;
};


export type MutationRevokeChartTokenArgs = {
  uuid: Scalars['String'];
};


export type MutationSetShowUnassignedNodesSidebarArgs = {
  showUnassignedNodesSidebar: Scalars['Boolean'];
};


export type MutationSyncChartArgs = {
  uuid: Scalars['String'];
};


export type MutationSyncChartWithFileArgs = {
  syncChartWithFileInput: SyncChartWithFileInput;
  uuid: Scalars['String'];
};


export type MutationToggleSubtreeArgs = {
  expanded: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationTrackEventArgs = {
  trackEventInput?: Maybe<TrackEventInput>;
};


export type MutationUnassignNodeArgs = {
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
};


export type MutationUpdateChartArgs = {
  updateChartInput: UpdateChartInput;
  uuid: Scalars['String'];
};


export type MutationUpdateChartTokenArgs = {
  updateChartTokenInput: UpdateChartTokenInput;
  uuid: Scalars['String'];
};


export type MutationUpdateCollaboratorArgs = {
  chartUuid: Scalars['String'];
  updateCollaboratorInput: UpdateCollaboratorInput;
  uuid: Scalars['String'];
};


export type MutationUpdateConnectionFieldArgs = {
  chartUuid: Scalars['String'];
  updateConnectionFieldInput: UpdateCustomConnectionFieldInput;
  uuid: Scalars['String'];
};


export type MutationUpdateDateFieldArgs = {
  chartUuid: Scalars['String'];
  updateDateFieldInput: UpdateCustomDateFieldInput;
  uuid: Scalars['String'];
};


export type MutationUpdateDepartmentArgs = {
  chartUuid: Scalars['String'];
  updateDepartmentInput: UpdateDepartmentInput;
  uuid: Scalars['String'];
};


export type MutationUpdateDropdownFieldArgs = {
  chartUuid: Scalars['String'];
  updateDropdownFieldInput: UpdateCustomDropdownFieldInput;
  uuid: Scalars['String'];
};


export type MutationUpdatePersonArgs = {
  chartUuid: Scalars['String'];
  updatePersonInput: UpdatePersonInput;
  uuid: Scalars['String'];
};


export type MutationUpdatePublicLinkArgs = {
  chartUuid: Scalars['String'];
  updatePublicLinkInput: UpdatePublicLinkInput;
  uuid: Scalars['String'];
};


export type MutationUpdateTextFieldArgs = {
  chartUuid: Scalars['String'];
  updateTextFieldInput: UpdateCustomTextFieldInput;
  uuid: Scalars['String'];
};

export type Node = Department | Person;

export type NodeCollection = {
  readonly __typename: 'NodeCollection';
  /** The list of nodes. */
  readonly items: ReadonlyArray<Node>;
};

export type NodeExport = {
  readonly __typename: 'NodeExport';
  /** A short-lived url to the file with exported nodes. */
  readonly downloadUrl: Scalars['String'];
};

export type OAuth2Provider =
  | 'gsuite';

export type OAuth2Scope =
  /** The data provider can view user calendars. */
  | 'calendarReadonly'
  /** The data provider can view and manage users. */
  | 'user'
  /** The data provider can view users. */
  | 'userReadonly';

export type PaginatedCollection = {
  readonly items: ReadonlyArray<PaginatedCollectionItem>;
};

export type PaginatedCollectionItem = {
  readonly id: Scalars['ID'];
};

export type Person = BaseNode & {
  readonly __typename: 'Person';
  /** Building identifier. */
  readonly buildingId?: Maybe<Scalars['String']>;
  /** The link to the employee calendar. */
  readonly calendarUrl?: Maybe<Scalars['String']>;
  /** Capabilities the current user has on this employee. Each capability corresponds to a fine-grained action that a user may take. */
  readonly capabilities?: Maybe<PersonCapabilities>;
  /** The id of the chart. */
  readonly chartUuid: Scalars['ID'];
  /** The list of departments that this employee supervises. */
  readonly childDepartments: ReadonlyArray<Department>;
  /** @deprecated Use `employeeCount` instead */
  readonly childrenCount: Scalars['Int'];
  /** The cost center of the employee's organization. */
  readonly costCenter?: Maybe<Scalars['String']>;
  /** The list of custom fields. */
  readonly customFields: ReadonlyArray<CustomFieldValue>;
  /** Whether the department is deleted. */
  readonly deleted: Scalars['Boolean'];
  /** Returns total count of departments in the subtree. */
  readonly departmentCount: Scalars['Int'];
  /** Code of individual desk location. */
  readonly deskCode?: Maybe<Scalars['String']>;
  /** The employee's primary email address. */
  readonly email?: Maybe<Scalars['String']>;
  /** A list of the employee's alias email addresses. */
  readonly emailAliases: ReadonlyArray<Scalars['String']>;
  /** Returns total count of employees in the subtree. */
  readonly employeeCount: Scalars['Int'];
  /** The id of the employee within the organization. */
  readonly employeeId?: Maybe<Scalars['String']>;
  /** The type of the employee within the organization. */
  readonly employeeType?: Maybe<Scalars['String']>;
  readonly endDate?: Maybe<Scalars['Date']>;
  readonly expanded: Scalars['Boolean'];
  /** The Facebook url. */
  readonly facebookLink?: Maybe<Scalars['String']>;
  /** The employees's last name. */
  readonly familyName?: Maybe<Scalars['String']>;
  /** Floor name within the building. */
  readonly floorName?: Maybe<Scalars['String']>;
  /** Floor section within the floor. */
  readonly floorSection?: Maybe<Scalars['String']>;
  /** The employees's first name. */
  readonly givenName?: Maybe<Scalars['String']>;
  /** The id of the employee. */
  readonly id: Scalars['ID'];
  /** Photo Url of the employee. */
  readonly image?: Maybe<Scalars['String']>;
  /** The Instagram url. */
  readonly instagramLink?: Maybe<Scalars['String']>;
  /** Whether the current user is the employee. */
  readonly isMe: Scalars['Boolean'];
  /** The LinkedIn url. */
  readonly linkedInLink?: Maybe<Scalars['String']>;
  /** The physical location of the employee. */
  readonly location?: Maybe<Scalars['String']>;
  /** The employee's mobile phone number. */
  readonly mobile?: Maybe<Scalars['String']>;
  /** @deprecated Use `givenName` and `familyName` fields instead. */
  readonly name: Scalars['String'];
  /** The list of parent nodes. */
  readonly parentNodes: ReadonlyArray<Node>;
  /** The id of the parent node. */
  readonly parentUuid?: Maybe<Scalars['ID']>;
  /** The employee's job title. */
  readonly position: Scalars['String'];
  /** Primary data provider for this department. */
  readonly provider: Scalars['String'];
  readonly startDate?: Maybe<Scalars['Date']>;
  readonly teamSize: Scalars['Int'];
  readonly teamSizeTest: Scalars['Int'];
  /** Identifies what kind of node this is. Value: the fixed string "employee". */
  readonly type: Scalars['String'];
  /** Whether the employee is in the directory. */
  readonly unassigned: Scalars['Boolean'];
  /** @deprecated Use `id` instead */
  readonly uuid: Scalars['ID'];
  /** The WhatsApp url. */
  readonly whatsAppLink?: Maybe<Scalars['String']>;
  /** The Twitter url. */
  readonly xLink?: Maybe<Scalars['String']>;
  /** The YouTube url. */
  readonly youTubeLink?: Maybe<Scalars['String']>;
};

export type PersonCapabilities = {
  readonly __typename: 'PersonCapabilities';
  /** Whether the current user can delete this employee. */
  readonly canDelete: Scalars['Boolean'];
  /** Whether the current user can move this employee. */
  readonly canMove: Scalars['Boolean'];
  /** Whether the current user can move this employee to the directory. */
  readonly canUnassign: Scalars['Boolean'];
  /** Whether the current user can update this employee. */
  readonly canUpdate: Scalars['Boolean'];
  /** Whether the current user can update employee's building id. */
  readonly canUpdateBuildingId: Scalars['Boolean'];
  /** Whether the current user can update employee's cost center. */
  readonly canUpdateCostCenter: Scalars['Boolean'];
  /** Whether the current user can update employee's desk code. */
  readonly canUpdateDeskCode: Scalars['Boolean'];
  /** Whether the current user can update employee's email. */
  readonly canUpdateEmail: Scalars['Boolean'];
  /** Whether the current user can update employee's email aliases. */
  readonly canUpdateEmailAliases: Scalars['Boolean'];
  /** Whether the current user can update employee's id. */
  readonly canUpdateEmployeeId: Scalars['Boolean'];
  /** Whether the current user can update employee's type. */
  readonly canUpdateEmployeeType: Scalars['Boolean'];
  /** Whether the current user can update employee's floor name. */
  readonly canUpdateFloorName: Scalars['Boolean'];
  /** Whether the current user can update employee's floor section. */
  readonly canUpdateFloorSection: Scalars['Boolean'];
  /** Whether the current user can update employee's profile picture. */
  readonly canUpdateImage: Scalars['Boolean'];
  /** Whether the current user can update employee's location. */
  readonly canUpdateLocation: Scalars['Boolean'];
  /** Whether the current user can update employee's mobile phone. */
  readonly canUpdateMobile: Scalars['Boolean'];
  /** Whether the current user can update employee's name. */
  readonly canUpdateName: Scalars['Boolean'];
  /** Whether the current user can update employee's position. */
  readonly canUpdatePosition: Scalars['Boolean'];
  /** Whether the current user can update employee's social contacts. */
  readonly canUpdateSocialContacts: Scalars['Boolean'];
};

export type PublicLink = {
  readonly __typename: 'PublicLink';
  readonly allowedCustomFields: ReadonlyArray<Scalars['String']>;
  readonly domains: ReadonlyArray<Scalars['String']>;
  readonly id: Scalars['ID'];
  readonly ips: ReadonlyArray<Scalars['String']>;
  readonly url: Scalars['String'];
  readonly uuid: Scalars['ID'];
};

export type Query = {
  readonly __typename: 'Query';
  /** Returns app info. */
  readonly app?: Maybe<App>;
  /** Retrieves a chart by a key. */
  readonly chart?: Maybe<Chart>;
  /** Retrieves a chart collection. */
  readonly charts?: Maybe<ChartCollection>;
  /**
   * Retrieves custom field collection.
   * @deprecated Use `customFields` field on `Chart` instead.
   */
  readonly customFields?: Maybe<CustomFieldCollection>;
  /** Retrieves url to the exported file. */
  readonly exportNodes: NodeExport;
  /**
   * Returns signed upload policy.
   *
   * How to upload file:
   * ```javascript
   *
   * const { url, fields, getUrl } = data.getAvatarUrl
   * const formData = new FormData()
   * for (const { key, value } of fields) {
   *   formData.append(key, value)
   * }
   * formData.append('Content-Type', blob.type)
   * formData.append('file', blob)
   *
   * const xhr = new XMLHttpRequest()
   * xhr.open('POST', url, true)
   * xhr.send(formData)
   * xhr.onload = () => {
   *   if (xhr.status === 204) {
   *     console.log('success')
   *   } else {
   *     console.log('failure')
   *   }
   * }
   * xhr.onerror = () => console.log('failure')
   * ```
   * @deprecated Use `generateAvatarUploadUrlPolicy` mutation instead.
   */
  readonly getAvatarUrl?: Maybe<UploadUrlPolicy>;
  /** Retrieves collaborators. */
  readonly getCollaborators: ReadonlyArray<Collaborator>;
  /** Retrieves an invitation by code. */
  readonly getInvitation?: Maybe<Collaborator>;
  readonly getPublicLinkByUuid?: Maybe<PublicLink>;
  readonly getPublicLinks: ReadonlyArray<PublicLink>;
  /** Retrieves an insight. */
  readonly insight?: Maybe<Insight>;
  /** Retrieves an insight collection. */
  readonly insights?: Maybe<InsightCollection>;
  /** Retrieves a node by chart key. Key can be chart id or public link code. */
  readonly node?: Maybe<Node>;
  /** Retrieves a node collection. */
  readonly nodes: NodeCollection;
  /** Retrieves filtered node collection. */
  readonly searchNodes: NodeCollection;
  readonly showUnassignedNodesSidebar: Scalars['Boolean'];
  readonly teamMembers: NodeCollection;
  readonly user?: Maybe<User>;
};


export type QueryChartArgs = {
  key: Scalars['String'];
};


export type QueryCustomFieldsArgs = {
  chartUuid: Scalars['String'];
};


export type QueryExportNodesArgs = {
  chartUuid: Scalars['String'];
  config: ExportNodesConfig;
};


export type QueryGetAvatarUrlArgs = {
  chartUuid: Scalars['String'];
  contentType: Scalars['String'];
  fileName: Scalars['String'];
};


export type QueryGetCollaboratorsArgs = {
  chartUuid: Scalars['String'];
};


export type QueryGetInvitationArgs = {
  code: Scalars['String'];
};


export type QueryGetPublicLinkByUuidArgs = {
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
};


export type QueryGetPublicLinksArgs = {
  chartUuid: Scalars['String'];
};


export type QueryInsightArgs = {
  chartUuid: Scalars['String'];
  filter?: Maybe<InsightFilter>;
};


export type QueryInsightsArgs = {
  chartUuid: Scalars['String'];
  filter?: Maybe<InsightsFilter>;
};


export type QueryNodeArgs = {
  chartKey?: Maybe<Scalars['String']>;
  chartUuid?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};


export type QueryNodesArgs = {
  chartKey?: Maybe<Scalars['String']>;
  chartUuid?: Maybe<Scalars['String']>;
  filter?: Maybe<GetNodesFilter>;
};


export type QuerySearchNodesArgs = {
  chartKey?: Maybe<Scalars['String']>;
  chartUuid?: Maybe<Scalars['String']>;
  filter: SearchNodesFilter;
};


export type QueryTeamMembersArgs = {
  chartKey: Scalars['String'];
  filter: TeamMembersFilter;
};

export type Quota = PaginatedCollectionItem & {
  readonly __typename: 'Quota';
  /** Current usage value for the resource. */
  readonly currentValue: Scalars['Int'];
  /** A string containing the human-readable name. */
  readonly displayName: Scalars['String'];
  /** Limit that the current user cannot exceed. */
  readonly hardLimit: Scalars['Int'];
  /**
   * The id of the quota.
   *
   * Allowed ID values:
   * - chart - max chart count
   * = chart.customField - max custom field count
   * - chart.employee - max employee count
   * - chart.member - max member count
   * - chart.export.pdf - number of PDF exports per month
   * - chart.export.png - number of PNG exports per month
   * - chart.export.csv - number of CSV exports per month
   * - chart.publicLink.domain - max number of domains on public link
   */
  readonly id: Scalars['ID'];
  /** Limit that the current user can temporarily exceed. */
  readonly softLimit: Scalars['Int'];
};

export type QuotaCollection = PaginatedCollection & {
  readonly __typename: 'QuotaCollection';
  /** The list of quotas. */
  readonly items: ReadonlyArray<Quota>;
};

export type RequestT4TInput = {
  readonly email?: Maybe<Scalars['String']>;
};

export type RequestT4TResponse = {
  readonly __typename: 'RequestT4TResponse';
  readonly success?: Maybe<Scalars['Boolean']>;
};

export type SearchNodesFilter = {
  /** Whether exclude specific node id. */
  readonly excludeUuid?: Maybe<Scalars['String']>;
  /** A query for filtering the node results. */
  readonly q: Scalars['String'];
  /** Filter nodes by `unassigned` field. Returns all if not provided. */
  readonly unassigned?: Maybe<Scalars['Boolean']>;
};

export type SignedUrlPolicyField = {
  readonly __typename: 'SignedUrlPolicyField';
  readonly key: Scalars['String'];
  readonly value: Scalars['String'];
};

export type Slice = InsightChart | InsightKpi | InsightSpanOfControl;

export type StringRangeValue = {
  /** The ISO 8601 formatted timestamp. */
  readonly end: Scalars['String'];
  /** The ISO 8601 formatted timestamp. */
  readonly start: Scalars['String'];
};

export type StringValue = {
  /** Text value. */
  readonly value: Scalars['String'];
};

export type StripeSession = {
  readonly __typename: 'StripeSession';
  /** A short-lived Stripe session url. */
  readonly url: Scalars['String'];
};

export type SubscriptionCapabilities = {
  readonly __typename: 'SubscriptionCapabilities';
  /** Whether the current user can manage this subscription. */
  readonly canManage: Scalars['Boolean'];
};

export type SubscriptionInfo = {
  readonly __typename: 'SubscriptionInfo';
  /** Capabilities the current user has on this subscription. Each capability corresponds to a fine-grained action that a user may take. */
  readonly capabilities: SubscriptionCapabilities;
  /** Subscription plan. */
  readonly plan: Scalars['String'];
  /** Subscription quotas. */
  readonly quotas: QuotaCollection;
  /**
   * Subscription status.
   *
   * Values:
   * - active
   * - past_due
   * - unpaid
   * - canceled
   */
  readonly status: Scalars['String'];
  /** Whether the subscription is upgradable. */
  readonly upgradable: Scalars['Boolean'];
};

export type SyncChartWithFileInput = {
  /** The Upload scalar type represents a csv upload as per https://github.com/jaydenseric/graphql-upload specification. */
  readonly file: Scalars['Upload'];
};

export type SyncUserInput = {
  readonly auth0Id: Scalars['String'];
};

export type TeamMembersFilter = {
  readonly supervisorId: Scalars['String'];
};

export type TokenResponse = {
  readonly __typename: 'TokenResponse';
  readonly id: Scalars['ID'];
  readonly redirectPath: Scalars['String'];
  /** @deprecated Use `id` instead */
  readonly uuid: Scalars['String'];
};

export type TrackEventInput = {
  /** Event name. */
  readonly event?: Maybe<Scalars['String']>;
  /** Event key/value map. */
  readonly properties?: Maybe<Scalars['JSONObject']>;
};

export type TrackEventResult = {
  readonly __typename: 'TrackEventResult';
  readonly success?: Maybe<Scalars['Boolean']>;
};

export type UpdateChartInput = {
  /** Unicode emoji. */
  readonly emoji?: Maybe<Scalars['String']>;
  /** The logo url. */
  readonly logo?: Maybe<Scalars['String']>;
  /** The name of the chart. */
  readonly name?: Maybe<Scalars['String']>;
};

export type UpdateChartTokenInput = {
  /** The Authorization Code received from the `authorize` mutation call. */
  readonly code: Scalars['String'];
  /**
   * Cryptographically random string that was used to generate the codeChallenge
   * passed to the `authorize` mutation.
   */
  readonly codeVerifier: Scalars['String'];
  /** The opaque state string received  the `authorize` mutation call. */
  readonly state: Scalars['String'];
};

export type UpdateCollaboratorInput = {
  /** The invitee/collaborator role. */
  readonly role: CollaboratorRole;
};

export type UpdateCustomConnectionFieldInput = {
  /** Whether to display icons on the chart node. */
  readonly displayLineOnChart?: Maybe<Scalars['Boolean']>;
  /** The label of the connection field. */
  readonly label?: Maybe<Scalars['String']>;
  /** Hexadecimal color of the connection line. */
  readonly lineColor?: Maybe<Scalars['String']>;
  /** Style of the connection line. */
  readonly lineStyle?: Maybe<Scalars['String']>;
};

export type UpdateCustomDateFieldInput = {
  /** Label of the custom date field. */
  readonly label: Scalars['String'];
};

export type UpdateCustomDropdownFieldInput = {
  /** Whether to display icons on the chart node. */
  readonly displayIconsOnChartNode: Scalars['Boolean'];
  /** The label of the dropdown field. */
  readonly label: Scalars['String'];
  /** The list of dropdown options. */
  readonly options: ReadonlyArray<UpdateCustomDropdownFieldValueInput>;
};

export type UpdateCustomDropdownFieldValueInput = {
  /** Hexadecimal color of the icon background */
  readonly background?: Maybe<Scalars['String']>;
  /** Unicode emoji */
  readonly icon?: Maybe<Scalars['String']>;
  /** The id of the dropdown field. */
  readonly id?: Maybe<Scalars['String']>;
  /** The label of the dropdown field. */
  readonly label: Scalars['String'];
};

export type UpdateCustomTextFieldInput = {
  /** Label of the custom text field. */
  readonly label: Scalars['String'];
};

export type UpdateDepartmentInput = {
  /** Hexadecimal color. */
  readonly color?: Maybe<Scalars['String']>;
  /** Description. */
  readonly description?: Maybe<Scalars['String']>;
  /** The name of the department. */
  readonly name: Scalars['String'];
  /** The id of the parent node. */
  readonly parentUuid?: Maybe<Scalars['String']>;
};

export type UpdatePersonInput = {
  /** Building identifier. */
  readonly buildingId?: Maybe<Scalars['String']>;
  /** The cost center of the employee's organization. */
  readonly costCenter?: Maybe<Scalars['String']>;
  /** The list of custom field values. */
  readonly customFields?: Maybe<CustomFieldValueInput>;
  /** Code of individual desk location. */
  readonly deskCode?: Maybe<Scalars['String']>;
  /** The employee's primary email address. */
  readonly email?: Maybe<Scalars['String']>;
  /** A list of the employee's alias email addresses. */
  readonly emailAliases?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  /** The id of the employee within the organization. */
  readonly employeeId?: Maybe<Scalars['String']>;
  /** The type of the employee within the organization. */
  readonly employeeType?: Maybe<Scalars['String']>;
  readonly endDate?: Maybe<Scalars['Date']>;
  /** The Facebook url. */
  readonly facebookLink?: Maybe<Scalars['String']>;
  /** The employees's last name. */
  readonly familyName?: Maybe<Scalars['String']>;
  /** Floor name within the building. */
  readonly floorName?: Maybe<Scalars['String']>;
  /** Floor section within the floor. */
  readonly floorSection?: Maybe<Scalars['String']>;
  /** The employees's first name. */
  readonly givenName?: Maybe<Scalars['String']>;
  /** Photo Url of the employee. */
  readonly image?: Maybe<Scalars['String']>;
  /** The Instagram url. */
  readonly instagramLink?: Maybe<Scalars['String']>;
  /** The LinkedIn url. */
  readonly linkedInLink?: Maybe<Scalars['String']>;
  /** The physical location of the employee. */
  readonly location?: Maybe<Scalars['String']>;
  /** The employee's mobile phone number. */
  readonly mobile?: Maybe<Scalars['String']>;
  /** The id of the parent node. */
  readonly parentUuid?: Maybe<Scalars['String']>;
  /** The employee's job title. */
  readonly position?: Maybe<Scalars['String']>;
  readonly startDate?: Maybe<Scalars['Date']>;
  /** The WhatsApp url. */
  readonly whatsAppLink?: Maybe<Scalars['String']>;
  /** The Twitter url. */
  readonly xLink?: Maybe<Scalars['String']>;
  /** The YouTube url. */
  readonly youTubeLink?: Maybe<Scalars['String']>;
};

export type UpdatePublicLinkInput = {
  readonly allowedCustomFields?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly domains?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
  readonly ips?: Maybe<ReadonlyArray<Maybe<Scalars['String']>>>;
};


export type UploadUrlPolicy = {
  readonly __typename: 'UploadUrlPolicy';
  readonly fields: ReadonlyArray<SignedUrlPolicyField>;
  /** Public asset url. */
  readonly getUrl: Scalars['String'];
  readonly url: Scalars['String'];
};

export type User = {
  readonly __typename: 'User';
  readonly email: Scalars['String'];
  readonly emailVerified: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly provider: Scalars['String'];
  readonly subscription: SubscriptionInfo;
};

export type AppFragmentFragment = (
  { readonly __typename: 'App' }
  & { readonly version: (
    { readonly __typename: 'AppVersion' }
    & Pick<AppVersion, 'web'>
  ) }
);

export type ChartCapabilitiesFragment = (
  { readonly __typename: 'ChartCapabilities' }
  & Pick<ChartCapabilities, 'canAddCollaborator' | 'canClone' | 'canCreateCustomField' | 'canCreateDepartment' | 'canCreatePerson' | 'canCreatePublicLink' | 'canDelete' | 'canDeleteCollaborator' | 'canDeleteCustomField' | 'canDeletePublicLink' | 'canExport' | 'canReadCollaborators' | 'canReadCustomField' | 'canReadInsight' | 'canReadPublicLink' | 'canUpdate' | 'canUpdateCollaborator' | 'canUpdateCustomField' | 'canUpdatePublicLink' | 'canReadDirectory' | 'canSync' | 'canRevokeToken'>
);

export type ChartDetailFragmentFragment = (
  { readonly __typename: 'Chart' }
  & Pick<Chart, 'id' | 'uuid' | 'name' | 'emoji' | 'logo' | 'public' | 'adminEmail' | 'employeeCount' | 'departmentCount' | 'unassignedEmployeeCount' | 'unassignedDepartmentCount'>
  & { readonly lock?: Maybe<(
    { readonly __typename: 'ChartLock' }
    & Pick<ChartLock, 'active' | 'from'>
  )>, readonly providerInfo?: Maybe<(
    { readonly __typename: 'ChartProviderInfo' }
    & Pick<ChartProviderInfo, 'name' | 'scopes'>
    & { readonly syncedFields: ReadonlyArray<(
      { readonly __typename: 'ChartProviderInfoSyncedField' }
      & Pick<ChartProviderInfoSyncedField, 'name' | 'readonly'>
    )>, readonly capabilities?: Maybe<(
      { readonly __typename: 'ChartProviderInfoCapabilities' }
      & Pick<ChartProviderInfoCapabilities, 'canReadUser' | 'canUpdateUser' | 'canReadCalendar'>
    )> }
  )>, readonly customFields: ReadonlyArray<(
    { readonly __typename: 'CustomConnectionField' }
    & CustomFieldFragment_CustomConnectionField_Fragment
  ) | (
    { readonly __typename: 'CustomDateField' }
    & CustomFieldFragment_CustomDateField_Fragment
  ) | (
    { readonly __typename: 'CustomDropdownField' }
    & CustomFieldFragment_CustomDropdownField_Fragment
  ) | (
    { readonly __typename: 'CustomTextField' }
    & CustomFieldFragment_CustomTextField_Fragment
  )>, readonly subscription?: Maybe<(
    { readonly __typename: 'SubscriptionInfo' }
    & SubscriptionInfoFragment
  )>, readonly userPermission?: Maybe<(
    { readonly __typename: 'ChartUserPermission' }
    & Pick<ChartUserPermission, 'role'>
  )>, readonly capabilities?: Maybe<(
    { readonly __typename: 'ChartCapabilities' }
    & ChartCapabilitiesFragment
  )>, readonly me?: Maybe<(
    { readonly __typename: 'Person' }
    & Pick<Person, 'id' | 'email' | 'teamSize'>
  )> }
);

export type ChartFragmentFragment = (
  { readonly __typename: 'Chart' }
  & Pick<Chart, 'id' | 'uuid' | 'name' | 'emoji' | 'logo' | 'public' | 'adminEmail' | 'employeeCount' | 'departmentCount' | 'unassignedEmployeeCount' | 'unassignedDepartmentCount'>
  & { readonly lock?: Maybe<(
    { readonly __typename: 'ChartLock' }
    & Pick<ChartLock, 'active' | 'from'>
  )>, readonly providerInfo?: Maybe<(
    { readonly __typename: 'ChartProviderInfo' }
    & Pick<ChartProviderInfo, 'name' | 'scopes'>
    & { readonly syncedFields: ReadonlyArray<(
      { readonly __typename: 'ChartProviderInfoSyncedField' }
      & Pick<ChartProviderInfoSyncedField, 'name' | 'readonly'>
    )>, readonly capabilities?: Maybe<(
      { readonly __typename: 'ChartProviderInfoCapabilities' }
      & Pick<ChartProviderInfoCapabilities, 'canReadUser' | 'canUpdateUser' | 'canReadCalendar'>
    )> }
  )>, readonly topNodes: (
    { readonly __typename: 'NodeCollection' }
    & { readonly items: ReadonlyArray<(
      { readonly __typename: 'Department' }
      & Pick<Department, 'id' | 'uuid' | 'name' | 'color' | 'type' | 'employeeCount' | 'expanded'>
    ) | (
      { readonly __typename: 'Person' }
      & Pick<Person, 'id' | 'uuid' | 'name' | 'image' | 'position' | 'type' | 'expanded'>
    )> }
  ), readonly subscription?: Maybe<(
    { readonly __typename: 'SubscriptionInfo' }
    & SubscriptionInfoFragment
  )>, readonly userPermission?: Maybe<(
    { readonly __typename: 'ChartUserPermission' }
    & Pick<ChartUserPermission, 'role'>
  )>, readonly capabilities?: Maybe<(
    { readonly __typename: 'ChartCapabilities' }
    & ChartCapabilitiesFragment
  )> }
);

export type ChartMeFragmentFragment = (
  { readonly __typename: 'Chart' }
  & Pick<Chart, 'id'>
  & { readonly me?: Maybe<(
    { readonly __typename: 'Person' }
    & Pick<Person, 'id' | 'email' | 'teamSize'>
  )> }
);

export type CollaboratorFragmentFragment = (
  { readonly __typename: 'Collaborator' }
  & Pick<Collaborator, 'chartUuid' | 'id' | 'uuid' | 'accepted' | 'status' | 'email' | 'role' | 'allowedRoles'>
);

export type CustomTextFieldFragmentFragment = (
  { readonly __typename: 'CustomTextField' }
  & Pick<CustomTextField, 'id' | 'type' | 'label'>
);

export type CustomDateFieldFragmentFragment = (
  { readonly __typename: 'CustomDateField' }
  & Pick<CustomDateField, 'id' | 'type' | 'label'>
);

export type CustomDropdownFieldFragmentFragment = (
  { readonly __typename: 'CustomDropdownField' }
  & Pick<CustomDropdownField, 'id' | 'type' | 'label' | 'displayIconsOnChartNode'>
  & { readonly options: ReadonlyArray<(
    { readonly __typename: 'CustomDropdownFieldOption' }
    & Pick<CustomDropdownFieldOption, 'id' | 'label' | 'icon' | 'background'>
  )> }
);

export type CustomConnectionFieldFragmentFragment = (
  { readonly __typename: 'CustomConnectionField' }
  & Pick<CustomConnectionField, 'id' | 'displayLineOnChart' | 'label' | 'lineColor' | 'lineStyle' | 'type'>
);

export type CustomFieldFragment_CustomConnectionField_Fragment = (
  { readonly __typename: 'CustomConnectionField' }
  & CustomConnectionFieldFragmentFragment
);

export type CustomFieldFragment_CustomDateField_Fragment = (
  { readonly __typename: 'CustomDateField' }
  & CustomDateFieldFragmentFragment
);

export type CustomFieldFragment_CustomDropdownField_Fragment = (
  { readonly __typename: 'CustomDropdownField' }
  & CustomDropdownFieldFragmentFragment
);

export type CustomFieldFragment_CustomTextField_Fragment = (
  { readonly __typename: 'CustomTextField' }
  & CustomTextFieldFragmentFragment
);

export type CustomFieldFragmentFragment = CustomFieldFragment_CustomConnectionField_Fragment | CustomFieldFragment_CustomDateField_Fragment | CustomFieldFragment_CustomDropdownField_Fragment | CustomFieldFragment_CustomTextField_Fragment;

export type DepartmentDetailDataFragmentFragment = (
  { readonly __typename: 'Department' }
  & Pick<Department, 'id' | 'uuid' | 'unassigned' | 'deleted' | 'parentUuid' | 'chartUuid' | 'name' | 'color' | 'description' | 'type' | 'childrenCount' | 'employeeCount' | 'departmentCount' | 'expanded'>
  & { readonly capabilities?: Maybe<(
    { readonly __typename: 'DepartmentCapabilities' }
    & Pick<DepartmentCapabilities, 'canUpdate' | 'canMove' | 'canDelete' | 'canUnassign'>
  )>, readonly parentNodes: ReadonlyArray<(
    { readonly __typename: 'Department' }
    & Pick<Department, 'id' | 'uuid' | 'name' | 'color' | 'expanded'>
  ) | (
    { readonly __typename: 'Person' }
    & Pick<Person, 'id' | 'uuid' | 'name' | 'expanded'>
  )> }
);

export type DepartmentDataFragmentFragment = (
  { readonly __typename: 'Department' }
  & Pick<Department, 'id' | 'uuid' | 'unassigned' | 'deleted' | 'parentUuid' | 'chartUuid' | 'name' | 'color' | 'description' | 'type' | 'childrenCount' | 'employeeCount' | 'departmentCount' | 'expanded'>
  & { readonly capabilities?: Maybe<(
    { readonly __typename: 'DepartmentCapabilities' }
    & Pick<DepartmentCapabilities, 'canUpdate' | 'canMove' | 'canDelete' | 'canUnassign'>
  )>, readonly parentNodes: ReadonlyArray<(
    { readonly __typename: 'Department' }
    & Pick<Department, 'id' | 'name' | 'uuid' | 'color' | 'expanded'>
  ) | (
    { readonly __typename: 'Person' }
    & Pick<Person, 'id' | 'name' | 'uuid' | 'expanded'>
  )> }
);

export type DepartmentIdentifyFragmentFragment = (
  { readonly __typename: 'Department' }
  & Pick<Department, 'id' | 'uuid'>
);

export type DepartmentSearchDataFragmentFragment = (
  { readonly __typename: 'Department' }
  & Pick<Department, 'chartUuid' | 'id' | 'uuid' | 'type' | 'unassigned' | 'name' | 'color' | 'expanded'>
  & { readonly parentNodes: ReadonlyArray<(
    { readonly __typename: 'Department' }
    & Pick<Department, 'id' | 'uuid' | 'name' | 'color' | 'expanded'>
  ) | (
    { readonly __typename: 'Person' }
    & Pick<Person, 'id' | 'uuid' | 'name' | 'expanded'>
  )>, readonly capabilities?: Maybe<(
    { readonly __typename: 'DepartmentCapabilities' }
    & Pick<DepartmentCapabilities, 'canMove'>
  )> }
);

export type ExpandedPersonFragmentFragment = (
  { readonly __typename: 'Person' }
  & Pick<Person, 'expanded'>
);

export type ExpandedDepartmentFragmentFragment = (
  { readonly __typename: 'Department' }
  & Pick<Department, 'expanded'>
);

export type InsightDatasetSingleFragmentFragment = (
  { readonly __typename: 'InsightDatasetSingle' }
  & Pick<InsightDatasetSingle, 'id'>
  & { readonly values: ReadonlyArray<(
    { readonly __typename: 'InsightDatasetValueSingle' }
    & Pick<InsightDatasetValueSingle, 'v' | 'l'>
    & { readonly f: (
      { readonly __typename: 'InsightValueFilter' }
      & Pick<InsightValueFilter, 'dep'>
    ) }
  )> }
);

export type InsightDatasetMultiFragmentFragment = (
  { readonly __typename: 'InsightDatasetMulti' }
  & Pick<InsightDatasetMulti, 'id'>
  & { readonly values: ReadonlyArray<(
    { readonly __typename: 'InsightDatasetValueMulti' }
    & { readonly f: (
      { readonly __typename: 'InsightValueFilter' }
      & Pick<InsightValueFilter, 'dep'>
    ), readonly d: ReadonlyArray<(
      { readonly __typename: 'InsightDatasetValueMultiData' }
      & Pick<InsightDatasetValueMultiData, 'v' | 'l'>
    )> }
  )> }
);

export type InsightDatasetSpanOfControlFragmentFragment = (
  { readonly __typename: 'InsightDatasetSpanOfControl' }
  & Pick<InsightDatasetSpanOfControl, 'id'>
  & { readonly values: ReadonlyArray<(
    { readonly __typename: 'InsightDatasetValueSpanOfControl' }
    & Pick<InsightDatasetValueSpanOfControl, 'uuid' | 'image' | 'givenName' | 'familyName' | 'subordinatesCount'>
    & { readonly f: (
      { readonly __typename: 'InsightValueFilter' }
      & Pick<InsightValueFilter, 'dep'>
    ), readonly department: (
      { readonly __typename: 'InsightDepartment' }
      & Pick<InsightDepartment, 'uuid' | 'name'>
    ) }
  )> }
);

export type InsightDatasetSociomapFragmentFragment = (
  { readonly __typename: 'InsightDatasetSociomap' }
  & Pick<InsightDatasetSociomap, 'id' | 'demo'>
  & { readonly values: ReadonlyArray<(
    { readonly __typename: 'InsightDatasetValueSociomap' }
    & Pick<InsightDatasetValueSociomap, 'x' | 'y' | 'z' | 'n'>
    & { readonly f: (
      { readonly __typename: 'InsightValueFilter' }
      & Pick<InsightValueFilter, 'dep'>
    ) }
  )> }
);

export type InsightFragmentFragment = (
  { readonly __typename: 'Insight' }
  & Pick<Insight, 'id' | 'uuid' | 'chartUuid' | 'status' | 'dateFrom' | 'dateTo'>
  & { readonly departments: ReadonlyArray<(
    { readonly __typename: 'InsightDepartment' }
    & Pick<InsightDepartment, 'uuid' | 'name'>
  )>, readonly datasets: ReadonlyArray<(
    { readonly __typename: 'InsightDatasetMulti' }
    & InsightDatasetMultiFragmentFragment
  ) | { readonly __typename: 'InsightDatasetOneOnOne' } | (
    { readonly __typename: 'InsightDatasetSingle' }
    & InsightDatasetSingleFragmentFragment
  ) | (
    { readonly __typename: 'InsightDatasetSociomap' }
    & InsightDatasetSociomapFragmentFragment
  ) | (
    { readonly __typename: 'InsightDatasetSpanOfControl' }
    & InsightDatasetSpanOfControlFragmentFragment
  )> }
);

export type NodeDetailDataFragment_Department_Fragment = (
  { readonly __typename: 'Department' }
  & DepartmentDetailDataFragmentFragment
);

export type NodeDetailDataFragment_Person_Fragment = (
  { readonly __typename: 'Person' }
  & PersonDetailDataFragmentFragment
);

export type NodeDetailDataFragmentFragment = NodeDetailDataFragment_Department_Fragment | NodeDetailDataFragment_Person_Fragment;

export type NodeDataFragment_Department_Fragment = (
  { readonly __typename: 'Department' }
  & DepartmentDataFragmentFragment
);

export type NodeDataFragment_Person_Fragment = (
  { readonly __typename: 'Person' }
  & PersonDataFragmentFragment
);

export type NodeDataFragmentFragment = NodeDataFragment_Department_Fragment | NodeDataFragment_Person_Fragment;

export type NodeSearchDataFragment_Department_Fragment = (
  { readonly __typename: 'Department' }
  & DepartmentSearchDataFragmentFragment
);

export type NodeSearchDataFragment_Person_Fragment = (
  { readonly __typename: 'Person' }
  & PersonSearchDataFragmentFragment
);

export type NodeSearchDataFragmentFragment = NodeSearchDataFragment_Department_Fragment | NodeSearchDataFragment_Person_Fragment;

export type PersonChildDepartmentsDataFragmentFragment = (
  { readonly __typename: 'Person' }
  & Pick<Person, 'uuid' | 'id' | 'expanded'>
  & { readonly childDepartments: ReadonlyArray<(
    { readonly __typename: 'Department' }
    & Pick<Department, 'uuid' | 'id' | 'name' | 'color' | 'parentUuid' | 'expanded'>
  )> }
);

export type PersonDetailDataFragmentFragment = (
  { readonly __typename: 'Person' }
  & Pick<Person, 'employeeCount' | 'departmentCount' | 'chartUuid' | 'unassigned' | 'deleted' | 'provider' | 'calendarUrl' | 'email' | 'image' | 'mobile' | 'name' | 'givenName' | 'familyName' | 'location' | 'buildingId' | 'floorName' | 'floorSection' | 'deskCode' | 'position' | 'id' | 'uuid' | 'parentUuid' | 'emailAliases' | 'employeeType' | 'employeeId' | 'costCenter' | 'type' | 'teamSizeTest' | 'xLink' | 'linkedInLink' | 'facebookLink' | 'youTubeLink' | 'instagramLink' | 'whatsAppLink' | 'expanded'>
  & { readonly capabilities?: Maybe<(
    { readonly __typename: 'PersonCapabilities' }
    & Pick<PersonCapabilities, 'canMove' | 'canDelete' | 'canUnassign' | 'canUpdate' | 'canUpdateName' | 'canUpdatePosition' | 'canUpdateEmail' | 'canUpdateMobile' | 'canUpdateImage' | 'canUpdateLocation' | 'canUpdateEmailAliases' | 'canUpdateBuildingId' | 'canUpdateFloorName' | 'canUpdateFloorSection' | 'canUpdateDeskCode' | 'canUpdateEmployeeId' | 'canUpdateEmployeeType' | 'canUpdateCostCenter' | 'canUpdateSocialContacts'>
  )>, readonly parentNodes: ReadonlyArray<(
    { readonly __typename: 'Department' }
    & Pick<Department, 'id' | 'uuid' | 'name' | 'color' | 'expanded'>
  ) | (
    { readonly __typename: 'Person' }
    & Pick<Person, 'id' | 'uuid' | 'name' | 'expanded'>
  )>, readonly customFields: ReadonlyArray<(
    { readonly __typename: 'CustomConnectionFieldValue' }
    & Pick<CustomConnectionFieldValue, 'id' | 'type' | 'values'>
    & { readonly nodes: ReadonlyArray<{ readonly __typename: 'Department' } | (
      { readonly __typename: 'Person' }
      & Pick<Person, 'id' | 'familyName' | 'givenName'>
    )> }
  ) | (
    { readonly __typename: 'CustomDateFieldValue' }
    & Pick<CustomDateFieldValue, 'id' | 'type' | 'value'>
  ) | (
    { readonly __typename: 'CustomDropdownFieldValue' }
    & Pick<CustomDropdownFieldValue, 'id' | 'type' | 'value'>
  ) | (
    { readonly __typename: 'CustomTextFieldValue' }
    & Pick<CustomTextFieldValue, 'id' | 'type' | 'value'>
  )> }
);

export type PersonDataFragmentFragment = (
  { readonly __typename: 'Person' }
  & Pick<Person, 'employeeCount' | 'departmentCount' | 'chartUuid' | 'unassigned' | 'deleted' | 'image' | 'name' | 'givenName' | 'familyName' | 'position' | 'email' | 'emailAliases' | 'uuid' | 'id' | 'parentUuid' | 'type' | 'teamSizeTest' | 'xLink' | 'linkedInLink' | 'facebookLink' | 'youTubeLink' | 'instagramLink' | 'whatsAppLink' | 'expanded'>
  & { readonly capabilities?: Maybe<(
    { readonly __typename: 'PersonCapabilities' }
    & Pick<PersonCapabilities, 'canUpdate' | 'canMove' | 'canDelete' | 'canUnassign'>
  )>, readonly parentNodes: ReadonlyArray<(
    { readonly __typename: 'Department' }
    & Pick<Department, 'id' | 'name' | 'uuid' | 'color' | 'expanded'>
  ) | (
    { readonly __typename: 'Person' }
    & Pick<Person, 'id' | 'name' | 'uuid' | 'expanded'>
  )>, readonly customFields: ReadonlyArray<(
    { readonly __typename: 'CustomConnectionFieldValue' }
    & Pick<CustomConnectionFieldValue, 'id' | 'type' | 'values'>
    & { readonly nodes: ReadonlyArray<{ readonly __typename: 'Department' } | (
      { readonly __typename: 'Person' }
      & Pick<Person, 'id' | 'familyName' | 'givenName'>
    )> }
  ) | { readonly __typename: 'CustomDateFieldValue' } | (
    { readonly __typename: 'CustomDropdownFieldValue' }
    & Pick<CustomDropdownFieldValue, 'id' | 'type' | 'value'>
  ) | { readonly __typename: 'CustomTextFieldValue' }> }
);

export type PersonIdentifyFragmentFragment = (
  { readonly __typename: 'Person' }
  & Pick<Person, 'id' | 'uuid'>
);

export type PersonSearchDataFragmentFragment = (
  { readonly __typename: 'Person' }
  & Pick<Person, 'chartUuid' | 'id' | 'uuid' | 'type' | 'email' | 'image' | 'name' | 'position' | 'unassigned' | 'expanded'>
  & { readonly capabilities?: Maybe<(
    { readonly __typename: 'PersonCapabilities' }
    & Pick<PersonCapabilities, 'canMove'>
  )>, readonly parentNodes: ReadonlyArray<(
    { readonly __typename: 'Department' }
    & Pick<Department, 'id' | 'uuid' | 'expanded'>
  ) | (
    { readonly __typename: 'Person' }
    & Pick<Person, 'id' | 'uuid' | 'expanded'>
  )> }
);

export type PersonTeamSizeFragmentFragment = (
  { readonly __typename: 'Person' }
  & Pick<Person, 'id' | 'uuid' | 'teamSizeTest'>
);

export type PublicLinkFragment = (
  { readonly __typename: 'PublicLink' }
  & Pick<PublicLink, 'id' | 'uuid' | 'url' | 'domains' | 'ips' | 'allowedCustomFields'>
);

export type SubscriptionInfoFragment = (
  { readonly __typename: 'SubscriptionInfo' }
  & Pick<SubscriptionInfo, 'plan' | 'upgradable'>
  & { readonly capabilities: (
    { readonly __typename: 'SubscriptionCapabilities' }
    & Pick<SubscriptionCapabilities, 'canManage'>
  ), readonly quotas: (
    { readonly __typename: 'QuotaCollection' }
    & { readonly items: ReadonlyArray<(
      { readonly __typename: 'Quota' }
      & Pick<Quota, 'id' | 'displayName' | 'softLimit' | 'hardLimit' | 'currentValue'>
    )> }
  ) }
);

export type TeamMembersFragmentFragment = (
  { readonly __typename: 'Person' }
  & Pick<Person, 'id' | 'uuid' | 'givenName' | 'familyName'>
);

export type TrackEventResultFragmentFragment = (
  { readonly __typename: 'TrackEventResult' }
  & Pick<TrackEventResult, 'success'>
);

export type UploadUrlPolicyFragmentFragment = (
  { readonly __typename: 'UploadUrlPolicy' }
  & Pick<UploadUrlPolicy, 'url' | 'getUrl'>
  & { readonly fields: ReadonlyArray<(
    { readonly __typename: 'SignedUrlPolicyField' }
    & Pick<SignedUrlPolicyField, 'key' | 'value'>
  )> }
);

export type UserFragment = (
  { readonly __typename: 'User' }
  & Pick<User, 'id' | 'name' | 'email' | 'emailVerified' | 'provider'>
  & { readonly subscription: (
    { readonly __typename: 'SubscriptionInfo' }
    & SubscriptionInfoFragment
  ) }
);

export type AcceptInvitationMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type AcceptInvitationMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly acceptInvitation?: Maybe<(
    { readonly __typename: 'Collaborator' }
    & CollaboratorFragmentFragment
  )> }
);

export type AddCollaboratorMutationVariables = Exact<{
  data: AddCollaboratorInput;
  chartUuid: Scalars['String'];
}>;


export type AddCollaboratorMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly addCollaborator?: Maybe<(
    { readonly __typename: 'Collaborator' }
    & CollaboratorFragmentFragment
  )> }
);

export type AuthorizeMutationVariables = Exact<{
  codeChallenge: Scalars['String'];
  authorizeInput?: Maybe<AuthorizeInput>;
}>;


export type AuthorizeMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly authorize?: Maybe<(
    { readonly __typename: 'AuthorizeResponse' }
    & Pick<AuthorizeResponse, 'url'>
  )> }
);

export type CloneChartMutationVariables = Exact<{
  uuid: Scalars['String'];
  rootUuid?: Maybe<Scalars['String']>;
}>;


export type CloneChartMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly cloneChart?: Maybe<(
    { readonly __typename: 'Chart' }
    & ChartFragmentFragment
  )> }
);

export type CreateChartFromFileMutationVariables = Exact<{
  data: CreateChartFromFileInput;
}>;


export type CreateChartFromFileMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createChartFromFile?: Maybe<(
    { readonly __typename: 'Chart' }
    & ChartFragmentFragment
  )> }
);

export type CreateChartFromGoogleWorkspaceMutationVariables = Exact<{
  data: CreateChartFromGoogleWorkspaceInput;
}>;


export type CreateChartFromGoogleWorkspaceMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createChartFromGoogleWorkspace?: Maybe<(
    { readonly __typename: 'Chart' }
    & ChartFragmentFragment
  )> }
);

export type CreateChartMutationVariables = Exact<{
  data: CreateChartInput;
}>;


export type CreateChartMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createChart?: Maybe<(
    { readonly __typename: 'Chart' }
    & ChartFragmentFragment
  )> }
);

export type CreateConnectionFieldMutationVariables = Exact<{
  data: CreateCustomConnectionFieldInput;
  chartUuid: Scalars['String'];
}>;


export type CreateConnectionFieldMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createConnectionField?: Maybe<(
    { readonly __typename: 'CustomConnectionField' }
    & CustomConnectionFieldFragmentFragment
  )> }
);

export type CreateDateFieldMutationVariables = Exact<{
  data: CreateCustomDateFieldInput;
  chartUuid: Scalars['String'];
}>;


export type CreateDateFieldMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createDateField?: Maybe<(
    { readonly __typename: 'CustomDateField' }
    & CustomDateFieldFragmentFragment
  )> }
);

export type CreateDepartmentMutationVariables = Exact<{
  data: CreateDepartmentInput;
  chartUuid: Scalars['String'];
}>;


export type CreateDepartmentMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createDepartment?: Maybe<(
    { readonly __typename: 'Department' }
    & DepartmentDataFragmentFragment
  )> }
);

export type CreateDropdownFieldMutationVariables = Exact<{
  data: CreateCustomDropdownFieldInput;
  chartUuid: Scalars['String'];
}>;


export type CreateDropdownFieldMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createDropdownField?: Maybe<(
    { readonly __typename: 'CustomDropdownField' }
    & CustomDropdownFieldFragmentFragment
  )> }
);

export type CreatePersonMutationVariables = Exact<{
  data: CreatePersonInput;
  chartUuid: Scalars['String'];
}>;


export type CreatePersonMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createPerson?: Maybe<(
    { readonly __typename: 'Person' }
    & PersonDataFragmentFragment
  )> }
);

export type CreatePublicLinkMutationVariables = Exact<{
  chartUuid: Scalars['String'];
  createPublicLinkInput: CreatePublicLinkInput;
}>;


export type CreatePublicLinkMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createPublicLink?: Maybe<(
    { readonly __typename: 'PublicLink' }
    & PublicLinkFragment
  )> }
);

export type CreateStripeCheckoutSessionMutationVariables = Exact<{
  billingPeriod?: Maybe<Scalars['String']>;
  plan?: Maybe<Scalars['String']>;
  coupon?: Maybe<Scalars['String']>;
}>;


export type CreateStripeCheckoutSessionMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createStripeCheckoutSession?: Maybe<(
    { readonly __typename: 'StripeSession' }
    & Pick<StripeSession, 'url'>
  )> }
);

export type CreateStripePortalSessionMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateStripePortalSessionMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createStripePortalSession?: Maybe<(
    { readonly __typename: 'StripeSession' }
    & Pick<StripeSession, 'url'>
  )> }
);

export type CreateTextFieldMutationVariables = Exact<{
  data: CreateCustomTextFieldInput;
  chartUuid: Scalars['String'];
}>;


export type CreateTextFieldMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly createTextField?: Maybe<(
    { readonly __typename: 'CustomTextField' }
    & CustomTextFieldFragmentFragment
  )> }
);

export type DeleteChartMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type DeleteChartMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly deleteChart?: Maybe<(
    { readonly __typename: 'Chart' }
    & ChartFragmentFragment
  )> }
);

export type DeleteCollaboratorMutationVariables = Exact<{
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
}>;


export type DeleteCollaboratorMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly deleteCollaborator?: Maybe<(
    { readonly __typename: 'Collaborator' }
    & CollaboratorFragmentFragment
  )> }
);

export type DeleteConnectionFieldMutationVariables = Exact<{
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
}>;


export type DeleteConnectionFieldMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly deleteConnectionField?: Maybe<(
    { readonly __typename: 'CustomConnectionField' }
    & CustomConnectionFieldFragmentFragment
  )> }
);

export type DeleteDateFieldMutationVariables = Exact<{
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
}>;


export type DeleteDateFieldMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly deleteDateField?: Maybe<(
    { readonly __typename: 'CustomDateField' }
    & CustomDateFieldFragmentFragment
  )> }
);

export type DeleteDropdownFieldMutationVariables = Exact<{
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
}>;


export type DeleteDropdownFieldMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly deleteDropdownField?: Maybe<(
    { readonly __typename: 'CustomDropdownField' }
    & CustomDropdownFieldFragmentFragment
  )> }
);

export type DeleteNodeMutationVariables = Exact<{
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
}>;


export type DeleteNodeMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly deleteNode: ReadonlyArray<(
    { readonly __typename: 'Department' }
    & NodeDataFragment_Department_Fragment
  ) | (
    { readonly __typename: 'Person' }
    & NodeDataFragment_Person_Fragment
  )> }
);

export type DeletePublicLinkMutationVariables = Exact<{
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
}>;


export type DeletePublicLinkMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly deletePublicLink?: Maybe<(
    { readonly __typename: 'PublicLink' }
    & PublicLinkFragment
  )> }
);

export type DeleteTextFieldMutationVariables = Exact<{
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
}>;


export type DeleteTextFieldMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly deleteTextField?: Maybe<(
    { readonly __typename: 'CustomTextField' }
    & CustomTextFieldFragmentFragment
  )> }
);

export type GenerateAvatarUploadUrlPolicyMutationVariables = Exact<{
  chartUuid: Scalars['String'];
  contentType: Scalars['String'];
}>;


export type GenerateAvatarUploadUrlPolicyMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly generateAvatarUploadUrlPolicy?: Maybe<(
    { readonly __typename: 'UploadUrlPolicy' }
    & UploadUrlPolicyFragmentFragment
  )> }
);

export type GenerateLogoUploadUrlPolicyMutationVariables = Exact<{
  chartUuid: Scalars['String'];
  contentType: Scalars['String'];
}>;


export type GenerateLogoUploadUrlPolicyMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly generateLogoUploadUrlPolicy?: Maybe<(
    { readonly __typename: 'UploadUrlPolicy' }
    & UploadUrlPolicyFragmentFragment
  )> }
);

export type MakeChartEditableMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type MakeChartEditableMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly makeChartEditable?: Maybe<(
    { readonly __typename: 'Chart' }
    & ChartFragmentFragment
  )> }
);

export type MoveNodeMutationVariables = Exact<{
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
  targetUuid?: Maybe<Scalars['String']>;
}>;


export type MoveNodeMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly moveNode?: Maybe<(
    { readonly __typename: 'Department' }
    & NodeDataFragment_Department_Fragment
  ) | (
    { readonly __typename: 'Person' }
    & NodeDataFragment_Person_Fragment
  )> }
);

export type ResendInvitationMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type ResendInvitationMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly resendInvitation?: Maybe<(
    { readonly __typename: 'Collaborator' }
    & CollaboratorFragmentFragment
  )> }
);

export type RevokeChartTokenMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type RevokeChartTokenMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly revokeChartToken?: Maybe<(
    { readonly __typename: 'Chart' }
    & ChartFragmentFragment
  )> }
);

export type SendVerificationEmailMutationVariables = Exact<{ [key: string]: never; }>;


export type SendVerificationEmailMutation = (
  { readonly __typename: 'Mutation' }
  & Pick<Mutation, 'sendVerificationEmail'>
);

export type SetShowUnassignedNodesSidebarMutationVariables = Exact<{
  showUnassignedNodesSidebar: Scalars['Boolean'];
}>;


export type SetShowUnassignedNodesSidebarMutation = (
  { readonly __typename: 'Mutation' }
  & Pick<Mutation, 'setShowUnassignedNodesSidebar'>
);

export type SyncChartWithFileMutationVariables = Exact<{
  uuid: Scalars['String'];
  data: SyncChartWithFileInput;
}>;


export type SyncChartWithFileMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly syncChartWithFile?: Maybe<(
    { readonly __typename: 'Chart' }
    & Pick<Chart, 'id'>
  )> }
);

export type SyncChartMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type SyncChartMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly syncChart?: Maybe<(
    { readonly __typename: 'Chart' }
    & ChartFragmentFragment
  )> }
);

export type ToggleSubtreeMutationVariables = Exact<{
  id: Scalars['String'];
  expanded: Scalars['Boolean'];
}>;


export type ToggleSubtreeMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly toggleSubtree?: Maybe<(
    { readonly __typename: 'Department' }
    & Pick<Department, 'id' | 'expanded'>
  ) | (
    { readonly __typename: 'Person' }
    & Pick<Person, 'id' | 'expanded'>
  )> }
);

export type TrackEventMutationVariables = Exact<{
  trackEventInput: TrackEventInput;
}>;


export type TrackEventMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly trackEvent?: Maybe<(
    { readonly __typename: 'TrackEventResult' }
    & TrackEventResultFragmentFragment
  )> }
);

export type UnassignNodeMutationVariables = Exact<{
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
}>;


export type UnassignNodeMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly unassignNode: ReadonlyArray<(
    { readonly __typename: 'Department' }
    & NodeDataFragment_Department_Fragment
  ) | (
    { readonly __typename: 'Person' }
    & NodeDataFragment_Person_Fragment
  )> }
);

export type UpdateChartTokenMutationVariables = Exact<{
  uuid: Scalars['String'];
  data: UpdateChartTokenInput;
}>;


export type UpdateChartTokenMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateChartToken?: Maybe<(
    { readonly __typename: 'Chart' }
    & ChartFragmentFragment
  )> }
);

export type UpdateChartMutationVariables = Exact<{
  uuid: Scalars['String'];
  data: UpdateChartInput;
}>;


export type UpdateChartMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateChart?: Maybe<(
    { readonly __typename: 'Chart' }
    & ChartFragmentFragment
  )> }
);

export type UpdateCollaboratorMutationVariables = Exact<{
  data: UpdateCollaboratorInput;
  uuid: Scalars['String'];
  chartUuid: Scalars['String'];
}>;


export type UpdateCollaboratorMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateCollaborator?: Maybe<(
    { readonly __typename: 'Collaborator' }
    & CollaboratorFragmentFragment
  )> }
);

export type UpdateConnectionFieldMutationVariables = Exact<{
  data: UpdateCustomConnectionFieldInput;
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
}>;


export type UpdateConnectionFieldMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateConnectionField?: Maybe<(
    { readonly __typename: 'CustomConnectionField' }
    & CustomConnectionFieldFragmentFragment
  )> }
);

export type UpdateDateFieldMutationVariables = Exact<{
  data: UpdateCustomDateFieldInput;
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
}>;


export type UpdateDateFieldMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateDateField?: Maybe<(
    { readonly __typename: 'CustomDateField' }
    & CustomDateFieldFragmentFragment
  )> }
);

export type UpdateDepartmentMutationVariables = Exact<{
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
  data: UpdateDepartmentInput;
}>;


export type UpdateDepartmentMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateDepartment?: Maybe<(
    { readonly __typename: 'Department' }
    & DepartmentDataFragmentFragment
  )> }
);

export type UpdateDropdownFieldMutationVariables = Exact<{
  data: UpdateCustomDropdownFieldInput;
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
}>;


export type UpdateDropdownFieldMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateDropdownField?: Maybe<(
    { readonly __typename: 'CustomDropdownField' }
    & CustomDropdownFieldFragmentFragment
  )> }
);

export type UpdatePersonMutationVariables = Exact<{
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
  data: UpdatePersonInput;
}>;


export type UpdatePersonMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updatePerson?: Maybe<(
    { readonly __typename: 'Person' }
    & PersonDetailDataFragmentFragment
  )> }
);

export type UpdatePublicLinkMutationVariables = Exact<{
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
  updatePublicLinkInput: UpdatePublicLinkInput;
}>;


export type UpdatePublicLinkMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updatePublicLink?: Maybe<(
    { readonly __typename: 'PublicLink' }
    & PublicLinkFragment
  )> }
);

export type UpdateTextFieldMutationVariables = Exact<{
  data: UpdateCustomTextFieldInput;
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
}>;


export type UpdateTextFieldMutation = (
  { readonly __typename: 'Mutation' }
  & { readonly updateTextField?: Maybe<(
    { readonly __typename: 'CustomTextField' }
    & CustomTextFieldFragmentFragment
  )> }
);

export type ExportNodesQueryVariables = Exact<{
  chartUuid: Scalars['String'];
  config: ExportNodesConfig;
}>;


export type ExportNodesQuery = (
  { readonly __typename: 'Query' }
  & { readonly exportNodes: (
    { readonly __typename: 'NodeExport' }
    & Pick<NodeExport, 'downloadUrl'>
  ) }
);

export type AppQueryVariables = Exact<{ [key: string]: never; }>;


export type AppQuery = (
  { readonly __typename: 'Query' }
  & { readonly app?: Maybe<(
    { readonly __typename: 'App' }
    & AppFragmentFragment
  )> }
);

export type ChartQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type ChartQuery = (
  { readonly __typename: 'Query' }
  & { readonly chart?: Maybe<(
    { readonly __typename: 'Chart' }
    & ChartDetailFragmentFragment
  )> }
);

export type ChartsQueryVariables = Exact<{ [key: string]: never; }>;


export type ChartsQuery = (
  { readonly __typename: 'Query' }
  & { readonly charts?: Maybe<(
    { readonly __typename: 'ChartCollection' }
    & { readonly items: ReadonlyArray<(
      { readonly __typename: 'Chart' }
      & ChartFragmentFragment
    )> }
  )> }
);

export type GetCollaboratorsQueryVariables = Exact<{
  chartUuid: Scalars['String'];
}>;


export type GetCollaboratorsQuery = (
  { readonly __typename: 'Query' }
  & { readonly getCollaborators: ReadonlyArray<(
    { readonly __typename: 'Collaborator' }
    & CollaboratorFragmentFragment
  )> }
);

export type InsightsQueryVariables = Exact<{
  chartUuid: Scalars['String'];
}>;


export type InsightsQuery = (
  { readonly __typename: 'Query' }
  & { readonly insights?: Maybe<(
    { readonly __typename: 'InsightCollection' }
    & { readonly items: ReadonlyArray<(
      { readonly __typename: 'Insight' }
      & InsightFragmentFragment
    )> }
  )> }
);

export type GetInvitationQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type GetInvitationQuery = (
  { readonly __typename: 'Query' }
  & { readonly getInvitation?: Maybe<(
    { readonly __typename: 'Collaborator' }
    & CollaboratorFragmentFragment
  )> }
);

export type NodeChildDepartmentsQueryVariables = Exact<{
  chartUuid: Scalars['String'];
  uuid: Scalars['String'];
}>;


export type NodeChildDepartmentsQuery = (
  { readonly __typename: 'Query' }
  & { readonly node?: Maybe<{ readonly __typename: 'Department' } | (
    { readonly __typename: 'Person' }
    & PersonChildDepartmentsDataFragmentFragment
  )> }
);

export type NodeQueryVariables = Exact<{
  chartKey: Scalars['String'];
  uuid: Scalars['String'];
}>;


export type NodeQuery = (
  { readonly __typename: 'Query' }
  & { readonly node?: Maybe<(
    { readonly __typename: 'Department' }
    & NodeDetailDataFragment_Department_Fragment
  ) | (
    { readonly __typename: 'Person' }
    & NodeDetailDataFragment_Person_Fragment
  )> }
);

export type NodesQueryVariables = Exact<{
  chartKey: Scalars['String'];
  filter?: Maybe<GetNodesFilter>;
}>;


export type NodesQuery = (
  { readonly __typename: 'Query' }
  & { readonly nodes: (
    { readonly __typename: 'NodeCollection' }
    & { readonly items: ReadonlyArray<(
      { readonly __typename: 'Department' }
      & NodeDataFragment_Department_Fragment
    ) | (
      { readonly __typename: 'Person' }
      & NodeDataFragment_Person_Fragment
    )> }
  ) }
);

export type PersonTeamSizeQueryVariables = Exact<{
  chartKey: Scalars['String'];
  uuid: Scalars['String'];
}>;


export type PersonTeamSizeQuery = (
  { readonly __typename: 'Query' }
  & { readonly node?: Maybe<{ readonly __typename: 'Department' } | (
    { readonly __typename: 'Person' }
    & PersonTeamSizeFragmentFragment
  )> }
);

export type GetPublicLinksQueryVariables = Exact<{
  chartUuid: Scalars['String'];
}>;


export type GetPublicLinksQuery = (
  { readonly __typename: 'Query' }
  & { readonly getPublicLinks: ReadonlyArray<(
    { readonly __typename: 'PublicLink' }
    & PublicLinkFragment
  )> }
);

export type TeamMembersQueryVariables = Exact<{
  chartKey: Scalars['String'];
  filter: TeamMembersFilter;
}>;


export type TeamMembersQuery = (
  { readonly __typename: 'Query' }
  & { readonly teamMembers: (
    { readonly __typename: 'NodeCollection' }
    & { readonly items: ReadonlyArray<{ readonly __typename: 'Department' } | (
      { readonly __typename: 'Person' }
      & TeamMembersFragmentFragment
    )> }
  ) }
);

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = (
  { readonly __typename: 'Query' }
  & { readonly user?: Maybe<(
    { readonly __typename: 'User' }
    & UserFragment
  )> }
);

export type SearchNodesQueryVariables = Exact<{
  chartKey: Scalars['String'];
  filter: SearchNodesFilter;
}>;


export type SearchNodesQuery = (
  { readonly __typename: 'Query' }
  & { readonly searchNodes: (
    { readonly __typename: 'NodeCollection' }
    & { readonly items: ReadonlyArray<(
      { readonly __typename: 'Department' }
      & NodeSearchDataFragment_Department_Fragment
    ) | (
      { readonly __typename: 'Person' }
      & NodeSearchDataFragment_Person_Fragment
    )> }
  ) }
);

export type ShowUnassignedNodesSidebarQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type ShowUnassignedNodesSidebarQueryQuery = (
  { readonly __typename: 'Query' }
  & Pick<Query, 'showUnassignedNodesSidebar'>
);

export const AppFragmentFragmentDoc = gql`
    fragment AppFragment on App {
  version {
    web
  }
}
    `;
export const CustomTextFieldFragmentFragmentDoc = gql`
    fragment CustomTextFieldFragment on CustomTextField {
  id
  type
  label
}
    `;
export const CustomDateFieldFragmentFragmentDoc = gql`
    fragment CustomDateFieldFragment on CustomDateField {
  id
  type
  label
}
    `;
export const CustomDropdownFieldFragmentFragmentDoc = gql`
    fragment CustomDropdownFieldFragment on CustomDropdownField {
  id
  type
  label
  options {
    id
    label
    icon
    background
  }
  displayIconsOnChartNode
}
    `;
export const CustomConnectionFieldFragmentFragmentDoc = gql`
    fragment CustomConnectionFieldFragment on CustomConnectionField {
  id
  displayLineOnChart
  label
  lineColor
  lineStyle
  type
}
    `;
export const CustomFieldFragmentFragmentDoc = gql`
    fragment CustomFieldFragment on CustomFieldItem {
  ...CustomTextFieldFragment
  ...CustomDateFieldFragment
  ...CustomDropdownFieldFragment
  ...CustomConnectionFieldFragment
}
    ${CustomTextFieldFragmentFragmentDoc}
${CustomDateFieldFragmentFragmentDoc}
${CustomDropdownFieldFragmentFragmentDoc}
${CustomConnectionFieldFragmentFragmentDoc}`;
export const SubscriptionInfoFragmentDoc = gql`
    fragment SubscriptionInfo on SubscriptionInfo {
  plan
  upgradable
  capabilities {
    canManage
  }
  quotas {
    items {
      id
      displayName
      softLimit
      hardLimit
      currentValue
    }
  }
}
    `;
export const ChartCapabilitiesFragmentDoc = gql`
    fragment ChartCapabilities on ChartCapabilities {
  canAddCollaborator
  canClone
  canCreateCustomField
  canCreateDepartment
  canCreatePerson
  canCreatePublicLink
  canDelete
  canDeleteCollaborator
  canDeleteCustomField
  canDeletePublicLink
  canExport
  canReadCollaborators
  canReadCustomField
  canReadInsight
  canReadPublicLink
  canUpdate
  canUpdateCollaborator
  canUpdateCustomField
  canUpdatePublicLink
  canReadDirectory
  canSync
  canRevokeToken
}
    `;
export const ChartDetailFragmentFragmentDoc = gql`
    fragment ChartDetailFragment on Chart {
  id
  uuid
  name
  emoji
  logo
  lock {
    active
    from
  }
  providerInfo {
    name
    syncedFields {
      name
      readonly
    }
    scopes
    capabilities {
      canReadUser
      canUpdateUser
      canReadCalendar
    }
  }
  public
  adminEmail
  customFields {
    ...CustomFieldFragment
  }
  employeeCount
  departmentCount
  unassignedEmployeeCount
  unassignedDepartmentCount
  subscription {
    ...SubscriptionInfo
  }
  userPermission {
    role
  }
  capabilities {
    ...ChartCapabilities
  }
  me {
    ... on Person {
      id
      email
      teamSize
    }
  }
}
    ${CustomFieldFragmentFragmentDoc}
${SubscriptionInfoFragmentDoc}
${ChartCapabilitiesFragmentDoc}`;
export const ChartFragmentFragmentDoc = gql`
    fragment ChartFragment on Chart {
  id
  uuid
  name
  emoji
  logo
  lock {
    active
    from
  }
  providerInfo {
    name
    syncedFields {
      name
      readonly
    }
    scopes
    capabilities {
      canReadUser
      canUpdateUser
      canReadCalendar
    }
  }
  public
  adminEmail
  topNodes {
    items {
      ... on Person {
        id
        uuid
        name
        image
        position
        type
        expanded @client
      }
      ... on Department {
        id
        uuid
        name
        color
        type
        employeeCount
        expanded @client
      }
    }
  }
  subscription {
    ...SubscriptionInfo
  }
  employeeCount
  departmentCount
  unassignedEmployeeCount
  unassignedDepartmentCount
  userPermission {
    role
  }
  capabilities {
    ...ChartCapabilities
  }
}
    ${SubscriptionInfoFragmentDoc}
${ChartCapabilitiesFragmentDoc}`;
export const ChartMeFragmentFragmentDoc = gql`
    fragment ChartMeFragment on Chart {
  id
  me {
    ... on Person {
      id
      email
      teamSize
    }
  }
}
    `;
export const CollaboratorFragmentFragmentDoc = gql`
    fragment CollaboratorFragment on Collaborator {
  chartUuid
  id
  uuid
  accepted
  status
  email
  role
  allowedRoles
}
    `;
export const DepartmentIdentifyFragmentFragmentDoc = gql`
    fragment DepartmentIdentifyFragment on Department {
  id
  uuid
}
    `;
export const ExpandedPersonFragmentFragmentDoc = gql`
    fragment ExpandedPersonFragment on Person {
  expanded @client
}
    `;
export const ExpandedDepartmentFragmentFragmentDoc = gql`
    fragment ExpandedDepartmentFragment on Department {
  expanded @client
}
    `;
export const InsightDatasetSingleFragmentFragmentDoc = gql`
    fragment InsightDatasetSingleFragment on InsightDatasetSingle {
  id
  values {
    f {
      dep
    }
    v
    l
  }
}
    `;
export const InsightDatasetMultiFragmentFragmentDoc = gql`
    fragment InsightDatasetMultiFragment on InsightDatasetMulti {
  id
  values {
    f {
      dep
    }
    d {
      v
      l
    }
  }
}
    `;
export const InsightDatasetSpanOfControlFragmentFragmentDoc = gql`
    fragment InsightDatasetSpanOfControlFragment on InsightDatasetSpanOfControl {
  id
  values {
    f {
      dep
    }
    uuid
    image
    givenName
    familyName
    department {
      uuid
      name
    }
    subordinatesCount
  }
}
    `;
export const InsightDatasetSociomapFragmentFragmentDoc = gql`
    fragment InsightDatasetSociomapFragment on InsightDatasetSociomap {
  id
  demo
  values {
    f {
      dep
    }
    x
    y
    z
    n
  }
}
    `;
export const InsightFragmentFragmentDoc = gql`
    fragment InsightFragment on Insight {
  id
  uuid
  chartUuid
  status
  dateFrom
  dateTo
  departments {
    uuid
    name
  }
  datasets {
    ...InsightDatasetSingleFragment
    ...InsightDatasetMultiFragment
    ...InsightDatasetSpanOfControlFragment
    ...InsightDatasetSociomapFragment
  }
}
    ${InsightDatasetSingleFragmentFragmentDoc}
${InsightDatasetMultiFragmentFragmentDoc}
${InsightDatasetSpanOfControlFragmentFragmentDoc}
${InsightDatasetSociomapFragmentFragmentDoc}`;
export const PersonDetailDataFragmentFragmentDoc = gql`
    fragment PersonDetailDataFragment on Person {
  employeeCount
  departmentCount
  chartUuid
  unassigned
  deleted
  provider
  calendarUrl
  capabilities {
    canMove
    canDelete
    canUnassign
    canUpdate
    canUpdateName
    canUpdatePosition
    canUpdateEmail
    canUpdateMobile
    canUpdateImage
    canUpdateLocation
    canUpdateEmailAliases
    canUpdateBuildingId
    canUpdateFloorName
    canUpdateFloorSection
    canUpdateDeskCode
    canUpdateEmployeeId
    canUpdateEmployeeType
    canUpdateCostCenter
    canUpdateSocialContacts
  }
  parentNodes {
    ... on Department {
      id
      uuid
      name
      color
      expanded @client
    }
    ... on Person {
      id
      uuid
      name
      expanded @client
    }
  }
  customFields {
    ... on CustomTextFieldValue {
      id
      type
      value
    }
    ... on CustomDateFieldValue {
      id
      type
      value
    }
    ... on CustomDropdownFieldValue {
      id
      type
      value
    }
    ... on CustomConnectionFieldValue {
      id
      type
      values
      nodes {
        ... on Person {
          id
          familyName
          givenName
        }
      }
    }
  }
  email
  image
  mobile
  name
  givenName
  familyName
  location
  buildingId
  floorName
  floorSection
  deskCode
  position
  id
  uuid
  parentUuid
  emailAliases
  employeeType
  employeeId
  costCenter
  type
  teamSizeTest
  xLink
  linkedInLink
  facebookLink
  youTubeLink
  instagramLink
  whatsAppLink
  expanded @client
}
    `;
export const DepartmentDetailDataFragmentFragmentDoc = gql`
    fragment DepartmentDetailDataFragment on Department {
  id
  uuid
  capabilities {
    canUpdate
    canMove
    canDelete
    canUnassign
  }
  parentNodes {
    ... on Department {
      id
      uuid
      name
      color
      expanded @client
    }
    ... on Person {
      id
      uuid
      name
      expanded @client
    }
  }
  unassigned
  deleted
  parentUuid
  chartUuid
  name
  color
  description
  type
  childrenCount
  employeeCount
  departmentCount
  expanded @client
}
    `;
export const NodeDetailDataFragmentFragmentDoc = gql`
    fragment NodeDetailDataFragment on Node {
  ...PersonDetailDataFragment
  ...DepartmentDetailDataFragment
}
    ${PersonDetailDataFragmentFragmentDoc}
${DepartmentDetailDataFragmentFragmentDoc}`;
export const PersonDataFragmentFragmentDoc = gql`
    fragment PersonDataFragment on Person {
  employeeCount
  departmentCount
  chartUuid
  unassigned
  deleted
  capabilities {
    canUpdate
    canMove
    canDelete
    canUnassign
  }
  parentNodes {
    ... on Department {
      id
      name
      uuid
      color
      expanded @client
    }
    ... on Person {
      id
      name
      uuid
      expanded @client
    }
  }
  customFields {
    ... on CustomDropdownFieldValue {
      id
      type
      value
    }
    ... on CustomConnectionFieldValue {
      id
      type
      values
      nodes {
        ... on Person {
          id
          familyName
          givenName
        }
      }
    }
  }
  image
  name
  givenName
  familyName
  position
  email
  emailAliases
  uuid
  id
  parentUuid
  type
  teamSizeTest
  xLink
  linkedInLink
  facebookLink
  youTubeLink
  instagramLink
  whatsAppLink
  expanded @client
}
    `;
export const DepartmentDataFragmentFragmentDoc = gql`
    fragment DepartmentDataFragment on Department {
  id
  uuid
  capabilities {
    canUpdate
    canMove
    canDelete
    canUnassign
  }
  parentNodes {
    ... on Department {
      id
      name
      uuid
      color
      expanded @client
    }
    ... on Person {
      id
      name
      uuid
      expanded @client
    }
  }
  unassigned
  deleted
  parentUuid
  chartUuid
  name
  color
  description
  type
  childrenCount
  employeeCount
  departmentCount
  expanded @client
}
    `;
export const NodeDataFragmentFragmentDoc = gql`
    fragment NodeDataFragment on Node {
  ...PersonDataFragment
  ...DepartmentDataFragment
}
    ${PersonDataFragmentFragmentDoc}
${DepartmentDataFragmentFragmentDoc}`;
export const PersonSearchDataFragmentFragmentDoc = gql`
    fragment PersonSearchDataFragment on Person {
  chartUuid
  id
  uuid
  type
  email
  image
  name
  position
  unassigned
  capabilities {
    canMove
  }
  parentNodes {
    ... on Department {
      id
      uuid
      expanded @client
    }
    ... on Person {
      id
      uuid
      expanded @client
    }
  }
  expanded @client
}
    `;
export const DepartmentSearchDataFragmentFragmentDoc = gql`
    fragment DepartmentSearchDataFragment on Department {
  chartUuid
  id
  uuid
  type
  unassigned
  parentNodes {
    ... on Department {
      id
      uuid
      name
      color
      expanded @client
    }
    ... on Person {
      id
      uuid
      name
      expanded @client
    }
  }
  name
  color
  capabilities {
    canMove
  }
  expanded @client
}
    `;
export const NodeSearchDataFragmentFragmentDoc = gql`
    fragment NodeSearchDataFragment on Node {
  ...PersonSearchDataFragment
  ...DepartmentSearchDataFragment
}
    ${PersonSearchDataFragmentFragmentDoc}
${DepartmentSearchDataFragmentFragmentDoc}`;
export const PersonChildDepartmentsDataFragmentFragmentDoc = gql`
    fragment PersonChildDepartmentsDataFragment on Person {
  uuid
  id
  childDepartments {
    ... on Department {
      uuid
      id
      name
      color
      parentUuid
      expanded @client
    }
  }
  expanded @client
}
    `;
export const PersonIdentifyFragmentFragmentDoc = gql`
    fragment PersonIdentifyFragment on Person {
  id
  uuid
}
    `;
export const PersonTeamSizeFragmentFragmentDoc = gql`
    fragment PersonTeamSizeFragment on Person {
  id
  uuid
  teamSizeTest
}
    `;
export const PublicLinkFragmentDoc = gql`
    fragment PublicLink on PublicLink {
  id
  uuid
  url
  domains
  ips
  allowedCustomFields
}
    `;
export const TeamMembersFragmentFragmentDoc = gql`
    fragment TeamMembersFragment on Person {
  id
  uuid
  givenName
  familyName
}
    `;
export const TrackEventResultFragmentFragmentDoc = gql`
    fragment TrackEventResultFragment on TrackEventResult {
  success
}
    `;
export const UploadUrlPolicyFragmentFragmentDoc = gql`
    fragment UploadUrlPolicyFragment on UploadUrlPolicy {
  url
  getUrl
  fields {
    key
    value
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  email
  emailVerified
  provider
  subscription {
    ...SubscriptionInfo
  }
}
    ${SubscriptionInfoFragmentDoc}`;
export const AcceptInvitationDocument = gql`
    mutation acceptInvitation($code: String!) {
  acceptInvitation(code: $code) {
    ...CollaboratorFragment
  }
}
    ${CollaboratorFragmentFragmentDoc}`;
export type AcceptInvitationMutationFn = Apollo.MutationFunction<AcceptInvitationMutation, AcceptInvitationMutationVariables>;

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAcceptInvitationMutation(baseOptions?: Apollo.MutationHookOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptInvitationMutation, AcceptInvitationMutationVariables>(AcceptInvitationDocument, options);
      }
export type AcceptInvitationMutationHookResult = ReturnType<typeof useAcceptInvitationMutation>;
export type AcceptInvitationMutationResult = Apollo.MutationResult<AcceptInvitationMutation>;
export type AcceptInvitationMutationOptions = Apollo.BaseMutationOptions<AcceptInvitationMutation, AcceptInvitationMutationVariables>;
export const AddCollaboratorDocument = gql`
    mutation addCollaborator($data: AddCollaboratorInput!, $chartUuid: String!) {
  addCollaborator(addCollaboratorInput: $data, chartUuid: $chartUuid) {
    ...CollaboratorFragment
  }
}
    ${CollaboratorFragmentFragmentDoc}`;
export type AddCollaboratorMutationFn = Apollo.MutationFunction<AddCollaboratorMutation, AddCollaboratorMutationVariables>;

/**
 * __useAddCollaboratorMutation__
 *
 * To run a mutation, you first call `useAddCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCollaboratorMutation, { data, loading, error }] = useAddCollaboratorMutation({
 *   variables: {
 *      data: // value for 'data'
 *      chartUuid: // value for 'chartUuid'
 *   },
 * });
 */
export function useAddCollaboratorMutation(baseOptions?: Apollo.MutationHookOptions<AddCollaboratorMutation, AddCollaboratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCollaboratorMutation, AddCollaboratorMutationVariables>(AddCollaboratorDocument, options);
      }
export type AddCollaboratorMutationHookResult = ReturnType<typeof useAddCollaboratorMutation>;
export type AddCollaboratorMutationResult = Apollo.MutationResult<AddCollaboratorMutation>;
export type AddCollaboratorMutationOptions = Apollo.BaseMutationOptions<AddCollaboratorMutation, AddCollaboratorMutationVariables>;
export const AuthorizeDocument = gql`
    mutation authorize($codeChallenge: String!, $authorizeInput: AuthorizeInput) {
  authorize(
    provider: gsuite
    codeChallenge: $codeChallenge
    authorizeInput: $authorizeInput
  ) {
    url
  }
}
    `;
export type AuthorizeMutationFn = Apollo.MutationFunction<AuthorizeMutation, AuthorizeMutationVariables>;

/**
 * __useAuthorizeMutation__
 *
 * To run a mutation, you first call `useAuthorizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthorizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authorizeMutation, { data, loading, error }] = useAuthorizeMutation({
 *   variables: {
 *      codeChallenge: // value for 'codeChallenge'
 *      authorizeInput: // value for 'authorizeInput'
 *   },
 * });
 */
export function useAuthorizeMutation(baseOptions?: Apollo.MutationHookOptions<AuthorizeMutation, AuthorizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthorizeMutation, AuthorizeMutationVariables>(AuthorizeDocument, options);
      }
export type AuthorizeMutationHookResult = ReturnType<typeof useAuthorizeMutation>;
export type AuthorizeMutationResult = Apollo.MutationResult<AuthorizeMutation>;
export type AuthorizeMutationOptions = Apollo.BaseMutationOptions<AuthorizeMutation, AuthorizeMutationVariables>;
export const CloneChartDocument = gql`
    mutation CloneChart($uuid: String!, $rootUuid: String) {
  cloneChart(uuid: $uuid, rootUuid: $rootUuid) {
    ...ChartFragment
  }
}
    ${ChartFragmentFragmentDoc}`;
export type CloneChartMutationFn = Apollo.MutationFunction<CloneChartMutation, CloneChartMutationVariables>;

/**
 * __useCloneChartMutation__
 *
 * To run a mutation, you first call `useCloneChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneChartMutation, { data, loading, error }] = useCloneChartMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      rootUuid: // value for 'rootUuid'
 *   },
 * });
 */
export function useCloneChartMutation(baseOptions?: Apollo.MutationHookOptions<CloneChartMutation, CloneChartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloneChartMutation, CloneChartMutationVariables>(CloneChartDocument, options);
      }
export type CloneChartMutationHookResult = ReturnType<typeof useCloneChartMutation>;
export type CloneChartMutationResult = Apollo.MutationResult<CloneChartMutation>;
export type CloneChartMutationOptions = Apollo.BaseMutationOptions<CloneChartMutation, CloneChartMutationVariables>;
export const CreateChartFromFileDocument = gql`
    mutation createChartFromFile($data: CreateChartFromFileInput!) {
  createChartFromFile(createChartFromFileInput: $data) {
    ...ChartFragment
  }
}
    ${ChartFragmentFragmentDoc}`;
export type CreateChartFromFileMutationFn = Apollo.MutationFunction<CreateChartFromFileMutation, CreateChartFromFileMutationVariables>;

/**
 * __useCreateChartFromFileMutation__
 *
 * To run a mutation, you first call `useCreateChartFromFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChartFromFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChartFromFileMutation, { data, loading, error }] = useCreateChartFromFileMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateChartFromFileMutation(baseOptions?: Apollo.MutationHookOptions<CreateChartFromFileMutation, CreateChartFromFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChartFromFileMutation, CreateChartFromFileMutationVariables>(CreateChartFromFileDocument, options);
      }
export type CreateChartFromFileMutationHookResult = ReturnType<typeof useCreateChartFromFileMutation>;
export type CreateChartFromFileMutationResult = Apollo.MutationResult<CreateChartFromFileMutation>;
export type CreateChartFromFileMutationOptions = Apollo.BaseMutationOptions<CreateChartFromFileMutation, CreateChartFromFileMutationVariables>;
export const CreateChartFromGoogleWorkspaceDocument = gql`
    mutation createChartFromGoogleWorkspace($data: CreateChartFromGoogleWorkspaceInput!) {
  createChartFromGoogleWorkspace(createChartFromGoogleWorkspaceInput: $data) {
    ...ChartFragment
  }
}
    ${ChartFragmentFragmentDoc}`;
export type CreateChartFromGoogleWorkspaceMutationFn = Apollo.MutationFunction<CreateChartFromGoogleWorkspaceMutation, CreateChartFromGoogleWorkspaceMutationVariables>;

/**
 * __useCreateChartFromGoogleWorkspaceMutation__
 *
 * To run a mutation, you first call `useCreateChartFromGoogleWorkspaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChartFromGoogleWorkspaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChartFromGoogleWorkspaceMutation, { data, loading, error }] = useCreateChartFromGoogleWorkspaceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateChartFromGoogleWorkspaceMutation(baseOptions?: Apollo.MutationHookOptions<CreateChartFromGoogleWorkspaceMutation, CreateChartFromGoogleWorkspaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChartFromGoogleWorkspaceMutation, CreateChartFromGoogleWorkspaceMutationVariables>(CreateChartFromGoogleWorkspaceDocument, options);
      }
export type CreateChartFromGoogleWorkspaceMutationHookResult = ReturnType<typeof useCreateChartFromGoogleWorkspaceMutation>;
export type CreateChartFromGoogleWorkspaceMutationResult = Apollo.MutationResult<CreateChartFromGoogleWorkspaceMutation>;
export type CreateChartFromGoogleWorkspaceMutationOptions = Apollo.BaseMutationOptions<CreateChartFromGoogleWorkspaceMutation, CreateChartFromGoogleWorkspaceMutationVariables>;
export const CreateChartDocument = gql`
    mutation createChart($data: CreateChartInput!) {
  createChart(createChartInput: $data) {
    ...ChartFragment
  }
}
    ${ChartFragmentFragmentDoc}`;
export type CreateChartMutationFn = Apollo.MutationFunction<CreateChartMutation, CreateChartMutationVariables>;

/**
 * __useCreateChartMutation__
 *
 * To run a mutation, you first call `useCreateChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChartMutation, { data, loading, error }] = useCreateChartMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateChartMutation(baseOptions?: Apollo.MutationHookOptions<CreateChartMutation, CreateChartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChartMutation, CreateChartMutationVariables>(CreateChartDocument, options);
      }
export type CreateChartMutationHookResult = ReturnType<typeof useCreateChartMutation>;
export type CreateChartMutationResult = Apollo.MutationResult<CreateChartMutation>;
export type CreateChartMutationOptions = Apollo.BaseMutationOptions<CreateChartMutation, CreateChartMutationVariables>;
export const CreateConnectionFieldDocument = gql`
    mutation createConnectionField($data: CreateCustomConnectionFieldInput!, $chartUuid: String!) {
  createConnectionField(createConnectionFieldInput: $data, chartUuid: $chartUuid) {
    ...CustomConnectionFieldFragment
  }
}
    ${CustomConnectionFieldFragmentFragmentDoc}`;
export type CreateConnectionFieldMutationFn = Apollo.MutationFunction<CreateConnectionFieldMutation, CreateConnectionFieldMutationVariables>;

/**
 * __useCreateConnectionFieldMutation__
 *
 * To run a mutation, you first call `useCreateConnectionFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConnectionFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConnectionFieldMutation, { data, loading, error }] = useCreateConnectionFieldMutation({
 *   variables: {
 *      data: // value for 'data'
 *      chartUuid: // value for 'chartUuid'
 *   },
 * });
 */
export function useCreateConnectionFieldMutation(baseOptions?: Apollo.MutationHookOptions<CreateConnectionFieldMutation, CreateConnectionFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConnectionFieldMutation, CreateConnectionFieldMutationVariables>(CreateConnectionFieldDocument, options);
      }
export type CreateConnectionFieldMutationHookResult = ReturnType<typeof useCreateConnectionFieldMutation>;
export type CreateConnectionFieldMutationResult = Apollo.MutationResult<CreateConnectionFieldMutation>;
export type CreateConnectionFieldMutationOptions = Apollo.BaseMutationOptions<CreateConnectionFieldMutation, CreateConnectionFieldMutationVariables>;
export const CreateDateFieldDocument = gql`
    mutation createDateField($data: CreateCustomDateFieldInput!, $chartUuid: String!) {
  createDateField(createDateFieldInput: $data, chartUuid: $chartUuid) {
    ...CustomDateFieldFragment
  }
}
    ${CustomDateFieldFragmentFragmentDoc}`;
export type CreateDateFieldMutationFn = Apollo.MutationFunction<CreateDateFieldMutation, CreateDateFieldMutationVariables>;

/**
 * __useCreateDateFieldMutation__
 *
 * To run a mutation, you first call `useCreateDateFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDateFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDateFieldMutation, { data, loading, error }] = useCreateDateFieldMutation({
 *   variables: {
 *      data: // value for 'data'
 *      chartUuid: // value for 'chartUuid'
 *   },
 * });
 */
export function useCreateDateFieldMutation(baseOptions?: Apollo.MutationHookOptions<CreateDateFieldMutation, CreateDateFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDateFieldMutation, CreateDateFieldMutationVariables>(CreateDateFieldDocument, options);
      }
export type CreateDateFieldMutationHookResult = ReturnType<typeof useCreateDateFieldMutation>;
export type CreateDateFieldMutationResult = Apollo.MutationResult<CreateDateFieldMutation>;
export type CreateDateFieldMutationOptions = Apollo.BaseMutationOptions<CreateDateFieldMutation, CreateDateFieldMutationVariables>;
export const CreateDepartmentDocument = gql`
    mutation createDepartment($data: CreateDepartmentInput!, $chartUuid: String!) {
  createDepartment(createDepartmentInput: $data, chartUuid: $chartUuid) {
    ...DepartmentDataFragment
  }
}
    ${DepartmentDataFragmentFragmentDoc}`;
export type CreateDepartmentMutationFn = Apollo.MutationFunction<CreateDepartmentMutation, CreateDepartmentMutationVariables>;

/**
 * __useCreateDepartmentMutation__
 *
 * To run a mutation, you first call `useCreateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDepartmentMutation, { data, loading, error }] = useCreateDepartmentMutation({
 *   variables: {
 *      data: // value for 'data'
 *      chartUuid: // value for 'chartUuid'
 *   },
 * });
 */
export function useCreateDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDepartmentMutation, CreateDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDepartmentMutation, CreateDepartmentMutationVariables>(CreateDepartmentDocument, options);
      }
export type CreateDepartmentMutationHookResult = ReturnType<typeof useCreateDepartmentMutation>;
export type CreateDepartmentMutationResult = Apollo.MutationResult<CreateDepartmentMutation>;
export type CreateDepartmentMutationOptions = Apollo.BaseMutationOptions<CreateDepartmentMutation, CreateDepartmentMutationVariables>;
export const CreateDropdownFieldDocument = gql`
    mutation createDropdownField($data: CreateCustomDropdownFieldInput!, $chartUuid: String!) {
  createDropdownField(createDropdownFieldInput: $data, chartUuid: $chartUuid) {
    ...CustomDropdownFieldFragment
  }
}
    ${CustomDropdownFieldFragmentFragmentDoc}`;
export type CreateDropdownFieldMutationFn = Apollo.MutationFunction<CreateDropdownFieldMutation, CreateDropdownFieldMutationVariables>;

/**
 * __useCreateDropdownFieldMutation__
 *
 * To run a mutation, you first call `useCreateDropdownFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDropdownFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDropdownFieldMutation, { data, loading, error }] = useCreateDropdownFieldMutation({
 *   variables: {
 *      data: // value for 'data'
 *      chartUuid: // value for 'chartUuid'
 *   },
 * });
 */
export function useCreateDropdownFieldMutation(baseOptions?: Apollo.MutationHookOptions<CreateDropdownFieldMutation, CreateDropdownFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDropdownFieldMutation, CreateDropdownFieldMutationVariables>(CreateDropdownFieldDocument, options);
      }
export type CreateDropdownFieldMutationHookResult = ReturnType<typeof useCreateDropdownFieldMutation>;
export type CreateDropdownFieldMutationResult = Apollo.MutationResult<CreateDropdownFieldMutation>;
export type CreateDropdownFieldMutationOptions = Apollo.BaseMutationOptions<CreateDropdownFieldMutation, CreateDropdownFieldMutationVariables>;
export const CreatePersonDocument = gql`
    mutation createPerson($data: CreatePersonInput!, $chartUuid: String!) {
  createPerson(createPersonInput: $data, chartUuid: $chartUuid) {
    ...PersonDataFragment
  }
}
    ${PersonDataFragmentFragmentDoc}`;
export type CreatePersonMutationFn = Apollo.MutationFunction<CreatePersonMutation, CreatePersonMutationVariables>;

/**
 * __useCreatePersonMutation__
 *
 * To run a mutation, you first call `useCreatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonMutation, { data, loading, error }] = useCreatePersonMutation({
 *   variables: {
 *      data: // value for 'data'
 *      chartUuid: // value for 'chartUuid'
 *   },
 * });
 */
export function useCreatePersonMutation(baseOptions?: Apollo.MutationHookOptions<CreatePersonMutation, CreatePersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePersonMutation, CreatePersonMutationVariables>(CreatePersonDocument, options);
      }
export type CreatePersonMutationHookResult = ReturnType<typeof useCreatePersonMutation>;
export type CreatePersonMutationResult = Apollo.MutationResult<CreatePersonMutation>;
export type CreatePersonMutationOptions = Apollo.BaseMutationOptions<CreatePersonMutation, CreatePersonMutationVariables>;
export const CreatePublicLinkDocument = gql`
    mutation createPublicLink($chartUuid: String!, $createPublicLinkInput: CreatePublicLinkInput!) {
  createPublicLink(
    chartUuid: $chartUuid
    createPublicLinkInput: $createPublicLinkInput
  ) {
    ...PublicLink
  }
}
    ${PublicLinkFragmentDoc}`;
export type CreatePublicLinkMutationFn = Apollo.MutationFunction<CreatePublicLinkMutation, CreatePublicLinkMutationVariables>;

/**
 * __useCreatePublicLinkMutation__
 *
 * To run a mutation, you first call `useCreatePublicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublicLinkMutation, { data, loading, error }] = useCreatePublicLinkMutation({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *      createPublicLinkInput: // value for 'createPublicLinkInput'
 *   },
 * });
 */
export function useCreatePublicLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreatePublicLinkMutation, CreatePublicLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePublicLinkMutation, CreatePublicLinkMutationVariables>(CreatePublicLinkDocument, options);
      }
export type CreatePublicLinkMutationHookResult = ReturnType<typeof useCreatePublicLinkMutation>;
export type CreatePublicLinkMutationResult = Apollo.MutationResult<CreatePublicLinkMutation>;
export type CreatePublicLinkMutationOptions = Apollo.BaseMutationOptions<CreatePublicLinkMutation, CreatePublicLinkMutationVariables>;
export const CreateStripeCheckoutSessionDocument = gql`
    mutation createStripeCheckoutSession($billingPeriod: String, $plan: String, $coupon: String) {
  createStripeCheckoutSession(
    billingPeriod: $billingPeriod
    plan: $plan
    coupon: $coupon
  ) {
    url
  }
}
    `;
export type CreateStripeCheckoutSessionMutationFn = Apollo.MutationFunction<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>;

/**
 * __useCreateStripeCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeCheckoutSessionMutation, { data, loading, error }] = useCreateStripeCheckoutSessionMutation({
 *   variables: {
 *      billingPeriod: // value for 'billingPeriod'
 *      plan: // value for 'plan'
 *      coupon: // value for 'coupon'
 *   },
 * });
 */
export function useCreateStripeCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>(CreateStripeCheckoutSessionDocument, options);
      }
export type CreateStripeCheckoutSessionMutationHookResult = ReturnType<typeof useCreateStripeCheckoutSessionMutation>;
export type CreateStripeCheckoutSessionMutationResult = Apollo.MutationResult<CreateStripeCheckoutSessionMutation>;
export type CreateStripeCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>;
export const CreateStripePortalSessionDocument = gql`
    mutation createStripePortalSession {
  createStripePortalSession {
    url
  }
}
    `;
export type CreateStripePortalSessionMutationFn = Apollo.MutationFunction<CreateStripePortalSessionMutation, CreateStripePortalSessionMutationVariables>;

/**
 * __useCreateStripePortalSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripePortalSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripePortalSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripePortalSessionMutation, { data, loading, error }] = useCreateStripePortalSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateStripePortalSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripePortalSessionMutation, CreateStripePortalSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripePortalSessionMutation, CreateStripePortalSessionMutationVariables>(CreateStripePortalSessionDocument, options);
      }
export type CreateStripePortalSessionMutationHookResult = ReturnType<typeof useCreateStripePortalSessionMutation>;
export type CreateStripePortalSessionMutationResult = Apollo.MutationResult<CreateStripePortalSessionMutation>;
export type CreateStripePortalSessionMutationOptions = Apollo.BaseMutationOptions<CreateStripePortalSessionMutation, CreateStripePortalSessionMutationVariables>;
export const CreateTextFieldDocument = gql`
    mutation createTextField($data: CreateCustomTextFieldInput!, $chartUuid: String!) {
  createTextField(createTextFieldInput: $data, chartUuid: $chartUuid) {
    ...CustomTextFieldFragment
  }
}
    ${CustomTextFieldFragmentFragmentDoc}`;
export type CreateTextFieldMutationFn = Apollo.MutationFunction<CreateTextFieldMutation, CreateTextFieldMutationVariables>;

/**
 * __useCreateTextFieldMutation__
 *
 * To run a mutation, you first call `useCreateTextFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTextFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTextFieldMutation, { data, loading, error }] = useCreateTextFieldMutation({
 *   variables: {
 *      data: // value for 'data'
 *      chartUuid: // value for 'chartUuid'
 *   },
 * });
 */
export function useCreateTextFieldMutation(baseOptions?: Apollo.MutationHookOptions<CreateTextFieldMutation, CreateTextFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTextFieldMutation, CreateTextFieldMutationVariables>(CreateTextFieldDocument, options);
      }
export type CreateTextFieldMutationHookResult = ReturnType<typeof useCreateTextFieldMutation>;
export type CreateTextFieldMutationResult = Apollo.MutationResult<CreateTextFieldMutation>;
export type CreateTextFieldMutationOptions = Apollo.BaseMutationOptions<CreateTextFieldMutation, CreateTextFieldMutationVariables>;
export const DeleteChartDocument = gql`
    mutation DeleteChart($uuid: String!) {
  deleteChart(uuid: $uuid) {
    ...ChartFragment
  }
}
    ${ChartFragmentFragmentDoc}`;
export type DeleteChartMutationFn = Apollo.MutationFunction<DeleteChartMutation, DeleteChartMutationVariables>;

/**
 * __useDeleteChartMutation__
 *
 * To run a mutation, you first call `useDeleteChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChartMutation, { data, loading, error }] = useDeleteChartMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteChartMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChartMutation, DeleteChartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChartMutation, DeleteChartMutationVariables>(DeleteChartDocument, options);
      }
export type DeleteChartMutationHookResult = ReturnType<typeof useDeleteChartMutation>;
export type DeleteChartMutationResult = Apollo.MutationResult<DeleteChartMutation>;
export type DeleteChartMutationOptions = Apollo.BaseMutationOptions<DeleteChartMutation, DeleteChartMutationVariables>;
export const DeleteCollaboratorDocument = gql`
    mutation deleteCollaborator($chartUuid: String!, $uuid: String!) {
  deleteCollaborator(chartUuid: $chartUuid, uuid: $uuid) {
    ...CollaboratorFragment
  }
}
    ${CollaboratorFragmentFragmentDoc}`;
export type DeleteCollaboratorMutationFn = Apollo.MutationFunction<DeleteCollaboratorMutation, DeleteCollaboratorMutationVariables>;

/**
 * __useDeleteCollaboratorMutation__
 *
 * To run a mutation, you first call `useDeleteCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollaboratorMutation, { data, loading, error }] = useDeleteCollaboratorMutation({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteCollaboratorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCollaboratorMutation, DeleteCollaboratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCollaboratorMutation, DeleteCollaboratorMutationVariables>(DeleteCollaboratorDocument, options);
      }
export type DeleteCollaboratorMutationHookResult = ReturnType<typeof useDeleteCollaboratorMutation>;
export type DeleteCollaboratorMutationResult = Apollo.MutationResult<DeleteCollaboratorMutation>;
export type DeleteCollaboratorMutationOptions = Apollo.BaseMutationOptions<DeleteCollaboratorMutation, DeleteCollaboratorMutationVariables>;
export const DeleteConnectionFieldDocument = gql`
    mutation deleteConnectionField($chartUuid: String!, $uuid: String!) {
  deleteConnectionField(chartUuid: $chartUuid, uuid: $uuid) {
    ...CustomConnectionFieldFragment
  }
}
    ${CustomConnectionFieldFragmentFragmentDoc}`;
export type DeleteConnectionFieldMutationFn = Apollo.MutationFunction<DeleteConnectionFieldMutation, DeleteConnectionFieldMutationVariables>;

/**
 * __useDeleteConnectionFieldMutation__
 *
 * To run a mutation, you first call `useDeleteConnectionFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConnectionFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConnectionFieldMutation, { data, loading, error }] = useDeleteConnectionFieldMutation({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteConnectionFieldMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConnectionFieldMutation, DeleteConnectionFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConnectionFieldMutation, DeleteConnectionFieldMutationVariables>(DeleteConnectionFieldDocument, options);
      }
export type DeleteConnectionFieldMutationHookResult = ReturnType<typeof useDeleteConnectionFieldMutation>;
export type DeleteConnectionFieldMutationResult = Apollo.MutationResult<DeleteConnectionFieldMutation>;
export type DeleteConnectionFieldMutationOptions = Apollo.BaseMutationOptions<DeleteConnectionFieldMutation, DeleteConnectionFieldMutationVariables>;
export const DeleteDateFieldDocument = gql`
    mutation deleteDateField($chartUuid: String!, $uuid: String!) {
  deleteDateField(chartUuid: $chartUuid, uuid: $uuid) {
    ...CustomDateFieldFragment
  }
}
    ${CustomDateFieldFragmentFragmentDoc}`;
export type DeleteDateFieldMutationFn = Apollo.MutationFunction<DeleteDateFieldMutation, DeleteDateFieldMutationVariables>;

/**
 * __useDeleteDateFieldMutation__
 *
 * To run a mutation, you first call `useDeleteDateFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDateFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDateFieldMutation, { data, loading, error }] = useDeleteDateFieldMutation({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteDateFieldMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDateFieldMutation, DeleteDateFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDateFieldMutation, DeleteDateFieldMutationVariables>(DeleteDateFieldDocument, options);
      }
export type DeleteDateFieldMutationHookResult = ReturnType<typeof useDeleteDateFieldMutation>;
export type DeleteDateFieldMutationResult = Apollo.MutationResult<DeleteDateFieldMutation>;
export type DeleteDateFieldMutationOptions = Apollo.BaseMutationOptions<DeleteDateFieldMutation, DeleteDateFieldMutationVariables>;
export const DeleteDropdownFieldDocument = gql`
    mutation deleteDropdownField($chartUuid: String!, $uuid: String!) {
  deleteDropdownField(chartUuid: $chartUuid, uuid: $uuid) {
    ...CustomDropdownFieldFragment
  }
}
    ${CustomDropdownFieldFragmentFragmentDoc}`;
export type DeleteDropdownFieldMutationFn = Apollo.MutationFunction<DeleteDropdownFieldMutation, DeleteDropdownFieldMutationVariables>;

/**
 * __useDeleteDropdownFieldMutation__
 *
 * To run a mutation, you first call `useDeleteDropdownFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDropdownFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDropdownFieldMutation, { data, loading, error }] = useDeleteDropdownFieldMutation({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteDropdownFieldMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDropdownFieldMutation, DeleteDropdownFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDropdownFieldMutation, DeleteDropdownFieldMutationVariables>(DeleteDropdownFieldDocument, options);
      }
export type DeleteDropdownFieldMutationHookResult = ReturnType<typeof useDeleteDropdownFieldMutation>;
export type DeleteDropdownFieldMutationResult = Apollo.MutationResult<DeleteDropdownFieldMutation>;
export type DeleteDropdownFieldMutationOptions = Apollo.BaseMutationOptions<DeleteDropdownFieldMutation, DeleteDropdownFieldMutationVariables>;
export const DeleteNodeDocument = gql`
    mutation deleteNode($chartUuid: String!, $uuid: String!) {
  deleteNode(chartUuid: $chartUuid, uuid: $uuid) {
    ...NodeDataFragment
  }
}
    ${NodeDataFragmentFragmentDoc}`;
export type DeleteNodeMutationFn = Apollo.MutationFunction<DeleteNodeMutation, DeleteNodeMutationVariables>;

/**
 * __useDeleteNodeMutation__
 *
 * To run a mutation, you first call `useDeleteNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNodeMutation, { data, loading, error }] = useDeleteNodeMutation({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteNodeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNodeMutation, DeleteNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNodeMutation, DeleteNodeMutationVariables>(DeleteNodeDocument, options);
      }
export type DeleteNodeMutationHookResult = ReturnType<typeof useDeleteNodeMutation>;
export type DeleteNodeMutationResult = Apollo.MutationResult<DeleteNodeMutation>;
export type DeleteNodeMutationOptions = Apollo.BaseMutationOptions<DeleteNodeMutation, DeleteNodeMutationVariables>;
export const DeletePublicLinkDocument = gql`
    mutation deletePublicLink($chartUuid: String!, $uuid: String!) {
  deletePublicLink(chartUuid: $chartUuid, uuid: $uuid) {
    ...PublicLink
  }
}
    ${PublicLinkFragmentDoc}`;
export type DeletePublicLinkMutationFn = Apollo.MutationFunction<DeletePublicLinkMutation, DeletePublicLinkMutationVariables>;

/**
 * __useDeletePublicLinkMutation__
 *
 * To run a mutation, you first call `useDeletePublicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePublicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePublicLinkMutation, { data, loading, error }] = useDeletePublicLinkMutation({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeletePublicLinkMutation(baseOptions?: Apollo.MutationHookOptions<DeletePublicLinkMutation, DeletePublicLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePublicLinkMutation, DeletePublicLinkMutationVariables>(DeletePublicLinkDocument, options);
      }
export type DeletePublicLinkMutationHookResult = ReturnType<typeof useDeletePublicLinkMutation>;
export type DeletePublicLinkMutationResult = Apollo.MutationResult<DeletePublicLinkMutation>;
export type DeletePublicLinkMutationOptions = Apollo.BaseMutationOptions<DeletePublicLinkMutation, DeletePublicLinkMutationVariables>;
export const DeleteTextFieldDocument = gql`
    mutation deleteTextField($chartUuid: String!, $uuid: String!) {
  deleteTextField(chartUuid: $chartUuid, uuid: $uuid) {
    ...CustomTextFieldFragment
  }
}
    ${CustomTextFieldFragmentFragmentDoc}`;
export type DeleteTextFieldMutationFn = Apollo.MutationFunction<DeleteTextFieldMutation, DeleteTextFieldMutationVariables>;

/**
 * __useDeleteTextFieldMutation__
 *
 * To run a mutation, you first call `useDeleteTextFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTextFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTextFieldMutation, { data, loading, error }] = useDeleteTextFieldMutation({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteTextFieldMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTextFieldMutation, DeleteTextFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTextFieldMutation, DeleteTextFieldMutationVariables>(DeleteTextFieldDocument, options);
      }
export type DeleteTextFieldMutationHookResult = ReturnType<typeof useDeleteTextFieldMutation>;
export type DeleteTextFieldMutationResult = Apollo.MutationResult<DeleteTextFieldMutation>;
export type DeleteTextFieldMutationOptions = Apollo.BaseMutationOptions<DeleteTextFieldMutation, DeleteTextFieldMutationVariables>;
export const GenerateAvatarUploadUrlPolicyDocument = gql`
    mutation generateAvatarUploadUrlPolicy($chartUuid: String!, $contentType: String!) {
  generateAvatarUploadUrlPolicy(chartUuid: $chartUuid, contentType: $contentType) {
    ...UploadUrlPolicyFragment
  }
}
    ${UploadUrlPolicyFragmentFragmentDoc}`;
export type GenerateAvatarUploadUrlPolicyMutationFn = Apollo.MutationFunction<GenerateAvatarUploadUrlPolicyMutation, GenerateAvatarUploadUrlPolicyMutationVariables>;

/**
 * __useGenerateAvatarUploadUrlPolicyMutation__
 *
 * To run a mutation, you first call `useGenerateAvatarUploadUrlPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateAvatarUploadUrlPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateAvatarUploadUrlPolicyMutation, { data, loading, error }] = useGenerateAvatarUploadUrlPolicyMutation({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useGenerateAvatarUploadUrlPolicyMutation(baseOptions?: Apollo.MutationHookOptions<GenerateAvatarUploadUrlPolicyMutation, GenerateAvatarUploadUrlPolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateAvatarUploadUrlPolicyMutation, GenerateAvatarUploadUrlPolicyMutationVariables>(GenerateAvatarUploadUrlPolicyDocument, options);
      }
export type GenerateAvatarUploadUrlPolicyMutationHookResult = ReturnType<typeof useGenerateAvatarUploadUrlPolicyMutation>;
export type GenerateAvatarUploadUrlPolicyMutationResult = Apollo.MutationResult<GenerateAvatarUploadUrlPolicyMutation>;
export type GenerateAvatarUploadUrlPolicyMutationOptions = Apollo.BaseMutationOptions<GenerateAvatarUploadUrlPolicyMutation, GenerateAvatarUploadUrlPolicyMutationVariables>;
export const GenerateLogoUploadUrlPolicyDocument = gql`
    mutation generateLogoUploadUrlPolicy($chartUuid: String!, $contentType: String!) {
  generateLogoUploadUrlPolicy(chartUuid: $chartUuid, contentType: $contentType) {
    ...UploadUrlPolicyFragment
  }
}
    ${UploadUrlPolicyFragmentFragmentDoc}`;
export type GenerateLogoUploadUrlPolicyMutationFn = Apollo.MutationFunction<GenerateLogoUploadUrlPolicyMutation, GenerateLogoUploadUrlPolicyMutationVariables>;

/**
 * __useGenerateLogoUploadUrlPolicyMutation__
 *
 * To run a mutation, you first call `useGenerateLogoUploadUrlPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateLogoUploadUrlPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateLogoUploadUrlPolicyMutation, { data, loading, error }] = useGenerateLogoUploadUrlPolicyMutation({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *      contentType: // value for 'contentType'
 *   },
 * });
 */
export function useGenerateLogoUploadUrlPolicyMutation(baseOptions?: Apollo.MutationHookOptions<GenerateLogoUploadUrlPolicyMutation, GenerateLogoUploadUrlPolicyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateLogoUploadUrlPolicyMutation, GenerateLogoUploadUrlPolicyMutationVariables>(GenerateLogoUploadUrlPolicyDocument, options);
      }
export type GenerateLogoUploadUrlPolicyMutationHookResult = ReturnType<typeof useGenerateLogoUploadUrlPolicyMutation>;
export type GenerateLogoUploadUrlPolicyMutationResult = Apollo.MutationResult<GenerateLogoUploadUrlPolicyMutation>;
export type GenerateLogoUploadUrlPolicyMutationOptions = Apollo.BaseMutationOptions<GenerateLogoUploadUrlPolicyMutation, GenerateLogoUploadUrlPolicyMutationVariables>;
export const MakeChartEditableDocument = gql`
    mutation makeChartEditable($uuid: String!) {
  makeChartEditable(uuid: $uuid) {
    ...ChartFragment
  }
}
    ${ChartFragmentFragmentDoc}`;
export type MakeChartEditableMutationFn = Apollo.MutationFunction<MakeChartEditableMutation, MakeChartEditableMutationVariables>;

/**
 * __useMakeChartEditableMutation__
 *
 * To run a mutation, you first call `useMakeChartEditableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeChartEditableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeChartEditableMutation, { data, loading, error }] = useMakeChartEditableMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useMakeChartEditableMutation(baseOptions?: Apollo.MutationHookOptions<MakeChartEditableMutation, MakeChartEditableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeChartEditableMutation, MakeChartEditableMutationVariables>(MakeChartEditableDocument, options);
      }
export type MakeChartEditableMutationHookResult = ReturnType<typeof useMakeChartEditableMutation>;
export type MakeChartEditableMutationResult = Apollo.MutationResult<MakeChartEditableMutation>;
export type MakeChartEditableMutationOptions = Apollo.BaseMutationOptions<MakeChartEditableMutation, MakeChartEditableMutationVariables>;
export const MoveNodeDocument = gql`
    mutation moveNode($chartUuid: String!, $uuid: String!, $targetUuid: String) {
  moveNode(chartUuid: $chartUuid, uuid: $uuid, targetUuid: $targetUuid) {
    ...NodeDataFragment
  }
}
    ${NodeDataFragmentFragmentDoc}`;
export type MoveNodeMutationFn = Apollo.MutationFunction<MoveNodeMutation, MoveNodeMutationVariables>;

/**
 * __useMoveNodeMutation__
 *
 * To run a mutation, you first call `useMoveNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveNodeMutation, { data, loading, error }] = useMoveNodeMutation({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *      uuid: // value for 'uuid'
 *      targetUuid: // value for 'targetUuid'
 *   },
 * });
 */
export function useMoveNodeMutation(baseOptions?: Apollo.MutationHookOptions<MoveNodeMutation, MoveNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveNodeMutation, MoveNodeMutationVariables>(MoveNodeDocument, options);
      }
export type MoveNodeMutationHookResult = ReturnType<typeof useMoveNodeMutation>;
export type MoveNodeMutationResult = Apollo.MutationResult<MoveNodeMutation>;
export type MoveNodeMutationOptions = Apollo.BaseMutationOptions<MoveNodeMutation, MoveNodeMutationVariables>;
export const ResendInvitationDocument = gql`
    mutation resendInvitation($code: String!) {
  resendInvitation(code: $code) {
    ...CollaboratorFragment
  }
}
    ${CollaboratorFragmentFragmentDoc}`;
export type ResendInvitationMutationFn = Apollo.MutationFunction<ResendInvitationMutation, ResendInvitationMutationVariables>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useResendInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ResendInvitationMutation, ResendInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(ResendInvitationDocument, options);
      }
export type ResendInvitationMutationHookResult = ReturnType<typeof useResendInvitationMutation>;
export type ResendInvitationMutationResult = Apollo.MutationResult<ResendInvitationMutation>;
export type ResendInvitationMutationOptions = Apollo.BaseMutationOptions<ResendInvitationMutation, ResendInvitationMutationVariables>;
export const RevokeChartTokenDocument = gql`
    mutation RevokeChartToken($uuid: String!) {
  revokeChartToken(uuid: $uuid) {
    ...ChartFragment
  }
}
    ${ChartFragmentFragmentDoc}`;
export type RevokeChartTokenMutationFn = Apollo.MutationFunction<RevokeChartTokenMutation, RevokeChartTokenMutationVariables>;

/**
 * __useRevokeChartTokenMutation__
 *
 * To run a mutation, you first call `useRevokeChartTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeChartTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeChartTokenMutation, { data, loading, error }] = useRevokeChartTokenMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRevokeChartTokenMutation(baseOptions?: Apollo.MutationHookOptions<RevokeChartTokenMutation, RevokeChartTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeChartTokenMutation, RevokeChartTokenMutationVariables>(RevokeChartTokenDocument, options);
      }
export type RevokeChartTokenMutationHookResult = ReturnType<typeof useRevokeChartTokenMutation>;
export type RevokeChartTokenMutationResult = Apollo.MutationResult<RevokeChartTokenMutation>;
export type RevokeChartTokenMutationOptions = Apollo.BaseMutationOptions<RevokeChartTokenMutation, RevokeChartTokenMutationVariables>;
export const SendVerificationEmailDocument = gql`
    mutation sendVerificationEmail {
  sendVerificationEmail
}
    `;
export type SendVerificationEmailMutationFn = Apollo.MutationFunction<SendVerificationEmailMutation, SendVerificationEmailMutationVariables>;

/**
 * __useSendVerificationEmailMutation__
 *
 * To run a mutation, you first call `useSendVerificationEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendVerificationEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendVerificationEmailMutation, { data, loading, error }] = useSendVerificationEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendVerificationEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendVerificationEmailMutation, SendVerificationEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendVerificationEmailMutation, SendVerificationEmailMutationVariables>(SendVerificationEmailDocument, options);
      }
export type SendVerificationEmailMutationHookResult = ReturnType<typeof useSendVerificationEmailMutation>;
export type SendVerificationEmailMutationResult = Apollo.MutationResult<SendVerificationEmailMutation>;
export type SendVerificationEmailMutationOptions = Apollo.BaseMutationOptions<SendVerificationEmailMutation, SendVerificationEmailMutationVariables>;
export const SetShowUnassignedNodesSidebarDocument = gql`
    mutation setShowUnassignedNodesSidebar($showUnassignedNodesSidebar: Boolean!) {
  setShowUnassignedNodesSidebar(
    showUnassignedNodesSidebar: $showUnassignedNodesSidebar
  ) @client
}
    `;
export type SetShowUnassignedNodesSidebarMutationFn = Apollo.MutationFunction<SetShowUnassignedNodesSidebarMutation, SetShowUnassignedNodesSidebarMutationVariables>;

/**
 * __useSetShowUnassignedNodesSidebarMutation__
 *
 * To run a mutation, you first call `useSetShowUnassignedNodesSidebarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetShowUnassignedNodesSidebarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setShowUnassignedNodesSidebarMutation, { data, loading, error }] = useSetShowUnassignedNodesSidebarMutation({
 *   variables: {
 *      showUnassignedNodesSidebar: // value for 'showUnassignedNodesSidebar'
 *   },
 * });
 */
export function useSetShowUnassignedNodesSidebarMutation(baseOptions?: Apollo.MutationHookOptions<SetShowUnassignedNodesSidebarMutation, SetShowUnassignedNodesSidebarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetShowUnassignedNodesSidebarMutation, SetShowUnassignedNodesSidebarMutationVariables>(SetShowUnassignedNodesSidebarDocument, options);
      }
export type SetShowUnassignedNodesSidebarMutationHookResult = ReturnType<typeof useSetShowUnassignedNodesSidebarMutation>;
export type SetShowUnassignedNodesSidebarMutationResult = Apollo.MutationResult<SetShowUnassignedNodesSidebarMutation>;
export type SetShowUnassignedNodesSidebarMutationOptions = Apollo.BaseMutationOptions<SetShowUnassignedNodesSidebarMutation, SetShowUnassignedNodesSidebarMutationVariables>;
export const SyncChartWithFileDocument = gql`
    mutation syncChartWithFile($uuid: String!, $data: SyncChartWithFileInput!) {
  syncChartWithFile(uuid: $uuid, syncChartWithFileInput: $data) {
    id
  }
}
    `;
export type SyncChartWithFileMutationFn = Apollo.MutationFunction<SyncChartWithFileMutation, SyncChartWithFileMutationVariables>;

/**
 * __useSyncChartWithFileMutation__
 *
 * To run a mutation, you first call `useSyncChartWithFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncChartWithFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncChartWithFileMutation, { data, loading, error }] = useSyncChartWithFileMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSyncChartWithFileMutation(baseOptions?: Apollo.MutationHookOptions<SyncChartWithFileMutation, SyncChartWithFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncChartWithFileMutation, SyncChartWithFileMutationVariables>(SyncChartWithFileDocument, options);
      }
export type SyncChartWithFileMutationHookResult = ReturnType<typeof useSyncChartWithFileMutation>;
export type SyncChartWithFileMutationResult = Apollo.MutationResult<SyncChartWithFileMutation>;
export type SyncChartWithFileMutationOptions = Apollo.BaseMutationOptions<SyncChartWithFileMutation, SyncChartWithFileMutationVariables>;
export const SyncChartDocument = gql`
    mutation SyncChart($uuid: String!) {
  syncChart(uuid: $uuid) {
    ...ChartFragment
  }
}
    ${ChartFragmentFragmentDoc}`;
export type SyncChartMutationFn = Apollo.MutationFunction<SyncChartMutation, SyncChartMutationVariables>;

/**
 * __useSyncChartMutation__
 *
 * To run a mutation, you first call `useSyncChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncChartMutation, { data, loading, error }] = useSyncChartMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useSyncChartMutation(baseOptions?: Apollo.MutationHookOptions<SyncChartMutation, SyncChartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncChartMutation, SyncChartMutationVariables>(SyncChartDocument, options);
      }
export type SyncChartMutationHookResult = ReturnType<typeof useSyncChartMutation>;
export type SyncChartMutationResult = Apollo.MutationResult<SyncChartMutation>;
export type SyncChartMutationOptions = Apollo.BaseMutationOptions<SyncChartMutation, SyncChartMutationVariables>;
export const ToggleSubtreeDocument = gql`
    mutation toggleSubtree($id: String!, $expanded: Boolean!) {
  toggleSubtree(id: $id, expanded: $expanded) @client {
    ... on Person {
      id
      expanded @client
    }
    ... on Department {
      id
      expanded @client
    }
  }
}
    `;
export type ToggleSubtreeMutationFn = Apollo.MutationFunction<ToggleSubtreeMutation, ToggleSubtreeMutationVariables>;

/**
 * __useToggleSubtreeMutation__
 *
 * To run a mutation, you first call `useToggleSubtreeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleSubtreeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleSubtreeMutation, { data, loading, error }] = useToggleSubtreeMutation({
 *   variables: {
 *      id: // value for 'id'
 *      expanded: // value for 'expanded'
 *   },
 * });
 */
export function useToggleSubtreeMutation(baseOptions?: Apollo.MutationHookOptions<ToggleSubtreeMutation, ToggleSubtreeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleSubtreeMutation, ToggleSubtreeMutationVariables>(ToggleSubtreeDocument, options);
      }
export type ToggleSubtreeMutationHookResult = ReturnType<typeof useToggleSubtreeMutation>;
export type ToggleSubtreeMutationResult = Apollo.MutationResult<ToggleSubtreeMutation>;
export type ToggleSubtreeMutationOptions = Apollo.BaseMutationOptions<ToggleSubtreeMutation, ToggleSubtreeMutationVariables>;
export const TrackEventDocument = gql`
    mutation trackEvent($trackEventInput: TrackEventInput!) {
  trackEvent(trackEventInput: $trackEventInput) {
    ...TrackEventResultFragment
  }
}
    ${TrackEventResultFragmentFragmentDoc}`;
export type TrackEventMutationFn = Apollo.MutationFunction<TrackEventMutation, TrackEventMutationVariables>;

/**
 * __useTrackEventMutation__
 *
 * To run a mutation, you first call `useTrackEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTrackEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [trackEventMutation, { data, loading, error }] = useTrackEventMutation({
 *   variables: {
 *      trackEventInput: // value for 'trackEventInput'
 *   },
 * });
 */
export function useTrackEventMutation(baseOptions?: Apollo.MutationHookOptions<TrackEventMutation, TrackEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TrackEventMutation, TrackEventMutationVariables>(TrackEventDocument, options);
      }
export type TrackEventMutationHookResult = ReturnType<typeof useTrackEventMutation>;
export type TrackEventMutationResult = Apollo.MutationResult<TrackEventMutation>;
export type TrackEventMutationOptions = Apollo.BaseMutationOptions<TrackEventMutation, TrackEventMutationVariables>;
export const UnassignNodeDocument = gql`
    mutation unassignNode($chartUuid: String!, $uuid: String!) {
  unassignNode(chartUuid: $chartUuid, uuid: $uuid) {
    ...NodeDataFragment
  }
}
    ${NodeDataFragmentFragmentDoc}`;
export type UnassignNodeMutationFn = Apollo.MutationFunction<UnassignNodeMutation, UnassignNodeMutationVariables>;

/**
 * __useUnassignNodeMutation__
 *
 * To run a mutation, you first call `useUnassignNodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignNodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignNodeMutation, { data, loading, error }] = useUnassignNodeMutation({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUnassignNodeMutation(baseOptions?: Apollo.MutationHookOptions<UnassignNodeMutation, UnassignNodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnassignNodeMutation, UnassignNodeMutationVariables>(UnassignNodeDocument, options);
      }
export type UnassignNodeMutationHookResult = ReturnType<typeof useUnassignNodeMutation>;
export type UnassignNodeMutationResult = Apollo.MutationResult<UnassignNodeMutation>;
export type UnassignNodeMutationOptions = Apollo.BaseMutationOptions<UnassignNodeMutation, UnassignNodeMutationVariables>;
export const UpdateChartTokenDocument = gql`
    mutation UpdateChartToken($uuid: String!, $data: UpdateChartTokenInput!) {
  updateChartToken(uuid: $uuid, updateChartTokenInput: $data) {
    ...ChartFragment
  }
}
    ${ChartFragmentFragmentDoc}`;
export type UpdateChartTokenMutationFn = Apollo.MutationFunction<UpdateChartTokenMutation, UpdateChartTokenMutationVariables>;

/**
 * __useUpdateChartTokenMutation__
 *
 * To run a mutation, you first call `useUpdateChartTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChartTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChartTokenMutation, { data, loading, error }] = useUpdateChartTokenMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateChartTokenMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChartTokenMutation, UpdateChartTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChartTokenMutation, UpdateChartTokenMutationVariables>(UpdateChartTokenDocument, options);
      }
export type UpdateChartTokenMutationHookResult = ReturnType<typeof useUpdateChartTokenMutation>;
export type UpdateChartTokenMutationResult = Apollo.MutationResult<UpdateChartTokenMutation>;
export type UpdateChartTokenMutationOptions = Apollo.BaseMutationOptions<UpdateChartTokenMutation, UpdateChartTokenMutationVariables>;
export const UpdateChartDocument = gql`
    mutation UpdateChart($uuid: String!, $data: UpdateChartInput!) {
  updateChart(uuid: $uuid, updateChartInput: $data) {
    ...ChartFragment
  }
}
    ${ChartFragmentFragmentDoc}`;
export type UpdateChartMutationFn = Apollo.MutationFunction<UpdateChartMutation, UpdateChartMutationVariables>;

/**
 * __useUpdateChartMutation__
 *
 * To run a mutation, you first call `useUpdateChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChartMutation, { data, loading, error }] = useUpdateChartMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateChartMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChartMutation, UpdateChartMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChartMutation, UpdateChartMutationVariables>(UpdateChartDocument, options);
      }
export type UpdateChartMutationHookResult = ReturnType<typeof useUpdateChartMutation>;
export type UpdateChartMutationResult = Apollo.MutationResult<UpdateChartMutation>;
export type UpdateChartMutationOptions = Apollo.BaseMutationOptions<UpdateChartMutation, UpdateChartMutationVariables>;
export const UpdateCollaboratorDocument = gql`
    mutation updateCollaborator($data: UpdateCollaboratorInput!, $uuid: String!, $chartUuid: String!) {
  updateCollaborator(
    updateCollaboratorInput: $data
    uuid: $uuid
    chartUuid: $chartUuid
  ) {
    ...CollaboratorFragment
  }
}
    ${CollaboratorFragmentFragmentDoc}`;
export type UpdateCollaboratorMutationFn = Apollo.MutationFunction<UpdateCollaboratorMutation, UpdateCollaboratorMutationVariables>;

/**
 * __useUpdateCollaboratorMutation__
 *
 * To run a mutation, you first call `useUpdateCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCollaboratorMutation, { data, loading, error }] = useUpdateCollaboratorMutation({
 *   variables: {
 *      data: // value for 'data'
 *      uuid: // value for 'uuid'
 *      chartUuid: // value for 'chartUuid'
 *   },
 * });
 */
export function useUpdateCollaboratorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCollaboratorMutation, UpdateCollaboratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCollaboratorMutation, UpdateCollaboratorMutationVariables>(UpdateCollaboratorDocument, options);
      }
export type UpdateCollaboratorMutationHookResult = ReturnType<typeof useUpdateCollaboratorMutation>;
export type UpdateCollaboratorMutationResult = Apollo.MutationResult<UpdateCollaboratorMutation>;
export type UpdateCollaboratorMutationOptions = Apollo.BaseMutationOptions<UpdateCollaboratorMutation, UpdateCollaboratorMutationVariables>;
export const UpdateConnectionFieldDocument = gql`
    mutation updateConnectionField($data: UpdateCustomConnectionFieldInput!, $chartUuid: String!, $uuid: String!) {
  updateConnectionField(
    updateConnectionFieldInput: $data
    chartUuid: $chartUuid
    uuid: $uuid
  ) {
    ...CustomConnectionFieldFragment
  }
}
    ${CustomConnectionFieldFragmentFragmentDoc}`;
export type UpdateConnectionFieldMutationFn = Apollo.MutationFunction<UpdateConnectionFieldMutation, UpdateConnectionFieldMutationVariables>;

/**
 * __useUpdateConnectionFieldMutation__
 *
 * To run a mutation, you first call `useUpdateConnectionFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConnectionFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConnectionFieldMutation, { data, loading, error }] = useUpdateConnectionFieldMutation({
 *   variables: {
 *      data: // value for 'data'
 *      chartUuid: // value for 'chartUuid'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUpdateConnectionFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConnectionFieldMutation, UpdateConnectionFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConnectionFieldMutation, UpdateConnectionFieldMutationVariables>(UpdateConnectionFieldDocument, options);
      }
export type UpdateConnectionFieldMutationHookResult = ReturnType<typeof useUpdateConnectionFieldMutation>;
export type UpdateConnectionFieldMutationResult = Apollo.MutationResult<UpdateConnectionFieldMutation>;
export type UpdateConnectionFieldMutationOptions = Apollo.BaseMutationOptions<UpdateConnectionFieldMutation, UpdateConnectionFieldMutationVariables>;
export const UpdateDateFieldDocument = gql`
    mutation updateDateField($data: UpdateCustomDateFieldInput!, $chartUuid: String!, $uuid: String!) {
  updateDateField(updateDateFieldInput: $data, chartUuid: $chartUuid, uuid: $uuid) {
    ...CustomDateFieldFragment
  }
}
    ${CustomDateFieldFragmentFragmentDoc}`;
export type UpdateDateFieldMutationFn = Apollo.MutationFunction<UpdateDateFieldMutation, UpdateDateFieldMutationVariables>;

/**
 * __useUpdateDateFieldMutation__
 *
 * To run a mutation, you first call `useUpdateDateFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDateFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDateFieldMutation, { data, loading, error }] = useUpdateDateFieldMutation({
 *   variables: {
 *      data: // value for 'data'
 *      chartUuid: // value for 'chartUuid'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUpdateDateFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDateFieldMutation, UpdateDateFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDateFieldMutation, UpdateDateFieldMutationVariables>(UpdateDateFieldDocument, options);
      }
export type UpdateDateFieldMutationHookResult = ReturnType<typeof useUpdateDateFieldMutation>;
export type UpdateDateFieldMutationResult = Apollo.MutationResult<UpdateDateFieldMutation>;
export type UpdateDateFieldMutationOptions = Apollo.BaseMutationOptions<UpdateDateFieldMutation, UpdateDateFieldMutationVariables>;
export const UpdateDepartmentDocument = gql`
    mutation updateDepartment($chartUuid: String!, $uuid: String!, $data: UpdateDepartmentInput!) {
  updateDepartment(
    updateDepartmentInput: $data
    uuid: $uuid
    chartUuid: $chartUuid
  ) {
    ...DepartmentDataFragment
  }
}
    ${DepartmentDataFragmentFragmentDoc}`;
export type UpdateDepartmentMutationFn = Apollo.MutationFunction<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>;

/**
 * __useUpdateDepartmentMutation__
 *
 * To run a mutation, you first call `useUpdateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepartmentMutation, { data, loading, error }] = useUpdateDepartmentMutation({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *      uuid: // value for 'uuid'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDepartmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>(UpdateDepartmentDocument, options);
      }
export type UpdateDepartmentMutationHookResult = ReturnType<typeof useUpdateDepartmentMutation>;
export type UpdateDepartmentMutationResult = Apollo.MutationResult<UpdateDepartmentMutation>;
export type UpdateDepartmentMutationOptions = Apollo.BaseMutationOptions<UpdateDepartmentMutation, UpdateDepartmentMutationVariables>;
export const UpdateDropdownFieldDocument = gql`
    mutation updateDropdownField($data: UpdateCustomDropdownFieldInput!, $chartUuid: String!, $uuid: String!) {
  updateDropdownField(
    updateDropdownFieldInput: $data
    chartUuid: $chartUuid
    uuid: $uuid
  ) {
    ...CustomDropdownFieldFragment
  }
}
    ${CustomDropdownFieldFragmentFragmentDoc}`;
export type UpdateDropdownFieldMutationFn = Apollo.MutationFunction<UpdateDropdownFieldMutation, UpdateDropdownFieldMutationVariables>;

/**
 * __useUpdateDropdownFieldMutation__
 *
 * To run a mutation, you first call `useUpdateDropdownFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDropdownFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDropdownFieldMutation, { data, loading, error }] = useUpdateDropdownFieldMutation({
 *   variables: {
 *      data: // value for 'data'
 *      chartUuid: // value for 'chartUuid'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUpdateDropdownFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDropdownFieldMutation, UpdateDropdownFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDropdownFieldMutation, UpdateDropdownFieldMutationVariables>(UpdateDropdownFieldDocument, options);
      }
export type UpdateDropdownFieldMutationHookResult = ReturnType<typeof useUpdateDropdownFieldMutation>;
export type UpdateDropdownFieldMutationResult = Apollo.MutationResult<UpdateDropdownFieldMutation>;
export type UpdateDropdownFieldMutationOptions = Apollo.BaseMutationOptions<UpdateDropdownFieldMutation, UpdateDropdownFieldMutationVariables>;
export const UpdatePersonDocument = gql`
    mutation updatePerson($chartUuid: String!, $uuid: String!, $data: UpdatePersonInput!) {
  updatePerson(updatePersonInput: $data, uuid: $uuid, chartUuid: $chartUuid) {
    ...PersonDetailDataFragment
  }
}
    ${PersonDetailDataFragmentFragmentDoc}`;
export type UpdatePersonMutationFn = Apollo.MutationFunction<UpdatePersonMutation, UpdatePersonMutationVariables>;

/**
 * __useUpdatePersonMutation__
 *
 * To run a mutation, you first call `useUpdatePersonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePersonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePersonMutation, { data, loading, error }] = useUpdatePersonMutation({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *      uuid: // value for 'uuid'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePersonMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePersonMutation, UpdatePersonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePersonMutation, UpdatePersonMutationVariables>(UpdatePersonDocument, options);
      }
export type UpdatePersonMutationHookResult = ReturnType<typeof useUpdatePersonMutation>;
export type UpdatePersonMutationResult = Apollo.MutationResult<UpdatePersonMutation>;
export type UpdatePersonMutationOptions = Apollo.BaseMutationOptions<UpdatePersonMutation, UpdatePersonMutationVariables>;
export const UpdatePublicLinkDocument = gql`
    mutation updatePublicLink($chartUuid: String!, $uuid: String!, $updatePublicLinkInput: UpdatePublicLinkInput!) {
  updatePublicLink(
    chartUuid: $chartUuid
    uuid: $uuid
    updatePublicLinkInput: $updatePublicLinkInput
  ) {
    ...PublicLink
  }
}
    ${PublicLinkFragmentDoc}`;
export type UpdatePublicLinkMutationFn = Apollo.MutationFunction<UpdatePublicLinkMutation, UpdatePublicLinkMutationVariables>;

/**
 * __useUpdatePublicLinkMutation__
 *
 * To run a mutation, you first call `useUpdatePublicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePublicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePublicLinkMutation, { data, loading, error }] = useUpdatePublicLinkMutation({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *      uuid: // value for 'uuid'
 *      updatePublicLinkInput: // value for 'updatePublicLinkInput'
 *   },
 * });
 */
export function useUpdatePublicLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublicLinkMutation, UpdatePublicLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePublicLinkMutation, UpdatePublicLinkMutationVariables>(UpdatePublicLinkDocument, options);
      }
export type UpdatePublicLinkMutationHookResult = ReturnType<typeof useUpdatePublicLinkMutation>;
export type UpdatePublicLinkMutationResult = Apollo.MutationResult<UpdatePublicLinkMutation>;
export type UpdatePublicLinkMutationOptions = Apollo.BaseMutationOptions<UpdatePublicLinkMutation, UpdatePublicLinkMutationVariables>;
export const UpdateTextFieldDocument = gql`
    mutation updateTextField($data: UpdateCustomTextFieldInput!, $chartUuid: String!, $uuid: String!) {
  updateTextField(updateTextFieldInput: $data, chartUuid: $chartUuid, uuid: $uuid) {
    ...CustomTextFieldFragment
  }
}
    ${CustomTextFieldFragmentFragmentDoc}`;
export type UpdateTextFieldMutationFn = Apollo.MutationFunction<UpdateTextFieldMutation, UpdateTextFieldMutationVariables>;

/**
 * __useUpdateTextFieldMutation__
 *
 * To run a mutation, you first call `useUpdateTextFieldMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTextFieldMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTextFieldMutation, { data, loading, error }] = useUpdateTextFieldMutation({
 *   variables: {
 *      data: // value for 'data'
 *      chartUuid: // value for 'chartUuid'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useUpdateTextFieldMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTextFieldMutation, UpdateTextFieldMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTextFieldMutation, UpdateTextFieldMutationVariables>(UpdateTextFieldDocument, options);
      }
export type UpdateTextFieldMutationHookResult = ReturnType<typeof useUpdateTextFieldMutation>;
export type UpdateTextFieldMutationResult = Apollo.MutationResult<UpdateTextFieldMutation>;
export type UpdateTextFieldMutationOptions = Apollo.BaseMutationOptions<UpdateTextFieldMutation, UpdateTextFieldMutationVariables>;
export const ExportNodesDocument = gql`
    query exportNodes($chartUuid: String!, $config: ExportNodesConfig!) {
  exportNodes(chartUuid: $chartUuid, config: $config) {
    downloadUrl
  }
}
    `;

/**
 * __useExportNodesQuery__
 *
 * To run a query within a React component, call `useExportNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportNodesQuery({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *      config: // value for 'config'
 *   },
 * });
 */
export function useExportNodesQuery(baseOptions: Apollo.QueryHookOptions<ExportNodesQuery, ExportNodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportNodesQuery, ExportNodesQueryVariables>(ExportNodesDocument, options);
      }
export function useExportNodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportNodesQuery, ExportNodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportNodesQuery, ExportNodesQueryVariables>(ExportNodesDocument, options);
        }
export type ExportNodesQueryHookResult = ReturnType<typeof useExportNodesQuery>;
export type ExportNodesLazyQueryHookResult = ReturnType<typeof useExportNodesLazyQuery>;
export type ExportNodesQueryResult = Apollo.QueryResult<ExportNodesQuery, ExportNodesQueryVariables>;
export const AppDocument = gql`
    query app {
  app {
    ...AppFragment
  }
}
    ${AppFragmentFragmentDoc}`;

/**
 * __useAppQuery__
 *
 * To run a query within a React component, call `useAppQuery` and pass it any options that fit your needs.
 * When your component renders, `useAppQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAppQuery({
 *   variables: {
 *   },
 * });
 */
export function useAppQuery(baseOptions?: Apollo.QueryHookOptions<AppQuery, AppQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppQuery, AppQueryVariables>(AppDocument, options);
      }
export function useAppLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppQuery, AppQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppQuery, AppQueryVariables>(AppDocument, options);
        }
export type AppQueryHookResult = ReturnType<typeof useAppQuery>;
export type AppLazyQueryHookResult = ReturnType<typeof useAppLazyQuery>;
export type AppQueryResult = Apollo.QueryResult<AppQuery, AppQueryVariables>;
export const ChartDocument = gql`
    query chart($key: String!) {
  chart(key: $key) {
    ...ChartDetailFragment
  }
}
    ${ChartDetailFragmentFragmentDoc}`;

/**
 * __useChartQuery__
 *
 * To run a query within a React component, call `useChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useChartQuery(baseOptions: Apollo.QueryHookOptions<ChartQuery, ChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChartQuery, ChartQueryVariables>(ChartDocument, options);
      }
export function useChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChartQuery, ChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChartQuery, ChartQueryVariables>(ChartDocument, options);
        }
export type ChartQueryHookResult = ReturnType<typeof useChartQuery>;
export type ChartLazyQueryHookResult = ReturnType<typeof useChartLazyQuery>;
export type ChartQueryResult = Apollo.QueryResult<ChartQuery, ChartQueryVariables>;
export const ChartsDocument = gql`
    query charts {
  charts {
    items {
      ...ChartFragment
    }
  }
}
    ${ChartFragmentFragmentDoc}`;

/**
 * __useChartsQuery__
 *
 * To run a query within a React component, call `useChartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChartsQuery({
 *   variables: {
 *   },
 * });
 */
export function useChartsQuery(baseOptions?: Apollo.QueryHookOptions<ChartsQuery, ChartsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChartsQuery, ChartsQueryVariables>(ChartsDocument, options);
      }
export function useChartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChartsQuery, ChartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChartsQuery, ChartsQueryVariables>(ChartsDocument, options);
        }
export type ChartsQueryHookResult = ReturnType<typeof useChartsQuery>;
export type ChartsLazyQueryHookResult = ReturnType<typeof useChartsLazyQuery>;
export type ChartsQueryResult = Apollo.QueryResult<ChartsQuery, ChartsQueryVariables>;
export const GetCollaboratorsDocument = gql`
    query getCollaborators($chartUuid: String!) {
  getCollaborators(chartUuid: $chartUuid) {
    ...CollaboratorFragment
  }
}
    ${CollaboratorFragmentFragmentDoc}`;

/**
 * __useGetCollaboratorsQuery__
 *
 * To run a query within a React component, call `useGetCollaboratorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCollaboratorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCollaboratorsQuery({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *   },
 * });
 */
export function useGetCollaboratorsQuery(baseOptions: Apollo.QueryHookOptions<GetCollaboratorsQuery, GetCollaboratorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCollaboratorsQuery, GetCollaboratorsQueryVariables>(GetCollaboratorsDocument, options);
      }
export function useGetCollaboratorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCollaboratorsQuery, GetCollaboratorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCollaboratorsQuery, GetCollaboratorsQueryVariables>(GetCollaboratorsDocument, options);
        }
export type GetCollaboratorsQueryHookResult = ReturnType<typeof useGetCollaboratorsQuery>;
export type GetCollaboratorsLazyQueryHookResult = ReturnType<typeof useGetCollaboratorsLazyQuery>;
export type GetCollaboratorsQueryResult = Apollo.QueryResult<GetCollaboratorsQuery, GetCollaboratorsQueryVariables>;
export const InsightsDocument = gql`
    query insights($chartUuid: String!) {
  insights(chartUuid: $chartUuid) {
    items {
      ...InsightFragment
    }
  }
}
    ${InsightFragmentFragmentDoc}`;

/**
 * __useInsightsQuery__
 *
 * To run a query within a React component, call `useInsightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInsightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInsightsQuery({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *   },
 * });
 */
export function useInsightsQuery(baseOptions: Apollo.QueryHookOptions<InsightsQuery, InsightsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InsightsQuery, InsightsQueryVariables>(InsightsDocument, options);
      }
export function useInsightsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InsightsQuery, InsightsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InsightsQuery, InsightsQueryVariables>(InsightsDocument, options);
        }
export type InsightsQueryHookResult = ReturnType<typeof useInsightsQuery>;
export type InsightsLazyQueryHookResult = ReturnType<typeof useInsightsLazyQuery>;
export type InsightsQueryResult = Apollo.QueryResult<InsightsQuery, InsightsQueryVariables>;
export const GetInvitationDocument = gql`
    query getInvitation($code: String!) {
  getInvitation(code: $code) {
    ...CollaboratorFragment
  }
}
    ${CollaboratorFragmentFragmentDoc}`;

/**
 * __useGetInvitationQuery__
 *
 * To run a query within a React component, call `useGetInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetInvitationQuery(baseOptions: Apollo.QueryHookOptions<GetInvitationQuery, GetInvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvitationQuery, GetInvitationQueryVariables>(GetInvitationDocument, options);
      }
export function useGetInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvitationQuery, GetInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvitationQuery, GetInvitationQueryVariables>(GetInvitationDocument, options);
        }
export type GetInvitationQueryHookResult = ReturnType<typeof useGetInvitationQuery>;
export type GetInvitationLazyQueryHookResult = ReturnType<typeof useGetInvitationLazyQuery>;
export type GetInvitationQueryResult = Apollo.QueryResult<GetInvitationQuery, GetInvitationQueryVariables>;
export const NodeChildDepartmentsDocument = gql`
    query nodeChildDepartments($chartUuid: String!, $uuid: String!) {
  node(chartUuid: $chartUuid, uuid: $uuid) {
    ...PersonChildDepartmentsDataFragment
  }
}
    ${PersonChildDepartmentsDataFragmentFragmentDoc}`;

/**
 * __useNodeChildDepartmentsQuery__
 *
 * To run a query within a React component, call `useNodeChildDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeChildDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeChildDepartmentsQuery({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useNodeChildDepartmentsQuery(baseOptions: Apollo.QueryHookOptions<NodeChildDepartmentsQuery, NodeChildDepartmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodeChildDepartmentsQuery, NodeChildDepartmentsQueryVariables>(NodeChildDepartmentsDocument, options);
      }
export function useNodeChildDepartmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodeChildDepartmentsQuery, NodeChildDepartmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodeChildDepartmentsQuery, NodeChildDepartmentsQueryVariables>(NodeChildDepartmentsDocument, options);
        }
export type NodeChildDepartmentsQueryHookResult = ReturnType<typeof useNodeChildDepartmentsQuery>;
export type NodeChildDepartmentsLazyQueryHookResult = ReturnType<typeof useNodeChildDepartmentsLazyQuery>;
export type NodeChildDepartmentsQueryResult = Apollo.QueryResult<NodeChildDepartmentsQuery, NodeChildDepartmentsQueryVariables>;
export const NodeDocument = gql`
    query node($chartKey: String!, $uuid: String!) {
  node(chartKey: $chartKey, uuid: $uuid) {
    ...NodeDetailDataFragment
  }
}
    ${NodeDetailDataFragmentFragmentDoc}`;

/**
 * __useNodeQuery__
 *
 * To run a query within a React component, call `useNodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeQuery({
 *   variables: {
 *      chartKey: // value for 'chartKey'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useNodeQuery(baseOptions: Apollo.QueryHookOptions<NodeQuery, NodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodeQuery, NodeQueryVariables>(NodeDocument, options);
      }
export function useNodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodeQuery, NodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodeQuery, NodeQueryVariables>(NodeDocument, options);
        }
export type NodeQueryHookResult = ReturnType<typeof useNodeQuery>;
export type NodeLazyQueryHookResult = ReturnType<typeof useNodeLazyQuery>;
export type NodeQueryResult = Apollo.QueryResult<NodeQuery, NodeQueryVariables>;
export const NodesDocument = gql`
    query nodes($chartKey: String!, $filter: GetNodesFilter) {
  nodes(chartKey: $chartKey, filter: $filter) {
    items {
      ...NodeDataFragment
    }
  }
}
    ${NodeDataFragmentFragmentDoc}`;

/**
 * __useNodesQuery__
 *
 * To run a query within a React component, call `useNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodesQuery({
 *   variables: {
 *      chartKey: // value for 'chartKey'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useNodesQuery(baseOptions: Apollo.QueryHookOptions<NodesQuery, NodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodesQuery, NodesQueryVariables>(NodesDocument, options);
      }
export function useNodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodesQuery, NodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodesQuery, NodesQueryVariables>(NodesDocument, options);
        }
export type NodesQueryHookResult = ReturnType<typeof useNodesQuery>;
export type NodesLazyQueryHookResult = ReturnType<typeof useNodesLazyQuery>;
export type NodesQueryResult = Apollo.QueryResult<NodesQuery, NodesQueryVariables>;
export const PersonTeamSizeDocument = gql`
    query personTeamSize($chartKey: String!, $uuid: String!) {
  node(chartKey: $chartKey, uuid: $uuid) {
    ...PersonTeamSizeFragment
  }
}
    ${PersonTeamSizeFragmentFragmentDoc}`;

/**
 * __usePersonTeamSizeQuery__
 *
 * To run a query within a React component, call `usePersonTeamSizeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePersonTeamSizeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePersonTeamSizeQuery({
 *   variables: {
 *      chartKey: // value for 'chartKey'
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function usePersonTeamSizeQuery(baseOptions: Apollo.QueryHookOptions<PersonTeamSizeQuery, PersonTeamSizeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PersonTeamSizeQuery, PersonTeamSizeQueryVariables>(PersonTeamSizeDocument, options);
      }
export function usePersonTeamSizeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PersonTeamSizeQuery, PersonTeamSizeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PersonTeamSizeQuery, PersonTeamSizeQueryVariables>(PersonTeamSizeDocument, options);
        }
export type PersonTeamSizeQueryHookResult = ReturnType<typeof usePersonTeamSizeQuery>;
export type PersonTeamSizeLazyQueryHookResult = ReturnType<typeof usePersonTeamSizeLazyQuery>;
export type PersonTeamSizeQueryResult = Apollo.QueryResult<PersonTeamSizeQuery, PersonTeamSizeQueryVariables>;
export const GetPublicLinksDocument = gql`
    query getPublicLinks($chartUuid: String!) {
  getPublicLinks(chartUuid: $chartUuid) {
    ...PublicLink
  }
}
    ${PublicLinkFragmentDoc}`;

/**
 * __useGetPublicLinksQuery__
 *
 * To run a query within a React component, call `useGetPublicLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicLinksQuery({
 *   variables: {
 *      chartUuid: // value for 'chartUuid'
 *   },
 * });
 */
export function useGetPublicLinksQuery(baseOptions: Apollo.QueryHookOptions<GetPublicLinksQuery, GetPublicLinksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublicLinksQuery, GetPublicLinksQueryVariables>(GetPublicLinksDocument, options);
      }
export function useGetPublicLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicLinksQuery, GetPublicLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublicLinksQuery, GetPublicLinksQueryVariables>(GetPublicLinksDocument, options);
        }
export type GetPublicLinksQueryHookResult = ReturnType<typeof useGetPublicLinksQuery>;
export type GetPublicLinksLazyQueryHookResult = ReturnType<typeof useGetPublicLinksLazyQuery>;
export type GetPublicLinksQueryResult = Apollo.QueryResult<GetPublicLinksQuery, GetPublicLinksQueryVariables>;
export const TeamMembersDocument = gql`
    query teamMembers($chartKey: String!, $filter: TeamMembersFilter!) {
  teamMembers(chartKey: $chartKey, filter: $filter) {
    items {
      ...TeamMembersFragment
    }
  }
}
    ${TeamMembersFragmentFragmentDoc}`;

/**
 * __useTeamMembersQuery__
 *
 * To run a query within a React component, call `useTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersQuery({
 *   variables: {
 *      chartKey: // value for 'chartKey'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useTeamMembersQuery(baseOptions: Apollo.QueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, options);
      }
export function useTeamMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, options);
        }
export type TeamMembersQueryHookResult = ReturnType<typeof useTeamMembersQuery>;
export type TeamMembersLazyQueryHookResult = ReturnType<typeof useTeamMembersLazyQuery>;
export type TeamMembersQueryResult = Apollo.QueryResult<TeamMembersQuery, TeamMembersQueryVariables>;
export const UserDocument = gql`
    query user {
  user {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const SearchNodesDocument = gql`
    query searchNodes($chartKey: String!, $filter: SearchNodesFilter!) {
  searchNodes(chartKey: $chartKey, filter: $filter) {
    items {
      ...NodeSearchDataFragment
    }
  }
}
    ${NodeSearchDataFragmentFragmentDoc}`;

/**
 * __useSearchNodesQuery__
 *
 * To run a query within a React component, call `useSearchNodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchNodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNodesQuery({
 *   variables: {
 *      chartKey: // value for 'chartKey'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useSearchNodesQuery(baseOptions: Apollo.QueryHookOptions<SearchNodesQuery, SearchNodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchNodesQuery, SearchNodesQueryVariables>(SearchNodesDocument, options);
      }
export function useSearchNodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchNodesQuery, SearchNodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchNodesQuery, SearchNodesQueryVariables>(SearchNodesDocument, options);
        }
export type SearchNodesQueryHookResult = ReturnType<typeof useSearchNodesQuery>;
export type SearchNodesLazyQueryHookResult = ReturnType<typeof useSearchNodesLazyQuery>;
export type SearchNodesQueryResult = Apollo.QueryResult<SearchNodesQuery, SearchNodesQueryVariables>;
export const ShowUnassignedNodesSidebarQueryDocument = gql`
    query showUnassignedNodesSidebarQuery {
  showUnassignedNodesSidebar @client
}
    `;

/**
 * __useShowUnassignedNodesSidebarQueryQuery__
 *
 * To run a query within a React component, call `useShowUnassignedNodesSidebarQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useShowUnassignedNodesSidebarQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShowUnassignedNodesSidebarQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useShowUnassignedNodesSidebarQueryQuery(baseOptions?: Apollo.QueryHookOptions<ShowUnassignedNodesSidebarQueryQuery, ShowUnassignedNodesSidebarQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShowUnassignedNodesSidebarQueryQuery, ShowUnassignedNodesSidebarQueryQueryVariables>(ShowUnassignedNodesSidebarQueryDocument, options);
      }
export function useShowUnassignedNodesSidebarQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShowUnassignedNodesSidebarQueryQuery, ShowUnassignedNodesSidebarQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShowUnassignedNodesSidebarQueryQuery, ShowUnassignedNodesSidebarQueryQueryVariables>(ShowUnassignedNodesSidebarQueryDocument, options);
        }
export type ShowUnassignedNodesSidebarQueryQueryHookResult = ReturnType<typeof useShowUnassignedNodesSidebarQueryQuery>;
export type ShowUnassignedNodesSidebarQueryLazyQueryHookResult = ReturnType<typeof useShowUnassignedNodesSidebarQueryLazyQuery>;
export type ShowUnassignedNodesSidebarQueryQueryResult = Apollo.QueryResult<ShowUnassignedNodesSidebarQueryQuery, ShowUnassignedNodesSidebarQueryQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "BaseNode": [
      "Department",
      "Person"
    ],
    "CustomField": [
      "CustomConnectionField",
      "CustomDateField",
      "CustomDropdownField",
      "CustomTextField"
    ],
    "CustomFieldItem": [
      "CustomConnectionField",
      "CustomDateField",
      "CustomDropdownField",
      "CustomTextField"
    ],
    "CustomFieldValue": [
      "CustomConnectionFieldValue",
      "CustomDateFieldValue",
      "CustomDropdownFieldValue",
      "CustomTextFieldValue"
    ],
    "IDataset": [
      "InsightDatasetMulti",
      "InsightDatasetOneOnOne",
      "InsightDatasetSingle",
      "InsightDatasetSociomap",
      "InsightDatasetSpanOfControl"
    ],
    "ISlice": [
      "InsightChart",
      "InsightKpi",
      "InsightSpanOfControl"
    ],
    "InsightDataset": [
      "InsightDatasetMulti",
      "InsightDatasetOneOnOne",
      "InsightDatasetSingle",
      "InsightDatasetSociomap",
      "InsightDatasetSpanOfControl"
    ],
    "ListValue": [
      "CustomConnectionFieldValue"
    ],
    "Node": [
      "Department",
      "Person"
    ],
    "PaginatedCollection": [
      "ChartCollection",
      "InsightCollection",
      "QuotaCollection"
    ],
    "PaginatedCollectionItem": [
      "Chart",
      "Insight",
      "Quota"
    ],
    "Slice": [
      "InsightChart",
      "InsightKpi",
      "InsightSpanOfControl"
    ],
    "StringRangeValue": [],
    "StringValue": [
      "CustomDateFieldValue",
      "CustomDropdownFieldValue",
      "CustomTextFieldValue"
    ]
  }
};
      export default result;
    