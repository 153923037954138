export const downsizePortraitImage = async (
  base64Encoded: string,
  maxSize = 200 * 1024, // 200 KB
  maxWidth = 400,
  maxHeight = maxWidth
): Promise<string> => {
  if (base64Encoded.length < maxSize) return base64Encoded
  return downsizeBase64Image(base64Encoded, maxWidth, maxHeight).then(blobToBase64Image)
}

export const getImgSizeInMB = (absoluteBytes: number) => absoluteBytes / 1048576

const downsizeBase64Image = async (base64Image: string, maxWidth: number, maxHeight: number): Promise<Blob | null> => {
  return new Promise<Blob | null>((resolve, reject) => {
    const image = new Image()
    image.src = base64Image

    image.onload = () => {
      const width = image.width
      const height = image.height

      let newWidth
      let newHeight

      if (width > height) {
        newHeight = height * (maxWidth / width)
        newWidth = maxWidth
      } else {
        newWidth = width * (maxHeight / height)
        newHeight = maxHeight
      }

      const canvas = document.createElement('canvas')
      canvas.width = newWidth
      canvas.height = newHeight

      const context = canvas.getContext('2d')
      context?.drawImage(image, 0, 0, newWidth, newHeight)
      canvas.toBlob(resolve)
    }
    image.onerror = reject
  })
}

const blobToBase64Image = async (blob: Blob | null): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result as string)
    }
    reader.readAsDataURL(blob as Blob)
  })
}

export function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.readAsDataURL(file)
    reader.onload = function () {
      return resolve(reader.result as string)
    }

    reader.onerror = function (error) {
      return reject(error)
    }
  })
}

export const getFileFromBase64 = async (
  base64String: string,
  fileName = 'image',
  options?: FilePropertyBag
): Promise<File> => {
  const newOptions = { lastModified: new Date().getTime(), type: 'image/png', ...options }
  const response = await fetch(base64String)
  const blob = await response.blob()
  return new File([blob], fileName, newOptions)
}
