import { Redirect, RedirectProps } from 'react-router-dom'
import { history } from 'app/history'

export const RenderRedirects = (redirects: RedirectProps[]) => {
  const location = history.location

  return redirects.map((props, i) => {
    if (typeof props.to === 'object') {
      return <Redirect key={i} {...props} to={{ ...location, ...props.to }} />
    }
    return <Redirect key={i} {...props} to={{ ...location, pathname: props.to }} />
  })
}
