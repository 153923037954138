import { useState, ReactNode, useEffect } from 'react'

import { createSafeContext } from 'helpers'
import { chartSidePanelDetailSorage } from '../utils'

type Context = {
  isAboutExpanded: boolean
  isConnectionsExpanded: boolean
  isContactsExpanded: boolean
  isLocationsExpanded: boolean
  setIsAboutExpanded: (isExpanded: boolean) => void
  setIsConnectionsExpanded: (isExpanded: boolean) => void
  setIsContactsExpanded: (isExpanded: boolean) => void
  setIsLocationsExpanded: (isExpanded: boolean) => void
}
export const [useChartSidePanelDetail, ChartSidePanelDetailContextSafeProvider] = createSafeContext<Context>()

type Props = {
  children: ReactNode
}

export const ChartSidePanelDetailProvider = ({ children }: Props) => {
  const { getState, editState, initializeStorage } = chartSidePanelDetailSorage

  // Initialize values
  useEffect(() => {
    initializeStorage()
  }, [])

  // Set initial state from local storage
  const [initState] = useState(getState)
  const [isAboutExpanded, setIsAboutExpanded] = useState(Boolean(initState?.isAboutExpanded))
  const [isConnectionsExpanded, setIsConnectionsExpanded] = useState(Boolean(initState?.isConnectionsExpanded))
  const [isContactsExpanded, setIsContactsExpanded] = useState(Boolean(initState?.isContactsExpanded))
  const [isLocationsExpanded, setIsLocationsExpanded] = useState(Boolean(initState?.isLocationsExpanded))

  // Set state and save in local storage
  const handleSetIsAboutExpanded = (isExpanded: boolean) => {
    setIsAboutExpanded(isExpanded)
    editState({ isAboutExpanded: isExpanded })
  }
  const handleSetIsConnectionsExpanded = (isExpanded: boolean) => {
    setIsConnectionsExpanded(isExpanded)
    editState({ isConnectionsExpanded: isExpanded })
  }
  const handleSetIsContactsExpanded = (isExpanded: boolean) => {
    setIsContactsExpanded(isExpanded)
    editState({ isContactsExpanded: isExpanded })
  }
  const handleSetIsLocationsExpanded = (isExpanded: boolean) => {
    setIsLocationsExpanded(isExpanded)
    editState({ isLocationsExpanded: isExpanded })
  }

  return (
    <ChartSidePanelDetailContextSafeProvider
      value={{
        isAboutExpanded,
        isConnectionsExpanded,
        isContactsExpanded,
        isLocationsExpanded,
        setIsAboutExpanded: handleSetIsAboutExpanded,
        setIsConnectionsExpanded: handleSetIsConnectionsExpanded,
        setIsContactsExpanded: handleSetIsContactsExpanded,
        setIsLocationsExpanded: handleSetIsLocationsExpanded,
      }}
    >
      {children}
    </ChartSidePanelDetailContextSafeProvider>
  )
}
