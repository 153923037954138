import { ReactNode } from 'react'

import { GroupContainer } from './group-container'
import { Heading } from './heading'
import { IconContainer } from './icon-container'
import { StyledChevronDownIcon } from './styled-chevron-down-icon'

type Props = {
  icon: ReactNode
  title: string
  isExpanded?: boolean
  onClick?: () => void
}

export const GroupHeading = ({ icon, title, isExpanded, onClick }: Props) => (
  <GroupContainer onClick={onClick}>
    <IconContainer>{icon}</IconContainer>
    <Heading>{title}</Heading>
    {onClick && <StyledChevronDownIcon isPointingUp={Boolean(isExpanded)} />}
  </GroupContainer>
)
