import { FC } from 'react'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'

import { ChartSidePanel } from 'components/generic'

import { getChartDetailPath } from 'routes'

type WrappedComponent = FC<{ onClose: () => void }>
type Props = {
  component: WrappedComponent
}

function SidePanel({ component: Component }: Props) {
  const { params } = useRouteMatch<Record<string, string>>()
  const history = useHistory()
  const onClose = () => history.push(generatePath(getChartDetailPath(params), params))

  return (
    <ChartSidePanel>
      <Component onClose={onClose} />
    </ChartSidePanel>
  )
}

export function withSidePanel(WrappedComponent: WrappedComponent) {
  return function SidePanelWrap() {
    return <SidePanel component={WrappedComponent} />
  }
}
