import { gql } from '@apollo/client'
import { personSearchDataFragment } from './person-search-fragment'
import { departmentSearchDataFragment } from './department-search-fragment'

export const nodeSearchDataFragment = gql`
  fragment NodeSearchDataFragment on Node {
    ...PersonSearchDataFragment
    ...DepartmentSearchDataFragment
  }
  ${personSearchDataFragment}
  ${departmentSearchDataFragment}
`
