import { useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom'

import { ConnectGoogleWorkspace, ConnectGoogleWorkspaceProps } from 'screens/chart/components/connect-google-workspace'

import { useApolloClient } from '@apollo/client'
import { node as nodeQuery } from 'apollo/query'
import { NodeQuery, NodeQueryVariables } from 'apollo/generated/graphql'

export const ConnectGoogleWorkspaceAddWritePermission = (props: ConnectGoogleWorkspaceProps) => {
  const { params } = useRouteMatch<{ uuid: string; chartUuid: string }>()
  const { uuid, chartUuid } = params
  const client = useApolloClient()

  const onSuccessHandler = useCallback(() => {
    // rewrites old data that didn't have write permission
    client.query<NodeQuery, NodeQueryVariables>({
      query: nodeQuery,
      fetchPolicy: 'network-only',
      variables: { chartKey: chartUuid, uuid },
    })
  }, [])

  return <ConnectGoogleWorkspace {...props} onSuccess={onSuccessHandler} />
}
