import { MouseEvent } from 'react'
import styled from 'styled-components'

type Props = {
  className?: string
  checked?: boolean
  onClick?: (e: MouseEvent<HTMLElement>) => void
}

export const ToggleButton = ({ className, onClick, checked = false }: Props) => (
  <Container className={className}>
    <Input type='checkbox' onClick={onClick} checked={checked} />
    <Label />
  </Container>
)

const Container = styled.div`
  display: flex;
  position: relative;
`

const Label = styled.label`
  width: 50px;
  height: 26px;
  position: relative;
  border-radius: 25px;
  transition: ${props => props.theme.transitions.mediumEase};
  background: ${props => props.theme.colors.greyExtraLight};

  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    left: 3px;
    top: 3px;
    z-index: 2;
    background: ${props => props.theme.colors.white};
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: 0.4s;
  }
`

const Input = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  opacity: 0;
  cursor: pointer;

  &:hover + ${Label}:after {
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2), 0 3px 8px 0 rgba(0, 0, 0, 0.15);
  }

  &:checked + ${Label} {
    background: ${props => props.theme.colors.purple};
    &:after {
      transform: translateX(24px);
    }
  }
`
