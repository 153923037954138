import { toast } from 'react-toastify'
import { FormikHelpers } from 'formik'

import { useUpdateChartRequest } from './use-update-chart-request'
import { ChartFormSubmitValues, ChartFormValues } from 'tree/types'
import analytics from 'analytics'
import { ORG_TRACK } from 'analytics/constants'
import { handleErrorValidation } from 'helpers'

import { Chart } from 'apollo/generated/graphql'

type UpdateChartActionParams = {
  actions: FormikHelpers<ChartFormValues>
  chartId: Chart['id']
  values: ChartFormSubmitValues
}

export const useUpdateChartAction = () => {
  const { updateChartRequest } = useUpdateChartRequest()

  const updateChartAction = async ({ actions, chartId, values }: UpdateChartActionParams) => {
    try {
      const res = await updateChartRequest({ chartId, values })
      analytics.track(ORG_TRACK.updateChart, { uuid: chartId, name: values.name })
      toast('Changes saved', { type: 'success' })
      return Promise.resolve(res)
    } catch (error) {
      handleErrorValidation({
        track: { message: ORG_TRACK.updateChartFailure, values: { chartUuid: chartId, error } },
        toast: { message: 'Chart update failed' },
        error,
        actions,
      })
    }
  }

  return { updateChartAction }
}
