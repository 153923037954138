import { useCallback } from 'react'

import { useRefetchPersonTeamSize, useDeleteOrUnassignNode } from '../../hooks'
import { useChartId } from '../../providers'
import { findSuperiorPerson } from 'helpers'

import { NodeDataFragmentFragment, useUnassignNodeMutation } from 'apollo/generated/graphql'

type NodeParam = Pick<NodeDataFragmentFragment, 'uuid' | 'parentUuid' | 'parentNodes'>

export const useUnassignNodeRequest = () => {
  const chartUuid = useChartId()
  const [unassignNodeMutation] = useUnassignNodeMutation()
  const { deleteOrUnassignNode } = useDeleteOrUnassignNode()
  const { isGsuiteProvider, refetchPersonTeamSize } = useRefetchPersonTeamSize()

  const unassignNodeRequest = useCallback(
    (node: NodeParam) => {
      return unassignNodeMutation({
        variables: { chartUuid, uuid: node.uuid },
        update: (cache, { data }) => {
          const mutationNodes = data?.unassignNode || []
          const prevData = { unassigned: false }
          const prevParentUuid = node.parentUuid || null
          deleteOrUnassignNode({ cache, mutationNodes, prevData, prevParentUuid })

          // Refetch teamSize
          if (isGsuiteProvider) {
            const previousSuperiorPerson = findSuperiorPerson(node)
            refetchPersonTeamSize(previousSuperiorPerson?.id)
          }
        },
      })
    },
    [chartUuid, unassignNodeMutation, deleteOrUnassignNode]
  )

  return { unassignNodeRequest }
}
