import styled, { css } from 'styled-components'

export const TableCol = styled.div<{ isActive?: boolean }>`
  display: flex;
  flex: 20%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  &:first-child {
    flex: 50%;
  }
  &:last-child {
    flex: 30%;
    text-align: right;
    justify-content: flex-end;
    align-self: center;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      color: ${props => props.theme.colors.dark};
    `};

  @media (max-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    &:last-child {
      flex-direction: column-reverse;
    }
  }
  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    line-height: 15px;
    align-items: flex-end;
    &:last-child {
      flex: 35%;
    }
  }
`
