import { useGetCollaboratorsQuery } from 'apollo/generated/graphql'

export const useGetCollaborators = (chartUuid: string) => {
  const { data } = useGetCollaboratorsQuery({
    variables: { chartUuid },
  })

  const collaborators = data?.getCollaborators || []

  return collaborators
}
