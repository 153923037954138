import { GroupHeading } from '../../components'
import { NodeDetailItem } from '../../../node-components'
import { FlexBox, Link, SeparatedInlineList, SocialLink } from 'components/generic'
import { OpenBookIcon } from 'components/icons'

import { useChartSidePanelDetail } from 'tree/providers'
import { ContactFields, PersonDetail, SocialLinkFieldKey, SocialLinkFields } from 'tree/types'
import { SOCIAL_LINKS_ICON } from 'tree/constants'
import { getWhatsappLink } from 'helpers'
import { getPriority } from 'consts'

type Props = {
  person: PersonDetail
}

export const ContactFieldsDetail = ({ person }: Props) => {
  const { emailAliases, email, mobile, facebookLink, instagramLink, linkedInLink, whatsAppLink, xLink, youTubeLink } =
    person
  const contactFields: ContactFields = {
    emailAliases,
    email,
    mobile,
    facebookLink,
    instagramLink,
    linkedInLink,
    whatsAppLink,
    xLink,
    youTubeLink,
  }
  const showContactFields = Object.values(contactFields).some(value => {
    if (Array.isArray(value)) return value.length > 0
    return Boolean(value)
  })

  if (!showContactFields) {
    return null
  }

  return <ContactFieldsDetailInner contactFields={contactFields} />
}

type PropsInner = {
  contactFields: ContactFields
}

const ContactFieldsDetailInner = ({ contactFields }: PropsInner) => {
  const { emailAliases, email, mobile, facebookLink, instagramLink, linkedInLink, whatsAppLink, xLink, youTubeLink } =
    contactFields
  const { isContactsExpanded, setIsContactsExpanded } = useChartSidePanelDetail()

  const emailArray = [...emailAliases]
  if (email) emailArray.unshift(email)
  const emailItems = emailArray.map(email => (
    <Link key={email} href={`mailto:${email}`} type='email'>
      {email}
    </Link>
  ))
  const showEmail = emailArray.length > 0

  const socialLinkFields: SocialLinkFields = {
    facebookLink,
    instagramLink,
    linkedInLink,
    whatsAppLink,
    xLink,
    youTubeLink,
  }
  const socialLinkFieldsArray = Object.entries(socialLinkFields).sort(
    ([a], [b]) => getPriority(b as keyof SocialLinkFields) - getPriority(a as keyof SocialLinkFields)
  )
  const showSocialLinks = socialLinkFieldsArray.some(([_, href]) => href)

  return (
    <section>
      <GroupHeading
        icon={<OpenBookIcon width='17px' />}
        title='Contacts'
        isExpanded={isContactsExpanded}
        onClick={() => setIsContactsExpanded(!isContactsExpanded)}
      />

      {isContactsExpanded && (
        <>
          {showEmail && <NodeDetailItem title='Email' value={<SeparatedInlineList items={emailItems} />} />}

          {mobile && (
            <NodeDetailItem
              title='Phone'
              value={
                <Link href={`tel:${mobile}`} type='tel'>
                  {mobile}
                </Link>
              }
            />
          )}

          {showSocialLinks && (
            <NodeDetailItem
              title='Social links'
              value={
                <FlexBox $gap='m'>
                  {socialLinkFieldsArray.map(([untypedKey, href]) => {
                    if (!href) return null
                    const key = untypedKey as SocialLinkFieldKey
                    const newHref = key === 'whatsAppLink' ? getWhatsappLink(href) : href
                    const { background, icon } = SOCIAL_LINKS_ICON[key]

                    return <SocialLink key={key} icon={icon} background={background} href={newHref} />
                  })}
                </FlexBox>
              }
            />
          )}
        </>
      )}
    </section>
  )
}
