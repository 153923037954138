import { SVG, SVGProps } from './SVG'

export const SyncErrorBadgeIcon = (props: SVGProps) => (
  <SVG width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <circle cx='8' cy='8' r='7.5' fill='#DA1414' stroke='white' />
    <path
      d='M3.18659 12.5V11.2957H4.02966L4.13513 11.3716C3.67374 10.94 3.30242 10.4382 3.02115 9.86603C2.74037 9.29435 2.59998 8.67234 2.59998 8C2.59998 6.89613 2.94867 5.93289 3.64605 5.11029C4.34343 4.28769 5.21878 3.76585 6.27207 3.54479V5.0655C5.64019 5.24563 5.11595 5.60419 4.69935 6.14119C4.28275 6.6782 4.07445 7.2978 4.07445 8C4.07445 8.45176 4.1674 8.87077 4.35331 9.25702C4.53873 9.64328 4.787 9.97175 5.09813 10.2424L5.03817 10.1825V9.44485H6.24173V12.5H3.18659ZM8.00301 10.9952C7.8224 10.9952 7.66949 10.9323 7.54427 10.8066C7.41857 10.6814 7.35572 10.5285 7.35572 10.3479C7.35572 10.1673 7.41857 10.0144 7.54427 9.88915C7.66949 9.76345 7.8224 9.70059 8.00301 9.70059C8.18362 9.70059 8.33653 9.76345 8.46175 9.88915C8.58745 10.0144 8.6503 10.1673 8.6503 10.3479C8.6503 10.5285 8.58745 10.6814 8.46175 10.8066C8.33653 10.9323 8.18362 10.9952 8.00301 10.9952ZM7.38606 8.79756V4.98965H8.61996V8.79756H7.38606ZM9.71877 12.4552V10.9345C10.3511 10.7544 10.873 10.3958 11.2843 9.85881C11.6956 9.3218 11.9012 8.7022 11.9012 8C11.9012 7.54824 11.8109 7.12923 11.6303 6.74298C11.4497 6.35672 11.2038 6.02825 10.8927 5.75759L10.9679 5.81755V6.55515H9.73395V3.5H12.8043V4.70429H11.9612L11.8557 4.62843C12.3272 5.04985 12.6985 5.54905 12.9697 6.12602C13.2408 6.703 13.3764 7.32766 13.3764 8C13.3764 9.10387 13.0301 10.0671 12.3376 10.8897C11.645 11.7123 10.7721 12.2341 9.71877 12.4552Z'
      fill='white'
    />
  </SVG>
)
