import { SVG, SVGProps } from './SVG'

export const HomeIcon = (props: SVGProps) => (
  <SVG width='16' height='14' viewBox='0 0 16 14' fill='none' {...props}>
    <path
      d='M8 2.44315L12 5.97256V12.098H10.4V7.39217H5.6V12.098H4V5.97256L8 2.44315ZM8 0.333344L0 7.39217H2.4V13.6667H7.2V8.96079H8.8V13.6667H13.6V7.39217H16L8 0.333344Z'
      fill='currentColor'
    />
  </SVG>
)
