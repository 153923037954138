import { StrictMode, useEffect, useState } from 'react'
import { Redirect, Switch } from 'react-router-dom'

import { ConditonalWrapper } from 'components/generic'

import globalConfig from 'config'
import analytics from 'analytics'
import { SUBSCRIPTION_TRACK } from 'analytics/constants'
import {
  CHARTS_PATH,
  LOGIN_PATH,
  OLD_ROUTES_REDIRECTS,
  PAYMENT_FAILURE_PATH,
  PAYMENT_SUCCESS_PATH,
  PROTECTED_ROUTES,
  PUBLIC_ROUTES,
  RenderProtectedRoutes,
  RenderRedirects,
  RenderRoutes,
} from 'routes'

type Props = {
  isAuthenticated: boolean
}

export const AppRouter = ({ isAuthenticated }: Props) => {
  const [hasCheckedPathname, setHasCheckedPathname] = useState(false)

  // Track PRO payment
  useEffect(() => {
    const pathname = window.location.pathname
    if (pathname.includes(PAYMENT_FAILURE_PATH)) {
      analytics.track(SUBSCRIPTION_TRACK.purchaseFailure)
    } else if (pathname.includes(PAYMENT_SUCCESS_PATH)) {
      analytics.track(SUBSCRIPTION_TRACK.purchaseSuccess)
    }
    setHasCheckedPathname(true)
  }, [])

  if (!hasCheckedPathname) return null

  return (
    <ConditonalWrapper
      condition={globalConfig.REACT_STRICT_MODE_ENABLED}
      wrapper={children => <StrictMode>{children}</StrictMode>}
    >
      <Switch>
        {RenderRedirects(OLD_ROUTES_REDIRECTS)}
        {RenderProtectedRoutes(PROTECTED_ROUTES)}
        {RenderRoutes(PUBLIC_ROUTES)}
        <Redirect to={isAuthenticated ? CHARTS_PATH : LOGIN_PATH} />
      </Switch>
    </ConditonalWrapper>
  )
}
