import { useState } from 'react'
import styled from 'styled-components'

import { Button, IconButton, Tooltip } from 'components/generic'
import { EditIcon, DuplicateChartIcon, FolderIcon, OrgchartStandardStructureIcon, TrashCanIcon } from 'components/icons'

import { useForkChartAction } from 'tree/actions'
import { useDirectory } from 'tree/hooks'
import { DepartmentDetail, PersonDetail } from 'tree/types'
import { useChartData, useTree } from 'tree/providers'
import { spaces } from 'theme'

type Props = {
  node: DepartmentDetail | PersonDetail
  onDelete: () => void
  onEdit: () => void
}

export const NodeActions = (props: Props) => {
  const { node, onDelete, onEdit } = props
  const { uuid, parentNodes, capabilities, type: nodeType, unassigned, name } = node
  const { canUpdate, canDelete, canMove } = capabilities || {}
  const { providerInfo, uuid: chartUuid } = useChartData()

  const { forkChartWithConfirm } = useForkChartAction()
  const { openDirectory } = useDirectory()
  const { zoomTo } = useTree()
  const handleZoomTo = () => zoomTo({ uuid, parentNodes })

  const [displayForkTooltip, setDisplayForkTooltip] = useState(false)
  const [displayEditTooltip, setDisplayEditTooltip] = useState(false)
  const [displayDeleteTooltip, setDisplayDeleteTooltip] = useState(false)

  const showDisabledDelete = nodeType === 'employee' && providerInfo?.name === 'gsuite' && canUpdate && !canDelete

  return (
    <NodeActionsInner>
      {unassigned ? (
        <Button
          buttonType='secondary'
          buttonSize='small'
          leftEl={<FolderIcon />}
          spacing={{ m: spaces.m, mr: 'auto' }}
          onClick={openDirectory}
          data-testid='button-open-directory-in-node-side-panel'
        >
          <StructureText>Open directory</StructureText>
        </Button>
      ) : (
        <Button
          buttonType='secondary'
          buttonSize='small'
          leftEl={<OrgchartStandardStructureIcon />}
          spacing={{ m: spaces.m, mr: 'auto' }}
          onClick={handleZoomTo}
          data-testid='button-see-in-chart-in-node-side-panel'
        >
          <StructureText>View in Org Chart</StructureText>
        </Button>
      )}

      <RightContent>
        {canUpdate && (
          <StyledIconButton
            type='secondary'
            size='big'
            onClick={() => forkChartWithConfirm({ chartId: chartUuid, providerInfo, rootName: name, rootId: uuid })}
            onMouseEnter={() => setDisplayForkTooltip(true)}
            onMouseLeave={() => setDisplayForkTooltip(false)}
            data-testid='button-duplicate-in-node-side-panel'
          >
            <StyledTooltip isTooltipShown={displayForkTooltip} isWider>
              Create chart starting from this position
            </StyledTooltip>
            <DuplicateChartIcon />
          </StyledIconButton>
        )}

        {(canUpdate || canMove) && (
          <StyledIconButton
            type='secondary'
            size='big'
            onClick={onEdit}
            onMouseEnter={() => setDisplayEditTooltip(true)}
            onMouseLeave={() => setDisplayEditTooltip(false)}
            data-testid='button-edit-in-node-side-panel'
          >
            <StyledTooltip isTooltipShown={displayEditTooltip}>Edit {nodeType}</StyledTooltip>
            <EditIcon />
          </StyledIconButton>
        )}

        {canDelete && (
          <StyledIconButton
            type='secondary'
            size='big'
            onClick={onDelete}
            onMouseEnter={() => setDisplayDeleteTooltip(true)}
            onMouseLeave={() => setDisplayDeleteTooltip(false)}
            data-testid='button-delete-in-node-side-panel'
          >
            <StyledTooltip isTooltipShown={displayDeleteTooltip}>Delete {nodeType}</StyledTooltip>
            <TrashCanIcon />
          </StyledIconButton>
        )}
        {showDisabledDelete && (
          <StyledIconButton
            type='secondary'
            size='big'
            onMouseEnter={() => setDisplayDeleteTooltip(true)}
            onMouseLeave={() => setDisplayDeleteTooltip(false)}
            disabled
          >
            <StyledTooltip isTooltipShown={displayDeleteTooltip}>
              Employees from Google Workspace cannot be deleted
            </StyledTooltip>
            <TrashCanIcon />
          </StyledIconButton>
        )}
      </RightContent>
    </NodeActionsInner>
  )
}

const NodeActionsInner = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid ${props => props.theme.colors.greyExtraLight};
  padding: ${props => props.theme.spaces.m};
`

const RightContent = styled.div`
  display: flex;
  margin: ${props => props.theme.spaces.m};
`

const StyledIconButton = styled(IconButton)`
  margin-left: ${props => props.theme.spaces.s};
`

const StructureText = styled.div`
  line-height: initial;
  margin-left: ${props => props.theme.spaces.s};
`

const StyledTooltip = styled(Tooltip)<{ isWider?: boolean }>`
  top: -8px;
  left: unset;
  bottom: unset;
  min-width: ${props => (props.isWider ? '115px' : '58px')};
  width: auto;
  max-width: unset;
  height: auto;
  margin-left: -12px;
  box-shadow: 0px 2px 4px rgba(39, 40, 51, 0.2);
  transform: translateY(-100%);
  font-size: 12px;
  line-height: 17px;

  &:after {
    top: unset;
    bottom: -3px;
    left: 6px;
  }
`
