import { gql } from '@apollo/client'
import { personDetailDataFragment } from './person-detail-fragment'
import { departmentDetailDataFragment } from './department-detail-fragment'

export const nodeDetailDataFragment = gql`
  fragment NodeDetailDataFragment on Node {
    ...PersonDetailDataFragment
    ...DepartmentDetailDataFragment
  }
  ${personDetailDataFragment}
  ${departmentDetailDataFragment}
`
