type Sizes = {
  vertical: string
  verticalGroup: string
}

export const TREE_STANDARD_SIZES: Sizes = {
  vertical: '30px', // bigger due to Person image overflowing node
  verticalGroup: '20px',
}

export const TREE_COMPACT_SIZES: Sizes = {
  vertical: '16px',
  verticalGroup: '16px',
}
