import { toast } from 'react-toastify'

export const useCopyToClipboard = () => {
  const copyToClipboard = (text: string) => {
    try {
      const inputElement = document.createElement('input')
      inputElement.value = text
      inputElement.style.position = 'absolute'
      inputElement.style.opacity = '0'
      inputElement.style.pointerEvents = 'none'

      document.body.append(inputElement)
      inputElement.select()
      document.execCommand('Copy')

      toast('Copied to clipboard!', { type: 'success' })

      return inputElement.remove()
    } catch (_) {
      toast('Operation "Copy" failed', { type: 'error' })
    }
  }

  return { copyToClipboard }
}
