import styled, { css } from 'styled-components'

type Orientation = 'bottom' | 'left'

type Props = {
  isTooltipShown: boolean
  className?: string
  noEndAnimation?: boolean
  shiftXPx?: number // Only works for $orientation='bottom'
  $orientation?: Orientation
}

export const Tooltip = styled.div<Props>`
  position: absolute;
  min-width: 80px;
  width: 100%;
  border-radius: 4px;
  padding: ${props => props.theme.spaces.s} ${props => props.theme.spaces.m};
  text-align: center;
  background: ${props => props.theme.colors.dark};
  color: ${props => props.theme.colors.white};
  font-size: 14px;
  line-height: 20px;
  white-space: initial;
  font-weight: initial;
  transform: translateY(${props => (props.isTooltipShown ? '100%' : 'calc(100% - 10px)')})
    translateX(calc(-50% + ${props => `${props.shiftXPx ?? 0}px`}));
  opacity: ${props => (props.isTooltipShown ? '1' : '0')};
  transition: all
    ${({ theme, isTooltipShown, noEndAnimation }) =>
      noEndAnimation && !isTooltipShown ? '0' : theme.transitions.extraFastEase};
  transition-property: transform, opacity;
  pointer-events: none;
  cursor: default;
  z-index: 99;
  ${props => (props.$orientation === 'left' ? tooltipLeftCSS : tooltipBottomCSS)}

  &:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    background: ${props => props.theme.colors.dark};
    ${props => (props.$orientation === 'left' ? arrowLeftCSS : arrowBottomCSS)}
  }
`

const tooltipBottomCSS = css<Props>`
  bottom: 0;
  left: 50%;
`
const tooltipLeftCSS = css<Props>`
  bottom: unset;
  left: 0;
  transform: translate(-100%, 0px);
`

const arrowBottomCSS = css<Props>`
  top: -3px;
  left: 0;
  margin-left: 50%;
  transform: translateX(calc(-50% + ${props => `${(props.shiftXPx ?? 0) * -1}px`})) rotate(-45deg);
`
const arrowLeftCSS = css<Props>`
  right: 0px;
  bottom: 50%;
  transform: translate(3px, 3px) rotate(-45deg);
`
