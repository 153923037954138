import { RefObject } from 'react'
import styled from 'styled-components'

import { sizes } from 'theme'

type Props = {
  innerRef: RefObject<HTMLDivElement>
}

export const ScrollAnchor = ({ innerRef }: Props) => (
  <Container>
    <Anchor ref={innerRef} />
  </Container>
)

const Container = styled.div`
  position: relative;
  width: 0;
  height: 0;
`

const Anchor = styled.div`
  position: absolute;
  top: -${sizes.topNavigation};
  height: 0;
  width: 0;
`
