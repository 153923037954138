import { useState, useEffect, ChangeEvent, SyntheticEvent } from 'react'
import styled from 'styled-components'
import { DragElementWrapper } from 'react-dnd'

import { DirectorySearchContainer } from './directory-search-container'
import { DirectoryEmptyContainer } from './directory-empty-container'
import { TabList, DirectoryTab } from 'components/specific/tab-list'
import { Loading, IconButton } from 'components/generic'
import { CloseIcon } from 'components/icons'

import { colors } from 'theme'
import { useFullTextSearch } from 'hooks'
import { useChartData } from 'tree/providers'
import { useTopNavigationToggle } from 'providers'

import { NodeDataFragmentFragment, NodeSearchDataFragmentFragment } from 'apollo/generated/graphql'

type TabTypes = 'employeesTab' | 'departmentsTab'

type Props = {
  nodes: NodeDataFragmentFragment[]
  loading: boolean
  canDrop: boolean
  isOver: boolean
  dropRef: DragElementWrapper<any>
  onClose: () => void
}

export const DirectorySidebar = (props: Props) => {
  const { nodes, loading, canDrop, isOver, dropRef, onClose } = props
  const { uuid: chartUuid, unassignedEmployeeCount, unassignedDepartmentCount } = useChartData()
  const { isVisible } = useTopNavigationToggle()

  const [activeTab, setActiveTab] = useState<TabTypes>('employeesTab')
  const activeNodeType = activeTab === 'employeesTab' ? 'employee' : 'department'
  const activeNodeCount = activeTab === 'employeesTab' ? unassignedEmployeeCount : unassignedDepartmentCount

  const [inputValue, setInputValue] = useState('')
  const filterNodes = (type: 'employee' | 'department', nodes: NodeSearchDataFragmentFragment[]) => {
    return nodes.filter(node => node?.type === type)
  }
  const getResultNodes = () => {
    const showSearchedNodes = inputValue.length >= 2
    return filterNodes(activeNodeType, showSearchedNodes ? searchedNodes : (nodes as NodeSearchDataFragmentFragment[]))
  }

  const { search, data: searchedNodes, isSearching } = useFullTextSearch({ chartUuid, filter: { unassigned: true } })

  useEffect(() => {
    setInputValue('')
    search('')
    // TODO: remove nodes from dependency array and update resultNodes if showSearchedNodes === true (for actions -> update node/move from directory/move to directory)
  }, [activeTab, nodes])

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setInputValue(value)
    search(value)
  }

  if (loading) {
    return (
      <DirectoryContainer key='directory-loading' $isTopNavVisible={isVisible}>
        <Loading size='medium' />
      </DirectoryContainer>
    )
  }

  return (
    <DirectoryContainer $isTopNavVisible={isVisible}>
      {canDrop && (
        <DropZone
          isOver={isOver}
          onMouseDown={(e: SyntheticEvent) => e.stopPropagation()}
          ref={dropRef}
          data-testid='opened-directory-dropzone'
        >
          <DropZoneText>Drag here to relocate employee/department to your Directory.</DropZoneText>
        </DropZone>
      )}

      <Heading>
        Directory
        <IconButton onClick={onClose} data-testid='button-directory-close'>
          <CloseIcon />
        </IconButton>
      </Heading>

      <Content key='directory-items'>
        <TabList defaultActiveTab={0}>
          <DirectoryTab
            count={unassignedEmployeeCount}
            headingMaxWidth='90px'
            onClick={() => setActiveTab('employeesTab')}
          >
            Unassigned employees
          </DirectoryTab>
          <DirectoryTab
            count={unassignedDepartmentCount}
            headingMaxWidth='90px'
            onClick={() => setActiveTab('departmentsTab')}
          >
            Unassigned departments
          </DirectoryTab>
        </TabList>

        {activeNodeCount > 0 ? (
          <DirectorySearchContainer
            nodes={getResultNodes()}
            nodeType={activeNodeType}
            isSearching={isSearching}
            onChange={handleOnInputChange}
            value={inputValue}
          />
        ) : (
          <DirectoryEmptyContainer nodeType={activeNodeType} />
        )}
      </Content>
    </DirectoryContainer>
  )
}

const DirectoryContainer = styled.div<{ $isTopNavVisible: boolean }>`
  position: relative;
  width: 100%;
  max-width: 400px;
  max-height: calc(100vh - ${props => (props.$isTopNavVisible ? props.theme.sizes.topNavigation : '0px')});
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: ${colors.white};
  box-shadow: 0px 3px 12px rgba(69, 90, 100, 0.3);
  z-index: 10;
`

const DropZone = styled.div<{ isOver: boolean }>`
  position: absolute;
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 2px dashed ${props => (props.isOver ? colors.purple : colors.border)};
  background: ${props =>
    props.isOver
      ? 'linear-gradient(0deg, rgba(77, 6, 154, 0.05), rgba(77, 6, 154, 0.05)), rgba(255, 255, 255, 0.85)'
      : 'rgba(255, 255, 255, 0.85)'};
  pointer-events: auto;
  z-index: 20;
`

const DropZoneText = styled.div`
  max-width: 232px;
  text-align: center;
  color: ${colors.grey};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  user-select: none;
  pointer-events: none;
`

const Heading = styled.div`
  position: relative;
  width: 100%;
  min-height: 54px;
  padding: 10px 20px;
  padding-right: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.grey};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${colors.greyLightSecond};
  }
`

const Content = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
`
