import { ChangeEvent, useState } from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'

import { HeaderPreview } from './components'
import { View, ViewHeader, ViewTitle, ViewDescription } from '../components'
import { Card } from '../../components'
import { ImageDrop, DropFile, ColorPicker } from 'components/specific'
import { Button, SelectButton } from 'components/generic'

import { applyAlphaToHex, HexString } from 'helpers'
import { mainTheme } from 'theme'

// if getCanShowCustomBrandingView
export const Branding = () => {
  const [file, setFiles] = useState<DropFile | undefined>(undefined)
  const handleFiles = (files: DropFile[]) => setFiles(files[0])

  type HeaderStyle = 'one' | 'two' | 'three'
  const [headerStyle, setHeaderStyle] = useState<HeaderStyle>('one')

  const initialColor: HexString = mainTheme.colors.purple
  const initialSecondaryColor: HexString = applyAlphaToHex(mainTheme.colors.purple, 0.15)
  const [color, setColor] = useState<HexString>(initialColor)
  const [secondaryColor, setSecondaryColor] = useState<HexString>(initialSecondaryColor)

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHeaderStyle(e.target.value as HeaderStyle)
  }

  return (
    <View>
      <ViewHeader>
        <ViewTitle>Customize</ViewTitle>
      </ViewHeader>
      <ViewDescription>
        Make changes here to personalise your Org Chart. Saved changes will apply for all your users.
      </ViewDescription>

      {/* TODO: specify data structure of the form when backend ready */}
      <Formik<any>
        enableReinitialize
        initialValues={{ color, secondaryColor, file, headerStyle }}
        onSubmit={(data, actions) => {
          alert(`TODO: Do ${actions} on submit with data: ${JSON.stringify(data)}`)
          // if (file) getBase64(file).then(res => console.log('base64 img :', res))
        }}
      >
        {formState => {
          const { values, handleChange, handleBlur, handleSubmit, submitForm } = formState
          return (
            <Form tabIndex={0} onSubmit={handleSubmit}>
              <Card title='Your logo'>
                <ImageDrop provideFiles={handleFiles} type='logo' />
              </Card>

              <Card title='Header style'>
                <SelectButton
                  name='headerStyle'
                  type='radio'
                  value='one'
                  checked={values.headerStyle === 'one'}
                  onChange={e => {
                    handleChange(e)
                    handleRadioChange(e)
                  }}
                  onBlur={handleBlur}
                >
                  <HeaderPreview logoUrl={file?.preview} headlineColor='#fffff' buttonsColor={color} />
                </SelectButton>
                <SelectButton
                  name='headerStyle'
                  type='radio'
                  value='two'
                  checked={values.headerStyle === 'two'}
                  onChange={e => {
                    handleChange(e)
                    handleRadioChange(e)
                  }}
                  onBlur={handleBlur}
                >
                  <HeaderPreview logoUrl={file?.preview} headlineColor={color} buttonsColor={secondaryColor} />
                </SelectButton>
                <SelectButton
                  name='headerStyle'
                  type='radio'
                  value='three'
                  checked={values.headerStyle === 'three'}
                  onChange={e => {
                    handleChange(e)
                    handleRadioChange(e)
                  }}
                  onBlur={handleBlur}
                >
                  <HeaderPreview logoUrl={file?.preview} headlineColor={secondaryColor} buttonsColor={color} />
                </SelectButton>
              </Card>

              <Card title='Color scheme' direction='row'>
                <ColorPicker color={color} colorSelected={setColor} title='Change Primary Color' />
                <ColorPicker color={secondaryColor} colorSelected={setSecondaryColor} title='Change Secondary Color' />
              </Card>

              <ButtonWrapper>
                <Button onClick={submitForm}>Save changes</Button>
              </ButtonWrapper>
            </Form>
          )
        }}
      </Formik>
    </View>
  )
}

const Form = styled.form`
  outline: none;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`
