import { getCustomFieldsValueName } from 'components/specific/chart-side-panel/employee/helpers'

import { FormikHelpers } from 'formik'
import { camelCaseToString } from './string'

export const ERROR_CODE_MAP = {
  ACCOUNT_LOCKED: { silenceToast: true },
  FORBIDDEN: { silenceToast: false },
  BAD_USER_INPUT: { silenceToast: true },
  GSUITE_INSUFFICIENT_PERMISSIONS: { silenceToast: true },
  COLLABORATOR_NOT_FOUND: { silenceToast: true },
  COLLABORATOR_EXISTS: { silenceToast: true },
  PERSON_HAS_CHILDREN: { silenceToast: true },
  INVITATION_EXPIRED: { silenceToast: true },
  INVITATION_WRONG_EMAIL: { silenceToast: true },
  IMPORT_CYCLE_DETECTED: { silenceToast: true },
  UNAUTHENTICATED: { silenceToast: true },
}

export type ErrorCode = keyof typeof ERROR_CODE_MAP

export const extractErrorCode = (error: any): ErrorCode | undefined => {
  return extractExtensions(error)?.code as ErrorCode | undefined
}

export const extractExtensions = <T extends Record<string, unknown>>(error: any): T | undefined => {
  const { graphQLErrors } = error || {}
  if (Array.isArray(graphQLErrors)) {
    return graphQLErrors[0]?.extensions
  }
}

type ApolloMessageBE = {
  value: string
  sourceLocation?: {
    type: string
    path: (string | number)[]
  }
  constraints: Record<string, string>
  property: string
  children?: ApolloMessageBE[]
}

type ApolloMessageCustomFieldsBE = Omit<ApolloMessageBE, 'value' | 'children'> & {
  value: string | { id: string; value: string }
  children?: ApolloMessageCustomFieldsBE[]
}

type PropertyNameDelegate = (value?: string, property?: string) => string

const propertyNameMap: Record<string, string> = {
  familyName: 'lastName',
  givenName: 'firstName',
}

const replacePropertyName = (name = '') => propertyNameMap[name] ?? name

const defaultPropertyNameDelegate: PropertyNameDelegate = (value, property) =>
  `${camelCaseToString(replacePropertyName(property))} "${value}"`

export const extractErrorMessages = (
  error: any,
  propertyNameDelegate: PropertyNameDelegate = defaultPropertyNameDelegate
): ApolloMessageBE[] => {
  // TODO: get error list for customFields (structure: customFields -> text / date / select -> array of errors -> value)
  const errorList: ApolloMessageBE[] = error?.graphQLErrors[0]?.extensions?.message?.message || []

  if (errorList.length === 0) {
    const extensionMessage = error?.graphQLErrors[0]?.extensions?.details || []
    errorList.push(...extensionMessage)
  }

  return errorList.map(({ constraints = {}, property = '', value = '', ...rest }) => ({
    ...rest,
    value,
    property,
    constraints: Object.keys(constraints).reduce<Record<string, string>>((obj, key) => {
      const constraint = constraints[key] || ''
      obj[key] = constraint.replace(new RegExp(`^${property}?`), propertyNameDelegate(value, property))
      return obj
    }, {}),
  }))
}

const handleCustomTextFieldErrors = <T>({
  children = [],
  actions,
}: {
  children: ApolloMessageCustomFieldsBE[]
  actions: FormikHelpers<T>
}) => {
  children.forEach(({ children = [] }) => {
    children.forEach(({ value, children = [] }) => {
      const fieldName = getCustomFieldsValueName(typeof value === 'object' ? value?.id : '')
      const [errorMessage] = Object.values(children[0]?.constraints)
      actions.setFieldError(fieldName, errorMessage)
    })
  })
}

export const formErrorHandler = <T>(error: any, actions?: FormikHelpers<T>): ErrorCode | undefined => {
  const code = extractErrorCode(error)

  if (code !== 'BAD_USER_INPUT') {
    return code
  }

  if (actions) {
    const errorList = extractErrorMessages(error, () => 'Field')
    errorList.forEach(({ constraints, property: fieldName, children = [] }: ApolloMessageBE) => {
      if (fieldName === 'customFields') {
        try {
          handleCustomTextFieldErrors({ children, actions })
        } catch (error) {
          console.error(error)
        }
        return
      }

      if (constraints && fieldName) {
        const [errorMessage] = Object.values(constraints)
        actions.setFieldError(fieldName, errorMessage)
      }
    })
  }

  return code
}
