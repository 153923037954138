import { useEffect } from 'react'
import { useIntercom } from 'providers'

export const useHideIntercomOnEditEnter = () => {
  const { setShowLauncher } = useIntercom()
  // Due to intercom chat overlaping the save button in form
  useEffect(() => {
    setShowLauncher(false)
    return () => setShowLauncher(true)
  }, [])
}
