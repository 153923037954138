import { css } from 'styled-components'

export const nodeBorderCSS = css`
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% + 2px); /* add 2px due to bottom border not being counted into height */
    box-sizing: border-box;
    border-width: 2px;
    border-style: solid;
    border-radius: 4px;
    z-index: 3;
  }
`
