import { useState, ChangeEvent } from 'react'
import styled from 'styled-components'

import { NoResults, Results, StartTyping } from './search-contents'
import { useGetCollaborators } from 'components/forms'
import { FieldInput, SearchHint } from 'components/generic'
import { SearchIcon } from 'components/icons'

import { useFullTextSearch } from 'hooks'

import { CollaboratorRole, NodeSearchDataFragment_Person_Fragment } from 'apollo/generated/graphql'

export type EmployeeWithEmail = NodeSearchDataFragment_Person_Fragment & { email: string }
type Props = {
  chartUuid: string
  loggedUserChartRole: CollaboratorRole
  initValue?: string
}

export const InviteMembers = ({ chartUuid, loggedUserChartRole, initValue = '' }: Props) => {
  const { search, data, isSearching } = useFullTextSearch({ chartUuid })

  const collaborators = useGetCollaborators(chartUuid)
  const isInvited = (email: string) => collaborators.some(collaborator => collaborator.email === email)
  const invitableEmployees = data.filter(
    node => node.__typename === 'Person' && node.email && !isInvited(node.email)
  ) as EmployeeWithEmail[]

  const [value, setValue] = useState(initValue)
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currValue = e.target.value
    setValue(currValue)
    search(currValue)
  }

  const renderSearchContent = () => {
    if (value.length < 2) return <StartTyping />
    if (isSearching) return <SearchHint>Searching ...</SearchHint>
    if (invitableEmployees.length > 0) {
      return (
        <Results
          employeesWithEmail={invitableEmployees}
          chartUuid={chartUuid}
          loggedUserChartRole={loggedUserChartRole}
        />
      )
    }
    return <NoResults />
  }

  return (
    <>
      <FieldInput
        type='text'
        value={value}
        icon={<StyledSearchIcon />}
        optimized={false}
        placeholder='Search name, email address or job title'
        onClear={() => setValue('')}
        onChange={handleChange}
        data-testid='input-search-members'
      />

      {renderSearchContent()}
    </>
  )
}

const StyledSearchIcon = styled(SearchIcon)`
  width: 12px;
  height: auto;
  color: ${props => props.theme.colors.dark};
`
