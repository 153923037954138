export const isValidString = (val: unknown) => typeof val === 'string' || val instanceof String
export const isValidNonEmptyString = (val: unknown) => isValidString(val) && val !== ''
export const normalizeString = (str: string) => str.toLowerCase().normalize()

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const decapitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toLowerCase() + s.slice(1)
}

export function stripStr(str = '', maxLength = 20, useWordBoundary = true) {
  if (str.length <= maxLength) return str
  const subString = str.slice(0, maxLength - 1)
  return (useWordBoundary ? subString.slice(0, subString.lastIndexOf(' ')) : subString).trim().concat('…')
}

const accents = 'áäčďéěíĺľňóôőöŕšťúůűüýřžÁÄČĎÉĚÍĹĽŇÓÔŐÖŔŠŤÚŮŰÜÝŘŽ'
const accents_out = 'aacdeeillnoooorstuuuuyrzAACDEEILLNOOOORSTUUUUYRZ'
const accents_map = new Map()
accents.split('').forEach((_, i) => accents_map.set(accents.charCodeAt(i), accents_out.charCodeAt(i)))
export const removeAccents = (str: string) => {
  const nstr = new Array(str.length)
  let x
  for (let i = 0; i < nstr.length; i++) nstr[i] = accents_map.get((x = str.charCodeAt(i))) || x
  return String.fromCharCode.apply(null, nstr)
}

export const camelCaseToString = (str = '') =>
  str
    // add a space between lower and upper
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    // transform to lower case
    .toLocaleLowerCase()
    // uppercase the first character
    .replace(/^./, str => str.toUpperCase())
