export const lineOptions = {
  legend: {
    display: true,
    align: 'end',
    position: 'top',
    labels: {
      usePointStyle: true,
      pointRadius: 1,
    },
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          borderDash: [4, 7],
          highlightVerticalLine: true,
        },
      },
    ],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 6,
        },
      },
    ],
  },
  maintainAspectRatio: false,
}

export const lineDatasetAttrs = {
  fill: false,
  pointBorderWidth: 1,
  pointHoverRadius: 10,
  pointHoverBorderColor: 'white',
  pointHoverBorderWidth: 3,
  pointRadius: 1,
  pointHitRadius: 50,
}
