import styled from 'styled-components'

export const Sociomap = styled.img`
  position: absolute;
  bottom: 30px;
  left: calc(100% - 200px);
  z-index: 50;
  pointer-events: none;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    transform: translateX(-50%);
    left: 50%;
  }
`
