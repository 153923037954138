import { ReactNode } from 'react'

import {
  CompanyNodeStateProvider,
  ChartDataContextSafeProvider,
  ChartIdContextSafeProvider,
  ChartSidePanelDetailProvider,
  LoadingOverTreeProvider,
  NodesInViewportSafeProvider,
  TreeContextProvider,
  TreeStateProvider,
} from 'tree/providers'
import { ChartDetail } from 'tree/types'

type Props = {
  children: ReactNode
  chartData: ChartDetail
}

export const ChartProviders = ({ chartData, children }: Props) => (
  <LoadingOverTreeProvider>
    <ChartDataContextSafeProvider value={chartData}>
      <ChartIdContextSafeProvider value={chartData.id}>
        <NodesInViewportSafeProvider>
          <CompanyNodeStateProvider>
            <TreeStateProvider>
              <TreeContextProvider>
                <ChartSidePanelDetailProvider>{children}</ChartSidePanelDetailProvider>
              </TreeContextProvider>
            </TreeStateProvider>
          </CompanyNodeStateProvider>
        </NodesInViewportSafeProvider>
      </ChartIdContextSafeProvider>
    </ChartDataContextSafeProvider>
  </LoadingOverTreeProvider>
)
