const uninstallServiceWorkers = async () => {
  const registrations = await navigator.serviceWorker.getRegistrations()
  registrations.forEach(registration => registration.unregister())
}

const clearCache = async () => {
  const keyList = await caches.keys()
  const pList = keyList.map(key => caches.delete(key))
  return Promise.allSettled(pList)
}

export const hardPageReload = async () => {
  const p1 = uninstallServiceWorkers()
  const p2 = clearCache()
  await Promise.allSettled([p1, p2])
  window.location.reload()
}
