import { useRef, MutableRefObject, useCallback, ReactNode, useEffect } from 'react'

import { useChartId } from './use-chart-uuid'
import { ExpandedNode } from '../types'
import { topologicalSort, getSubgraphByDestinationNodes } from 'dag/dag.service'
import { createSafeContext } from 'helpers'
import { COMPANY_UUID } from 'consts'
import { sizes } from 'theme'

export type WatchState = {
  /** Use useWatchConnectionLines hook to get up-to date connection line values */
  onConnectionLinesChange?: never
}

export type TreeStateStored = {
  compact: boolean
  expandedNodes: ExpandedNode[]
  nodesInDom: string[]
  positionX: number
  positionY: number
  uuid: string
  zoom: number
} & WatchState

export type TreeState = TreeStateStored & WatchState

export type UseTreeStateResult = {
  treeStateRef: MutableRefObject<TreeState>
  getValidExpandedNodes: () => ExpandedNode[]
  replaceExpandedNodes: (expandedNodes: ExpandedNode[]) => void
  resetPosition: () => void
  setCompact: (compact: boolean) => void
  setExpandedNode: (node: ExpandedNode, expanded: boolean) => void
  setNodeInDom: (nodeId: string, isInDom: boolean) => void
  setPosition: (x: number, y: number) => void
  setZoom: (zoom: number) => void
}

export const [useTreeState, EmptyTreeStateProvider] = createSafeContext<UseTreeStateResult>()

const getStorageKey = (chartUuid = '') => `chart-state-${chartUuid}`
const getItem = (uuid: string): TreeState => {
  const key = getStorageKey(uuid)
  try {
    const item = window.localStorage.getItem(key)
    return { ...getInitTreeState(), ...(item && JSON.parse(item)) }
  } catch (error) {
    return { ...getInitTreeState() }
  }
}
const setItem = (uuid: string, state: TreeState) => {
  const key = getStorageKey(uuid)
  window.localStorage.setItem(key, JSON.stringify(state))
}

const INIT_EXPANDED_NODES = [{ uuid: null, parentUuid: null }]
const getInitPositionY = () => -((window.innerHeight - parseInt(sizes.topNavigation.split('px')[0], 10)) / 2)
const getInitTreeState = () => ({
  compact: false,
  expandedNodes: [...INIT_EXPANDED_NODES],
  nodesInDom: [],
  positionX: 0,
  positionY: getInitPositionY(),
  uuid: '',
  zoom: 1,
})

type Props = {
  children: ReactNode
}

export const TreeStateProvider = ({ children }: Props) => {
  const chartUuid = useChartId()
  const state = useRef<TreeState>(getItem(chartUuid))
  const setState = (newState: TreeState) => (state.current = newState)

  // Restore local storage state on chart uuid change
  // (ref is not re-initialized as this component doesn't unmount when you select another chart via chart select)
  useEffect(() => {
    setState(getItem(chartUuid))
  }, [chartUuid])

  const setTreeState = useCallback(
    (newValue: Partial<TreeState>) => {
      const newState = { ...state.current, ...newValue }
      setItem(chartUuid, newState)
      setState(newState)
    },
    [chartUuid, setState]
  )

  const resetPosition = useCallback(() => {
    const { positionX, positionY } = getInitTreeState()
    setTreeState({ positionX, positionY })
  }, [setTreeState])

  const setPosition = useCallback(
    (x: number, y: number) => setTreeState({ positionX: x, positionY: y }),
    [setTreeState]
  )

  const setZoom = useCallback((zoom: number) => setTreeState({ zoom }), [setTreeState])

  const setCompact = useCallback((compact: boolean) => setTreeState({ compact }), [setTreeState])

  const replaceExpandedNodes = useCallback(
    (expandedNodes: ExpandedNode[]) => {
      const newExpandedNodes = expandedNodes.length === 0 ? INIT_EXPANDED_NODES : expandedNodes
      setTreeState({ expandedNodes: newExpandedNodes })
    },
    [setTreeState]
  )

  const setExpandedNode = useCallback(
    (node: ExpandedNode, expanded: boolean) => {
      if (!state.current) return

      const isCompanyNode = node.uuid === COMPANY_UUID
      const nodeUuid = isCompanyNode ? null : node.uuid

      if (expanded) {
        const extractedNode: ExpandedNode = { uuid: nodeUuid, parentUuid: node.parentUuid }
        const isNodeUnique =
          state.current.expandedNodes.findIndex(expandedNode => expandedNode.uuid === extractedNode.uuid) === -1
        if (isNodeUnique) {
          // Add node to expanded array
          const expandedNodes = [...state.current.expandedNodes, extractedNode]
          setTreeState({ expandedNodes })
        }
      } else {
        // Remove node from expanded array
        const expandedNodes = [...state.current.expandedNodes.filter(node => node.uuid !== nodeUuid)]
        setTreeState({ expandedNodes })
      }
    },
    [setTreeState]
  )

  const setNodeInDom = useCallback(
    (nodeId: string, isInDom: boolean) => {
      const currNodesInDom = state.current.nodesInDom || []
      if (isInDom) {
        const isIncluded = currNodesInDom.includes(nodeId)
        if (!isIncluded) setTreeState({ nodesInDom: [...currNodesInDom, nodeId] })
      } else {
        const newNodesInDom = [...currNodesInDom].filter(id => id !== nodeId)
        setTreeState({ nodesInDom: newNodesInDom })
      }
    },
    [setTreeState]
  )

  const getValidExpandedNodes = useCallback(() => {
    const ROOT_NODE = 'root'
    const hasRootNode = state.current.expandedNodes.findIndex(({ uuid }) => uuid === null) > -1
    // Company node should be always expanded
    if (!hasRootNode) return INIT_EXPANDED_NODES

    const expandedNodesRootMap = state.current.expandedNodes.reduce<Record<string, string[]>>((map, node) => {
      const parentUuid = node.parentUuid || ROOT_NODE
      if (!map[parentUuid]) map[parentUuid] = []
      if (node.uuid) map[parentUuid].push(node.uuid)
      return map
    }, {})

    const nodeMap = getSubgraphByDestinationNodes([ROOT_NODE], expandedNodesRootMap)
    const validExpandedNodes: ExpandedNode[] = [...INIT_EXPANDED_NODES] // Company node should be always expanded
    const sortedNodes = topologicalSort(nodeMap, {})
    // Map uuid to ExpandedNode
    for (const nodeUuid of sortedNodes) {
      const node = state.current.expandedNodes.find(({ uuid }) => uuid === nodeUuid)
      if (node) validExpandedNodes.push(node)
    }

    return validExpandedNodes
  }, [])

  return (
    <EmptyTreeStateProvider
      value={{
        treeStateRef: state,
        getValidExpandedNodes,
        replaceExpandedNodes,
        resetPosition,
        setCompact,
        setExpandedNode,
        setNodeInDom,
        setPosition,
        setZoom,
      }}
    >
      {children}
    </EmptyTreeStateProvider>
  )
}
