import styled, { keyframes } from 'styled-components'

import { SVG, SVGProps } from './SVG'

type Props = SVGProps & {
  offset?: number
  duration?: string
}

export const SpinnerIcon = (props: Props) => {
  const { offset = 187, duration = '1.4s' } = props

  return (
    <StyledSVG width='65px' height='65px' viewBox='0 0 66 66' duration={duration} {...props}>
      <Circle
        fill='none'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        cx='33'
        cy='33'
        r='30'
        offset={offset}
        duration={duration}
      ></Circle>
    </StyledSVG>
  )
}

const rotator = keyframes`
  0% {
    transform: rotate(0deg);

  }
  100% {
    transform: rotate(270deg);
  }
`
const StyledSVG = styled(SVG)<{ duration: string }>`
  animation: ${rotator} ${props => props.duration} linear infinite;
`

const dash = (offset: number) => keyframes`
  0% {
    stroke-dashoffset: ${offset};
  }
  50% {
    stroke-dashoffset: ${offset / 4};
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: ${offset};
    transform: rotate(450deg);
  }
`
const Circle = styled.circle<{ offset: number; duration: string }>`
  stroke-dasharray: ${props => props.offset};
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${props => dash(props.offset)} ${props => props.duration} ease-in-out infinite;
`
