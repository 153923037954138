import styled from 'styled-components'

import { IconButton, ImageWithFallback } from 'components/generic'
import { CloseIcon, DepartmentIcon, ProfileInCircleIcon } from 'components/icons'

import { addTransparencyToColor, getNodeColorPallete } from 'helpers'
import { spaces } from 'theme'

type Props = {
  color?: string
  title: string
  onClose: () => void
  departmentCount?: number
  description?: string
  employeeCount?: number
  image?: string | null
}

export const NodeHeader = (props: Props) => {
  const { color, title, onClose, departmentCount, description, employeeCount, image } = props
  const colorPallete = getNodeColorPallete(color)

  return (
    <NodeHeaderInner background={colorPallete.gradient} color={addTransparencyToColor(colorPallete.textColor, 0.8)}>
      {image && <ImageWithFallback src={image} size='96px' spacing={{ mr: spaces.l }} />}

      <TextContainer>
        <Title color={colorPallete.textColor}>{title}</Title>
        <Description>{description}</Description>
        <CountContainer>
          <CountItem>
            <ProfileInCircleIcon />
            <Count>{employeeCount}</Count>
          </CountItem>
          <CountItem>
            <DepartmentIcon />
            <Count>{departmentCount}</Count>
          </CountItem>
        </CountContainer>
      </TextContainer>

      <StyledIconButton size='small' animation='opacity' onClick={onClose} data-testid='button-close-node-side-panel'>
        <CloseIcon />
      </StyledIconButton>
    </NodeHeaderInner>
  )
}

const NodeHeaderInner = styled.div<{ background: string; color: string }>`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.l};
  background: ${props => props.background};
  color: ${props => props.color};
  cursor: default;
`

const TextContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  word-break: break-all;
`

const Title = styled.div<{ color: string }>`
  color: ${props => props.color};
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
`

const Description = styled.div`
  margin-top: ${props => props.theme.spaces.xs};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

const CountContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: ${props => props.theme.spaces.l};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`

const CountItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${props => props.theme.spaces.m};
`

const Count = styled.div`
  margin-top: 2px;
  margin-left: ${props => props.theme.spaces.xs};
`

const StyledIconButton = styled(IconButton)`
  margin-top: -4px;
  margin-right: -4px;
  margin-left: auto;
`
