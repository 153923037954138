import { ChangeEvent, FocusEvent, ReactNode } from 'react'
import styled from 'styled-components'

import { FlexBox, SelectButton } from 'components/generic'

type Props = {
  children: ReactNode
  checked: boolean
  name: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
}

export const SelectBoxButton = ({ name, checked, children, onChange, onFocus, onBlur }: Props) => (
  <label>
    <Box $isYCentered>
      <ButtonArea>
        <SelectButton
          name={name}
          checked={checked}
          type='radio'
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
        />
      </ButtonArea>

      {children}
    </Box>
  </label>
)

const Box = styled(FlexBox)`
  height: auto;
  min-height: 72px;
  display: flex;
  background: ${props => props.theme.colors.white};
  border-radius: 6px;
  border: 1px solid ${props => props.theme.colors.greyActive};
  cursor: pointer;
`

const ButtonArea = styled.div`
  align-self: stretch;
  width: 44px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${props => props.theme.spaces.l};
  border-radius: 6px 0px 0px 6px;
  background: rgba(178, 179, 192, 0.3);
`
