import { gql } from '@apollo/client'

export const personChildDepartmentsDataFragment = gql`
  fragment PersonChildDepartmentsDataFragment on Person {
    uuid
    id
    childDepartments {
      ... on Department {
        uuid
        id
        name
        color
        parentUuid
        expanded @client
      }
    }
    expanded @client
  }
`
