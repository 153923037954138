import { useCallback } from 'react'

import { shouldUpdateTree } from './should-update-tree'
import { useUpdateTree, useRefetchPersonTeamSize, useUpdateMeOnChart, personMapper } from 'tree/hooks'
import { useChartId, useChartData, useChartConfig } from 'tree/providers'
import { PersonFormSubmitValues } from 'tree/types'
import { findSuperiorPerson } from 'helpers'
import { useAuth } from 'auth'

import { node as nodeQuery } from 'apollo/query'
import {
  NodeDataFragment_Department_Fragment,
  NodeDataFragment_Person_Fragment,
  UpdateDepartmentInput,
  UpdateDepartmentMutation,
  UpdateDepartmentMutationResult,
  UpdatePersonMutation,
  UpdatePersonMutationResult,
  useUpdateDepartmentMutation,
  useUpdatePersonMutation,
} from 'apollo/generated/graphql'

type UpdateDepartmentRequestParams = {
  node: NodeDataFragment_Department_Fragment
  nodeTypename: 'Department'
  values: UpdateDepartmentInput
}

type UpdatePersonRequestParams = {
  node: NodeDataFragment_Person_Fragment
  nodeTypename: 'Person'
  values: PersonFormSubmitValues
}

type UpdateNodeRequestParams = UpdateDepartmentRequestParams | UpdatePersonRequestParams

type NodesState = {
  previousNode: NodeDataFragment_Department_Fragment | NodeDataFragment_Person_Fragment
  currentNode: UpdateDepartmentMutation['updateDepartment'] | UpdatePersonMutation['updatePerson']
}

export const useUpdateNodeRequest = () => {
  const chartUuid = useChartId()
  const chartData = useChartData()
  const { user } = useAuth()
  const { updateTree } = useUpdateTree()
  const [updateDepartmentMutation] = useUpdateDepartmentMutation()
  const [updatePersonMutation] = useUpdatePersonMutation()
  const { isGsuiteProvider, refetchPersonTeamSize } = useRefetchPersonTeamSize()
  const { updateMeOnChart } = useUpdateMeOnChart()
  const { config: chartConfig } = useChartConfig()

  const refetchTeamSizeOnSuperiorPerson = async ({ previousNode, currentNode }: NodesState) => {
    const previousSuperiorPerson = findSuperiorPerson(previousNode)
    await refetchPersonTeamSize(previousSuperiorPerson?.id)
    const currentSuperiorPerson = currentNode && findSuperiorPerson(currentNode)
    await refetchPersonTeamSize(currentSuperiorPerson?.id)
  }

  const updateDepartment = useCallback(
    (node: NodeDataFragment_Department_Fragment, values: UpdateDepartmentInput) => {
      return updateDepartmentMutation({
        update: (cache, { data }: Partial<UpdateDepartmentMutationResult>) => {
          const updatedDepartment = data?.updateDepartment
          if (updatedDepartment && shouldUpdateTree(node, values)) {
            updateTree({
              cache,
              mutationData: updatedDepartment,
              prevParentUuid: node.parentUuid,
              nextParentUuid: updatedDepartment.parentUuid,
              prevData: node,
            })
          }

          // Refetch teamSize
          const hasDifferentSuperiorNode = node.parentUuid !== values.parentUuid
          if (isGsuiteProvider && hasDifferentSuperiorNode) {
            refetchTeamSizeOnSuperiorPerson({ previousNode: node, currentNode: updatedDepartment })
          }
        },
        variables: { chartUuid, uuid: node.uuid, data: values },
        refetchQueries: [{ query: nodeQuery, variables: { uuid: node.uuid, chartKey: chartUuid } }],
      })
    },
    [chartUuid, updateDepartmentMutation, shouldUpdateTree, updateTree, refetchTeamSizeOnSuperiorPerson]
  )

  const updatePerson = useCallback(
    (node: NodeDataFragment_Person_Fragment, values: PersonFormSubmitValues) => {
      return updatePersonMutation({
        update: async (cache, { data }: Partial<UpdatePersonMutationResult>) => {
          const updatedPerson = data?.updatePerson
          if (updatedPerson && shouldUpdateTree(node, values)) {
            updateTree({
              cache,
              mutationData: updatedPerson,
              prevParentUuid: node.parentUuid,
              nextParentUuid: updatedPerson.parentUuid,
              prevData: node,
            })
          }

          // Refetch teamSize
          const hasDifferentSuperiorNode = node.parentUuid !== values.parentUuid
          if (isGsuiteProvider && hasDifferentSuperiorNode) {
            await refetchTeamSizeOnSuperiorPerson({ previousNode: node, currentNode: updatedPerson })
          }

          // Update "Me" on "Chart" fragment
          const previousEmail = node.email?.toLocaleLowerCase()
          const currentEmail = updatedPerson?.email?.toLocaleLowerCase()
          if (previousEmail !== currentEmail) {
            const authEmail = user?.email?.toLocaleLowerCase()
            if (updatedPerson && currentEmail === authEmail) {
              const { id, email, teamSize, __typename } = personMapper({
                authEmail,
                chart: chartData,
                chartConfig,
                person: updatedPerson,
              })
              updateMeOnChart({ id, teamSize, email, __typename }, cache) // Add person to "me" on "Chart"
            } else if (previousEmail === authEmail) {
              updateMeOnChart(null, cache) // Removes "me" on "Chart"
            }
          }
        },
        variables: { chartUuid, uuid: node.uuid, data: values },
        refetchQueries: [{ query: nodeQuery, variables: { uuid: node.uuid, chartKey: chartUuid } }],
      })
    },
    [chartUuid, updatePersonMutation, shouldUpdateTree, updateTree, refetchTeamSizeOnSuperiorPerson]
  )

  const updateNodeRequest = useCallback(
    (params: UpdateNodeRequestParams) => {
      return params.nodeTypename === 'Person'
        ? updatePerson(params.node, params.values)
        : updateDepartment(params.node, params.values)
    },
    [updatePerson, updateDepartment]
  )

  return { updateNodeRequest }
}
