import { useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { DeleteButton, tableComponents } from '../../components'
import { Card } from '../../../components'
import { ConfirmationModalType } from 'components/specific'
import { EyeCrossedIcon, EyeIcon } from 'components/icons'

import { useChartId } from 'tree/providers'
import { useChartPermission } from 'tree/hooks'
import { CustomField, CustomFieldWithoutConnection } from 'tree/types'
import { SETTINGS_EDIT_CUSTOM_FIELD_PATH } from 'routes'
import { useModal } from 'modal'
import { stripStr, pluralize } from 'helpers'
import analytics from 'analytics'
import { CUSTOM_FIELDS_TRACK } from 'analytics/constants'

import { chart as chartQuery } from 'apollo/query'
import {
  useDeleteTextFieldMutation,
  useDeleteDropdownFieldMutation,
  useDeleteDateFieldMutation,
} from 'apollo/generated/graphql'

type DeleteFieldParams = {
  id: string
  type: CustomField['type']
  label: string
}

type Props = {
  customFieldsWithoutConnection: CustomFieldWithoutConnection[]
}

export const Detail = ({ customFieldsWithoutConnection }: Props) => {
  const chartUuid = useChartId()
  const history = useHistory()
  const { open } = useModal()
  const { canDeleteCustomField, canReadCustomField, canUpdateCustomField } = useChartPermission()
  const [isDeleteHovered, setIsDeleteHovered] = useState(false)

  const [deleteTextFieldMutation] = useDeleteTextFieldMutation()
  const [deleteDropdownFieldMutation] = useDeleteDropdownFieldMutation()
  const [deleteDateFieldMutation] = useDeleteDateFieldMutation()

  const handleDeleteField = async ({ id, label, type }: DeleteFieldParams) => {
    const options = {
      variables: { uuid: id, chartUuid },
      refetchQueries: [{ query: chartQuery, variables: { key: chartUuid } }],
    }
    try {
      if (type === 'text') await deleteTextFieldMutation(options)
      if (type === 'single-select') await deleteDropdownFieldMutation(options)
      if (type === 'single-date') await deleteDateFieldMutation(options)
      toast(`Custom field deleted.`, { type: 'success' })
      analytics.track(CUSTOM_FIELDS_TRACK.delete, {
        chartUuid,
        fieldType: type,
        fieldLabel: label,
      })
    } catch (error) {
      toast(`Failed to delete custom field. Please refresh the page and try again.`, { type: 'error' })
    }
  }

  const handleConfirmDeleteField = (deleteParams: DeleteFieldParams) => {
    open<ConfirmationModalType>('confirmationModal', {
      title: { text: 'Delete custom field' },
      message:
        'All values assigned to this custom field will be permanently deleted from your employees. This cannot be undone.',
      confirmButton: { buttonType: 'danger', children: 'Delete' },
      onConfirm: () => handleDeleteField(deleteParams),
    })
  }

  return (
    <Card title='Fields' noCardPadding>
      {canReadCustomField &&
        customFieldsWithoutConnection.map(customField => {
          const { id, label, typeLabel, ...field } = customField

          return (
            <tableComponents.Row
              key={id}
              onClick={() => {
                if (canUpdateCustomField) {
                  history.push(generatePath(SETTINGS_EDIT_CUSTOM_FIELD_PATH, { chartUuid, customFieldId: id }))
                }
              }}
              $isDisabled={isDeleteHovered || !canUpdateCustomField}
            >
              <tableComponents.LabelCell>{stripStr(label, 50)}</tableComponents.LabelCell>

              <tableComponents.TypeCell>{typeLabel}</tableComponents.TypeCell>

              {field.type === 'single-select' && (
                <>
                  <tableComponents.OptionsCell>
                    {field.options.length} {pluralize('option', field.options.length)}
                  </tableComponents.OptionsCell>

                  <tableComponents.Cell>
                    <tableComponents.IconContainer>
                      {field.displayIconsOnChartNode ? (
                        <EyeIcon width='17px' height='100%' />
                      ) : (
                        <EyeCrossedIcon width='17px' height='100%' />
                      )}
                    </tableComponents.IconContainer>
                    {field.displayIconsOnChartNode ? 'Visible on cards' : ' Hidden on cards'}
                  </tableComponents.Cell>
                </>
              )}

              {canDeleteCustomField && (
                <DeleteButton
                  title='Delete field'
                  onMouseEnter={() => setIsDeleteHovered(true)}
                  onMouseLeave={() => setIsDeleteHovered(false)}
                  onClick={e => {
                    e.stopPropagation()
                    handleConfirmDeleteField({ id, type: field.type, label })
                  }}
                />
              )}
            </tableComponents.Row>
          )
        })}
    </Card>
  )
}
