import { SVG, SVGProps } from './SVG'

export const DollarIcon = (props: SVGProps) => (
  <SVG width='96' height='81' viewBox='0 0 96 81' fill='none' {...props}>
    <path
      d='M20.1818 0H111V60.75H20.1818V0ZM65.5909 15.1875C69.6053 15.1875 73.4553 16.7876 76.2939 19.6358C79.1326 22.484 80.7273 26.347 80.7273 30.375C80.7273 34.403 79.1326 38.266 76.2939 41.1142C73.4553 43.9624 69.6053 45.5625 65.5909 45.5625C61.5765 45.5625 57.7265 43.9624 54.8879 41.1142C52.0493 38.266 50.4545 34.403 50.4545 30.375C50.4545 26.347 52.0493 22.484 54.8879 19.6358C57.7265 16.7876 61.5765 15.1875 65.5909 15.1875ZM40.3636 10.125C40.3636 12.8103 39.3005 15.3856 37.4081 17.2845C35.5157 19.1833 32.949 20.25 30.2727 20.25V40.5C32.949 40.5 35.5157 41.5667 37.4081 43.4655C39.3005 45.3644 40.3636 47.9397 40.3636 50.625H90.8182C90.8182 47.9397 91.8813 45.3644 93.7737 43.4655C95.6662 41.5667 98.2328 40.5 100.909 40.5V20.25C98.2328 20.25 95.6662 19.1833 93.7737 17.2845C91.8813 15.3856 90.8182 12.8103 90.8182 10.125H40.3636ZM0 20.25H10.0909V70.875H90.8182V81H0V20.25Z'
      fill='currentColor'
      fillOpacity='0.4'
    />
  </SVG>
)
