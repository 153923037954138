import styled from 'styled-components'

import { GoogleIcon, MicrosoftIcon, TilAnalyticsIcon, WebexIcon, ZoomIcon } from 'components/icons'
import { Flag, TilButton } from 'components/generic'

import { UNARIC_URL } from 'consts'

import GoogleWorkspaceMarketplaceLogo from 'assets/google-workspace-marketplace-logo.png'
import TilAnalyticsBannerLogo from 'assets/til-analytics-banner-2.png'

export const TilAnalytics = () => (
  <Container>
    <LeftWrapper>
      <Heading>
        <StyledTilAnalyticsIcon />
        <StyledFreeToolFlag>FREE TOOL</StyledFreeToolFlag>
      </Heading>

      <LeftText>
        Get started with Workspace Insights today: A practical, easy way to get free analytics about Google Workspace.
        Sign up for free!
      </LeftText>
      <StyledTilButton href={UNARIC_URL}>Get started with Workplace</StyledTilButton>
      <MarketPlaceAdContainer>
        <MarketPlaceAd>
          <Text>Available also on</Text>
          <a href={UNARIC_URL} target='blank'>
            <img src={GoogleWorkspaceMarketplaceLogo} />
          </a>
        </MarketPlaceAd>
      </MarketPlaceAdContainer>
    </LeftWrapper>
    <RightWrapper>
      <TilAnalyticsBanner src={TilAnalyticsBannerLogo} />
      <AnalyzeText>You can select which source you want to analyze</AnalyzeText>
      <CardsContainer>
        <Card href={UNARIC_URL}>
          <SourceIconWrapper>
            <GoogleIcon />
          </SourceIconWrapper>
          <SourceName>Google Workspace</SourceName>
        </Card>

        <Card href={UNARIC_URL}>
          <SourceIconWrapper>
            <MicrosoftIcon />
          </SourceIconWrapper>
          <SourceName>Microsoft 365</SourceName>
        </Card>

        <Card href={UNARIC_URL}>
          <SourceIconWrapper>
            <ZoomIcon />
          </SourceIconWrapper>
          <SourceName>Zoom</SourceName>
        </Card>

        <Card>
          <SourceIconWrapper>
            <WebexIcon />
          </SourceIconWrapper>
          <SourceName>
            Webex Teams<StyledComingSoonFlag>COMING SOON</StyledComingSoonFlag>
          </SourceName>
        </Card>
      </CardsContainer>
    </RightWrapper>
  </Container>
)

const Container = styled.div`
  display: flex;
  min-height: calc(100vh - ${props => props.theme.sizes.topNavigation});

  @media (max-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    flex-direction: column;
  }
`

const Heading = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${props => props.theme.deviceBreakpoints.tinyMobile}) {
    flex-direction: column;
  }

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    justify-content: center;
  }
`

const StyledTilAnalyticsIcon = styled(TilAnalyticsIcon)`
  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    height: 22px;
    width: auto;
  }
`

const StyledTilButton = styled(TilButton)`
  align-self: flex-start;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    width: 100%;
    max-width: 340px;
    margin: 0 auto;
  }
`

const StyledFreeToolFlag = styled(Flag)`
  margin-left: ${props => props.theme.spaces.m};
  min-width: 62px;
`

const StyledComingSoonFlag = styled(Flag)`
  @media (max-width: ${props => props.theme.deviceBreakpoints.laptop}) {
    text-align: center;
    padding: 4px 8px;
    line-height: 10px;
    margin-right: 16px;
  }
`

const LeftWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  background: ${props => props.theme.colors.white};
  padding: 55px;
  padding-top: calc(79px + 55px);
  padding-bottom: calc(16px + 55px);
  flex: 5;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    padding: 4em ${props => props.theme.spaces.l};
  }
`

const Text = styled.div`
  font-size: 13px;
  line-height: 18px;
  color: ${props => props.theme.colors.greyMediumLight};
  margin-right: ${props => props.theme.spaces.m};

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    margin-right: 0;
  }
`

const AnalyzeText = styled(Text)`
  font-weight: 500;
  font-size: 18px;
  margin-top: 100px;
  margin-bottom: 32px;
  color: ${props => props.theme.colors.dark};

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    text-align: center;
  }
`

const LeftText = styled(Text)`
  margin-top: 35px;
  margin-bottom: 40px;
  max-width: 384px;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    max-width: 340px;
    text-align: center;
  }
`

const MarketPlaceAdContainer = styled.div`
  display: flex;
  flex: 1;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    justify-content: center;
  }

  @media (max-width: ${props => props.theme.deviceBreakpoints.bigTablet}) {
    margin-top: ${props => props.theme.spaces.xl};
  }
`

const MarketPlaceAd = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-end;

  @media (max-width: ${props => props.theme.deviceBreakpoints.tinyMobile}) {
    flex-direction: column;
  }
`

const RightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 7;
  margin-top: 85px;
  margin-bottom: 70px;
  padding-left: 48px;
  padding-right: 30px;

  @media (max-width: ${props => props.theme.deviceBreakpoints.tinyMobile}) {
    padding: 0;
  }

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    margin-top: 40px;
  }
`

const TilAnalyticsBanner = styled.img`
  display: flex;
  align-self: center;
  max-width: 80%;
  margin: 0 30px;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    max-width: 100%;
    margin: 0;
  }
`

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-width: 1024px;
`

const Card = styled.a`
  display: flex;
  align-items: center;
  height: 108px;
  width: 100%;
  padding-left: 36px;
  background: ${props => props.theme.colors.white};
  border-radius: 5px;
  box-shadow: rgb(69 90 100 / 5%) 0px 3px 6px;
  flex: 0 1 calc(50% - 1em);
  flex-basis: calc(50% - 1em);
  max-width: 400px;
  margin: ${props => props.theme.spaces.m};
  transition: transform ${props => props.theme.transitions.mediumEase};

  &[href]:hover {
    transform: translateY(-4px);
  }

  @media (max-width: ${props => props.theme.deviceBreakpoints.laptop}) {
    flex: 0 1 calc(100%);
    margin: ${props => props.theme.spaces.m} 0;
  }
`

const SourceIconWrapper = styled.div`
  transform: scale(1.5);
  margin-right: calc(${props => props.theme.spaces.l} + ${props => props.theme.spaces.l} / 2);
`

const SourceName = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
`
