import styled, { css } from 'styled-components'
import CountUp from 'react-countup'

import { ArrowDownIcon, ArrowUpIcon } from 'components/icons'

type Props = {
  value?: number
}

export const TrendValue = ({ value }: Props) => (
  <Container hasGrowingTrend={value ? value > 0 : undefined}>
    <CountUp end={value ?? 0} duration={1} suffix='%' preserveValue />
    <ArrowContainer>{value ? value > 0 ? <ArrowUpIcon /> : <ArrowDownIcon /> : <></>}</ArrowContainer>
  </Container>
)

const ArrowContainer = styled.div`
  height: 20px;
  width: 20px;
`

const Container = styled.div<{ hasGrowingTrend?: boolean }>`
  display: flex;
  font-weight: 500;
  font-size: 16px;
  color: #a00067;
  align-items: center;
  align-self: flex-end;

  ${props =>
    props.hasGrowingTrend &&
    css`
      color: #4d009d;
    `};
`
