import { gql } from '@apollo/client'
import { collaboratorFragment } from '../fragments'

export const getInvitation = gql`
  query getInvitation($code: String!) {
    getInvitation(code: $code) {
      ...CollaboratorFragment
    }
  }
  ${collaboratorFragment}
`
