import styled, { css } from 'styled-components'

export const TableRow = styled.div<{ isHeader?: boolean; isHighlighted?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 28px;
  padding: 10px 0;
  border-bottom: 1px solid #e6e7ed;
  line-height: 18px;

  &:last-child {
    border-bottom: none;
  }

  ${props =>
    props.isHeader &&
    css`
      width: calc(100% - 50px);
      height: 16px;
      margin: 0 25px;
      border-bottom: 1px solid #d0d0d0;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      color: #83869c;
      & > div {
        cursor: pointer;
        user-select: none;
      }
    `};

  ${props =>
    props.isHighlighted &&
    css`
      font-weight: bold;
    `};

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    ${props =>
      props.isHeader &&
      css`
        margin: 0 ${props => props.theme.spaces.m};
        width: calc(100% - (${props => props.theme.spaces.m} * 2));
      `};
  }
`
