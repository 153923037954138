/** Time is in milliseconds. Usage example:
 *
 * await wait(5000) */
export const wait = async (time: number): Promise<void> => {
  return new Promise(resolve => {
    if (time <= 0) {
      return resolve()
    }
    setTimeout(resolve, time)
  })
}
