import styled, { keyframes } from 'styled-components'

import { OrgchartFigure } from 'components/icons'
import { colors } from 'theme'

type Size = 'small' | 'regular' | 'medium' | 'big'

type LoadingProps = {
  className?: string
  size?: Size
}

export const Loading = ({ className, size = 'regular' }: LoadingProps) => (
  <Container className={className}>
    <FigureBox size={size}>
      <FirstOrgchartFigure />
    </FigureBox>
    <FigureBox size={size}>
      <SecondOrgchartFigure />
    </FigureBox>
  </Container>
)

const getSize = (size: Size) => {
  switch (size) {
    case 'small':
      return '12px'
    case 'medium':
      return '22px'
    case 'big':
      return '48px'
    default:
      return '17px'
  }
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colors.dark};
`

const FigureBox = styled.div<{ size: Size }>`
  min-width: ${props => getSize(props.size)};
  width: ${props => getSize(props.size)};
  max-width: ${props => getSize(props.size)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: inherit;
`

const scaleKeyframes = keyframes`
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(0.5);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
`

const FirstOrgchartFigure = styled(OrgchartFigure)`
  width: 100%;
  height: auto;
  color: inherit;
  animation: 1.6s ${scaleKeyframes} cubic-bezier(0.45, 0, 0.55, 1) infinite;
`

const SecondOrgchartFigure = styled(FirstOrgchartFigure)`
  animation-delay: 0.8s;
`
