type PayloadMapperFactoryOptions = {
  maxStrLength: number
  maxStrGroupCount: number
}

export const payloadMapperFactory = (options: PayloadMapperFactoryOptions) => {
  const { maxStrLength, maxStrGroupCount } = options

  return (payload: Record<string, unknown> | undefined) => {
    if (!payload) {
      return
    }

    return Object.keys(payload).reduce<Record<string, unknown>>((obj, key) => {
      const value = payload[key]
      if (typeof value === 'string' && value.length > maxStrLength) {
        const groups = value.match(new RegExp(`.{1,${maxStrLength}}`, 'g')) || []
        const j = Math.min(groups.length, maxStrGroupCount)

        for (let i = 0; i < j; i++) {
          const newKey = i === 0 ? key : `${key}__${i}`
          obj[newKey] = groups[i]
        }
      } else {
        obj[key] = value
      }

      return obj
    }, {})
  }
}
