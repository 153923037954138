import { useState, useEffect } from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { ContactLink, OverviewButton } from './components'
import { Button, Loading } from 'components/generic'
import { OrgchartFullLogo } from 'components/icons'

import { handleErrorValidation, formErrorHandler } from 'helpers'
import analytics from 'analytics'
import { ERRORS_TRACK, SHARING_TRACK } from 'analytics/constants'
import { useAuth } from 'auth'
import { CHARTS_PATH, CHART_EDIT_PATH, INVITE_PATH } from 'routes'

import {
  useAcceptInvitationMutation,
  useResendInvitationMutation,
  useGetInvitationQuery,
} from 'apollo/generated/graphql'

type InvitationProgress = 'loading' | 'expired' | 'not-found' | 'error' | 'wrong-email'
type ResendProgress = 'toBeSend' | 'success' | 'error'

export const InviteScreen = () => {
  const { code } = useParams<{ code: string }>()
  const { replace } = useHistory()
  const { data: invitationData } = useGetInvitationQuery({ variables: { code } })
  const { user, loginWithRedirect, login } = useAuth()

  const [invitationProgress, setInvitationProgress] = useState<InvitationProgress>('loading')
  const [resendProgress, setResendProgress] = useState<ResendProgress>('toBeSend')

  const [acceptInvitation] = useAcceptInvitationMutation()
  const [resendInvitation] = useResendInvitationMutation()

  const redirectToOverview = () => replace(CHARTS_PATH)

  const handleAcceptInvitation = () => {
    acceptInvitation({ variables: { code } })
      .then(({ data }) => {
        const acceptedInvitation = data?.acceptInvitation
        if (!acceptedInvitation) {
          setInvitationProgress('not-found')
          return
        }
        replace(generatePath(CHART_EDIT_PATH, { chartUuid: acceptedInvitation.chartUuid }))
      })
      .catch(error => {
        const errorCode = formErrorHandler(error)

        if (errorCode === 'INVITATION_EXPIRED') {
          setInvitationProgress('expired')
          return
        } else if (errorCode === 'INVITATION_WRONG_EMAIL') {
          setInvitationProgress('wrong-email')
          return
        } else if (errorCode === 'UNAUTHENTICATED') {
          login({ redirectPath: generatePath(INVITE_PATH, { code }) })
          return
        }

        analytics.track(ERRORS_TRACK.invitationAcceptError, { email: user?.email, name: user?.name, error })
        setInvitationProgress('error')
      })
  }

  const handleResendInvitation = () => {
    resendInvitation({ variables: { code } })
      .then(() => setResendProgress('success'))
      .catch(error => {
        handleErrorValidation({
          track: { message: SHARING_TRACK.invitationResendFailure, values: { code, error } },
        })
        setResendProgress('error')
      })
  }

  useEffect(() => {
    handleAcceptInvitation()
  }, [])

  switch (invitationProgress) {
    case 'expired':
      return (
        <Content>
          <StyledOrgchartFullLogo onClick={redirectToOverview} />
          <Heading>Link expired</Heading>
          <Description>
            Invitation links are only valid for 48 hours. Don&apos;t worry - you can request a new one now.
          </Description>
          <ButtonsWrap>
            {resendProgress === 'toBeSend' && (
              <ResendStatus>
                <Button onClick={handleResendInvitation}>Send new link</Button>
              </ResendStatus>
            )}
            {resendProgress === 'success' && (
              <ResendStatus>
                <Description>Successfully sent. Please check your email for a new invitation.</Description>
              </ResendStatus>
            )}
            {resendProgress === 'error' && (
              <ResendStatus>
                <Description>
                  Failed to send invitation. Please try again in a moment or <ContactLink />.
                </Description>
              </ResendStatus>
            )}
          </ButtonsWrap>
        </Content>
      )
    case 'wrong-email':
      return (
        <Content>
          <StyledOrgchartFullLogo onClick={redirectToOverview} />
          <Heading>Account incompatible</Heading>
          <Description>
            The invite was sent to {invitationData?.getInvitation?.email}. Please sign in (or create a new account)
            using this email address.
          </Description>
          <ButtonsWrap>
            <Button onClick={() => loginWithRedirect({ redirectPath: generatePath(INVITE_PATH, { code }) })}>
              Sign in with different account
            </Button>
            <OverviewButton onClick={redirectToOverview} />
          </ButtonsWrap>
        </Content>
      )
    case 'not-found':
      return (
        <Content>
          <StyledOrgchartFullLogo onClick={redirectToOverview} />
          <Heading>Invite expired</Heading>
          <Description>
            Your invitation has expired. You are either already a member of this Org Chart or the admin has revoked your
            access. Please contact the administrator for more details.
          </Description>
          <ButtonsWrap>
            <OverviewButton onClick={redirectToOverview} />
          </ButtonsWrap>
        </Content>
      )
    case 'error':
      return (
        <Content>
          <StyledOrgchartFullLogo onClick={redirectToOverview} />
          <Heading>Oops! Something went wrong</Heading>
          <Description>
            Please accept the invitation again. If the problem persists, <ContactLink />.
          </Description>
          <ButtonsWrap>
            <Button onClick={() => window.location.reload()}>Try again</Button>
            <OverviewButton onClick={redirectToOverview} />
          </ButtonsWrap>
        </Content>
      )
    default:
      return (
        <Content>
          <Loading size='big' />
        </Content>
      )
  }
}

const fadeInCSS = css`
  animation: ${props => props.theme.keyframes.opacity} ${props => props.theme.transitions.slowEase};
`

const Content = styled.div`
  ${fadeInCSS}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  text-align: center;
`

const StyledOrgchartFullLogo = styled(OrgchartFullLogo)<{
  onClick: () => void
}>`
  width: 200px;
  height: auto;
  margin-bottom: 60px;
  color: ${props => props.theme.colors.dark};
  cursor: pointer;
`

const Heading = styled.div`
  max-width: 447px;
  margin-bottom: 10px;
  color: ${props => props.theme.colors.dark};
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
`

const descriptionFontCSS = css`
  color: ${props => props.theme.colors.dark};
  font-size: 13px;
  line-height: 20px;
`
const Description = styled.div`
  ${descriptionFontCSS}
  max-width: 347px;
`

const ResendStatus = styled.div`
  ${fadeInCSS}
  ${descriptionFontCSS}
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`
