import { ReactNode, useRef } from 'react'
import styled, { css } from 'styled-components'

import { dropdownMenu } from 'helpers'
import { useEscapeKeypressListener, useOnClickOutside } from 'hooks'

export type ModalWindowSize = 'small' | 'medium' | 'big'
type ModalWindowProps = {
  children: ReactNode
  className?: string
  size?: ModalWindowSize
  onClose: () => void
}

export const ModalWindow = ({ className, size, children, onClose }: ModalWindowProps) => {
  const modalWindow = useRef<HTMLInputElement>(null)
  useOnClickOutside(modalWindow, () => onClose(), [dropdownMenu])
  useEscapeKeypressListener(onClose)

  return (
    <Window className={className} size={size} ref={modalWindow}>
      {children}
    </Window>
  )
}

const Window = styled.div<{ size?: ModalWindowSize }>`
  position: relative;
  width: 100%;
  max-width: 640px;
  max-height: 100%;
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.l};
  border-radius: 3px;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 2px 3px rgba(179, 185, 207, 0.5);
  overflow: auto;
  animation: ${props => props.theme.keyframes.scale} ${props => props.theme.transitions.fastEase};
  z-index: 20;

  ${props =>
    props.size === 'small' &&
    css`
      max-width: 480px;
    `}

  ${props =>
    props.size === 'big' &&
    css`
      max-width: 800px;
    `}
`
