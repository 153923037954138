import { SVG, SVGProps } from './SVG'

export const TableIcon = (props: SVGProps) => (
  <SVG width='64' height='56' viewBox='0 0 64 56' fill='none' {...props}>
    <path
      d='M8 28V16H28V28H8ZM8 36H28V48H8V36ZM36 48V36H56V48H36ZM56 28H36V16H56V28ZM8 0C3.5875 0 0 3.5875 0 8V48C0 52.4125 3.5875 56 8 56H56C60.4125 56 64 52.4125 64 48V8C64 3.5875 60.4125 0 56 0H8Z'
      fill='currentColor'
    />
  </SVG>
)
