import { CustomFieldPreview } from '../../components'
import { Card, SelectBoxButton } from '../../../components'
import { DottedLinePreview } from 'components/specific'
import { FlexBox } from 'components/generic'

import { CONNECTION_LINE_OPTIONS } from 'tree/constants'
import { CustomConnectionLineStyle } from 'tree/types'
import { colors } from 'theme'

type Props = {
  title: string
  selectedLineStyle: CustomConnectionLineStyle | undefined
  onChange: (lineStyle: CustomConnectionLineStyle) => void
}

export const SelectType = ({ title, selectedLineStyle, onChange }: Props) => (
  <Card title={title}>
    <FlexBox $gap='l' $isDirectionColumn>
      {CONNECTION_LINE_OPTIONS.map(o => (
        <SelectBoxButton
          key={o.lineStyle}
          name={o.lineStyle}
          checked={selectedLineStyle === o.lineStyle}
          onChange={() => onChange(o.lineStyle)}
        >
          <CustomFieldPreview
            icon={<DottedLinePreview $lineColor={colors.white} $lineStyle={o.lineStyle} $borderWidth='4px' />}
            squareColor={colors.greyMedium}
            title={o.lineLabel}
          />
        </SelectBoxButton>
      ))}
    </FlexBox>
  </Card>
)
