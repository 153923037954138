import { ChangeEvent } from 'react'
import styled, { css } from 'styled-components'

import { Input } from './input/input'
import { CheckIcon } from 'components/icons'

type Props = {
  checked: boolean
  name: string
  textColor: string
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  className?: string
}

export const ColorSelectRadio = ({ checked, name, textColor, value, onChange, className }: Props) => (
  <ColorSelectRadioInner className={className} value={value} $isChecked={checked}>
    <IconContainer $isChecked={checked} $textColor={textColor}>
      <CheckIcon />
    </IconContainer>
    <Input type='radio' name={name} value={value} hidden onChange={onChange} />
  </ColorSelectRadioInner>
)

const checkedRadioCSS = css`
  cursor: default;
  pointer-events: none;
`

const ColorSelectRadioInner = styled.label<{ $isChecked: boolean; value: string }>`
  cursor: pointer;
  position: relative;
  min-width: 36px;
  min-height: 36px;
  max-width: 36px;
  max-height: 36px;
  border-radius: 50%;
  background: ${props => props.value};
  transition: ${props => props.theme.transitions.extraFastEase};

  &:hover {
    transform: scale(1.1);
  }

  ${props => props.$isChecked && checkedRadioCSS}
`

const IconContainer = styled.div<{ $isChecked: boolean; $textColor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${props => (props.$isChecked ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-top: 1px;
  color: ${props => props.$textColor};
  animation: ${props => props.theme.keyframes.opacity} ${props => props.theme.transitions.fastEase};
`
