import { gql } from '@apollo/client'
import { chartCapabilitiesFragment } from './chart-capabilities-fragment'
import { customFieldFragment } from './custom-field-fragment'
import { subscriptionInfoFragment } from './subscription-info-fragment'

export const chartDetailFragment = gql`
  fragment ChartDetailFragment on Chart {
    id
    uuid
    name
    emoji
    logo
    lock {
      active
      from
    }
    providerInfo {
      name
      syncedFields {
        name
        readonly
      }
      scopes
      capabilities {
        canReadUser
        canUpdateUser
        canReadCalendar
      }
    }
    public
    adminEmail
    customFields {
      ...CustomFieldFragment
    }
    employeeCount
    departmentCount
    unassignedEmployeeCount
    unassignedDepartmentCount
    subscription {
      ...SubscriptionInfo
    }
    userPermission {
      role
    }
    capabilities {
      ...ChartCapabilities
    }
    me {
      ... on Person {
        id
        email
        teamSize
      }
    }
  }
  ${chartCapabilitiesFragment}
  ${subscriptionInfoFragment}
  ${customFieldFragment}
`
