import { ReactNode, useState } from 'react'

import { createSafeContext } from 'helpers'

type ContextType = {
  isVisible: boolean
  withAnimation: boolean
  setHide: (withAnimation?: boolean) => void
  setShow: (withAnimation?: boolean) => void
}
export const [useTopNavigationToggle, EmptyTopNavigationToggleProvider] = createSafeContext<ContextType>()

type Props = {
  children: ReactNode
}

export const TopNavigationToggleSafeProvider = ({ children }: Props) => {
  const [isVisible, setIsVisible] = useState(true)
  const [withAnimation, setWithAnimation] = useState(true)

  const setHide = (withAnimation = true) => {
    if (isVisible) {
      setWithAnimation(withAnimation)
      setIsVisible(false)
    }
  }

  const setShow = (withAnimation = true) => {
    if (!isVisible) {
      setWithAnimation(withAnimation)
      setIsVisible(true)
    }
  }

  return (
    <EmptyTopNavigationToggleProvider value={{ isVisible, withAnimation, setHide, setShow }}>
      {children}
    </EmptyTopNavigationToggleProvider>
  )
}
