import { SVG, SVGProps } from './SVG'

export const DottedLineReportingFlatIcon = (props: SVGProps) => (
  <SVG width='17' height='11' viewBox='0 0 17 11' fill='none' {...props}>
    <path d='M7 5H5V11H4V8.5H3V11H2V5H0V4H7V5Z' fill='currentColor' />
    <path d='M17 5H15V11H14V8.5H13V11H12V5H10V4H17V5Z' fill='currentColor' />
    <circle cx='3.5' cy='1.5' r='1.5' fill='currentColor' />
    <circle cx='13.5' cy='1.5' r='1.5' fill='currentColor' />
    <rect x='6' y='7' width='1' height='1' fill='currentColor' />
    <rect x='8' y='7' width='1' height='1' fill='currentColor' />
    <rect x='10' y='7' width='1' height='1' fill='currentColor' />
  </SVG>
)
