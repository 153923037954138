import { useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

import { DeleteButton, tableComponents } from '../../components'
import { Card } from '../../../components'
import { ConfirmationModalType, DottedLinePreview } from 'components/specific'
import { EyeCrossedIcon, EyeIcon } from 'components/icons'

import { useChartId } from 'tree/providers'
import { useChartPermission } from 'tree/hooks'
import { CustomField, CustomConnectionField } from 'tree/types'
import { SETTINGS_EDIT_DOTTED_LINE_PATH } from 'routes'
import { useModal } from 'modal'
import { stripStr } from 'helpers'
import analytics from 'analytics'
import { CUSTOM_FIELDS_TRACK } from 'analytics/constants'

import { chart as chartQuery } from 'apollo/query'
import { useDeleteConnectionFieldMutation } from 'apollo/generated/graphql'

type DeleteFieldParams = {
  id: string
  type: CustomField['type']
  label: string
}

type Props = {
  connectionFields: CustomConnectionField[]
}

export const Detail = ({ connectionFields }: Props) => {
  const chartUuid = useChartId()
  const history = useHistory()
  const { open } = useModal()
  const { canDeleteCustomField, canReadCustomField, canUpdateCustomField } = useChartPermission()
  const [isDeleteHovered, setIsDeleteHovered] = useState(false)

  const [deleteConnectionFieldMutation] = useDeleteConnectionFieldMutation()

  const handleDeleteField = async ({ id, label, type }: DeleteFieldParams) => {
    try {
      await deleteConnectionFieldMutation({
        variables: { uuid: id, chartUuid },
        refetchQueries: [{ query: chartQuery, variables: { key: chartUuid } }],
      })
      toast(`Connection field deleted.`, { type: 'success' })
      analytics.track(CUSTOM_FIELDS_TRACK.delete, {
        chartUuid,
        fieldType: type,
        fieldLabel: label,
      })
    } catch (error) {
      toast(`Failed to delete connection field. Please refresh the page and try again.`, { type: 'error' })
    }
  }

  const handleConfirmDeleteField = (deleteParams: DeleteFieldParams) => {
    open<ConfirmationModalType>('confirmationModal', {
      title: { text: 'Delete connection field' },
      message:
        'All values assigned to this connection field will be permanently deleted from your employees. This cannot be undone.',
      confirmButton: { buttonType: 'danger', children: 'Delete' },
      onConfirm: () => handleDeleteField(deleteParams),
    })
  }

  return (
    <Card title='Fields' noCardPadding>
      {canReadCustomField &&
        connectionFields.map(connection => {
          const { id, label, lineLabel, type, lineColor, lineStyle, displayLineOnChart } = connection

          return (
            <tableComponents.Row
              key={id}
              onClick={() => {
                if (canUpdateCustomField) {
                  history.push(generatePath(SETTINGS_EDIT_DOTTED_LINE_PATH, { chartUuid, connectionFieldId: id }))
                }
              }}
              $isDisabled={isDeleteHovered || !canUpdateCustomField}
            >
              <tableComponents.LabelCell>{stripStr(label, 50)}</tableComponents.LabelCell>

              <tableComponents.TypeCell>{lineLabel}</tableComponents.TypeCell>

              <tableComponents.LineCell>
                <DottedLinePreview $lineColor={lineColor} $lineStyle={lineStyle} />
              </tableComponents.LineCell>

              <tableComponents.Cell>
                <tableComponents.IconContainer>
                  {displayLineOnChart ? (
                    <EyeIcon width='17px' height='100%' />
                  ) : (
                    <EyeCrossedIcon width='17px' height='100%' />
                  )}
                </tableComponents.IconContainer>
                {displayLineOnChart ? 'Visible on chart' : ' Hidden on chart'}
              </tableComponents.Cell>

              {canDeleteCustomField && (
                <DeleteButton
                  title='Delete connection'
                  onMouseEnter={() => setIsDeleteHovered(true)}
                  onMouseLeave={() => setIsDeleteHovered(false)}
                  onClick={e => {
                    e.stopPropagation()
                    handleConfirmDeleteField({ id, type, label })
                  }}
                />
              )}
            </tableComponents.Row>
          )
        })}
    </Card>
  )
}
