import { SVG, SVGProps } from '../SVG'

export const ZoomIcon = (props: SVGProps) => (
  <SVG width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M11.9998 24C18.6275 24 24 18.627 24 11.9998C24 5.37249 18.6275 0 11.9998 0C5.37202 0 0 5.37249 0 11.9998C0 18.627 5.37249 24 11.9998 24Z'
      fill='#2196F3'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7.04238 15.4297H14.9789V9.6101C14.9789 8.73332 14.2684 8.0228 13.3916 8.0228H5.45508V13.8424C5.45508 14.7192 6.1656 15.4297 7.04238 15.4297ZM16.0366 13.3138L19.2113 15.4297V8.0228L16.0371 10.1392L16.0366 13.3138Z'
      fill='white'
    />
  </SVG>
)
