import { useMemo } from 'react'
import styled, { css } from 'styled-components'

import { IconButton, LineDivider } from 'components/generic'
import { BurgerIcon, HomeIcon, OrgchartSymbolLogo } from 'components/icons'
import {
  Logo,
  ChartSelect,
  ChartButton,
  ChartShareButton,
  ChartDuplicate,
  ChartSettings,
  ChartExportButton,
} from 'components/specific/top-navigation/components'

import { useChartId } from 'tree/providers'
import { useResponsiveInfo } from 'providers'
import { HexString, isTextColorDark } from 'helpers'
import { colors, mainTheme, spaces } from 'theme'

type Props = {
  headlineColor: HexString
  buttonsColor: HexString
  logoUrl?: string
}

export const HeaderPreview = ({ headlineColor, buttonsColor, logoUrl }: Props) => {
  const chartUuid = useChartId()
  const isTextHeadlineWhite = useMemo(() => !isTextColorDark(headlineColor), [headlineColor])
  const areTextButtonsDark = useMemo(() => isTextColorDark(buttonsColor), [buttonsColor])

  const { isSmallerDevice, isSmallDevice } = useResponsiveInfo()

  return (
    <Container headlineColor={headlineColor} isTextHeadlineWhite={isTextHeadlineWhite}>
      {logoUrl ? (
        <LogoWrapper>
          <OrgchartSymbolLogo color={mainTheme.colors.purple} />
          <LineDivider $height='24px' $horizontalSpacing={spaces.m} />
          <Img src={logoUrl} />
        </LogoWrapper>
      ) : (
        <LogoWrapper hasBackground>
          <StyledLogo />
        </LogoWrapper>
      )}
      {!isSmallerDevice && <StyledHomeIcon />}
      <StyledChartSelect chartUuid={chartUuid} hasDarkText={isSmallDevice} />
      {!isSmallerDevice && (
        <StyledChartButton chartUuid={chartUuid} buttonsColor={buttonsColor} areTextButtonsDark={areTextButtonsDark} />
      )}
      <RightContainer>
        {isSmallerDevice ? (
          <IconButton spacing={{ m: mainTheme.spaces.l }}>
            <BurgerIcon />
          </IconButton>
        ) : (
          <>
            <StyledChartShareButton chartUuid={chartUuid} />
            <ChartExportButton chartUuid={chartUuid} />
            <ChartDuplicate chartUuid={chartUuid} />
            <ChartSettings chartUuid={chartUuid} />
          </>
        )}
      </RightContainer>
    </Container>
  )
}

type ContainerProps = {
  headlineColor?: HexString
  isTextHeadlineWhite?: boolean
}
const Container = styled.div.attrs<ContainerProps>(({ headlineColor, isTextHeadlineWhite }) => ({
  style: {
    background: headlineColor ?? 'transparent',
    ...(headlineColor && { color: isTextHeadlineWhite ? colors.white : colors.dark }),
  },
}))<ContainerProps>`
  width: 100%;
  max-width: calc(100% - 21px);
  height: 50px;
  display: flex;
  align-items: center;
  margin: ${props => props.theme.spaces.m};
  margin-right: 0;
  pointer-events: none;
`

const LogoWrapper = styled.div<{ hasBackground?: boolean }>`
  display: flex;
  justify-content: ${props => (props.hasBackground ? 'flex-end' : 'center')};
  align-items: center;
  height: 50px;
  width: auto;
  padding-right: ${props => props.theme.spaces.m};

  ${props =>
    !props.hasBackground &&
    css`
      background: ${props => props.theme.colors.white};
    `};
`

const Img = styled.img`
  display: flex;
  width: auto;
  max-height: 34px;
  max-width: 95px;
`

const StyledLogo = styled(Logo)`
  display: block;
  width: auto;
  height: auto;
  margin-left: ${props => props.theme.spaces.m};
`

const StyledHomeIcon = styled(HomeIcon)`
  display: flex;
  transform: scale(1.3);
  margin: 0 15px;
`

const StyledChartSelect = styled(ChartSelect)<{ hasDarkText?: boolean }>`
  margin-left: ${props => props.theme.spaces.m};
  min-width: 100px;
  max-width: 25%;

  ${({ hasDarkText }) =>
    hasDarkText &&
    css`
      color: #2c2f46;
    `}
`

type ButtonsProps = {
  buttonsColor?: HexString
  areTextButtonsDark?: boolean
}
const StyledChartButton = styled(ChartButton).attrs<ButtonsProps>(({ buttonsColor, areTextButtonsDark }) => ({
  style: {
    ...(buttonsColor && { background: buttonsColor }),
    ...(buttonsColor && { color: areTextButtonsDark ? colors.dark : colors.white }),
  },
}))<ButtonsProps>`
  margin-left: 12px;
  margin-right: ${props => props.theme.spaces.m};
`

const StyledChartShareButton = styled(ChartShareButton)`
  color: #2c2f46;
`

const RightContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: ${props => props.theme.spaces.m};
`
