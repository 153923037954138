import { useState } from 'react'

import { usePendingCollaborators } from 'providers'
import analytics from 'analytics'
import { handleErrorValidation } from 'helpers'
import { SHARING_TRACK } from 'analytics/constants'

import { getCollaborators } from 'apollo/query'
import { CollaboratorRole, useDeleteCollaboratorMutation } from 'apollo/generated/graphql'

type DeleteCollaborator = { chartUuid: string; collaboratorUuid: string; role: CollaboratorRole; email: string }

export const useDeleteCollaborator = () => {
  const [isDeleting, setIsDeleting] = useState(false)
  const [deleteCollaboratorMutation] = useDeleteCollaboratorMutation()
  const { onCollabRemove } = usePendingCollaborators()

  const deleteCollaborator = ({ chartUuid, collaboratorUuid, role, email }: DeleteCollaborator) => {
    setIsDeleting(true)

    deleteCollaboratorMutation({
      variables: { chartUuid, uuid: collaboratorUuid },
      refetchQueries: [{ query: getCollaborators, variables: { chartUuid } }],
    })
      .then(() => {
        analytics.track(SHARING_TRACK.deleteCollaborator, { chartUuid, role })
        onCollabRemove(chartUuid, email)
      })
      .catch(error => {
        handleErrorValidation({
          track: { message: SHARING_TRACK.deleteColleagueFailure, values: { chartUuid, error } },
          toast: { message: 'Failed to delete colleague' },
        })
      })
      .finally(() => setIsDeleting(false))
  }

  return { deleteCollaborator, isDeleting }
}
