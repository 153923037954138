import styled from 'styled-components'

import { Button } from '../../generic/button'
import { DownloadIcon, GemIcon } from 'components/icons'

import { useExportChartAction } from 'tree/actions'
import { useChartData } from 'tree/providers'
import { ChartExportFileFormat, Node } from 'tree/types'
import { useQuotaFor } from 'features/premium'
import { spaces } from 'theme'

type Props = {
  format: ChartExportFileFormat
  goToPremiumScreen: () => void
  compact?: boolean
  rootId?: Node['id']
}

export const ExportButton = ({ format, compact = false, rootId, goToPremiumScreen }: Props) => {
  const chartData = useChartData()
  const { checkQuota } = useQuotaFor([chartData])
  const { status } = checkQuota(`chart.export.${format}`)
  const quotaExceeded = status === 'exceeded'

  const isSubUpgradable = chartData?.subscription?.upgradable
  const { exportChartWithStatus } = useExportChartAction({ chartData })

  if (quotaExceeded) {
    return (
      <StyledButton buttonType='warning' disabled={!isSubUpgradable} onClick={() => goToPremiumScreen()}>
        <GemIcon $spacing={{ mr: spaces.m }} /> {isSubUpgradable ? 'Upgrade to PRO' : 'Avaliable to PRO'}
      </StyledButton>
    )
  }

  return (
    <StyledButton buttonType='primary' onClick={() => exportChartWithStatus({ format, compact, rootId })}>
      <DownloadIcon $spacing={{ mr: spaces.s }} />
      Download
    </StyledButton>
  )
}

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 170px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-left: auto;
  }
`
