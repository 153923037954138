import { SVG, SVGProps } from './SVG'

export const LetterTIcon = (props: SVGProps) => (
  <SVG width='21' height='24' viewBox='0 0 21 24' fill='none' {...props}>
    <path
      d='M2.25 3.75V6C2.25 6.62344 1.74844 7.125 1.125 7.125C0.501562 7.125 0 6.62344 0 6V3.375C0 2.33906 0.839062 1.5 1.875 1.5H19.125C20.1609 1.5 21 2.33906 21 3.375V6C21 6.62344 20.4984 7.125 19.875 7.125C19.2516 7.125 18.75 6.62344 18.75 6V3.75H11.625V20.25H13.875C14.4984 20.25 15 20.7516 15 21.375C15 21.9984 14.4984 22.5 13.875 22.5H7.125C6.50156 22.5 6 21.9984 6 21.375C6 20.7516 6.50156 20.25 7.125 20.25H9.375V3.75H2.25Z'
      fill='currentColor'
    />
  </SVG>
)
