import { gql } from '@apollo/client'

export const subscriptionInfoFragment = gql`
  fragment SubscriptionInfo on SubscriptionInfo {
    plan
    upgradable
    capabilities {
      canManage
    }
    quotas {
      items {
        id
        displayName
        softLimit
        hardLimit
        currentValue
      }
    }
  }
`
