import { SVG, SVGProps } from './SVG'

export const AddPhotoIcon = (props: SVGProps) => (
  <SVG width='18' height='17' viewBox='0 0 18 17' fill='none' {...props}>
    <path
      d='M15.75 4.5H13.3725L12 3H7.5V4.5H11.34L12.7125 6H15.75V15H3.75V8.25H2.25V15C2.25 15.825 2.925 16.5 3.75 16.5H15.75C16.575 16.5 17.25 15.825 17.25 15V6C17.25 5.175 16.575 4.5 15.75 4.5ZM6 10.5C6 12.57 7.68 14.25 9.75 14.25C11.82 14.25 13.5 12.57 13.5 10.5C13.5 8.43 11.82 6.75 9.75 6.75C7.68 6.75 6 8.43 6 10.5ZM9.75 8.25C10.9875 8.25 12 9.2625 12 10.5C12 11.7375 10.9875 12.75 9.75 12.75C8.5125 12.75 7.5 11.7375 7.5 10.5C7.5 9.2625 8.5125 8.25 9.75 8.25ZM3.75 4.5H6V3H3.75V0.75H2.25V3H0V4.5H2.25V6.75H3.75V4.5Z'
      fill='currentColor'
    />
  </SVG>
)
