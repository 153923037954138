import { ChartsBannerAdEntrySkeleton } from './charts-banner-ad.types'
import { contentfulClient } from '../contentful-cms-client'
import { ContentfulTag } from '../contentful-cms.types'
import globalConfig from 'config'

const contentType = globalConfig.CONTENTFUL_CONTENT_TYPE_CHARTS_BANNER_AD

export const getChartsBannerAdEntries = (tag: ContentfulTag) => {
  return contentfulClient?.getEntries<ChartsBannerAdEntrySkeleton>({
    content_type: contentType,
    'metadata.tags.sys.id[all]': [tag],
  })
}
