import { generatePath, useHistory } from 'react-router-dom'
import { History } from 'history'

import { useIsOnPublicRoute } from 'tree/hooks'
import { useChartId } from 'tree/providers'
import { Node } from 'tree/types'
import {
  DEPARTMENT_DETAIL_PATH,
  EMPLOYEE_DETAIL_PATH,
  SHARED_LINK_DEPARTMENT_EDIT_PATH,
  SHARED_LINK_EMPLOYEE_DETAIL_PATH,
} from 'routes'

export type GoToNodeDetailParams = {
  chartUuid: string
  history: History<unknown>
  isOnPublicRoute: boolean
  nodeId: string
}

export const goToEmployeeDetail = ({ chartUuid, history, isOnPublicRoute, nodeId }: GoToNodeDetailParams) => {
  const path = isOnPublicRoute ? SHARED_LINK_EMPLOYEE_DETAIL_PATH : EMPLOYEE_DETAIL_PATH
  const pathParams = isOnPublicRoute ? { code: chartUuid, uuid: nodeId } : { chartUuid, uuid: nodeId }
  history.push(generatePath(path, pathParams))
}

export const goToDepartmentDetail = ({ chartUuid, history, isOnPublicRoute, nodeId }: GoToNodeDetailParams) => {
  const path = isOnPublicRoute ? SHARED_LINK_DEPARTMENT_EDIT_PATH : DEPARTMENT_DETAIL_PATH
  const pathParams = isOnPublicRoute ? { code: chartUuid, uuid: nodeId } : { chartUuid, uuid: nodeId }
  history.push(generatePath(path, pathParams))
}

export const useGoToEmployeeDetail = () => {
  const history = useHistory()
  const chartUuid = useChartId()
  const isOnPublicRoute = useIsOnPublicRoute()

  const handler = (nodeId: Node['id']) => {
    goToEmployeeDetail({ chartUuid, history, isOnPublicRoute, nodeId })
  }

  return { goToEmployeeDetail: handler }
}

export const useGoToDepartmentDetail = () => {
  const history = useHistory()
  const chartUuid = useChartId()
  const isOnPublicRoute = useIsOnPublicRoute()

  const handler = (nodeId: Node['id']) => {
    goToDepartmentDetail({ chartUuid, history, isOnPublicRoute, nodeId })
  }

  return { goToDepartmentDetail: handler }
}
