import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom'

import { ChartList, ChartListEmpty } from './components'
import { ContactUs } from '../errors'

import analytics from 'analytics'
import { ORG_TRACK } from 'analytics/constants'
import { TopNavigation } from 'components/specific'
import { WebLayout } from 'components/layouts'
import { useCharts } from 'hooks'
import { CREATE_CHART_PATH, MAINTENANCE_PATH } from 'routes'
import { extractErrorCode } from 'helpers'

export const ChartsScreen = () => {
  const { data: charts, loading, error } = useCharts()
  const history = useHistory()
  const location = useLocation()

  const handleCreateChart = () => {
    history.push(CREATE_CHART_PATH)
    analytics.track(ORG_TRACK.createNewChart)
  }

  if (loading) return null
  if (error) {
    const errorCode = extractErrorCode(error)
    if (errorCode === 'UNAUTHENTICATED') return <ContactUs />
    history.push(MAINTENANCE_PATH, { from: location.pathname })
    return null
  }

  return (
    <>
      <Switch>
        <Redirect exact from='/charts/new' to={{ ...location, pathname: CREATE_CHART_PATH }} />
      </Switch>

      <WebLayout>
        <TopNavigation />
        {charts.length > 0 ? (
          <ChartList charts={charts} onCreateChartClick={handleCreateChart} />
        ) : (
          <ChartListEmpty onCreateChartClick={handleCreateChart} />
        )}
      </WebLayout>
    </>
  )
}
