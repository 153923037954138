import { ChartDetail } from '../types'

import analytics from 'analytics'
import { ORG_TRACK } from 'analytics/constants'
import { useNumberOfChartVisits } from 'hooks'

type ChartVisitSource = 'direct' | 'publicLink'
type Params = {
  chartData: ChartDetail
  source: ChartVisitSource
}

export const useOnChartVisit = () => {
  const { getNumberOfChartVisits, setNumberOfChartVisits } = useNumberOfChartVisits()

  const onChartVisit = ({ chartData, source }: Params) => {
    const { uuid, name, employeeCount, departmentCount } = chartData
    const numberOfChartVisits = getNumberOfChartVisits(uuid) + 1
    setNumberOfChartVisits(uuid, numberOfChartVisits)

    analytics.track(ORG_TRACK.openChart, {
      uuid,
      name,
      peopleCount: employeeCount,
      departmentCount: departmentCount,
      source,
    })
  }

  return onChartVisit
}
