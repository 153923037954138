import { ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { RequiredAsterisk } from '../../required-asterisk'

type Size = 'big' | 'normal'
export type FieldTitleProps = {
  title: ReactNode
  className?: string
  required?: boolean
  size?: Size
}

export const FieldTitle = ({ title, className, required, size = 'normal' }: FieldTitleProps) => (
  <FieldTitleInner className={className} $size={size}>
    {title}
    {required && <RequiredAsterisk />}
  </FieldTitleInner>
)

const FieldTitleInner = styled.div<{ $size: FieldTitleProps['size'] }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${props => props.theme.spaces.s};
  cursor: default;

  ${props => props.$size === 'big' && sizeBigCSS}
  ${props => props.$size === 'normal' && sizeNormalCSS}
`

const sizeBigCSS = css`
  color: ${props => props.theme.colors.dark};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`
const sizeNormalCSS = css`
  color: ${props => props.theme.colors.greyLight};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`
