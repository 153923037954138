import { useState } from 'react'

import { useCreatePublicLinkRequest } from './use-create-public-link-request'
import { ChartDetail } from '../../types'
import analytics from 'analytics'
import { SHARING_TRACK } from 'analytics/constants'
import { handleErrorValidation } from 'helpers'

type CreatePublicLinkActionParams = {
  chartData: Pick<ChartDetail, 'id' | 'name' | 'employeeCount' | 'departmentCount'>
}

export const useCreatePublicLinkAction = () => {
  const [isCreating, setIsCreating] = useState(false)
  const { createPublicLinkRequest } = useCreatePublicLinkRequest()

  const createPublicLink = async ({ chartData }: CreatePublicLinkActionParams) => {
    setIsCreating(true)
    const { id: chartId, name: chartName, employeeCount, departmentCount } = chartData
    try {
      const res = await createPublicLinkRequest({ chartId })
      const trackProps = {
        chartUuid: chartId,
        chartName,
        employeeCount,
        departmentCount,
        publicLink: res.data?.createPublicLink?.url,
      }
      analytics.track(SHARING_TRACK.createPublicLink, trackProps)
      // Is not removed otherwise we would lost 3 months of additional properties
      analytics.track(SHARING_TRACK.createPublicLinkOfTheChart, trackProps)
      return Promise.resolve(res)
    } catch (error) {
      handleErrorValidation({
        track: {
          message: SHARING_TRACK.createPublicLinkFailure,
          values: { chartUuid: chartId, error },
        },
        toast: { message: 'Link not created' },
      })
    } finally {
      setIsCreating(false)
    }
  }

  return { isCreating, createPublicLink }
}
