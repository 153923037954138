import { SVG, SVGProps } from './SVG'

export const CopyIcon = (props: SVGProps) => (
  <SVG width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <path
      d='M10.6668 0.666626L2.66683 0.666626C1.9335 0.666626 1.3335 1.26663 1.3335 1.99996L1.3335 11.3333H2.66683L2.66683 1.99996L10.6668 1.99996V0.666626ZM12.6668 3.33329L5.3335 3.33329C4.60016 3.33329 4.00016 3.93329 4.00016 4.66663V14C4.00016 14.7333 4.60016 15.3333 5.3335 15.3333H12.6668C13.4002 15.3333 14.0002 14.7333 14.0002 14V4.66663C14.0002 3.93329 13.4002 3.33329 12.6668 3.33329ZM12.6668 14H5.3335V4.66663H12.6668V14Z'
      fill='currentColor'
    />
  </SVG>
)
