import { useCallback } from 'react'
import { generatePath, useHistory, useRouteMatch } from 'react-router-dom'

import { ConnectGoogleWorkspace, ConnectGoogleWorkspaceProps } from 'screens/chart/components/connect-google-workspace'
import { CalendarIcon, Text } from 'components/specific/modal/insights-modals/styled'
import { ButtonGoogleCalendar, ModalContentType, TilModalType } from 'components/specific'
import { Button, TilButton } from 'components/generic'
import GoogleCalendarColorfulBig from 'assets/google-calendar-colorful-big-2x.png'

import { useGoogleWorkspace } from 'tree/hooks'
import { useModal } from 'modal'
import { INSIGHTS_OVERVIEW_CONNECT_GW_PATH, INSIGHTS_SOCIOMAP_PATH } from 'routes'

export const ConnectGoogleWorkspaceCalendar = (props: ConnectGoogleWorkspaceProps) => {
  const { authorize } = useGoogleWorkspace()
  const { open, close } = useModal()
  const history = useHistory()
  const { params } = useRouteMatch<{ chartUuid: string }>()

  const calendarSuccessfulModal: ModalContentType = {
    preTitleContent: <CalendarIcon src={GoogleCalendarColorfulBig} isBig />,
    title: 'Google Calendar was successfully synchronized',
    content: <Text>All Insights features have been fully unlocked.</Text>,
    actionsArea: (
      <Button
        onClick={() => {
          close('tilModal')
          history.replace({ pathname: generatePath(INSIGHTS_SOCIOMAP_PATH, params) })
        }}
      >
        Check out Sociomaps
      </Button>
    ),
    isCentered: true,
  }

  const calendarUnsuccessfulModal: ModalContentType = {
    preTitleContent: <CalendarIcon src={GoogleCalendarColorfulBig} isBig />,
    title: 'Oops! Something went wrong while syncing Google Calendar.',
    content: <Text>Try again to unlock more Insights.</Text>,
    actionsArea: (
      <>
        <TilButton
          key='prevent-button-color-transition'
          type='link'
          spacing={{ mr: '8px' }}
          onClick={() => close<TilModalType>('tilModal')}
        >
          Share later
        </TilButton>
        <ButtonGoogleCalendar
          redirectPath={generatePath(INSIGHTS_OVERVIEW_CONNECT_GW_PATH, params)}
          authorize={authorize}
          onClick={() => close<TilModalType>('tilModal')}
        />
      </>
    ),
    isCentered: true,
  }

  const onSuccessHandler = useCallback(() => {
    open<TilModalType>('tilModal', calendarSuccessfulModal)
  }, [open])

  const onFailureHandler = useCallback(() => {
    open<TilModalType>('tilModal', calendarUnsuccessfulModal)
  }, [open])

  return <ConnectGoogleWorkspace {...props} onSuccess={onSuccessHandler} onFailure={onFailureHandler} />
}
