import { setContext } from '@apollo/client/link/context'
import { useAuthActions } from 'auth'

export const useTokenLink = () => {
  const { getAccessTokenSilently } = useAuthActions()

  const tokenLink = setContext(async (_, ctx) => {
    const { headers } = ctx
    try {
      const accessToken = await getAccessTokenSilently()
      return { ...ctx, headers: { ...headers, authorization: accessToken ? `Bearer ${accessToken}` : '' } }
    } catch (err) {
      return ctx
    }
  })

  return tokenLink
}
