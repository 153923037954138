import styled from 'styled-components'

import { UpgradeSubscriptionButton } from './upgrade-subscription-button'
import { AlertBox, AlertBoxDescription } from 'components/generic'

import { getChartLimits } from './helpers'
import { CheckQuotaResult } from './types'
import { useAuth } from 'auth'

type Props = {
  quotaResults: CheckQuotaResult[]
  className?: string
}

export const QuotaExceeded = ({ quotaResults, className }: Props) => {
  const { user } = useAuth()
  const quotaWarningResults = quotaResults.filter(q => q.status === 'warning')

  return (
    <AlertBox
      className={className}
      type='error'
      title={`You have exceeded the limits of the ${user.plan} version`}
      ButtonComponent={<StyledUpgradeSubscriptionButton />}
      includeIcon
      isIconCentered
    >
      <AlertBoxDescription>
        In {user.plan} version {getChartLimits(quotaWarningResults)}
      </AlertBoxDescription>
    </AlertBox>
  )
}

const StyledUpgradeSubscriptionButton = styled(UpgradeSubscriptionButton)`
  align-self: center;
  margin-top: ${props => props.theme.spaces.l};

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    margin-top: 0;
  }
`
