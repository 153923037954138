import styled from 'styled-components'
import { spaces } from 'theme'

type Props = {
  $compact: boolean
  $isCompany?: boolean
  $isShortTopPadding?: boolean
}

const getPadding = ({ $compact, $isCompany, $isShortTopPadding }: Props) => {
  if ($compact) return `6px ${spaces.s} ${spaces.s}`
  if ($isCompany) return `${spaces.l} ${spaces.m}`
  return `${$isShortTopPadding ? '26px' : '28px'} ${spaces.s} ${spaces.l}`
}

export const NodeContent = styled.div.attrs<Props>(props => ({
  style: {
    padding: getPadding(props),
  },
}))<Props>`
  flex: 1;
  position: relative;
  min-width: 0; /* fixes content being any width (based on text length) as it is set to auto due to flex parent (https://makandracards.com/makandra/66994-css-flex-and-min-width) */
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  z-index: 4;
`
