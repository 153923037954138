import { Route, Switch, useParams } from 'react-router-dom'

import { EmployeeEdit } from './edit'
import { EmployeeDetails } from './details'
import { NodeComponent } from '../node-components'

import { useDeleteNodeAction, useUpdateNodeAction } from 'tree/actions'
import { usePerson } from 'tree/hooks'
import { useChartId } from 'tree/providers'
import { OnPersonFormSubmit } from 'tree/types'
import { EMPLOYEE_EDIT_PATH, TrackedRoute, useGoToEmployeeDetail } from 'routes'

import { PersonDetailDataFragmentFragment, useNodeQuery } from 'apollo/generated/graphql'

export const Employee = ({ onClose }: NodeComponent) => {
  const { uuid } = useParams<{ uuid: string }>()
  const chartUuid = useChartId()

  const { data } = useNodeQuery({ variables: { uuid, chartKey: chartUuid } })
  const person = data?.node
  if (person?.__typename !== 'Person') return null

  return <EmployeeWithData person={person} onClose={onClose} />
}

type Props = {
  person: PersonDetailDataFragmentFragment
  onClose: () => void
}

const EmployeeWithData = ({ person: personBE, onClose }: Props) => {
  const person = usePerson(personBE)
  const { parentUuid = null } = person

  const { goToEmployeeDetail } = useGoToEmployeeDetail()

  const { deleteNodeWithConfirm } = useDeleteNodeAction()
  const handleDelete = () => deleteNodeWithConfirm({ node: personBE }).then(onClose)

  const { updateNodeAction } = useUpdateNodeAction()
  const submitUpdateHandler: OnPersonFormSubmit = ({ values, actions }) => {
    updateNodeAction({ node: personBE, values, actions, nodeTypename: 'Person' })
  }

  return (
    <Switch>
      <TrackedRoute
        path={EMPLOYEE_EDIT_PATH}
        render={() => (
          <EmployeeEdit
            parentUuid={parentUuid}
            person={person}
            onClose={onClose}
            onCancel={() => goToEmployeeDetail(person.id)}
            onSubmit={submitUpdateHandler}
          />
        )}
      />
      <Route render={() => <EmployeeDetails person={person} onDelete={handleDelete} onClose={onClose} />} />
    </Switch>
  )
}
