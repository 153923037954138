import { useApolloClient } from '@apollo/client'

import { useChartId } from '../providers/use-chart-uuid'

import { searchNodes as searchNodesQuery } from 'apollo/query'
import {
  SearchNodesQuery,
  SearchNodesQueryVariables,
  NodeSearchDataFragment_Person_Fragment,
} from 'apollo/generated/graphql'

export const useGetChartNodeViaEmail = () => {
  const client = useApolloClient()
  const chartUuid = useChartId()

  const getChartNodeViaEmail = async (email: string) => {
    if (!email) return
    return client
      .query<SearchNodesQuery, SearchNodesQueryVariables>({
        query: searchNodesQuery,
        fetchPolicy: 'no-cache',
        variables: { chartKey: chartUuid, filter: { q: email, unassigned: false } },
      })
      .then(({ data }) => {
        let chartNode: NodeSearchDataFragment_Person_Fragment | undefined
        const searchNodes = data?.searchNodes?.items || []
        searchNodes.some(node => {
          if (
            node.__typename === 'Person' &&
            node?.email &&
            node.email.toLocaleLowerCase() === email.toLocaleLowerCase()
          ) {
            chartNode = node
            return true
          }
        })
        return chartNode
      })
  }

  return { getChartNodeViaEmail }
}
