import styled, { css } from 'styled-components'

import { SelectedDropdownOption } from 'tree/types'
import { BoxContainerCSS, CustomFieldIconBox } from 'components/generic'

import { CustomFieldColorType } from 'theme'

export type CustomFieldsIconsPanelProps = {
  compact: boolean
  hiddenFields: SelectedDropdownOption[]
  hiddenFieldsTitle: string
  showPanel: boolean
  visibleFields: SelectedDropdownOption[]
  setPanelRef: (node: HTMLDivElement | null) => void
}

export const CustomFieldsIconsPanel = ({
  compact,
  hiddenFields,
  hiddenFieldsTitle,
  showPanel,
  visibleFields,
  setPanelRef,
}: CustomFieldsIconsPanelProps) => (
  <CustomFieldsIconsPanelInner $compact={compact} ref={setPanelRef}>
    {showPanel && (
      <>
        {visibleFields.map(({ id, value, icon, background }) => (
          <CustomFieldIconBox key={id} backgroundColor={background as CustomFieldColorType} icon={icon} value={value} />
        ))}
        {hiddenFields.length > 0 && <HiddenFieldsBox title={hiddenFieldsTitle}>+{hiddenFields.length}</HiddenFieldsBox>}
      </>
    )}
  </CustomFieldsIconsPanelInner>
)

const CustomFieldsIconsPanelInner = styled.div<{ $compact?: boolean }>`
  width: 100%;
  min-width: 1px; /* min 1px so this row will wrap to the next line and width 100% can have affect */
  display: flex;
  justify-content: center;
  margin-top: auto;

  ${props =>
    props.$compact &&
    css`
      order: 3;
      flex: 1;
      justify-content: flex-end;
    `}
`

const HiddenFieldsBox = styled.div`
  ${BoxContainerCSS}
  background-color: ${props => props.theme.colors.greyExtraLight};
  color: ${props => props.theme.colors.greyLight};
  font-size: 12px;
  font-weight: 500;
`
