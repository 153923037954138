import { CheckboxLabel, StyledSelectButton } from '../../components'
import { ColorPicker } from 'components/specific'
import { FieldTitle, FlexBox } from 'components/generic'

import { CustomConnectionField } from 'tree/types'
import { spaces } from 'theme'

type Props = {
  displayLineOnChart: CustomConnectionField['displayLineOnChart']
  lineColor: CustomConnectionField['lineColor']
  onChangeDisplayLineOnChart: (displayLineOnChart: CustomConnectionField['displayLineOnChart']) => void
  onChangeLineColor: (color: CustomConnectionField['lineColor']) => void
}

export const LineSettings = ({
  displayLineOnChart,
  lineColor,
  onChangeDisplayLineOnChart,
  onChangeLineColor,
}: Props) => (
  <>
    <FlexBox $isDirectionColumn $spacing={{ mb: spaces.l }}>
      <FieldTitle title='Line color' size='big' />
      <ColorPicker color={lineColor} colorSelected={onChangeLineColor} title='Change color' isSquared isBig />
    </FlexBox>

    <FieldTitle title='Visibility on Org Chart' size='big' />
    <FlexBox $isDirectionColumn $spacing={{ mt: spaces.xs, mb: spaces.s }}>
      <CheckboxLabel>
        <StyledSelectButton
          name='displayLineOnChart'
          type='checkbox'
          checked={displayLineOnChart}
          onChange={() => onChangeDisplayLineOnChart(!displayLineOnChart)}
        />
        Display line on Org Chart
      </CheckboxLabel>
    </FlexBox>
  </>
)
