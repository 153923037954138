import { useState } from 'react'
import styled from 'styled-components'

import { ChartMinimap } from './chart-minimap'
import { ChartControlButton } from './chart-control-button'
import { Tooltip } from 'components/generic'
import {
  OrgchartStandardStructureIcon,
  OrgchartCompactStructureIcon,
  PlusIcon,
  MinusIcon,
  DoubleArrowsUpIcon,
  TargetIcon,
  MapWithMagnifyingGlassIcon,
} from 'components/icons'

import { useWatch, useZoomOnMe } from 'tree/hooks'
import { useTree } from 'tree/providers'
import { useResponsiveInfo } from 'providers'

export const ChartControls = () => {
  const [isMinimapShown, setIsMinimapShown] = useState(false)

  const { isSmallDevice } = useResponsiveInfo()
  const { zoomOnMe } = useZoomOnMe()
  const { setCompact, setZoom, resetPosition, collapseAll } = useTree()

  const zoom = useWatch({ name: 'zoom' })
  const compact = useWatch({ name: 'compact' })
  const expandedNodes = useWatch({ name: 'expandedNodes' })

  const findMeMiniMapElements = (shiftXPx?: number) => (
    <>
      {zoomOnMe && (
        <ControlsWrap>
          <ChartControlButton
            tooltip={<StyledTooltip isTooltipShown={false}>Find myself in Org Chart</StyledTooltip>}
            onClick={zoomOnMe}
          >
            <TargetIcon />
          </ChartControlButton>
        </ControlsWrap>
      )}

      <ControlsWrap>
        <MinimapChartControlButton
          isActive={isMinimapShown}
          isClickable
          tooltip={
            <StyledTooltip isTooltipShown={false} shiftXPx={shiftXPx}>
              {isMinimapShown ? 'Hide' : 'Show'} Org Chart minimap
            </StyledTooltip>
          }
          onClick={() => setIsMinimapShown(!isMinimapShown)}
        >
          {isMinimapShown ? 'Hide' : 'Show'} <StyledMapWithMagnifyingGlassIcon />
        </MinimapChartControlButton>
      </ControlsWrap>
    </>
  )

  return (
    <ChartControlsContainer>
      <MainControlsContainer>
        {!isSmallDevice && <>{findMeMiniMapElements()}</>}
        <ControlsWrap>
          <ChartControlButton
            isActive={!compact}
            tooltip={<StyledTooltip isTooltipShown={false}>Standard view</StyledTooltip>}
            onClick={() => setCompact(false)}
            data-testid='button-standard-view'
          >
            <StyledOrgChartStandardIcon />
          </ChartControlButton>
          <ChartControlButton
            isActive={compact}
            tooltip={<StyledTooltip isTooltipShown={false}>Compact view</StyledTooltip>}
            onClick={() => setCompact(true)}
            data-testid='button-compact-view'
          >
            <StyledOrgChartCompactIcon />
          </ChartControlButton>
        </ControlsWrap>

        <ControlsWrap>
          <ChartControlButton
            isDisabled={expandedNodes.length <= 1}
            tooltip={<StyledTooltip isTooltipShown={false}>Collapse</StyledTooltip>}
            onClick={() => {
              collapseAll()
              resetPosition()
            }}
            data-testid='button-collapse-nodes'
          >
            <DoubleArrowsUpIcon />
          </ChartControlButton>
        </ControlsWrap>

        <ZoomControlsWrap>
          <ChartControlButton
            isDisabled={zoom === 1}
            tooltip={<StyledTooltip isTooltipShown={false}>Zoom in</StyledTooltip>}
            onClick={() => setZoom(zoom + 0.1)}
            data-testid='button-zoom-in'
          >
            <StyledPlusIcon />
          </ChartControlButton>

          <ChartControlButton
            isDisabled={zoom < 0.2}
            tooltip={<StyledTooltip isTooltipShown={false}>Zoom out</StyledTooltip>}
            onClick={() => setZoom(zoom - 0.1)}
            data-testid='button-zoom-out'
          >
            <MinusIcon />
          </ChartControlButton>

          <ResetViewChartControlButton
            tooltip={
              <StyledTooltip isTooltipShown={false} shiftXPx={-14}>
                Reset zoom & view
              </StyledTooltip>
            }
            onClick={() => {
              setZoom(1)
              resetPosition()
            }}
            data-testid='button-zoom-reset'
          >
            Reset view
          </ResetViewChartControlButton>
        </ZoomControlsWrap>
      </MainControlsContainer>
      {isSmallDevice && <ControlsContainer>{findMeMiniMapElements(-20)}</ControlsContainer>}

      {isMinimapShown && <ChartMinimap minimapSize={isSmallDevice ? 256 : zoomOnMe ? 316 : 280} />}
    </ChartControlsContainer>
  )
}

const ChartControlsContainer = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: ${props => props.theme.spaces.m} ${props => props.theme.spaces.m};
`

const ControlsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: ${props => props.theme.spaces.m};
  margin-bottom: ${props => props.theme.spaces.l};
  user-select: none;
  z-index: 3;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    transform: translate(-${props => props.theme.spaces.m}, ${props => props.theme.spaces.m});
    grid-column-gap: ${props => props.theme.spaces.l};
  }
`

const MainControlsContainer = styled(ControlsContainer)`
  z-index: 4;
`

const MinimapChartControlButton = styled(ChartControlButton)`
  min-width: 69px;
  width: auto;
  box-sizing: border-box;
  padding: 0 6px;
`

const ResetViewChartControlButton = styled(ChartControlButton)`
  width: auto;
  padding: 0 6px;
`

const ControlsWrap = styled.div`
  display: grid;
  grid-auto-flow: column;
  box-shadow: 0px 3px 6px rgba(69, 90, 100, 0.16);
`

const ZoomControlsWrap = styled(ControlsWrap)`
  grid-column-gap: 1px;
`

const StyledPlusIcon = styled(PlusIcon)`
  width: 10.5px;
  height: auto;
`

const StyledTooltip = styled(Tooltip)`
  min-width: unset;
  width: unset;
  padding: 4px;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
`

const StyledOrgChartStandardIcon = styled(OrgchartStandardStructureIcon)`
  transform: scale(1.2);
`

const StyledOrgChartCompactIcon = styled(OrgchartCompactStructureIcon)`
  transform: scale(1.2);
`

const StyledMapWithMagnifyingGlassIcon = styled(MapWithMagnifyingGlassIcon)`
  margin-left: ${props => props.theme.spaces.s};
`
