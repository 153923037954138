import { useGoogleWorkspace } from 'tree/hooks'

export const useResyncChartRequest = () => {
  const { authorize } = useGoogleWorkspace()

  const resyncChartRequest = async (redirectPath: string) => {
    return authorize({ redirectPath, scopes: [], includeGrantedScopes: true })
  }

  return { resyncChartRequest }
}
