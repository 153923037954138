import { SetStateAction, ReactNode } from 'react'
import { createSafeContext } from 'helpers'

type ContextValue = {
  actualMonthIndex: number
  actualDataIndex: number
  startMonthIndex: number
  askForCalendarPermission: boolean
  isComputingInsights: boolean
  setIsTyping: (value: SetStateAction<boolean>) => void
  setMonthsDisabled: (value: SetStateAction<boolean>) => void
}
export const [useInsightsState, EmptyInsightsDateSafeProvider] = createSafeContext<ContextValue>()

type Props = {
  children: ReactNode
  value: ContextValue
}
export const InsightsDateSafeProvider = ({ value, children }: Props) => (
  <EmptyInsightsDateSafeProvider value={value}>{children}</EmptyInsightsDateSafeProvider>
)
