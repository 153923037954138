import styled, { css } from 'styled-components'

import { FlexBox } from '../flex'
import { SearchHint } from '../styled'
import { RowNode } from 'components/specific/row-node'
import { AvatarCircleMiniature } from 'components/specific/avatar-circle-miniature'
import { DepartmentIcon, ProfileIcon } from 'components/icons'

import { Department, Person } from 'tree/types'
import { getNodeColorPallete } from 'helpers'
import { colors, spaces } from 'theme'

import { NodeSearchDataFragmentFragment } from 'apollo/generated/graphql'

type Props = {
  hint: string
  nodes: NodeSearchDataFragmentFragment[]
  onNodeSelect: (node: NodeSearchDataFragmentFragment) => void
}

export const NodeSearchMenu = ({ hint, nodes, onNodeSelect }: Props) => (
  <NodesContainer $isDirectionColumn>
    {hint && <StyledSeachHint $hasBottomMargin={Boolean(nodes.length)}>{hint}</StyledSeachHint>}
    {nodes.map(node => {
      const { id, name, __typename } = node

      const isPerson = __typename === 'Person'
      const { position, image } = isPerson ? (node as Person) : ({} as Partial<Person>)

      const isDepartment = __typename === 'Department'
      const { color } = isDepartment ? (node as Department) : ({} as Partial<Department>)

      const icon = (
        <AvatarCircleMiniature
          src={image}
          fallbackIcon={
            isPerson ? <ProfileIcon width='16px' /> : <DepartmentIcon width='16px' height='100%' color={colors.dark} />
          }
        />
      )
      const nodeColorPallete = isDepartment ? getNodeColorPallete(color) : undefined

      return (
        <FlexBox key={id} onMouseDown={e => e.preventDefault()} onClick={() => onNodeSelect(node)}>
          <StyledRowNode
            title={name}
            description={position}
            nodeColorPallete={nodeColorPallete}
            icon={icon}
            spacing={{ py: spaces.xs }}
            isClickable
          />
        </FlexBox>
      )
    })}
  </NodesContainer>
)

const NodesContainer = styled(FlexBox)`
  transform: translateY(100%);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 3px 12px rgba(69, 90, 100, 0.3);
  padding: ${props => props.theme.spaces.m};
  background: ${props => props.theme.colors.white};
  z-index: 10;
`

const StyledRowNode = styled(RowNode)`
  min-height: 38px;
`

const StyledSeachHint = styled(SearchHint)<{ $hasBottomMargin: boolean }>`
  ${props =>
    !props.$hasBottomMargin &&
    css`
      margin-bottom: 0;
    `}
`
