import { useRef } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { Formik, FormikHelpers } from 'formik'

import { SelectType } from './SelectType'
import { EditType } from './EditType'
import { SelectOptions } from './SelectOptions'
import { CheckboxLabel, StyledSelectButton } from '../../components'
import { Card } from '../../../components'
import { Button, FieldInput, FieldTitle, FlexBox, ScrollAnchor } from 'components/generic'

import { useChartPermission } from 'tree/hooks'
import { useChartData } from 'tree/providers'
import { CUSTOM_FIELD_OPTIONS, CUSTOM_FIELD_DEFAULT_OPTION, CUSTOM_FIELD_NAME_PLACEHOLDER } from 'tree/constants'
import { CustomField, CustomFieldOption, DropdownOption, DropdownOptionCreation } from 'tree/types'
import { getIsSubscribed, useGoToPremiumScreen } from 'features/premium'
import analytics from 'analytics'
import { CUSTOM_FIELDS_TRACK } from 'analytics/constants'
import { DeepPartial, StringifyRecordValues } from 'types'
import { spaces } from 'theme'

import { chart as chartQuery } from 'apollo/query'
import {
  CreateCustomDropdownFieldInput,
  useCreateTextFieldMutation,
  useCreateDropdownFieldMutation,
  useCreateDateFieldMutation,
  useUpdateDateFieldMutation,
  useUpdateDropdownFieldMutation,
  useUpdateTextFieldMutation,
  UpdateCustomDropdownFieldInput,
  CreateDropdownFieldMutation,
  CreateTextFieldMutation,
  CreateDateFieldMutation,
} from 'apollo/generated/graphql'

type CustomFieldFormValues = {
  label: string
  fieldOption?: CustomFieldOption
  displayIconsOnChartNode: boolean
  dropdownOptions: (DropdownOption | DropdownOptionCreation)[]
}

type CustomFieldFormSubmitValues = CustomFieldFormValues & {
  fieldOption: CustomFieldOption
}

type CustomFieldsFormErrors = DeepPartial<StringifyRecordValues<CustomFieldFormValues>>

type Props = {
  customFields: CustomField[]
  onBack?: () => void
}

export const CreateOrEdit = ({ customFields, onBack }: Props) => {
  const { id: chartUuid, subscription } = useChartData()
  const isSubscribed = getIsSubscribed(subscription)
  const { goToPremiumScreen } = useGoToPremiumScreen()
  const { params } = useRouteMatch<{ customFieldId?: string }>()
  const { customFieldId } = params
  const editedField = customFields.find(cf => cf.id === customFieldId)
  const mode = editedField?.id ? 'edit' : 'create'
  const { canCreateCustomField, canUpdateCustomField } = useChartPermission()
  const isDisabled = (mode === 'create' && !canCreateCustomField) || (mode === 'edit' && !canUpdateCustomField)

  const initialValues: CustomFieldFormValues = {
    label: editedField?.label || '',
    fieldOption: CUSTOM_FIELD_OPTIONS.find(({ type }) => type === editedField?.type),
    displayIconsOnChartNode: editedField?.type === 'single-select' && editedField.displayIconsOnChartNode,
    dropdownOptions: editedField?.type === 'single-select' ? editedField.options : [{ ...CUSTOM_FIELD_DEFAULT_OPTION }],
  }

  const [createTextFieldMutation] = useCreateTextFieldMutation()
  const [createDateFieldMutation] = useCreateDateFieldMutation()
  const [createDropdownFieldMutation] = useCreateDropdownFieldMutation()
  const [updateTextFieldMutation] = useUpdateTextFieldMutation()
  const [updateDateFieldMutation] = useUpdateDateFieldMutation()
  const [updateDropdownFieldMutation] = useUpdateDropdownFieldMutation()

  const settingsRef = useRef<HTMLDivElement>(null)
  const scrollToSettings = () => {
    setTimeout(() => settingsRef.current?.scrollIntoView({ behavior: 'smooth' }), 0)
  }

  const handleCreateField = async ({ values }: { values: CustomFieldFormSubmitValues }) => {
    const { label, fieldOption, displayIconsOnChartNode, dropdownOptions } = values

    const mutationOptions = {
      variables: { data: { label }, chartUuid },
      refetchQueries: [{ query: chartQuery, variables: { key: chartUuid } }],
    }

    if (fieldOption.type === 'text') {
      return createTextFieldMutation(mutationOptions)
    }
    if (fieldOption.type === 'single-date') {
      return createDateFieldMutation(mutationOptions)
    }
    if (fieldOption.type === 'single-select') {
      const transformedOptions: CreateCustomDropdownFieldInput['options'] = (
        dropdownOptions as DropdownOptionCreation[]
      ).map(({ value, ...rest }) => ({ label: value, ...rest }))
      const data = { label, displayIconsOnChartNode, options: transformedOptions }
      const dropdownCreateOptions = { ...mutationOptions, variables: { ...mutationOptions.variables, data } }
      return createDropdownFieldMutation(dropdownCreateOptions)
    }
  }

  const handleEditField = ({ values }: { values: CustomFieldFormSubmitValues }) => {
    const { label, fieldOption, displayIconsOnChartNode, dropdownOptions } = values

    const editOptions = {
      variables: { uuid: editedField?.id ?? '', chartUuid, data: { label } },
    }

    if (fieldOption.type === 'text') {
      return updateTextFieldMutation(editOptions)
    }
    if (fieldOption.type === 'single-date') {
      return updateDateFieldMutation(editOptions)
    }
    if (fieldOption.type === 'single-select') {
      const transformedOptions = (dropdownOptions as DropdownOption[]).map(({ id, value, ...rest }) => ({
        ...(id && { id }),
        label: value,
        ...rest,
      }))
      const data: UpdateCustomDropdownFieldInput = {
        label,
        displayIconsOnChartNode,
        options: transformedOptions,
      }
      return updateDropdownFieldMutation({ variables: { ...editOptions.variables, data } })
    }
  }

  const handleCancel = () => {
    onBack?.()
    if (mode === 'create') analytics.track(CUSTOM_FIELDS_TRACK.cancelCreate, { chartUuid })
  }

  const handleSubmit = (values: CustomFieldFormSubmitValues, actions: FormikHelpers<CustomFieldFormSubmitValues>) => {
    if (mode === 'create' && !isSubscribed) {
      return goToPremiumScreen()
    }

    const promise = mode === 'create' ? handleCreateField({ values }) : handleEditField({ values })
    promise
      ?.then(res => {
        handleTracking({ label: values.label, fieldType: values.fieldOption.type, mode, mutationData: res?.data })
        toast(`Custom field ${values.label} ${mode === 'create' ? 'created' : 'edited'}.`, { type: 'success' })
        onBack?.()
      })
      .catch(() => {
        toast(`Custom field ${values.label} could not be ${mode === 'create' ? 'created' : 'edited'}.`, {
          type: 'error',
        })
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <Formik<CustomFieldFormValues>
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={false}
      onSubmit={(values, actions) =>
        handleSubmit(values as CustomFieldFormSubmitValues, actions as FormikHelpers<CustomFieldFormSubmitValues>)
      }
      validate={handleValidate}
    >
      {({ values, isSubmitting, handleSubmit: handleFormikSubmit, setFieldValue }) => {
        const { label, fieldOption, displayIconsOnChartNode, dropdownOptions } = values

        return (
          <form tabIndex={0} onSubmit={handleFormikSubmit}>
            {mode === 'create' ? (
              <SelectType
                selectedFieldOption={fieldOption}
                onChange={fieldOption => {
                  setFieldValue('fieldOption', fieldOption)
                  scrollToSettings()
                }}
              />
            ) : (
              fieldOption && <EditType selectedFieldOption={fieldOption} />
            )}

            <ScrollAnchor innerRef={settingsRef} />

            {fieldOption?.type === 'single-select' && (
              <StyledCard>
                <SelectOptions
                  title={`${fieldOption.typeLabel} options`}
                  name='dropdownOptions'
                  dropdownOptions={dropdownOptions}
                />
              </StyledCard>
            )}

            {fieldOption && (
              <Card title='Field settings'>
                <FieldInput
                  title={{ title: 'Field name', size: 'big', required: true }}
                  name='label'
                  type='text'
                  placeholder={CUSTOM_FIELD_NAME_PLACEHOLDER[fieldOption.type]}
                  onChange={e => setFieldValue('label', e.target.value)}
                  initialValue=''
                  maxLength={255}
                  value={label}
                  disabled={isDisabled}
                  onClear={() => setFieldValue('label', '')}
                  required
                />

                {fieldOption.type === 'single-select' && (
                  <>
                    <FieldTitle title='Visibility on Org Chart' size='big' />
                    <FlexBox $isDirectionColumn $spacing={{ mt: spaces.xs, mb: spaces.s }}>
                      <CheckboxLabel>
                        <StyledSelectButton
                          name='displayIconsOnChartNode'
                          type='checkbox'
                          checked={displayIconsOnChartNode}
                          onChange={() => setFieldValue('displayIconsOnChartNode', !displayIconsOnChartNode)}
                        />
                        Display icons on Org Chart cards
                      </CheckboxLabel>
                    </FlexBox>
                  </>
                )}
              </Card>
            )}

            <ButtonsContainer>
              <Button
                buttonType='secondary'
                spacing={{ mr: spaces.l }}
                onClick={handleCancel}
                data-testid='button-new-custom-fields-form-cancel'
              >
                Cancel
              </Button>
              <Button
                disabled={isDisabled || isSubmitting}
                onClick={() => handleFormikSubmit()}
                data-testid='button-new-custom-fields-form-save'
              >
                {mode === 'create' ? 'Create new field' : 'Save'}
              </Button>
            </ButtonsContainer>
          </form>
        )
      }}
    </Formik>
  )
}

const StyledCard = styled(Card)`
  /* Fixes hidden color picker behind another card */
  z-index: 2;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${props => props.theme.spaces.l};
`

const displayWarning = (text: string) => {
  toast(text, { type: 'warning' })
}

const handleValidateDrodopwnOptions = (
  values: Pick<CustomFieldFormValues, 'dropdownOptions' | 'displayIconsOnChartNode'>
) => {
  const { dropdownOptions, displayIconsOnChartNode } = values
  const errors: CustomFieldsFormErrors['dropdownOptions'] = []

  if (dropdownOptions.some(o => !o.value)) displayWarning('Please fill all option names')
  if (displayIconsOnChartNode && dropdownOptions.some(o => !o.icon)) displayWarning('Please fill all option icons')

  dropdownOptions.forEach((option, index) => {
    const valueMissing = Boolean(!option.value)
    const iconMissing = Boolean(displayIconsOnChartNode && !option.icon)
    if (valueMissing || iconMissing) {
      errors[index] = {
        ...(valueMissing && { value: 'Please fill name' }),
        ...(iconMissing && { icon: 'Please select icon' }),
      }
    }
  })

  return errors
}

const handleValidate = (values: CustomFieldFormValues): CustomFieldsFormErrors => {
  const { label, fieldOption, displayIconsOnChartNode, dropdownOptions } = values
  const errors: CustomFieldsFormErrors = {}

  if (!fieldOption?.type) {
    displayWarning('Please choose field type')
    errors.fieldOption = 'Please choose field type'
    return errors
  }

  if (!label) {
    displayWarning('Please fill custom field name')
    errors.label = 'Please fill custom field name'
  }

  if (fieldOption.type === 'single-select') {
    errors.dropdownOptions = handleValidateDrodopwnOptions({ dropdownOptions, displayIconsOnChartNode })
  }

  if (errors.dropdownOptions?.length === 0) delete errors.dropdownOptions

  return errors
}

type HandleTrackingParams = {
  label: string
  fieldType: CustomField['type']
  mode: 'create' | 'edit'
  mutationData: CreateTextFieldMutation | CreateDateFieldMutation | CreateDropdownFieldMutation | null | undefined
}

const handleTracking = ({ label, fieldType, mode, mutationData }: HandleTrackingParams) => {
  let singleSelectProperties = {}

  if (fieldType === 'single-select') {
    const { displayIconsOnChartNode, options } = (mutationData as CreateDropdownFieldMutation).createDropdownField || {}
    let emojiCount = 0
    for (const { icon } of options || []) icon && (emojiCount += 1)
    singleSelectProperties = { displayOnCards: displayIconsOnChartNode, optionsCount: options?.length, emojiCount }
  }

  analytics.track(CUSTOM_FIELDS_TRACK[mode], { fieldLabel: label, fieldType, ...singleSelectProperties })
}
