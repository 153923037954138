import styled from 'styled-components'

import { View, ViewHeader, ViewTitle } from '../components'
import { Card } from '../../components'
import { EditCollaboratorForm, useGetCollaborators } from 'components/forms'
import { InviteMembers, InviteNonMembers } from 'components/specific'

import { useChartPermission } from 'tree/hooks'
import { useChartData } from 'tree/providers'

import { CollaboratorRole } from 'apollo/generated/graphql'

// if getCanShowMembersView
export const Members = () => {
  const { uuid: chartUuid, userPermission } = useChartData()
  const loggedUserChartRole = (userPermission?.role as CollaboratorRole) || 'viewer'
  const collaborators = useGetCollaborators(chartUuid)
  const { canAddCollaborator, canReadCollaborators } = useChartPermission()

  return (
    <StyledView quota='chart.member'>
      <ViewHeader>
        <ViewTitle>Members</ViewTitle>
      </ViewHeader>

      {canAddCollaborator && (
        <StyledCard title='Invite colleagues'>
          <InviteMembers chartUuid={chartUuid} loggedUserChartRole={loggedUserChartRole} />
          <InviteNonMembers chartUuid={chartUuid} loggedUserChartRole={loggedUserChartRole} />
        </StyledCard>
      )}
      {canReadCollaborators && (
        <Card title='Manage users'>
          {collaborators.map(collaborator => (
            <EditCollaboratorForm key={collaborator.uuid} {...collaborator} />
          ))}
        </Card>
      )}
    </StyledView>
  )
}

const StyledView = styled(View)`
  margin-bottom: ${props => props.theme.spaces.xl};
`

const StyledCard = styled(Card)`
  min-height: 375px;
`
