import styled from 'styled-components'

import { PersonDetailDataFragmentFragment, DepartmentDetailDataFragmentFragment } from 'apollo/generated/graphql'

type DisplaySupervisorAndDepartmentProps = {
  unassigned: boolean
  superiorPerson?: PersonDetailDataFragmentFragment
  superiorDepartment?: DepartmentDetailDataFragmentFragment
}

export const DisplaySupervisorAndDepartment = ({
  unassigned,
  superiorPerson,
  superiorDepartment,
}: DisplaySupervisorAndDepartmentProps) => {
  if (unassigned) return <>In directory</>
  return (
    <>
      {superiorPerson ? superiorPerson.name : 'No supervisor'}
      <VerticalLine />
      {superiorDepartment ? (
        <SuperiorDepartment departmentColor={superiorDepartment.color}>{superiorDepartment.name}</SuperiorDepartment>
      ) : (
        'No department'
      )}
    </>
  )
}

const VerticalLine = styled.div`
  width: 1px;
  height: 16px;
  margin-right: 10px;
  margin-left: 10px;
  background: ${props => props.theme.colors.dark};
  opacity: 0.2;
`

const SuperiorDepartment = styled.div<{
  departmentColor: string | null | undefined
}>`
  display: flex;
  align-items: center;

  &:before {
    content: '';
    min-width: 6px;
    min-height: 6px;
    max-width: 6px;
    max-height: 6px;
    display: flex;
    background: ${props => props.departmentColor};
    border-radius: 50%;
    margin-right: 4px;
  }
`
