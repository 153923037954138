import { InsightDataset, useInsightsQuery } from 'apollo/generated/graphql'

export const useInsights = (chartUuid: string) => {
  const { data: response, error, loading } = useInsightsQuery({ variables: { chartUuid } })

  if (error) return { error, data: [], loading }
  const insightsItems = response?.insights?.items ?? []

  const data = insightsItems
    .map(({ departments, dateFrom, dateTo, status, datasets }) => ({
      departments,
      reportInfo: { dateFrom, dateTo, status },
      datasets: (datasets as InsightDataset[]).reduce<Record<string, InsightDataset>>((obj, dataset) => {
        obj[dataset.id] = dataset
        return obj
      }, {}),
    }))
    .reverse()

  return { data, loading }
}
