import { MouseEvent, HTMLAttributes } from 'react'
import styled from 'styled-components'

import { CloseIcon } from 'components/icons'

type Props = HTMLAttributes<HTMLDivElement> & {
  onClick: (e: MouseEvent<HTMLDivElement>) => void
}

export const InputClear = ({ className, ...rest }: Props) => (
  <InputClearInner className={className}>
    <ClearButton {...rest}>
      <ClearIconStyled />
    </ClearButton>
  </InputClearInner>
)

const InputClearInner = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: ${props => props.theme.spaces.m};
  margin-left: auto;
  pointer-events: auto;
`

const ClearButton = styled.div`
  min-width: 18px;
  width: 18px;
  min-height: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  user-select: none;
  cursor: pointer;

  ${props => props.theme.animations.backgroundShapeFill};
  &::before {
    z-index: 0;
  }
`

const ClearIconStyled = styled(CloseIcon)`
  position: relative;
  width: 10.5px;
  color: ${props => props.theme.colors.greyLight};
  z-index: 2;
`
