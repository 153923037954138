import { SVG, SVGProps } from './SVG'

export const AddEmployeeIcon = (props: SVGProps) => (
  <SVG width='18' height='11' viewBox='0 0 18 11' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.5 11C15.5376 11 18 8.53757 18 5.5C18 2.46243 15.5376 0 12.5 0C9.46243 0 7 2.46243 7 5.5C7 8.53757 9.46243 11 12.5 11ZM13 6H16V5H13V2H12V5H9V6H12V9H13V6Z'
      fill='currentColor'
    />
    <path d='M16 6H13V9H12V6H9V5H12V2H13V5H16V6Z' fill='white' />
    <path d='M7 5H5V11H4V8.5H3V11H2V5H0V4H7V5Z' fill='currentColor' />
    <circle cx='3.5' cy='1.5' r='1.5' fill='currentColor' />
  </SVG>
)
