import { SVG, SVGProps } from './SVG'

export const ShareOutlineIcon = (props: SVGProps) => (
  <SVG width='24' height='22' viewBox='0 0 24 22' fill='none' {...props}>
    <path
      d='M13.5 10.25H9C5.89688 10.25 3.28125 12.3453 2.49375 15.1953C2.33906 14.6141 2.25 13.9672 2.25 13.25C2.25 9.93597 4.93594 7.25003 8.25 7.25003H13.5H14.625C15.2484 7.25003 15.75 6.74847 15.75 6.12503V5.00003V3.68284L21.3797 8.75003L15.75 13.8172V12.5V11.375C15.75 10.7516 15.2484 10.25 14.625 10.25H13.5ZM13.5 12.5V14.75V15.5C13.5 16.0907 13.8469 16.6297 14.3906 16.8688C14.9344 17.1078 15.5625 17.0094 16.0031 16.6157L23.5031 9.86565C23.8172 9.57972 24 9.17659 24 8.75003C24 8.32347 23.8219 7.92034 23.5031 7.6344L16.0031 0.884404C15.5625 0.485966 14.9297 0.387529 14.3906 0.631279C13.8516 0.875029 13.5 1.4094 13.5 2.00003V2.75003V5.00003H11.25H8.25C3.69375 5.00003 0 8.69378 0 13.25C0 16.9063 1.80937 19.1657 3.22031 20.3797C3.4125 20.5438 3.6 20.6891 3.76875 20.8157C3.91875 20.9282 4.05938 21.0219 4.18594 21.1063C4.39688 21.2469 4.575 21.3453 4.69219 21.411C4.80937 21.4766 4.94062 21.5 5.07187 21.5C5.58281 21.5 5.99531 21.0828 5.99531 20.5766C5.99531 20.2578 5.82656 19.9578 5.60625 19.7282C5.58281 19.7047 5.56406 19.686 5.54062 19.6625C5.42812 19.5547 5.30156 19.4235 5.17969 19.2594C5.1 19.1516 5.02031 19.025 4.94531 18.8891C4.69688 18.4344 4.5 17.8157 4.5 17.0047C4.5 14.5203 6.51562 12.5047 9 12.5047H11.25H13.5V12.5Z'
      fill='currentColor'
    />
  </SVG>
)
