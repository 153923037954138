import { SVG, SVGProps } from './SVG'

export const OrgchartFigure = (props: SVGProps) => (
  <SVG width='17' height='26' viewBox='0 0 17 26' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.004 4C12.004 6.20914 10.2132 8 8.00404 8C5.7949 8 4.00404 6.20914 4.00404 4C4.00404 1.79086 5.7949 0 8.00404 0C10.2132 0 12.004 1.79086 12.004 4ZM8.00404 10L16.004 18L8.00404 26L0.00402832 18L8.00404 10Z'
      fill='currentColor'
    />
  </SVG>
)
