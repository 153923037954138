import styled from 'styled-components'

import { UpgradeSubscriptionButton } from './upgrade-subscription-button'
import { AlertBar, FlexBox, ListItem } from 'components/generic'
import { GemIcon } from 'components/icons'

import { useResponsiveInfo } from 'providers'
import { colors, spaces } from 'theme'

export const QuotaBarProInfo = () => {
  const { isBigDevice } = useResponsiveInfo()

  return (
    <AlertBar
      type='warning'
      size='small'
      title={
        <FlexBox $isXCentered>
          <div>
            <StyledGemIcon />
          </div>
          Org Chart PRO: Upgrade your organizational chart to next level
        </FlexBox>
      }
      ButtonComponent={<UpgradeSubscriptionButton buttonSize='small' />}
    >
      {isBigDevice && (
        <FlexBox $spacing={{ ml: spaces.l }} $gap='m'>
          <ListItem iconColor={colors.yellow}>Export beautiful PDFs & PNGs</ListItem>
          <ListItem iconColor={colors.yellow}>Customize your Org Charts with unique labels</ListItem>
        </FlexBox>
      )}
    </AlertBar>
  )
}

const StyledGemIcon = styled(GemIcon)`
  display: inline-block;
  vertical-align: middle;
  margin-right: ${props => props.theme.spaces.m};
`
