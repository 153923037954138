import { gql } from '@apollo/client'
import { nodeDataFragment } from '../fragments'

export const nodes = gql`
  query nodes($chartKey: String!, $filter: GetNodesFilter) {
    nodes(chartKey: $chartKey, filter: $filter) {
      items {
        ...NodeDataFragment
      }
    }
  }
  ${nodeDataFragment}
`
