import { useState, useLayoutEffect, useRef, cloneElement, SyntheticEvent } from 'react'
import styled from 'styled-components'

type TabListProps = {
  children: JSX.Element[]
  className?: string
  defaultActiveTab?: number
}

export const TabList = ({ className, defaultActiveTab = 0, children }: TabListProps) => {
  const [activeTab, setActiveTab] = useState(defaultActiveTab)
  const [underlineWidth, setUnderlineWidth] = useState(0)
  const [underlineLeftOffset, setUnderlineLeftOffset] = useState(0)
  const defaultTabRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (defaultTabRef.current) {
      setUnderlineWidth(defaultTabRef.current.clientWidth)
      setUnderlineLeftOffset(defaultTabRef.current.offsetLeft)
    }
  }, [])

  const handleClick = (event: SyntheticEvent<HTMLDivElement>, index: number) => {
    setActiveTab(index)
    setUnderlineWidth(event.currentTarget.offsetWidth)
    setUnderlineLeftOffset(event.currentTarget.offsetLeft)
  }

  return (
    <TabListContainer className={className}>
      <Underline width={underlineWidth} left={underlineLeftOffset} />

      {children
        .filter(child => !child.props.isHidden)
        .map((child, i) =>
          cloneElement(child, {
            key: i,
            isActive: i === activeTab,
            ref: i === activeTab ? defaultTabRef : null,
            onClick: (e: SyntheticEvent<HTMLDivElement>) => {
              handleClick(e, i)
              if (child.props.onClick) child.props.onClick()
            },
          })
        )}
    </TabListContainer>
  )
}

const TabListContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 42px;
  display: inline-flex;
  border-bottom: 1px solid ${props => props.theme.colors.greyExtraLight};
`

type UnderlineProps = {
  width: number
  left: number
}
const Underline = styled.div.attrs<UnderlineProps>(({ width, left }) => ({
  style: {
    width: `${width}px`,
    transform: `translateX(${left}px)`,
  },
}))<UnderlineProps>`
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background: ${props => props.theme.colors.purple};
  transition: all ${props => props.theme.transitions.fastBezier};
  user-select: none;
  pointer-events: none;
`
