import { SVG, SVGProps } from './SVG'

export const ArrowsDistanceIcon = (props: SVGProps) => (
  <SVG width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <g clipPath='url(#clip0)'>
      <path
        d='M23.6031 11.0029H17.2392L19.7777 8.46443L18.3635 7.05022L13.4137 12L18.3635 16.9497L19.7777 15.5355L17.2392 12.997L23.6031 12.997L23.6031 11.0029ZM5.63555 7.05022L4.22134 8.46443L6.75985 11.0029H0.39589L0.39589 12.997L6.75985 12.997L4.22134 15.5355L5.63555 16.9497L10.5853 12L5.63555 7.05022Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0'>
        <rect width='24' height='24' fill='white' transform='translate(-0.000488281)' />
      </clipPath>
    </defs>
  </SVG>
)
