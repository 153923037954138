import { SVG, SVGProps } from './SVG'

export const ChevronRightIcon = (props: SVGProps) => (
  <SVG width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <path
      d='M5.72668 11.0533L8.78002 8L5.72668 4.94L6.66668 4L10.6667 8L6.66668 12L5.72668 11.0533Z'
      fill='currentColor'
    />
  </SVG>
)
