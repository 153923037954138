import { useEffect } from 'react'
import { generatePath, useRouteMatch } from 'react-router-dom'

import { ModalContentType, TilModalType } from '../til-modal'
import { Text, Sociomap, CalendarIcon } from './styled'
import { ButtonGoogleCalendar } from 'components/specific/buttons'
import { TilButton } from 'components/generic'
import GoogleCalendarColorful from 'assets/google-calendar-colorful.png'
import SociomapUri from 'assets/sociomap-ad.png'

import { useModal } from 'modal'
import { useNumberOfInsightsVisits } from 'hooks'
import { useGoogleWorkspace } from 'tree/hooks'
import { useChartId } from 'tree/providers'
import { INSIGHTS_OVERVIEW_CONNECT_GW_PATH } from 'routes'
import { spaces } from 'theme'

export const InsightsOnboardingModal = () => {
  const { authorize } = useGoogleWorkspace()
  const { params } = useRouteMatch()
  const chartUuid = useChartId()
  const { open, replace, close } = useModal()
  const { getNumberOfInsightsVisits } = useNumberOfInsightsVisits()

  useEffect(() => {
    const insightsVisited = sessionStorage.getItem('insights-visited')
    const displayOnboarding = getNumberOfInsightsVisits(chartUuid) <= 5 && Boolean(!insightsVisited)
    if (displayOnboarding) open<TilModalType>('tilModal', stepOneModal)
  }, [])

  const stepOneModal: ModalContentType = {
    modalStep: 'Step 1 of 2',
    title: 'Enhance your productivity in just 2 easy steps',
    content: (
      <>
        <Text $maxWidth='250px'>Visualize your team&apos;s collaboration with Insights, click Next to continue.</Text>
        <Sociomap src={SociomapUri} />
      </>
    ),
    actionsArea: (
      <TilButton onClick={() => replace<TilModalType, TilModalType>('tilModal', 'tilModal', stepTwoModal)}>
        Next
      </TilButton>
    ),
    isUnclosable: true,
    isOverflowVisible: true,
    hasLineSeparator: true,
    contentSpacingProps: { mb: spaces.xl },
    titleMaxWidth: '300px',
  }

  const stepTwoModal: ModalContentType = {
    modalStep: 'Step 2 of 2',
    title: (
      <>
        Sync with Google Calendar
        <CalendarIcon src={GoogleCalendarColorful} />
      </>
    ),
    content: <Text $maxWidth='400px'>Synchronize your company data from Google Calendar to get more Insights.</Text>,
    actionsArea: (
      <>
        <TilButton
          key='prevent-button-color-transition'
          type='link'
          spacing={{ mr: '8px' }}
          onClick={() => close<TilModalType>('tilModal')}
        >
          Share later
        </TilButton>
        <ButtonGoogleCalendar
          redirectPath={generatePath(INSIGHTS_OVERVIEW_CONNECT_GW_PATH, params)}
          authorize={authorize}
          onClick={() => close<TilModalType>('tilModal')}
        />
      </>
    ),
    hasLineSeparator: true,
    contentSpacingProps: { mb: spaces.xl },
  }

  return <></>
}
