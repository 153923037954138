import { SVG, SVGProps } from './SVG'

export const AccountGroupIcon = (props: SVGProps) => (
  <SVG width='28' height='28' viewBox='0 0 28 28' fill='none' {...props}>
    <path
      d='M14 6.41666C15.083 6.41666 16.1216 6.84686 16.8874 7.61264C17.6531 8.37841 18.0833 9.41702 18.0833 10.5C18.0833 11.583 17.6531 12.6216 16.8874 13.3873C16.1216 14.1531 15.083 14.5833 14 14.5833C12.917 14.5833 11.8784 14.1531 11.1126 13.3873C10.3469 12.6216 9.91667 11.583 9.91667 10.5C9.91667 9.41702 10.3469 8.37841 11.1126 7.61264C11.8784 6.84686 12.917 6.41666 14 6.41666ZM5.83333 9.33332C6.48667 9.33332 7.09333 9.50832 7.61833 9.82332C7.44333 11.4917 7.93333 13.1483 8.93667 14.4433C8.35333 15.5633 7.18667 16.3333 5.83333 16.3333C4.90508 16.3333 4.01484 15.9646 3.35846 15.3082C2.70208 14.6518 2.33333 13.7616 2.33333 12.8333C2.33333 11.9051 2.70208 11.0148 3.35846 10.3584C4.01484 9.70207 4.90508 9.33332 5.83333 9.33332ZM22.1667 9.33332C23.0949 9.33332 23.9852 9.70207 24.6415 10.3584C25.2979 11.0148 25.6667 11.9051 25.6667 12.8333C25.6667 13.7616 25.2979 14.6518 24.6415 15.3082C23.9852 15.9646 23.0949 16.3333 22.1667 16.3333C20.8133 16.3333 19.6467 15.5633 19.0633 14.4433C20.0667 13.1483 20.5567 11.4917 20.3817 9.82332C20.9067 9.50832 21.5133 9.33332 22.1667 9.33332ZM6.41667 21.2917C6.41667 18.8767 9.81167 16.9167 14 16.9167C18.1883 16.9167 21.5833 18.8767 21.5833 21.2917V23.3333H6.41667V21.2917ZM0 23.3333V21.5833C0 19.9617 2.205 18.5967 5.19167 18.2C4.50333 18.9933 4.08333 20.09 4.08333 21.2917V23.3333H0ZM28 23.3333H23.9167V21.2917C23.9167 20.09 23.4967 18.9933 22.8083 18.2C25.795 18.5967 28 19.9617 28 21.5833V23.3333Z'
      fill='currentColor'
    />
  </SVG>
)
