import styled, { css } from 'styled-components'

type Side = 'left' | 'right'

type HoverEffectProps = {
  background: string
  side: Side
  isActive?: boolean
}

export const HoverEffect = ({ isActive, background, side }: HoverEffectProps) => (
  <HoverContainer>
    <HoverBackground $isActive={isActive} $background={background} $side={side} />
    <HoverRegister $side={side} />
  </HoverContainer>
)

const CIRCLE_MARGIN = '-20px'

const backgroundCircleCSS = css`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: -1;
`

const HoverContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
`

export const HoverBackground = styled.div<{ $isActive?: boolean; $background: string; $side: Side }>`
  ${backgroundCircleCSS}
  position: absolute;
  top: ${CIRCLE_MARGIN};
  ${props => (props.$side === 'left' ? `left: ${CIRCLE_MARGIN};` : `right: ${CIRCLE_MARGIN};`)}
  transform: translate(${props => (props.$side === 'left' ? '-100%' : '100%')});
  background: ${props => props.$background};
  transition: ${props => props.theme.transitions.extraFastEase};

  ${props => props.$isActive && 'transform: translate(0);'}
`

const HoverRegister = styled.div<{ $side: Side }>`
  ${backgroundCircleCSS}
  position: absolute;
  top: ${CIRCLE_MARGIN};
  ${props => (props.$side === 'left' ? `left: ${CIRCLE_MARGIN};` : `right: ${CIRCLE_MARGIN};`)}
  pointer-events: auto;
`
