import { useState } from 'react'
import styled from 'styled-components'

import { IconButton, Tooltip } from 'components/generic'
import { CloseIcon } from 'components/icons'

type Props = {
  tooltip: string
  onClose: () => void
}

// TODO: move to 'screens/create-chart/components' and use it also inside create-chart-screen
export const CloseButton = ({ tooltip, onClose }: Props) => {
  const [isTooltipShown, setIsTooltipShown] = useState(false)

  return (
    <StyledIconButton
      onMouseEnter={() => setIsTooltipShown(true)}
      onMouseLeave={() => setIsTooltipShown(false)}
      onClick={onClose}
    >
      <CloseIcon />
      <StyledTooltip isTooltipShown={isTooltipShown}>{tooltip}</StyledTooltip>
    </StyledIconButton>
  )
}

const StyledIconButton = styled(IconButton)`
  color: ${props => props.theme.colors.greyMediumLight};
`

const StyledTooltip = styled(Tooltip)`
  display: none;

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    display: block;
    min-width: 120px;
  }
`
