import { useEffect } from 'react'
import { Redirect, RouteProps } from 'react-router-dom'

import { TrackedRoute } from './tracked-route'
import { LOGIN_PATH } from './constants'
import { useAuth } from 'auth'

export const ProtectedRoute = (routeProps: RouteProps) => {
  const { isAuthenticated, login, getAccessTokenSilently } = useAuth()

  useEffect(() => {
    getAccessTokenSilently().catch(() =>
      login({ redirectPath: `${window.location.pathname}${window.location.search}`, isSilentLogin: true })
    )
  }, [])

  return isAuthenticated ? <TrackedRoute {...routeProps} /> : <Redirect to={LOGIN_PATH} />
}
