import { useMemo } from 'react'
import { useApolloClient } from '@apollo/client'
import { useDrop } from 'react-dnd'

import { useChartId } from '../providers/use-chart-uuid'
import { NodeTypename } from '../types'

import { nodeDataFragment } from 'apollo/fragments'
import { NodeDataFragmentFragment, useNodesQuery } from 'apollo/generated/graphql'

type UseUnassignedNodesConfig = {
  onDrop?: (node: NodeDataFragmentFragment) => void
}

export const useUnassignedNodes = ({ onDrop }: UseUnassignedNodesConfig = {}) => {
  const chartKey = useChartId()
  const apolloClient = useApolloClient()
  const { data, loading, error } = useNodesQuery({ variables: { chartKey, filter: { unassigned: true } } })

  const nodes = useMemo(() => {
    return (data?.nodes?.items as NodeDataFragmentFragment[]) || []
  }, [data?.nodes?.items])

  const acceptedNodes: NodeTypename[] = ['Person', 'Department']
  const [{ canDrop, isOver }, dropRef] = useDrop({
    accept: acceptedNodes,
    drop: (item: { __typename: NodeTypename; id: string; parentUuid?: string }) => {
      const node = apolloClient.readFragment<NodeDataFragmentFragment>({
        id: apolloClient.cache.identify(item),
        fragment: nodeDataFragment,
        fragmentName: 'NodeDataFragment',
      })
      if (!node) return
      onDrop && onDrop(node)
    },
    canDrop: item => !nodes.find(node => node.uuid === item.id)?.unassigned,
    collect: monitor => ({ canDrop: monitor.canDrop(), isOver: monitor.isOver() }),
  })

  return {
    canDrop,
    dropRef,
    error,
    isOver,
    loading,
    nodes,
  }
}
