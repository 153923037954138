import styled, { css } from 'styled-components'

type TitleSize = 'small' | 'medium'
export type ModalTitleProps = {
  text: string
  size?: TitleSize
}

const sizeSmallCSS = css`
  font-size: 14px;
  line-height: 20px;
`

export const ModalTitle = styled.div<{
  size?: TitleSize
}>`
  position: relative;
  top: -${props => props.theme.spaces.l};
  margin-right: -${props => props.theme.spaces.l};
  margin-left: -${props => props.theme.spaces.l};
  width: calc(${props => props.theme.spaces.l} * 2 + 100%);
  min-height: 56px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.l};
  color: ${props => props.theme.colors.dark};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  ${props => props.size === 'small' && sizeSmallCSS}

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${props => props.theme.colors.greyExtraLight};
  }
`
