import { ReactNode, MouseEvent, ReactElement } from 'react'
import styled, { css } from 'styled-components'

import { ConditonalWrapper } from '../conditional-wrapper'

import { Link } from '../link'
import { SpacingProps, applySpacingProps } from 'helpers'
import { mainTheme } from 'theme'

export type ButtonType = 'primary' | 'secondary' | 'outlined' | 'link'
export type ButtonSize = 'tiny' | 'small' | 'normal' | 'large'
export type ElSpacing = keyof typeof mainTheme.spaces | 'none'

type Props = {
  children?: ReactNode
  type?: ButtonType
  size?: ButtonSize
  href?: string
  target?: string
  spacing?: SpacingProps
  disabled?: boolean
  outlineIsOnDark?: boolean
  className?: string
  isSubmitButton?: boolean
  onClick?: (e: MouseEvent<HTMLElement>) => void

  leftEl?: ReactNode
  rightEl?: ReactNode
  elSpacing?: ElSpacing
}

export const TilButton = ({
  leftEl,
  rightEl,
  children,
  spacing,
  disabled,
  href,
  target,
  className,
  isSubmitButton,
  onClick,
  outlineIsOnDark,

  type = 'primary',
  size = 'normal',
  elSpacing = 'm',
}: Props) => (
  <ConditonalWrapper
    condition={Boolean(href)}
    wrapper={(children: ReactElement) => (
      <StyledLink href={href ?? ''} target={target ?? '_blank'}>
        {children}
      </StyledLink>
    )}
  >
    <StyledButton
      onClick={onClick}
      className={className}
      $type={type}
      $size={size}
      $spacing={spacing}
      $outlineIsOnDark={outlineIsOnDark}
      $disabled={disabled}
      {...(isSubmitButton ? { type: 'submit' } : { type: 'button' })}
    >
      {leftEl && <LeftEl $elSpacing={elSpacing}>{leftEl}</LeftEl>}
      {children}
      {rightEl && <RightEl $elSpacing={elSpacing}>{rightEl}</RightEl>}
    </StyledButton>
  </ConditonalWrapper>
)

const flexCentring = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

const buttonInitialCSS = css`
  ${flexCentring}
  border: none;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 500;
  font-size: 13px;
  appearance: none;
  user-select: none;
  transition: all ${props => props.theme.transitions.extraFastEase};
`

const buttonDisabledCSS = css`
  cursor: default;
  pointer-events: none;
  cursor: not-allowed;
  background: ${mainTheme.colors.greyActive};
`

const StyledLink = styled(Link)`
  &,
  &:hover {
    box-shadow: none;
  }
`

const StyledButton = styled.button<{
  $type: ButtonType
  $size: ButtonSize
  $outlineIsOnDark?: boolean
  $disabled?: boolean
  $spacing?: SpacingProps
}>`
  ${buttonInitialCSS}

  ${({ $type, $outlineIsOnDark }) => handleColor({ $type, $outlineIsOnDark })};
  ${({ $size }) => handleSize($size)};

  &:hover {
    ${({ $type, $outlineIsOnDark }) => handleColor({ $type, $outlineIsOnDark, $isHover: true })};
  }

  ${props => props.$disabled && buttonDisabledCSS}
  ${props => applySpacingProps(props.$spacing)}
`

const LeftEl = styled.div<{ $elSpacing: ElSpacing }>`
  ${flexCentring}
  margin-right: ${props => (props.$elSpacing === 'none' ? 0 : mainTheme.spaces[props.$elSpacing])};
`

const RightEl = styled.div<{ $elSpacing: ElSpacing }>`
  ${flexCentring}
  margin-left: ${props => (props.$elSpacing === 'none' ? 0 : mainTheme.spaces[props.$elSpacing])};
`

const handleSize = (size: ButtonSize) => {
  switch (size) {
    case 'tiny':
      return `height: 24px; padding: 0 ${mainTheme.spaces.m}`
    case 'small':
      return `height: 34px; padding: 0 ${mainTheme.spaces.m}`
    case 'normal':
      return `height: 40px; padding: 0 ${mainTheme.spaces.l}`
    case 'large':
      return `height: 44px; padding: 0 ${mainTheme.spaces.l}`
  }
}

type HandleColorProps = {
  $type: ButtonType
  $isHover?: boolean
  $outlineIsOnDark?: boolean
}

const handleColor = ({ $type, $isHover, $outlineIsOnDark }: HandleColorProps) => {
  switch ($type) {
    case 'primary':
      return `color: ${mainTheme.colors.white}; background: ${mainTheme.colors[$isHover ? 'purpleDark' : 'purple']}`
    case 'secondary':
      return `color: ${mainTheme.colors[$isHover ? 'purple' : 'purpleDark']};
      background: ${mainTheme.colors[$isHover ? 'neutralSecondary' : 'white']}`
    case 'outlined':
      return `color: ${mainTheme.colors[$outlineIsOnDark ? 'white' : 'dark']};
      background: transparent; box-shadow: inset 0 0 0 ${$isHover ? '2px' : '1px'}
      ${mainTheme.colors.greyExtraLight}; border-radius: 6px`
    case 'link':
      return `color: ${mainTheme.colors[$isHover ? 'greyLight' : 'greyLightSecond']}; background: transparent`
  }
}
