import styled from 'styled-components'

import { CardTitle } from '../card'
import { UpgradeSubscriptionButton, useGoToPremiumScreen } from 'features/premium'
import { FlexBox, LinkStyles, ListItem, UnsortedList } from 'components/generic'
import { ImageFileIcon, OrgchartCompactStructureIcon, OrgchartStandardStructureIcon, PdfIcon } from 'components/icons'

import { downloadChartSample } from '../../helpers'
import { SpacingProps } from 'helpers'
import { colors, spaces } from 'theme'

type Props = {
  spacing?: SpacingProps
}

export const ExportPremiumBenefits = ({ spacing }: Props) => {
  const { goToPremiumScreen } = useGoToPremiumScreen()

  return (
    <FlexBox $isDirectionColumn $spacing={spacing}>
      <CardTitle>Export in style</CardTitle>
      <Content>
        <FlexBox $isDirectionColumn>
          <Description>Leverage your organizational charts outside of our tool with PDF and PNG exports.</Description>
          <UnsortedList $gap='m'>
            <StyledListItem
              iconColor={colors.linkColor}
              spacing={{ mb: spaces.s }}
              onClick={() => downloadChartSample('pdf')}
            >
              <LinkStyled>
                Download <PdfIcon color={colors.solidRed} width='14px' $spacing={{ mx: '4px', mb: '4px' }} /> PDF Sample
              </LinkStyled>
            </StyledListItem>
            <StyledListItem iconColor={colors.linkColor} onClick={() => downloadChartSample('png')}>
              <LinkStyled>
                Download <ImageFileIcon color={colors.googleBlue} width='11px' height='13px' $spacing={{ mx: '6px' }} />{' '}
                PNG Sample
              </LinkStyled>
            </StyledListItem>
          </UnsortedList>

          <FlexBox $spacing={{ mt: spaces.l }}>
            <UpgradeSubscriptionButton spacing={{ mt: spaces.m }} />
          </FlexBox>
        </FlexBox>

        <TypeSelectContainer $isDirectionColumn $isYCentered>
          <BackgroundCircle />
          <SmallTitle>Choose format</SmallTitle>
          <FlexBox $spacing={{ mb: spaces.l }} $gap='s'>
            <PDFMiniCard onClick={() => goToPremiumScreen()}>
              <PdfIcon width='32px' />
              PDF
            </PDFMiniCard>
            <PNGMiniCard onClick={() => goToPremiumScreen()}>
              <ImageFileIcon width='24px' />
              PNG
            </PNGMiniCard>
          </FlexBox>

          <SmallTitle>Choose type</SmallTitle>
          <FlexBox $gap='s'>
            <MiniCard onClick={() => goToPremiumScreen()}>
              <OrgchartStandardStructureIcon width='36px' height='28px' color={colors.purple} />
              Standard
            </MiniCard>
            <MiniCard onClick={() => goToPremiumScreen()}>
              <OrgchartCompactStructureIcon width='28px' height='30px' color={colors.purple} />
              Compact
            </MiniCard>
          </FlexBox>
        </TypeSelectContainer>
      </Content>
    </FlexBox>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    flex-direction: row;
  }
`

const Description = styled.div`
  max-width: 340px;
  margin-bottom: ${props => props.theme.spaces.l};
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.dark};
`

const StyledListItem = styled(ListItem)`
  justify-self: flex-start;
`

const LinkStyled = styled.div`
  ${LinkStyles}
  color: ${props => props.theme.colors.purple};
`

const TypeSelectContainer = styled(FlexBox)`
  position: relative;
  max-width: 320px;
  margin-top: 64px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    margin-top: -40px;
    margin-left: auto;
  }
`

const BackgroundCircle = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 320px;
  height: 320px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.purpleExtraLight};
  z-index: -1;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    top: -30px;
    right: -50px;
    left: unset;
    transform: unset;
  }
`

const SmallTitle = styled.div`
  margin-bottom: ${props => props.theme.spaces.m};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.colors.dark};
`

const MiniCard = styled.div`
  width: 120px;
  height: 85px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.spaces.m};
  box-shadow: 0px 1.93295px 3.8659px rgba(69, 90, 100, 0.16);
  border-radius: 4px;
  background: ${props => props.theme.colors.white};

  ${props => props.theme.animations.opacityHover}
`

const PDFMiniCard = styled(MiniCard)`
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.solidRed};
`

const PNGMiniCard = styled(MiniCard)`
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.googleBlue};
`
