import { SVG, SVGProps } from './SVG'

export const CheckmarkIcon = (props: SVGProps) => (
  <SVG width='8' height='6' viewBox='0 0 8 6' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2.65183 5.89436L0.106364 3.33145C-0.0354547 3.19044 -0.0354547 2.96092 0.106364 2.81841L0.620549 2.30613C0.762368 2.16512 0.992914 2.16512 1.13473 2.30613L2.90929 4.10024L6.86495 0.106245C7.00677 -0.0347639 7.23731 -0.0347639 7.37913 0.106245L7.89332 0.619277C8.03513 0.760286 8.03513 0.99055 7.89332 1.13081L3.16602 5.89436C3.0242 6.03537 2.79365 6.03537 2.65183 5.89436Z'
      fill='currentColor'
    />
  </SVG>
)
