import { useState, ReactNode, useEffect } from 'react'
import styled from 'styled-components'

import { applySpacingProps, SpacingProps } from 'helpers'

export type ImageWithFallbackProps = {
  alt?: string
  className?: string
  draggable?: boolean
  fallbackIcon?: ReactNode
  size?: string
  spacing?: SpacingProps
  src?: string | null
}

export const ImageWithFallback = ({
  fallbackIcon,
  size = '42px',
  spacing,
  src,
  ...imgProps
}: ImageWithFallbackProps) => {
  const [displayFallback, setDisplayFallback] = useState(!src)

  useEffect(() => {
    if (displayFallback && src) setDisplayFallback(false)
  }, [src])

  if (displayFallback) return fallbackIcon ? <>{fallbackIcon}</> : null
  return (
    <Image $size={size} $spacing={spacing} src={src || ''} onError={() => setDisplayFallback(true)} {...imgProps} />
  )
}

const Image = styled.img<{ $size?: string; $spacing?: SpacingProps }>`
  min-width: ${props => props.$size};
  max-width: ${props => props.$size};
  min-height: ${props => props.$size};
  max-height: ${props => props.$size};
  object-fit: cover;
  border-radius: 50%;

  ${props => applySpacingProps(props.$spacing)}
`
