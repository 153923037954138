import { ReactNode, useState, cloneElement } from 'react'
import styled, { css } from 'styled-components'

type Props = {
  children: ReactNode
  tooltip: JSX.Element
  onClick: () => void
  className?: string
  isActive?: boolean
  isClickable?: boolean
  isDisabled?: boolean
  // data-testid
}

export const ChartControlButton = ({ children, tooltip, ...rest }: Props) => {
  const [isTooltipShown, setIsTooltipShown] = useState(false)

  return (
    <Button onMouseEnter={() => setIsTooltipShown(true)} onMouseLeave={() => setIsTooltipShown(false)} {...rest}>
      {children}
      {cloneElement(tooltip, { isTooltipShown })}
    </Button>
  )
}

const Button = styled.div<{
  isActive?: boolean
  isClickable?: boolean
  isDisabled?: boolean
}>`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.white};
  font-size: 14px;
  cursor: pointer;
  z-index: 1;

  ${props => props.theme.animations.backgroundShapeFill};

  ${props =>
    props.isActive &&
    css`
      color: ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.purple};
      cursor: default;
      pointer-events: none;

      &:before {
        background: ${props => props.theme.colors.purple};
      }
    `}

  ${props =>
    props.isClickable &&
    css`
      cursor: pointer;
      pointer-events: auto;
    `}

  &:before {
    content: ${props => (props.isActive && props.isClickable ? 'none' : '')};
  }

  ${props =>
    props.isDisabled &&
    css`
      color: ${props => props.theme.colors.greyExtraLight};
      cursor: default;
      pointer-events: none;
    `}

  @media (max-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    width: 32px;
    height: 32px;
  }
`
