import styled from 'styled-components'

import { UpgradeSubscriptionButton } from './upgrade-subscription-button'
import { FlipClockCountdown } from 'components/generic'

import { useChartLockCountdown } from './hooks'
import { colors, spaces } from 'theme'

type Props = {
  chartLockDate: Date | string
}

export const ChartCardCountdownOverlay = ({ chartLockDate }: Props) => {
  const { timeRemaining, background, clockBackground } = useChartLockCountdown({ chartLockDate })
  const { days, hours, minutes } = timeRemaining

  return (
    <Overlay>
      <BackgroundFade $backgroundColor={background} />
      <Content $backgroundColor={background}>
        <FlipClockCountdown
          countdown={{ days, hours, minutes }}
          background={clockBackground}
          color={colors.white}
          flipPadWidth='32px'
          flipPadHeight='36px'
        />
        <Title>Upgrade and avoid your org chart lockout</Title>
        <UpgradeSubscriptionButton spacing={{ px: spaces.m }} />
      </Content>
    </Overlay>
  )
}

const Overlay = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 3;
`

const BackgroundFade = styled.span<{ $backgroundColor: string }>`
  width: 100%;
  height: 24px;
  background: linear-gradient(180deg, rgba(250, 226, 228, 0) 0%, ${props => props.$backgroundColor} 100%);
`

const Content = styled.div<{ $backgroundColor: string }>`
  width: 100%;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${props => props.theme.spaces.s};
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.s} ${props => props.theme.spaces.m};
  padding-bottom: ${props => props.theme.spaces.m};
  background: ${props => props.$backgroundColor};

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    justify-content: space-around;
    flex-wrap: nowrap;
    gap: ${props => props.theme.spaces.m};
    padding-bottom: ${props => props.theme.spaces.s};
  }
`

const Title = styled.span`
  color: ${props => props.theme.colors.dark};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    max-width: 180px;
  }
`
