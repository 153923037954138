import { FC } from 'react'
import { generatePath, useRouteMatch } from 'react-router-dom'
import { ConnectGoogleWorkspace, ConnectGoogleWorkspaceProps } from './connect-google-workspace'

type WithConnectGoogleWorkspaceOptions = { component?: FC<WrapperProps>; redirectPath?: string }
type WrapperProps = ConnectGoogleWorkspaceProps & { redirectPath?: string }

function ConnectGoogleWorkspaceWrapper(props: WrapperProps & { component?: FC<WrapperProps> }) {
  const { params } = useRouteMatch<Record<string, string>>()
  const { redirectPath, component: Component } = props
  const redirectUrl = redirectPath && generatePath(redirectPath, params)

  const ConnectComponent = Component ?? ConnectGoogleWorkspace

  return <ConnectComponent {...props} redirectUrl={redirectUrl} />
}

export function withConnectGoogleWorkspace({ component, redirectPath }: WithConnectGoogleWorkspaceOptions) {
  return function ConnectGoogleWorkspaceWrap(props: ConnectGoogleWorkspaceProps) {
    return <ConnectGoogleWorkspaceWrapper {...props} component={component} redirectPath={redirectPath} />
  }
}
