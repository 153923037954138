import { SVG, SVGProps } from './SVG'

export const EyeCrossedIcon = (props: SVGProps) => (
  <SVG width='14' height='14' viewBox='0 0 14 14' fill='none' {...props}>
    <path
      d='M6.90075 5.25L8.74992 7.09333C8.74992 7.06417 8.74992 7.02917 8.74992 7C8.74992 6.53587 8.56554 6.09075 8.23736 5.76256C7.90917 5.43437 7.46405 5.25 6.99992 5.25C6.96492 5.25 6.93575 5.25 6.90075 5.25ZM4.39242 5.71667L5.29659 6.62083C5.26742 6.74333 5.24992 6.86583 5.24992 7C5.24992 7.46413 5.43429 7.90925 5.76248 8.23744C6.09067 8.56563 6.53579 8.75 6.99992 8.75C7.12825 8.75 7.25658 8.7325 7.37908 8.70333L8.28325 9.6075C7.89242 9.8 7.46075 9.91667 6.99992 9.91667C6.22637 9.91667 5.4845 9.60938 4.93752 9.0624C4.39054 8.51541 4.08325 7.77355 4.08325 7C4.08325 6.53917 4.19992 6.1075 4.39242 5.71667ZM1.16659 2.49083L2.49659 3.82083L2.75909 4.08333C1.79659 4.84167 1.03825 5.83333 0.583252 7C1.59242 9.56083 4.08325 11.375 6.99992 11.375C7.90409 11.375 8.76742 11.2 9.55492 10.885L9.80575 11.13L11.5091 12.8333L12.2499 12.0925L1.90742 1.75L1.16659 2.49083ZM6.99992 4.08333C7.77347 4.08333 8.51533 4.39062 9.06231 4.93761C9.60929 5.48459 9.91658 6.22645 9.91658 7C9.91658 7.37333 9.84075 7.735 9.70658 8.06167L11.4158 9.77083C12.2908 9.04167 12.9908 8.085 13.4166 7C12.4074 4.43917 9.91658 2.625 6.99992 2.625C6.18325 2.625 5.40159 2.77083 4.66659 3.03333L5.93242 4.2875C6.26492 4.15917 6.62075 4.08333 6.99992 4.08333Z'
      fill='currentColor'
    />
  </SVG>
)
