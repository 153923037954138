import { SVG, SVGProps } from './SVG'

export const ChainLinkIcon = (props: SVGProps) => (
  <SVG width='96' height='75' viewBox='0 0 96 75' fill='none' {...props}>
    <path
      d='M89.2688 39.2125C98.2437 30.2376 98.2437 15.703 89.2688 6.72805C81.3264 -1.21437 68.8091 -2.24688 59.6754 4.28179L59.4212 4.45652C57.1338 6.09266 56.6096 9.26962 58.2457 11.5412C59.8819 13.8127 63.0588 14.3528 65.3304 12.7166L65.5845 12.5419C70.6835 8.90427 77.657 9.47613 82.073 13.908C87.0767 18.9117 87.0767 27.013 82.073 32.0167L64.2502 49.8713C59.2465 54.875 51.1452 54.875 46.1415 49.8713C41.7096 45.4394 41.1378 38.466 44.7754 33.3828L44.9501 33.1286C46.5863 30.8412 46.0462 27.6643 43.7746 26.044C41.5031 24.4238 38.3103 24.948 36.69 27.2195L36.5153 27.4736C29.9707 36.5915 31.0032 49.1088 38.9456 57.0512C47.9206 66.0261 62.4552 66.0261 71.4301 57.0512L89.2688 39.2125ZM6.7312 35.4955C-2.24373 44.4704 -2.24373 59.005 6.7312 67.98C14.6736 75.9224 27.1909 76.9549 36.3246 70.4262L36.5788 70.2515C38.8662 68.6154 39.3904 65.4384 37.7543 63.1669C36.1181 60.8953 32.9412 60.3553 30.6696 61.9914L30.4155 62.1661C25.3165 65.8038 18.343 65.2319 13.927 60.8C8.92331 55.7804 8.92331 47.6792 13.927 42.6754L31.7498 24.8368C36.7535 19.833 44.8548 19.833 49.8585 24.8368C54.2904 29.2686 54.8622 36.2421 51.2246 41.3411L51.0499 41.5953C49.4138 43.8827 49.9538 47.0596 52.2254 48.6799C54.4969 50.3002 57.6898 49.776 59.31 47.5044L59.4847 47.2503C66.0293 38.1165 64.9968 25.5992 57.0544 17.6568C48.0794 8.68188 33.5448 8.68188 24.5699 17.6568L6.7312 35.4955Z'
      fill='currentColor'
    />
  </SVG>
)
