import { generatePath, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { InviteMembers, InviteNonMembers } from '../invite-colleagues'
import { Button, FlexBox, IconButton } from 'components/generic'
import { CloseIcon, OrgchartFullLogo } from 'components/icons'

import { useChartData } from 'tree/providers'
import { useInviteCollaboratorsPanel, useTopNavigationToggle } from 'providers'
import { CHART_EDIT_PATH } from 'routes'
import { colors, spaces } from 'theme'

import { CollaboratorRole } from 'apollo/generated/graphql'

export const InviteCollaborators = () => {
  const { setIsShown } = useInviteCollaboratorsPanel()
  const { setShow: showTopNav } = useTopNavigationToggle()

  const { userPermission, id: chartUuid } = useChartData()
  const loggedUserChartRole = userPermission?.role as CollaboratorRole
  const history = useHistory()

  const handleNext = () => {
    history.push(generatePath(CHART_EDIT_PATH, { chartUuid }))
    setIsShown(false)
    showTopNav(true)
  }

  if (!loggedUserChartRole) {
    handleNext()
    return null
  }

  return (
    <Container>
      <Content>
        <CloseIconButton size='small' onClick={handleNext} data-testid='button-close-node-side-panel'>
          <CloseIcon />
        </CloseIconButton>

        <OrgchartFullLogo $spacing={{ mb: spaces.xl }} color={colors.dark} />
        <Heading>Invite collaborators to help you build your Org Chart</Heading>

        <InviteMembers chartUuid={chartUuid} loggedUserChartRole={loggedUserChartRole} initValue='HR' />
        <InviteNonMembers chartUuid={chartUuid} loggedUserChartRole={loggedUserChartRole} />

        <FlexBox $spacing={{ mt: spaces.xxl }}>
          <Button spacing={{ mr: spaces.m }} buttonType='secondary' onClick={handleNext}>
            Skip sharing
          </Button>
          <Button onClick={handleNext}>Go to your Org Chart</Button>
        </FlexBox>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => props.theme.spaces.xl} ${props => props.theme.spaces.l};
`

const Content = styled.div`
  width: 100%;
  max-width: 460px;
`

const Heading = styled.div`
  margin-bottom: ${props => props.theme.spaces.l};
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.theme.colors.dark};
`

const CloseIconButton = styled(IconButton)`
  position: absolute;
  right: 30px;
  color: ${props => props.theme.colors.greyLight};
`
