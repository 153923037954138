import styled from 'styled-components'

export const Centering = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  line-height: 16px;
  cursor: default;
  animation: ${props => props.theme.keyframes.opacity} ${props => props.theme.transitions.fastEase};
`
