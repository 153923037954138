import { useState, useEffect } from 'react'

import CountUp from 'react-countup'

import { TableCol, TableRow, TableBody, TableDescIcon, TableAscIcon, NotAvailable } from './styled/table'

import { WidgetHeading, WidgetContainer } from '../General'
import { TrendValue } from '../TrendValue'
import { Label } from '../styled'
import { CompanyOverviewTableRow } from '../../types'
import { sortNumbers, sortStrings } from 'helpers'
import analytics from 'analytics'
import { INSIGHTS_TRACK } from 'analytics/constants'

type Props = {
  title: string
  valueColLabel?: string
  rows: CompanyOverviewTableRow[]
  activeMonth?: number
  chartUuid?: string
}

type ColType = keyof CompanyOverviewTableRow

export const CompanyOverviewTable = ({ title, rows, valueColLabel, chartUuid }: Props) => {
  const [tableRows, setRows] = useState(rows)
  const [sortedBy, setSortedBy] = useState<ColType>('value')
  const [isDesc, setIsDesc] = useState<boolean>(true)

  useEffect(() => {
    sortAndSetRows(sortedBy, rows)
  }, [rows])

  const sortAndSetRows = (type: ColType, rows?: CompanyOverviewTableRow[]) => {
    const isDescActual = rows ? isDesc : sortedBy === type ? !isDesc : true
    setRows(sortRows(rows ?? tableRows, type, isDescActual))
    setIsDesc(isDescActual)
    setSortedBy(type)
  }

  const sortArrow = isDesc ? <TableDescIcon /> : <TableAscIcon />
  const colTitle = 'Click to sort according to this column'

  if (!rows || rows.length === 0 || !tableRows) return null

  return (
    <WidgetContainer>
      <WidgetHeading>{title}</WidgetHeading>
      <TableRow isHeader>
        <TableCol
          onClick={() => {
            sortAndSetRows('dep')
            analytics.track(INSIGHTS_TRACK.sortTableByDepartment, { chartUuid })
          }}
          isActive={sortedBy === 'dep'}
          title={colTitle}
        >
          Deparment{sortedBy === 'dep' && sortArrow}
        </TableCol>
        <TableCol
          onClick={() => {
            sortAndSetRows('value')
            analytics.track(INSIGHTS_TRACK.sortTableByValue, { chartUuid })
          }}
          isActive={sortedBy === 'value'}
          title={colTitle}
        >
          {valueColLabel ?? 'Value'}
          {sortedBy === 'value' && sortArrow}
        </TableCol>
        <TableCol
          onClick={() => {
            sortAndSetRows('trend')
            analytics.track(INSIGHTS_TRACK.sortTableByTrend, { chartUuid })
          }}
          isActive={sortedBy === 'trend'}
          title={colTitle}
        >
          {sortedBy === 'trend' && sortArrow}From previous month
        </TableCol>
      </TableRow>
      <TableBody>
        {tableRows.map((row, i) => (
          <TableRow key={row.dep + i} isHighlighted={row.dep === 'Company avarage'}>
            <TableCol>
              <Label value={row.dep} />
            </TableCol>
            <TableCol>
              <CountUp end={row.value} duration={1} preserveValue />
            </TableCol>
            <TableCol>
              {isNaN(row.trend) ? <NotAvailable>N/A</NotAvailable> : <TrendValue value={row.trend} />}
            </TableCol>
          </TableRow>
        ))}
      </TableBody>
    </WidgetContainer>
  )
}

const sortRows = (array: CompanyOverviewTableRow[], type: ColType, isDescActual: boolean) =>
  [...array].sort((a, b) => {
    if (type === 'dep') return sortStrings(a[type], b[type], isDescActual)
    return sortNumbers(a[type], b[type], isDescActual)
  })
