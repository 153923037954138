import { generatePath } from 'react-router-dom'

import { useResyncChartRequest } from './use-resync-chart-request'
import { WithConfirmModal } from '../../types'
import { useResyncModal } from 'components/specific'
import analytics from 'analytics'
import { PERMISSIONS_TRACK } from 'analytics/constants'
import { CHART_EDIT_GW_PATH } from 'routes'

type ResyncChartActionParams = {
  chartUuid: string
  redirectPath?: string
}

export const useResyncChartAction = () => {
  const { resyncChartRequest } = useResyncChartRequest()
  const { openResyncModal } = useResyncModal()

  const resyncChart = async ({ chartUuid, redirectPath }: ResyncChartActionParams) => {
    const pathParams = { chartUuid }
    const newRedirectPath = redirectPath ?? generatePath(CHART_EDIT_GW_PATH, pathParams)
    const res = await resyncChartRequest(newRedirectPath)
    analytics.track(PERMISSIONS_TRACK.chartResync)
    return Promise.resolve(res)
  }

  const resyncChartWithConfirm = async ({ onAbort, ...params }: ResyncChartActionParams & WithConfirmModal) => {
    return new Promise((resolve, reject) => {
      let hasConfirmed = false
      openResyncModal({
        onResync: () => {
          hasConfirmed = true
          resyncChart(params).catch(reject).then(resolve)
        },
        onClose: () => {
          if (!hasConfirmed) onAbort?.()
        },
      })
    })
  }

  return { resyncChart, resyncChartWithConfirm }
}
