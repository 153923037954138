import { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'

import { Button, IconButton } from 'components/generic'
import { CloseIcon } from 'components/icons'

import { useNodesInViewport, useTree } from 'tree/providers'
import { COMPANY_UUID } from 'consts'
import { spaces } from 'theme'

export const ResetPositionHelper = () => {
  const { resetPosition } = useTree()
  const { areNodesInViewport, addNodeToViewport } = useNodesInViewport()
  const [isClosed, setIsClosed] = useState(false)

  const handleReset = () => {
    resetPosition()
    addNodeToViewport(COMPANY_UUID)
  }

  const handleClose = () => {
    setIsClosed(true)
  }

  useEffect(() => {
    // Reset user choice
    if (isClosed && areNodesInViewport) {
      setIsClosed(false)
    }
  }, [areNodesInViewport, isClosed])

  if (areNodesInViewport || isClosed) return null

  return (
    <ResetPositionHelperInner>
      <Content>
        <StyledIconButton size='small' spacing={{ ml: 'auto' }} onClick={handleClose} data-testid='button-close-modal'>
          <CloseIcon />
        </StyledIconButton>
        Can&apos;t find your Org Chart?
        <StyledButton spacing={{ mt: spaces.l }} onClick={handleReset}>
          Return to Org Chart
        </StyledButton>
      </Content>
    </ResetPositionHelperInner>
  )
}

const fadeIn = keyframes`
  0%,
  84% {
    display: none;
    opacity: 0;
  }
  85% {
    display: flex;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`
const ResetPositionHelperInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
  pointer-events: none;
  animation: ${fadeIn} 2s linear;
`

const Content = styled.div`
  position: relative;
  min-width: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.theme.spaces.l};
  border-radius: 3px;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 2px 3px rgba(179, 185, 207, 0.5);
  text-align: center;
  color: ${props => props.theme.colors.dark};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 8px;
  right: 8px;
  pointer-events: auto;
`

const pointerEventsButton = keyframes`
  0%,
  84% {
    pointer-events: none;
  }
  85%,
  100% {
    pointer-events: auto;
  }
`
const StyledButton = styled(Button)`
  pointer-events: auto;
  animation: ${pointerEventsButton} 2s linear;
`
