import { createGlobalStyle } from 'styled-components'

export const SociomapStyle = createGlobalStyle`
 .socView .socLabel{
    user-select:none;
    width:fit-content
}
.socView .socLabel.socSelected .socText,.socView .socLabel:hover .socText{
    text-shadow:-1px -1px 2px #000,1px -1px 2px #000,-1px 1px 2px #000,1px 1px 2px #000
}
.socView .socLabel.socSelected:hover .socText{
    text-shadow:-1px -1px 4px #000,1px -1px 4px #000,-1px 1px 4px #000,1px 1px 4px #000
}
.socView .socText{
    /* extra styles - start */
    text-overflow: ellipsis;
    overflow: hidden; 
    max-width: 200px;
    padding: 1px 3px;
    padding-bottom: 4px;
    /* extra styles - end */
    position:absolute;
    text-shadow:1px 1px 2px #000;
    color:#fff;
    font-family:Arial,Helvetica,sans-serif;
    font-weight:600;
    font-size:16px;
    white-space:nowrap;
    transition:transform .5s ease;
    --WDT:-100%;
    --HGT:-100%;
    --CRC:12px;
    --DGS:8px
}
/* extra styles - start */
.socView .socText:hover{
    text-overflow: initial;
    overflow: visible; 
}
/* extra styles - end */
.socView .socLabel.socShift_S .socText{
    transform:translate(calc(var(--WDT) * .5),calc(var(--CRC)))
}
.socView .socLabel.socShift_N .socText{
    transform:translate(calc(var(--WDT) * .5),calc(var(--HGT) - var(--CRC)))
}
.socView .socLabel.socShift_W .socText{
    transform:translate(calc(var(--WDT) - var(--CRC)),calc(var(--HGT) * .5))
}
.socView .socLabel.socShift_E .socText{
    transform:translate(calc(var(--CRC)),calc(var(--HGT) * .5))
}
.socView .socLabel.socShift_SW .socText{
    transform:translate(calc(var(--WDT) - var(--CRC) + var(--DGS)),calc(var(--CRC) - var(--DGS)))
}
.socView .socLabel.socShift_NW .socText{
    transform:translate(calc(var(--WDT) - var(--CRC) + var(--DGS)),calc(var(--HGT) - var(--CRC) + var(--DGS)))
}
.socView .socLabel.socShift_NE .socText{
    transform:translate(calc(var(--CRC) - var(--DGS)),calc(var(--HGT) - var(--CRC) + var(--DGS)))
}
.socView .socLabel.socShift_SE .socText{
    transform:translate(calc(var(--CRC) - var(--DGS)),calc(var(--CRC) - var(--DGS)))
}
.socView .socCircle{
    display:block;
    position:absolute;
    width:8px;
    height:8px;
    box-shadow:0 0 4px #000;
    border:2px solid #fff;
    border-radius:100% 100% 100% 0;
    margin-left:-6px;
    margin-top:-6px;
    transition:transform .3s ease;
    transform:translate(0,0) rotate(-45deg)
}
.socView .socLabel.socShift_S .socCircle{
    transform:rotate(-45deg)
}
.socView .socLabel.socShift_SE .socCircle{
    transform:rotate(-90deg)
}
.socView .socLabel.socShift_E .socCircle{
    transform:rotate(-135deg)
}
.socView .socLabel.socShift_NE .socCircle{
    transform:rotate(-180deg)
}
.socView .socLabel.socShift_N .socCircle{
    transform:rotate(-225deg)
}
.socView .socLabel.socShift_NW .socCircle{
    transform:rotate(-270deg)
}
.socView .socLabel.socShift_W .socCircle{
    transform:rotate(-315deg)
}
.socView .socLabel.socShift_SW .socCircle{
    transform:rotate(0)
}
.socView .socLabel:hover .socCircle{
    box-shadow:0 0 2px 2px #000;
    background-color:#fff
}
.socView .socDimmed .socLabel{
    opacity:.25!important
}
.socView .socDimmed .socLabel.socSelected{
    opacity:1!important
}
@keyframes socCircleAnim{
    0%{
        transform:scale(1);
        background:#fff
    }
    100%{
        transform:scale(2);
        background:0 0
    }
}
.socView .socDimmed .socLabel.socSelected .socCircle{
    border-radius:100%;
    transform:scale(1.5);
    animation-name:socCircleAnim;
    animation-duration:.3s;
    animation-timing-function:ease-in;
    animation-direction:alternate;
    animation-iteration-count:infinite;
    border:2px solid #fff;
    background:#fff
}
@keyframes socDashAnim{
    to{
        stroke-dashoffset:-1000
    }
}
@keyframes socDotsAnim{
    to{
        stroke-dashoffset:0!important;
        stroke-dasharray:1 8;
        stroke-width:4
    }
}
.socView .socArrowsHolder{
    pointer-events:none
}
.socView svg.socArrows{
    position:absolute;
    display:block;
    width:100%;
    height:100%;
    pointer-events:none;
    filter:url(#soc-arrow-blur)
}
.socView svg .soc-arrow-end,.socView svg .soc-arrow-start{
    fill:#ffd000
}
.socView .socArrowsHolder.socHidden{
    visibility:hidden
}
.socView .socArrow{
    marker-end:url(#soc-arrow-end);
    stroke:#ffd000;
    stroke-width:3
}
.socView .socArrow.socDouble{
    marker-start:url(#soc-arrow-start)
}
.socView .socAnim .socArrow{
    marker-end:url(#soc-arrow-end-anim);
    animation:socDashAnim 30s linear infinite;
    stroke-dasharray:8
}
.socView .socAnim .socArrow.socDouble{
    marker-start:url(#soc-arrow-start-anim);
    marker-end:url(#soc-arrow-end-anim);
    stroke:#ffa600;
    animation:none;
    stroke-dasharray:none
}
.socView .socArrows.socAnim .socArrow.socStretch{
    marker-start:none;
    marker-end:none;
    stroke:#fff;
    animation:socDotsAnim 1s ease-in alternate infinite;
    stroke-dasharray:1 6;
    stroke-linecap:round
}
.socView .socArrows.socAnim .socArrow.socFlow{
    marker-start:none;
    marker-end:none;
    stroke:#fff;
    animation:socDashAnim 30s linear infinite;
    stroke-dasharray:1 6;
    stroke-linecap:round
}
.socView.socNoAnim .socAnim .socArrow,.socView.socNoAnim .socCircle,.socView.socNoAnim .socText{
    animation:none!important;
    transition:none!important
}

`
