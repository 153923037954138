import { ChartConfig, useChartConfig } from 'tree/providers'
import { Department, DepartmentBase, DepartmentDetail } from 'tree/types'
import { makeCapabilitiesReadOnly } from 'tree/utils'

import { DepartmentDataFragmentFragment, DepartmentDetailDataFragmentFragment } from 'apollo/generated/graphql'

type BaseDepartmentMapperParams = {
  chartConfig: ChartConfig
  department: DepartmentDataFragmentFragment | DepartmentDetailDataFragmentFragment
}
export const baseDepartmentMapper = ({ chartConfig, department }: BaseDepartmentMapperParams): DepartmentBase => {
  const { capabilities } = department

  // Return new capabilities based on chart mode
  const newCapabilities = chartConfig.isReadOnly ? makeCapabilitiesReadOnly(capabilities) : capabilities

  return { capabilities: newCapabilities, type: 'department' }
}

type DepartmentMapperParams = Omit<BaseDepartmentMapperParams, 'department'> & {
  department: DepartmentDataFragmentFragment
}
export const departmentMapper = (params: DepartmentMapperParams): Department => {
  const departmentBase = baseDepartmentMapper(params)
  const { department } = params

  return { ...department, ...departmentBase }
}

type DepartmentDetailMapperParams = Omit<BaseDepartmentMapperParams, 'department'> & {
  department: DepartmentDetailDataFragmentFragment
}
export const departmentDetailMapper = (params: DepartmentDetailMapperParams): DepartmentDetail => {
  const departmentBase = baseDepartmentMapper(params)
  const { department } = params

  return { ...department, ...departmentBase }
}

export const useDepartment = (department: DepartmentDetailDataFragmentFragment): DepartmentDetail => {
  const { config: chartConfig } = useChartConfig()
  return departmentDetailMapper({ chartConfig, department })
}
