import { KpiKey, LineChartValueKey, SociomapKey } from '../types'

export const kpiLabels: Record<KpiKey, string> = {
  // Employees Page
  headcountKpi: 'Headcount',
  attendedKpi: 'Monthly time of attended meeting / hours spent in meeting',
  meetSizeKpi: 'Avarage meeting size',
  meetLengthKpi: 'Avarage meeting length',
  planAheadKpi: 'Avarage time meeting are planned ahead',
  estCostKpi: 'Estimated cost',
  joiners: 'Joiners',
  leavers: 'Leavers',
  turnover: 'Employee turnover',

  // Meeting Page
  conductedMtgsKpi: 'Number of 1:1 meetings between superior and direct report conducted in the last month.',
  notConductedMtgsKpi: 'Number of 1:1 meetings between superior and direct report that should have been conducted.',
}

export const lineChartLabels: Record<LineChartValueKey, string> = {
  headcountMonthly: 'Headcount',
  joinersMonthly: 'Joiners',
  leaversMonthly: 'Leavers',
}

type SociomapLabels = {
  label: string
  scale: string[]
}
export const sociomapLabels: Record<SociomapKey, SociomapLabels> = {
  // Sociomaps page
  individTeamsSociomap: {
    label: 'Sociomap of interactions between individuals within the team',
    scale: ['Few meetings', 'Many meetings'],
  },
}
