import styled from 'styled-components'

import { ButtonNewChart } from './button-new-chart'
import { ConnectingTeams } from 'components/icons'
import { applySpacingProps, SpacingProps } from 'helpers'

import { useAuth } from 'auth'
import { mainTheme } from 'theme'
import analytics from 'analytics'
import { ORG_TRACK } from 'analytics/constants'

type ChartListEmptyPropsProps = {
  onCreateChartClick: () => void
}

export const ChartListEmpty = ({ onCreateChartClick }: ChartListEmptyPropsProps) => {
  const { user, logout } = useAuth()

  const handleLogoutClick = () => {
    analytics.track(ORG_TRACK.loginDifferentAcc)
    logout()
  }

  return (
    <NoChartsContainer>
      <ConnectingTeams />
      <Heading>No Org Charts to display</Heading>
      <Description>Create your first Org Chart now with our helpful builder.</Description>
      <ButtonNewChart onClick={onCreateChartClick} />
      {user?.email && (
        <Description spacing={{ mt: mainTheme.spaces.xl }}>
          <EmailDetail>
            You are logged in using <Email>{user?.email}</Email>.
          </EmailDetail>
          In case you were trying to use a different account,{' '}
          <Logout onClick={handleLogoutClick} data-testid='button-logout-in-empty-chart'>
            log out here
          </Logout>
          .
        </Description>
      )}
    </NoChartsContainer>
  )
}

const NoChartsContainer = styled.div`
  align-self: flex-start;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding-top: 100px;
  padding-bottom: 100px;
  margin-right: ${props => props.theme.spaces.l};
  margin-left: ${props => props.theme.spaces.l};
  text-align: center;

  @media (max-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    padding-top: ${props => props.theme.spaces.xxl};
    padding-bottom: ${props => props.theme.spaces.xxl};
  }
`

const Heading = styled.div`
  margin-top: ${props => props.theme.spaces.xl};
  color: ${props => props.theme.colors.dark};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`

const Description = styled.div<{ spacing?: SpacingProps }>`
  max-width: 400px;
  margin-top: ${props => props.theme.spaces.m};
  margin-bottom: ${props => props.theme.spaces.xl};
  color: ${props => props.theme.colors.dark};
  font-size: 14px;
  line-height: 18px;

  ${props => applySpacingProps(props.spacing)}
`

const EmailDetail = styled.pre``
const Email = styled.span`
  font-weight: bold;
`

const Logout = styled.span`
  color: ${props => props.theme.colors.purple};
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.purpleLight};
  }
`
