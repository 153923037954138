import styled from 'styled-components'

import { TreeConnection } from 'tree/providers'

export const ConnectionLine = (props: TreeConnection) => {
  const { top, left, width, height, x1, y1, x2, y2, strokeColor, strokeDasharray } = props
  const lineX1 = (y1 > y2 && x1 > x2) || (y1 < y2 && x1 < x2) ? 0 : width
  const lineX2 = lineX1 === 0 ? width : 0

  return (
    <ConnectionLineInner $top={`${top}px`} $left={`${left}px`}>
      <svg width={width} height={height} style={{ overflow: 'visible' }}>
        <line
          x1={lineX1}
          y1={0}
          x2={lineX2}
          y2={height}
          fill='none'
          stroke={strokeColor}
          strokeDasharray={strokeDasharray}
        />
      </svg>
    </ConnectionLineInner>
  )
}

type ConnectionLineInnerProps = { $top: string; $left: string }
const ConnectionLineInner = styled.div.attrs<ConnectionLineInnerProps>(({ $top, $left }) => ({
  style: { top: $top, left: $left },
}))<ConnectionLineInnerProps>`
  position: absolute;
  z-index: -1;
  pointer-events: none;
`
