import { useState, SetStateAction } from 'react'
import styled, { css } from 'styled-components'

import { Tooltip } from 'components/generic'
import { CalendarTodayIcon, ChevronLeftIcon, ChevronRightIcon } from 'components/icons'

import { monthNames } from '../../constants'
import useKeypress from 'hooks/use-key-press'
import analytics from 'analytics'
import { INSIGHTS_TRACK } from 'analytics/constants'

type Props = {
  chartUuid: string
  isTyping: boolean
  monthsDisabled: boolean
  actualMonthIndex: number
  actualDataIndex: number
  dataLength: number
  setActualDataIndex: (value: SetStateAction<number>) => void
}

export const MonthsSelector = ({
  chartUuid,
  isTyping,
  monthsDisabled,
  actualMonthIndex,
  actualDataIndex,
  dataLength,
  setActualDataIndex,
}: Props) => {
  const isFirstMonthActive = actualDataIndex === 0
  const isLastMonthActive = actualDataIndex === dataLength - 1

  const setPreviousDataIndex = () => {
    setActualDataIndex(isFirstMonthActive ? 0 : actualDataIndex - 1)
    analytics.track(INSIGHTS_TRACK.monthChange, { chartUuid })
  }
  const setNextDataIndex = () => {
    setActualDataIndex(isLastMonthActive ? dataLength - 1 : actualDataIndex + 1)
    analytics.track(INSIGHTS_TRACK.monthChange, { chartUuid })
  }

  useKeypress('ArrowLeft', setPreviousDataIndex, isTyping)
  useKeypress('ArrowRight', setNextDataIndex, isTyping)

  const [isMonthSelectHovered, setIsMonthSelectHovered] = useState<boolean>(false)

  return (
    <Container>
      <StyledTooltip isTooltipShown={monthsDisabled && isMonthSelectHovered}>
        To change months pause the sociomap animation
      </StyledTooltip>

      <Content
        isDisabled={monthsDisabled}
        onMouseEnter={() => setIsMonthSelectHovered(true)}
        onMouseLeave={() => setIsMonthSelectHovered(false)}
      >
        <Arrow onClick={setPreviousDataIndex} isDisabled={monthsDisabled || isFirstMonthActive}>
          <ChevronLeftIcon />
        </Arrow>
        <SelectorCenter>
          <StyledCalendarTodayIcon />
          {monthNames[actualMonthIndex]}
        </SelectorCenter>
        <Arrow onClick={setNextDataIndex} isDisabled={monthsDisabled || isLastMonthActive}>
          <ChevronRightIcon />
        </Arrow>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
`

const Content = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 168px;
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.greyExtraLight};
  border-radius: 4px;
  filter: drop-shadow(0px 0px 4px rgba(38, 15, 63, 0.08));
  margin: 0 20px;
  z-index: 2;

  ${props =>
    props.isDisabled &&
    css`
      filter: grayscale(1);
      opacity: 0.3;
    `}

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    width: 100%;
    margin: 0;
  }
`

const StyledTooltip = styled(Tooltip)`
  font-size: 13px;
`

const Arrow = styled.div<{ isDisabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 32px;
  height: 100%;
  border-right: 1px solid ${props => props.theme.colors.greyExtraLight};
  color: ${props => props.theme.colors.purple};

  ${props =>
    props.isDisabled &&
    css`
      pointer-events: none;
      filter: grayscale(1);
      opacity: 0.3;
    `}

  &:hover {
    background: ${props => props.theme.colors.greyExtraLight};
  }

  &:last-child {
    border-right: none;
    border-left: 1px solid ${props => props.theme.colors.greyExtraLight};
  }

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    width: 48px;
  }
`

const SelectorCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 106px;
  height: 100%;
  font-size: 13px;
  font-weight: 500;
  color: ${props => props.theme.colors.dark};
  position: relative;
  padding-left: 24px;
`

const StyledCalendarTodayIcon = styled(CalendarTodayIcon)`
  color: ${props => props.theme.colors.purple};
  margin-right: 10px;
  width: 24px;
  position: absolute;
  left: 5px;
`
