import { FormEvent, useRef } from 'react'
import { Formik } from 'formik'

import { AboutForm } from './about-form'
import { ConnectionsForm } from './connections-form'
import { ContactsForm } from './contacts-form'
import { LocationForm } from './location-form'
import { EmployeeFieldTabs } from '../../employee-field-tabs'
import { NodeFormButtons } from '../../../node-components'
import { UnsavedPrompt } from 'components/specific'
import { FlexBox } from 'components/generic'

import { CUSTOM_FIELDS_TYPE_TABLE, isValidDate } from '../fields/custom'
import { getCustomFieldsValueName } from '../../helpers'
import { useStateCallback } from 'hooks'
import {
  PersonFormCapabilities,
  CustomConnectionFieldValue,
  CustomFieldValue,
  PersonFormErrors,
  OnPersonFormSubmit,
  PersonDetail,
  PersonFormSubmitValues,
  PersonFormValues,
  SocialLinkFields,
  SocialLinkFieldKey,
} from 'tree/types'
import { isValidEmail, isValidUrl } from 'helpers'
import { DeepMutable } from 'types'
import { spaces } from 'theme'

import { CustomFieldValueInput } from 'apollo/generated/graphql'

type Props = {
  initialValues: PersonFormValues
  chartUuid: string
  capabilities: PersonFormCapabilities
  parentUuid: string | null
  onSubmit: OnPersonFormSubmit
  onClose: () => void
  person?: PersonDetail
  onCancel?: () => void
}

export const EmployeeForm = (props: Props) => {
  const { initialValues, capabilities, person, onSubmit, onClose, onCancel } = props
  const [revokeUnsavedPrompt, setRevokeUnsavedPrompt] = useStateCallback(false)

  const aboutRef = useRef<HTMLElement>(null)
  const connectionsRef = useRef<HTMLElement>(null)
  const contactsRef = useRef<HTMLElement>(null)
  const locationRef = useRef<HTMLElement>(null)

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={(formValues, actions) => onSubmit({ values: getFormValues(formValues, person), actions })}
      validate={values => {
        const errors = getErrors(values)
        if (Object.keys(errors).length > 0) setRevokeUnsavedPrompt(false)
        return errors
      }}
    >
      {formState => {
        const { dirty, isSubmitting, errors: _errors, handleSubmit } = formState
        const errors = _errors as PersonFormErrors

        // For submiting form by default html submit like enter button
        const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
          e.preventDefault()
          setRevokeUnsavedPrompt(true, () => handleSubmit(e))
        }

        // Form buttons props
        const isSubmitDisabled = isSubmitting || !dirty
        const onCancelWithoutPrompt = () => setRevokeUnsavedPrompt(true, onCancel ?? onClose)
        const onSubmit = () => {
          const keys = Object.keys(errors)
          if (keys.length > 0) {
            let keyName = keys[0]
            if (errors.customFields) keyName = `"${getCustomFieldsValueName(Object.keys(errors.customFields)[0])}"`
            if (errors.parentUuid) keyName = 'search_superiorField'

            const selector = `[name=${keyName}]`
            const errorElement = document.querySelector(selector) as HTMLInputElement

            if (errorElement) {
              errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
              errorElement.focus({ preventScroll: true })
            }
          }
          setRevokeUnsavedPrompt(true, () => handleSubmit())
        }

        return (
          <form onSubmit={handleFormSubmit}>
            <UnsavedPrompt when={!revokeUnsavedPrompt && dirty} />

            <EmployeeFieldTabs
              onAboutClick={() => aboutRef.current?.scrollIntoView()}
              onConnectionsClick={() => connectionsRef.current?.scrollIntoView()}
              onContactsClick={() => contactsRef.current?.scrollIntoView()}
              onLocationClick={() => locationRef.current?.scrollIntoView()}
            />

            <FlexBox $isDirectionColumn $gap='m' $spacing={{ mt: spaces.l }}>
              <AboutForm capabilities={capabilities} formState={formState} sectionRef={aboutRef} />
              <ConnectionsForm
                capabilities={capabilities}
                formState={formState}
                person={person}
                sectionRef={connectionsRef}
              />
              <ContactsForm capabilities={capabilities} formState={formState} sectionRef={contactsRef} />
              <LocationForm capabilities={capabilities} formState={formState} sectionRef={locationRef} />
            </FlexBox>

            <NodeFormButtons isSubmitDisabled={isSubmitDisabled} onCancel={onCancelWithoutPrompt} onSubmit={onSubmit} />
          </form>
        )
      }}
    </Formik>
  )
}

const getCustomFieldValue = (f: CustomFieldValue) => {
  if (f.type === 'text') return f.value || ''
  if (f.type === 'single-date') return f.value ? new Date(f.value).toISOString() : f.value
  if (f.type === 'single-select') return f.options.find(o => o.isSelected)?.id || ''
  return ''
}

const getFormValues = (formValues: PersonFormValues, person: PersonDetail | undefined) => {
  const {
    moveToChart,
    parentUuid,
    email: emailUnconverted,
    emailAliases: emailAliasesUnconverted,
    customFields: customFieldsUnconverted,
    ...restFormValues
  } = formValues

  const stayInDirectory = person?.unassigned && !moveToChart
  const email = emailUnconverted ? emailUnconverted : null
  const emailAliases = Array.isArray(emailAliasesUnconverted) ? emailAliasesUnconverted.filter(email => email) : []

  const customFields: DeepMutable<CustomFieldValueInput> = { text: [], date: [], dropdown: [], connection: [] }
  Object.values(customFieldsUnconverted).forEach(f => {
    const type = CUSTOM_FIELDS_TYPE_TABLE[f.type]
    if (type === 'connection') {
      const values = (f as CustomConnectionFieldValue).values.map(v => v.id)
      return customFields.connection?.push({ id: f.id, values })
    }
    customFields[type]?.push({ id: f.id, value: getCustomFieldValue(f) })
  })

  const values: PersonFormSubmitValues = {
    ...restFormValues,
    ...(!stayInDirectory && { parentUuid }),
    email,
    emailAliases,
    customFields,
  }

  return values
}

const getErrors = (values: PersonFormValues) => {
  const { familyName, givenName, email, emailAliases, parentUuid, customFields } = values
  const errors: PersonFormErrors = { emailAliases: [], customFields: {} }

  // Basic fields validation
  if (!familyName) errors.familyName = 'Required.'
  if (!givenName) errors.givenName = 'Required.'
  if (email && !isValidEmail(email)) errors.email = 'Invalid email address.'
  emailAliases?.map((emailAlias, i) => {
    if (errors.emailAliases && emailAlias && !isValidEmail(emailAlias)) {
      errors.emailAliases[i] = 'Invalid email address.'
    }
  })
  if (parentUuid === '') errors.parentUuid = 'Either enter valid employee/department or leave empty.'

  // Length check
  const MAX_LENGTH = 255
  const MAX_CUSTOM_TEXT_FIELD_LENGTH = 2000
  const MAX_NAMES_LENGTH = 60
  const lengthErrorMessage = (maxChars: number) => `Field can't be longer than ${maxChars} characters.`
  Object.keys(values).map(_keyName => {
    const keyName = _keyName as keyof typeof values
    const value = values[keyName]
    const isNameField = keyName === 'familyName' || keyName === 'givenName'
    const actualMaxLength = isNameField ? MAX_NAMES_LENGTH : MAX_LENGTH

    // Basic fields
    if (typeof value === 'string') {
      if (value.length > actualMaxLength) {
        errors[keyName] = lengthErrorMessage(actualMaxLength) as any
      }
    }
  })

  // Social Links
  const { facebookLink, instagramLink, linkedInLink, whatsAppLink, xLink, youTubeLink } = values
  const socialLinks: SocialLinkFields = { facebookLink, instagramLink, linkedInLink, whatsAppLink, xLink, youTubeLink }
  Object.entries(socialLinks).map(([_keyName, socialLink]) => {
    const keyName = _keyName as SocialLinkFieldKey

    // Skip WhatsApp (is not an URL link)
    if (keyName === 'whatsAppLink') {
      return
    }

    // Check for valid URL
    if (socialLink && !isValidUrl(socialLink)) {
      errors[keyName] = 'Invalid URL. Please ensure that the URL begins with "https://" or "http://".'
    }
  })

  // Custom fields
  Object.values(customFields).map(cf => {
    if (!errors.customFields) {
      return
    }

    if (cf.type === 'text') {
      if (cf.value.length > MAX_CUSTOM_TEXT_FIELD_LENGTH) {
        errors.customFields[cf.id] = { value: lengthErrorMessage(MAX_CUSTOM_TEXT_FIELD_LENGTH) }
      }
      return
    }

    if (cf.type === 'single-date') {
      if (cf.value && !isValidDate(cf.value)) {
        errors.customFields[cf.id] = { value: `Use format mm/dd/yyyy or leave the field blank.` }
      }
      return
    }
  })

  if (errors.emailAliases?.length === 0) delete errors.emailAliases
  if (Object.values(errors.customFields || {}).length === 0) delete errors.customFields

  return errors
}
