import { SVG, SVGProps } from './SVG'

export const PaletteOutlineIcon = (props: SVGProps) => (
  <SVG width='18' height='18' viewBox='0 0 18 18' fill='none' {...props}>
    <rect width='18' height='18' fill='none' />
    <path
      d='M9 16.5C4.8675 16.5 1.5 13.1325 1.5 9C1.5 4.8675 4.8675 1.5 9 1.5C13.1325 1.5 16.5 4.53 16.5 8.25C16.5 10.7325 14.4825 12.75 12 12.75H10.6725C10.4625 12.75 10.2975 12.915 10.2975 13.125C10.2975 13.215 10.335 13.2975 10.395 13.3725C10.7025 13.725 10.875 14.1675 10.875 14.625C10.875 15.66 10.035 16.5 9 16.5ZM9 3C5.6925 3 3 5.6925 3 9C3 12.3075 5.6925 15 9 15C9.21 15 9.375 14.835 9.375 14.625C9.375 14.505 9.315 14.415 9.27 14.3625C8.9625 14.0175 8.7975 13.575 8.7975 13.125C8.7975 12.09 9.6375 11.25 10.6725 11.25H12C13.6575 11.25 15 9.9075 15 8.25C15 5.355 12.3075 3 9 3Z'
      fill='currentColor'
    />
    <path
      d='M4.875 9.75C5.49632 9.75 6 9.24632 6 8.625C6 8.00368 5.49632 7.5 4.875 7.5C4.25368 7.5 3.75 8.00368 3.75 8.625C3.75 9.24632 4.25368 9.75 4.875 9.75Z'
      fill='currentColor'
    />
    <path
      d='M7.125 6.75C7.74632 6.75 8.25 6.24632 8.25 5.625C8.25 5.00368 7.74632 4.5 7.125 4.5C6.50368 4.5 6 5.00368 6 5.625C6 6.24632 6.50368 6.75 7.125 6.75Z'
      fill='currentColor'
    />
    <path
      d='M10.875 6.75C11.4963 6.75 12 6.24632 12 5.625C12 5.00368 11.4963 4.5 10.875 4.5C10.2537 4.5 9.75 5.00368 9.75 5.625C9.75 6.24632 10.2537 6.75 10.875 6.75Z'
      fill='currentColor'
    />
    <path
      d='M13.125 9.75C13.7463 9.75 14.25 9.24632 14.25 8.625C14.25 8.00368 13.7463 7.5 13.125 7.5C12.5037 7.5 12 8.00368 12 8.625C12 9.24632 12.5037 9.75 13.125 9.75Z'
      fill='currentColor'
    />
  </SVG>
)
