import { ChangeEvent } from 'react'
import styled from 'styled-components'
import { FieldArray, FormikHelpers } from 'formik'

import { FieldInput, FieldInputProps } from './field-input'
import { FieldContainer, FieldTitle } from './components'
import { PlusIcon } from 'components/icons'

import { Mutable } from 'types'

import { CreatePersonInput } from 'apollo/generated/graphql'

type FieldEmailAliasesProps = Omit<FieldInputProps, 'value' | 'onChange' | 'onClear'> & {
  values: Mutable<CreatePersonInput>['emailAliases']
  name: string
  setFieldTouched: FormikHelpers<CreatePersonInput>['setFieldTouched']
}

export const FieldEmailAliases = ({
  values = [],
  name,
  disabled,
  setFieldTouched,
  ...fieldInputProps
}: FieldEmailAliasesProps) => {
  const showReadonlyField = disabled && values?.length === 0

  return (
    <FieldContainer>
      <FieldTitle title='Email aliases' />
      <FieldArray
        name={name}
        render={arrayHelpers => (
          <Container>
            {Array.isArray(values) &&
              values.map((value, i) => (
                <StyledFieldInput
                  key={i}
                  name={`emailAliases[${i}]`}
                  value={value}
                  disabled={disabled}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => arrayHelpers.replace(i, e?.target.value)}
                  onBlur={() => setFieldTouched(`emailAliases[${i}]`)}
                  onClear={() => arrayHelpers.remove(i)}
                  showClearOnEmptyValue
                  {...fieldInputProps}
                />
              ))}

            {showReadonlyField && (
              <FieldInput
                {...fieldInputProps}
                name={name}
                value=''
                disabled={disabled}
                onChange={() => null}
                onClear={() => null}
              />
            )}

            {!disabled && (
              <Button onClick={() => arrayHelpers.push('')}>
                <StyledPlusIcon /> Add email alias
              </Button>
            )}
          </Container>
        )}
      />
    </FieldContainer>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledFieldInput = styled(props => <FieldInput {...props} />)`
  margin-bottom: ${props => props.theme.spaces.s};
`

const Button = styled.div`
  min-width: 135px;
  height: 34px;
  align-self: flex-start;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: 1px solid #b2b3c1;
  margin-top: ${props => props.theme.spaces.s};
  padding: ${props => props.theme.spaces.m};
  color: ${props => props.theme.colors.dark};
  font-weight: 500;
  font-size: 14px;
  line-height: 33px;
  cursor: pointer;
  user-select: none;

  ${props => props.theme.animations.backgroundShapeFill}
`

const StyledPlusIcon = styled(PlusIcon)`
  margin-right: ${props => props.theme.spaces.m};
`
