import { ProtectedRoute } from '../protected-route'
import { TrackedRoute } from '../tracked-route'
import { NestedRoutes } from '../types'

export const RenderRoutes = (routes: NestedRoutes = {}) => {
  return Object.values(routes).map(({ routes, ...rest }) => {
    return rest.isProtected ? (
      <ProtectedRoute key={String(rest.path)} {...rest} />
    ) : (
      <TrackedRoute key={String(rest.path)} {...rest} />
    )
  })
}
