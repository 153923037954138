import { generatePath } from 'react-router-dom'
import { FormikHelpers } from 'formik'

import { useUpdateNodeRequest } from './use-update-node-request'

import { useResyncRequiredModal } from 'components/specific/modal'
import { useResyncChartAction } from 'tree/actions'
import { useChartData } from 'tree/providers'
import { PersonFormValues, PersonFormSubmitParams, DepartmentFormSubmitParams, DepartmentFormValues } from 'tree/types'
import analytics from 'analytics'
import { ORG_TRACK } from 'analytics/constants'
import { handleErrorValidation, capitalize, extractErrorCode } from 'helpers'
import { EMPLOYEE_EDIT_CONNECT_GW_PATH, useGoToDepartmentDetail, useGoToEmployeeDetail } from 'routes'

import {
  DepartmentDetailDataFragmentFragment,
  PersonDetailDataFragmentFragment,
  useChartLazyQuery,
  useNodeLazyQuery,
} from 'apollo/generated/graphql'

type PersonSubmitActionParams = PersonFormSubmitParams & {
  nodeTypename: 'Person'
  node: PersonDetailDataFragmentFragment
}

type DepartmentSubmitActionParams = DepartmentFormSubmitParams & {
  nodeTypename: 'Department'
  node: DepartmentDetailDataFragmentFragment
}

type UpdateNodeActionParams = DepartmentSubmitActionParams | PersonSubmitActionParams

export const useUpdateNodeAction = () => {
  const { uuid: chartUuid, name: chartName, capabilities } = useChartData()
  const { updateNodeRequest } = useUpdateNodeRequest()
  const { goToEmployeeDetail } = useGoToEmployeeDetail()
  const { goToDepartmentDetail } = useGoToDepartmentDetail()
  const [chartLazyQuery] = useChartLazyQuery()
  const [nodeLazyQuery] = useNodeLazyQuery()
  const { resyncChartWithConfirm } = useResyncChartAction()
  const { openResyncRequiredModal } = useResyncRequiredModal()

  const updateNodeAction = async ({ actions, ...params }: UpdateNodeActionParams) => {
    const { nodeTypename, node } = params
    const nodeType = nodeTypename === 'Person' ? 'employee' : 'department'
    const trackKey = nodeTypename === 'Person' ? 'updateEmployee' : 'updateDepartment'

    try {
      const res = await updateNodeRequest(params)
      analytics.track(ORG_TRACK[trackKey], { chartUuid, chartName })
      nodeTypename === 'Person' ? goToEmployeeDetail(node.id) : goToDepartmentDetail(node.id)
      return Promise.resolve(res)
    } catch (error) {
      const errorCode = extractErrorCode(error)
      if (errorCode === 'GSUITE_INSUFFICIENT_PERMISSIONS' && nodeType === 'employee') {
        await chartLazyQuery({ variables: { key: chartUuid } })
        await nodeLazyQuery({ variables: { chartKey: chartUuid, uuid: node.id } })

        if (!capabilities?.canUpdate) {
          return openResyncRequiredModal()
        }

        const redirectPath = generatePath(EMPLOYEE_EDIT_CONNECT_GW_PATH, { chartUuid, uuid: node.id })
        await resyncChartWithConfirm({ chartUuid, redirectPath })
      } else {
        const { uuid, name } = node
        handleErrorValidation({
          track: {
            message: `Updating ${nodeType} failed` as const,
            values: {
              chartUuid,
              [nodeTypename === 'Person' ? 'employeeUuid' : 'departmentUuid']: uuid,
              [nodeType]: name,
              error,
            },
          },
          toast: { message: `${capitalize(nodeType)} update failed` },
          error,
          actions: actions as FormikHelpers<PersonFormValues | DepartmentFormValues>,
        })
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  return { updateNodeAction }
}
