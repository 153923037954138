import { StylesConfig } from 'react-select'

import { colors } from 'theme'

export const customStyles: StylesConfig<Record<string, unknown>, false> = {
  container: provided => ({
    ...provided,
    display: 'flex',
    width: '100%',
    maxWidth: '440px',
  }),
  menu: provided => ({
    ...provided,
    marginTop: '1px',
    width: '100%',
    zIndex: 10,
    boxShadow: '0px 0px 6px rgba(38, 15, 63, 0.08)',
    borderRadius: '3px',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    cursor: 'pointer',
  }),
  control: provided => ({
    ...provided,
    marginTop: '5%',
    backgroundColor: '#e6e7ed',
    color: colors.dark,
    borderRadius: '3px',
    width: '100%',
    height: '40px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    cursor: 'pointer',
    margin: 0,
    border: 0,
    // This line disable the blue border
    boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? colors.purple : colors.greyLight,
    backgroundColor: state.isFocused ? '#E6E7ED' : colors.white,
    cursor: 'pointer',
    height: '33px',
    lineHeight: '33px',
    fontSize: '14px',
    fontFamily: 'Roboto',
    padding: '0 10px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    ':active': {
      background: '#E6E7ED',
    },
  }),
  singleValue: provided => ({
    ...provided,
    height: '33px',
    lineHeight: '33px',
    paddingLeft: '2px',
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 299,
  }),
}
