import { useHistory } from 'react-router-dom'

import { useDeleteChartRequest } from './use-delete-chart-request'
import { WithConfirmModal } from '../../types'
import { ConfirmationModalType } from 'components/specific'
import { useModal } from 'modal'
import analytics from 'analytics'
import { ORG_TRACK } from 'analytics/constants'
import { CHARTS_PATH } from 'routes'
import { handleErrorValidation } from 'helpers'

import { Chart } from 'apollo/generated/graphql'

type DeleteChartActionParams = {
  chartId: Chart['id']
  chartName: Chart['name']
}

export const useDeleteChartAction = () => {
  const history = useHistory()
  const { open } = useModal()
  const { deleteChartRequest } = useDeleteChartRequest()

  const deleteChart = async (params: DeleteChartActionParams) => {
    const { chartId, chartName } = params
    try {
      const res = await deleteChartRequest(params)
      analytics.track(ORG_TRACK.deleteChart, { uuid: chartId, name: chartName })
      history.push(CHARTS_PATH)
      return Promise.resolve(res)
    } catch (error) {
      handleErrorValidation({
        track: { message: ORG_TRACK.deleteChartFailure, values: { chartUuid: chartId, error } },
        toast: { message: 'Failed to delete Org Chart' },
      })
    }
  }

  const deleteChartWithConfirm = async ({ onAbort, ...params }: DeleteChartActionParams & WithConfirmModal) => {
    return new Promise((resolve, reject) => {
      let hasConfirmed = false
      open<ConfirmationModalType>('confirmationModal', {
        title: { text: `Delete ${params.chartName}` },
        message: 'Permanently delete your Org Chart? This cannot be undone.',
        confirmButton: { buttonType: 'danger', children: 'Delete' },
        onConfirm: () => {
          hasConfirmed = true
          deleteChart(params).catch(reject).then(resolve)
        },
        onClose: () => {
          if (!hasConfirmed) onAbort?.()
        },
      })
    })
  }

  return { deleteChart, deleteChartWithConfirm }
}
