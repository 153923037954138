import { SVG, SVGProps } from './SVG'

export const BigCheckmarkIcon = (props: SVGProps) => (
  <SVG width='18' height='18' viewBox='0 0 18 18' fill='none' {...props}>
    <path
      d='M6.75 12.1275L3.62249 9L2.5575 10.0575L6.75 14.25L15.75 5.25L14.6925 4.1925L6.75 12.1275Z'
      fill='currentColor'
    />
  </SVG>
)
