import { gql } from '@apollo/client'
import { personTeamSizeFragment } from '../fragments'

export const personTeamSize = gql`
  query personTeamSize($chartKey: String!, $uuid: String!) {
    node(chartKey: $chartKey, uuid: $uuid) {
      ...PersonTeamSizeFragment
    }
  }
  ${personTeamSizeFragment}
`
