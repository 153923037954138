import { gql } from '@apollo/client'

export const typeDefs = gql`
  extend type Person {
    expanded: Boolean!
  }

  extend type Department {
    expanded: Boolean!
  }

  extend type Query {
    showUnassignedNodesSidebar: Boolean!
  }

  extend type Mutation {
    toggleSubtree(id: String!, expanded: Boolean!): Node
    setShowUnassignedNodesSidebar(showUnassignedNodesSidebar: Boolean!): Boolean!
  }
`
