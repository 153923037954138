import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useDropzone } from 'react-dropzone'

const MB_IN_BYTES = 1000000
const MAX_CSV_SIZE = 20 * MB_IN_BYTES

type Props = {
  onFileChange?: (file?: File) => void
}

export const useCsvUpload = ({ onFileChange }: Props = {}) => {
  const [file, setFile] = useState<File>()
  const dropzone = useDropzone({
    accept: '.csv',
    multiple: false,
    maxSize: MAX_CSV_SIZE,
    onDrop: files => {
      if (files[0]) {
        const file = files[0]
        onFileChange?.(file)
        setFile(file)
      }
    },
  })
  const { fileRejections } = dropzone

  useEffect(() => {
    const fileErrors = fileRejections[0]?.errors || []
    fileErrors.forEach(({ code, message }) => {
      if (code === 'file-too-large') {
        toast(`File is larger than ${MAX_CSV_SIZE / MB_IN_BYTES} MB`, { type: 'error' })
      } else {
        toast(message, { type: 'error' })
      }
    })
  }, [fileRejections])

  return {
    file,
    setFile,
    ...dropzone,
  }
}
