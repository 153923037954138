import { memo } from 'react'
import styled from 'styled-components'

import { Loading } from 'components/generic'

export const SubTreeLoader = memo(() => (
  <LoadingWrap>
    <Loading size='small' />
  </LoadingWrap>
))
SubTreeLoader.displayName = 'SubTreeLoader'

const LoadingWrap = styled.div`
  position: absolute;
  left: 50%;
  bottom: -90px;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  z-index: 100;
`
