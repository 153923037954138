import { ReactNode } from 'react'
import styled from 'styled-components'

import { ButtonWithIcon } from './button-with-icon'
import { GsuiteIcon } from 'components/icons'

import { addTransparencyToColor, SpacingProps, applySpacingProps } from 'helpers'

type ButtonGoogleProps = {
  children: ReactNode
  spacing?: SpacingProps
  className?: string
  onClick: () => void
}

export const ButtonGoogle = ({ spacing, className, onClick, children, ...rest }: ButtonGoogleProps) => (
  <GoogleButtonWithIcon $spacing={spacing} className={className} icon={<GsuiteIcon />} onClick={onClick} {...rest}>
    {children}
  </GoogleButtonWithIcon>
)

const GoogleButtonWithIcon = styled(ButtonWithIcon)<{ $spacing?: SpacingProps }>`
  min-width: 260px;
  background: ${props => props.theme.colors.googleBlue};
  transition: ${props => props.theme.transitions.extraFastEase};

  ${props => applySpacingProps(props.$spacing)}

  &:hover {
    background: ${props => addTransparencyToColor(props.theme.colors.googleBlue, 0.85)};
  }
`
