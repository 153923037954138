import styled from 'styled-components'

type Props = {
  $height?: string
  $horizontalSpacing?: string
  $width?: string
}

export const LineDivider = styled.div<Props>`
  align-self: center;
  min-width: ${props => props.$width ?? '1px'};
  max-width: ${props => props.$width ?? '1px'};
  min-height: ${props => props.$height ?? '16px'};
  max-height: ${props => props.$height ?? '16px'};
  display: inline-flex;
  margin: 0 ${props => props.$horizontalSpacing};
  background: ${props => props.theme.colors.dark};
  cursor: default;
  opacity: 0.2;
`
