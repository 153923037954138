import styled from 'styled-components'

import { Container } from './charts/styled'
import { TilButton } from 'components/generic'
import { TimeIsLtdLogo } from 'components/icons'
import SociomapUri from 'assets/sociomap-ad.png'

import analytics from 'analytics'
import { INSIGHTS_TRACK } from 'analytics/constants'
import { ORG_CHART_UNARIC_CONTACT_URL } from 'consts'

type Props = {
  chartUuid?: string
}

export const TilAd = ({ chartUuid }: Props) => (
  <StyledContainer>
    <Logo />
    <Heading>Want more detailed company Insights?</Heading>
    <SubHeading>We have a solution for you!</SubHeading>
    <TilButton
      type='secondary'
      href={ORG_CHART_UNARIC_CONTACT_URL}
      onClick={() => analytics.track(INSIGHTS_TRACK.contactUs, { chartUuid })}
      spacing={{ mt: 25 }}
    >
      Contact Us
    </TilButton>
    <Sociomap src={SociomapUri} />
  </StyledContainer>
)

const Logo = styled(TimeIsLtdLogo)`
  color: ${props => props.theme.colors.white};
  transform: scale(1.23);
  margin: 0 auto;
  margin-top: 47px;
`

const Heading = styled.h1`
  padding: 0 15px;
  font-weight: 500;
  font-size: 18px;
  margin-top: 50px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
`

const SubHeading = styled.h2`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-top: 15px;
`

const Sociomap = styled.img`
  position: absolute;
  bottom: 30px;
  left: -15px;
  transform: scale(1.05);
  z-index: 50;
  pointer-events: none;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    transform: translateX(-50%);
    left: 50%;
  }
`

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 14px;
  display: flex;
  height: 520px;
  width: 272px;
  min-width: 272px;
  padding: 0;
  background: #36006f;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    width: 100%;
  }
`
