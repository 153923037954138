import { createClient } from 'contentful'

import globalConfig from 'config'

const getContentfulClient = () => {
  try {
    const client = createClient({
      environment: globalConfig.CONTENTFUL_ENVIRONMENT,
      space: globalConfig.CONTENTFUL_SPACE,
      accessToken: globalConfig.CONTENTFUL_ACCESS_TOKEN,
    })
    return client
  } catch (error) {
    console.error(error)
  }
}

export const contentfulClient = getContentfulClient()
