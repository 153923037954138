import { SVG, SVGProps } from './SVG'

export const DepartmentIcon = (props: SVGProps) => (
  <SVG width='12' height='12' viewBox='0 0 12 12' fill='none' {...props}>
    <path
      d='M5.99984 3.08333V0.75H0.166504V11.25H11.8332V3.08333H5.99984ZM2.49984 10.0833H1.33317V8.91667H2.49984V10.0833ZM2.49984 7.75H1.33317V6.58333H2.49984V7.75ZM2.49984 5.41667H1.33317V4.25H2.49984V5.41667ZM2.49984 3.08333H1.33317V1.91667H2.49984V3.08333ZM4.83317 10.0833H3.6665V8.91667H4.83317V10.0833ZM4.83317 7.75H3.6665V6.58333H4.83317V7.75ZM4.83317 5.41667H3.6665V4.25H4.83317V5.41667ZM4.83317 3.08333H3.6665V1.91667H4.83317V3.08333ZM10.6665 10.0833H5.99984V8.91667H7.1665V7.75H5.99984V6.58333H7.1665V5.41667H5.99984V4.25H10.6665V10.0833ZM9.49984 5.41667H8.33317V6.58333H9.49984V5.41667ZM9.49984 7.75H8.33317V8.91667H9.49984V7.75Z'
      fill='currentColor'
    />
  </SVG>
)
