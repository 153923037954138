import { forwardRef, LabelHTMLAttributes, ReactNode, useState } from 'react'
import styled from 'styled-components'

type ChildrenProps = {
  isOver: boolean
}

type Props = {
  children?: ReactNode | ((childrenProps: ChildrenProps) => JSX.Element)
  className?: string
} & LabelHTMLAttributes<HTMLLabelElement>

export const FileDropLabel = forwardRef<HTMLLabelElement, Props>((props, ref) => {
  const { children, className, onDrop, onDragOver, onDragEnd, onDragExit, onDragLeave, ...rest } = props
  const [isOver, setIsOver] = useState(false)
  const start = () => setIsOver(true)
  const end = () => setIsOver(false)

  return (
    <Label
      className={className}
      $isOver={isOver}
      onDrop={e => {
        onDrop?.(e)
        end()
      }}
      onDragOver={e => {
        onDragOver?.(e)
        start()
      }}
      onDragEnd={e => {
        onDragEnd?.(e)
        end()
      }}
      onDragExit={e => {
        onDragExit?.(e)
        end()
      }}
      onDragLeave={e => {
        onDragLeave?.(e)
        end()
      }}
      ref={ref}
      {...rest}
    >
      {typeof children === 'function' ? children({ isOver }) : children}
    </Label>
  )
})
FileDropLabel.displayName = 'FileDropLabel'

const Label = styled.label<{ $isOver: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.l};
  border: 1px dashed ${props => (props.$isOver ? props.theme.colors.purple : props.theme.colors.border)};
  border-radius: 3px;
  color: ${props => (props.$isOver ? props.theme.colors.purple : props.theme.colors.dark)};
  cursor: pointer;
  user-select: none;
  transition: ${props => props.theme.transitions.fastEase};

  &:hover {
    border: 1px dashed ${props => props.theme.colors.purple};
    color: ${props => props.theme.colors.purple};
  }
`
