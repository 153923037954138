import { useEffect, useRef, useState, useCallback, RefObject, MutableRefObject } from 'react'
import styled from 'styled-components'

import { NodeSearchMenu } from './node-search-menu'
import { FieldInput } from '../field/field-input'
import { FlexBox } from '../flex'

import { NodeSearchResult, useFullTextSearch } from 'hooks'

import { NodeSearchDataFragmentFragment } from 'apollo/generated/graphql'

type Props = {
  value: string
  chartId: string
  className?: string
  disabled?: boolean
  innerRef?: MutableRefObject<HTMLInputElement | null>
  onChange: (value: string) => void
  onNodeSelect: (node: NodeSearchDataFragmentFragment) => void
  onInputFocus?: (inputRef: RefObject<HTMLInputElement>) => void
  onInputBlur?: (inputRef: RefObject<HTMLInputElement>) => void
  onSearch?: (searchData: NodeSearchResult) => void
}

const MIN_VALUE_SEARCH_LENGTH = 2

export const NodeSearchField = (props: Props) => {
  const { value, chartId, className, disabled, innerRef, onChange, onSearch, onNodeSelect, onInputFocus, onInputBlur } =
    props

  const inputRef = useRef<HTMLInputElement | null>(null)
  const [isInputFocused, setIsInputFocused] = useState(false)
  const { data, isSearching, error, search } = useFullTextSearch({
    chartUuid: chartId,
    minLength: MIN_VALUE_SEARCH_LENGTH,
  })

  const hint = (() => {
    if (value.length < MIN_VALUE_SEARCH_LENGTH)
      return `You need to enter at least ${MIN_VALUE_SEARCH_LENGTH} characters`
    if (isSearching) return 'Searching ...'
    if (!data.length) return 'No data found'
    return ''
  })()

  const setInnerRefs = useCallback((input: HTMLInputElement | null) => {
    inputRef.current = input
    if (innerRef) innerRef.current = input
  }, [])

  const handleOnNodeSelect = (node: NodeSearchDataFragmentFragment) => {
    inputRef.current?.blur()
    onNodeSelect(node)
  }

  useEffect(() => {
    onSearch?.({ data, isSearching, error })
  }, [data, isSearching, error, onSearch])

  useEffect(() => {
    search(value)
  }, [value])

  return (
    <FlexBox className={className} $isDirectionColumn>
      <StyledFieldInput
        type='text'
        value={value}
        placeholder='Search department or employee'
        optimized={false}
        disabled={disabled}
        onFocus={() => {
          setIsInputFocused(true)
          onInputFocus?.(inputRef)
        }}
        onChange={e => onChange(e.target.value)}
        onBlur={() => {
          setIsInputFocused(false)
          onInputBlur?.(inputRef)
        }}
        onClear={() => onChange('')}
        innerRef={setInnerRefs}
      />

      {isInputFocused && <NodeSearchMenu nodes={data} hint={hint} onNodeSelect={handleOnNodeSelect} />}
    </FlexBox>
  )
}

const StyledFieldInput = styled(FieldInput)`
  margin-bottom: 0;
`
