import { gql } from '@apollo/client'

export const collaboratorFragment = gql`
  fragment CollaboratorFragment on Collaborator {
    chartUuid
    id
    uuid
    accepted
    status
    email
    role
    allowedRoles
  }
`
