import { useRef } from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { Formik, FormikHelpers } from 'formik'

import { SelectType } from './SelectType'
import { LineSettings } from './LineSettings'
import { Card } from '../../../components/card'
import { Button, FieldInput, ScrollAnchor } from 'components/generic'

import { useChartPermission } from 'tree/hooks'
import { useChartData } from 'tree/providers'
import { CustomConnectionField, CustomConnectionLineStyle } from 'tree/types'
import { getIsSubscribed, useGoToPremiumScreen } from 'features/premium'
import analytics from 'analytics'
import { CUSTOM_FIELDS_TRACK } from 'analytics/constants'
import { StringifyRecordValues } from 'types'
import { spaces } from 'theme'

import { chart as chartQuery } from 'apollo/query'
import {
  useCreateConnectionFieldMutation,
  useUpdateConnectionFieldMutation,
  CreateCustomConnectionFieldInput,
} from 'apollo/generated/graphql'

type CreateDottedLineFormValues = {
  fieldLabel: string
  lineColor: CustomConnectionField['lineColor']
  lineStyle?: CustomConnectionLineStyle
  displayLineOnChart: CustomConnectionField['displayLineOnChart']
}

type CreateCustomFieldsFormErrors = Partial<StringifyRecordValues<CreateDottedLineFormValues>>

type Props = {
  connectionFields: CustomConnectionField[]
  onBack?: () => void
}

export const CreateOrEdit = ({ onBack, connectionFields }: Props) => {
  const { id: chartUuid, subscription } = useChartData()
  const isSubscribed = getIsSubscribed(subscription)
  const { goToPremiumScreen } = useGoToPremiumScreen()
  const { params } = useRouteMatch<{ connectionFieldId?: string }>()
  const { connectionFieldId } = params
  const editedField = connectionFields.find(cf => cf.id === connectionFieldId)
  const mode = editedField?.id ? 'edit' : 'create'
  const { canCreateCustomField, canUpdateCustomField } = useChartPermission()
  const isDisabled = (mode === 'create' && !canCreateCustomField) || (mode === 'edit' && !canUpdateCustomField)

  const initialValues: CreateDottedLineFormValues = {
    fieldLabel: editedField?.label || '',
    lineColor: editedField?.lineColor || 'red',
    lineStyle: editedField?.lineStyle,
    displayLineOnChart: Boolean(editedField?.displayLineOnChart),
  }

  const [createConnectionFieldMutation] = useCreateConnectionFieldMutation()
  const [updateConnectionFieldMutation] = useUpdateConnectionFieldMutation()

  const settingsRef = useRef<HTMLDivElement>(null)
  const scrollToSettings = () => {
    setTimeout(() => settingsRef.current?.scrollIntoView({ behavior: 'smooth' }), 0)
  }

  const handleCreateField = async ({ values }: { values: CreateDottedLineFormValues }) => {
    const { fieldLabel, lineColor, lineStyle, displayLineOnChart } = values

    const mutationOptions = {
      variables: { data: { label: fieldLabel }, chartUuid },
      refetchQueries: [{ query: chartQuery, variables: { key: chartUuid } }],
    }

    const data: CreateCustomConnectionFieldInput = {
      displayLineOnChart,
      label: fieldLabel,
      lineColor,
      lineStyle,
    }

    return createConnectionFieldMutation({ ...mutationOptions, variables: { chartUuid, data } })
  }

  const handleEditField = ({ values }: { values: CreateDottedLineFormValues }) => {
    const { fieldLabel, lineColor, lineStyle, displayLineOnChart } = values

    const editOptions = {
      variables: { uuid: editedField?.id ?? '', chartUuid, data: { label: fieldLabel } },
    }

    const data: CreateCustomConnectionFieldInput = {
      displayLineOnChart,
      label: fieldLabel,
      lineColor,
      lineStyle,
    }

    return updateConnectionFieldMutation({ variables: { ...editOptions.variables, data } })
  }

  const handleCancel = () => {
    onBack?.()
    if (mode === 'create') analytics.track(CUSTOM_FIELDS_TRACK.cancelCreate, { chartUuid })
  }

  const handleSubmit = (values: CreateDottedLineFormValues, actions: FormikHelpers<CreateDottedLineFormValues>) => {
    if (mode === 'create' && !isSubscribed) {
      return goToPremiumScreen()
    }

    const promise = mode === 'create' ? handleCreateField({ values }) : handleEditField({ values })
    const { fieldLabel } = values
    promise
      ?.then(() => {
        analytics.track(CUSTOM_FIELDS_TRACK[mode], { fieldLabel, fieldType: 'connection' })
        toast(`Connection field ${fieldLabel} ${mode === 'create' ? 'created' : 'edited'}.`, { type: 'success' })
        onBack?.()
      })
      .catch(() => {
        toast(`Connection field ${fieldLabel} could not be ${mode === 'create' ? 'created' : 'edited'}.`, {
          type: 'error',
        })
      })
      .finally(() => actions.setSubmitting(false))
  }

  return (
    <Formik<CreateDottedLineFormValues>
      enableReinitialize
      initialValues={initialValues}
      validateOnChange={false}
      onSubmit={handleSubmit}
      validate={handleValidate}
    >
      {({ values, isSubmitting, handleSubmit: handleFormikSubmit, setFieldValue }) => {
        const { fieldLabel, lineColor, lineStyle, displayLineOnChart } = values

        return (
          <form tabIndex={0} onSubmit={handleFormikSubmit}>
            <SelectType
              title={mode === 'create' ? 'Choose connection type' : 'Connection type'}
              selectedLineStyle={lineStyle}
              onChange={lineStyle => {
                setFieldValue('lineStyle', lineStyle)
                scrollToSettings()
              }}
            />

            <ScrollAnchor innerRef={settingsRef} />

            {lineStyle && (
              <Card title='Connection settings'>
                <FieldInput
                  title={{ title: 'Field name', size: 'big', required: true }}
                  name='fieldLabel'
                  type='text'
                  placeholder='E.g. Secondary reporting manager '
                  onChange={e => setFieldValue('fieldLabel', e.target.value)}
                  initialValue=''
                  maxLength={255}
                  value={fieldLabel}
                  disabled={isDisabled}
                  onClear={() => setFieldValue('fieldLabel', '')}
                  required
                />

                <LineSettings
                  displayLineOnChart={displayLineOnChart}
                  lineColor={lineColor}
                  onChangeLineColor={color => setFieldValue('lineColor', color)}
                  onChangeDisplayLineOnChart={displayLineOnChart =>
                    setFieldValue('displayLineOnChart', displayLineOnChart)
                  }
                />
              </Card>
            )}

            <ButtonsContainer>
              <Button
                buttonType='secondary'
                spacing={{ mr: spaces.l }}
                onClick={handleCancel}
                data-testid='button-new-custom-fields-form-cancel'
              >
                Cancel
              </Button>
              <Button
                disabled={isDisabled || isSubmitting}
                onClick={() => handleFormikSubmit()}
                data-testid='button-new-custom-fields-form-save'
              >
                {mode === 'create' ? 'Create new field' : 'Save'}
              </Button>
            </ButtonsContainer>
          </form>
        )
      }}
    </Formik>
  )
}

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${props => props.theme.spaces.l};
`

const handleValidate = ({ fieldLabel, lineStyle }: CreateDottedLineFormValues) => {
  const errors: CreateCustomFieldsFormErrors = {}

  if (!lineStyle) {
    const msg = 'Please select connection type'
    toast(msg, { type: 'warning' })
    errors.lineStyle = msg
    return errors
  }

  if (!fieldLabel) {
    const msg = 'Please fill connection field name'
    toast(msg, { type: 'warning' })
    errors.fieldLabel = msg
  }

  return errors
}
