import { AnchorHTMLAttributes, ReactNode, SyntheticEvent } from 'react'
import styled, { css } from 'styled-components'

import { LinkStyles } from './link-styles'
import { applySpacingProps, SpacingProps } from 'helpers'

type Props = {
  children: ReactNode
  href?: string
  isInline?: boolean
  color?: string
  spacing?: SpacingProps
  as?: keyof JSX.IntrinsicElements
  onClick?: (e: SyntheticEvent) => void
} & AnchorHTMLAttributes<HTMLAnchorElement>

export const Link = ({ as, target = '_blank', rel, isInline, color, spacing, children, ...rest }: Props) => {
  const customTarget = as?.toLocaleLowerCase() === 'a' || as === undefined ? target : undefined
  // Prevent usage of unsafe target='_blank' -> https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/jsx-no-target-blank.md
  const safeRel = rel ?? target.toLocaleLowerCase() === '_blank' ? 'noreferrer' : ''
  return (
    <Anchor
      as={as}
      target={customTarget}
      rel={safeRel}
      $isInline={isInline}
      $color={color}
      $spacing={spacing}
      {...rest}
    >
      {children}
    </Anchor>
  )
}

const Anchor = styled.a<{ $isInline?: boolean; $color?: string; $spacing?: SpacingProps }>`
  ${LinkStyles}

  ${props =>
    props.$color &&
    css`
      color: ${props.$color};
      &:hover {
        box-shadow: inset 0px -1px 0px ${props.$color};
      }
    `}

  ${props =>
    props.$isInline &&
    css`
      display: inline;
    `}

    ${props => applySpacingProps(props.$spacing)}
`
