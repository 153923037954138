export const REQUESTED_URL_KEY = 'requestedUrl'

export const setItem = (url = '') => sessionStorage.setItem(REQUESTED_URL_KEY, url)

export const getItem = (): string | null => sessionStorage.getItem(REQUESTED_URL_KEY)

export const removeItem = () => sessionStorage.removeItem(REQUESTED_URL_KEY)

export default {
  setItem,
  getItem,
  removeItem,
}
