import { useEffect } from 'react'

export const useEscapeKeypressListener = (successCallback: () => any) => {
  useEffect(() => {
    const escHandler = (event: KeyboardEvent) => {
      const code = event.keyCode
      if (code === 27) {
        successCallback()
      }
    }

    document.addEventListener('keydown', escHandler)
    return () => {
      document.removeEventListener('keydown', escHandler)
    }
  }, [successCallback])
}
