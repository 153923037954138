import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Screen } from './components'
import { MaintenanceIcon } from 'components/icons'

import { CHARTS_PATH } from 'routes'

export const Maintenance = () => {
  const history = useHistory()
  const location = useLocation<{ from?: string }>()
  const prevLocation = location.state?.from || CHARTS_PATH

  useEffect(() => {
    if (history.action === 'POP') history.push(prevLocation)
    const autoRedirect = Number(setTimeout(() => history.push(prevLocation), 180000))
    return () => clearTimeout(autoRedirect)
  }, [])

  return (
    <Screen
      icon={<MaintenanceIcon />}
      title='Maintenance in progress'
      description={`Sorry, we're down for maintenance at the moment, please try back in a few minutes.`}
      descMaxWidth='500px'
      withoutButton
    />
  )
}
