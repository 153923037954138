import { ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { ThemeProvider } from 'styled-components'

import { Auth0ProviderWithHistory } from 'auth'
import { AuthorizedApolloProvider } from 'apollo'
import {
  IntercomSafeProvider,
  InviteCollaboratorsPanelProvider,
  IsSafariBrowserSafeProvider,
  PendingCollaboratorsProvider,
  ResponsiveInfoProvider,
  TopNavigationToggleSafeProvider,
} from 'providers'
import { ModalProvider } from 'modal'
import { mainTheme } from 'theme'

type Props = {
  children: ReactNode
}

export const GlobalProviders = ({ children }: Props) => (
  <BrowserRouter>
    <Auth0ProviderWithHistory>
      <IsSafariBrowserSafeProvider>
        <ThemeProvider theme={mainTheme}>
          <AuthorizedApolloProvider>
            <DndProvider backend={HTML5Backend}>
              <TopNavigationToggleSafeProvider>
                <IntercomSafeProvider>
                  <PendingCollaboratorsProvider>
                    <ResponsiveInfoProvider>
                      <ModalProvider>
                        <InviteCollaboratorsPanelProvider>{children}</InviteCollaboratorsPanelProvider>
                      </ModalProvider>
                    </ResponsiveInfoProvider>
                  </PendingCollaboratorsProvider>
                </IntercomSafeProvider>
              </TopNavigationToggleSafeProvider>
            </DndProvider>
          </AuthorizedApolloProvider>
        </ThemeProvider>
      </IsSafariBrowserSafeProvider>
    </Auth0ProviderWithHistory>
  </BrowserRouter>
)
