import styled from 'styled-components'
import { generatePath } from 'react-router-dom'

import { Button } from 'components/generic'
import { BigCheckmarkIcon, CopyIcon } from 'components/icons'

import { useCopyToClipboard } from 'hooks'
import { BASE_URL, INVITE_PATH } from 'routes'

type Props = {
  invitationUuid: string
}

export const Invited = ({ invitationUuid }: Props) => {
  const { copyToClipboard } = useCopyToClipboard()

  return (
    <InvitedInner>
      <InvitedWrap>
        <StyledBigCheckmarkIcon /> Invited
      </InvitedWrap>

      <Button
        buttonType='secondary'
        buttonSize='small'
        leftEl={<StyledCopyIcon />}
        onClick={() => copyToClipboard(`${BASE_URL}${generatePath(INVITE_PATH, { code: invitationUuid })}`)}
      >
        Copy invitation link
      </Button>
    </InvitedInner>
  )
}

const InvitedInner = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.green};
  font-weight: 500;
  font-size: 13px;
  line-height: 24px;
`

const InvitedWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${props => props.theme.spaces.l};
`

const StyledBigCheckmarkIcon = styled(BigCheckmarkIcon)`
  margin-right: ${props => props.theme.spaces.s};
`

const StyledCopyIcon = styled(CopyIcon)`
  margin-right: ${props => props.theme.spaces.s};
`
