import { getDateFromString } from 'helpers'
import { ChartDetail } from 'tree/types'

type Params = ChartDetail['lock']

type Return = {
  chartLockDate: Date | undefined
}

export const getChartLockDate = (lock: Params): Return => {
  const date = getDateFromString(lock?.from || '')
  if (date) {
    return { chartLockDate: date }
  }

  return { chartLockDate: undefined }
}
