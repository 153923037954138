import styled from 'styled-components'

import { AlertBar, AlertBoxDescription, AlertBoxTitle, FlexBox, FlipClockCountdown } from 'components/generic'

import { UpgradeSubscriptionButton } from './upgrade-subscription-button'
import { useChartLockCountdown } from './hooks'
import { CHART_LOCK_COUNTDOWN_URGENT_THRESHOLD_DAYS } from './constants'
import { useResponsiveInfo } from 'providers'
import { colors, spaces } from 'theme'

type Props = {
  chartLockDate: Date | string
}

export const ChartLockCountdownBar = ({ chartLockDate }: Props) => {
  const { isBigDevice } = useResponsiveInfo()
  const { timeRemaining, clockBackground } = useChartLockCountdown({ chartLockDate })
  const { days, hours, minutes } = timeRemaining
  const type = days < CHART_LOCK_COUNTDOWN_URGENT_THRESHOLD_DAYS ? 'error' : 'warning'

  return (
    <StyledAlertBar type={type} size='medium' ButtonComponent={<UpgradeSubscriptionButton buttonSize='small' />}>
      <FlipClockCountdown
        countdown={{ days, hours, minutes }}
        background={clockBackground}
        color={colors.white}
        flipPadHeight={isBigDevice ? undefined : '36px'}
        flipPadWidth={isBigDevice ? undefined : '32px'}
      />
      <FlexBox $isDirectionColumn $spacing={{ ml: spaces.xl }} $gap='s'>
        <AlertBoxTitle>Action Required: Upgrade and avoid your org chart lockout</AlertBoxTitle>
        {isBigDevice && (
          <AlertBoxDescription>
            Upgrade to Org Chart PRO now to have access to all of its features and enjoy uninterrupted usage. Otherwise
            your organizational charts will be locked.
          </AlertBoxDescription>
        )}
      </FlexBox>
    </StyledAlertBar>
  )
}

const StyledAlertBar = styled(AlertBar)`
  padding: ${props => props.theme.spaces.m};
  border-radius: 0;

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    padding: ${props => props.theme.spaces.l};
  }
`
