import { generatePath, useHistory } from 'react-router-dom'
import { History } from 'history'

import { useChartId } from 'tree/providers'
import { Node } from 'tree/types'
import { DEPARTMENT_CREATE_PATH, EMPLOYEE_CREATE_PATH } from 'routes'

export type GoToAddNodeParams = {
  chartUuid: string
  history: History<unknown>
  parentUuid: string
}

export const goToAddEmployee = ({ chartUuid, history, parentUuid }: GoToAddNodeParams) => {
  history.push(generatePath(EMPLOYEE_CREATE_PATH, { chartUuid, parentUuid }))
}

export const goToAddDepartment = ({ chartUuid, history, parentUuid }: GoToAddNodeParams) => {
  history.push(generatePath(DEPARTMENT_CREATE_PATH, { chartUuid, parentUuid }))
}

export const useGoToAddEmployee = () => {
  const history = useHistory()
  const chartUuid = useChartId()

  const handler = (parentUuid: Node['id']) => {
    goToAddEmployee({ chartUuid, history, parentUuid })
  }

  return { goToAddEmployee: handler }
}

export const useGoToAddDepartment = () => {
  const history = useHistory()
  const chartUuid = useChartId()

  const handler = (parentUuid: Node['id']) => {
    goToAddDepartment({ chartUuid, history, parentUuid })
  }

  return { goToAddDepartment: handler }
}
