import { AnimationEvent, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { DragElementWrapper, DragSourceOptions, useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { useInView } from 'react-intersection-observer'

import { useChartNodeDragPreview } from './use-chart-node-drag-preview'
import { useUpdateTree } from './use-tree-update'
import { useRefetchPersonTeamSize } from './use-refetch-person-team-size'
import { useIsOnPublicRoute } from './use-is-on-public-route'
import { useNodesInViewport } from '../providers/use-nodes-in-viewport'
import { useTree } from '../providers/use-tree'
import { useIsSafariBrowser, useResponsiveInfo } from 'providers'
import { Company, Department, Node, NodeTypename, Person } from '../types'
import { COMPANY_UUID } from 'consts'
import { findSuperiorPerson } from 'helpers'

import { nodeDataFragment } from 'apollo/fragments'
import { NodeDataFragmentFragment, useMoveNodeMutation } from 'apollo/generated/graphql'

type Result<T> = T & {
  canDrop: boolean
  expanded: boolean
  inView: boolean
  isOver: boolean
  isNodeHovered: boolean
  showActions: boolean
  handleBorderAnimationEnd: (e: AnimationEvent<HTMLDivElement>) => void
  setIsNodeHovered: (isNodeHovered: boolean) => void
  toggleExpanded: () => void
  // Refs
  dragRef: DragElementWrapper<DragSourceOptions>
  setNodeRefs: (node: HTMLDivElement | null) => void
  setNodeInnerRefs: (node: HTMLDivElement | null) => void
}

const getUuid = (uuid?: string): string | undefined | null => (uuid === COMPANY_UUID ? null : uuid)

export function useNode(node: Department): Result<Department>
export function useNode(node: Person): Result<Person>
export function useNode(node: Company): Result<Company>
export function useNode(node: Node): Result<Node> {
  const { __typename, expanded, id } = node
  const { register, unregister, setExpandedNode, expandNodesOnMove } = useTree()
  const { updateTree } = useUpdateTree()
  const [inViewRef, inView] = useInView({ initialInView: __typename === 'Company' }) // FIXME: Workaround to not display reset position helper when there are no nodes and navigating away from invite collaborators side panel
  const { isSafariBrowser } = useIsSafariBrowser()

  // Show actions
  const [isNodeHovered, setIsNodeHovered] = useState(false)
  const isOnPublicRoute = useIsOnPublicRoute()
  const { isSmallerDevice } = useResponsiveInfo()
  const showActions = !isOnPublicRoute && (isSmallerDevice || isNodeHovered)

  // Expand node
  useEffect(() => {
    if (expanded !== expanded) setExpandedNode(node, expanded)
  }, [expanded, setExpandedNode])

  const toggleExpanded = useCallback(() => {
    setExpandedNode(node, !expanded)
  }, [node, setExpandedNode])

  // Viewport visibility of node
  const { addNodeToViewport, removeNodeFromViewport } = useNodesInViewport()
  const onViewportVisibilityChange = (isVisible: boolean) => {
    isVisible ? addNodeToViewport(id) : removeNodeFromViewport(id)
  }
  useEffect(() => {
    onViewportVisibilityChange(inView)
  }, [inView])

  useEffect(() => {
    return () => removeNodeFromViewport(id)
  }, [])

  // Border animation
  const onBorderAnimationEnd = (e: AnimationEvent<HTMLDivElement>) => {
    ;(e.target as HTMLDivElement).classList.remove('animate-border')
  }

  // Drag
  const { canMove = false, canUnassign = false } = __typename !== 'Company' ? node.capabilities || {} : {}
  const canDrag = canMove || canUnassign
  const [{ isDragging }, dragRef, previewRef] = useDrag({
    item: {
      __typename,
      chartUuid: node.chartUuid,
      id,
      parentUuid: node.parentUuid || COMPANY_UUID,
      employeeCount: node.employeeCount ?? 0,
      departmentCount: node.departmentCount ?? 0,
    },
    canDrag,
    type: __typename,
    collect: monitor => ({ isDragging: monitor.isDragging() }),
  })

  // Drop
  const client = useApolloClient()
  const [moveNode] = useMoveNodeMutation()
  const acceptedNodes: NodeTypename[] = ['Person', 'Department']
  const { isGsuiteProvider, refetchPersonTeamSize } = useRefetchPersonTeamSize()

  const [{ isOver, canDrop }, dropRef] = useDrop({
    accept: acceptedNodes,
    drop: (item: { __typename: NodeTypename; id: string; parentUuid?: string }) => {
      const oldResult = client.readFragment<NodeDataFragmentFragment>({
        id: client.cache.identify(item),
        fragment: nodeDataFragment,
        fragmentName: 'NodeDataFragment',
      })

      moveNode({
        variables: { chartUuid: node.chartUuid, uuid: item.id, targetUuid: getUuid(id) },
        update: async (cache, { data }) => {
          const movedNode = data?.moveNode

          if (movedNode) {
            updateTree({
              cache,
              mutationData: movedNode,
              prevParentUuid: getUuid(item.parentUuid),
              nextParentUuid: getUuid(id),
              prevData: oldResult,
            })

            const parentUuid = item.parentUuid || null
            const parentUuidList = [null, ...movedNode.parentNodes.map(({ uuid }) => uuid)]
            await expandNodesOnMove(parentUuid, parentUuidList)

            // Refetch teamSize
            if (isGsuiteProvider) {
              const previousSuperiorPerson = oldResult && findSuperiorPerson(oldResult)
              refetchPersonTeamSize(previousSuperiorPerson?.id)
              const currentSuperiorPerson = movedNode && findSuperiorPerson(movedNode)
              refetchPersonTeamSize(currentSuperiorPerson?.id)
            }
          }
        },
      })
    },
    canDrop: item => {
      const path = (node.parentNodes || []).map(n => n.uuid)
      return !path.includes(item.id) && item.id !== id && item.parentUuid !== id
    },
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })

  // Register node
  const nodeInnerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const shouldRegister = id && nodeInnerRef.current != null && __typename !== 'Company'
    if (shouldRegister) register(id, nodeInnerRef as MutableRefObject<HTMLElement>)
    return () => unregister(id)
  }, [register, unregister, nodeInnerRef, id])

  // Refs
  const nodeDragPreviewRef = useRef<HTMLDivElement | null>(null)

  const setNodeRefs = useCallback(
    (node: HTMLDivElement | null) => {
      inViewRef(node)
      if (isSafariBrowser) {
        nodeDragPreviewRef.current = node
        previewRef(getEmptyImage(), { captureDraggingState: true }) // Renders empty default drag preview
      } else {
        previewRef(node)
      }
    },
    [isSafariBrowser, inViewRef, previewRef, getEmptyImage]
  )

  const setNodeInnerRefs = useCallback(
    (node: HTMLDivElement | null) => {
      nodeInnerRef.current = node
      dropRef(node)
    },
    [__typename, dropRef]
  )

  // Safari fix - chart node preview on drag
  const { showCustomChartNodePreview, hideCustomChartNodePreview } = useChartNodeDragPreview({
    isDragging,
    isPreviewEnabled: isSafariBrowser,
    nodeRef: nodeDragPreviewRef,
  })
  useEffect(() => {
    if (isSafariBrowser) isDragging ? showCustomChartNodePreview() : hideCustomChartNodePreview()
  }, [isDragging])

  return {
    ...node,
    canDrop,
    inView,
    isNodeHovered,
    isOver,
    showActions,
    handleBorderAnimationEnd: onBorderAnimationEnd,
    setIsNodeHovered,
    toggleExpanded,
    // Refs
    dragRef,
    setNodeRefs,
    setNodeInnerRefs,
  }
}
