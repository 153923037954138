import styled from 'styled-components'

import { CardTitle } from '../../../components'
import { FlexBox, ListItem, UnsortedList } from 'components/generic'
import { ChainLinkIcon } from 'components/icons'

import { UpgradeSubscriptionButton } from 'features/premium'
import { colors, spaces } from 'theme'

export const SharePremiumBenefits = () => (
  <FlexBox $isDirectionColumn>
    <CardTitle>Powerful and flexible way to share your Org Chart with others</CardTitle>
    <FlexBox $isDirectionColumn>
      <UnsortedList $gap='m'>
        <ListItem iconColor={colors.purpleLighter}>Others always see the current version of your Org Chart </ListItem>
        <ListItem iconColor={colors.purpleLighter}>No more need of export to PDF or PNG</ListItem>
        <ListItem iconColor={colors.purpleLighter}>
          The ability to restrict access for users of a specific domain
        </ListItem>
      </UnsortedList>
      <FlexBox $spacing={{ mt: spaces.l }}>
        <UpgradeSubscriptionButton />
      </FlexBox>
    </FlexBox>

    <ChainLinkContainer $isCentered>
      <ChainLinkIcon color={colors.purple} />
    </ChainLinkContainer>
  </FlexBox>
)

const ChainLinkContainer = styled(FlexBox)`
  display: none;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    position: absolute;
    top: 10px;
    right: -40px;
    width: 227px;
    height: 227px;
    display: flex;
    border-radius: 50%;
    background: ${props => props.theme.colors.purpleExtraLight};
    z-index: -1;
  }
`
