import styled from 'styled-components'

type Props = {
  message: string
  className?: string
}

export const FieldErrorMessage = ({ message, className }: Props) => <Message className={className}>{message}</Message>

const Message = styled.div`
  width: 100%;
  color: ${props => props.theme.colors.red};
  font-size: 11px;
  line-height: 16px;
  user-select: none;
  pointer-events: none;

  &:before {
    content: '';
    width: 100%;
    height: 2px;
    display: block;
    margin-bottom: ${props => props.theme.spaces.xs};
    background: ${props => props.theme.colors.red};
  }
`
