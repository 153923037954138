import { ApolloCache } from '@apollo/client'
import { departmentIdentifyFragment, personIdentifyFragment } from '../fragments'
import { DepartmentIdentifyFragmentFragment, PersonIdentifyFragmentFragment } from '../generated/graphql'

export const getChartNormalizedId = (id: string) => `Chart:${id}`
export const getPersonNormalizedId = (id: string | null) => `Person:${id}`
export const getDepartmentNormalizedId = (id: string | null) => `Department:${id}`

// Finds out if it's Person or Department only via id
const getIdentificationNode = (id: string | null, cache: ApolloCache<unknown>) => {
  const person = cache.readFragment<PersonIdentifyFragmentFragment>({
    id: getPersonNormalizedId(id),
    fragment: personIdentifyFragment,
    fragmentName: 'PersonIdentifyFragment',
  })
  if (person) return person

  const department = cache.readFragment<DepartmentIdentifyFragmentFragment>({
    id: getDepartmentNormalizedId(id),
    fragment: departmentIdentifyFragment,
    fragmentName: 'DepartmentIdentifyFragment',
  })
  if (department) return department

  return null
}

export const getNodeNormalizedId = (id: string | null, cache: ApolloCache<unknown>) => {
  const identityNode = getIdentificationNode(id, cache)
  if (identityNode) {
    return cache.identify(identityNode)
  }
  return null
}
