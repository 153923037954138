import styled, { css } from 'styled-components'

export const CardTitle = styled.div<{ withPadding?: boolean }>`
  margin-bottom: ${props => props.theme.spaces.l};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  ${props =>
    props.withPadding &&
    css`
      padding: ${props => props.theme.spaces.l};
      margin-bottom: 0;
    `};
`
