import { useEffect } from 'react'
/**
 * useKeyPress
 * @param {string} key - the name of the key to respond to, compared against event.key
 * @param {function} action - the action to perform on key press
 */
export default function useKeypress(key: string, action: any, prevent?: boolean) {
  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      if (e.key === key && !prevent) action()
    }
    window.addEventListener('keyup', onKeyDown)
    return () => window.removeEventListener('keyup', onKeyDown)
  })
}
