import { Fragment, MutableRefObject } from 'react'
import { FormikProps } from 'formik'

import { FormSection } from './form-section'
import { GroupHeading } from '../../components'
import { OpenBookIcon } from 'components/icons'

import { useFieldFactory } from '../fields'
import { useChartData } from 'tree/providers'
import { ContactFieldKey, ContactFields, PersonFormCapabilities, PersonFormValues } from 'tree/types'
import { getPriority } from 'consts'

type Props = {
  capabilities: PersonFormCapabilities
  formState: FormikProps<PersonFormValues>
  sectionRef: MutableRefObject<HTMLElement | null>
}

export const ContactsForm = ({ capabilities, formState, sectionRef }: Props) => {
  const { providerInfo, GWTokenRevoked, capabilities: chartCapabilities } = useChartData()
  const { canUpdate: canUpdateChart } = chartCapabilities || {}
  const fieldFactory = useFieldFactory({ capabilities, providerInfo, canUpdateChart, GWTokenRevoked })

  const { values } = formState
  const { emailAliases, email, facebookLink, instagramLink, linkedInLink, mobile, whatsAppLink, xLink, youTubeLink } =
    values
  const contactFields: ContactFields = {
    emailAliases,
    email,
    mobile,
    facebookLink,
    instagramLink,
    linkedInLink,
    whatsAppLink,
    xLink,
    youTubeLink,
  }
  const contactFieldKeys: ContactFieldKey[] = Object.entries(contactFields)
    .map(([key]) => key as ContactFieldKey)
    .sort((a, b) => getPriority(b) - getPriority(a))

  return (
    <FormSection ref={sectionRef}>
      <GroupHeading icon={<OpenBookIcon width='17px' />} title='Contacts' />

      {contactFieldKeys.map(key => (
        <Fragment key={key}>{fieldFactory(key, formState)}</Fragment>
      ))}
    </FormSection>
  )
}
