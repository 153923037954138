import { generatePath, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'

import { CreateOrEdit, Detail, Empty } from './components'
import { View, ViewHeader, ViewTitle, NewFieldButton } from '../components'

import { useChartData, useChartId } from 'tree/providers'
import { useChartPermission } from 'tree/hooks'
import { CustomConnectionField } from 'tree/types'
import { getIsSubscribed } from 'features/premium'
import { SETTINGS_CREATE_DOTTED_LINE_PATH, SETTINGS_DOTTED_LINES_PATH, SETTINGS_EDIT_DOTTED_LINE_PATH } from 'routes'
import analytics from 'analytics'
import { CUSTOM_FIELDS_TRACK } from 'analytics/constants'

// if getCanShowDottedLineView
export const ConnectionFields = () => {
  const chartUuid = useChartId()
  const history = useHistory()
  const { customFields, subscription } = useChartData()
  const { canCreateCustomField } = useChartPermission()
  const isSubscribed = getIsSubscribed(subscription)
  const connectionFields = customFields.filter(cf => cf.type === 'connection') as CustomConnectionField[]

  const detailPath = generatePath(SETTINGS_DOTTED_LINES_PATH, { chartUuid })
  const createPath = generatePath(SETTINGS_CREATE_DOTTED_LINE_PATH, { chartUuid })

  const isOnDetail = Boolean(useRouteMatch(SETTINGS_DOTTED_LINES_PATH)?.isExact)
  const isCreating = Boolean(useRouteMatch(SETTINGS_CREATE_DOTTED_LINE_PATH)?.isExact)
  const isEditing = Boolean(useRouteMatch(SETTINGS_EDIT_DOTTED_LINE_PATH)?.isExact)

  const title = isCreating ? 'Create new connection field' : isEditing ? 'Edit connection field' : 'Connection fields'

  const showCreateConnectionField = canCreateCustomField && isSubscribed && isOnDetail

  const goToCreateConnectionField = () => {
    history.push(createPath)
    analytics.track(CUSTOM_FIELDS_TRACK.beginCreate, { chartUuid })
  }

  const onBackClick = () => {
    if (isCreating) analytics.track(CUSTOM_FIELDS_TRACK.cancelCreate, { chartUuid })
    history.push(detailPath)
  }

  if (connectionFields.length === 0 && !isCreating) {
    return <Empty viewTitle={title} onClick={goToCreateConnectionField} />
  }

  return (
    <View quota='chart.customField'>
      <ViewHeader backTitle='Back on connection fields list' onBackClick={isOnDetail ? undefined : onBackClick}>
        <ViewTitle>{title}</ViewTitle>
        {showCreateConnectionField && (
          <NewFieldButton onClick={goToCreateConnectionField}>New connection field</NewFieldButton>
        )}
      </ViewHeader>

      <Switch>
        <Route
          path={[SETTINGS_CREATE_DOTTED_LINE_PATH, SETTINGS_EDIT_DOTTED_LINE_PATH]}
          render={() => <CreateOrEdit connectionFields={connectionFields} onBack={() => history.push(detailPath)} />}
          exact
        />
        <Route render={() => <Detail connectionFields={connectionFields} />} />
      </Switch>
    </View>
  )
}
