import { css } from 'styled-components'

export const chartCardCSS = css`
  position: relative;
  width: 100%;
  max-width: 500px;
  min-height: 320px;
  max-height: 320px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(69, 90, 100, 0.16);
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.grey};
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
`

export const chartCardHeadingCSS = css`
  position: relative;
  min-height: 90px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.l};
  background: ${props => props.theme.colors.white};

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${props => props.theme.colors.border};
    opacity: 0.4;
  }
`

export const chartCardContentCSS = css`
  flex: 1;
  position: relative;
  display: flex;
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.l};
  background: ${props => props.theme.colors.greyCard};
  overflow: hidden;
`
