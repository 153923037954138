import { CSSProperties } from 'react'

export const applyCSSToElement = (element: HTMLElement, styles: CSSProperties) => {
  Object.keys(styles).forEach(_key => {
    const key = _key as keyof CSSProperties
    try {
      const value = styles[key] as string
      element.style[key as any] = value
    } catch (error) {
      console.error("Can't assign CSS value to a key: ", key, ' on element: ', element)
    }
  })
}
