import { css } from 'styled-components'

export const LinkStyles = css`
  display: inline-flex;
  color: ${props => props.theme.colors.linkColor};
  font-family: inherit;
  font-style: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
  box-shadow: inset 0px -1px 0px ${props => props.theme.colors.greyExtraLight};
  transition: ${props => props.theme.transitions.fastEase};
  cursor: pointer;

  &:hover {
    box-shadow: inset 0px -1px 0px ${props => props.theme.colors.linkColor};
  }
`
