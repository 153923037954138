import { SVG, SVGProps } from './SVG'

export const DottedLineReportingIcon = (props: SVGProps) => (
  <SVG width='36' height='31' viewBox='0 0 36 31' fill='none' {...props}>
    <path
      d='M4.28568 3.42857C4.28568 2.78447 4.54155 2.16676 4.99699 1.71131C5.45244 1.25587 6.07015 1 6.71425 1C7.35835 1 7.97606 1.25587 8.43151 1.71131C8.88696 2.16676 9.14282 2.78447 9.14282 3.42857C9.14282 4.07267 8.88696 4.69039 8.43151 5.14583C7.97607 5.60128 7.35835 5.85714 6.71425 5.85714C6.07015 5.85714 5.45244 5.60128 4.99699 5.14583C4.54155 4.69039 4.28568 4.07267 4.28568 3.42857Z'
      stroke='currentColor'
      strokeWidth='2'
    />
    <path
      d='M1 13.9185C1 11.2801 3.1375 9.14258 5.77589 9.14258H8.22411C10.8625 9.14258 13 11.2801 13 13.9185'
      stroke='currentColor'
      strokeWidth='2'
    />
    <circle cx='15' cy='18' r='2' fill='currentColor' />
    <circle cx='20' cy='22' r='2' fill='currentColor' />
    <path
      d='M26.2857 20.4286C26.2857 19.7845 26.5415 19.1668 26.997 18.7113C27.4524 18.2559 28.0702 18 28.7143 18C29.3584 18 29.9761 18.2559 30.4315 18.7113C30.887 19.1668 31.1428 19.7845 31.1428 20.4286C31.1428 21.0727 30.887 21.6904 30.4315 22.1458C29.9761 22.6013 29.3584 22.8571 28.7143 22.8571C28.0702 22.8571 27.4524 22.6013 26.997 22.1458C26.5415 21.6904 26.2857 21.0727 26.2857 20.4286Z'
      stroke='currentColor'
      strokeWidth='2'
    />
    <path
      d='M23 30.9185C23 28.2801 25.1375 26.1426 27.7759 26.1426H30.2241C32.8625 26.1426 35 28.2801 35 30.9185'
      stroke='currentColor'
      strokeWidth='2'
    />
  </SVG>
)
