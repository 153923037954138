import { getDate } from './get-date'
import { isValidDate } from './is-valid-date'
import { DAY_IN_MS, HOUR_IN_MS, MINUTE_IN_MS, SECOND_IN_MS } from './time-in-ms'

export type TimeRemaining = {
  /** Total time remaining in milliseconds */
  totalRemainingMs: number
  days: number
  hours: number
  minutes: number
  seconds: number
  millisecond: number
}

export const NO_TIME_REMAINING: TimeRemaining = {
  totalRemainingMs: 0,
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
  millisecond: 0,
}

type GetTimeRemainingParams = {
  to: Date | string | number
  /** If no `from` date is passed, calculate from current `new Date()` date. */
  from?: Date | string | number
}

/** Calculates how much time is remaining between two dates (from date is current `new Date()` date when not defined). */
export const getTimeRemaining = ({ from: fromDate, to: toDate }: GetTimeRemainingParams): TimeRemaining | null => {
  const newFromDate = typeof fromDate === 'undefined' ? new Date() : getDate(fromDate)
  const newToDate = getDate(toDate)

  if (!isValidDate(newToDate) || !isValidDate(newFromDate)) {
    return null
  }

  const timeDifference = newToDate.getTime() - newFromDate.getTime()

  if (timeDifference < 0) {
    return NO_TIME_REMAINING
  }

  const days = Math.floor(timeDifference / DAY_IN_MS)
  const hours = Math.floor((timeDifference % DAY_IN_MS) / HOUR_IN_MS)
  const minutes = Math.floor((timeDifference % HOUR_IN_MS) / MINUTE_IN_MS)
  const seconds = Math.floor((timeDifference % MINUTE_IN_MS) / SECOND_IN_MS)
  const millisecond = Math.floor(timeDifference % SECOND_IN_MS)

  return {
    totalRemainingMs: timeDifference,
    days,
    hours,
    minutes,
    seconds,
    millisecond,
  }
}
