import { useChartId, useTree, useTreeState } from 'tree/providers'

export const useExpandNodesOnCreate = () => {
  const chartUuid = useChartId()
  const { getNodesByParentUuidList, restoreCache, getUniqueExpandedNodesFromList } = useTree()
  const { treeStateRef, replaceExpandedNodes } = useTreeState()

  const expandNodesOnCreate = async (parentUuidList: (string | null)[]) => {
    const items = await getNodesByParentUuidList(parentUuidList)
    await restoreCache(chartUuid, parentUuidList, items)
    const currExpandedNodes = treeStateRef.current.expandedNodes
    const newExpandedNodes = getUniqueExpandedNodesFromList(parentUuidList, currExpandedNodes)
    replaceExpandedNodes([...currExpandedNodes, ...newExpandedNodes])
  }

  return { expandNodesOnCreate }
}
