import styled from 'styled-components'

import { View, ViewHeader, ViewTitle } from '../../components'
import { Card, PremiumBenefits } from '../../../components'
import { Button, FlexBox, ListItem, UnsortedList } from 'components/generic'
import { PlusIcon } from 'components/icons'

import { useChartData } from 'tree/providers'
import { useChartPermission } from 'tree/hooks'
import { CONNECTION_LINE_OPTIONS } from 'tree/constants'
import { getIsSubscribed, UpgradeSubscriptionButton } from 'features/premium'
import { colors, spaces } from 'theme'

import dottedLinePreviewOnSidePanel from 'assets/dotted-line-preview-on-side-panel.png'

type Props = {
  viewTitle: string
  onClick: () => void
}

export const Empty = ({ viewTitle, onClick }: Props) => {
  const { subscription } = useChartData()
  const { canCreateCustomField } = useChartPermission()
  const isSubscribed = getIsSubscribed(subscription)

  return (
    <View>
      <ViewHeader>
        <ViewTitle>{viewTitle}</ViewTitle>
      </ViewHeader>

      <StyledCard
        title={
          canCreateCustomField
            ? 'Create connections with employees from different departments.'
            : 'Your chart has no connection fields yet.'
        }
      >
        <Content>
          <FlexBox $isDirectionColumn $spacing={{ mb: spaces.m }}>
            <Text>
              These connections are used in the Org Chart to represent different types of relationships between
              employees. You can choose between:
            </Text>
          </FlexBox>

          <UnsortedList $gap='m'>
            {Object.values(CONNECTION_LINE_OPTIONS).map(({ lineStyle, lineLabel }) => (
              <ListItem key={lineStyle} iconColor={colors.purpleLighter}>
                {lineLabel}
              </ListItem>
            ))}
          </UnsortedList>
        </Content>

        <FlexBox>
          {isSubscribed && canCreateCustomField && (
            <Button onClick={onClick} data-testid='button-create-a-new-custom-field'>
              <PlusIcon $spacing={{ mr: spaces.m }} />
              Create connection field
            </Button>
          )}
          {!isSubscribed && <UpgradeSubscriptionButton />}
        </FlexBox>

        {!isSubscribed && <PremiumBenefits spacing={{ mt: spaces.xl }} />}

        <DottedLinePreviewImg src={dottedLinePreviewOnSidePanel} alt='dotted line preview' />
      </StyledCard>
    </View>
  )
}

const StyledCard = styled(Card)`
  overflow: hidden;
`

const DottedLinePreviewImg = styled.img`
  display: none;

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    position: absolute;
    top: -20px;
    right: -50px;
    width: 100%;
    max-width: 380px;
    display: block;
    z-index: -1;
  }
`

const Content = styled.div`
  margin-bottom: ${props => props.theme.spaces.l};
`

const Text = styled.div`
  max-width: 430px;
  color: ${props => props.theme.colors.dark};
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background: ${props => props.theme.colors.white};
`
