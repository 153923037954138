type Params = {
  /** The IP address to validate, formatted as `x.x.x.x` or `x.x.x.x/y` if `hasSubnet` is `true`. */
  ipAddress: string
  /** Whether a subnet is required in the IP address. Defaults to `false`. */
  hasSubnet?: boolean
  /** Lowest number for a subnet. Default is `0`. */
  subnetLowest?: number
  /** Highest number for a subnet. Default is `32`. */
  subnetHighest?: number
}

const IP_LOWEST = 0
const IP_HIGHEST = 255

export const validateIpAddress = ({
  ipAddress,
  hasSubnet,
  subnetLowest = 0,
  subnetHighest = 32,
}: Params): string | undefined => {
  const [address, subnet] = ipAddress.split('/')

  // Split the address into parts and validate length
  const octets = address.split('.')
  if (octets.length !== 4) {
    return hasSubnet
      ? `Invalid IP address format. Expected format: x.x.x.x/y where "x" is a number between ${IP_LOWEST} and ${IP_HIGHEST} and "y" is a number between ${subnetLowest} and ${subnetHighest}`
      : `Invalid IP address format. Expected format: x.x.x.x where "x" is a number between ${IP_LOWEST} and ${IP_HIGHEST}`
  }

  // Validate each octet
  for (const octet of octets) {
    // Check if octet is valid integer without any letters or symbols OR without leading zeros
    if (!/^\d+$/.test(octet) || (octet.length > 1 && octet.startsWith('0'))) {
      return `Invalid IP address format. Each octet should be a number between ${IP_LOWEST} and ${IP_HIGHEST} without letters or symbols`
    }

    // Validate octet range
    const number = parseInt(octet, 10)
    if (number < IP_LOWEST || number > IP_HIGHEST) {
      return `Invalid IP address format. Each octet should be a number between ${IP_LOWEST} and ${IP_HIGHEST}`
    }
  }

  // Validate subnet part
  if (hasSubnet) {
    if (subnet === undefined) {
      return `Subnet is required. Expected format: x.x.x.x/y where "y" is a number between ${subnetLowest} and ${subnetHighest}`
    }

    // Check if subnet is valid integer without any letters or symbols
    if (!/^\d+$/.test(subnet)) {
      return `Invalid subnet format. Subnet should be a value between ${subnetLowest} and ${subnetHighest}`
    }

    // Validate subnet range
    const subnetNumber = parseInt(subnet, 10)
    if (subnetNumber < subnetLowest || subnetNumber > subnetHighest) {
      return `Invalid subnet format. Subnet should be a number between ${subnetLowest} and ${subnetHighest}`
    }
  } else if (subnet !== undefined) {
    // If no subnet is expected but found in the input
    return 'Unexpected subnet provided. Expected format: x.x.x.x'
  }
}
