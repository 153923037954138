import { useHistory } from 'react-router-dom'

import { useForkChartRequest } from './use-fork-chart-request'
import { ChartDetail, Node, WithConfirmModal } from '../../types'
import { ConfirmationModalType } from 'components/specific'
import { useModal } from 'modal'
import analytics from 'analytics'
import { ORG_TRACK } from 'analytics/constants'
import { CHART_EDIT_PATH } from 'routes'
import { handleErrorValidation } from 'helpers'

type ForkChartActionParams = {
  chartId: ChartDetail['id']
  providerInfo: ChartDetail['providerInfo']
  rootId: Node['id']
}

type ForkChartActionWithConfirmParams = ForkChartActionParams & {
  rootName: string
}

export const useForkChartAction = () => {
  const history = useHistory()
  const { open } = useModal()
  const { forkChartRequest } = useForkChartRequest()

  const forkChart = async ({ chartId, rootId }: ForkChartActionParams) => {
    try {
      const res = await forkChartRequest({ chartId, rootId })
      if (!res.data?.cloneChart) {
        throw new Error('Payload missing')
      }
      analytics.track(ORG_TRACK.cloneChart, { uuid: chartId, rootUuid: rootId })
      history.push(CHART_EDIT_PATH.replace(':chartUuid', res.data.cloneChart.id))
      return Promise.resolve(res)
    } catch (error) {
      handleErrorValidation({
        track: { message: ORG_TRACK.cloneChartFailure, values: { chartUuid: chartId, error } },
        toast: { message: 'Failed to clone Org Chart' },
      })
    }
  }

  const forkChartWithConfirm = async ({
    chartId,
    providerInfo,
    rootName,
    rootId,
    onAbort,
  }: ForkChartActionWithConfirmParams & WithConfirmModal) => {
    const providedByGW = providerInfo?.name === 'gsuite'
    const GWNote = providedByGW ? ' Please note that your new Org Chart will not be synced with Google Workspace.' : ''

    return new Promise((resolve, reject) => {
      let hasConfirmed = false
      open<ConfirmationModalType>('confirmationModal', {
        title: { text: 'Create chart starting from this position' },
        message: `Do you really want to create new Org Chart starting from ${rootName}?${GWNote}`,
        onConfirm: () => {
          hasConfirmed = true
          forkChart({ chartId, providerInfo, rootId }).catch(reject).then(resolve)
        },
        onClose: () => {
          if (!hasConfirmed) onAbort?.()
        },
      })
    })
  }

  return { forkChart, forkChartWithConfirm }
}
