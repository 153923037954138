export const getBlobFromUrl = async (url: string) => {
  const response = await fetch(url)
  return response.blob()
}

export const downloadFileFromBlob = (blob: Blob, fileName: string) => {
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = fileName
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000)
}

export const normalizeFileName = (fileName: string) => {
  return fileName
    .substring(0, 99)
    .replace(/\s+/g, '-') // replace space with dash
    .replace(/(<([^>]+)>)/gi, '') // strip tags
    .replace(/[^A-Za-z0-9-]+/g, '') // whitelist characters
    .toLocaleLowerCase()
}
