import { useDeleteNodeRequest } from './use-delete-node-request'
import { WithConfirmModal } from '../../types'
import { ConfirmationModalType } from 'components/specific'
import { useModal } from 'modal'
import { useChartData } from 'tree/providers'
import analytics from 'analytics'
import { ORG_TRACK } from 'analytics/constants'
import { handleErrorValidation } from 'helpers'

import { NodeDataFragmentFragment } from 'apollo/generated/graphql'

type DeleteNodeActionParams = {
  node: Record<string, unknown> &
    Pick<
      NodeDataFragmentFragment,
      'uuid' | 'name' | '__typename' | 'employeeCount' | 'departmentCount' | 'parentUuid' | 'parentNodes'
    >
}

export const useDeleteNodeAction = () => {
  const { uuid: chartUuid, name: chartName } = useChartData()
  const { open } = useModal()
  const { deleteNodeRequest } = useDeleteNodeRequest()

  const deleteNode = async ({ node }: DeleteNodeActionParams) => {
    const { uuid, name, __typename, employeeCount, departmentCount } = node
    const nodeChildrenCount = employeeCount + departmentCount
    const nodeType = __typename === 'Person' ? 'employee' : 'department'
    const trackKey = __typename === 'Person' ? 'deleteEmployee' : 'deleteDepartment'
    const trackBaseObj = {
      chartName,
      chartUuid,
      nodeChildrenCount,
      nodeName: name,
      onNodeUuid: uuid,
      nodeType: __typename,
    }

    try {
      const res = await deleteNodeRequest({ node })
      analytics.track(ORG_TRACK[trackKey], trackBaseObj)
      return Promise.resolve(res)
    } catch (error) {
      handleErrorValidation({
        track: {
          message: `Deleting ${nodeType} failed` as const,
          values: { ...trackBaseObj, error },
        },
        toast: { message: `Failed to delete ${nodeType}` },
      })
    }
  }

  const deleteNodeWithConfirm = async ({ node, onAbort }: DeleteNodeActionParams & WithConfirmModal) => {
    return new Promise((resolve, reject) => {
      let hasConfirmed = false
      const nodeType = node.__typename === 'Person' ? 'employee' : 'department'
      const nodeName = node.name
      open<ConfirmationModalType>('confirmationModal', {
        cancelButton: { buttonType: 'secondary' },
        confirmButton: { buttonType: 'danger', children: 'Delete' },
        message: `Permanently delete your ${nodeType}. This cannot be undone.`,
        title: { text: `Delete ${nodeName}` },
        onConfirm: () => {
          hasConfirmed = true
          deleteNode({ node }).catch(reject).then(resolve)
        },
        onClose: () => {
          if (!hasConfirmed) onAbort?.()
        },
      })
    })
  }

  return { deleteNode, deleteNodeWithConfirm }
}
