import { useHistory, useRouteMatch } from 'react-router-dom'

import { PremiumPayScreenLocationState, PremiumPayScreenUrlParams } from './use-premium-screen-handler'
import { useChartId } from 'tree/providers'
import { PAY_PREMIUM_PATH } from 'routes'
import { createQueryString } from 'helpers'
import analytics from 'analytics'
import { SUBSCRIPTION_TRACK } from 'analytics/constants'

type Params = Partial<PremiumPayScreenUrlParams> | undefined

const getModifiedOrigin = (path: string) => {
  let result = path.startsWith('/') ? path.slice(1) : path
  result = result.replaceAll('/', '__')
  result = result.replaceAll(':', '')
  return result
}

export const useGoToPremiumScreen = () => {
  const chartUuid = useChartId(true)
  const router = useRouteMatch()
  const history = useHistory<PremiumPayScreenLocationState>()

  const goToPremiumScreen = ({ billingPeriod, plan, coupon }: Params = {}) => {
    analytics.track(SUBSCRIPTION_TRACK.getInfo, { chartUuid, from: router.path })
    const { queryString } = createQueryString({ billingPeriod, plan, coupon })
    const origin = getModifiedOrigin(router.path)
    history.push({ pathname: PAY_PREMIUM_PATH, search: queryString }, { origin })
  }

  return { goToPremiumScreen }
}
