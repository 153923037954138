import { useEffect } from 'react'
import styled from 'styled-components'

import { RouteComponentProps } from 'react-router-dom'
import qs from 'query-string'
import { decode } from 'jsonwebtoken'

import { Loading, Screen } from 'components/generic'
import { CREATE_CHART_PATH } from 'routes'

import { OAuth2Scope } from 'apollo/generated/graphql'

const Loader = () => (
  <LoadingScreen>
    <Loading size='big' />
  </LoadingScreen>
)

type JWTPayload = {
  redirectPath: string
  requestedScopes: OAuth2Scope[]
}

export const GSuiteConnectionLoader = ({ history, location }: RouteComponentProps) => {
  const search = qs.parse(location.search)
  const code = search.code as string
  const state = search.state as string
  const error = search.error as string

  const redirect = (path: string, state?: unknown) => history.replace(path, state)
  const redirectToCreateChart = () => redirect(CREATE_CHART_PATH)

  const isCodeValid = typeof code === 'string' && code.length > 0

  useEffect(() => {
    const { requestedScopes, redirectPath } = (decode(state) as JWTPayload) || {}
    const redirectState = isCodeValid ? { code, state, requestedScopes } : error ? { error } : {}
    redirect(redirectPath, redirectState)
  }, [isCodeValid, state, error])

  if (!isCodeValid) {
    redirectToCreateChart()
    return <Loader />
  }

  return <Loader />
}

const LoadingScreen = styled(Screen)`
  justify-content: string;
  align-items: string;
  background: transparent;
`
