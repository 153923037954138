import { generatePath, Link } from 'react-router-dom'

import { Button } from 'components/generic'
import { CustomFieldsIcon } from 'components/icons'

import { SETTINGS_CUSTOM_FIELDS_PATH } from 'routes'
import analytics from 'analytics'
import { CUSTOM_FIELDS_TRACK } from 'analytics/constants'
import { spaces } from 'theme'

type Props = {
  chartUuid: string
}

export const ChartCustomFieldsButton = ({ chartUuid }: Props) => (
  <Link
    to={{
      pathname: generatePath(SETTINGS_CUSTOM_FIELDS_PATH, { chartUuid }),
      state: { from: window.location.pathname },
    }}
    onClick={() => analytics.track(CUSTOM_FIELDS_TRACK.click, { chartUuid, from: 'Top navigation' })}
  >
    <Button buttonSize='small' buttonType='warning' data-testid='button-top-navigation-chart-custom-fields'>
      <CustomFieldsIcon height='18px' $spacing={{ mr: spaces.xs }} />
      Custom fields
    </Button>
  </Link>
)
