import styled, { css } from 'styled-components'

export const ExpandButtonContent = styled.div<{ $compact: boolean }>`
  position: relative;
  min-height: 20px;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  grid-gap: 4px;
  box-sizing: border-box;
  padding: ${props =>
    props.$compact ? `${props.theme.spaces.xs} 0` : `${props.theme.spaces.xs} ${props.theme.spaces.m}`};
  border-radius: 30px;
  cursor: pointer;

  ${props =>
    !props.$compact &&
    css`
      min-width: 60px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    `}

  ${props => props.theme.animations.backgroundShapeFill}
  &::before {
    left: ${props => (props.$compact ? '-4px' : '0')};
    width: ${props => (props.$compact ? 'calc(100% + 8px)' : '100%')};
  }
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${props => (props.$compact ? props.theme.colors.transparent : props.theme.colors.white)};
    border-radius: inherit;
    z-index: -2;
  }
`
