import { SVG, SVGProps } from './SVG'

export const ChevronLeftIcon = (props: SVGProps) => (
  <SVG width='16' height='16' viewBox='0 0 16 16' fill='none' {...props}>
    <path
      d='M10.2733 4.94667L7.21998 8L10.2733 11.06L9.33332 12L5.33332 8L9.33332 4L10.2733 4.94667Z'
      fill='currentColor'
    />
  </SVG>
)
