import styled from 'styled-components'

import { TilButton, ButtonSize } from 'components/generic'
import { AuthorizeConfig } from 'tree/hooks'
import analytics from 'analytics'

import GoogleCalendarColorful from 'assets/google-calendar-colorful.png'
import { SpacingProps } from 'helpers'
import { INSIGHTS_TRACK } from 'analytics/constants'

type Props = {
  authorize: (config: AuthorizeConfig) => void
  className?: string
  onClick?: () => void
  outlineIsOnDark?: boolean
  redirectPath: string
  size?: ButtonSize
  spacing?: SpacingProps
}

export const ButtonGoogleCalendar = ({
  authorize,
  className,
  onClick,
  outlineIsOnDark,
  redirectPath,
  size,
  spacing = { m: '0 8px' },
}: Props) => (
  <TilButton
    size={size}
    type='outlined'
    spacing={spacing}
    outlineIsOnDark={outlineIsOnDark}
    className={className}
    leftEl={
      <GoogleCalendarIcon src={GoogleCalendarColorful} alt='Share Google Calendar' title='Share Google Calendar' />
    }
    onClick={() => {
      analytics.track(INSIGHTS_TRACK.addCalPerAtt, { syncCalendar: true })
      authorize({ scopes: ['calendarReadonly'], includeGrantedScopes: true, redirectPath })
      localStorage.setItem('calendarReadonly-requested', 'true')
      if (onClick) onClick()
    }}
  >
    Share Google Calendar
  </TilButton>
)

const GoogleCalendarIcon = styled.img`
  width: 16px;
`

export const InfoBoxButtonGoogleCalendar = styled(ButtonGoogleCalendar)`
  && {
    @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
      height: 44px;
      line-height: 12px;
      margin: 0 ${props => props.theme.spaces.s};
    }
  }
`
