import { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  icon: ReactNode
  text: ReactNode
}

export const IconWithTextItem = ({ icon, text }: Props) => (
  <Item>
    <Square>{icon}</Square>
    <Text>{text}</Text>
  </Item>
)

const Item = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
`

const Square = styled.div`
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: ${props => props.theme.colors.greyExtraLight};
`

const Text = styled.div`
  padding: ${props => props.theme.spaces.m} ${props => props.theme.spaces.l};
  color: ${props => props.theme.colors.dark};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
`
