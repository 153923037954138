import { useEffect, useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import { useApolloClient } from '@apollo/client'
import { useDebounce } from 'use-debounce'

import { SearchFieldResults } from './search-field-results'
import { inputPlaceholderCSS } from '../input/input-CSS'
import { InitSearchFieldValues, SetDepartmentSelectValues, HandleParentChange } from '../../specific/superior-field'

import { SearchIcon } from 'components/icons'
import { MdClose } from 'react-icons/md'

import { searchNodes as searchNodesQuery } from 'apollo/query'
import { NodeSearchDataFragmentFragment, SearchNodesQuery, SearchNodesQueryVariables } from 'apollo/generated/graphql'

const initSearchResults: NodeSearchDataFragmentFragment[] = []

export type SearchFieldMode = 'independent' | 'superiorField' | 'topNavigation'

export type SelectedDepartmentColor = null | string

type Props = {
  chartUuid: string
  mode: SearchFieldMode
  className?: string
  initValues?: InitSearchFieldValues
  nodeType?: 'Person' | 'Department'
  nodeUuid?: string
  parentUuid?: string | null
  personUuid?: string | null
  wasUnassigned?: boolean
  handleSetPersonUuid?: (uuid: string) => void
  handleSetDepartmentSelectValues?: SetDepartmentSelectValues
  onParentChange?: HandleParentChange
}

export const SearchField = ({
  chartUuid,
  mode,
  className,
  initValues,
  nodeType,
  nodeUuid,
  parentUuid,
  personUuid,
  wasUnassigned,
  handleSetDepartmentSelectValues,
  handleSetPersonUuid,
  onParentChange,
  ...rest
}: Props) => {
  const client = useApolloClient()
  const searchInputRef = useRef<any>(null)

  const [searchValue, setSearchValue] = useState(initValues?.name || '')
  useEffect(() => setSearchValue(initValues?.name || ''), [initValues?.name])
  const [searchUuidValue, setSearchUuidValue] = useState(initValues?.uuid || '')
  useEffect(() => setSearchUuidValue(initValues?.uuid || ''), [initValues?.uuid])
  const [debouncedSearchValue] = useDebounce(searchValue, 500)
  const [shouldDoSearch, setShouldDoSearch] = useState(mode !== 'superiorField')
  const [isSearching, setIsSearching] = useState(true)
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [searchResults, setSearchResults] = useState(initSearchResults)
  const [selectedDepartmentColor, setSelectedDepartmentColor] = useState<SelectedDepartmentColor>(
    initValues?.departmentColor || null
  )
  useEffect(() => setSelectedDepartmentColor(initValues?.departmentColor || null), [initValues?.departmentColor])

  const searchValueHasAtLeastTwoSymbols = debouncedSearchValue.length > 1

  // Fix for superiorField to do first search/request only on input focus
  const didInitSearch = useRef(false)
  const shouldDoInitSearchForSuperiorField =
    mode === 'superiorField' && searchValueHasAtLeastTwoSymbols && !didInitSearch.current

  const search = () => {
    if (!isSearching) setIsSearching(true)
    const unassignedObj = mode === 'superiorField' ? { unassigned: false } : {}
    const excludeUuidObj = mode === 'superiorField' && nodeUuid ? { excludeUuid: nodeUuid } : {}

    client
      .query<SearchNodesQuery, SearchNodesQueryVariables>({
        query: searchNodesQuery,
        fetchPolicy: 'no-cache',
        variables: {
          chartKey: chartUuid,
          filter: {
            q: searchValue,
            ...unassignedObj,
            ...excludeUuidObj,
          },
        },
      })
      .then(({ data }) => {
        if (data?.searchNodes?.items) {
          setSearchResults([...data.searchNodes.items])
          setIsSearching(false)
        }
      })
  }

  // Search
  useEffect(() => {
    shouldDoSearch && searchValueHasAtLeastTwoSymbols ? search() : setShouldDoSearch(true)
  }, [debouncedSearchValue])

  useEffect(() => {
    if (shouldDoSearch && !isSearching && setSearchValue.length < 2) {
      setIsSearching(true)
    }

    // Below affects superiorField only
    // Remove department color if input value is changed by keystroke
    if (mode === 'superiorField' && selectedDepartmentColor && isInputFocused) {
      setSelectedDepartmentColor(null)
    }
    // Reset active selected item if input value is changed by keystroke
    if (mode === 'superiorField' && searchUuidValue && isInputFocused) {
      setSearchUuidValue('')
    }
    // FIXME: may be doing the same as condition above (merge them)
    if (mode === 'superiorField' && handleSetPersonUuid && personUuid && isInputFocused) {
      setSearchUuidValue('')
      handleSetPersonUuid('')
    }
    if (
      // If parentUuid isn't set in SuperiorField -> will throw error (no employee or department selected)
      mode === 'superiorField' &&
      onParentChange &&
      parentUuid !== '' &&
      searchValue !== '' &&
      isInputFocused
    ) {
      onParentChange('')
    } else if (
      // If SearchField is emptied by keystroke
      mode === 'superiorField' &&
      onParentChange &&
      searchValue === '' &&
      isInputFocused
    ) {
      onParentChange(null)
    }
  }, [searchValue])

  // Sync state with HTML focus/blur
  useEffect(() => {
    const el = searchInputRef.current
    if (!el) return

    isInputFocused ? el.focus() : el.blur()
  }, [isInputFocused])

  const clearComponent = () => {
    setSearchValue('')
    setSearchUuidValue('')
    setShouldDoSearch(true)
    setIsSearching(true)
    setIsInputFocused(false)
    setSearchResults([])
    setSelectedDepartmentColor(null)
    handleSetPersonUuid && handleSetPersonUuid('')
    onParentChange && onParentChange(null)
  }
  const resetComponent = () => {
    setSearchValue('')
    setIsSearching(true)
    setSearchResults(initSearchResults)
  }
  const resetComponentWithBlur = () => {
    resetComponent()
    searchInputRef.current.blur()
  }

  return (
    <SearchWrap className={className} mode={mode} searchValue={searchValue} wasUnassigned={wasUnassigned}>
      <SearchIconWrap mode={mode}>
        <StyledSearchIcon />
        {selectedDepartmentColor && (
          <SelectedResultType selectedDepartmentColor={selectedDepartmentColor}></SelectedResultType>
        )}
      </SearchIconWrap>

      <SearchInput
        mode={mode}
        name={`search_${mode}`}
        placeholder='Search employee or department'
        value={searchValue}
        selectedDepartmentColor={selectedDepartmentColor}
        onFocus={() => {
          setIsInputFocused(true)
          if (shouldDoInitSearchForSuperiorField) {
            didInitSearch.current = true
            search()
          }
        }}
        onChange={({ target }) => setSearchValue(target.value)}
        onBlur={() => {
          if (mode === 'superiorField') {
            setIsInputFocused(false)
          } else {
            searchValue && resetComponentWithBlur()
          }
        }}
        autoComplete='off'
        ref={searchInputRef}
        {...rest}
      />
      {searchValue.length > 0 && (
        <ClearSearchInput
          tabIndex={0}
          mode={mode}
          isAloneInRow={!personUuid}
          onMouseDown={e => e.preventDefault()}
          onClick={() => {
            if (mode === 'superiorField') {
              clearComponent()
            } else {
              resetComponent()
            }
          }}
        >
          <StyledClearIcon mode={mode} />
        </ClearSearchInput>
      )}

      {mode === 'superiorField' && searchValue === '' && (
        <BlankDescription>
          Complete this field to assign{' '}
          {nodeType === 'Person' ? 'employee' : nodeType === 'Department' ? 'department' : 'node'} to an existing
          supervisor/department
        </BlankDescription>
      )}

      {isInputFocused && searchValue.length > 0 && (
        <SearchFieldResults
          mode={mode}
          handleSetIsInputFocused={setIsInputFocused}
          searchValue={searchValue}
          handleSetSearchValue={setSearchValue}
          isSearching={isSearching}
          handleSetShouldDoSearch={setShouldDoSearch}
          searchResults={searchResults}
          searchUuidValue={searchUuidValue}
          handleSetSearchUuidValue={setSearchUuidValue}
          handleSetPersonUuid={uuid => {
            searchInputRef.current.blur()
            handleSetPersonUuid && handleSetPersonUuid(uuid)
          }}
          handleSetSelectedDepartmentColor={setSelectedDepartmentColor}
          handleResetComponentWithBlur={resetComponentWithBlur}
          handleSetDepartmentSelectValues={handleSetDepartmentSelectValues}
          onParentChange={onParentChange}
        />
      )}
    </SearchWrap>
  )
}

const SearchWrapSuperiorField = css`
  flex-direction: column;
  flex-wrap: wrap;
`

const SearchWrap = styled.div<{
  mode: SearchFieldMode
  searchValue: string
  wasUnassigned?: boolean
}>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  outline: none;

  ${props => (props.mode === 'superiorField' ? SearchWrapSuperiorField : props.theme.animations.backgroundShapeFill)}

  ${props =>
    props.mode === 'superiorField' &&
    props.searchValue &&
    props?.wasUnassigned &&
    css`
      margin-bottom: 22px;
    `}
`

const SearchIconWrapSuperiorField = css`
  height: 40px;
`
const SearchIconWrap = styled.div<{ mode: SearchFieldMode }>`
  position: absolute;
  top: 0;
  left: ${props => props.theme.spaces.m};
  height: 100%;
  display: flex;
  align-items: center;
  pointer-events: none;

  ${props => props.mode === 'superiorField' && SearchIconWrapSuperiorField}
`

const StyledSearchIcon = styled(SearchIcon)`
  width: 14px;
  height: auto;
  color: ${props => props.theme.colors.dark};
`

const SearchInput = styled.input<{
  mode: SearchFieldMode
  selectedDepartmentColor: SelectedDepartmentColor
}>`
  min-width: inherit;
  width: 100%;
  height: 100%;
  min-height: inherit;
  box-sizing: border-box;
  padding-top: 0;
  padding-right: 20px;
  padding-bottom: 0;
  padding-left: ${props => (props.selectedDepartmentColor ? '40px' : '30px')};
  border-radius: inherit;
  color: ${props => props.theme.colors.dark};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  background: transparent;
  outline: none;
  border: none;
  transition: ${props => props.theme.transitions.extraFastEase};

  &:focus {
    background: ${props => props.theme.colors.greyActive};
  }

  &::placeholder,
  &::-webkit-input-placeholder {
    ${inputPlaceholderCSS}
    /* Fixes vertical centering on Safari (15.1) */
    ${props =>
      props.mode === 'superiorField' &&
      css`
        height: unset;
      `}
  }

  ${props =>
    props.mode === 'superiorField' &&
    css`
      height: 40px;
      background: ${props => props.theme.colors.neutralSecondary};
      font-weight: normal;
    `}
`

const ClearSearchInput = styled.div<{
  mode: SearchFieldMode
  isAloneInRow: boolean
}>`
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  cursor: pointer;
  user-select: none;
  transition: all ${props => props.theme.transitions.fastEase};

  :hover {
    background: rgba(0, 0, 0, 0.1);
  }

  left: ${props => props.isAloneInRow && props.mode === 'superiorField' && 'calc(100% + 13px)'};
  left: ${props => !props.isAloneInRow && props.mode === 'superiorField' && 'calc(200% + 20px)'};
  ${props =>
    props.mode === 'superiorField' &&
    css`
      top: 11px;
      min-width: 18px;
      width: 18px;
      min-height: 18px;
      height: 18px;
    `}
`

const StyledClearIcon = styled(MdClose)<{ mode: SearchFieldMode }>`
  width: ${props => props.mode === 'superiorField' && '100%'};
  height: ${props => props.mode === 'superiorField' && '100%'};
  color: ${props => (props.mode === 'superiorField' ? props.theme.colors.greyLight : props.theme.colors.dark)};
  pointer-events: none;
`

const BlankDescription = styled.div`
  margin-top: 6px;
  margin-right: 100px;
  color: ${props => props.theme.colors.greyLight};
  font-size: 11px;
  line-height: 16px;
  cursor: default;
`

const SelectedResultType = styled.div<{
  selectedDepartmentColor: SelectedDepartmentColor
}>`
  min-width: 6px;
  min-height: 6px;
  width: 6px;
  height: 6px;
  margin-top: 2px;
  margin-left: 8px;
  border-radius: 50%;
  background: ${props => props.selectedDepartmentColor};
  animation: ${props => props.theme.keyframes.opacity} ${props => props.theme.transitions.fastEase};
`
