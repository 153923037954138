import { gql } from '@apollo/client'
import { userFragment } from 'apollo/fragments/user-fragment'

export const user = gql`
  query user {
    user {
      ...User
    }
  }
  ${userFragment}
`
