import { useState, useCallback, ChangeEvent, useEffect } from 'react'
import AvatarEditor from 'react-avatar-editor'
import styled, { css } from 'styled-components'

import { RangeInputWithFill } from './range-input-with-fill'
import { Button, Input } from 'components/generic'
import { IconButton } from 'components/generic/icon-button'

import { AddPhotoIcon, RotateIcon, CloseIcon } from 'components/icons'

import { handleErrorValidation } from 'helpers'
import { ORG_TRACK } from 'analytics/constants'

import {
  useGenerateAvatarUploadUrlPolicyMutation,
  useGenerateLogoUploadUrlPolicyMutation,
} from 'apollo/generated/graphql'

type AssetUploadProps = {
  chartUuid: string
  initialImgSrc: string | null | undefined
  disabled: boolean
  withEditor?: boolean
  assetType: 'avatar' | 'logo'
  setFieldValue: (value: string) => void
}

export const AssetUpload = ({
  chartUuid,
  assetType,
  initialImgSrc,
  withEditor,
  disabled,
  setFieldValue,
}: AssetUploadProps) => {
  const initScale = assetType === 'avatar' ? 2.5 : 0
  const rounded = assetType !== 'logo'

  const [uploadedImage, setUploadedImage] = useState<File | null>(null)
  const [savedImage, setSavedImage] = useState(initialImgSrc)
  const isImageSet = Boolean(savedImage)
  const [scale, setScale] = useState(initScale)
  const [rotate, setRotate] = useState(0)
  const [isSaving, setIsSaving] = useState(false)
  const [generateAvatarUploadUrlPolicyMutation] = useGenerateAvatarUploadUrlPolicyMutation()
  const [generateLogoUploadUrlPolicyMutation] = useGenerateLogoUploadUrlPolicyMutation()

  let editor: any
  const setEditorRef = (propEditor: any) => (editor = propEditor)

  const handleNewImage = useCallback((e?: ChangeEvent<HTMLInputElement>) => {
    const target = e?.target as HTMLInputElement
    const file: File = (target.files as FileList)[0]
    setUploadedImage(file)
  }, [])

  const handleScale = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    const scale = parseFloat(target.value)
    setScale(scale)
  }

  const displayEditedImage = (blob: Blob) => {
    const urlCreator = window.URL || window.webkitURL
    const blobUrl = urlCreator.createObjectURL(blob)
    setSavedImage(blobUrl)
  }

  const handleSave = () => {
    if (uploadedImage) {
      setIsSaving(true)
      editor.getImageScaledToCanvas().toBlob((blob: Blob) => uploadAsset(blob), 'image/jpeg', 0.95)
    } else {
      resetEditorSettings()
      setSavedImage('')
      setIsSaving(false)
    }
  }

  const onUploadFail = (
    error: Error,
    message = 'Failed to upload image. You may not be connected to the internet or Avatar upload service is not working right now.'
  ) => {
    handleErrorValidation({
      track: { message: ORG_TRACK.avatarUploadImageFailure, values: { chartUuid, error } },
      toast: { message },
    })
    resetEditorSettings()
    setSavedImage('')
    setIsSaving(false)
  }

  const uploadAsset = useCallback(
    (blob: Blob) => {
      const contentType = blob.type
      const params = { variables: { chartUuid, contentType } }
      const uploadMutationPromise =
        assetType === 'logo'
          ? generateLogoUploadUrlPolicyMutation(params).then(({ data }) => data?.generateLogoUploadUrlPolicy)
          : generateAvatarUploadUrlPolicyMutation(params).then(({ data }) => data?.generateAvatarUploadUrlPolicy)

      uploadMutationPromise
        .then(data => {
          if (!data) {
            return Promise.reject('no data')
          }
          const { url, fields, getUrl } = data

          const formData = new FormData()
          for (const { key, value } of fields) {
            formData.append(key, value)
          }
          formData.append('Content-Type', contentType)
          formData.append('file', blob)

          const xhr = new XMLHttpRequest()
          xhr.open('POST', url, true)
          xhr.send(formData)

          xhr.onload = () => {
            const status = xhr.status
            if (status === 204) {
              displayEditedImage(blob)
              resetEditorSettings()
              setFieldValue(getUrl)
              setIsSaving(false)
            } else if (status === 400) {
              onUploadFail(new Error('size exceeded'), 'File size exceeded.')
            } else {
              onUploadFail(new Error(`xhr status ${status}`))
            }
          }
          xhr.onerror = () => onUploadFail(new Error('xhr error'))
        })
        .catch(error => onUploadFail(error))
    },
    [generateLogoUploadUrlPolicyMutation, generateAvatarUploadUrlPolicyMutation, setIsSaving]
  )

  const resetEditorSettings = () => {
    setUploadedImage(null)
    setScale(initScale)
    setRotate(0)
  }

  useEffect(() => {
    if (!withEditor && uploadedImage) {
      uploadAsset(uploadedImage)
    }
  }, [uploadedImage, withEditor])

  return (
    <Wrap>
      {!uploadedImage && (
        <>
          <AssetLabel disabled={disabled} rounded={rounded} isImageSet={isImageSet}>
            {!disabled && (
              <Input name='image' type='file' value='' accept='image/png,image/jpeg' hidden onChange={handleNewImage} />
            )}
            {savedImage && (
              <AssetContainer>
                <AssetImg src={savedImage} />
              </AssetContainer>
            )}
            <AddPhotoWrap disabled={disabled} rounded={rounded} isImageSet={isImageSet}>
              <StyledAddPhotoIcon />
            </AddPhotoWrap>
          </AssetLabel>

          {Boolean(savedImage) && !disabled && (
            <ClearButton
              onClick={() => {
                resetEditorSettings()
                setFieldValue && setFieldValue('')
                setSavedImage('')
              }}
            >
              <ClearIconStyled />
            </ClearButton>
          )}
        </>
      )}

      {uploadedImage && withEditor && (
        <EditorContainer>
          <Row>
            <AddPhotoWrapEditor>
              <label>
                <Input name='image' type='file' value='' hidden onChange={handleNewImage} />
                <IconButton size='small'>
                  <StyledAddPhotoIconBlack />
                </IconButton>
              </label>
            </AddPhotoWrapEditor>
            <StyledAvatarEditor
              image={uploadedImage}
              width={rounded ? 160 : undefined}
              height={160}
              border={0}
              scale={parseFloat(scale.toString())}
              color={[255, 255, 255, 0.75]}
              rotate={rotate}
              borderRadius={rounded ? 125 : undefined}
              ref={setEditorRef}
            />
            <RotateWrap>
              <label>
                <IconButton
                  size='small'
                  onClick={e => {
                    e.preventDefault()
                    setRotate(rotate + 90)
                  }}
                >
                  <StyledRotateIcon />
                </IconButton>
                <IconButtonLeft
                  size='small'
                  onClick={e => {
                    e.preventDefault()
                    setRotate(rotate - 90)
                  }}
                >
                  <StyledRotateIcon />
                </IconButtonLeft>
              </label>
            </RotateWrap>
          </Row>

          <RangeInputWithFill name='scale' onChange={handleScale} min={1} max={4} step={0.01} defaultValue={scale} />

          <ButtonsWrap>
            <Button buttonType='tertiary' onClick={() => resetEditorSettings()}>
              Cancel
            </Button>
            <Button disabled={isSaving} onClick={handleSave}>
              Crop image
            </Button>
          </ButtonsWrap>
        </EditorContainer>
      )}
    </Wrap>
  )
}

const Wrap = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  user-select: none;
`

const AssetLabel = styled.label<{ disabled: boolean; rounded: boolean; isImageSet: boolean }>`
  position: relative;
  ${props =>
    !props.isImageSet &&
    css`
      min-width: 48px;
      min-height: 48px;
    `}
  width: ${props => (props.rounded ? '48px' : 'auto')};
  max-width: ${props => (props.rounded ? '48px' : '200px')};
  height: ${props => (props.rounded ? '48px' : 'auto')};
  max-height: ${props => (props.rounded ? '48px' : '80px')};
  ${props => props.rounded && `background: ${props.theme.colors.neutralSecondary};`}
  border-radius: ${props => (props.rounded ? '50%' : '0')};
  overflow: hidden;
  cursor: pointer;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`

const AssetContainer = styled.div`
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`

const AssetImg = styled.img`
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: inherit;
`

const AddPhotoWrap = styled.div<{
  isImageSet: boolean
  disabled: boolean
  rounded: boolean
}>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.white};
  border-radius: ${props => (props.rounded ? '50%' : '0')};
  overflow: hidden;
  transform: translate(-50%, -50%);
  opacity: ${props => (props.isImageSet ? '0' : props.disabled ? '0.2' : '1')};
  transition: ${props => props.theme.transitions.extraFastEase};

  ${AssetLabel}:hover & {
    ${props => !props.disabled && 'opacity: 0.8;'}
  }
`

const StyledAddPhotoIcon = styled(AddPhotoIcon)`
  width: 18px;
  height: auto;
  margin-top: -1px;
  color: ${props => props.theme.colors.dark};
`

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  margin-bottom: 15px;
`

const AddPhotoWrapEditor = styled.div`
  display: flex;
  align-items: center;
`

const ClearButton = styled.div`
  min-width: 18px;
  width: 18px;
  min-height: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 13px;
  margin-right: 6px;
  user-select: none;
  cursor: pointer;
  transition: ${props => props.theme.transitions.extraFastEase};
  opacity: 0;
  z-index: 2;

  ${props => props.theme.animations.backgroundShapeFill};

  ${Wrap}:hover & {
    opacity: 1;
  }
`

const ClearIconStyled = styled(CloseIcon)`
  width: 10.5px;
  color: ${props => props.theme.colors.greyLight};
`

const StyledAddPhotoIconBlack = styled(StyledAddPhotoIcon)`
  color: ${props => props.theme.colors.black};
`
const StyledRotateIcon = styled(RotateIcon)`
  width: 12px;
  height: auto;
  color: ${props => props.theme.colors.black};
`

const StyledAvatarEditor = styled(AvatarEditor)`
  margin-left: 10px;
  margin-right: 10px;
`

const RotateWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const IconButtonLeft = styled(IconButton)`
  transform: scaleX(-1);
  margin-top: 5px;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
`
