import { CollaboratorRole } from 'apollo/generated/graphql'

type UserRoles = { userRole: CollaboratorRole; loggedUserChartRole: CollaboratorRole }
type Options = Record<CollaboratorRole, boolean>

const rolesPriorityRank: { [key in CollaboratorRole]: number } = {
  admin: 300,
  editor: 200,
  viewer: 100,
}
const initDisplayOptions: Options = {
  admin: false,
  editor: false,
  viewer: false,
}

const getPriority = (role: CollaboratorRole) => rolesPriorityRank[role] || 0
const loggedUserHasLowerRank = ({ userRole, loggedUserChartRole }: UserRoles) => {
  return getPriority(loggedUserChartRole) < getPriority(userRole)
}
export const getDisplayOptions = ({ userRole, loggedUserChartRole }: UserRoles): Options => {
  if (loggedUserHasLowerRank({ userRole, loggedUserChartRole })) return initDisplayOptions

  if (loggedUserChartRole === 'viewer') return initDisplayOptions
  return {
    viewer: true,
    editor: true,
    admin: loggedUserChartRole === 'admin',
  }
}

export const getAllowedRoles = ({ userRole, loggedUserChartRole }: UserRoles): CollaboratorRole[] => {
  const options = getDisplayOptions({ userRole, loggedUserChartRole })
  return Object.keys(options).flatMap(key => {
    const role = key as CollaboratorRole
    if (options[role] !== true) {
      return []
    }

    return [role]
  })
}
