import { SVG, SVGProps } from './SVG'

export const ArrowIcon = (props: SVGProps) => (
  <SVG width='10' height='10' viewBox='0 0 10 10' fill='none' {...props}>
    <path
      d='M9.66667 4.41667V5.58334H2.66667L5.875 8.79167L5.04667 9.62L0.426666 5L5.04667 0.380005L5.875 1.20834L2.66667 4.41667H9.66667Z'
      fill='currentColor'
    />
  </SVG>
)
