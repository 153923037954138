import { FocusEvent, ChangeEvent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { Input } from './input/input'
import { RadioButton } from './radio-button'

import { CheckmarkIcon } from 'components/icons'

import { applySpacingProps, SpacingProps } from 'helpers'

export type SelectButtonProps = {
  className?: string
  type: SelectButtonType
  name?: string
  checked?: boolean
  disabled?: boolean
  value?: string
  spacing?: SpacingProps
  children?: ReactNode
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
}

type SelectButtonType = 'checkbox' | 'radio'

export const SelectButton = ({
  className,
  type,
  name,
  checked,
  disabled,
  value = '',
  spacing,
  children,
  onFocus,
  onChange,
  onBlur,
}: SelectButtonProps) => (
  <SelectButtonWrap className={className} disabled={disabled} $spacing={spacing}>
    <Input
      type={type}
      name={name}
      checked={checked}
      value={value}
      tabIndex={-1}
      hidden
      onFocus={onFocus}
      onChange={onChange}
      onBlur={onBlur}
    />
    {type === 'radio' && <RadioButton isChecked={checked} disabled={disabled} />}
    {type === 'checkbox' && (
      <Checkbox isChecked={checked} disabled={disabled}>
        <IconWrap isChecked={checked}>
          <StyledCheckmarkIcon />
        </IconWrap>
      </Checkbox>
    )}
    {children}
  </SelectButtonWrap>
)

const SelectButtonWrap = styled.label<{
  disabled?: boolean
  $spacing?: SpacingProps
}>`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  pointer-events: auto;

  ${props => applySpacingProps(props.$spacing)}
`

const CheckboxChecked = css<{ disabled?: boolean }>`
  position: relative;
  border-radius: 2px;
  border: 1px solid ${props => (props.disabled ? props.theme.colors.purpleVeryLight : props.theme.colors.purple)};
  background: ${props => (props.disabled ? props.theme.colors.purpleVeryLight : props.theme.colors.purple)};

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }
`

const Checkbox = styled.div<{
  isChecked?: boolean
  disabled?: boolean
}>`
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
  box-sizing: border-box;
  border: 1px solid ${props => (props.disabled ? props.theme.colors.greyExtraLight : props.theme.colors.border)};
  background: ${props => props.theme.colors.white};

  ${props => props.isChecked && CheckboxChecked}
`

const IconWrap = styled.div<{
  isChecked?: boolean
}>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${props => (props.isChecked ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
`

const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  width: 8px;
  height: auto;
  color: ${props => props.theme.colors.white};
`
