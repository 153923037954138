import { getDateFromString } from './get-date-from-string'
import { isValidDate } from './is-valid-date'

export const getDate = (date?: Date | string | number | null): Date | null => {
  try {
    // Don't check for `!date` as `0` is valid date but falsy
    if (typeof date === 'undefined' || date === null || Number.isNaN(date)) {
      return null
    }

    if (typeof date === 'string') {
      const newDate = getDateFromString(date)
      return isValidDate(newDate) ? newDate : null
    }

    if (typeof date === 'number') {
      const newDate = new Date(date)
      return isValidDate(newDate) ? newDate : null
    }

    return date
  } catch (_) {
    return null
  }
}
