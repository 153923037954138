import { useCallback, useMemo } from 'react'

import { useChartData } from '../providers/use-chart-data'
import { getCodeChallenge } from 'helpers'

import { useAuthorizeMutation, OAuth2Scope } from 'apollo/generated/graphql'

export type AuthorizeConfig = {
  scopes: OAuth2Scope[]
  includeGrantedScopes?: boolean
  redirectPath: string
}

export const useGoogleWorkspace = () => {
  const [authorizeMutation] = useAuthorizeMutation()
  const chartData = useChartData(true)
  const { uuid = '', providerInfo } = chartData ?? {}
  const chartUuid = useMemo<string>(() => uuid, [uuid])
  const chartScopes = useMemo<readonly OAuth2Scope[]>(() => providerInfo?.scopes ?? [], [providerInfo?.scopes])

  const authorize = useCallback(
    (config: AuthorizeConfig) => {
      const { scopes, redirectPath, includeGrantedScopes = false } = config

      const requestScopes = [...scopes]
      if (includeGrantedScopes && chartUuid) {
        if (chartScopes) {
          requestScopes.push(...chartScopes)
        } else {
          throw new Error('No Org Chart scopes found')
        }
      }

      return authorizeMutation({
        variables: {
          codeChallenge: getCodeChallenge(),
          authorizeInput: { scopes: requestScopes, redirectPath, version: 2 },
        },
      }).then(data => {
        const url = data.data?.authorize?.url
        if (url) window.location.href = url
        return data
      })
    },
    [authorizeMutation, getCodeChallenge, chartScopes, chartUuid]
  )

  const hasScope = useCallback((scope: OAuth2Scope) => chartScopes?.includes(scope), [chartScopes])

  return {
    authorize,
    hasScope,
  }
}
