import { css } from 'styled-components'
import { mainTheme } from 'theme'

import { InputProps } from './input'

export const inputPlaceholderCSS = css<Pick<InputProps, 'disabled'>>`
  font-family: Roboto;
  height: inherit;
  color: ${props => (props.disabled ? mainTheme.colors.border : mainTheme.colors.grey)};
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
  font-style: normal;
  opacity: 0.6;
`

export const inputFieldCSS = css<Pick<InputProps, 'disabled'>>`
  width: 100%;
  min-height: 40px;
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.m} 12px;
  outline: none;
  border: none;
  color: ${props => (props.disabled ? mainTheme.colors.border : mainTheme.colors.grey)};
  font-size: 13px;
  line-height: initial;
  background: ${props => (props.disabled ? mainTheme.colors.greyUltraLight : mainTheme.colors.neutralSecondary)};
  transition: ${mainTheme.transitions.extraFastEase};

  &:focus {
    background: ${mainTheme.colors.greyActive};
  }

  &::placeholder,
  &::-webkit-input-placeholder {
    ${inputPlaceholderCSS}
  }
`

export const inputFieldHiddenCSS = css`
  /* Hide checkbox visually but remain accessible to screen readers. */
  /* Source: https://polished.js.org/docs/#hidevisually */
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`
