import { DirectoryButton } from './components/directory-button'
import { DirectorySidebar } from './components/directory-sidebar'
import { UnassingNodesModal, UnassingNodesModalType } from 'components/specific/modal/unassign-nodes-modal'

import { useUnassignNodeAction } from 'tree/actions'
import { useUnassignedNodes, useDirectory } from 'tree/hooks'
import { useChartData } from 'tree/providers'
import { useModal, useRegister } from 'modal/hooks'

import { useShowUnassignedNodesSidebarQueryQuery } from 'apollo/generated/graphql'

export const Directory = () => {
  const { uuid: chartUuid, unassignedEmployeeCount, unassignedDepartmentCount } = useChartData()

  const { data } = useShowUnassignedNodesSidebarQueryQuery()
  const expanded = Boolean(data?.showUnassignedNodesSidebar)
  const { openDirectory, closeDirectory } = useDirectory()

  const { open } = useModal()
  useRegister<UnassingNodesModalType>('unassignNodesModal', UnassingNodesModal)

  const { unassignNodeAction } = useUnassignNodeAction()
  const { nodes, loading, canDrop, isOver, dropRef } = useUnassignedNodes({
    onDrop: node => {
      openDirectory()
      const { uuid, parentUuid, employeeCount, departmentCount, __typename, name, parentNodes } = node
      const nodeChildrenCount = employeeCount + departmentCount
      const unassignParams = { nodeChildrenCount, chartUuid, uuid, parentUuid, __typename, name, parentNodes }
      if (nodeChildrenCount > 0) {
        open<UnassingNodesModalType>('unassignNodesModal', {
          node,
          onConfirm: () => unassignNodeAction(unassignParams),
        })
      } else {
        unassignNodeAction(unassignParams)
      }
    },
  })

  if (!expanded) {
    return (
      <DirectoryButton
        canDrop={canDrop}
        isOver={isOver}
        employeeCount={unassignedEmployeeCount}
        departmentCount={unassignedDepartmentCount}
        onOpen={openDirectory}
        dropRef={dropRef}
      />
    )
  }

  return (
    <DirectorySidebar
      nodes={nodes}
      loading={loading}
      canDrop={canDrop}
      isOver={isOver}
      dropRef={dropRef}
      onClose={closeDirectory}
    />
  )
}
