import { Centering, Title } from '../../components/styled'
import { WomanSittingOnMoonShapeOfVoidIcon } from 'components/icons'

export const NoResults = () => (
  <Centering>
    <WomanSittingOnMoonShapeOfVoidIcon />
    <Title>No results found!</Title>
    Try to adjust search.
  </Centering>
)
