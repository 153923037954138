import { payloadMapperFactory } from 'helpers'

let tries = 0
const MAX_TRIES = 50
const MAX_INTERCOM_RETRIES = 6
const windowObj: any = window

export const CUSTOM_INTERCOM_LAUNCHER_ID = 'custom_intercom_launcher'
export const onIntercomInitializedEvent = new Event('onIntercomInitialized')

const payloadMapper = payloadMapperFactory({ maxStrLength: 255, maxStrGroupCount: 5 })

export const getIsCustomInitialized = () => Boolean(windowObj?.Intercom?.isCustomInitialized)
const setIsCustomInitialized = () => {
  if (windowObj?.Intercom) windowObj.Intercom.isCustomInitialized = true
  window.dispatchEvent(onIntercomInitializedEvent)
}
// Checks whether the Intercom object is present and loaded but has nothing to do if it's initialized and tracking
const isBooted = () => Boolean(windowObj?.Intercom?.booted)

type IntercomBootSettings = {
  // Messenger Attributes
  app_id: string
  custom_launcher_selector?: string
  hide_default_launcher?: boolean
  horizontal_padding?: number
  vertical_padding?: number
  // Data Attributes
  name?: string
  email?: string
  created_at?: number
}

export const init = (appId: string, name?: string, email?: string, createdAt?: Date) => {
  if (!isBooted()) {
    // Try to boot intercom for MAX_TRIES
    if (tries < MAX_TRIES) {
      setTimeout(() => {
        tries++
        init(appId, name, email, createdAt)
      }, 100)
    }
    return
  }

  const settings: IntercomBootSettings = {
    app_id: appId,
    custom_launcher_selector: `#${CUSTOM_INTERCOM_LAUNCHER_ID}`,
    hide_default_launcher: true,
    horizontal_padding: 20,
    vertical_padding: 100,
  }

  if (name) settings.name = name
  if (email) settings.email = email
  if (createdAt instanceof Date) {
    settings.created_at = createdAt.getTime() / 1000
  }

  windowObj.Intercom('boot', settings)
  setIsCustomInitialized()
}

export const track = (name: string, properties?: Record<string, unknown>) => {
  if (isBooted()) {
    const payload = payloadMapper(properties)
    windowObj.Intercom('trackEvent', name, payload)
  }
}

let timeoutID: number | undefined
type UpdateLoop = {
  userData?: Record<string, any>
  retries?: number
  timeout?: number
}
const updateLoop = ({ userData, retries = MAX_INTERCOM_RETRIES, timeout = 1000 }: UpdateLoop) => {
  let timeoutInternal = timeout
  if (timeoutID) {
    clearTimeout(timeoutID)
  }
  if (MAX_INTERCOM_RETRIES - retries >= 3) {
    timeoutInternal = timeoutInternal * 3
  }
  if (retries > 0) {
    timeoutID = Number(
      setTimeout(() => {
        timeoutID = undefined
        windowObj.Intercom('update', userData)
        updateLoop({ userData, retries: retries - 1, timeout: timeoutInternal })
      }, timeoutInternal)
    )
  }
}

type Update = {
  userData?: Record<string, any>
  retry?: boolean
}
export const update = ({ userData, retry }: Update) => {
  if (isBooted()) {
    if (retry) {
      updateLoop({ userData })
    } else {
      windowObj.Intercom('update', userData)
    }
  }
}

export const setUserProperties = (properties: Record<string, any>) => {
  if (isBooted()) {
    windowObj.Intercom('update', properties)
  }
}

export const showNewMessage = (prePopulatedMessage?: string) => {
  if (isBooted()) {
    windowObj.Intercom('showNewMessage', prePopulatedMessage)
  }
}

export const hide = () => {
  if (isBooted()) {
    windowObj.Intercom('hide')
  }
}

export default {
  init,
  track,
  hide,
  update,
  setUserProperties,
  showNewMessage,
}
