import styled from 'styled-components'

import { SortAscendingIcon, SortDescendingIcon } from 'components/icons'

export const TableDescIcon = styled(SortDescendingIcon)`
  padding: 5px;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    padding: 2px;
  }
`

export const TableAscIcon = styled(SortAscendingIcon)`
  padding: 5px;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    padding: 2px;
  }
`

export const TableBody = styled.div`
  overflow-y: auto;
  padding: 0 25px;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    padding: 0 ${props => props.theme.spaces.m};
  }
`

export const NotAvailable = styled.div`
  color: #83869c;
  padding-right: 12px;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    padding-right: ${props => props.theme.spaces.l};
  }
`
