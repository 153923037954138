import { useEffect, useState } from 'react'
import rafSchd from 'raf-schd'

type WindowProp = {
  width?: number
  height?: number
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowProp>({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const resize = () => setWindowSize({ width: window.innerWidth, height: window.innerHeight })
    const scheduleResize = rafSchd(resize)

    window.addEventListener('resize', scheduleResize)
    return () => window.removeEventListener('resize', scheduleResize)
  }, [])

  return windowSize
}
