import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Tooltip, IconButton } from 'components/generic'
import { HomeIcon } from 'components/icons'

import { CHARTS_PATH } from 'routes'

export const Home = () => {
  const [isTooltipShown, setIsTooltipShown] = useState(false)
  const history = useHistory()

  return (
    <IconButton
      size='big'
      onMouseEnter={() => setIsTooltipShown(true)}
      onMouseLeave={() => setIsTooltipShown(false)}
      onClick={() => history.push(CHARTS_PATH)}
      data-testid='button-top-navigation-overview'
    >
      <HomeIcon />
      <Tooltip isTooltipShown={isTooltipShown}>Overview</Tooltip>
    </IconButton>
  )
}
