import { isValidDate } from './is-valid-date'

export const getDateFromString = (date: string): Date | null => {
  try {
    const newDate = new Date(date)
    return isValidDate(newDate) ? newDate : null
  } catch (_) {
    return null
  }
}
