import { useState, useMemo, FocusEvent } from 'react'
import Select, { StylesConfig } from 'react-select'

import { selectComponents, selectMenuBottomMargin, selectStyles } from 'components/generic'

import { CustomSingleDropdownFieldValue, CustomSelectOption, SelectedDropdownOption } from 'tree/types'
import { decapitalize, dropdownMenu } from 'helpers'

type Option = CustomSelectOption

export type CustomSelectInputProps = Pick<CustomSingleDropdownFieldValue, 'options' | 'label' | 'type'> & {
  name: string
  selectedOptions: SelectedDropdownOption[]
  onChange: (option: Option | Option[]) => void
  onBlur: (event: FocusEvent<HTMLElement>) => void
  onClear: () => void
}

const getOptions = (options: CustomSingleDropdownFieldValue['options']) =>
  options.map(({ id, value, icon }) => ({ id, value, label: `${icon} ${value}` }))

export const CustomSelectInput = (props: CustomSelectInputProps) => {
  const { name, options, label, selectedOptions: selectedOptionsUnconverted, onChange, onBlur, onClear } = props
  const initOptions: Option[] = useMemo(() => getOptions(options), [options])
  const [selectedOptions, setSelectedOptions] = useState<Option | Option[]>(getOptions(selectedOptionsUnconverted))

  return (
    <Select<Option, true | false>
      inputId={name}
      menuPosition='fixed'
      menuPortalTarget={dropdownMenu} // Portaled due to Safari bug which hides fixed positioned element if their parent has overflow hidden -> https://bugs.webkit.org/show_bug.cgi?id=160953
      components={{
        ClearIndicator: selectComponents.ClearIndicator,
        Control: selectComponents.Control,
        DropdownIndicator: selectComponents.DropdownIndicator,
        IndicatorSeparator: () => null,
        Placeholder: selectComponents.Placeholder,
      }}
      isSearchable={false}
      menuPlacement='auto'
      options={initOptions}
      placeholder={`Select ${decapitalize(label)}`}
      styles={customStyles}
      value={selectedOptions}
      isClearable
      onChange={value => {
        setSelectedOptions(value as Option | Option[])
        onChange(value as Option | Option[])
      }}
      onBlur={onBlur}
      customOnClear={() => {
        setSelectedOptions([])
        onClear()
      }}
    />
  )
}

const customStyles: StylesConfig<Option, true | false> = {
  ...selectStyles,
  menu: (provided, props) => ({
    ...selectStyles.menu?.(provided, props),
    ...selectMenuBottomMargin,
  }),
}
