import { Fragment, MutableRefObject } from 'react'
import { FormikProps } from 'formik'
import { generatePath, useHistory } from 'react-router-dom'

import { FormSection } from './form-section'
import { CustomFieldsForm } from './custom-fields-form'
import { DetailValue } from '../../../node-components'
import { FieldContainer, FieldTitle, Link } from 'components/generic'

import { useFieldFactory } from '../fields'
import { CustomFieldsAdModalType, getIsSubscribed } from 'features/premium'
import { useChartData } from 'tree/providers'
import { AboutFieldFormKey, AboutFieldsForm, PersonFormCapabilities, PersonFormValues } from 'tree/types'
import { useModal } from 'modal'
import { SETTINGS_CREATE_CUSTOM_FIELD_PATH } from 'routes'
import { getPriority } from 'consts'

type Props = {
  capabilities: PersonFormCapabilities
  formState: FormikProps<PersonFormValues>
  sectionRef: MutableRefObject<HTMLElement | null>
}

export const AboutForm = ({ sectionRef, capabilities, formState }: Props) => {
  const { id: chartId, providerInfo, GWTokenRevoked, capabilities: chartCapabilities, subscription } = useChartData()
  const canUpdateChart = chartCapabilities?.canUpdate
  const fieldFactory = useFieldFactory({ capabilities, providerInfo, GWTokenRevoked, canUpdateChart })

  const { values } = formState
  const { employeeId, employeeType, familyName, givenName, image, position } = values
  const aboutFields: AboutFieldsForm = {
    employeeId,
    employeeType,
    familyName,
    givenName,
    image,
    position,
  }
  const aboutFieldKeys: AboutFieldFormKey[] = Object.entries(aboutFields)
    .map(([key]) => key as AboutFieldFormKey)
    .sort((a, b) => getPriority(b) - getPriority(a))

  // Add custom field
  const history = useHistory()
  const isSubscribed = getIsSubscribed(subscription)
  const { open } = useModal()
  const handleAddCustomField = () => {
    if (isSubscribed) {
      history.push(generatePath(SETTINGS_CREATE_CUSTOM_FIELD_PATH, { chartUuid: chartId }), {
        from: window.location.pathname,
      })
    } else {
      open<CustomFieldsAdModalType>('customFieldsAdModal')
    }
  }

  return (
    <FormSection ref={sectionRef}>
      {aboutFieldKeys.map(key => (
        <Fragment key={key}>{fieldFactory(key, formState)}</Fragment>
      ))}

      <CustomFieldsForm formState={formState} />

      <FieldContainer>
        <FieldTitle title='Your own input' />
        <DetailValue>
          <Link as='div' onClick={handleAddCustomField}>
            + Add custom field
          </Link>
        </DetailValue>
      </FieldContainer>
    </FormSection>
  )
}
