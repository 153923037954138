import { SVG, SVGProps } from './SVG'

export const BulletListIcon = (props: SVGProps) => (
  <SVG width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M1.875 2.25C1.25156 2.25 0.75 2.75156 0.75 3.375V5.625C0.75 6.24844 1.25156 6.75 1.875 6.75H4.125C4.74844 6.75 5.25 6.24844 5.25 5.625V3.375C5.25 2.75156 4.74844 2.25 4.125 2.25H1.875ZM8.625 3.375C8.00156 3.375 7.5 3.87656 7.5 4.5C7.5 5.12344 8.00156 5.625 8.625 5.625H22.875C23.4984 5.625 24 5.12344 24 4.5C24 3.87656 23.4984 3.375 22.875 3.375H8.625ZM8.625 10.875C8.00156 10.875 7.5 11.3766 7.5 12C7.5 12.6234 8.00156 13.125 8.625 13.125H22.875C23.4984 13.125 24 12.6234 24 12C24 11.3766 23.4984 10.875 22.875 10.875H8.625ZM8.625 18.375C8.00156 18.375 7.5 18.8766 7.5 19.5C7.5 20.1234 8.00156 20.625 8.625 20.625H22.875C23.4984 20.625 24 20.1234 24 19.5C24 18.8766 23.4984 18.375 22.875 18.375H8.625ZM0.75 10.875V13.125C0.75 13.7484 1.25156 14.25 1.875 14.25H4.125C4.74844 14.25 5.25 13.7484 5.25 13.125V10.875C5.25 10.2516 4.74844 9.75 4.125 9.75H1.875C1.25156 9.75 0.75 10.2516 0.75 10.875ZM1.875 17.25C1.25156 17.25 0.75 17.7516 0.75 18.375V20.625C0.75 21.2484 1.25156 21.75 1.875 21.75H4.125C4.74844 21.75 5.25 21.2484 5.25 20.625V18.375C5.25 17.7516 4.74844 17.25 4.125 17.25H1.875Z'
      fill='currentColor'
    />
  </SVG>
)
