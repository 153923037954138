export const useNumberOfChartVisits = () => {
  const getNumberOfChartVisits = (chartUuid: string) => {
    const numOfVisitsJSON = window.localStorage.getItem(`number-of-chart-visits-${chartUuid}`)
    const numOfVisits = numOfVisitsJSON && JSON.parse(numOfVisitsJSON)
    return typeof numOfVisits === 'number' ? numOfVisits : 0
  }

  const setNumberOfChartVisits = (chartUuid: string, numberOfChartVisits: number) => {
    window.localStorage.setItem(`number-of-chart-visits-${chartUuid}`, JSON.stringify(numberOfChartVisits))
  }

  return { getNumberOfChartVisits, setNumberOfChartVisits }
}
