import { ReactNode } from 'react'
import styled from 'styled-components'
import { GrFacebookOption, GrInstagram, GrLinkedinOption, GrYoutube } from 'react-icons/gr'
import { FaWhatsapp, FaXTwitter } from 'react-icons/fa6'

import { SocialLinkFieldKey } from '../types'

const StyledFaWhatsapp = styled(FaWhatsapp)`
  width: 100%;
  margin-left: 1px;
`

export type SocialLinkIcon = {
  icon: ReactNode
  background: string
}

export type SocialLinksIcon = Record<SocialLinkFieldKey, SocialLinkIcon>

export const SOCIAL_LINKS_ICON: SocialLinksIcon = {
  linkedInLink: { icon: <GrLinkedinOption />, background: '#0077B5' },
  whatsAppLink: { icon: <StyledFaWhatsapp />, background: '#25D366' },
  xLink: { icon: <FaXTwitter />, background: '#000000' },
  facebookLink: { icon: <GrFacebookOption />, background: '#3B5998' },
  instagramLink: { icon: <GrInstagram />, background: '#FF3366' },
  youTubeLink: {
    icon: <GrYoutube />,
    background: '#FF0000',
  },
}
