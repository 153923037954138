import { ReactNode } from 'react'
import { CSSProperties } from 'styled-components'

import { CardContainer } from './CardContainer'
import { CardContent } from './CardContent'
import { CardTitle } from './CardTitle'

type CardProps = {
  className?: string
  children?: ReactNode
  title?: string
  direction?: CSSProperties['flexDirection']
  noCardPadding?: boolean
}

export const Card = ({ className, title, children, direction = 'column', noCardPadding }: CardProps) => (
  <CardContainer className={className} noCardPadding={noCardPadding}>
    {title && <CardTitle withPadding={noCardPadding}>{title}</CardTitle>}
    <CardContent $direction={direction}>{children}</CardContent>
  </CardContainer>
)
