import { FormikProps } from 'formik'

import { customFieldFactory } from '../fields'
import { CustomFieldValueWithoutConnection, PersonFormValues } from 'tree/types'

type Props = {
  formState: FormikProps<PersonFormValues>
}

export const CustomFieldsForm = ({ formState }: Props) => {
  const customFieldsWithoutConnection = Object.values(formState.values.customFields).filter(
    value => value.type !== 'connection'
  ) as CustomFieldValueWithoutConnection[]

  return <>{customFieldsWithoutConnection.map(field => customFieldFactory({ field, formState }))}</>
}
