import styled from 'styled-components'

type Props = {
  $verticalGroupSize: string
  $hideConnectingLine?: boolean
}

export const TreeItemGroup = styled.div<Props>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: ${props => props.$verticalGroupSize};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    width: 50%;
    height: ${props => props.$verticalGroupSize};
    display: ${props => (props.$hideConnectingLine ? 'none' : 'block')};
    box-sizing: border-box;
    border-right: 1px solid ${props => props.theme.colors.border};
  }
`
