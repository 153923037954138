import { useChartData } from '../providers/use-chart-data'

import { ChartCapabilitiesFragment } from 'apollo/generated/graphql'

type LoadingPermissions = Record<string, boolean> & {
  isLoading: true
}

type LoadedPermissions = ChartCapabilitiesFragment & {
  isLoading: false
}

type Permissions = LoadingPermissions | LoadedPermissions

export const useChartPermission = (): Permissions => {
  const { capabilities: c } = useChartData(true) || {}

  if (!c) return { isLoading: true }
  return { ...c, isLoading: false }
}
