import styled from 'styled-components'

import { SharePremiumBenefits } from './components'
import { View, ViewHeader, ViewTitle } from '../components'
import { Card, DeletionCard, PremiumBenefits } from '../../components'
import { PublicLinkForm } from 'components/forms'
import { Button, FieldCopyToClipboard } from 'components/generic'

import { useCreatePublicLinkAction, useDeletePublicLinkAction } from 'tree/actions'
import { useChartData } from 'tree/providers'
import { useChartPermission } from 'tree/hooks'
import { getIsSubscribed } from 'features/premium'
import analytics from 'analytics'
import { SHARING_TRACK } from 'analytics/constants'
import { spaces } from 'theme'

import { useGetPublicLinksQuery } from 'apollo/generated/graphql'

// if getCanShowSharingView
export const SharableLink = () => {
  const chartData = useChartData()
  const { uuid: chartUuid, name: chartName, customFields, employeeCount, departmentCount, subscription } = chartData
  const { data, loading } = useGetPublicLinksQuery({ variables: { chartUuid } })
  const publicLinks = data?.getPublicLinks
  const publicLink = publicLinks ? publicLinks[0] : undefined
  const { canDeletePublicLink, canCreatePublicLink, canReadPublicLink } = useChartPermission()
  const isSubscribed = getIsSubscribed(subscription)

  const showCreateLink = isSubscribed && !publicLink
  const showLink = publicLink && canReadPublicLink
  const showSharePromo = !isSubscribed
  const showDelete = publicLink && canDeletePublicLink

  const { createPublicLink, isCreating } = useCreatePublicLinkAction()
  const { deletePublicLinkWithConfirm, isDeleting } = useDeletePublicLinkAction()

  const handleCopyTrack = () => {
    analytics.track(SHARING_TRACK.publicLinkCopied, {
      chartUuid,
      chartName,
      employeeCount,
      departmentCount,
      publicLink: publicLink?.url,
    })
  }

  if (loading) return null

  return (
    <View quota='chart.publicLink.domain'>
      <ViewHeader>
        <ViewTitle>Sharable link</ViewTitle>
      </ViewHeader>

      {showCreateLink && (
        <Card title='Create new link'>
          <BasicText>
            Users with this link have read-only access. Delete the link if you wish to cease all sharing.
          </BasicText>
          <Button
            disabled={isCreating || !canCreatePublicLink}
            spacing={{ mr: 'auto' }}
            onClick={() => createPublicLink({ chartData })}
            data-testid='button-create-link-in-chart-share-modal'
          >
            Create Link
          </Button>
        </Card>
      )}

      {showLink && (
        <Card>
          <FieldCopyToClipboard
            title={{
              title: (
                <>
                  Link to your Org Chart{' '}
                  <TitleExplanation>(Users with this link have read-only access.)</TitleExplanation>
                </>
              ),
              size: 'big',
            }}
            value={publicLink.url}
            onCopy={handleCopyTrack}
          />

          <PublicLinkForm chartUuid={chartUuid} customFields={customFields} publicLink={publicLink} />
        </Card>
      )}

      {showSharePromo && (
        <BenefitsCard>
          <SharePremiumBenefits />
          <PremiumBenefits spacing={{ mt: spaces.xl }} />
        </BenefitsCard>
      )}

      {showDelete && (
        <DeletionCard
          title='Delete link'
          description='This action will permanently delete current link. This cannot be undone.'
          deleteButtonText='Delete link'
          disabled={isDeleting}
          onDelete={() => deletePublicLinkWithConfirm({ chartId: chartUuid, publicLinkId: publicLink.id })}
          data-testid='button-delete-link'
        />
      )}
    </View>
  )
}

const BasicText = styled.div`
  margin-bottom: ${props => props.theme.spaces.l};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`

const TitleExplanation = styled.div`
  margin-left: ${props => props.theme.spaces.s};
  color: ${props => props.theme.colors.greyLight};
`

const BenefitsCard = styled(Card)`
  overflow: hidden;
`
