import { GroupHeading } from '../../components'
import { NodeDetailItem } from '../../../node-components'
import { DisplaySupervisorAndDepartment } from 'components/specific/display-supervisor-and-department'
import { Link, SeparatedInlineList } from 'components/generic'
import { DottedLineReportingFlatIcon } from 'components/icons'

import { useChartSidePanelDetail } from 'tree/providers'
import { CustomConnectionFieldValue } from 'tree/types'
import { useGoToEmployeeDetail } from 'routes'
import { spaces } from 'theme'

import { DepartmentDetailDataFragmentFragment, PersonDetailDataFragmentFragment } from 'apollo/generated/graphql'

type Props = {
  customConnectionFieldValue: CustomConnectionFieldValue[]
  unassigned: boolean
  superiorDepartment: DepartmentDetailDataFragmentFragment | undefined
  superiorPerson: PersonDetailDataFragmentFragment | undefined
}

export const ConnectionFieldsDetail = ({
  customConnectionFieldValue,
  unassigned,
  superiorDepartment,
  superiorPerson,
}: Props) => {
  const nonEmptyConnectionFieldValues = customConnectionFieldValue.filter(f => f.values.length)
  const { isConnectionsExpanded, setIsConnectionsExpanded } = useChartSidePanelDetail()
  const { goToEmployeeDetail } = useGoToEmployeeDetail()

  return (
    <section>
      <GroupHeading
        icon={<DottedLineReportingFlatIcon width='19px' height='100%' />}
        title='Connections'
        isExpanded={isConnectionsExpanded}
        onClick={() => setIsConnectionsExpanded(!isConnectionsExpanded)}
      />

      {isConnectionsExpanded && (
        <>
          <NodeDetailItem
            title='Supervisor and department'
            value={
              <DisplaySupervisorAndDepartment
                unassigned={unassigned}
                superiorPerson={superiorPerson}
                superiorDepartment={superiorDepartment}
              />
            }
          />
          {nonEmptyConnectionFieldValues.map(cf => {
            const value = (
              <SeparatedInlineList
                items={cf.values.map(value => (
                  <Link key={value.id} as='div' onClick={() => goToEmployeeDetail(value.id)}>
                    {value.name}
                  </Link>
                ))}
                marginBottom={spaces.m}
              />
            )
            return <NodeDetailItem key={cf.id} title={cf.label} value={value} />
          })}
        </>
      )}
    </section>
  )
}
