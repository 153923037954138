import { useSetShowUnassignedNodesSidebarMutation } from 'apollo/generated/graphql'

export const useDirectory = () => {
  const [setShowDirectory] = useSetShowUnassignedNodesSidebarMutation()

  const openDirectory = () => setShowDirectory({ variables: { showUnassignedNodesSidebar: true } })
  const closeDirectory = () => setShowDirectory({ variables: { showUnassignedNodesSidebar: false } })

  return { openDirectory, closeDirectory }
}
