import styled from 'styled-components'

import { Button } from 'components/generic'
import { chartCardCSS, chartCardHeadingCSS, chartCardContentCSS } from './chart-card-css'
import calendarAnalyticsBanner from 'assets/calendar-analytics-banner.png'

import analytics from 'analytics'
import { ADS_TRACK } from 'analytics/constants'
import { UNARIC_URL } from 'consts'
import { spaces } from 'theme'

export const ChartCardCalendarAnalyticsBanner = () => (
  <ChardCardBannerWrap
    onClick={() => {
      analytics.track(ADS_TRACK.adClicked, {
        type: 'Calendar Analytics',
        redirectUrl: UNARIC_URL,
        source: 'overview',
      })
    }}
    href={UNARIC_URL}
    target='_blank'
  >
    <CardHeading>
      Power your insights with Calendar Analytics
      <Badge>FREE TOOL</Badge>
    </CardHeading>
    <ContentContainer>
      <TextWrap>
        <Heading>Receive in-depth insights into your team meeting dynamics</Heading>
        <Text>
          See who you meet with most both internally and externally. Leverage insights to improve productivity,
          collaboration, customer relationships, and more!
        </Text>
        <Button spacing={{ mt: spaces.l }}>Try it for free!</Button>
      </TextWrap>
      <BannerImage src={calendarAnalyticsBanner} alt='Calendar Analytics illustration' />
    </ContentContainer>
  </ChardCardBannerWrap>
)

const ChardCardBannerWrap = styled.a`
  ${chartCardCSS}
`

const CardHeading = styled.div`
  ${chartCardHeadingCSS}
`

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => props.theme.spaces.s};
  margin-left: ${props => props.theme.spaces.m};
  border-radius: 3px;
  background: ${props => props.theme.colors.yellowishOrange};
  text-align: center;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
`

const ContentContainer = styled.div`
  ${chartCardContentCSS}
  align-items: center;
`

const TextWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
`

const Heading = styled.div`
  max-width: 212px;
  margin-bottom: ${props => props.theme.spaces.m};
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
`

const Text = styled.div`
  font-weight: normal;
  color: ${props => props.theme.colors.grey};
  font-size: 13px;
  line-height: 18px;
`

const BannerImage = styled.img`
  width: auto;
  max-width: 235px;
  height: auto;
  max-height: 214px;
  margin: -${props => props.theme.spaces.l};
  margin-left: auto;
  user-select: none;
`
