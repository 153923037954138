let configFromWindowObject: Record<string, string> = {}
try {
  configFromWindowObject = JSON.parse((window as any).ORG_CHART_CONFIG)
} catch (_) {
  // Error while parsing json of ORG_CHART_CONFIG
}

const getStringValue = (key: string, defaultValue = '') => {
  return configFromWindowObject[key] || process.env[key] || defaultValue
}

const getBooleanValue = (key: string) => {
  return getStringValue(key) === 'true'
}

export default {
  API_URI: getStringValue('REACT_APP_API_URI'),
  PRICING_PAGE_URI: getStringValue('REACT_APP_PRICING_PAGE_URI'),
  MIXPANEL_PROXY_URI: getStringValue('REACT_APP_MIXPANEL_PROXY_URI'),
  AUTH0_CUSTOM_DOMAIN: getStringValue('REACT_APP_AUTH0_CUSTOM_DOMAIN'),
  AUTH0_DOMAIN: getStringValue('REACT_APP_AUTH0_DOMAIN'),
  AUTH0_CLIENT_ID: getStringValue('REACT_APP_AUTH0_CLIENT_ID'),
  AUTH0_CALLBACK: getStringValue('REACT_APP_AUTH0_CALLBACK'),
  AUTH0_AUDIENCE: getStringValue('REACT_APP_AUTH0_AUDIENCE'),
  MIXPANEL_ENABLED: getBooleanValue('REACT_APP_MIXPANEL_ENABLED'),
  MIXPANEL_TOKEN: getStringValue('REACT_APP_MIXPANEL_TOKEN'),
  INTERCOM_ENABLED: getBooleanValue('REACT_APP_INTERCOM_ENABLED'),
  INTERCOM_APP_ID: getStringValue('REACT_APP_INTERCOM_APP_ID'),
  GTM_ENABLED: getBooleanValue('REACT_APP_GTM_ENABLED'),
  GTM_ID: getStringValue('REACT_APP_GTM_ID'),
  VERSION_CHECK_ENABLED: getBooleanValue('REACT_APP_VERSION_CHECK_ENABLED'),
  INSIGHTS_ENABLED: getBooleanValue('REACT_APP_INSIGHTS_ENABLED'),
  REACT_STRICT_MODE_ENABLED: getBooleanValue('REACT_APP_REACT_STRICT_MODE_ENABLED'),
  HELP_CENTER_URL: getStringValue('REACT_APP_HELP_CENTER_URL'),
  CONTENTFUL_ENVIRONMENT: getStringValue('REACT_APP_CONTENTFUL_ENVIRONMENT'),
  CONTENTFUL_SPACE: getStringValue('REACT_APP_CONTENTFUL_SPACE'),
  CONTENTFUL_ACCESS_TOKEN: getStringValue('REACT_APP_CONTENTFUL_ACCESS_TOKEN'),
  CONTENTFUL_CONTENT_TYPE_CHARTS_BANNER_AD: getStringValue('REACT_APP_CONTENTFUL_CONTENT_TYPE_CHARTS_BANNER_AD'),
  CONTENTFUL_CONTENT_TYPE_SUBSCRIPTION_CARD: getStringValue('REACT_APP_CONTENTFUL_CONTENT_TYPE_SUBSCRIPTION_CARD'),
}
