import { generatePath, useHistory } from 'react-router-dom'
import { History } from 'history'

import { useChartId } from 'tree/providers'
import { Node } from 'tree/types'
import { DEPARTMENT_EDIT_PATH, EMPLOYEE_EDIT_PATH } from 'routes'

export type GoToEditNodeParams = {
  chartUuid: string
  history: History<unknown>
  nodeId: string
}

export const goToEditEmployee = ({ chartUuid, history, nodeId }: GoToEditNodeParams) => {
  history.push(generatePath(EMPLOYEE_EDIT_PATH, { chartUuid, uuid: nodeId }))
}

export const goToEditDepartment = ({ chartUuid, history, nodeId }: GoToEditNodeParams) => {
  history.push(generatePath(DEPARTMENT_EDIT_PATH, { chartUuid, uuid: nodeId }))
}

export const useGoToEditEmployee = () => {
  const history = useHistory()
  const chartUuid = useChartId()

  const handlerStandardFields = (nodeId: Node['id']) => {
    goToEditEmployee({ chartUuid, history, nodeId })
  }

  return { goToEditEmployee: handlerStandardFields }
}

export const useGoToEditDepartment = () => {
  const history = useHistory()
  const chartUuid = useChartId()

  const handler = (nodeId: Node['id']) => {
    goToEditDepartment({ chartUuid, history, nodeId })
  }

  return { goToEditDepartment: handler }
}
