import { css } from 'styled-components'

export const BoxContainerCSS = css`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  margin: 0 ${props => props.theme.spaces.xs};
`
