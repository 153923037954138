import { gql } from '@apollo/client'

export const chartMeFragment = gql`
  fragment ChartMeFragment on Chart {
    id
    me {
      ... on Person {
        id
        email
        teamSize
      }
    }
  }
`
