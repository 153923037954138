import { ReactNode, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { useScrollToTopChartSidePanel } from './use-scroll-top-chart-side-panel'
import { ChartSidePanelRefProvider } from './chart-side-panel-context'
import { useTopNavigationToggle } from 'providers'

type Props = {
  children: ReactNode
}

export const ChartSidePanel = ({ children }: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const { isVisible } = useTopNavigationToggle()
  const { pathname } = useLocation()

  const { scrollToTop } = useScrollToTopChartSidePanel()
  useEffect(() => {
    scrollToTop()
  }, [pathname])

  return (
    <ChartSidePanelInner $isTopNavVisible={isVisible} ref={ref}>
      <ChartSidePanelRefProvider panelRef={ref}>{children}</ChartSidePanelRefProvider>
    </ChartSidePanelInner>
  )
}

const ChartSidePanelInner = styled.div<{ $isTopNavVisible: boolean }>`
  position: relative;
  width: 100%;
  max-width: 458px;
  height: auto;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 3px 12px rgba(69, 90, 100, 0.3);
  overflow-y: auto;
  z-index: 20;
`
