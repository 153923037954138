import { ReactNode } from 'react'
import { FormikProps } from 'formik'

import { SocialLinkTitle } from './social-link-title'
import { FieldInput, FieldEmailAliases, FieldInputProps, FieldProfilePicture } from 'components/generic'

import { ChartSyncProvider, PersonFormCapabilities, PersonFormValues } from 'tree/types'

import { ChartFragmentFragment } from 'apollo/generated/graphql'

type FieldFactoryParams = {
  capabilities: PersonFormCapabilities
  providerInfo: ChartFragmentFragment['providerInfo']
  GWTokenRevoked?: boolean
  canUpdateChart?: boolean
}

const TOOLTIP_TEXTS = {
  GWTokenRevoked:
    'The connection to Google Workspace is not synced right now. Please contact the owner or administrator of this organizational chart to re-connect the chart with Google Workspace.',
  GWTokenRevokedAdmin:
    'Your synchronization token has expired. Please reconnect to continue syncing your Org Chart with Google Workspace.',
  GWNoCapability: 'Field can only be edited by admin in Google Workspace.',
}

export const useFieldFactory = ({ capabilities, providerInfo, GWTokenRevoked, canUpdateChart }: FieldFactoryParams) => {
  const disabledTooltipText = (() => {
    if (GWTokenRevoked) return TOOLTIP_TEXTS[canUpdateChart ? 'GWTokenRevokedAdmin' : 'GWTokenRevoked']
    if (providerInfo?.name === 'gsuite') return TOOLTIP_TEXTS.GWNoCapability
  })()

  const getFieldSyncProvider = (name: keyof PersonFormValues): ChartSyncProvider | undefined => {
    if (providerInfo?.name === 'gsuite') {
      const isFieldSynced = Boolean(providerInfo?.syncedFields.some(field => field.name === name))
      if (isFieldSynced) return 'google'
    }
  }

  const {
    canModifyImage,
    canModifyName,
    canModifyPosition,
    canModifyEmail,
    canModifyMobile,
    canModifyLocation,
    canModifyEmailAliases,
    canModifyBuildingId,
    canModifyFloorName,
    canModifyFloorSection,
    canModifyDeskCode,
    canModifyEmployeeId,
    canModifyEmployeeType,
    canModifyCostCenter,
    canModifySocialContacts,
  } = capabilities

  const fieldFactory = (name: keyof PersonFormValues, formState: FormikProps<PersonFormValues>) => {
    const { values, handleChange, handleBlur, setFieldValue, setFieldTouched } = formState

    const inputFieldFactory = ({
      name,
      type = 'text',
      title,
      required,
      placeholder,
      disabled,
    }: {
      name: Exclude<keyof PersonFormValues, 'customFields' | 'emailAliases'>
      type?: FieldInputProps['type']
      title?: ReactNode
      placeholder?: string
      required?: boolean
      disabled?: boolean
    }) => (
      <FieldInput
        name={name}
        type={type}
        title={{ title, required }}
        placeholder={placeholder}
        value={String(values[name] ?? '')}
        disabled={disabled}
        iconTooltipText={disabledTooltipText}
        onChange={handleChange}
        onBlur={handleBlur}
        onClear={() => setFieldValue(name, '')}
      />
    )

    switch (name) {
      case 'image':
        return (
          <FieldProfilePicture
            disabled={!canModifyImage}
            initialImgSrc={values.image}
            onChange={val => setFieldValue('image', val)}
          />
        )
      case 'givenName':
        return (
          <FieldInput
            name='givenName'
            type='text'
            title={{ title: 'First name', required: true }}
            placeholder='First name'
            value={values.givenName || ''}
            disabled={!canModifyName}
            iconTooltipText={disabledTooltipText}
            syncProvider={getFieldSyncProvider(name)}
            onChange={handleChange}
            onBlur={handleBlur}
            onClear={() => setFieldValue('givenName', '')}
          />
        )
      case 'familyName':
        return (
          <FieldInput
            name='familyName'
            type='text'
            title={{ title: 'Last name', required: true }}
            placeholder='Last name'
            value={values.familyName || ''}
            disabled={!canModifyName}
            iconTooltipText={disabledTooltipText}
            syncProvider={getFieldSyncProvider(name)}
            onChange={handleChange}
            onBlur={handleBlur}
            onClear={() => setFieldValue('familyName', '')}
          />
        )
      case 'position':
        return (
          <FieldInput
            name='position'
            type='text'
            title={{ title: 'Job title' }}
            placeholder='Job title'
            autoComplete='on'
            value={values.position || ''}
            disabled={!canModifyPosition}
            iconTooltipText={disabledTooltipText}
            syncProvider={getFieldSyncProvider(name)}
            onChange={handleChange}
            onBlur={handleBlur}
            onClear={() => setFieldValue('position', '')}
          />
        )
      case 'email':
        return (
          <FieldInput
            name='email'
            type='email'
            title={{ title: 'Email' }}
            placeholder='Email address'
            value={values.email || ''}
            disabled={!canModifyEmail}
            iconTooltipText={disabledTooltipText}
            syncProvider={getFieldSyncProvider(name)}
            onChange={handleChange}
            onBlur={handleBlur}
            onClear={() => setFieldValue('email', '')}
          />
        )
      case 'mobile':
        return (
          <FieldInput
            name='mobile'
            type='text'
            title={{ title: 'Phone' }}
            placeholder='Phone number'
            value={values.mobile || ''}
            disabled={!canModifyMobile}
            iconTooltipText={disabledTooltipText}
            syncProvider={getFieldSyncProvider(name)}
            onChange={handleChange}
            onBlur={handleBlur}
            onClear={() => setFieldValue('mobile', '')}
          />
        )
      case 'employeeId':
        return (
          <FieldInput
            name='employeeId'
            type='text'
            title={{ title: 'Employee id' }}
            placeholder='Employee id'
            value={values.employeeId || ''}
            disabled={!canModifyEmployeeId}
            iconTooltipText={disabledTooltipText}
            syncProvider={getFieldSyncProvider(name)}
            onChange={handleChange}
            onBlur={handleBlur}
            onClear={() => setFieldValue('employeeId', '')}
          />
        )
      case 'employeeType':
        return (
          <FieldInput
            name='employeeType'
            type='text'
            title={{ title: 'Employee type' }}
            placeholder='Employee type'
            autoComplete='on'
            value={values.employeeType || ''}
            disabled={!canModifyEmployeeType}
            iconTooltipText={disabledTooltipText}
            syncProvider={getFieldSyncProvider(name)}
            onChange={handleChange}
            onBlur={handleBlur}
            onClear={() => setFieldValue('employeeType', '')}
          />
        )
      case 'costCenter':
        return (
          <FieldInput
            name='costCenter'
            type='text'
            title={{ title: 'Cost center' }}
            placeholder='Cost center'
            autoComplete='on'
            value={values.costCenter || ''}
            disabled={!canModifyCostCenter}
            iconTooltipText={disabledTooltipText}
            syncProvider={getFieldSyncProvider(name)}
            onChange={handleChange}
            onBlur={handleBlur}
            onClear={() => setFieldValue('costCenter', '')}
          />
        )
      case 'location':
        return (
          <FieldInput
            name='location'
            type='text'
            title={{ title: 'Area' }}
            placeholder='Area'
            autoComplete='on'
            value={values.location || ''}
            disabled={!canModifyLocation}
            iconTooltipText={disabledTooltipText}
            syncProvider={getFieldSyncProvider(name)}
            onChange={handleChange}
            onBlur={handleBlur}
            onClear={() => setFieldValue('location', '')}
          />
        )
      case 'buildingId':
        return (
          <FieldInput
            name='buildingId'
            type='text'
            title={{ title: 'Building Id' }}
            placeholder='Building Id'
            autoComplete='on'
            value={values.buildingId || ''}
            disabled={!canModifyBuildingId}
            iconTooltipText={disabledTooltipText}
            syncProvider={getFieldSyncProvider(name)}
            onChange={handleChange}
            onBlur={handleBlur}
            onClear={() => setFieldValue('buildingId', '')}
          />
        )
      case 'floorName':
        return (
          <FieldInput
            name='floorName'
            type='text'
            title={{ title: 'Floor name' }}
            placeholder='Floor name'
            autoComplete='on'
            value={values.floorName || ''}
            disabled={!canModifyFloorName}
            iconTooltipText={disabledTooltipText}
            syncProvider={getFieldSyncProvider(name)}
            onChange={handleChange}
            onBlur={handleBlur}
            onClear={() => setFieldValue('floorName', '')}
          />
        )
      case 'floorSection':
        return (
          <FieldInput
            name='floorSection'
            type='text'
            title={{ title: 'Floor section' }}
            placeholder='Floor section'
            autoComplete='on'
            value={values.floorSection || ''}
            disabled={!canModifyFloorSection}
            iconTooltipText={disabledTooltipText}
            syncProvider={getFieldSyncProvider(name)}
            onChange={handleChange}
            onBlur={handleBlur}
            onClear={() => setFieldValue('floorSection', '')}
          />
        )
      case 'deskCode':
        return (
          <FieldInput
            name='deskCode'
            type='text'
            title={{ title: 'Desk code' }}
            placeholder='Desk code'
            value={values.deskCode || ''}
            disabled={!canModifyDeskCode}
            iconTooltipText={disabledTooltipText}
            syncProvider={getFieldSyncProvider(name)}
            onChange={handleChange}
            onBlur={handleBlur}
            onClear={() => setFieldValue('deskCode', '')}
          />
        )
      case 'emailAliases':
        return (
          <FieldEmailAliases
            name='emailAliases'
            placeholder='Email address'
            type='email'
            values={values.emailAliases}
            disabled={!canModifyEmailAliases}
            iconTooltipText={disabledTooltipText}
            syncProvider={getFieldSyncProvider(name)}
            setFieldTouched={setFieldTouched}
          />
        )
      case 'xLink':
        return inputFieldFactory({
          name: 'xLink',
          title: <SocialLinkTitle name={name}>X</SocialLinkTitle>,
          placeholder: 'X profile url address',
          disabled: !canModifySocialContacts,
        })
      case 'facebookLink':
        return inputFieldFactory({
          name: 'facebookLink',
          title: <SocialLinkTitle name={name}>Facebook</SocialLinkTitle>,
          placeholder: 'Facebook profile url address',
          disabled: !canModifySocialContacts,
        })
      case 'linkedInLink':
        return inputFieldFactory({
          name: 'linkedInLink',
          title: <SocialLinkTitle name={name}>LinkedIn</SocialLinkTitle>,
          placeholder: 'LinkedIn profile url address',
          disabled: !canModifySocialContacts,
        })
      case 'instagramLink':
        return inputFieldFactory({
          name: 'instagramLink',
          title: <SocialLinkTitle name={name}>Instagram</SocialLinkTitle>,
          placeholder: 'Instagram profile url address',
          disabled: !canModifySocialContacts,
        })
      case 'whatsAppLink':
        return inputFieldFactory({
          name: 'whatsAppLink',
          title: <SocialLinkTitle name={name}>WhatsApp</SocialLinkTitle>,
          placeholder: 'Country code and phone number (e.g., +1-(XXX)XXXXXXX)',
          disabled: !canModifySocialContacts,
        })
      case 'youTubeLink':
        return inputFieldFactory({
          name: 'youTubeLink',
          title: <SocialLinkTitle name={name}>YouTube</SocialLinkTitle>,
          placeholder: 'YouTube profile url address',
          disabled: !canModifySocialContacts,
        })
      default:
        return null
    }
  }

  return fieldFactory
}
