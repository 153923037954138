type EdgeMap = Record<string, string[]>

// Returns array with cycle path
const getCyclePath = (nodeId: string, ancestors: string[], outgoingEdgeMap: EdgeMap) => {
  // Find index od nodeId in ancestors
  const index = ancestors.indexOf(nodeId)
  // Get relevant cycle part from ancestors array
  const stackWithCycle = ancestors.slice(index)

  const queue = outgoingEdgeMap[nodeId] || []
  const cyclePath = [nodeId]
  while (queue.length > 0) {
    const id = queue.shift()
    if (!id) {
      continue
    }
    if (stackWithCycle.indexOf(id) >= 0) {
      cyclePath.unshift(id)
      if (outgoingEdgeMap[id]) {
        queue.push(...outgoingEdgeMap[id])
      }
    }
  }

  return cyclePath
}

export const topologicalSort = (incomingEdgeMap: EdgeMap, outgoingEdgeMap: EdgeMap) => {
  const sorted: string[] = []
  const visited: Record<string, boolean> = {}

  const visit = (id: string, ancestors: string[]) => {
    const childrenNodes = incomingEdgeMap[id] || []
    if (visited[id]) {
      return
    }

    ancestors.push(id)
    visited[id] = true

    childrenNodes.forEach(childrenId => {
      if (ancestors.indexOf(childrenId) >= 0) {
        const cyclePath = getCyclePath(childrenId, ancestors, outgoingEdgeMap)

        if (cyclePath.length > 1) {
          throw new Error('Cycle detected: ' + cyclePath.join(' -> '))
        }
      }
      visit(childrenId, [...ancestors])
    })

    sorted.unshift(id)
  }

  Object.keys(incomingEdgeMap).forEach(key => visit(key, []))

  return sorted
}

export const getSubgraphByDestinationNodes = (finalNodeIds: string[], edgeMap: EdgeMap) => {
  const subEdgeMap: EdgeMap = {}
  const visited: Record<string, boolean> = {}
  const queue = [...finalNodeIds]
  while (queue.length > 0) {
    const id = queue.shift()
    if (!id) {
      continue
    }
    if (visited[id]) {
      continue
    }
    visited[id] = true
    if (edgeMap[id]) {
      subEdgeMap[id] = edgeMap[id]
      queue.push(...edgeMap[id])
    } else {
      subEdgeMap[id] = []
    }
  }

  return subEdgeMap
}
