import { ReactNode } from 'react'
import styled from 'styled-components'

type Props = {
  background: string
  icon: ReactNode
  fontSize?: string
  href?: string
  size?: string
}

export const SocialLink = ({ background, icon, fontSize = '16px', href, size = '26px' }: Props) => {
  if (href) {
    return (
      <Link href={href} target='_blank' rel='noreferrer' title={href}>
        <Circle $background={background} $fontSize={fontSize} $size={size}>
          {icon}
        </Circle>
      </Link>
    )
  }

  return (
    <Link as='div'>
      <Circle $background={background} $fontSize={fontSize} $size={size}>
        {icon}
      </Circle>
    </Link>
  )
}

const Link = styled.a`
  text-decoration: none;
  color: inherit;
`

const Circle = styled.div<{ $background: string; $fontSize: string; $size: string }>`
  min-width: ${props => props.$size};
  max-width: ${props => props.$size};
  min-height: ${props => props.$size};
  max-height: ${props => props.$size};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: ${props => props.$background};
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.$fontSize};
  overflow: hidden;
`
