import { useState, useEffect } from 'react'
import { FileError, useDropzone } from 'react-dropzone'
import styled from 'styled-components'

import { Button } from 'components/generic'
import { pluralize } from 'helpers'

export type DropFile = File & { preview: string }

type Props = {
  provideFiles: (files: DropFile[]) => void
  allowMultiple?: boolean
  showAllErrors?: boolean
  type?: 'image' | 'logo'
}

export const ImageDrop = ({ provideFiles, allowMultiple, showAllErrors, type = 'image' }: Props) => {
  const [files, setFiles] = useState<DropFile[]>([])
  const { fileRejections, getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpg, image/jpeg, image/png, image/svg+xml',
    multiple: allowMultiple,
    maxSize: 2000000, // 2MB
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) })))
    },
  })

  useEffect(() => provideFiles(files), [files])

  useEffect(() => {
    // Cleanup: Making sure to revoke the data uris to avoid memory leaks
    return () => files.forEach((file: DropFile) => URL.revokeObjectURL(file.preview))
  }, [files])

  const getBetterErrorMsg = (code: string) => {
    if (code === 'file-too-large') return 'Please upload logo smaller than 2MB'
    if (code === 'file-invalid-type') return 'Please upload your logo in format jpg, png or svg'
  }

  const errorMesssages = fileRejections.map(({ errors }) =>
    errors.map((error: FileError) => (
      <Warning key={error.code}>{getBetterErrorMsg(error.code) ?? error.message}</Warning>
    ))
  )
  const errorsToDisplay = showAllErrors ? errorMesssages : errorMesssages[0]

  if (files.length === 0) {
    return (
      <Container>
        <DraggableArea {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <Row>
            <Text>{`Upload your ${type} here or`}</Text>
            <ButtonLabel>Browse</ButtonLabel>
          </Row>
          {fileRejections.length > 0 ? (
            errorsToDisplay
          ) : (
            <InfoText>Supported formats: PNG, JPG/JPEG. Maximum upload file size 2 MB.</InfoText>
          )}
        </DraggableArea>
      </Container>
    )
  }

  return (
    <ThumbsContainer>
      {files.map((file: DropFile) => (
        <StyledImg key={file.name} src={file.preview} />
      ))}
      <StyledButton buttonType='quaternary' onClick={() => setFiles([])}>
        {`Remove your ${pluralize(type, files.length)}`}
      </StyledButton>
    </ThumbsContainer>
  )
}

const Container = styled.div`
  max-height: 140px;
`

const StyledImg = styled.img`
  display: flex;
  max-width: 80%;
  max-height: 80%;
  height: auto;
  margin: 0 10px;
  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
`

const DraggableArea = styled.div`
  height: 132px;
  border: 2px dashed #e6e7ed;
  border-radius: 4px;
  cursor: pointer;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
`

const Text = styled.div`
  font-size: 13px;
  color: ${props => props.theme.colors.dark};
  margin: 8px;
`

const InfoText = styled.div`
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #83869c;
  margin-top: 10px;
`

const Warning = styled(InfoText)`
  font-weight: 500;
  color: #ee2906;
  &:first-child {
    margin-top: 10px;
  }
`

const ButtonLabel = styled.label`
  height: 18px;
  padding: 8px;
  line-height: 18px;
  border-radius: 3px;
  cursor: pointer;
  color: ${props => props.theme.colors.white};
  background: #4d009d;
  &:hover {
    background: #36006f;
  }
`

const ThumbsContainer = styled.div`
  display: flex;
  height: 120px;
  padding: 8px 0;
  align-items: center;
`

const StyledButton = styled(Button)`
  font-size: 13px;
  transition: all ${props => props.theme.transitions.mediumEase};
`
