import styled from 'styled-components'

import { View, ViewHeader, ViewTitle } from '../components'
import { Card, ExportPremiumBenefits, PremiumBenefits } from '../../components'
import { ExportingModalType, ExportingModal, ExportChart, ExportChartOrNode } from 'components/specific'

import { useChartData } from 'tree/providers'
import { getIsSubscribed, useGoToPremiumScreen } from 'features/premium'
import { useRegister } from 'modal'
import { spaces } from 'theme'

// if getCanShowExportView
export const Downloads = () => {
  useRegister<ExportingModalType>('exportingModal', ExportingModal)
  const { subscription } = useChartData()
  const isSubscribed = getIsSubscribed(subscription)
  const { goToPremiumScreen } = useGoToPremiumScreen()

  return (
    <View>
      <ViewHeader>
        <ViewTitle>Export Org Chart</ViewTitle>
      </ViewHeader>

      <Card>{isSubscribed ? <ExportChartOrNode /> : <ExportChart goToPremiumScreen={goToPremiumScreen} />}</Card>

      {!isSubscribed && (
        <BenefitsCard>
          <ExportPremiumBenefits />
          <PremiumBenefits spacing={{ mt: spaces.xxl }} />
        </BenefitsCard>
      )}
    </View>
  )
}

const BenefitsCard = styled(Card)`
  overflow: hidden;
`
