import { gql } from '@apollo/client'
import { nodeSearchDataFragment } from '../fragments'

export const searchNodes = gql`
  query searchNodes($chartKey: String!, $filter: SearchNodesFilter!) {
    searchNodes(chartKey: $chartKey, filter: $filter) {
      items {
        ...NodeSearchDataFragment
      }
    }
  }
  ${nodeSearchDataFragment}
`
