import { AppState } from '@auth0/auth0-react'

import { history } from 'app/history'
import requestedUrlStorage from './requested-url.storage'
import { CALLBACK_PATH } from 'routes/constants'

export const onRedirectCallback = (appState: AppState) => {
  if (appState?.returnTo) requestedUrlStorage.setItem(appState?.returnTo)
  history.replace(CALLBACK_PATH)
}
