import styled, { css } from 'styled-components'

import { applySpacingProps, SpacingProps } from 'helpers'
import { spaces } from 'theme'

export type FlexBoxProps = {
  $isCentered?: boolean
  $isDirectionColumn?: boolean
  $isFullWidth?: boolean
  $isXCentered?: boolean
  $isYCentered?: boolean
  $spacing?: SpacingProps
  $gap?: keyof typeof spaces
  onClick?: JSX.IntrinsicElements['div']['onClick']
}

export const FlexBox = styled.div<FlexBoxProps>`
  position: relative;
  display: flex;
  box-sizing: border-box;

  ${props =>
    props.$isCentered &&
    css`
      justify-content: center;
      align-items: center;
    `}

  ${props => props.$isFullWidth && 'width: 100%;'}
  ${props => props.$isDirectionColumn && 'flex-direction: column;'}
  ${props => props.$isXCentered && 'justify-content: center;'}
  ${props => props.$isYCentered && 'align-items: center;'}
  ${props => props.$gap && `gap: ${props.theme.spaces[props.$gap]};`}
  ${props => applySpacingProps(props.$spacing)}
`
