import { SVG, SVGProps } from '../SVG'

export const GoogleIcon = (props: SVGProps) => (
  <SVG width='24' height='25' viewBox='0 0 24 25' fill='none' {...props}>
    <path
      d='M24.0004 12.5396C24.0004 11.7239 23.9329 10.9038 23.789 10.1013H12.2422V14.7221H18.8545C18.5801 16.2124 17.6985 17.5308 16.4075 18.3685V21.3668H20.3524C22.6689 19.2768 24.0004 16.1904 24.0004 12.5396Z'
      fill='#4285F4'
    />
    <path
      d='M12.2403 24.2638C15.542 24.2638 18.3264 23.2012 20.355 21.3669L16.4101 18.3687C15.3126 19.1006 13.8957 19.5151 12.2448 19.5151C9.05113 19.5151 6.34323 17.4031 5.37163 14.5636H1.30078V17.6544C3.37894 21.7064 7.61172 24.2638 12.2403 24.2638Z'
      fill='#34A853'
    />
    <path
      d='M5.36857 14.5636C4.85578 13.0733 4.85578 11.4595 5.36857 9.96924V6.8784H1.30222C-0.434073 10.2691 -0.434073 14.2638 1.30222 17.6544L5.36857 14.5636Z'
      fill='#FBBC04'
    />
    <path
      d='M12.2403 5.01328C13.9856 4.98682 15.6724 5.63056 16.9364 6.81222L20.4315 3.38629C18.2184 1.34925 15.2811 0.229314 12.2403 0.264587C7.61172 0.264587 3.37894 2.82191 1.30078 6.87836L5.36713 9.9692C6.33423 7.12528 9.04663 5.01328 12.2403 5.01328Z'
      fill='#EA4335'
    />
  </SVG>
)
