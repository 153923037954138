import { SociomapValue } from '../../types'
import { parseLabel } from '../../utils'

import { InsightDataset } from 'apollo/generated/graphql'

// TODO filter by department(s)
export const datasetToSociomap = (ds?: InsightDataset) => {
  if (ds?.__typename !== 'InsightDatasetSociomap') return undefined

  return ds?.values.map(({ x, y, z, n }) => ({ x, y, z, n: parseLabel(n).toString() })) as SociomapValue[]
}
