import { Fragment, useMemo } from 'react'

import { SubTreeError, SubTreeLoader, TreeItemGroup } from './components'
import { TreeItem } from '../components'
import { ChartNode, CreateFirstNode } from '../chart-nodes'
import { TREE_COMPACT_SIZES, TREE_STANDARD_SIZES } from '../helpers'

import { useNodes, useChartPermission } from 'tree/hooks'
import { Department, Person } from 'tree/types'
import { sortArrayOfObjectsByString } from 'helpers'

type Node = Department | Person

const getSubTree = (node: Node, compact: boolean) => {
  if (node.employeeCount + node.departmentCount > 0) return <SubTree compact={compact} parentUuid={node.uuid} />
  return null
}

const getNode = (node: Node, compact: boolean) => (
  <ChartNode key={node.id} compact={compact} node={node}>
    {getSubTree(node, compact)}
  </ChartNode>
)

const getGroupedNodes = (nodes: Node[]) => {
  const nonEmptyNodes = nodes.filter(node => node.employeeCount + node.departmentCount > 0).map(node => [node])
  const emptyNodes = nodes.filter(node => node.employeeCount + node.departmentCount === 0)
  return [...nonEmptyNodes, emptyNodes]
}

type Props = {
  compact: boolean
  parentUuid: string | null
}

export const SubTree = ({ compact, parentUuid }: Props) => {
  const { nodes, loading, error } = useNodes(parentUuid)
  const noData = useMemo(() => nodes.length === 0 && parentUuid == null, [nodes, parentUuid])
  const { canCreatePerson, canCreateDepartment } = useChartPermission()

  if (loading) return <SubTreeLoader />
  if (error) return <SubTreeError compact={compact} />
  if (!nodes) return null

  const verticalSize = (compact ? TREE_COMPACT_SIZES : TREE_STANDARD_SIZES).vertical
  const verticalGroupSize = (compact ? TREE_COMPACT_SIZES : TREE_STANDARD_SIZES).verticalGroup

  const showCreateFirstNode = (canCreatePerson || canCreateDepartment) && noData
  const hideConnectingLine = noData && !showCreateFirstNode

  const nameSortedNodes = sortArrayOfObjectsByString([...nodes], 'name')
  const sortedNodes = sortArrayOfObjectsByString([...nameSortedNodes], 'type')

  const groupedNodes = compact ? getGroupedNodes(sortedNodes) : sortedNodes.map(node => [node])

  return (
    <TreeItemGroup
      className='node-group'
      $hideConnectingLine={hideConnectingLine}
      $verticalGroupSize={verticalGroupSize}
    >
      {showCreateFirstNode && <CreateFirstNode />}
      {groupedNodes.map(groupNodes => {
        if (groupNodes.length === 0) return null

        return (
          <Fragment key={`tree-item-${groupNodes[0].uuid}`}>
            <TreeItem
              $compact={compact}
              $isFlex={groupNodes.length === 1}
              $nodesCount={groupNodes.length}
              $verticalSize={verticalSize}
            >
              {groupNodes.map(n => getNode(n, compact))}
            </TreeItem>
          </Fragment>
        )
      })}
    </TreeItemGroup>
  )
}
