import { Fragment, MutableRefObject } from 'react'
import { FormikProps } from 'formik'

import { FormSection } from './form-section'
import { GroupHeading } from '../../components'
import { LocationIcon } from 'components/icons'

import { useFieldFactory } from '../fields'
import { useChartData } from 'tree/providers'
import { LocationFields, PersonFormCapabilities, PersonFormValues } from 'tree/types'
import { getPriority } from 'consts'

type Props = {
  capabilities: PersonFormCapabilities
  formState: FormikProps<PersonFormValues>
  sectionRef: MutableRefObject<HTMLElement | null>
}

type LocationFieldKey = keyof LocationFields

export const LocationForm = ({ capabilities, formState, sectionRef }: Props) => {
  const { providerInfo, GWTokenRevoked, capabilities: chartCapabilities } = useChartData()
  const { canUpdate: canUpdateChart } = chartCapabilities || {}
  const fieldFactory = useFieldFactory({ capabilities, providerInfo, canUpdateChart, GWTokenRevoked })

  const { values } = formState
  const { buildingId, costCenter, deskCode, floorName, floorSection, location } = values
  const locationFields: LocationFields = { buildingId, costCenter, deskCode, floorName, floorSection, location }
  const locationFieldKeys: LocationFieldKey[] = Object.entries(locationFields)
    .map(([key]) => key as LocationFieldKey)
    .sort((a, b) => getPriority(b) - getPriority(a))

  return (
    <FormSection ref={sectionRef}>
      <GroupHeading icon={<LocationIcon width='18px' height='100%' />} title='Location' />

      {locationFieldKeys.map(key => (
        <Fragment key={key}>{fieldFactory(key, formState)}</Fragment>
      ))}
    </FormSection>
  )
}
