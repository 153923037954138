import styled, { css } from 'styled-components'

export const Flag = styled.div<{ animate?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 18px;
  padding: 0 4px;
  margin-left: ${props => props.theme.spaces.s};
  margin-right: 2px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  background: ${props => props.theme.colors.yellowContrast};
  color: ${props => props.theme.colors.dark};

  transform: perspective(1px) translateZ(0);
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;

  ${props =>
    props.animate &&
    css`
      animation: ${props => props.theme.keyframes.shakeWithBounce} 2.75s linear 3;
    `}
`
