import { createContext, ReactNode, useContext } from 'react'

import { useWindowSize } from 'hooks'
import { mainTheme, pxToNum } from 'theme'

type ContextType = {
  isMobileDevice: boolean
  isSmallDevice: boolean
  isSmallerDevice: boolean
  isBigDevice: boolean
  isLaptop: boolean
}

const ResponsiveInfoContext = createContext<ContextType>({
  isMobileDevice: false,
  isSmallDevice: false,
  isSmallerDevice: false,
  isBigDevice: false,
  isLaptop: false,
})
export const useResponsiveInfo = () => useContext(ResponsiveInfoContext)

type Props = {
  children: ReactNode
}

export const ResponsiveInfoProvider = ({ children }: Props) => {
  const windowSize = useWindowSize()
  const { width } = windowSize
  const isMobileDevice = Boolean(width && width < pxToNum(mainTheme.deviceBreakpoints.mobile))
  const isSmallDevice = Boolean(width && width < pxToNum(mainTheme.deviceBreakpoints.smallTablet))
  const isSmallerDevice = Boolean(width && width < pxToNum(mainTheme.deviceBreakpoints.bigTablet))
  const isBigDevice = Boolean(width && width >= pxToNum(mainTheme.deviceBreakpoints.bigTablet))
  const isLaptop = Boolean(width && width >= pxToNum(mainTheme.deviceBreakpoints.laptop))

  return (
    <ResponsiveInfoContext.Provider value={{ isMobileDevice, isSmallDevice, isSmallerDevice, isBigDevice, isLaptop }}>
      {children}
    </ResponsiveInfoContext.Provider>
  )
}
