import { useState } from 'react'

type Params<Slide> = {
  slides: Slide[]
  /** Slide starts from 0 */
  initSlideIndex?: number
}

export const useSlider = <Slide>({ slides, initSlideIndex = 0 }: Params<Slide>) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(initSlideIndex)
  const slideCount = slides.length
  const slideLastIndex = slideCount - 1
  const currentSlide = slides[currentSlideIndex]

  const next = () => {
    if (currentSlideIndex < slideLastIndex) {
      setCurrentSlideIndex(currentSlideIndex + 1)
    } else {
      setCurrentSlideIndex(0)
    }
  }

  const previous = () => {
    if (currentSlideIndex > 0) {
      setCurrentSlideIndex(currentSlideIndex - 1)
    } else {
      setCurrentSlideIndex(slideLastIndex)
    }
  }

  /** Slide starts from 0 */
  const setIndex = (slideIndex: number) => setCurrentSlideIndex(slideIndex)

  return { currentSlide, currentSlideIndex, next, previous, setIndex }
}
