export const REQUESTE_PRO_KEY = 'requestPro'

export const setItem = (requestPro: boolean) => sessionStorage.setItem(REQUESTE_PRO_KEY, String(requestPro))

export const getItem = (): boolean => sessionStorage.getItem(REQUESTE_PRO_KEY) === 'true'

export const removeItem = () => sessionStorage.removeItem(REQUESTE_PRO_KEY)

export default {
  setItem,
  getItem,
  removeItem,
}
