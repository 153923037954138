import styled from 'styled-components'

import { ArrowIcon, CloseIcon } from 'components/icons'
import { IconButton } from 'components/generic'
import { mainTheme } from 'theme'

type Props = {
  title: string
  onClose: () => void
  onBack?: () => void
}

export const NodeEditTitle = ({ title, onClose, onBack }: Props) => (
  <NodeEditTitleInner>
    {onBack && (
      <IconButton
        size='small'
        spacing={{ mr: mainTheme.spaces.m }}
        onClick={onBack}
        data-testid='button-back-node-side-panel'
      >
        <ArrowIcon />
      </IconButton>
    )}

    <Title>{title}</Title>

    <CloseIconButton size='small' onClick={onClose} data-testid='button-close-node-side-panel'>
      <CloseIcon />
    </CloseIconButton>
  </NodeEditTitleInner>
)

const NodeEditTitleInner = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin-bottom: ${props => props.theme.spaces.m};
`

const Title = styled.div`
  color: ${props => props.theme.colors.dark};
  font-weight: 500;
  font-size: 15px;
  line-height: 16px;
`

const CloseIconButton = styled(IconButton)`
  margin-left: auto;
  color: ${props => props.theme.colors.greyLight};
`
