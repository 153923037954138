import { forwardRef, ReactNode, RefObject, SyntheticEvent } from 'react'
import styled, { css } from 'styled-components'

export type DirectoryTabProps = {
  children: ReactNode
  className?: string
  isActive?: boolean
  count?: number
  ref?: RefObject<HTMLDivElement> | null
  headingMaxWidth?: string
  isHidden?: boolean
  headingFullFill?: boolean
  onClick?: (e: SyntheticEvent<HTMLDivElement>) => void
}

export const DirectoryTab = forwardRef<HTMLDivElement, DirectoryTabProps>(
  ({ children, count, headingMaxWidth, isActive, headingFullFill, ...rest }, ref) => (
    <TabContainer ref={ref} isActive={isActive} {...rest}>
      <Heading isActive={isActive} $maxWidth={headingMaxWidth} headingFullFill={headingFullFill}>
        {children}
      </Heading>
      {typeof count === 'number' && <Count>({count})</Count>}
    </TabContainer>
  )
)
DirectoryTab.displayName = 'DirectoryTab'

const TabContainer = styled.div<{ isActive?: boolean; isHidden?: boolean }>`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  user-select: none;
  cursor: pointer;
  pointer-events: ${props => (props.isActive ? 'none' : 'auto')};
  transition: all ${props => props.theme.transitions.fastEase};

  &:hover {
    color: ${props => props.theme.colors.purple};
  }
  &:last-of-type {
    margin-right: 0;
  }

  ${props =>
    props.isHidden &&
    css`
      display: none;
      height: 0;
      width: 0;
    `}

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    margin-right: 0;
  }
`

const Heading = styled.div<{
  isActive?: DirectoryTabProps['isActive']
  $maxWidth?: DirectoryTabProps['headingMaxWidth']
  headingFullFill?: boolean
}>`
  max-width: ${props => props.$maxWidth || 'unset'};
  color: ${props => props.theme.colors.dark};
  text-align: center;
  font-size: 14px;
  line-height: 15px;

  ${props =>
    props.isActive &&
    css`
      color: ${props.theme.colors.purple};
    `}

  ${props =>
    props.headingFullFill &&
    css`
      display: flex;
      flex: 1;
      height: 100%;
    `}

  @media (min-width: ${props => props.theme.deviceBreakpoints.mobile}) {
    max-width: unset;
  }
`

const Count = styled.div`
  margin-bottom: 6px;
  margin-left: ${props => props.theme.spaces.xs};
  color: ${props => props.theme.colors.greyLight};
  font-size: 14px;
  line-height: 14px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    font-size: 12px;
  }
`
