import styled from 'styled-components'

export const Heading = styled.h1`
  position: absolute;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  top: 25px;
`

export const ChartHeading = styled(Heading)`
  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    top: ${props => props.theme.spaces.l};
    line-height: 20px;
    font-size: 16px;
    padding-left: ${props => props.theme.spaces.m};
    max-width: calc(60vw - (${props => props.theme.spaces.l} * 3 + ${props => props.theme.spaces.m} * 2));
  }
`
