import styled from 'styled-components'
import { MdAccountCircle, MdBusiness, MdKeyboardArrowDown } from 'react-icons/md'

import { ExpandButtonContainer } from './expand-button-container'
import { ExpandButtonContent } from './expand-button-content'
import { BaseText } from 'components/generic'
import Glogo from 'assets/GLogo.svg'

type Props = {
  compact: boolean
  departmentCount: number
  employeeCount: number
  expanded: boolean
  toggle: () => void
  showGoogleLogo?: boolean
}

export const ExpandButton = ({ compact, departmentCount, employeeCount, expanded, showGoogleLogo, toggle }: Props) => (
  <ExpandButtonContainer
    $compact={compact}
    onClick={e => {
      e.stopPropagation()
      toggle()
    }}
    data-id='node-expand-button'
  >
    {showGoogleLogo && (
      <GoogleLogoContainer $compact={compact}>
        <img src={Glogo} width={14} alt='' title='Connected with Google Workspace' />
      </GoogleLogoContainer>
    )}

    <ExpandButtonContent $compact={compact}>
      {employeeCount > 0 && (
        <>
          <MdAccountCircle size={12} />
          <Text>{employeeCount}</Text>
        </>
      )}{' '}
      {employeeCount > 0 && departmentCount > 0 && <Text $isBlack>|</Text>}{' '}
      {departmentCount > 0 && (
        <>
          <MdBusiness size={12} />
          <Text>{departmentCount}</Text>
        </>
      )}
      <StyledMdKeyboardArrowDown size={16} $isExpanded={expanded} />
    </ExpandButtonContent>
  </ExpandButtonContainer>
)

const GoogleLogoContainer = styled.div<{ $compact: boolean }>`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
  margin-right: ${props => (props.$compact ? props.theme.spaces.m : props.theme.spaces.s)};
`

const Text = styled(BaseText)<{ $isBlack?: boolean }>`
  font-size: 12px;
  color: ${props => (props.$isBlack ? 'rgba(0, 0, 0, 0.2)' : props.theme.colors.dark)};
`

const StyledMdKeyboardArrowDown = styled(MdKeyboardArrowDown)<{ $isExpanded: boolean }>`
  transition: transform ${props => props.theme.transitions.extraFastEase};
  transform: ${props => (props.$isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
`
