import { dimColor } from 'helpers'
import { css, FlattenSimpleInterpolation, keyframes as styledKeyframes, Keyframes } from 'styled-components'

// based on formula Math.pow(2, i)
export const spaces = {
  xs: '2px',
  s: '4px',
  m: '8px',
  l: '16px',
  xl: '32px',
  xxl: '64px',
}

export const deviceBreakpoints = {
  tinyMobile: '380px',
  mobile: '576px',
  smallTablet: '768px',
  tablet: '992px',
  bigTablet: '1200px',
  laptop: '1440px',
  smallDesktop: '1600px',
  desktop: '1920px',
}

export const sizes = {
  topNavigation: '50px',
  topNavigationMobile: '50px',
  superiorFieldHeight: '40px',
  treeItemWidth: '240px',
  treeItemHeight: '100px',
  treeBoundariesPadding: '80px',
  chartSidePanelFormButtons: '52px',
  chartSidePanelFieldTabsHeight: '36px',
}

export type ColorType = keyof typeof colors
export type ThemeColor = typeof colors[keyof typeof colors]
export const colors = {
  transparent: 'transparent',

  // Black
  black: '#000000',
  dark: '#2C2F46',

  // Grey
  grey: '#212121',
  greyDark: '#5F6368',
  greyMedium: '#545671',
  greyLight: '#83869C',
  greyMediumLight: '#909090',
  greyLightSecond: '#D9D9D9',
  greyExtraLight: '#E6E7F1',
  greyUltraLight: '#F8F8FB',
  greyActive: '#CFD0DC',
  greyCard: '#FBFBFC',
  greyBluishDark: '#D3D6E6',
  border: '#B2B3C1',
  silver: '#c5c5c5',

  // White
  white: '#FFFFFF',
  neutralSecondary: '#F1F5F7',

  // Red
  red: '#DA1414',
  solidRed: '#FF0000',
  redLighter: '#E53935',
  redLight: '#FAD5DA',
  redExtraLight: '#FEEFEF',

  // Orange
  orange: '#B95000',
  orangeLight: '#FB8C00',
  orangeExtraLight: '#FFF4EC',

  // Yellow
  yellowishGreen: '#C0CA33',
  darkYellow: '#DEB300',
  yellow: '#EDCA2C',
  yellowContrast: '#fed20a',
  yellowDep: '#FDD835',
  yellowishOrange: '#F4B70B',
  yellowLight: '#FFEDCC',
  yellowExtraLight: '#FFF9F3',

  // Green
  lightGreen: '#D9EEEC',
  green: '#287D3C',

  // Cyan
  darkCyan: '#00897B',
  cyan: '#00ACC1',

  // Blue
  darkBlue: '#3949AB',
  lightestBlue: '#CFE7F3',
  lightBlue: '#039BE5',
  linkColor: '#2F2BAD',
  googleBlue: '#4285f4',

  // Purple
  purpleDark: '#36006F',
  purple: '#4D009D',
  purpleLighter: '#6D2EAE',
  purpleLight: '#8E24AA',
  purpleVeryLight: '#B899D8',
  purpleExtraLight: '#F1EBF7',
} as const

export type DepColorType = keyof typeof depColors
export const depColors = {
  purple: colors.purple,
  purpleLight: colors.purpleLight,
  red: colors.redLighter,
  orange: colors.orangeLight,
  yellow: colors.yellowDep,
  greenLight: colors.yellowishGreen,
  green: colors.darkCyan,
  cyan: colors.cyan,
  blueLight: colors.lightBlue,
  blue: colors.darkBlue,
  gray: colors.greyLight,
}
export const getDepColor = (color: DepColorType) => dimColor(depColors[color], 0.2)

export type CustomFieldColorType = keyof typeof customFieldsColors
export const customFieldsColors = {
  purple: colors.purpleDark,
  green: '#42A89E',
  blue: '#0F87C1',
  red: '#E72E47',
  orange: '#FFA600',
  yellow: colors.yellowishGreen,
}
export const getCustomFieldColor = (color: CustomFieldColorType) => dimColor(depColors[color], 0.2)

export const transitions = {
  extraFastEase: '80ms ease',
  fastEase: '150ms ease',
  mediumEase: '280ms ease',
  mediumLinear: '280ms linear',
  slowEase: '600ms ease',
  fastBezier: '150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
}

export const keyframes = {
  opacity: styledKeyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `,
  opacityFromHalf: styledKeyframes`
    from {
      opacity: 0.5;
    }
    to {
      opacity: 1;
    }
  `,
  halfOpacity: styledKeyframes`
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.5;
    }
  `,
  scale: styledKeyframes`
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  `,
  fadeUp: styledKeyframes`
    from {
      opacity: 0.3;
      transform: translate3d(0, 4px, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  `,
  fadeUpBig: styledKeyframes`
    from {
      opacity: 0.3;
      transform: translate3d(0, 20px, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  `,
  breatheIn: styledKeyframes`
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  `,
  shakeWithBounce: styledKeyframes`
    3% {
      transform: translateX(3px) rotate(2deg);
    }
    6% {
      transform: translateX(-3px) rotate(-2deg);
    }
    9% {
      transform: translateX(3px) rotate(2deg);
    }
    12% {
      transform: translateX(-3px) rotate(-2deg);
    }
    15% {
      transform: translateX(2px) rotate(1deg);
    }
    18% {
      transform: translateX(-2px) rotate(-1deg);
    }
    21% {
      transform: translateX(2px) rotate(1deg);
    }
    24% {
      transform: translateX(-2px) rotate(-1deg);
    }
    27% {
      transform: translateX(1px) rotate(0);
    }
    30% {
      transform: translateX(-1px) rotate(0);
    }
    33%,
    100% {
      transform: translateX(0px) rotate(0);
    }
  `,
  borderHighlight: styledKeyframes`
    0%,
    5% {
      opacity: 0;
    }
    25%,
    85% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  `,
}

export const animations = {
  backgroundShapeFill: css`
    position: relative;

    :before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background: ${colors.greyExtraLight};
      transform: scale(0);
      transition: ${transitions.extraFastEase};
      z-index: -1;
    }
    :hover:before {
      transform: scale(1);
    }
  `,
  opacityHover: css`
    cursor: pointer;
    transition: ${transitions.extraFastEase};

    &:hover {
      opacity: 0.8;
    }
  `,
}

export type Theme = {
  spaces: Record<keyof typeof spaces, string>
  deviceBreakpoints: Record<keyof typeof deviceBreakpoints, string>
  sizes: Record<keyof typeof sizes, string>
  colors: Record<keyof typeof colors, string>
  transitions: Record<keyof typeof transitions, string>
  keyframes: Record<keyof typeof keyframes, Keyframes>
  animations: Record<keyof typeof animations, FlattenSimpleInterpolation>
  getDepColor: (color: DepColorType) => string
  getCustomFieldColor: (color: CustomFieldColorType) => string
}

export const mainTheme: Theme = {
  spaces,
  deviceBreakpoints,
  sizes,
  colors,
  transitions,
  keyframes,
  animations,
  getDepColor,
  getCustomFieldColor,
}

export const pxToNum = (size: string) => Number(size.split('px')[0])
