import { useHistory } from 'react-router-dom'

import { ChartForbiddenScreen, ChartLockScreen, ChartLoginScreen, ChartVerifyEmailScreen } from '../screens'
import { TopNavigation } from 'components/specific'
import { FlexBox } from 'components/generic'
import { WebLayout } from 'components/layouts'

import { useAuth } from 'auth'

import { ApolloError, ServerError } from '@apollo/client'
import { useSendVerificationEmailMutation } from 'apollo/generated/graphql'
import styled from 'styled-components'
import { ErrorCode } from 'helpers'

const withErrorContainer = (component: JSX.Element) => (
  <WebLayout>
    <FlexContainer $isCentered>
      <TopNavigation />
      {component}
    </FlexContainer>
  </WebLayout>
)

type Props = {
  error: ApolloError
}

export const OnChartError = ({ error }: Props) => {
  const history = useHistory()
  const { user, error: authError } = useAuth()
  const { email, isEmailVerified } = user
  const [sendVerificationEmailMutation] = useSendVerificationEmailMutation()

  const { graphQLErrors } = error
  const graphQLStringCode: ErrorCode = graphQLErrors[0]?.extensions?.code
  const networkStatusCode = (error?.networkError as ServerError | null)?.statusCode

  const isLocked = graphQLStringCode === 'ACCOUNT_LOCKED'
  const isForbidden = networkStatusCode === 403 || graphQLStringCode === 'FORBIDDEN'

  if (isLocked) return withErrorContainer(<ChartLockScreen />)

  // Auth fail || Unknown error
  if (authError || !isForbidden) return <ChartForbiddenScreen />

  if (!email) return withErrorContainer(<ChartLoginScreen />)

  if (!isEmailVerified)
    return withErrorContainer(
      <ChartVerifyEmailScreen onResendEmail={sendVerificationEmailMutation} onRefresh={() => history.go(0)} />
    )

  return <ChartForbiddenScreen />
}

const FlexContainer = styled(FlexBox)`
  flex: 1;
  width: 100%;
  padding: ${props => `${props.theme.spaces.xxl} ${props.theme.spaces.l}`};
`
