import styled from 'styled-components'

export const CardContainer = styled.div<{ noCardPadding?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${props => (props.noCardPadding ? 0 : props.theme.spaces.l)};
  margin-bottom: ${props => props.theme.spaces.l};
  border-radius: 3px;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 2px 3px rgba(179, 185, 207, 0.5);
  z-index: 1;
`
