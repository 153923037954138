import { useState, useEffect } from 'react'
import { Switch, NavLink, Redirect, useParams, useLocation, generatePath } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { SociomapStyle } from './components'
import { MonthsSelector } from './components/MonthsSelector'
import { ChartForbiddenScreen } from '../chart/screens/chart-forbidden-screen'
import { NavlinkTitle } from '../settings/components'
import { TabList, DirectoryTab, TopNavigation, InsightsOnboardingModal } from 'components/specific'
import { WebLayout } from 'components/layouts'
import { SidePanel, Loading } from 'components/generic'
import { TilAnalyticsIcon } from 'components/icons'
import insightsEmptySrc from 'assets/insights-empty.svg'

import { INSIGHTS_ENABLED } from './constants'
import { useInsights, InsightsDateSafeProvider } from './hooks'
import { useNumberOfInsightsVisits } from 'hooks'
import { scrollToTop } from 'helpers'
import { useResponsiveInfo } from 'providers'

import { INSIGHTS_ROUTES, CHARTS_PATH } from 'routes'
import { INSIGHTS_OVERVIEW_PATH, INSIGHTS_SOCIOMAP_PATH, INSIGHTS_TIL_ANALYTICS_PATH } from 'routes/constants'
import { RenderRoutes } from 'routes/render-components'
import analytics from 'analytics'
import { INSIGHTS_TRACK } from 'analytics/constants'
import {
  ChartDataContextSafeProvider,
  ChartIdContextSafeProvider,
  useChartData,
  useChartId,
  ChartConfigSafeProvider,
} from 'tree/providers'
import { useGoogleWorkspace, useChartMapper } from 'tree/hooks'

export const AnalyticsScreen = () =>
  INSIGHTS_ENABLED ? (
    <ChartConfigSafeProvider>
      <AnalyticsScreenWithProviders />
    </ChartConfigSafeProvider>
  ) : (
    <Redirect to={CHARTS_PATH} />
  )

const AnalyticsScreenWithProviders = () => {
  const { chartUuid } = useParams<{ chartUuid: string }>()
  const { data: insights, error: insightsError, loading: insightsLoading } = useInsights(chartUuid)
  const { loading, data, error } = useChartMapper(chartUuid)

  // TODO: Routes - Rewrite /chart/:chartUuid route to return single component handler for edit/setings/insights
  // https://app.clickup.com/t/u3x69b
  if (error) return <ChartForbiddenScreen />

  if (insightsLoading || loading)
    return (
      <>
        <TopNavigation />
        <LoadingContainer>
          <StyledLoading size='big' />
        </LoadingContainer>
      </>
    )

  if (!data) return null

  if (!insights || insights.length === 0 || insightsError) {
    return (
      <ChartIdContextSafeProvider value={chartUuid}>
        <ChartDataContextSafeProvider value={data}>
          <WebLayout>
            <TopNavigation />
            <Content noData>
              <InsightsEmptyContainer>
                <InsightsEmptyImg src={insightsEmptySrc} width='400px' alt='insights-empty' />
                <InfoText>
                  {insightsError
                    ? 'Some error has occurred. Please contact our support.'
                    : 'This chart has no insights. If you think this is a mistake, please contact our support.'}
                </InfoText>
              </InsightsEmptyContainer>
            </Content>
          </WebLayout>
        </ChartDataContextSafeProvider>
      </ChartIdContextSafeProvider>
    )
  }

  return (
    <ChartIdContextSafeProvider value={chartUuid}>
      <ChartDataContextSafeProvider value={data}>
        <AnalyticsScreenWithData />
      </ChartDataContextSafeProvider>
    </ChartIdContextSafeProvider>
  )
}

const AnalyticsScreenWithData = () => {
  const chartUuid = useChartId()
  const { data } = useInsights(chartUuid)
  const { isSmallDevice } = useResponsiveInfo()
  const { pathname } = useLocation()
  const overviewPath = generatePath(INSIGHTS_OVERVIEW_PATH, { chartUuid })
  const sociomapPath = generatePath(INSIGHTS_SOCIOMAP_PATH, { chartUuid })
  const tilAnalyticsPath = generatePath(INSIGHTS_TIL_ANALYTICS_PATH, { chartUuid })

  const { hasScope } = useGoogleWorkspace()
  const { userPermission } = useChartData()
  const askForCalendarPermission = !hasScope('calendarReadonly') && userPermission?.role === 'admin'
  const displayMonthsSelector = pathname !== tilAnalyticsPath

  const [actualMonthIndex, setActualMonthIndex] = useState<number>(0)
  const [actualDataIndex, setActualDataIndex] = useState<number>(data.length - 1)
  const [isComputingInsights, setIsComputingInsights] = useState<boolean>(false)
  const [isTyping, setIsTyping] = useState<boolean>(false)
  const [monthsDisabled, setMonthsDisabled] = useState<boolean>(false)

  const { getNumberOfInsightsVisits, setNumberOfInsightsVisits } = useNumberOfInsightsVisits()

  const startMonthIndex = new Date(data[0].reportInfo.dateFrom).getMonth()

  useEffect(() => {
    analytics.track(INSIGHTS_TRACK.visited, { chartUuid })
    sessionStorage.setItem('insights-visited', 'true')
    setNumberOfInsightsVisits(chartUuid, getNumberOfInsightsVisits(chartUuid) + 1)
  }, [])

  useEffect(() => {
    setActualMonthIndex(new Date(data[actualDataIndex]?.reportInfo.dateFrom).getMonth())
    setIsComputingInsights(data[actualDataIndex]?.reportInfo.status === 'created')
  }, [data.length, actualDataIndex])

  return (
    <InsightsDateSafeProvider
      value={{
        actualMonthIndex,
        actualDataIndex,
        startMonthIndex,
        askForCalendarPermission,
        isComputingInsights,
        setIsTyping,
        setMonthsDisabled,
      }}
    >
      {askForCalendarPermission && <InsightsOnboardingModal />}
      <WebLayout>
        <TopNavigation />
        <Container>
          {!isSmallDevice && (
            <SidePanel title='Insights' fullWidthLinks>
              <StyledNavlink onClick={scrollToTop} to={overviewPath}>
                Company Insights
              </StyledNavlink>

              <StyledNavlink onClick={scrollToTop} to={sociomapPath}>
                Sociomaps
              </StyledNavlink>

              <StyledNavlinkTitle>Platforms</StyledNavlinkTitle>
              <StyledNavlink onClick={scrollToTop} to={tilAnalyticsPath}>
                <TabTilAnalyticsIcon />
              </StyledNavlink>
            </SidePanel>
          )}

          <Content fullSizedPage={pathname === tilAnalyticsPath}>
            <Controllers>
              {displayMonthsSelector && (
                <MonthsSelector
                  chartUuid={chartUuid}
                  isTyping={isTyping}
                  monthsDisabled={monthsDisabled}
                  actualMonthIndex={actualMonthIndex}
                  actualDataIndex={actualDataIndex}
                  dataLength={data.length}
                  setActualDataIndex={setActualDataIndex}
                />
              )}
            </Controllers>

            {pathname === tilAnalyticsPath && isSmallDevice && <MobileMonthsSelectBlankSpace />}

            {isSmallDevice && (
              <TablistContainer>
                <TabList defaultActiveTab={pathname === overviewPath ? 0 : 1}>
                  <DirectoryTab isActive={pathname === overviewPath} headingFullFill>
                    <TabNavLink to={overviewPath}>Company Insights</TabNavLink>
                  </DirectoryTab>
                  <DirectoryTab isActive={pathname === sociomapPath} headingFullFill>
                    <TabNavLink to={sociomapPath}>Sociomap</TabNavLink>
                  </DirectoryTab>
                  <DirectoryTab isActive={pathname === tilAnalyticsPath} headingFullFill>
                    <TabNavLink to={tilAnalyticsPath}>TIL Analytics</TabNavLink>
                  </DirectoryTab>
                </TabList>
              </TablistContainer>
            )}

            <Switch>
              {RenderRoutes(INSIGHTS_ROUTES)}
              <Redirect to={INSIGHTS_OVERVIEW_PATH} />
            </Switch>
          </Content>

          <SociomapStyle />
        </Container>
      </WebLayout>
    </InsightsDateSafeProvider>
  )
}

const Container = styled.div<{ noData?: boolean }>`
  display: flex;
  height: 100%;
  width: 100%;
  min-height: calc(100vh - ${props => props.theme.sizes.topNavigation});
  position: relative;
  color: ${props => props.theme.colors.dark};
`

const InsightsEmptyImg = styled.img`
  width: 400px;
  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    width: 100%;
  }
`

const TabTilAnalyticsIcon = styled(TilAnalyticsIcon)`
  position: relative;
  top: 1px;
  height: ${props => props.theme.spaces.l};
  width: auto;
`

const StyledNavlinkTitle = styled(NavlinkTitle)`
  margin-left: ${props => props.theme.spaces.l};
`

const TablistContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: ${props => props.theme.spaces.l};
  margin-bottom: 26px;
`

const LoadingContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledLoading = styled(Loading)`
  position: relative;
  top: -22px;
`

const InfoText = styled.p`
  color: ${props => props.theme.colors.dark};
  font-size: 20px;
  margin-top: 20px;
  max-width: 400px;
  text-align: center;
  line-height: 24px;
`

const InsightsEmptyContainer = styled.div`
  display: flex;
  padding-bottom: 150px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    padding-bottom: 10vh;
  }
`

const Content = styled.div<{ noData?: boolean; fullSizedPage?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 40px 23px 40px 17px;
  position: relative;
  background: ${props => props.theme.colors.neutralSecondary};

  ${props =>
    props.noData &&
    css`
      margin: 0;
      height: calc(100vh - ${props => props.theme.sizes.topNavigation} - 32px);
      align-items: center;
      justify-content: center;
    `}

  ${props =>
    props.fullSizedPage &&
    css`
      margin: 0;
    `}

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    margin: 0;
    padding: ${props => props.theme.spaces.l};
  }
`

const Controllers = styled.div`
  display: flex;
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    position: relative;
    flex-direction: column;
  }
`

const TabNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const StyledNavlink = styled(NavLink)`
  display: flex;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
  text-align: left;
  padding-left: 16px;
  border-radius: 3px 0px 0px 3px;
  background: transparent;
  font-size: 12px;
  position: relative;
  color: ${props => props.theme.colors.dark};
  font-weight: 700;
  text-decoration: none;
  align-items: center;

  &:first-child {
    margin-top: 16px;
  }

  &.${'active'} {
    color: #36006f;
    background: #e6e7ed;
    &:after {
      content: '';
      height: 36px;
      width: 3px;
      right: 0;
      top: 0;
      border-radius: 3px 0px 0px 3px;
      position: absolute;
      background: #36006f;
    }
  }

  &:hover {
    color: #36006f;
  }
`

const MobileMonthsSelectBlankSpace = styled.div`
  display: flex;
  height: 42px;
`
