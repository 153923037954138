import { useState, ChangeEvent } from 'react'
import styled from 'styled-components'

import { RangeInput, RangeInputProps } from './range-input'

type RangeInputWithFillProps = {
  className?: string
} & RangeInputProps

export const RangeInputWithFill = ({
  className,
  min,
  max,
  defaultValue,
  onChange,
  ...rest
}: RangeInputWithFillProps) => {
  const calculateTranslateX = (value: number) => ((value - min) * 100) / (max - min) // Transforms value to be in range of 0-100

  const [translateX, setTranslateX] = useState<number>(calculateTranslateX(defaultValue))

  const handleOnChange = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement
    setTranslateX(calculateTranslateX(parseFloat(target.value)))
    onChange && onChange(e)
  }

  return (
    <Wrap className={className}>
      <FillWrap>
        <FillUpper translateX={translateX} />
      </FillWrap>
      <RangeInput min={min} max={max} defaultValue={defaultValue} onChange={handleOnChange} {...rest} />
    </Wrap>
  )
}

const Wrap = styled.div`
  position: relative;
  width: 100%;
  display: flex;
`

const FillWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 1px); /* Due to Fill color overflowing on right when 100% filled */
  height: 100%;
  background: ${props => props.theme.colors.purple};
  overflow: hidden;
  pointer-events: none;
`

type FillUpperProps = {
  translateX: number
}
const FillUpper = styled.div.attrs<FillUpperProps>(({ translateX }) => ({
  style: {
    transform: `translateX(${translateX}%)`,
  },
}))<FillUpperProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.greyExtraLight};
  pointer-events: none;
`
