import { CHART_LOCK_COUNTDOWN_URGENT_THRESHOLD_DAYS } from '../constants'
import { TimeRemaining } from 'helpers'
import { colors } from 'theme'

type Colors = {
  clock: string
  background: string
}

const RED_COLORS: Colors = {
  clock: colors.redLighter,
  background: colors.redLight,
}

const YELLOW_COLORS: Colors = {
  clock: colors.yellowishOrange,
  background: colors.yellowLight,
}

export const getChartLockColors = (timeRemaining: TimeRemaining): Colors => {
  return timeRemaining.days < CHART_LOCK_COUNTDOWN_URGENT_THRESHOLD_DAYS ? RED_COLORS : YELLOW_COLORS
}
