import { RefObject, Touch } from 'react'

type Rect = {
  x: number
  y: number
  width: number
  height: number
  top: number
  right: number
  bottom: number
  left: number
}

type Point = {
  x: number
  y: number
}

export const getPadRect = (padRef: RefObject<HTMLElement>): Rect => {
  const pad = padRef.current
  if (pad) {
    return pad.getBoundingClientRect()
  }

  return {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  }
}

export const getContainerHeight = (containerRef: RefObject<HTMLElement>) => {
  const container = containerRef.current
  if (container) {
    const rect = container.getBoundingClientRect()
    return rect.height
  }
  return 0
}

export const getMidpoint = (pointA: Point, pointB: Point) => ({
  x: (pointA.x + pointB.x) / 2,
  y: (pointA.y + pointB.y) / 2,
})

export const getDistanceBetweenPoints = (pointA: Point, pointB: Point) =>
  Math.sqrt(Math.pow(pointA.y - pointB.y, 2) + Math.pow(pointA.x - pointB.x, 2))

export const getPointFromTouch = (touch: Touch) => {
  return {
    x: touch.clientX,
    y: touch.clientY,
  }
}

export const getExponentialStep = (amount: number, diff: number, threshold: number) => {
  if (diff < threshold / 8) {
    return amount * 5
  }
  if (diff < threshold / 4) {
    return amount * 3
  }
  if (diff < threshold / 2) {
    return amount * 1.5
  }
  return amount
}
