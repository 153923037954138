import styled from 'styled-components'

import { FormikHandlers } from 'formik'

import { PermissionOption } from './components'
import { GoogleIcon } from 'components/icons'

type Props = {
  syncEmployeeInformation: boolean
  syncCalendar: boolean
  onChange: FormikHandlers['handleChange']
}

export const ConnectWithGoogleWorkspace = ({ syncEmployeeInformation, syncCalendar, onChange }: Props) => (
  <Container>
    <ButtonDescription>
      <StyledGoogleIcon />
      Available for Google Workspace admins only.
    </ButtonDescription>

    <Heading>Permission settings:</Heading>

    <StyledPermissionOption
      heading='Synchronize changes with Google Workspace'
      description='You only have to edit once, as changes to employee details are shared between Org Chart and Google Workspace.'
      note='[Note: You cannot change email field in Org Chart.]'
      checked={syncEmployeeInformation}
      name='syncEmployeeInformation'
      onChange={onChange}
    />
    <PermissionOption
      heading='Show Google Calendar in employee detail'
      description="Easily access any employee's Google calendar directly from their employee card in the Org Chart."
      checked={syncCalendar}
      name='syncCalendar'
      onChange={onChange}
    />
  </Container>
)

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ButtonDescription = styled.div`
  min-height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-bottom: ${props => props.theme.spaces.l};
  background: rgba(255, 143, 57, 0.15);
  color: ${props => props.theme.colors.orange};
  font-size: 14px;
  font-weight: 500;
  line-height: initial;
`

const StyledGoogleIcon = styled(GoogleIcon)`
  max-width: 16px;
  margin-right: ${props => props.theme.spaces.m};
`

const Heading = styled.div`
  margin-bottom: ${props => props.theme.spaces.m};
  color: ${props => props.theme.colors.dark};
  font-size: 15px;
  line-height: 20px;
  font-weight: 500;
`

const StyledPermissionOption = styled(props => <PermissionOption {...props} />)`
  margin-bottom: ${props => props.theme.spaces.l};
`
