import { SVG, SVGProps } from './SVG'

export const DragIcon = (props: SVGProps) => (
  <SVG width='8' height='13' viewBox='0 0 8 13' fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1 0C0.447715 0 0 0.447715 0 1V2C0 2.55228 0.447715 3 1 3H2C2.55228 3 3 2.55228 3 2V1C3 0.447715 2.55228 0 2 0H1ZM6 0C5.44772 0 5 0.447715 5 1V2C5 2.55228 5.44772 3 6 3H7C7.55228 3 8 2.55228 8 2V1C8 0.447715 7.55228 0 7 0H6ZM0 6C0 5.44772 0.447715 5 1 5H2C2.55228 5 3 5.44772 3 6V7C3 7.55228 2.55228 8 2 8H1C0.447715 8 0 7.55228 0 7V6ZM6 5C5.44772 5 5 5.44772 5 6V7C5 7.55228 5.44772 8 6 8H7C7.55228 8 8 7.55228 8 7V6C8 5.44772 7.55228 5 7 5H6ZM0 11C0 10.4477 0.447715 10 1 10H2C2.55228 10 3 10.4477 3 11V12C3 12.5523 2.55228 13 2 13H1C0.447715 13 0 12.5523 0 12V11ZM6 10C5.44772 10 5 10.4477 5 11V12C5 12.5523 5.44772 13 6 13H7C7.55228 13 8 12.5523 8 12V11C8 10.4477 7.55228 10 7 10H6Z'
      fill='currentColor'
    />
  </SVG>
)
