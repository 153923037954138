import styled from 'styled-components'
import { toast } from 'react-toastify'

import { InviteRowNode } from '../components'
import { CollaboratorForm, CollaboratorFormProps, useGetCollaborators } from 'components/forms'
import { CollaboratorRoleSelect } from 'components/specific/collaborator-role-select'
import { Button, FieldInput, FieldInputProps, SearchHint } from 'components/generic'
import { EmailIcon } from 'components/icons'

import { useGetCollaboratorUuid } from '../hooks'
import { usePendingCollaborators } from 'providers'

import { CollaboratorRole } from 'apollo/generated/graphql'
import { SHARING_TRACK } from 'analytics/constants'
import analytics from 'analytics'
import { getAllowedRoles } from 'components/specific/collaborator-role-select/helpers'

type Props = {
  chartUuid: string
  loggedUserChartRole: CollaboratorRole
}

export const InviteNonMembers = ({ chartUuid, loggedUserChartRole }: Props) => {
  const { getChartPendingCollabs, onAddPendingCollab, onCancelPendingCollab } = usePendingCollaborators()
  const pendingCollaborators = getChartPendingCollabs(chartUuid)
  const pendingNonMemberCollabs = pendingCollaborators.filter(collab => collab.isNonMember)

  const collaborators = useGetCollaborators(chartUuid)
  const getCollaboratorUuid = useGetCollaboratorUuid(chartUuid)

  const handleFormSubmit: CollaboratorFormProps['onSubmit'] = (collaborator, actions) => {
    actions.resetForm()
    const isAlreadyCollaborator = collaborators.some(collab => collab.email === collaborator.email)
    isAlreadyCollaborator
      ? toast(`Collaborator ${collaborator.email} already exists`, { type: 'error' })
      : onAddPendingCollab({ collaborator, chartUuid, isNonMember: true })
  }

  return (
    <Container>
      <CollaboratorForm onSubmit={handleFormSubmit}>
        {formState => {
          const { values, isSubmitting, setFieldValue, handleChange, submitForm } = formState
          return (
            <FormContent>
              <StyledHint>Invite new user</StyledHint>

              <StyledFieldInput
                value={values.email}
                type='text'
                name='email'
                optimized={false}
                icon={<StyledEmailIcon />}
                placeholder='Invite by email address'
                onClear={() => setFieldValue('email', '')}
                onChange={handleChange}
                data-testid='input-add-non-member-collaborator'
              />

              <StyledCollaboratorRoleSelect
                value={values.role}
                allowedRoles={getAllowedRoles({ userRole: values.role, loggedUserChartRole })}
                onRoleChange={value => setFieldValue('role', value)}
              />

              <Button
                type='button'
                disabled={isSubmitting || !values.email}
                onClick={() => {
                  analytics.track(SHARING_TRACK.invitePerson, {
                    chartUuid,
                    emailPerson: values.email,
                    rolePerson: values.role,
                  })
                  submitForm()
                }}
                data-testid='button-add-non-member-collaborator'
              >
                Invite
              </Button>

              {pendingNonMemberCollabs && (
                <InvitedNonMemberList>
                  {pendingNonMemberCollabs.map(collaborator => (
                    <InviteRowNode
                      key={collaborator.email}
                      title={collaborator.email}
                      email={collaborator.email}
                      invitationUuid={getCollaboratorUuid(collaborator.email)}
                      isInvited
                      onCancel={onCancelPendingCollab}
                    />
                  ))}
                </InvitedNonMemberList>
              )}
            </FormContent>
          )
        }}
      </CollaboratorForm>
    </Container>
  )
}

const Container = styled.div`
  margin-top: auto;
`

const FormContent = styled.div`
  justify-self: flex-end;
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: ${props => props.theme.spaces.m};
  flex-wrap: wrap;
  box-sizing: border-box;
  border-top: 1px solid ${props => props.theme.colors.greyExtraLight};
  margin-top: ${props => props.theme.spaces.l};
`

const StyledHint = styled(SearchHint)`
  flex: 1 0 100%;
  margin-top: ${props => props.theme.spaces.l};
  margin-bottom: 0;
`

const StyledFieldInput = styled((props: FieldInputProps) => <FieldInput {...props} />)`
  && {
    min-width: 180px;
    flex: 1;
    margin-bottom: 0;
  }
`

const StyledCollaboratorRoleSelect = styled(CollaboratorRoleSelect)`
  flex: 1;
`

const StyledEmailIcon = styled(EmailIcon)`
  width: 13px;
  height: auto;
  color: ${props => props.theme.colors.dark};
`

const InvitedNonMemberList = styled.div`
  margin-top: ${props => props.theme.spaces.m};
  flex: 1 0 100%;
  user-select: none;
`
