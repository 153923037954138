import { ConfirmationModalType } from '../confirmation-modal'
import { useModal } from 'modal'

export const useResyncRequiredModal = () => {
  const { open, close } = useModal()

  const openResyncRequiredModal = () => {
    open<ConfirmationModalType>('confirmationModal', {
      title: { text: 'Attention!' },
      message: (
        <div>
          The connection to Google Workspace is not synced right now. Please contact the owner or administrator of this
          organizational chart to re-connect the chart with Google Workspace. Thank you for using our Org Chart builder,
          and we apologize for any inconvenience.
        </div>
      ),
      confirmButton: { children: 'Ok, I understand' },
      onConfirm: () => close('confirmationModal'),
      onClose: () => close('confirmationModal'),
    })
  }

  return { openResyncRequiredModal }
}
