import styled from 'styled-components'

import { adModalComponents } from './ad-modal-components'
import { ResponsiveCircle } from 'components/generic'

import { ModalComponentProps, ModalTypeFactory } from 'modal'
import { mainTheme } from 'theme'

import dottedLinePreviewOnSidePanel from 'assets/dotted-line-preview-on-side-panel.png'

const {
  StyledModal,
  Content,
  ChartPreview,
  CircleContainer,
  PreviewImage,
  TextContainer,
  Title,
  Description,
  SmallTitle,
  SmallDescription,
  StyledUpgradeSubscriptionButton,
} = adModalComponents

type Props = ModalComponentProps

export type DottedLineAdModalType = ModalTypeFactory<'dottedLineAdModal', Props>

export const DottedLineAdModal = ({ onClose }: ModalComponentProps) => (
  <StyledModal onClose={onClose}>
    <Content>
      <ChartPreview>
        <CircleContainer>
          <ResponsiveCircle $color={mainTheme.colors.purple} />
        </CircleContainer>
        <StyledPreviewImage src={dottedLinePreviewOnSidePanel} />
      </ChartPreview>

      <TextContainer $isDirectionColumn>
        <Title>Create Multiple Connections with Ease!</Title>
        <Description>
          We&apos;re excited to introduce the premium feature: the ability to create multiple connections effortlessly!
        </Description>

        <SmallTitle>👥 Simplify Complex Charts</SmallTitle>
        <SmallDescription>
          Easily map out intricate relationships and hierarchies within your organization.
        </SmallDescription>

        <SmallTitle>👁️️ Enhanced Visualization</SmallTitle>
        <SmallDescription>
          Clearly see how teams and departments intertwine, improving communication and understanding across your
          organization.
        </SmallDescription>

        <StyledUpgradeSubscriptionButton />
      </TextContainer>
    </Content>
  </StyledModal>
)

const StyledPreviewImage = styled(PreviewImage)`
  max-width: 418px;

  @media (min-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    min-width: 372px;
  }
`
