import { SVG, SVGProps } from './SVG'

export const PaletteIcon = (props: SVGProps) => (
  <SVG width='24' height='24' viewBox='0 0 24 24' fill='none' {...props}>
    <path
      d='M17.4995 12C17.1017 12 16.7202 11.842 16.4389 11.5607C16.1575 11.2794 15.9995 10.8978 15.9995 10.5C15.9995 10.1022 16.1575 9.72064 16.4389 9.43934C16.7202 9.15804 17.1017 9 17.4995 9C17.8973 9 18.2789 9.15804 18.5602 9.43934C18.8415 9.72064 18.9995 10.1022 18.9995 10.5C18.9995 10.8978 18.8415 11.2794 18.5602 11.5607C18.2789 11.842 17.8973 12 17.4995 12ZM14.4995 8C14.1017 8 13.7202 7.84196 13.4389 7.56066C13.1575 7.27936 12.9995 6.89782 12.9995 6.5C12.9995 6.10218 13.1575 5.72064 13.4389 5.43934C13.7202 5.15804 14.1017 5 14.4995 5C14.8973 5 15.2789 5.15804 15.5602 5.43934C15.8415 5.72064 15.9995 6.10218 15.9995 6.5C15.9995 6.89782 15.8415 7.27936 15.5602 7.56066C15.2789 7.84196 14.8973 8 14.4995 8ZM9.49951 8C9.10169 8 8.72016 7.84196 8.43885 7.56066C8.15755 7.27936 7.99951 6.89782 7.99951 6.5C7.99951 6.10218 8.15755 5.72064 8.43885 5.43934C8.72016 5.15804 9.10169 5 9.49951 5C9.89734 5 10.2789 5.15804 10.5602 5.43934C10.8415 5.72064 10.9995 6.10218 10.9995 6.5C10.9995 6.89782 10.8415 7.27936 10.5602 7.56066C10.2789 7.84196 9.89734 8 9.49951 8ZM6.49951 12C6.10169 12 5.72016 11.842 5.43885 11.5607C5.15755 11.2794 4.99951 10.8978 4.99951 10.5C4.99951 10.1022 5.15755 9.72064 5.43885 9.43934C5.72016 9.15804 6.10169 9 6.49951 9C6.89734 9 7.27887 9.15804 7.56017 9.43934C7.84148 9.72064 7.99951 10.1022 7.99951 10.5C7.99951 10.8978 7.84148 11.2794 7.56017 11.5607C7.27887 11.842 6.89734 12 6.49951 12ZM11.9995 3C9.61256 3 7.32338 3.94821 5.63555 5.63604C3.94772 7.32387 2.99951 9.61305 2.99951 12C2.99951 14.3869 3.94772 16.6761 5.63555 18.364C7.32338 20.0518 9.61256 21 11.9995 21C12.3973 21 12.7789 20.842 13.0602 20.5607C13.3415 20.2794 13.4995 19.8978 13.4995 19.5C13.4995 19.11 13.3495 18.76 13.1095 18.5C12.8795 18.23 12.7295 17.88 12.7295 17.5C12.7295 17.1022 12.8875 16.7206 13.1689 16.4393C13.4502 16.158 13.8317 16 14.2295 16H15.9995C17.3256 16 18.5974 15.4732 19.535 14.5355C20.4727 13.5979 20.9995 12.3261 20.9995 11C20.9995 6.58 16.9695 3 11.9995 3Z'
      fill='currentColor'
    />
  </SVG>
)
