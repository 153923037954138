import { MutableRefObject } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { FormikProps } from 'formik'

import { FormSection } from './form-section'
import { CustomConnectionField } from '../fields/custom'
import { GroupHeading } from '../../components'
import { DetailValue } from '../../../node-components'
import { SuperiorErrorMessage, SuperiorField, SuperiorFieldCreate } from 'components/specific/superior-field'
import { FieldContainer, FieldTitle, Link } from 'components/generic'
import { DottedLineReportingFlatIcon } from 'components/icons'

import { getCustomFieldsValuesName } from '../../helpers'
import { DottedLineAdModalType, getIsSubscribed } from 'features/premium'
import { useChartData } from 'tree/providers'
import { CustomConnectionFieldValue, PersonDetail, PersonFormCapabilities, PersonFormValues } from 'tree/types'
import { useModal } from 'modal'
import { SETTINGS_CREATE_DOTTED_LINE_PATH } from 'routes'

type Props = {
  capabilities: PersonFormCapabilities
  formState: FormikProps<PersonFormValues>
  person: PersonDetail | undefined
  sectionRef: MutableRefObject<HTMLElement | null>
}

export const ConnectionsForm = ({ capabilities, formState, person, sectionRef }: Props) => {
  const { values, initialValues, setFieldValue } = formState
  const { id: chartId, subscription } = useChartData()

  const connectionFields = Object.values(values.customFields).filter(
    value => value.type === 'connection'
  ) as CustomConnectionFieldValue[]

  // Add connection field
  const history = useHistory()
  const isSubscribed = getIsSubscribed(subscription)
  const { open } = useModal()
  const handleAddConnection = () => {
    if (isSubscribed) {
      history.push(generatePath(SETTINGS_CREATE_DOTTED_LINE_PATH, { chartUuid: chartId }), {
        from: window.location.pathname,
      })
    } else {
      open<DottedLineAdModalType>('dottedLineAdModal')
    }
  }

  return (
    <FormSection ref={sectionRef}>
      <GroupHeading icon={<DottedLineReportingFlatIcon width='19px' height='100%' />} title='Connections' />

      {capabilities.canMove && (
        <FieldContainer>
          <FieldTitle title='Supervisor and department' />
          {person ? (
            <SuperiorField
              chartUuid={chartId}
              moveToChart={values.moveToChart}
              node={person}
              parentUuid={values.parentUuid}
              handleSetFieldValue={setFieldValue}
            />
          ) : (
            <SuperiorFieldCreate
              chartUuid={chartId}
              parentUuid={values.parentUuid}
              handleSetFieldValue={setFieldValue}
            />
          )}
          <SuperiorErrorMessage />
        </FieldContainer>
      )}

      {connectionFields.map(({ id, label }) => {
        const customConnectionField = initialValues.customFields[id] as CustomConnectionFieldValue
        return (
          <CustomConnectionField
            key={id}
            title={label}
            excludeId={person?.id}
            defaultValue={customConnectionField.values}
            onChange={val => setFieldValue(getCustomFieldsValuesName(id), [...val])}
          />
        )
      })}

      <FieldContainer>
        <FieldTitle title='2nd Supervisor' />
        <DetailValue>
          <Link as='div' onClick={handleAddConnection}>
            + Add another connection
          </Link>
        </DetailValue>
      </FieldContainer>
    </FormSection>
  )
}
