import { gql } from '@apollo/client'
import { subscriptionInfoFragment } from './subscription-info-fragment'

export const userFragment = gql`
  fragment User on User {
    id
    name
    email
    emailVerified
    provider
    subscription {
      ...SubscriptionInfo
    }
  }
  ${subscriptionInfoFragment}
`
