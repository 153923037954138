import { useState } from 'react'
import styled, { css } from 'styled-components'
import { useHistory, useRouteMatch } from 'react-router-dom'

import { SelectedDepartmentColor, SearchFieldMode } from './search-field'
import { AvatarCircleMiniature } from '../../specific/avatar-circle-miniature'
import { SetDepartmentSelectValues, HandleParentChange } from '../../specific/superior-field'
import { IconButton } from '../icon-button'
import { DepartmentIcon, FolderIcon, MagnifyingGlassWithPlus, ProfileIcon } from 'components/icons'

import { useEscapeKeypressListener } from 'hooks'
import { useTree } from 'tree/providers'
import { getNodeColorPallete } from 'helpers'
import { spaces } from 'theme'

import {
  NodeSearchDataFragmentFragment,
  NodeSearchDataFragment_Person_Fragment,
  NodeSearchDataFragment_Department_Fragment,
  useSetShowUnassignedNodesSidebarMutation,
} from 'apollo/generated/graphql'

type SearchFieldResultsProps = {
  mode: SearchFieldMode
  searchResults: NodeSearchDataFragmentFragment[]
  isSearching: boolean
  searchValue: string
  searchUuidValue: string
  handleSetSearchValue: (searchValue: string) => void
  handleSetSearchUuidValue: (searchUuidValue: string) => void
  handleSetShouldDoSearch: (shouldDoSearch: boolean) => void
  handleSetIsInputFocused: (isInputFocused: boolean) => void
  handleSetPersonUuid?: (personUuid: string) => void
  handleSetSelectedDepartmentColor: (selectedResultType: SelectedDepartmentColor) => void
  handleResetComponentWithBlur: () => void
  handleSetDepartmentSelectValues?: SetDepartmentSelectValues
  onParentChange?: HandleParentChange
}

export const SearchFieldResults = ({
  mode,
  searchResults,
  isSearching,
  searchValue,
  searchUuidValue,
  handleSetSearchValue,
  handleSetSearchUuidValue,
  handleSetShouldDoSearch,
  handleSetIsInputFocused,
  handleSetPersonUuid,
  handleSetSelectedDepartmentColor,
  handleResetComponentWithBlur,
  handleSetDepartmentSelectValues,
  onParentChange,
}: SearchFieldResultsProps) => {
  const match = useRouteMatch()
  const history = useHistory()
  const { zoomTo } = useTree()
  useEscapeKeypressListener(() => handleResetComponentWithBlur())

  const [isFindNodeBtnHovered, setIsFindNodeBtnHovered] = useState(false)
  const [setShowDirectory] = useSetShowUnassignedNodesSidebarMutation()
  const handleOpenDirectory = () => setShowDirectory({ variables: { showUnassignedNodesSidebar: true } })

  const employeeResults = searchResults.filter(
    node => node?.type === 'employee'
  ) as NodeSearchDataFragment_Person_Fragment[]
  const departmentResults = searchResults.filter(
    node => node?.type === 'department'
  ) as NodeSearchDataFragment_Department_Fragment[]

  const findSuperiorPerson = (parentNodes: any) => {
    return [...parentNodes].reverse().find(node => node.__typename === 'Person')
  }
  const findSuperiorDepartments = (parentNodes: NodeSearchDataFragmentFragment['parentNodes']) => {
    const filteredParentNodes = [...parentNodes].filter(node => node.__typename === 'Department')
    return filteredParentNodes.slice(filteredParentNodes.length - 2, filteredParentNodes.length)
  }

  const handleDiscoverNode = (node: NodeSearchDataFragmentFragment) => {
    node.unassigned ? handleOpenDirectory() : zoomTo(node)
  }

  const handleResultSelect = (node: NodeSearchDataFragmentFragment) => {
    handleSetIsInputFocused(false)
    handleSetShouldDoSearch(false)
    handleSetSelectedDepartmentColor(null)
    if (node.__typename === 'Person') {
      handleSetSearchValue(node.name)
      handleSetSearchUuidValue(node.uuid)
      handleSetPersonUuid && handleSetPersonUuid(node.uuid)
      handleSetDepartmentSelectValues &&
        handleSetDepartmentSelectValues({
          uuid: '', // null = 'Direct report'
          name: 'Select department',
          departmentColor: null,
          isDropdownListActive: true,
        })
      onParentChange && onParentChange('')
    } else if (node.__typename === 'Department') {
      const departmentSuperiorPerson = node.parentNodes
        .slice()
        .reverse()
        .find((node: any) => node.__typename === 'Person')
      if (departmentSuperiorPerson) {
        // Department has Superior Person
        handleSetSearchValue(departmentSuperiorPerson.name)
        handleSetSearchUuidValue(departmentSuperiorPerson.uuid)
        handleSetPersonUuid && handleSetPersonUuid(departmentSuperiorPerson.uuid)
        handleSetDepartmentSelectValues &&
          handleSetDepartmentSelectValues({
            uuid: node.uuid,
            name: node.name,
            departmentColor: node.color,
            isDropdownListActive: false,
          })
        onParentChange && onParentChange(node.uuid)
      } else {
        // Department with no No Superior Person
        handleSetSearchValue(node.name)
        handleSetSearchUuidValue(node.uuid)
        handleSetSelectedDepartmentColor(node.color)
        onParentChange && onParentChange(node.uuid)
      }
    }
  }

  const handleOpenNodeSidePanel = (node: NodeSearchDataFragmentFragment) => {
    history.push(`${match.url}/${node.type}/${node.uuid}`)
    handleResetComponentWithBlur()
  }

  return (
    <ListWrap mode={mode} data-testid='search-result-list'>
      {searchValue.length < 2 ? (
        <BasicText>You need to enter at least 2 characters</BasicText>
      ) : isSearching ? (
        <BasicText>Searching ...</BasicText>
      ) : searchResults.length > 0 ? (
        <>
          {departmentResults.map(node => {
            const nodeColor = getNodeColorPallete(node.color).value
            return (
              <ResultItem
                key={node.uuid}
                onMouseDown={e => e.preventDefault()}
                isActive={searchUuidValue === node.uuid}
                mode={mode}
                isHoverDisabled={isFindNodeBtnHovered}
                onClick={() => (mode === 'superiorField' ? handleResultSelect(node) : handleOpenNodeSidePanel(node))}
              >
                <AvatarCircleMiniature fallbackIcon={<StyledDepartmentIcon />} spacing={{ mr: spaces.m }} />
                <Content>
                  <NameWrap>
                    {findSuperiorDepartments(node.parentNodes).map((parentNode: any) => {
                      const parentNodeColor = getNodeColorPallete(parentNode.color).value
                      return (
                        <DepartmentName key={parentNode.uuid}>
                          <DepartmentBullet color={parentNodeColor} />
                          {parentNode.name}
                        </DepartmentName>
                      )
                    })}
                    <DepartmentName>
                      <DepartmentBullet color={nodeColor} />
                      {node.name}
                    </DepartmentName>
                  </NameWrap>
                  <Description>{findSuperiorPerson(node.parentNodes)?.name}</Description>
                </Content>
                {mode !== 'superiorField' && (
                  <IconButton
                    size='small'
                    type='secondary'
                    spacing={{ mx: spaces.s }}
                    onClick={e => {
                      e.stopPropagation()
                      handleDiscoverNode(node)
                      handleResetComponentWithBlur()
                    }}
                    onMouseEnter={() => setIsFindNodeBtnHovered(true)}
                    onMouseLeave={() => setIsFindNodeBtnHovered(false)}
                  >
                    {node.unassigned ? <StyledFolderIcon /> : <MagnifyingGlassWithPlus />}
                  </IconButton>
                )}
              </ResultItem>
            )
          })}
          {employeeResults.map(node => (
            <ResultItem
              key={node.uuid}
              onMouseDown={e => e.preventDefault()}
              isActive={searchUuidValue === node.uuid}
              mode={mode}
              isHoverDisabled={isFindNodeBtnHovered}
              onClick={() => (mode === 'superiorField' ? handleResultSelect(node) : handleOpenNodeSidePanel(node))}
            >
              <AvatarCircleMiniature
                src={node.image}
                fallbackIcon={<StyledEmployeeIcon />}
                spacing={{ mr: spaces.m }}
              />
              <Content>
                <NameWrap>
                  <DepartmentName>{node.name}</DepartmentName>
                </NameWrap>
                <Description>{node.position}</Description>
              </Content>
              {mode !== 'superiorField' && (
                <IconButton
                  size='small'
                  type='secondary'
                  spacing={{ mx: spaces.s }}
                  onClick={e => {
                    e.stopPropagation()
                    handleDiscoverNode(node)
                    handleResetComponentWithBlur()
                  }}
                  onMouseEnter={() => setIsFindNodeBtnHovered(true)}
                  onMouseLeave={() => setIsFindNodeBtnHovered(false)}
                >
                  {node.unassigned ? <StyledFolderIcon /> : <MagnifyingGlassWithPlus />}
                </IconButton>
              )}
            </ResultItem>
          ))}
        </>
      ) : (
        <BasicText>No results</BasicText>
      )}
    </ListWrap>
  )
}

const listWrapIndependentCSS = css`
  position: absolute;
  top: 100%;
  left: 0;
  max-height: calc(100vh - 64px);

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    position: fixed;
    top: unset;
    left: 50%;
    width: 92%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    transform: translate(-50%, 34px);
  }
`

const listWrapTopNavigationCSS = css`
  ${listWrapIndependentCSS}
  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    top: ${props => props.theme.sizes.topNavigationMobile};
    width: 100%;
    transform: translate(-50%, 0);
    border-radius: 0;
    max-height: calc(100vh - ${props => props.theme.sizes.topNavigationMobile});
  }
`

const ListWrap = styled.div<{ mode: SearchFieldMode }>`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin-top: -1px;
  background: ${props => props.theme.colors.white};
  box-shadow: 0px 3px 12px rgba(69, 90, 100, 0.3);
  overflow: auto;
  color: ${props => props.theme.colors.grey};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  z-index: 2;

  ${props => props.mode === 'independent' && listWrapIndependentCSS}
  ${props => props.mode === 'topNavigation' && listWrapTopNavigationCSS}
`

const BasicText = styled.div`
  color: ${props => props.theme.colors.greyMediumLight};
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`

const ResultItemSuperiorField = css`
  padding: 4px;
`
const ResultItem = styled.div<{ mode: SearchFieldMode; isActive: boolean; isHoverDisabled: boolean }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 10px;
  cursor: pointer;
  z-index: 1;
  transition: ${props => props.theme.transitions.extraFastEase};

  ${props => !props.isHoverDisabled && props.theme.animations.backgroundShapeFill}

  &:last-of-type {
    margin-bottom: 0;
  }

  ${props => props.mode === 'superiorField' && ResultItemSuperiorField}

  ${props =>
    props.isActive &&
    css`
      color: ${props => props.theme.colors.white};
      background: ${props => props.theme.colors.purple};
      cursor: default;
      pointer-events: none;

      ${Description} {
        color: ${props => props.theme.colors.greyExtraLight};
      }
    `}
`

const StyledDepartmentIcon = styled(DepartmentIcon)`
  width: 20px;
  height: auto;
  color: ${props => props.theme.colors.black};
`
const StyledEmployeeIcon = styled(ProfileIcon)`
  width: 16px;
  height: auto;
  color: ${props => props.theme.colors.border};
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const NameWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const DepartmentName = styled.div`
  display: inline-flex;

  &:after {
    content: '>';
    margin-right: 4px;
    margin-left: 4px;
    color: ${props => props.theme.colors.greyMediumLight};
  }

  &:last-of-type&:after {
    display: none;
  }
`

const DepartmentBullet = styled.div<{ color: string }>`
  min-width: 6px;
  min-height: 6px;
  width: 6px;
  height: 6px;
  margin-top: 5px;
  margin-right: 4px;
  border-radius: 50%;
  background: ${props => props.color};
`

const Description = styled.div`
  margin-top: 3px;
  color: ${props => props.theme.colors.greyMediumLight};
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
`

const StyledFolderIcon = styled(FolderIcon)`
  width: 14px;
  height: auto;
`
