import { useState } from 'react'
import styled from 'styled-components'
import { generatePath } from 'react-router-dom'
import { useAuth } from 'auth'

import { Deletion, InfoContainer, StatusContainer } from './components'
import { CollaboratorRoleSelect } from 'components/specific'
import { IconButton } from 'components/generic'
import { TrashCanIcon } from 'components/icons'

import { useOnRoleChange, useDeleteCollaborator, useResendCollaboratorInvitation } from '../hooks'
import { useCopyToClipboard } from 'hooks'
import { useChartPermission } from 'tree/hooks'
import { useChartData } from 'tree/providers'
import { BASE_URL, INVITE_PATH } from 'routes'
import { normalizeString } from 'helpers'

import { CollaboratorFragmentFragment, CollaboratorRole } from 'apollo/generated/graphql'

export const EditCollaboratorForm = ({
  uuid,
  role,
  email,
  status,
  chartUuid,
  allowedRoles,
}: CollaboratorFragmentFragment) => {
  const [confirmDeletion, setConfirmDeletion] = useState(false)

  const { copyToClipboard } = useCopyToClipboard()
  const onRoleChange = useOnRoleChange()
  const { deleteCollaborator, isDeleting } = useDeleteCollaborator()
  const { resendCollaboratorInvitation, isResending } = useResendCollaboratorInvitation()

  const { user } = useAuth()
  const isLoggedUser = normalizeString(user.email) === normalizeString(email)
  const { userPermission } = useChartData()
  const loggedUserChartRole = userPermission?.role as CollaboratorRole
  const loggedUserHasLowerRank = loggedUserChartRole === 'editor' && role === 'admin'
  const { canUpdateCollaborator, canDeleteCollaborator } = useChartPermission()

  const showDeleteButton = canDeleteCollaborator && !isLoggedUser && !loggedUserHasLowerRank
  const isRoleSelectDisabled = !canUpdateCollaborator || isLoggedUser

  return (
    <EditCollaboratorFormInner>
      {confirmDeletion ? (
        <Deletion
          email={email}
          isDeleting={isDeleting}
          onCancel={() => setConfirmDeletion(false)}
          onDelete={() => deleteCollaborator({ chartUuid, collaboratorUuid: uuid, role, email })}
        />
      ) : (
        <>
          <InfoContainer>
            <EmailText>{email}</EmailText>
            <StatusContainer
              isResending={isResending}
              status={status}
              onCopy={() => copyToClipboard(`${BASE_URL}${generatePath(INVITE_PATH, { code: uuid })}`)}
              onSendNewInv={() => resendCollaboratorInvitation(chartUuid, uuid)}
            />
          </InfoContainer>

          <ActionsContainer>
            <CollaboratorRoleSelect
              value={role}
              allowedRoles={allowedRoles}
              disabled={isRoleSelectDisabled}
              onRoleChange={role => onRoleChange(chartUuid, uuid, role)}
            />
            <DeleteButtonWrap>
              {showDeleteButton && (
                <IconButton
                  size='extraBig'
                  onClick={() => setConfirmDeletion(true)}
                  data-testid='button-delete-collaborator-trigger'
                >
                  <TrashCanIcon />
                </IconButton>
              )}
            </DeleteButtonWrap>
          </ActionsContainer>
        </>
      )}
    </EditCollaboratorFormInner>
  )
}

const EditCollaboratorFormInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.s} 0;
  padding-right: ${props => props.theme.spaces.m};
  box-shadow: inset 0px -1px 0px ${props => props.theme.colors.greyExtraLight};
  font-size: 13px;
  line-height: 20px;

  &:last-of-type {
    box-shadow: none;
  }

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    flex-wrap: nowrap;
  }

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    margin: ${props => props.theme.spaces.l} 0;
  }
`

const EmailText = styled.div`
  min-width: 0;
  margin: 4px ${props => props.theme.spaces.m};
  margin-left: 0;
  text-overflow: ellipsis;
  user-select: all;
  overflow: hidden;
`

const ActionsContainer = styled.div`
  flex-shrink: 0;
  display: flex;

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    margin-left: auto;
  }
`

const DeleteButtonWrap = styled.div`
  display: flex;
  align-items: center;
  width: 40px;
  margin-left: ${props => props.theme.spaces.l};
`
