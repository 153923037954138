import { FormikHelpers } from 'formik'
import { toast as toastify, TypeOptions, ToastPosition } from 'react-toastify'

import { extractErrorCode, ErrorCode, formErrorHandler } from './helper'
import analytics from 'analytics'
import { TrackKeyName } from 'types'

const getToastMessage = (message: string, causeOfError: string) => {
  if (!causeOfError) {
    return message
  }

  return `${message}. ${causeOfError}`
}

type HandleErrorValidationParameters<T> = {
  track?: {
    message: TrackKeyName
    values: Record<string, any>
  }
  toast?: {
    message: string
    type?: TypeOptions
    position?: ToastPosition
  }
  error?: any
  actions?: FormikHelpers<T>
}

export const handleErrorValidation = <T>({
  track,
  toast,
  error,
  actions,
}: HandleErrorValidationParameters<T>): ErrorCode | undefined => {
  let causeOfError = ''
  if (error && actions) {
    const code = extractErrorCode(error)

    switch (code) {
      case 'BAD_USER_INPUT':
        causeOfError = 'Incorrect input values.'
        break

      case 'GSUITE_INSUFFICIENT_PERMISSIONS':
        causeOfError = 'Insufficient permissions.'
        break

      case 'COLLABORATOR_NOT_FOUND':
        causeOfError = 'Collaborator not found.'
        break

      case 'COLLABORATOR_EXISTS':
        causeOfError = 'Collaborator already exists.'
        break

      case 'PERSON_HAS_CHILDREN':
        causeOfError = 'Person has employees below.'
        break

      case undefined: // network error which is handled in apollo/index.tsx
        causeOfError = ''
        break

      default:
        causeOfError = 'Unknown error.'
        break
    }
  }

  if (toast?.message) {
    const toastMessage = getToastMessage(toast.message, causeOfError)
    const position = toast?.position
    toastify(toastMessage, {
      type: toast?.type || 'error',
      ...(position && { position }),
    })
  }

  if (track?.message && track?.values) {
    analytics.track(track.message, track.values)
  }

  if (error && actions) return formErrorHandler(error, actions)
}
