import { SVG, SVGProps } from './SVG'

export const EditIcon = (props: SVGProps) => (
  <SVG width='14' height='14' viewBox='0 0 14 14' fill='none' {...props}>
    <path
      d='M0.25 10.9375V13.75H3.0625L11.3575 5.45498L8.545 2.64248L0.25 10.9375ZM13.5325 3.27998C13.825 2.98748 13.825 2.51498 13.5325 2.22248L11.7775 0.467483C11.485 0.174983 11.0125 0.174983 10.72 0.467483L9.3475 1.83998L12.16 4.65248L13.5325 3.27998Z'
      fill='currentColor'
    />
  </SVG>
)
