import { useState } from 'react'
import styled from 'styled-components'

import { FieldContainer, FieldTitle, FieldTitleProps } from './components'
import { Input } from '../input'
import { Button } from '../button'

import { useCopyToClipboard } from 'hooks'

type Props = {
  title: FieldTitleProps
  value: string
  className?: string
  onCopy?: () => void
}

export const FieldCopyToClipboard = ({ title, value, className, onCopy }: Props) => {
  const [linkCopied, setLinkCopied] = useState(false)

  const { copyToClipboard } = useCopyToClipboard()

  const handleCopy = () => {
    setLinkCopied(true)
    copyToClipboard(value)
    onCopy && onCopy()
  }

  return (
    <FieldContainer>
      <FieldTitle {...title} />
      <FieldsWrap className={className}>
        <StyledInput type='text' value={value} readOnly onChange={() => null} />
        <StyledButton onClick={handleCopy} data-testid='button-copy-link-in-chart-share-modal'>
          {linkCopied ? 'Copied!' : 'Copy'}
        </StyledButton>
      </FieldsWrap>
    </FieldContainer>
  )
}

const FieldsWrap = styled.div`
  display: flex;
`

const StyledInput = styled(Input)`
  padding: 0 12px;
  background: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.purple};
  color: ${props => props.theme.colors.dark};
  cursor: default;

  &:focus {
    outline: none;
  }
`

const StyledButton = styled(Button)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`
