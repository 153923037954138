import { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'

import { exportNodes as exportNodesQuery } from 'apollo/query'
import { ExportNodesQuery, ExportNodesQueryVariables } from 'apollo/generated/graphql'

export const ExportScreen = () => {
  const {
    params: { chartUuid, format },
  } = useRouteMatch<{ chartUuid: string; format: string }>()
  const apolloClient = useApolloClient()
  const [state, setState] = useState<string>('loading')
  useEffect(() => {
    apolloClient
      .query<ExportNodesQuery, ExportNodesQueryVariables>({
        query: exportNodesQuery,
        fetchPolicy: 'no-cache',
        variables: { chartUuid, config: { format } },
      })
      .then(
        ({
          data: {
            exportNodes: { downloadUrl },
          },
        }) => {
          const link = document.createElement('a')
          link.setAttribute('href', downloadUrl)
          link.setAttribute('download', 'test.png')
          link.style.visibility = 'hidden'
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          setState('downloaded')
        }
      )
      .catch(err => {
        setState(`error: ${err.message}`)
      })
  }, [])

  return <>{state}</>
}
