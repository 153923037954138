import { FormikProps } from 'formik'

import { CustomDatePickerField, CustomSelectField, CustomTextField } from './custom'

import { getCustomFieldsValueName, getCustomFieldsOptionsName } from '../../helpers'
import {
  CustomFieldValueWithoutConnection,
  CustomSelectOption,
  DropdownOptionValue,
  PersonFormValues,
} from 'tree/types'

type Params = {
  field: CustomFieldValueWithoutConnection
  formState: FormikProps<PersonFormValues>
}

export const customFieldFactory = ({ field, formState }: Params) => {
  const { id, label, ...customField } = field
  const name = getCustomFieldsValueName(id)

  const { handleChange, handleBlur, setFieldValue } = formState

  switch (customField.type) {
    case 'text':
      return (
        <CustomTextField
          key={id}
          disabled={false}
          name={name}
          title={label}
          value={customField.value}
          onChange={handleChange}
          onBlur={handleBlur}
          onClear={() => setFieldValue(name, '')}
        />
      )
    case 'single-date':
      return (
        <CustomDatePickerField
          key={id}
          title={label}
          inputName={name}
          value={customField.value}
          onChange={handleChange}
          onDayPick={value => setFieldValue(name, value)}
          onBlur={handleBlur}
          onClear={() => setFieldValue(name, '')}
        />
      )
    case 'single-select':
      return (
        <CustomSelectField
          key={id}
          name={name}
          label={label}
          options={customField.options}
          selectedOptions={customField.selectedOption ? [customField.selectedOption] : []}
          type='single-select'
          onChange={singleSelectOption => {
            const option = singleSelectOption as CustomSelectOption
            const newOptions: DropdownOptionValue[] = [...customField.options].map(oldOption => ({
              ...oldOption,
              isSelected: oldOption.id === option.id,
            }))
            setFieldValue(getCustomFieldsOptionsName(id), newOptions)
          }}
          onBlur={handleBlur}
          onClear={() => {
            const unselectedOptions = customField.options.map(option => ({ ...option, isSelected: false }))
            setFieldValue(getCustomFieldsOptionsName(id), unselectedOptions)
          }}
        />
      )
    default:
      console.error(`Custom field is not supported and rendered into employee form. Custom field object: `, customField)
      return null
  }
}
