import { useApolloClient } from '@apollo/client'

import { ChartExportFileFormat } from '../../types'
import { COMPANY_UUID } from 'consts'

import { exportNodes as exportNodesQuery } from 'apollo/query'
import { Chart, ExportNodesQuery, ExportNodesQueryVariables } from 'apollo/generated/graphql'

type ExportChartRequestParams = {
  chartId: Chart['id']
  format: ChartExportFileFormat
  compact: boolean
  rootId?: string
}

export const useExportChartRequest = () => {
  const apolloClient = useApolloClient()

  const exportChartRequest = async ({ chartId, compact, format, rootId }: ExportChartRequestParams) => {
    const newRootId = rootId === COMPANY_UUID ? undefined : rootId
    return apolloClient.query<ExportNodesQuery, ExportNodesQueryVariables>({
      query: exportNodesQuery,
      fetchPolicy: 'no-cache',
      variables: { chartUuid: chartId, config: { format, compact, rootUuid: newRootId } },
    })
  }

  return { exportChartRequest }
}
