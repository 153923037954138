import { gql } from '@apollo/client'

export const teamMembersFragment = gql`
  fragment TeamMembersFragment on Person {
    id
    uuid
    givenName
    familyName
  }
`
