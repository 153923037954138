export const convertToInternationalPhoneNumber = (phoneNumber: string): string | null => {
  // Remove all non-numeric characters
  const internationalPhoneNumber = phoneNumber.replace(/\D/g, '')

  if (internationalPhoneNumber.length === 0) {
    console.error('Invalid phone number format', { phoneNumber })
    return null
  }

  return internationalPhoneNumber
}
