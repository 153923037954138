import { useEffect, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { SubscriptionOptionCard } from './subscription-option-card'
import { FlexBox } from 'components/generic'

import { useChartId } from 'tree/providers'
import { useGoToPremiumScreen } from 'features/premium'
import { SubscriptionCardEntryCollection, getSubscriptionCardEntries } from 'contentful-cms'
import { CHART_EDIT_PATH } from 'routes'

export const SubscriptionCards = () => {
  const [subscriptionCards, setSubscriptionCards] = useState<SubscriptionCardEntryCollection>()

  const chartId = useChartId()
  const history = useHistory()

  const goToChartEdit = () => history.push(generatePath(CHART_EDIT_PATH, { chartUuid: chartId }))
  const { goToPremiumScreen } = useGoToPremiumScreen()

  // Fetch Subscription Cards
  useEffect(() => {
    getSubscriptionCardEntries()
      ?.then(response => {
        const hasItems = Boolean(response?.items.length)

        // Fallback
        if (!hasItems) {
          return goToChartEdit()
        }

        // Sort from lowest to highest price
        response.items.sort((a, b) => a.fields.pricePerMonth - b.fields.pricePerMonth)

        setSubscriptionCards(response)
      })
      .catch(error => {
        console.error('Could not fetch Subscription Cards from contentful cms. Error: ', { error })
        goToChartEdit()
      })
  }, [])

  return (
    <FlexBox $isDirectionColumn $gap='l'>
      {subscriptionCards?.items.map(({ fields }) => {
        const {
          plan = 'pro',
          subscriptionTitle,
          subscriptionTitleBadge,
          employeeLimit,
          pricePerMonth,
          googleWorkspaceSync,
          support,
          usersOrEditors,
          isCardHighlighted,
          coupon,
          couponBadgeLabel,
        } = fields

        const price = pricePerMonth === 0 ? 'Free' : `$${pricePerMonth}*`
        const onClick = pricePerMonth
          ? () => goToPremiumScreen({ plan, billingPeriod: 'monthly', coupon })
          : goToChartEdit

        return (
          <SubscriptionOptionCard
            key={subscriptionTitle}
            title={subscriptionTitle}
            subscriptionTitleBadge={subscriptionTitleBadge}
            employeeLimit={employeeLimit}
            price={price}
            couponBadgeLabel={couponBadgeLabel}
            GWSync={googleWorkspaceSync}
            support={support}
            usersOrEditors={usersOrEditors}
            isActive={Boolean(isCardHighlighted)}
            onClick={onClick}
          />
        )
      })}
      <Explanation>*excluding tax</Explanation>
    </FlexBox>
  )
}

const Explanation = styled.div`
  color: ${props => props.theme.colors.greyMedium};
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
`
