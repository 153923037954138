import { ApolloLink } from '@apollo/client'

import { errorLink } from './error-link'
import { useTokenLink } from './use-token-link'
import { httpUploadLink } from './http-upload-link'

export const useLink = () => {
  const tokenLink = useTokenLink()
  return ApolloLink.from([errorLink, tokenLink, httpUploadLink])
}
