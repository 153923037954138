import { NodeTypename, Person } from 'tree/types'

import { PersonDetailDataFragmentFragment, DepartmentDetailDataFragmentFragment } from 'apollo/generated/graphql'

type Node = Record<string, unknown> & Pick<Person, 'parentNodes'>

const findSuperiorInNode = (node: Node, nodeTypename: NodeTypename) => {
  return [...node.parentNodes].reverse().find(node => node.__typename === nodeTypename)
}

export const findSuperiorPerson = (node: Node) => {
  return findSuperiorInNode(node, 'Person') as PersonDetailDataFragmentFragment | undefined
}

export const findSuperiorDepartment = (node: Node) => {
  return findSuperiorInNode(node, 'Department') as DepartmentDetailDataFragmentFragment | undefined
}
