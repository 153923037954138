import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  width: 100%;
  & > div {
    margin: 0 ${props => props.theme.spaces.m};
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    & > div {
      margin: 0;
    }
  }
`
