import styled, { css } from 'styled-components'

import { addTransparencyToColor } from 'helpers'

export const CollaboratorRoleItem = styled.div<{
  $isSelected: boolean
}>`
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 6px 7px;
  color: ${props => props.theme.colors.dark};
  font-size: 13px;
  line-height: 15px;
  text-transform: capitalize;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.colors.greyExtraLight};
  }

  ${props =>
    props.$isSelected &&
    css`
      background: ${props => addTransparencyToColor(props.theme.colors.purpleVeryLight, 0.2)};
      color: ${props => props.theme.colors.purple};
      cursor: default;
      pointer-events: none;
    `}
`
