import { RefObject, createContext, useContext, ReactNode } from 'react'

type Props = {
  panelRef: RefObject<HTMLDivElement>
  children: ReactNode
}
const ChartLeftSidePanelRefContext = createContext<RefObject<HTMLDivElement> | null>(null)

export const ChartLeftSidePanelRefProvider = ({ panelRef, children }: Props) => (
  <ChartLeftSidePanelRefContext.Provider value={panelRef}>{children}</ChartLeftSidePanelRefContext.Provider>
)

export const useChartLeftSidePanelRef = () => useContext(ChartLeftSidePanelRefContext)
