import styled from 'styled-components'
import { generatePath, useRouteMatch } from 'react-router-dom'

import { SHARED_LINK_PATH } from 'routes'
import { LoginSignupButtonsGroup } from 'components/specific'
import { FlexBox } from 'components/generic'

import { LoginImage } from './images/login-image'

export const ChartLoginScreen = () => {
  const { params } = useRouteMatch<never>()

  return (
    <FlexBox $isDirectionColumn $isCentered>
      <LoginImage />
      <FlexBox $spacing={{ mt: '30px' }}>
        <Text>Log in or create account to access this Org Chart.</Text>
      </FlexBox>

      <FlexBox $isDirectionColumn>
        <LoginSignupButtonsGroup redirectPath={generatePath(SHARED_LINK_PATH, params)} />
      </FlexBox>
    </FlexBox>
  )
}

const Text = styled.div`
  color: ${props => props.theme.colors.greyDark};
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  text-align: center;
  margin-bottom: ${props => props.theme.spaces.xxl};
`
