import styled from 'styled-components'

import { Modal, Button } from 'components/generic'

import { ModalTypeFactory, ModalComponentProps } from 'modal'
import { spaces } from 'theme'

type GWorkspaceInsuffPermModalProps = ModalComponentProps
export type GsuiteInsuffPermModalType = ModalTypeFactory<'gWorkspaceInsuffPermModal', GWorkspaceInsuffPermModalProps>

export const GsuiteInsuffPermModal = ({ onClose }: GWorkspaceInsuffPermModalProps) => (
  <Modal title={{ text: 'Org Chart not created' }} onClose={onClose}>
    <Text>
      Dear user,
      <br />
      <br />
      unfortunately, we are unable to create your Org Chart at this time. This is likely due to a problem with your
      company&apos;s security settings. However, we do not have access to the specific details of your security settings
      and are unable to provide any further information.
      <br />
      <br />
      Also you may have used either a personal account or an account without administrator privileges.
      <br />
      <br />
      We apologize for any inconvenience this may cause and encourage you to contact your IT department or security team
      for assistance.
    </Text>

    <Button spacing={{ mt: spaces.l, ml: 'auto' }} onClick={onClose} data-testid='button-try-again'>
      Try again
    </Button>
  </Modal>
)

const Text = styled.div`
  margin-bottom: 10px;
  color: ${props => props.theme.colors.grey};
  font-size: 13px;
  line-height: 20px;
`
