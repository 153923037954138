import { SVG, SVGProps } from './SVG'

export const OpenBookIcon = (props: SVGProps) => (
  <SVG width='18' height='14' viewBox='0 0 18 14' fill='none' {...props}>
    <path
      d='M9.81818 10.6773C10.4182 10.3909 11.0216 10.1761 11.6284 10.033C12.2352 9.88977 12.8591 9.81818 13.5 9.81818C13.9909 9.81818 14.4716 9.85909 14.942 9.94091C15.4125 10.0227 15.8864 10.1455 16.3636 10.3091V2.20909C15.9136 2.01818 15.4466 1.875 14.9625 1.77955C14.4784 1.68409 13.9909 1.63636 13.5 1.63636C12.8591 1.63636 12.225 1.71818 11.5977 1.88182C10.9705 2.04545 10.3773 2.29091 9.81818 2.61818V10.6773ZM9 13.0909C8.34545 12.5727 7.63636 12.1705 6.87273 11.8841C6.10909 11.5977 5.31818 11.4545 4.5 11.4545C3.92727 11.4545 3.36477 11.5295 2.8125 11.6795C2.26023 11.8295 1.73182 12.0409 1.22727 12.3136C0.940909 12.4636 0.664773 12.4568 0.398864 12.2932C0.132955 12.1295 0 11.8909 0 11.5773V1.71818C0 1.56818 0.0375 1.425 0.1125 1.28864C0.1875 1.15227 0.3 1.05 0.45 0.981818C1.09091 0.668182 1.74886 0.426136 2.42386 0.255682C3.09886 0.0852273 3.79091 0 4.5 0C5.29091 0 6.06477 0.102273 6.82159 0.306818C7.57841 0.511364 8.30455 0.818182 9 1.22727C9.69545 0.818182 10.4216 0.511364 11.1784 0.306818C11.9352 0.102273 12.7091 0 13.5 0C14.2091 0 14.9011 0.0852273 15.5761 0.255682C16.2511 0.426136 16.9091 0.668182 17.55 0.981818C17.7 1.05 17.8125 1.15227 17.8875 1.28864C17.9625 1.425 18 1.56818 18 1.71818V11.5773C18 11.8909 17.867 12.1295 17.6011 12.2932C17.3352 12.4568 17.0591 12.4636 16.7727 12.3136C16.2682 12.0409 15.7398 11.8295 15.1875 11.6795C14.6352 11.5295 14.0727 11.4545 13.5 11.4545C12.6818 11.4545 11.8909 11.5977 11.1273 11.8841C10.3636 12.1705 9.65454 12.5727 9 13.0909Z'
      fill='currentColor'
    />
  </SVG>
)
