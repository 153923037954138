import { ConnectionLine } from './connection-line'

import { useWatchConnectionLines } from 'tree/hooks'

export const ConnectionLinesRenderer = () => {
  const { connectionLinesRefs } = useWatchConnectionLines()
  return (
    <section id='connection-lines-renderer'>
      {Object.keys(connectionLinesRefs.current).map(id => (
        <ConnectionLine key={id} {...connectionLinesRefs.current[id]} />
      ))}
    </section>
  )
}
