import styled from 'styled-components'
import { ErrorMessage } from 'formik'

import { colors } from 'theme'

export const SuperiorErrorMessage = () => (
  <ErrorMessage name='parentUuid'>
    {message => message && <ErrorText color={colors.red}>{message}</ErrorText>}
  </ErrorMessage>
)

const ErrorText = styled.div`
  position: absolute;
  top: 58px;
  left: 0;
  width: calc(100% - 34px);
  max-width: calc(100% - 34px);
  color: ${props => props.theme.colors.red};
  font-size: 11px;
  line-height: 16px;
  user-select: none;
  pointer-events: none;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    margin-bottom: ${props => props.theme.spaces.xs};
    background: ${props => props.theme.colors.red};
  }
`
