import styled from 'styled-components'

import { TilButton } from 'components/generic'

import analytics from 'analytics'
import { INSIGHTS_TRACK } from 'analytics/constants'
import { useAuth } from 'auth'
import { useChartId } from 'tree/providers'
import { UNARIC_URL } from 'consts'

import wavesBig from 'assets/waves-big.svg'
import sociomapBanner from 'assets/sociomap-banner-full.png'

export const TilAdCalendar = () => {
  const { user } = useAuth()
  const chartUuid = useChartId()

  return (
    <Container>
      <Text>Overwhelmed with work?</Text>
      <SecondaryText>We are here to help</SecondaryText>
      <TilButton
        type='secondary'
        size='large'
        spacing={{ mb: 32 }}
        onClick={() => analytics.track(INSIGHTS_TRACK.joinUs, { chartUuid, user: user?.email, name: user?.name })}
        href={UNARIC_URL}
        target='_blank'
      >
        Join us
      </TilButton>
      <WavesImg src={wavesBig} />
      <SociomapBannerImg src={sociomapBanner} alt='Sociomap' />
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  padding-left: 80px;
  height: 316px;
  width: 100%;
  box-shadow: 0px 0px 6px rgba(38, 15, 63, 0.08);
  border-radius: 6px;
  background: ${props => props.theme.colors.purpleDark};

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 0;
    align-items: center;
    overflow: hidden;
  }
`

const Text = styled.div`
  margin-top: 60px;
  margin-bottom: 20px;
  max-width: 600px;
  font-weight: bold;
  font-size: 34px;
  line-height: 54px;
  letter-spacing: 0.25px;
  color: #f8f8fb;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    margin: 32px;
    font-size: 28px;
    line-height: 44px;
    z-index: 10;
  }
`

const SecondaryText = styled(Text)`
  font-size: 28px;
  line-height: 28px;
  margin-top: 0;
  font-weight: normal;
  margin-bottom: 42px;
  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    font-size: 24;
  }
`

const WavesImg = styled.img`
  position: absolute;
  left: 4em;
  bottom: 0;
  pointer-events: none;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    top: 0;
    bottom: none;
    transform: rotate(180deg);
    left: -50%;
  }
`

const SociomapBannerImg = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  user-select: none;

  @media (max-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    position: relative;
    max-width: 100%;
  }

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallDesktop}) {
    right: 5%;
  }
`
