import { useAuth } from 'auth'
import { useRouteMatch } from 'react-router-dom'

import { useIsOnPublicRoute } from 'tree/hooks'
import { useChartId, useChartData } from 'tree/providers'
import { INSIGHTS_PATH, CHART_EDIT_PATH, SETTINGS_PATH, SHARED_LINK_PATH } from 'routes'
import { getCanAccessSharing, getCanShowExportView, useGetCanAccessSettings } from 'helpers/viewCapabilities'
import { SHOW_INSIGHTS_IN_TOP_NAV } from 'screens/analytics/constants'

export const useShowComponents = () => {
  const { isAuthenticated } = useAuth()
  const { path } = useRouteMatch()
  const chartUuid = useChartId(true)
  const { getCanAccessSettings } = useGetCanAccessSettings()
  const isOnPublicRoute = useIsOnPublicRoute()
  const { capabilities: cap } = useChartData(true) || {}

  const showRegisterToApp = !isAuthenticated
  const showChartSelect = isAuthenticated
  const showChartButton = Boolean(!path.includes(CHART_EDIT_PATH) && !path.includes(SHARED_LINK_PATH) && chartUuid)
  const showSearchField = Boolean(!path.includes(SETTINGS_PATH) && !path.includes(INSIGHTS_PATH) && chartUuid)
  const showInsightsButton = Boolean(
    SHOW_INSIGHTS_IN_TOP_NAV && !path.includes(INSIGHTS_PATH) && cap && cap.canReadInsight
  )
  const showChartSettings = Boolean(cap && getCanAccessSettings(cap))
  const showChartShareButton = Boolean(cap && getCanAccessSharing(cap))
  const showChartExportButton = Boolean(cap && getCanShowExportView(cap))
  const showCustomFieldsButton = Boolean(cap?.canReadCustomField && !isOnPublicRoute)
  const showDuplicatesButton = Boolean(cap?.canClone)
  const showCommonControls = isAuthenticated

  return {
    showRegisterToApp,
    showChartSelect,
    showChartButton,
    showSearchField,
    showInsightsButton,
    showChartSettings,
    showChartShareButton,
    showChartExportButton,
    showCustomFieldsButton,
    showDuplicatesButton,
    showCommonControls,
  }
}
