import { ReactNode } from 'react'
import styled from 'styled-components'

import { Screen } from 'components/generic'
import { mainTheme } from 'theme'

type Props = {
  children: ReactNode
}

export const AuthLayout = ({ children }: Props) => <StyledScreen>{children}</StyledScreen>

const StyledScreen = styled(Screen)`
  background: ${mainTheme.colors.neutralSecondary};
  overflow: auto;
  flex: 1;
`
