import { useFormikContext } from 'formik'
import styled from 'styled-components'

import { FieldEmojiPicker, FieldInput, TilButton } from 'components/generic'

import { ChartFormValues } from 'tree/types'
import { spaces } from 'theme'

type Props = {
  loading: boolean
  onContinue: () => void
}

export const StepZero = ({ loading, onContinue }: Props) => {
  const { values, isSubmitting, isValid, handleChange, handleBlur, setFieldValue } = useFormikContext<ChartFormValues>()

  return (
    <>
      <FieldInput
        title={{ title: 'Org Chart name', size: 'big', required: true }}
        name='name'
        type='text'
        placeholder='e.g. Unaric, London Office'
        value={values.name || ''}
        optimized={false}
        required
        onChange={handleChange}
        onBlur={handleBlur}
        onClear={() => setFieldValue('name', '')}
      />

      <StyledFieldEmojiPicker
        title='Emoji'
        value={values.emoji || ''}
        disabled={false}
        onSelect={val => setFieldValue('emoji', val)}
      />
      <TilButton
        disabled={loading || isSubmitting || !isValid || !values.name}
        spacing={{ mt: spaces.xl }}
        onClick={onContinue}
      >
        Continue
      </TilButton>
    </>
  )
}

const StyledFieldEmojiPicker = styled(FieldEmojiPicker)`
  margin-bottom: 0;
`
