import { InfoIcon, WarningIcon } from 'components/icons'
import { ReactNode } from 'react'
import styled, { css } from 'styled-components'
import { colors, spaces } from 'theme'

import { Tag } from './tag'

type ContainerType = 'info' | 'warning' | 'error'
type Props = {
  type?: ContainerType
  tagText?: string
  children?: ReactNode
  withIcon?: boolean
  className?: string
}

export const InfoWarningErrorContainer = ({ type = 'info', tagText, children, withIcon, className }: Props) => (
  <Container type={type} className={className}>
    {withIcon && (type === 'info' ? <StyledInfoIcon /> : <StyledWarningIcon type={type} />)}
    {children}
    {tagText && <Tag text={tagText} />}
  </Container>
)

const Container = styled.div<{ type?: ContainerType }>`
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 10px;
  color: #2c2f46;
  grid-column: 1 / -1;
  align-items: center;
  min-height: 40px;
  height: auto;

  ${({ type }) =>
    type === 'info' &&
    css`
      border: 1px solid ${colors.darkBlue};
      background: rgba(57, 73, 171, 0.1);
    `}

  ${({ type }) =>
    type === 'warning' &&
    css`
      border: 1px solid ${colors.yellow};
      background: rgba(237, 202, 44, 0.1);
    `}

    ${({ type }) =>
    type === 'error' &&
    css`
      border: 1px solid ${colors.redLighter};
      background: rgba(237, 44, 44, 0.1);
    `};
`

const StyledInfoIcon = styled(InfoIcon)`
  color: ${colors.darkBlue};
  margin-right: ${spaces.m};
`

const StyledWarningIcon = styled(WarningIcon)<{ type: Omit<ContainerType, 'info'> }>`
  margin-right: ${spaces.m};

  ${({ type }) =>
    type === 'warning' &&
    css`
      color: ${colors.yellow};
    `}
  ${({ type }) =>
    type === 'error' &&
    css`
      color: ${colors.redLighter};
    `};
`
