import { gql } from '@apollo/client'
import { teamMembersFragment } from '../fragments'

export const teamMembers = gql`
  query teamMembers($chartKey: String!, $filter: TeamMembersFilter!) {
    teamMembers(chartKey: $chartKey, filter: $filter) {
      items {
        ...TeamMembersFragment
      }
    }
  }
  ${teamMembersFragment}
`
