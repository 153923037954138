import { SVG, SVGProps } from './SVG'

export const ArrowUpIcon = (props: SVGProps) => (
  <SVG width='20' height='20' viewBox='0 0 20 20' fill='none' {...props}>
    <path
      d='M10.8337 16.6667H9.16706V6.66666L4.58372 11.25L3.40039 10.0667L10.0004 3.46666L16.6004 10.0667L15.4171 11.25L10.8337 6.66666V16.6667Z'
      fill='currentColor'
    />
  </SVG>
)
