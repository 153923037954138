import { SVG, SVGProps } from './SVG'

export const FileIcon = (props: SVGProps) => (
  <SVG width='28' height='36' viewBox='0 0 28 36' fill='none' {...props}>
    <path
      d='M15.75 12.75V3.125L25.375 12.75H15.75ZM3.5 0.5C1.5575 0.5 0 2.0575 0 4V32C0 32.9283 0.368749 33.8185 1.02513 34.4749C1.6815 35.1312 2.57174 35.5 3.5 35.5H24.5C25.4283 35.5 26.3185 35.1312 26.9749 34.4749C27.6313 33.8185 28 32.9283 28 32V11L17.5 0.5H3.5Z'
      fill='currentColor'
    />
  </SVG>
)
