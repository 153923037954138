import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import { OrgchartFullLogo, OrgchartSymbolLogo } from 'components/icons'

import { applySpacingProps, SpacingProps } from 'helpers'
import { CHARTS_PATH } from 'routes'
import { useChartData } from 'tree/providers'

type Props = {
  spacing?: SpacingProps
  color?: string
  className?: string
}

export const Logo = ({ spacing, color, className }: Props) => {
  const history = useHistory()
  const chartData = useChartData(true)
  const logo = chartData?.logo

  return (
    <LogoWrap
      className={className}
      $spacing={spacing}
      onClick={() => history.push(CHARTS_PATH)}
      data-testid='button-top-navigation-logo'
    >
      {logo && <CompanyLogo src={logo} />}
      {!logo && (
        <>
          <OrgchartSymbolLogoStyled $color={color} />
          <OrgchartFullLogoStyled $color={color} />
        </>
      )}
    </LogoWrap>
  )
}

const LogoWrap = styled.div<{ $spacing?: SpacingProps }>`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${props => applySpacingProps(props.$spacing)}
`

const CompanyLogo = styled.img`
  max-width: 200px;
  max-height: 100%;
  box-sizing: border-box;
  padding: ${props => props.theme.spaces.m};
`

const OrgchartSymbolLogoStyled = styled(OrgchartSymbolLogo)<{ $color?: string }>`
  @media (max-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    display: block;
  }

  display: none;
  height: 19px;
  margin-bottom: 1px;
  color: ${props => props.$color ?? 'inherit'};
`

const OrgchartFullLogoStyled = styled(OrgchartFullLogo)<{ $color?: string }>`
  @media (max-width: ${props => props.theme.deviceBreakpoints.tablet}) {
    display: none;
  }

  height: 34px;
  color: ${props => props.$color ?? 'inherit'};
`
