import { useState } from 'react'

import { PublicLinkFormData } from '../PublicLinkForm'

import { handleErrorValidation } from 'helpers'
import analytics from 'analytics'
import { SHARING_TRACK } from 'analytics/constants'

import { getPublicLinks, chart as chartQuery } from 'apollo/query'
import { useUpdatePublicLinkMutation } from 'apollo/generated/graphql'

export const useUpdatePublicLink = () => {
  const [isUpdating, setIsUpdating] = useState(false)

  const [updatePublicLinkMutation] = useUpdatePublicLinkMutation()

  const updateLink = (chartUuid: string, publicLinkUuid: string, data: PublicLinkFormData) => {
    const { domains, allowedCustomFields, ips } = data
    setIsUpdating(true)

    return updatePublicLinkMutation({
      variables: {
        chartUuid,
        uuid: publicLinkUuid,
        updatePublicLinkInput: { domains, ips, allowedCustomFields },
      },
      refetchQueries: [
        { query: getPublicLinks, variables: { chartUuid } },
        { query: chartQuery, variables: { key: chartUuid } },
      ],
    })
      .then(() => {
        analytics.track(SHARING_TRACK.updatePublicLink, { chartUuid, domainCount: domains.length })
      })
      .catch(error => {
        handleErrorValidation({
          track: {
            message: SHARING_TRACK.updatePublicLinkFailure,
            values: { chartUuid, error },
          },
          toast: { message: 'Link update failed' },
        })
      })
      .finally(() => setIsUpdating(false))
  }

  return { updateLink, isUpdating }
}
