import { useMemo, ReactNode, useState } from 'react'
import styled from 'styled-components'

import { PersonImage } from './person-image'
import { CustomFieldsIconsPanel, useCustomFieldsPanel } from './custom-fields-panel'
import {
  Node,
  NodeInner,
  ExpandButton,
  NodeContent,
  Container,
  NodeConnectionLine,
  NodeActionsOverlay,
} from '../components'

import { useNode, useChartPermission } from 'tree/hooks'
import { useChartData } from 'tree/providers'
import { CustomConnectionFieldValue, Department, Person } from 'tree/types'
import { useResponsiveInfo } from 'providers'
import { useGoToDepartmentDetail, useGoToEmployeeDetail } from 'routes'
import { colors } from 'theme'
import { getNodeColorPallete, findSuperiorDepartment, addTransparencyWithBackground, stripStr } from 'helpers'

type Props = {
  compact: boolean
  node: Department | Person
  children?: ReactNode
}

export const ChartNode = ({ compact, node, children }: Props) => (
  <>
    <ChartNodeInner compact={compact} node={node} />
    {node.expanded && children}
  </>
)

const ChartNodeInner = ({ compact, node }: Omit<Props, 'chilren'>) => {
  const { __typename, capabilities, departmentCount, employeeCount, expanded, id, name, parentNodes, type } = node

  const isPerson = __typename === 'Person'
  const { position, isMe, image, customFields = [] } = isPerson ? (node as Person) : ({} as Partial<Person>)

  const isDepartment = __typename === 'Department'
  const { color, description } = isDepartment ? (node as Department) : ({} as Partial<Department>)

  const {
    canDrop,
    inView,
    isOver,
    showActions,
    setIsNodeHovered,
    handleBorderAnimationEnd,
    toggleExpanded,
    dragRef,
    setNodeRefs,
    setNodeInnerRefs,
  } = useNode(node as any)

  const showExpandButton = !(employeeCount === 0 && departmentCount === 0)
  const hasFullBorder = Boolean(isMe)
  const showImage = Boolean(image)

  const { areNodesReducedHeight } = useChartData()
  const { isSmallDevice } = useResponsiveInfo()
  const { goToEmployeeDetail } = useGoToEmployeeDetail()
  const { goToDepartmentDetail } = useGoToDepartmentDetail()

  const onNodeContentClick = () => {
    isPerson ? goToEmployeeDetail(id) : goToDepartmentDetail(id)
  }

  // Colors
  const colorPallete = useMemo(() => {
    return getNodeColorPallete(isDepartment ? color : findSuperiorDepartment(node)?.color)
  }, [isDepartment ? color : parentNodes])
  const nodeBackground = useMemo(() => {
    return isDepartment ? addTransparencyWithBackground(colorPallete.value, colors.white, 0.1) : colors.white
  }, [colorPallete.value])

  // Custom Fields
  const customFieldsPanelProps = useCustomFieldsPanel({
    childrenCount: employeeCount + departmentCount,
    compact,
    customFields,
    image: image || '',
  })

  // Connection Line
  const [showConnectionLines, setShowConnectionLines] = useState(true)
  const connectionFields = customFields.filter(f => f.type === 'connection') as CustomConnectionFieldValue[]

  // Capabilities
  const { canCreateDepartment, canCreatePerson } = useChartPermission()
  const { canMove, canUnassign } = capabilities || {}
  const canCreateNode = canCreateDepartment || canCreatePerson
  const canDrag = Boolean(canMove || canUnassign) && !isSmallDevice
  const canDoActions = canCreateNode || canDrag

  return (
    <>
      <Node
        className={`node-${type}`}
        $canDoActions={canDoActions}
        $compact={compact}
        $type={__typename}
        $isOver={isOver}
        $isReducedHeight={areNodesReducedHeight}
        onMouseEnter={() => setIsNodeHovered(true)}
        onMouseLeave={() => setIsNodeHovered(false)}
        onMouseDown={e => e.stopPropagation()} // Prevents moving chart when clicking on node (prevents bubbling to PositionPad component)
        ref={setNodeRefs}
        data-testid={`node-${type}`}
      >
        <NodeInner
          $background={nodeBackground}
          $borderColor={colorPallete.value}
          $canDrop={canDrop}
          $compact={compact}
          $hasFullBorder={hasFullBorder}
          $isOver={isOver}
          $type={__typename}
          onAnimationEnd={handleBorderAnimationEnd}
          ref={setNodeInnerRefs}
        >
          {inView && (
            <>
              {showActions && (
                <NodeActionsOverlay
                  canDrag={canDrag}
                  nodeType={__typename}
                  node={node}
                  hoverColor={colorPallete.value}
                  dragRef={dragRef}
                  onMenuClick={() => setIsNodeHovered(false)}
                  onDragStart={() => setShowConnectionLines(false)}
                  onDragEnd={() => setShowConnectionLines(true)}
                />
              )}

              <NodeContent
                $compact={compact}
                $isShortTopPadding={areNodesReducedHeight}
                onClick={onNodeContentClick}
                data-id='node-content'
              >
                {showImage && <PersonImage src={image} compact={compact} draggable={false} />}
                <Container $compact={compact}>
                  <TextContainer $compact={compact} $nodeHasImage={Boolean(image)}>
                    <Heading title={name} $shouldWrap={!(isDepartment && !compact)}>
                      {isDepartment && !compact ? stripStr(name, 50) : name}
                    </Heading>
                    <Description title={position || description || ''}>{position || description || ''}</Description>
                  </TextContainer>

                  {isPerson && <CustomFieldsIconsPanel {...customFieldsPanelProps} />}

                  {showExpandButton && (
                    <ExpandButton
                      compact={compact}
                      departmentCount={departmentCount}
                      employeeCount={employeeCount}
                      expanded={expanded}
                      toggle={toggleExpanded}
                    />
                  )}
                </Container>
              </NodeContent>
            </>
          )}
        </NodeInner>
      </Node>
      {showConnectionLines &&
        connectionFields.map(cf => {
          if (!cf.displayLineOnChart) return null
          return cf.values.map(value => (
            <NodeConnectionLine
              key={`${id} ${value.id}`}
              areNodesReducedHeight={areNodesReducedHeight}
              id={id}
              isCompact={compact}
              lineColor={cf.lineColor}
              lineStyle={cf.lineStyle}
              nodeType={__typename}
              parentId={value.id}
            />
          ))
        })}
    </>
  )
}

const TEXT_CONTAINER_PADDING = '22px'
const TextContainer = styled.div<{ $compact: boolean; $nodeHasImage: boolean }>`
  width: 100%;
  padding-right: ${props => (props.$compact && !props.$nodeHasImage ? TEXT_CONTAINER_PADDING : '0')};
  padding-left: ${props => (props.$compact ? TEXT_CONTAINER_PADDING : '0')};
  line-height: 15px;
  font-weight: 500;
  text-align: ${props => (props.$compact ? 'left' : 'center')};
  white-space: nowrap;
  box-sizing: border-box;
`

const Heading = styled.div<{ $shouldWrap: boolean }>`
  font-size: 14px;
  color: ${props => props.theme.colors.grey};
  text-overflow: ellipsis;
  ${props => !props.$shouldWrap && 'white-space: initial;'}
  overflow: hidden;
  margin-bottom: ${props => props.theme.spaces.xs};
`

const Description = styled.div`
  font-size: 11px;
  color: ${props => props.theme.colors.greyMediumLight};
  text-overflow: ellipsis;
  overflow: hidden;
`
