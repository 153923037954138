import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { generatePath, useRouteMatch } from 'react-router-dom'

import { DataEmpty } from '../styled'
import { ButtonGoogleCalendar } from 'components/specific/buttons'
import { TilButton } from 'components/generic'
import sociomapPlaceholder from 'assets/sociomap-placeholder.jpg'

import { useInsightsState } from '../../hooks'
import { SociomapPoints, SociomapValue } from '../../types'
import { useGoogleWorkspace } from 'tree/hooks'
import { INSIGHTS_SOCIOMAP_CONNECT_GW_PATH } from 'routes'

type SocmapViewerOptions = {
  duration?: number
  easingType?: string
}

type SociomapPoint = {
  x: number
  y: number
  n: string
  DOMElement: HTMLElement
}

declare class SocmapViewer {
  constructor(element: HTMLElement, options?: SocmapViewerOptions)
  animateTo(points: SociomapValue[], options?: SocmapViewerOptions): void
  renderMap(points: SociomapValue[], options?: SocmapViewerOptions): void
  on(event: string, callback: (data: any) => void): void
}

type Props = {
  width: number
  data?: SociomapPoints
  height?: number
  isDemo?: boolean

  agreeWithDemoSociomap?: boolean
  setAgreeWithSociomap?: () => void
}

const TRANSITION_DURATION = 1500
const TRANSITION_EASING = 'ease-in-out'

const SOC_TEXTS = {
  noData:
    'Sociomap for this month is not yet prepared for your organization. Please contact customer support for further information.',
  askPerm: "This Sociomap is a demo. Sync your Google Calendar to see your company's real department data. ",
  computingInsights:
    'Your company data for this month is being processed, this may take several hours. Please try again later.',
  nonAdminJustDemo:
    "This Sociomap is a demo. Ask your Google Calendar admin to sync Google Calendar in order to see your company's real department data.",
}

export const Sociomap = ({ data, height, width, isDemo, agreeWithDemoSociomap, setAgreeWithSociomap }: Props) => {
  const size = getSociomapSize({ width, height })
  const { askForCalendarPermission, isComputingInsights } = useInsightsState()
  const { params } = useRouteMatch<Record<string, string>>()

  const hasData = data && data.length !== 0
  const socTextSelection = isComputingInsights
    ? 'computingInsights'
    : askForCalendarPermission
    ? 'askPerm'
    : hasData
    ? 'nonAdminJustDemo'
    : 'noData'

  const [socmapNode, setSocmapNode] = useState<HTMLDivElement | null>(null)
  const [socmapViewer, setSocmapViewer] = useState<SocmapViewer>()

  const { authorize } = useGoogleWorkspace()

  useEffect(() => {
    if (socmapNode && !socmapViewer) {
      const socmapViewer = new SocmapViewer(socmapNode, {
        duration: TRANSITION_DURATION,
        easingType: TRANSITION_EASING,
      })
      setSocmapViewer(socmapViewer)

      let startingIndex = 100
      socmapViewer.on('labelClick', (point: SociomapPoint) => {
        point.DOMElement.style.zIndex = String(startingIndex++) // moving sociomap labels more up, selecting filters via click in sociomap
      })
    }
  }, [data, socmapNode, socmapViewer])

  useEffect(() => {
    if (socmapViewer && data && data.length > 0) {
      socmapViewer.animateTo(data)
    }
  }, [data, size, socmapViewer])

  useEffect(() => {
    if (data && data.length === 0) setSocmapViewer(undefined)
  }, [data])

  return (
    <Container>
      <SociomapWrapper size={size}>
        {(agreeWithDemoSociomap || !isDemo) && hasData ? (
          <SociomapEl ref={setSocmapNode} />
        ) : (
          <>
            <img src={sociomapPlaceholder} width='100%' alt='' />
            <DataEmpty>
              <InfoHeading>{SOC_TEXTS[socTextSelection]}</InfoHeading>

              <ButtonsContainer>
                {!agreeWithDemoSociomap && hasData && isDemo && (
                  <TilButton
                    size='small'
                    type='outlined'
                    onClick={setAgreeWithSociomap}
                    spacing={{ m: '0 8px' }}
                    outlineIsOnDark
                  >
                    Try with demo data
                  </TilButton>
                )}
                {!isComputingInsights && askForCalendarPermission && (
                  <ButtonGoogleCalendar
                    size='small'
                    redirectPath={generatePath(INSIGHTS_SOCIOMAP_CONNECT_GW_PATH, params)}
                    authorize={authorize}
                    outlineIsOnDark
                  />
                )}
              </ButtonsContainer>
            </DataEmpty>
          </>
        )}
      </SociomapWrapper>
    </Container>
  )
}

type SociomapSize = { width: number; height?: number }
function getSociomapSize({ width, height }: SociomapSize) {
  return height ? Math.min(height, width) : width
}

const Container = styled.div`
  display: flex;
  flex: 1 0 auto;
  position: relative;
  height: 100%;
  overflow: hidden;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const InfoHeading = styled.div`
  font-size: 18px;
  line-height: 24px;
  margin: 16px;
  margin-bottom: 32px;
  text-align: center;

  @media (min-width: ${props => props.theme.deviceBreakpoints.smallTablet}) {
    width: 480px;
  }

  @media (min-width: ${props => props.theme.deviceBreakpoints.laptop}) {
    width: 540px;
  }
`

const SociomapWrapper = styled.div.withConfig<{ size: number }>({
  shouldForwardProp: prop => !['size'].includes(prop),
})`
  position: relative;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
`

const SociomapEl = styled.div`
  height: 100%;
  width: 100%;
`
