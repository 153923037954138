import { ReactNode } from 'react'
import styled from 'styled-components'

import { applySpacingProps, SpacingProps } from 'helpers'

type Props = {
  title: ReactNode
  value: ReactNode
}

export const NodeDetailItem = ({ title, value }: Props) => (
  <DetailContainer>
    <DetailHeading>{title}</DetailHeading>
    <DetailValue>{value}</DetailValue>
  </DetailContainer>
)

export const DetailContainer = styled.div`
  margin-bottom: ${props => props.theme.spaces.l};
  overflow-wrap: anywhere;

  :last-child {
    margin-bottom: ${props => props.theme.spaces.m};
  }
`

export const DetailHeading = styled.div`
  margin-bottom: ${props => props.theme.spaces.s};
  color: ${props => props.theme.colors.greyLight};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`

export const DetailValue = styled.div<{ spacing?: SpacingProps }>`
  display: flex;
  flex-wrap: wrap;
  color: ${props => props.theme.colors.dark};
  font-size: 13px;
  line-height: 16px;

  ${props => applySpacingProps(props.spacing)}
`
