import { useRef } from 'react'
import { ErrorMessage, Form, Formik, FormikHelpers } from 'formik'
import styled from 'styled-components'

import { Button, FieldErrorMessage, FlexBox, NodeSearchField, SelectButton } from 'components/generic'
import {
  DownloadIcon,
  ImageFileIcon,
  OrgchartCompactStructureIcon,
  OrgchartStandardStructureIcon,
  PdfIcon,
  TableIcon,
} from 'components/icons'

import { useExportChartAction } from 'tree/actions'
import { useChartData } from 'tree/providers'
import { ChartExportFileFormat, NodeTypename } from 'tree/types'
import { COMPANY_UUID } from 'consts'
import { colors, spaces } from 'theme'

type ExportChartFormValues = {
  compact: boolean
  exportNodeId: string | null
  format: ChartExportFileFormat
  searchValue: string
}

type ExportChartFormSubmitValues = Omit<ExportChartFormValues, 'exportNodeId'> & {
  exportNodeId: string
}

type ExportChartFormErrors = Partial<Record<keyof ExportChartFormValues, string>>

export type SelectedNode = {
  id: string | null
  name: string
  __typename?: NodeTypename
}

type Props = Partial<ExportChartFormValues> & {
  onNodeSelect?: (node: SelectedNode) => void
}

const ICON_SPACING = { ml: spaces.m, mr: spaces.m }
const ICON_COLOR = colors.border
const INITIAL_VALUES: ExportChartFormValues = {
  compact: false,
  exportNodeId: COMPANY_UUID,
  format: 'pdf',
  searchValue: '',
}

export const ExportChartOrNode = ({ onNodeSelect, ...formValues }: Props) => {
  const chartData = useChartData()
  const { exportChartWithStatus } = useExportChartAction({ chartData })
  const searchInputRef = useRef<HTMLInputElement>(null)
  const initialValues: ExportChartFormValues = { ...INITIAL_VALUES, ...formValues }

  const handleSubmit = async (
    values: ExportChartFormSubmitValues,
    formikHelpers: FormikHelpers<ExportChartFormValues>
  ) => {
    const { compact, format, exportNodeId } = values
    await exportChartWithStatus({ compact, format, rootId: exportNodeId })
    formikHelpers.resetForm()
  }

  const handleValidate = (values: ExportChartFormValues) => {
    const { exportNodeId } = values
    const errors: ExportChartFormErrors = {}

    if (!exportNodeId) {
      errors.exportNodeId = 'Please select employee or department'
    }

    return errors
  }

  return (
    <Formik<ExportChartFormValues>
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => handleSubmit(values as ExportChartFormSubmitValues, formikHelpers)}
      validate={handleValidate}
    >
      {formState => {
        const { values, isSubmitting, setFieldValue, handleSubmit } = formState
        const { compact, exportNodeId, format, searchValue } = values
        const isExportingCompany = exportNodeId === COMPANY_UUID

        return (
          <Form tabIndex={0} onSubmit={handleSubmit}>
            <Container $isDirectionColumn>
              <GroupTitle>Data</GroupTitle>

              <FlexBox $isDirectionColumn $spacing={{ mb: spaces.xl }}>
                <FlexBox $gap='l'>
                  <SelectButton
                    type='radio'
                    checked={isExportingCompany}
                    onChange={() => {
                      setFieldValue('searchValue', '')
                      setFieldValue('exportNodeId', COMPANY_UUID)
                      onNodeSelect?.({ id: COMPANY_UUID, name: 'Org Chart', __typename: 'Company' })
                    }}
                  >
                    <FlexBox $spacing={{ ml: spaces.m }}>Whole company</FlexBox>
                  </SelectButton>

                  <SelectButton
                    type='radio'
                    checked={!isExportingCompany}
                    onChange={() => {
                      setFieldValue('exportNodeId', null)
                      onNodeSelect?.({ id: null, name: 'department or team' })
                      // Focus the input when the component has finished rendering
                      setTimeout(() => searchInputRef.current?.focus(), 10)
                    }}
                  >
                    <FlexBox $spacing={{ ml: spaces.m }}>Department or team</FlexBox>
                  </SelectButton>
                </FlexBox>

                {!isExportingCompany && (
                  <SearchContainer>
                    <NodeSearchField
                      value={searchValue}
                      disabled={isExportingCompany}
                      chartId={chartData.id}
                      innerRef={searchInputRef}
                      onChange={value => {
                        setFieldValue('searchValue', value)
                        onNodeSelect?.({ id: null, name: 'department or team' })
                        if (exportNodeId) {
                          setFieldValue('exportNodeId', null)
                        }
                      }}
                      onNodeSelect={({ id, name, __typename }) => {
                        setFieldValue('searchValue', name)
                        setFieldValue('exportNodeId', id)
                        onNodeSelect?.({ id, name, __typename })
                      }}
                    />
                    <ErrorMessage name='exportNodeId'>
                      {message => (message ? <FieldErrorMessage message={message} /> : null)}
                    </ErrorMessage>
                  </SearchContainer>
                )}
              </FlexBox>

              <GroupTitle>Format</GroupTitle>
              <FlexBox $gap='l' $spacing={{ mb: spaces.xl }}>
                <SelectButton type='radio' checked={format === 'pdf'} onChange={() => setFieldValue('format', 'pdf')}>
                  <PdfIcon width='18px' color='#FA0F00' $spacing={ICON_SPACING} />
                  PDF
                </SelectButton>
                <SelectButton type='radio' checked={format === 'png'} onChange={() => setFieldValue('format', 'png')}>
                  <ImageFileIcon width='13px' color={ICON_COLOR} $spacing={ICON_SPACING} />
                  PNG
                </SelectButton>
                <SelectButton type='radio' checked={format === 'csv'} onChange={() => setFieldValue('format', 'csv')}>
                  <TableIcon width='17px' height='100%' color={ICON_COLOR} $spacing={ICON_SPACING} />
                  CSV
                </SelectButton>
              </FlexBox>

              {format !== 'csv' && (
                <>
                  <GroupTitle>Type</GroupTitle>
                  <FlexBox $gap='l' $spacing={{ mb: spaces.xl }}>
                    <SelectButton type='radio' checked={!compact} onChange={() => setFieldValue('compact', false)}>
                      <OrgchartStandardStructureIcon
                        width='21px'
                        height='100%'
                        color={ICON_COLOR}
                        $spacing={ICON_SPACING}
                      />
                      Standard
                    </SelectButton>
                    <SelectButton type='radio' checked={compact} onChange={() => setFieldValue('compact', true)}>
                      <OrgchartCompactStructureIcon
                        width='16px'
                        height='100%'
                        color={ICON_COLOR}
                        $spacing={ICON_SPACING}
                      />
                      Compact
                    </SelectButton>
                  </FlexBox>
                </>
              )}

              <Button
                leftEl={<DownloadIcon $spacing={{ mr: spaces.m }} />}
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                Download
              </Button>
            </Container>
          </Form>
        )
      }}
    </Formik>
  )
}

const Container = styled(FlexBox)`
  font-size: 13px;
  color: ${props => props.theme.colors.dark};
`

const GroupTitle = styled.div`
  margin-bottom: ${props => props.theme.spaces.m};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`

const SearchContainer = styled.div`
  max-width: 420px;
  margin-top: ${props => props.theme.spaces.l};
`
