import { useCreateDepartmentRequest } from './use-create-department-request'
import { useCreatePersonRequest } from './use-create-person-request'
import { useChartData } from 'tree/providers'

import analytics from 'analytics'
import { FormikHelpers } from 'formik'
import { handleErrorValidation, capitalize } from 'helpers'

import {
  DepartmentFormSubmitParams,
  PersonFormSubmitParams,
  PersonFormSubmitValues,
  PersonFormValues,
} from 'tree/types'

import { CreateDepartmentInput } from 'apollo/generated/graphql'
import { ORG_TRACK } from 'analytics/constants'

type CreatePersonParams = PersonFormSubmitParams & {
  nodeTypename: 'Person'
}

type CreateDepartmentParams = DepartmentFormSubmitParams & {
  nodeTypename: 'Department'
}

type CreateNodeParams = CreateDepartmentParams | CreatePersonParams

export const useCreateNodeAction = () => {
  const { uuid: chartUuid, name: chartName } = useChartData()
  const { createDepartmentRequest } = useCreateDepartmentRequest()
  const { createPersonRequest } = useCreatePersonRequest()

  const createNode = async ({ values, actions, nodeTypename }: CreateNodeParams) => {
    const createRequest = () =>
      nodeTypename === 'Department'
        ? createDepartmentRequest(values as CreateDepartmentInput)
        : createPersonRequest(values as PersonFormSubmitValues)

    const nodeType = nodeTypename === 'Person' ? 'employee' : 'department'
    const trackKey = nodeTypename === 'Person' ? 'createEmployee' : 'createDepartment'

    try {
      const res = await createRequest()
      analytics.track(ORG_TRACK[trackKey], { chartUuid, chartName })
      return Promise.resolve(res)
    } catch (error) {
      handleErrorValidation({
        track: {
          message: `Creating ${nodeType} failed` as const,
          values: { chartUuid, error },
        },
        toast: { message: `${capitalize(nodeType)} not created` },
        error,
        actions: actions as FormikHelpers<PersonFormValues | CreateDepartmentInput>,
      })
    }
  }

  return { createNode }
}
